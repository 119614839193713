<div class="info-card p-32">
    <div *ngIf="!!title" class="title text-left">
        {{ title | translate }}
    </div>
    <div *ngIf="!!text" class="text mt-4 mb-2">
        {{ text | translate }}
    </div>
    <a *ngIf="!!linkText" 
    class="link d-flex align-items-center mt-4 mb-2"
    (click)="onClick()">
        <span class="link-text">
            {{ linkText | translate }}
        </span>
        <span class="material-icons link-icon ml-2">arrow_forward</span>

    </a>
</div>