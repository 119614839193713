<div class="img-row">
    <img src=" ../../../assets/asp/logo_wee.png" class="logo-img" />
</div>
<div class="bandeau">
    {{
        (this.estReinit ? "ASP.FORGOTTEN_PWD.CONFIRMATION_TITLE" : "ASP.FORGOTTEN_PWD.CONFIRMATION_TITLE_ALT")
            | translate
    }}
</div>

<!-- row pour la vue descktop -->
<div class="d-none d-md-block">
    <form
        name="passwordForm"
        [formGroup]="passwordForm"
        (submit)="submitForm()"
        class="row justify-content-center form-asp"
    >
        <div *ngIf="lienInvalide">
            <section class="col-12">
                <div class="text-center">
                    {{ (this.estReinit ? "ASP.CHANGE_PWD.EXPIRATE" : "ASP.CHANGE_PWD.EXPIRATE_ALT") | translate }}
                </div>
                <div class="actions pt-5">
                    <button type="button" class="btn btn-large btn-primary" (click)="renvoyer()">
                        {{ "ASP.CHANGE_PWD.NEW_LINK" | translate }}
                    </button>
                </div>
            </section>
        </div>

        <div class="spinner-border spinner-border-sm" *ngIf="waiting">
            <span class="sr-only"></span>
        </div>

        <div *ngIf="!error && !success && !waiting" class="container">
            <section class="col-12">
                <div class="row">
                    <div class="col-6">
                        <div class="col-12 col-sm">
                            <div class="form-group">
                                <label for="password" class="form-label titre">{{
                                    "ASP.CHANGE_PWD.MOT_DE_PASSE" | translate
                                }}</label>
                                <input
                                    type="password"
                                    class="form-control value"
                                    id="password"
                                    name="password"
                                    formControlName="password"
                                    placeholder="******"
                                    (keyup)="controlPassWord()"
                                />
                                <div
                                    *ngIf="passwordForm.get('password').touched && passwordForm.get('password').invalid"
                                >
                                    <small
                                        class="form-text text-danger"
                                        *ngIf="passwordForm.get('password').errors.required"
                                    >
                                        {{ "COMMON.FORM.REQUIRED" | translate }}
                                    </small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="confirmPassword" class="form-label titre">{{
                                    "ASP.CHANGE_PWD.MOT_DE_PASSE_CONFIRM" | translate
                                }}</label>
                                <input
                                    type="password"
                                    class="form-control value"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    formControlName="confirmPassword"
                                    placeholder="******"
                                    (keyup)="controlPassWord()"
                                />
                                <div *ngIf="passwordForm.get('confirmPassword').touched">
                                    <small
                                        class="form-text text-danger"
                                        *ngIf="
                                            passwordForm.get('confirmPassword').errors &&
                                            passwordForm.get('confirmPassword').errors.required
                                        "
                                    >
                                        {{ "COMMON.FORM.REQUIRED" | translate }}
                                    </small>
                                    <small class="form-text text-danger" *ngIf="!passwordEqual">
                                        {{ "COMMON.PASSWORD.DO_NOT_MATCH" | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 infos-utilisateur">
                        <p class="pt-2">{{ "ASP.CHANGE_PWD.VALIDATION" | translate }}</p>
                        <app-password-validation
                            [passwordValue]="passwordForm.get('password').value"
                            [affLastPassword]="false"
                            [progressBar]="true"
                        >
                        </app-password-validation>
                    </div>
                </div>
                <div class="actions pt-5">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        [disabled]="!passwordForm.valid || !passwordEqual"
                        data-toggle="modal"
                        data-target="#confirmModal"
                    >
                        {{ "COMMON.FORM.SUBMIT" | translate | uppercase }}
                    </button>
                </div>

                <div class="legal pt-5">
                    {{ "ASP.CHANGE_PWD.MANDATORY" | translate }}
                </div>
            </section>
        </div>
    </form>
</div>

<div class="d-md-none p-2 bg-blanc">
    <form name="passwordForm" [formGroup]="passwordForm" (submit)="submitForm()" class="justify-content-center">
        <div *ngIf="lienInvalide">
            <section class="col-12">
                <div class="text-center">
                    {{ "ASP.CHANGE_PWD.EXPIRATE" | translate }}
                </div>
                <div class="actions pt-5">
                    <button type="button" class="btn btn-large btn-primary" (click)="renvoyer()">
                        {{ "ASP.CHANGE_PWD.NEW_LINK" | translate }}
                    </button>
                </div>
            </section>
        </div>

        <div class="spinner-border spinner-border-sm" *ngIf="waiting">
            <span class="sr-only"></span>
        </div>

        <div *ngIf="!error && !success && !waiting" class="row m-0">
            <div class="col-12">
                <div class="form-group">
                    <label for="password" class="form-label titre">{{
                        "ASP.CHANGE_PWD.MOT_DE_PASSE" | translate
                    }}</label>
                    <input
                        type="password"
                        class="form-control value"
                        id="password"
                        name="password"
                        formControlName="password"
                        placeholder="******"
                        (keyup)="controlPassWord()"
                    />
                    <div *ngIf="passwordForm.get('password').touched && passwordForm.get('password').invalid">
                        <small class="form-text text-danger" *ngIf="passwordForm.get('password').errors.required">
                            {{ "COMMON.FORM.REQUIRED" | translate }}
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-12 infos-utilisateur">
                <p class="pt-2">{{ "ASP.CHANGE_PWD.VALIDATION" | translate }}</p>
                <app-password-validation
                    [passwordValue]="passwordForm.get('password').value"
                    [affLastPassword]="false"
                    [progressBar]="true"
                >
                </app-password-validation>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="confirmPassword" class="form-label titre">{{
                        "ASP.CHANGE_PWD.MOT_DE_PASSE_CONFIRM" | translate
                    }}</label>
                    <input
                        type="password"
                        class="form-control value"
                        id="confirmPassword"
                        name="confirmPassword"
                        formControlName="confirmPassword"
                        placeholder="******"
                        (keyup)="controlPassWord()"
                    />
                    <div *ngIf="passwordForm.get('confirmPassword').touched">
                        <small
                            class="form-text text-danger"
                            *ngIf="
                                passwordForm.get('confirmPassword').errors &&
                                passwordForm.get('confirmPassword').errors.required
                            "
                        >
                            {{ "COMMON.FORM.REQUIRED" | translate }}
                        </small>
                        <small class="form-text text-danger" *ngIf="!passwordEqual">
                            {{ "COMMON.PASSWORD.DO_NOT_MATCH" | translate }}
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="actions col-12 pt-3">
            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="!passwordForm.valid || !passwordEqual"
                data-toggle="modal"
                data-target="#confirmModal"
            >
                {{ "COMMON.FORM.SUBMIT" | translate | uppercase }}
            </button>
        </div>

        <div class="legal pt-5">
            {{ "ASP.CHANGE_PWD.MANDATORY" | translate }}
        </div>
    </form>
</div>

<!-- Modal -->
<div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
    *ngIf="passwordEqual"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                {{ "ASP.CHANGE_PWD.MODAL_HEADER" | translate }}
            </div>
            <div class="modal-body">
                <b>{{ "ASP.CHANGE_PWD.MODAL_TEXT" | translate }}</b>
                <br />
                <p>{{ "ASP.CHANGE_PWD.MODAL_NOW" | translate }}</p>
            </div>
            <div class="actions">
                <button type="button" class="btn btn-secondary" (click)="login()" data-dismiss="modal">
                    {{ "ASP.FORGOTTEN_PWD.CLOSE" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<app-modal id="resendLinkModal">
    <app-header-modal titleModal="{{ 'ASP.CHANGE_PWD.MODAL_HEADER' | translate }}" (modalClose)="cancelPopUp()">
    </app-header-modal>
    <div class="container pt-4 popUp text-center">
        <p>{{ "ASP.CHANGE_PWD.NEW_LINK_SEND" | translate }}</p>
    </div>
    <div class="actions">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancelPopUp()">
            {{ "ASP.FORGOTTEN_ID.CLOSE" | translate }}
        </button>
    </div>
</app-modal>
