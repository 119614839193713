import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { ModalService } from '@shared/components/modal/modal.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import decodeCodeApp from '@shared/helpers/decoder.helper';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { BehaviorSubject } from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-check-user',
  templateUrl: './check-user.component.html',
  styleUrls: ['./check-user.component.scss']
})
export class CheckUserComponent implements OnInit {
  public messageErreur: string;
  public login: string;
  public applicationId: string;
  public redirectUrl: string;
  public estMobileAPZ: boolean;
  public estBimpli = false;
  public identifiantOK: boolean;
  public saml: string;
  public showConfimationModifLogin: boolean;
  public isLoading = false;
  public isLoadingComp = false;
  public passworValid = true;
  private valueCaptcha: string;

  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  isActualPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  identifiantForm = this.fb.group({
    currentPassword: [undefined, Validators.required]
  });

  constructor(
    protected fb: FormBuilder,
    private route: ActivatedRoute,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private translateService: TranslateService,
    private titleService: Title,
    private modalService: ModalService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    this.saml = this.route.snapshot.paramMap.get('saml');
    const samlDecoder = atob(this.saml);
    this.login = samlDecoder.split(';')[0];
    this.applicationId = samlDecoder.split(';')[1];
    this.redirectUrl = samlDecoder.split(';')[2];

    const decoded = decodeCodeApp(this.applicationId);
    if (ApplicationCodeEnum.BENEFIT_CAGNOTTE ===  this.applicationId || ApplicationCodeEnum.BENEFIT_FINANCEUR ===  this.applicationId
      || ApplicationCodeEnum.BENEFIT_BENEF ===  this.applicationId || ApplicationCodeEnum.BENEFIT_MOBILE ===  this.applicationId) {
      this.estBimpli = true;
      this.favIcon.href = '/assets/bimpli/favicon.ico';
      this.titleService.setTitle('Mon espace personnel Bimpli');
    }
  }

  checkUser(): void {
    this.isLoadingComp = true;
    this.gatewayService.getAccessToken().subscribe(authToken => {
        this.reCaptchaV3Service
          .execute('UserPasswordCheckAction')
          .pipe(finalize(() => {
            this.siloApiService.userPasswordCheck(this.login, this.identifiantForm.get('currentPassword').value,
              this.applicationId, authToken, this.valueCaptcha).subscribe(retour => {
                this.passworValid = retour.resultCheck;
                if (retour.resultCheck) {
                  this.redirection();
                }
                this.isLoadingComp = false;
              },
              () => this.isLoadingComp = false);
          }))
          .subscribe((token) => (this.valueCaptcha = token));
      },
      () => this.isLoadingComp = false);

  }

  public redirection(): void {
    if ( this.redirectUrl) {
      window.location.href =  this.redirectUrl;
    }
  }

  public gererErreurs(error: HttpErrorResponse): void {
    this.isLoading = false;
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = JSON.parse(error.error);
      if (errors && errors.errors && errors.errors.length > 0) {
        switch (errors.errors[0].code) {
          case CodeErreurs.UNEXPECTED_011:
            this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED_011');
            break;
          case CodeErreurs.INCORRECT_DATA_007:
            this.messageErreur = this.translateService.instant('COMMON.USER.NEWLOGIN_INCORRECT');
            break;
          case CodeErreurs.ALREADY_EXIST_022:
            this.messageErreur = this.translateService.instant('COMMON.USER.LOGIN_ALREADY_EXISTS');
            break;
          case CodeErreurs.FORBIDDEN_ACTION_010:
            this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.FORBIDDEN_ACTION_010');
            break;
          default:
            this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED') + errors.errors[0].code;
            break;
        }
      } else if (error.status === 500) {
        this.messageErreur = this.translateService.instant('COMMON.ERROR.500');
      }
    }
    if (this.estBimpli) {
      this.modalService.open('errorModal');
    }
  }

  closePopin(id: string){
    this.modalService.close(id);
  }

}
