<app-apz-layout [title]="'Modification réussie !'" [saml]="saml" [showBack]="false">
  <div class="col-12">
    <div class="row justify-content-center align-items-center">
      <img src="assets/apz/femme-pomme.png" />
    </div>
    <div class="row justify-content-center align-items-center">
      <p>
        {{ title }}
      </p>
    </div>
    <div class="text-center pt-3 btn-change-id">
      <button class="btn btn-action" (click)="goLogin()" type="submit">
        {{ 'COMMON.USER.CONNECT' | translate }}
      </button>
    </div>
  </div>
</app-apz-layout>
