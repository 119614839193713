import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-change-forgotten-pwd-success',
  templateUrl: './change-forgotten-pwd-success.component.html',
  styleUrls: ['./change-forgotten-pwd-success.component.scss'],
})
export class ChangeForgottenPwdSuccessComponent implements OnInit {
  public applicationCode$: Observable<ApplicationCodeEnum>;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
  ) {
  }

  ngOnInit(): void {
    this.sessionStateService.setApplicationCodeFromForgottenPassword(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;
  }

}
