<div class="img-fluid div-login apetiz-image">
  <div class="container">
    <div id="loginbox" style="max-width: 500px;" class="d-block mx-auto">
      <div class="form-row text-center">
        <div class="col-12 logo-container">
            <img class="img-fluid apetiz-logo" src="/assets/Logo_APZ_Mobile.png" />
        </div>
      </div>
      <div [hidden]="pageLoading" class="login-container">
        <div *ngIf="rememberLogin" class="welcome-container">
            <div class="first-name-reminder">Bonjour {{knownFirstName | titlecase}} !</div>
            <div class="login-reminder">{{knownLogin}}</div>
        </div>
        <form #f="ngForm" (submit)="connect(f)" [ngClass]="isSubmitted ? 'was-validated' : 'needs-validation'" novalidate>
          <section [formGroup]="ginsc">
            <div *ngIf="!rememberLogin" style="margin-bottom: 2.5vh">
              <div class="input-group ">
                <input
                  id="inputLogin"
                  [formControl]="login"
                  type="text"
                  class="form-control input icon-input"
                  placeholder="Identifiant"
                  aria-describedby="inputGroupPrepend"
                  required
                />
                <div *ngIf="login.invalid" class="invalid-feedback ">
                  <div class="error error-login">
                    Veuillez saisir votre identifiant.
                  </div>
                </div>
              </div>
                <div>
                    <a class="link" (click)="forgotLogin()">
                        Identifiant oublié
                    </a>
                </div>
            </div>
            <div style="margin-bottom: 2.5vh">
              <div class="input-group ">
                <input
                  id="inputPassword"
                  [formControl]="password"
                  type="password"
                  class="form-control input icon-input"
                  placeholder="Mot de passe"
                  required
                />
                <div *ngIf="password.invalid" class="invalid-feedback ">
                  <div class="error error-login">
                    Veuillez saisir votre mot de passe.
                  </div>
                </div>
              </div>
                <div>
                    <a class="link" (click)="forgotPassword()">
                        Mot de passe oublié
                    </a>
                </div>
            </div>
          </section>
          <div class="container" style="text-align: center; margin-bottom: 56px;margin-top: 50px;">
            <button type="submit" style="display:none">hidden submit</button>
            <nit-spinner-button
              (clicked)="connect(f)"
              label="JE ME CONNECTE"
              [isLoading]="isLoading"
              css="btn btn-action btn-login row justify-content-center"
            >
            </nit-spinner-button>
          </div>
          <div *ngIf="!rememberLogin" class="col ">
            <div class="register">
                Première connexion ?
            </div>
            <div class="register">
              <a id="register" [routerLink]="" class="register" (click)="register()">Je m’inscris !</a>
            </div>
          </div>
          <div *ngIf="rememberLogin" class="col text-center ">
            <div class="other-login">
                Vous n’êtes pas {{knownFirstName | titlecase}} ?
            </div>
            <div>
                <button type="button" class="btn btn-action" (click)="saisirAutreLogin()">CHANGER DE COMPTE</button>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="pageLoading" class="text-center">
        <div class="spinner-border" role="status"></div>
      </div>
    </div>
  </div>
  <!-- footer -->
  <section class="col-12 apz-footer d-flex">
    <small class="text-white version">Version : {{ version }}</small>
  </section>
  <img class="img-fluid salad" src="/assets/salade.png">
</div>

<app-modal id="infoModal">
    <div class="float-right">
        <button type="button" class="btn text-right" (click)="closePopin('infoModal')">
            <img src="assets/images/close.svg" class="img-fluid quit" alt="" />
        </button>
    </div>
    <div class="popin text-center">
        <div class="icon-container">
            <img class="img-fluid" src="/assets/images/info.svg" />
        </div>
        <div>{{ messageInfo }}</div>
        <div class="actions-container">
            <button type="button" class="btn btn-action btn-login"
                data-dismiss="modal" (click)="closePopin('infoModal')">FERMER</button>
        </div>
    </div>
</app-modal>

<app-modal id="errorModal">
    <div class="float-right">
        <button type="button" class="btn text-right" (click)="closePopin('errorModal')">
            <img src="assets/images/close.svg" class="img-fluid quit" alt="" />
        </button>
    </div>
    <div class="popin text-center">
        <div class="icon-container">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div>{{ messageErreur }}</div>
        <div class="actions-container">
            <button type="button" class="btn btn-action btn-login"
                data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>

