import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TealiumService } from '../../../../shared/services/tealium/tealium.service';

@Component({
  selector: 'app-first-step-apz-mobile-component',
  templateUrl: './first-step-apz-mobile.component.html',
  styleUrls: ['./first-step-apz-mobile.component.scss',
    '../../create-account-APZ.component.scss',
    '../../../../../assets/css/apz-global.scss']
})
export class FirstStepApzMobileComponent implements OnInit {

  cardForm = this.fb.group({
    numCard: [undefined, [Validators.required, Validators.minLength(16), Validators.maxLength(16), Validators.pattern('[0-9]{16}')]],
    expirationDate: [undefined, [Validators.required, Validators.minLength(4),
    Validators.maxLength(4), Validators.pattern('(0[1-9]|1[012])([0-9]{2})')]],
  });

  @Output() validate: EventEmitter<any[]> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input() messageErreur: string;
  @Input() success: boolean;
  @Input() cardNumber: string;
  @Input() inlined = false;
  @Input() saml: string;
  @Input() expirationDate: string;
  @ViewChild('expiration', { static: false }) expiraDateField: ElementRef;

  constructor(private fb: FormBuilder,
    private readonly tealiumService: TealiumService) {
  }

  ngOnInit() {
    if (!this.inlined) {
      this.tealiumService.view('apz.mobile.registrationProcess.registrationProcessPageLoad');
    }
    if (this.expirationDate && this.cardNumber) {
      this.cardForm.setValue({
        numCard: this.cardNumber,
        expirationDate: this.expirationDate
      });
    }
  }

  send() {
    if (!this.inlined) {
      this.tealiumService.link('apz.mobile.registrationProcess.validateRegistrationClick');
    }
    this.validate.emit(this.cardForm.getRawValue());
  }

  sortie() {
    this.cancel.emit();
  }

}
