import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-account-exists',
  templateUrl: './account-exists.component.html',
  styleUrls: ['./account-exists.component.scss']
})
export class AccountExistsComponent implements OnInit {
  refresh$: Observable<boolean>;

  public saml: string;

  constructor(private readonly router: Router, private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.refresh$ = this.route.paramMap.pipe(
      tap((params) => {
        this.saml = params.get('saml');
      }),
      map(() => true)
    );
  }

  public gotoConnection(): void {
    // redirection vers la mire de connexion
    this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
  }
}
