import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { validatePassword } from '../../../shared/components/password-validation/password.helper';
import { distinctUntilChanged } from 'rxjs/operators';
import { GroundTruthDataService } from '../../../shared/services/ground-truth-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-apz-confirmation-password',
  templateUrl: './apz-confirmation-password.component.html',
  styleUrls: ['./apz-confirmation-password.component.scss', '../../../../assets/css/apz-global.scss']
})
export class ApzConfirmationPasswordComponent implements OnInit, OnDestroy {
  @Output() submitConfirmation: EventEmitter<any[]> = new EventEmitter();
  @Output() goLogin: EventEmitter<any> = new EventEmitter();
  @Output() renvoyerDemande: EventEmitter<any> = new EventEmitter();

  @Input() success: boolean;
  @Input() error: boolean;
  @Input() messageErreur: string;
  @Input() waiting: boolean;
  @Input() lienInvalide: boolean;

  passwordForm = this.fb.group({
    password: [undefined, validatePassword()],
    confirmPassword: [undefined, [Validators.required]]
  });

  mySubscription = new Subscription();
  isLoading: boolean;
  passwordEqual = false;
  hide = true;
  hide2 = true;

  constructor(private fb: FormBuilder, private gtds: GroundTruthDataService) {}

  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
  }

  submit() {
    if (this.passwordForm.valid && this.passwordEqual === true) {
      this.gtds.setIsSpinnerActivated(true);
      this.submitConfirmation.emit(this.passwordForm.getRawValue());
    }
  }

  recall() {
    this.gtds.setIsSpinnerActivated(true);
    this.renvoyerDemande.emit();
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }

  controlPassWord() {
    this.passwordEqual = this.passwordForm.get('password').value === this.passwordForm.get('confirmPassword').value;
  }

  connect() {
    this.goLogin.emit();
  }
}
