<div id="forgotten-password-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row align-items-center justify-content-center w-100 h-100">
    <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-7 col-7 card shadow p-3">
      <form class="h-100 w-100" role="form" [formGroup]="forgottenPwdForm" (submit)="submit()" (keydown.enter)="submit()">
        <div class="d-flex flex-column h-100 w-100 align-items-center">
          <span class="alert alert-danger error-msg" *ngIf="errorMessage">
            {{ errorMessage }}
          </span>
          <div class="alert alert-info success-msg" *ngIf="notificationEnvoyer">
            <span class="d-flex flex-row mb-1">{{ 'AFFILIE_APZ.FORGOTTEN_PASSWORD.SEND_MAIL' | translate }}</span>
            <span class="d-flex flex-row">{{ 'AFFILIE_APZ.FORGOTTEN_PASSWORD.SEND_MAIL_CHECK' | translate }}</span>
          </div>

          <!-- HELLO -->
          <span id="title" class="d-flex flex-row align-items-center justify-content-start w-100 p-1"
            >{{ 'AFFILIE_APZ.FORGOTTEN_PASSWORD.HELLO' | translate }}
          </span>
          <span id="sub-title" class="d-flex flex-row align-items-center justify-content-start w-100 p-1">{{
            'AFFILIE_APZ.FORGOTTEN_PASSWORD.HELLO_DESCRIPTION' | translate
          }}</span>

          <!-- Login -->
          <div class="d-flex flex-column w-100 mt-3">
            <!-- Login -->
            <div class="d-flex flex-column">
              <label for="login" class="form-label">{{ 'AFFILIE_APZ.FORGOTTEN_PASSWORD.LOGIN' | translate }}</label>
              <input
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': forgottenPwdForm.get('login').invalid === true && forgottenPwdForm.get('login').touched === true,
                  'is-valid': forgottenPwdForm.get('login').invalid === false && forgottenPwdForm.get('login').touched === true
                }"
                name="login"
                id="login"
                formControlName="login"
                placeholder="{{ 'AFFILIE_APZ.FORGOTTEN_PASSWORD.LOGIN_PROMPT' | translate }}"
              />
              <div *ngIf="forgottenPwdForm.get('login').touched && forgottenPwdForm.get('login').invalid">
                <small class="text-danger error-msg" *ngIf="forgottenPwdForm.get('login').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="text-danger  error-msg" *ngIf="forgottenPwdForm.get('login').errors.pattern">
                  {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
                </small>
              </div>
            </div>
            <!-- Submit -->
            <div class="pt-4 d-flex flex-row align-items-center justify-content-center">
              <button type="button" class="btn btn-primary btn-block" [disabled]="!forgottenPwdForm.valid || isLoading" (click)="submit()">
                <div class="d-flex flex-row align-items-center justify-content-center">
                  <div *ngIf="isLoading" class="spinner-border"></div>
                  <span class="ml-2" id="submit-btn-label">{{ 'AFFILIE_APZ.FORGOTTEN_PASSWORD.TITLE_BUTTON' | translate }}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
