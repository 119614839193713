<footer class="footer d-none d-md-block">
    <div class="container">
      <div class="row justify-content-center text-white">
        <div class="col-md-12 col-xl-4">
          <div class="row text-white">
            <div class="col">
                <a id="menuCgu" [routerLink]="['/cgu/', token]">{{ 'FOOTER.CGU' | translate }}</a>
            </div>

            <span> | </span>

            <div class="col">
                <a id="menuRgpd" (click)="openRgpd()" [routerLink]="">{{ 'FOOTER.RGPD' | translate }}</a>
            </div>

            <span> | </span>

            <div class="col">
                <a id="menuNotice" [routerLink]="['/notice/', token]">{{ 'FOOTER.NOTICE' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
</footer>
  