<div id="fourth-step-container" class="container-fluid vw-100 vh-100 p-0">
    <div class="d-flex flex-row h-100">
        <div id="menu-container" class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 d-none d-md-block p-0">
            <app-affilie-apz [step]="4"></app-affilie-apz>
        </div>
        <div id="step-container" class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 p-0">
            <div class="d-flex flex-column align-items-start justify-content-start w-100 h-100 p-3">
                <!-- HELLO -->
                <div class="d-flex flex-column align-items-start justify-content-center wrapper mt-5 mb-4">
                    <span id="fourth-step-title"
                        class="d-flex flex-row align-items-start justify-content-center mb-2">{{
                        'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FOURTH_STEP.HELLO' | translate
                        }}</span>
                    <span class="d-flex flex-row align-items-start justify-content-center fourth-step-sub-title mb-3">{{
                        'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FOURTH_STEP.HELLO_DESCRIPTION' | translate
                        }}</span>
                    <span class="d-flex flex-row align-items-start justify-content-center fourth-step-sub-title">{{
                        'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FOURTH_STEP.THANKS' | translate
                        }}</span>
                </div>
                <!-- LOGIN -->
                <div class="d-flex flex-row align-items-center justify-content-center">
                    <button class="btn btn-primary" (click)="goLogin()">
                        <span id="login-btn-label">{{ 'COMMON.USER.CONNECT' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
