<app-apz-layout [title]="'Modifier mon identifiant'" [saml]="saml">
  <div class="row justify-content-center py-3">
    <app-promo-app-mobile></app-promo-app-mobile>

    <div class="row blocChangeId justify-content-top ">
      <section class="col-12">
        <div *ngIf="errorMessage !== null" class="error" style="padding-top: 2.5vh;text-align: center;">
          {{ errorMessage }}
        </div>
      </section>
      <div class="container detail-container p-2 justify-content-top">
        <div class="pb-3 text-left" [hidden]="!isSecondStep">
          <p>
            {{ 'APZ.CHANGEMENT_ID.INTRUCTION_ID_PART2' | translate }}
          </p>
        </div>
        <div class="pb-3 text-left" [hidden]="isSecondStep">
          <p>
            {{ 'APZ.CHANGEMENT_ID.INTRUCTION_ID_PART1' | translate }}
          </p>
        </div>
        <!-- row pour la vue mobile -->
        <div>
          <form name="passwordForm" [formGroup]="identifiantForm" (submit)="submitForm()" class="row justify-content-center">
            <div class="col-12 col-sm text-left" [hidden]="isSecondStep">
              <div class="form-group row d-flex justify-content-center align-items-center">
                <div class="col-10">
                  <input
                    [type]="hide ? 'password' : 'text'"
                    class="form-control lock"
                    name="currentPassword"
                    formControlName="currentPassword"
                    placeholder="Mot de passe"
                  />
                </div>
                <div class="img-fluid mr-1 col-1" [ngClass]="hide ? 'hide' : 'show'" (click)="hide = !hide"></div>
                <div *ngIf="identifiantForm.get('currentPassword').touched && identifiantForm.get('currentPassword').invalid">
                  <small class="form-text text-danger" *ngIf="identifiantForm.get('currentPassword').errors.required">
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                  </small>
                </div>
                <small class="form-text text-danger" *ngIf="currentPasswordInvalid">
                  {{ 'APZ.CHANGEMENT_MDP.MDP_INCORRECT' | translate }}
                </small>
              </div>
            </div>
            <div class="col-12 col-sm text-left" [hidden]="!isSecondStep">
              <div class="form-group row d-flex justify-content-center align-items-center">
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control user"
                    name="newLogin"
                    formControlName="newLogin"
                    placeholder="Nouvel identifiant"
                  />
                </div>
                <div *ngIf="identifiantForm.get('newLogin').touched && identifiantForm.get('newLogin').invalid">
                  <small class="form-text text-danger" *ngIf="identifiantForm.get('newLogin').errors.required">
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                  </small>
                </div>
              </div>
            </div>
          </form>
          <div class="text-center pt-3 btn-change-id">
            <button class="btn btn-action" (click)="goToSecondStep()" type="submit" *ngIf="!isSecondStep">
              {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
            </button>
            <button class="btn btn-action" (click)="submitForm()" type="submit" *ngIf="isSecondStep">
              {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-apz-layout>
