<app-apz-layout [title]="'Je crée mon compte'" [saml]="saml">
  <div class="row justify-content-center pt-3 pb-5 pb-sm-3 ">
    <app-ouvre-apetiz></app-ouvre-apetiz>
    <div class="col carte text-center">
      <label>Bienvenue dans la communauté Apetiz !</label>
      <p>Nous vous demandons simplement quelques clics pour créer votre espace adhérent.</p>

      <app-first-step-apz-mobile-component
        (validate)="validateRelay($event)"
        (cancel)="cancelRelay()"
        [messageErreur]="messageErreur"
        [success]="success"
        [cardNumber]="cardNumber"
        [expirationDate]="expirationDate"
        [inlined]="true"
      >
      </app-first-step-apz-mobile-component>

      <p class="pt-sm-5">
        Vous n'avez pas reçu votre carte Apetiz ? <a class="redirect-link" [routerLink]="" (click)="goOldInscription()">Cliquez-ici</a> pour
        vous inscrire.
      </p>
    </div>
  </div>
</app-apz-layout>
