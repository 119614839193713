export interface Erreur {
  additionalInformation: any;
  message: string;
  code: string;
  description: string;
  attribute: string;
}

export interface Erreurs {
  errors: Erreur[];
}

export enum CodeErreurs {
  NOT_FOUND = 'NOT_FOUND',
  NOT_FOUND_025 = 'NOT_FOUND_025',
  NOT_FOUND_028 = 'NOT_FOUND_028',
  FORBIDDEN_ACTION_010 = 'FORBIDDEN_ACTION_010',
  FORBIDDEN_ACTION_040 = 'FORBIDDEN_ACTION_040',
  FORBIDDEN_ACTION_042 = 'FORBIDDEN_ACTION_042',
  INCORRECT_DATA_007 = 'INCORRECT_DATA_007',
  ALREADY_EXIST_022 = 'ALREADY_EXIST_022',
  IMPOSSIBLE_ACTION_013 = 'IMPOSSIBLE_ACTION_013',
  IMPOSSIBLE_ACTION_041 = 'IMPOSSIBLE_ACTION_041',
  UNEXPECTED_011 = 'UNEXPECTED_011',
  FORBIDDEN_ACTION_087 = 'FORBIDDEN_ACTION_087',
  NOT_PROCESSED_039 = 'NOT_PROCESSED_039',
}
