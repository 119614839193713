<div class="img-fluid div-login">
  <div *ngIf="!waiting" class="container">
    <div id="loginbox" class="d-block mx-auto">
      <div class="form-row text-center">
        <div class="col-12 ">
          <div class="img-fluid apetiz-logo"></div>
          <div class="title pt-5 pb-4">
            Mot de passe oublié
          </div>
          <div *ngIf="!success" class="subtitle">
            Choisissez votre nouveau mot de passe
          </div>
        </div>
        <div *ngIf="!lienInvalide && messageErreur" class="col-12 error pt-4 text-center">
          {{ messageErreur }}
        </div>
        <div *ngIf="lienInvalide" class="col-12 error pt-4 text-center">
          <div *ngIf="!isLoading">
            Le lien n'est plus valide : une nouvelle demande de modification de mot de passe est peut-être en cours.
            <br />
            <br />
            Le lien a expiré : la durée de validité du lien est de 2h, la demande de modification de mot de passe est peut-être antérieure à
            2h
            <br />
            <br />
            Pour recevoir un nouvel email avec un nouveau lien :
            <a [routerLink]="" (click)="recall()">cliquez-ici</a>
          </div>
          <div *ngIf="isLoading" class="d-block text-center">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
      <div *ngIf="!lienInvalide && !success">
        <form [formGroup]="passwordForm" (submit)="submit()">
          <section class="w-100 row py-5">
            <!-- MDP -->
            <div class="col-12 col-sm-7 align-self-center">
              <div class="form-group row d-flex justify-content-center align-items-center">
                <div class="col-10">
                  <input
                    [type]="hide ? 'password' : 'text'"
                    class="form-control lock"
                    name="password"
                    formControlName="password"
                    placeholder="Mot de passe"
                  />
                </div>
                <div class="img-fluid mr-1 col-1" [ngClass]="hide ? 'hide' : 'show'" (click)="hide = !hide"></div>
                <div *ngIf="passwordForm.get('password').touched && passwordForm.get('password').invalid">
                  <small class="form-text text-danger" *ngIf="passwordForm.get('password').errors.required">
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                  </small>
                </div>
              </div>
              <div class="form-group row d-flex justify-content-center align-items-center">
                <div class="col-10">
                  <input
                    [type]="hide2 ? 'password' : 'text'"
                    class="form-control lock"
                    name="confirmPassword"
                    formControlName="confirmPassword"
                    placeholder="Confirmation"
                    (keyup)="controlPassWord()"
                  />
                </div>
                <div class="img-fluid mr-1 col-1" [ngClass]="hide2 ? 'hide' : 'show'" (click)="hide2 = !hide2"></div>
                <div *ngIf="passwordForm.get('confirmPassword').touched">
                  <small
                    class="form-text text-danger"
                    *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required"
                  >
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                  </small>
                  <small class="form-text text-danger" *ngIf="!passwordEqual">
                    {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
                  </small>
                </div>
              </div>
            </div>
            <!-- Progress -->
            <div class="col-12 col-sm-5 infos-utilisateur">
              <p>{{ 'CREATION_ACCOUNT.SECOND_STEP.VALIDATION' | translate }}</p>
              <app-password-validation [passwordValue]="passwordForm.get('password').value" [affLastPassword]="false" [progressBar]="true">
              </app-password-validation>
            </div>
          </section>
          <div class="form-row text-center">
            <div class="col-12">
              <nit-spinner-button
                (clicked)="submit()"
                label="{{ 'COMMON.FORM.SUBMIT' | translate | uppercase }}"
                [disabled]="passwordForm.invalid || !passwordEqual"
                [isLoading]="isLoading"
                css="btn btn-action px-5"
              >
              </nit-spinner-button>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="success">
        <div class="row justify-content-center">
          <p class="subtitle ">
            Votre mot de passe a bien été changé
          </p>
        </div>
        <div class="form-row text-center pt-4">
          <div class="col-12">
            <button class="btn btn-action px-4" (click)="connect()">
              JE ME CONNECTE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="waiting" class="loader"></div>
</div>
