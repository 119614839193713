import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { TealiumService } from '../../../../shared/services/tealium/tealium.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-apz-web-change-pwd',
  templateUrl: './apz-web-change-pwd.component.html',
  styleUrls: ['./apz-web-change-pwd.component.scss', '../../../../../assets/css/apz-global.scss'],
})
export class ApzWebChangePwdComponent implements OnInit {

  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() retour: EventEmitter<any[]> = new EventEmitter();
  @Input() errorMessage: string;
  @Input() saml: string;
  @Input() passwordOk: boolean;
  @Input() login: string;

  passwordEqual = false;
  doNotMatch = false;

  private valueCaptcha: string;

  public oldPassword: FormControl;
  public password: FormControl;
  public confirm: FormControl;
    public ginsc: FormGroup;
    private applicationId: string;
    public redirectUrl: string;
    public messageErreur: string;
    hideOld = true;
    hideNew = true;
    hideConfirmNew = true;
    passwordForm = this.fb.group({
        currentPassword: [undefined, Validators.required],
        newPassword: [undefined, validatePassword()],
        confirmPassword: [undefined, validatePassword()],
    });

    constructor(private fb: FormBuilder, private siloApiService: SiloApiService,
        private gatewayService: GatewayService,
        private readonly tealiumService: TealiumService,
        private translateService: TranslateService,
        private readonly reCaptchaV3Service: ReCaptchaV3Service) {
    }

    ngOnInit(): void {
      this.tealiumService.view('apz.web.profile.changePasswordPageLoad');
    }

    submitForm() {
      this.tealiumService.link('apz.web.profile.validateChangePasswordChoosePasswordClick');
        if (this.passwordForm.valid && this.doNotMatch && this.passwordEqual) {
            this.submitted.emit(this.passwordForm.getRawValue());
        }
    }

    arePasswordsDifferent() {
        if (this.passwordForm.valid && this.passwordForm.get(['currentPassword']).value !== this.passwordForm.get(['newPassword']).value) {
            this.doNotMatch = true;
        } else {
            this.doNotMatch = null;
        }
    }

    isNewPasswordIsConfirmed() {
        this.passwordEqual = this.passwordForm.get('newPassword').value === this.passwordForm.get('confirmPassword').value;
        this.arePasswordsDifferent();
    }

    public validerUpdatePwd(f: NgForm): void {
        this.messageErreur = null;
        if (this.ginsc.valid) {
            this.updateUser(this.oldPassword.value, this.password.value);
        }
    }

    public updateUser(oldPwd, newPwd) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.reCaptchaV3Service
        .execute('ConnecterAction')
        .pipe(finalize(() => {
          this.siloApiService
          .updateUser(this.login, null, this.applicationId, oldPwd, newPwd, authToken, this.valueCaptcha)
          .subscribe(retour => { this.passwordOk = true; this.redirection(); }, error => this.gererErreurs(error))
        }))
        .subscribe((token) => (this.valueCaptcha = token));
      },
        err => {
          this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
        });

        /* this.gatewayService
            .getAccessToken()
            .subscribe(authToken => this.siloApiService
                .updateUser(this.login, null, this.applicationId, oldPwd, newPwd, authToken, this.valueCaptcha)
                .subscribe(retour => { this.passwordOk = true; this.redirection(); }, error => this.gererErreurs(error))); */
    }

    public redirection() {
        if (this.redirectUrl) {
            window.location.href = this.redirectUrl;
        }
    }
    public gererErreurs(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            this.messageErreur = 'Erreur inatendue : ' + error.error.message;
        } else {
            const errors: Erreurs = JSON.parse(error.error);
            switch (errors.errors[0].code) {
                case CodeErreurs.UNEXPECTED_011:
                    this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED_011');
                    break;
                case CodeErreurs.INCORRECT_DATA_007:
                    this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.INCORRECT_DATA_007');
                    break;
                case CodeErreurs.FORBIDDEN_ACTION_010:
                    this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.FORBIDDEN_ACTION_010');
                    break;
                default:
                    this.messageErreur = this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED')
                        + errors.errors[0].code;
                    break;
            }
        }
    }
}
