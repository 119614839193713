import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { version } from '../../../../../package.json';
import { Router } from '@angular/router';
import {TealiumService} from '@services/tealium/tealium.service';
import { ModalService } from '../../../shared/components/modal/modal.service';
import { GatewayService } from '../../../shared/services/gateway.service';
import { SiloApiService } from '../../../shared/services/siloApi.service';
import { finalize } from 'rxjs/operators';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-apz-login',
  templateUrl: './apz-login.component.html',
  styleUrls: ['./apz-login.component.scss', '../login.component.scss', '../../../../assets/css/apz-global.scss']
})
export class ApzLoginComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() messageErreur;
  @Input() isMobile: boolean;
  @Input() isSubmitted: boolean;
  @Input() isLoading: boolean;
  @Input() saml: string;

  @Output() triggerRegister: EventEmitter<any> = new EventEmitter();
  @Output() triggerForgotLogin: EventEmitter<any> = new EventEmitter();
  @Output() triggerForgotPassword: EventEmitter<any> = new EventEmitter();
  @Output() triggerSubmit: EventEmitter<any> = new EventEmitter();

  public login: FormControl;
  public password: FormControl;
  public ginsc: FormGroup;
  public version: string = version;
  public messageInfo: string;
  public rememberLogin: boolean;
  public knownLogin: string;
  public knownFirstName: string;
  public pageLoading = true;

  constructor(private readonly formBuilder: FormBuilder, private router: Router,
    private readonly tealiumService: TealiumService,
    private modalService: ModalService, private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService) {}

  ngOnInit(): void {
    this.login = this.formBuilder.control(undefined, Validators.required);
    this.password = this.formBuilder.control(undefined, Validators.required);
    this.ginsc = this.formBuilder.group([this.login, this.password]);
    this.knownLogin = localStorage.getItem('login');
    if (localStorage.getItem('accountAlreadyExist')) {
      this.messageInfo = localStorage.getItem('accountAlreadyExist');
    }
    if (this.knownLogin) {
      this.knownLogin = window.atob(this.knownLogin);
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.siloApiService
          .infosUser(undefined, undefined, this.knownLogin, undefined, authToken)
          .pipe(finalize(()=> this.pageLoading = false))
          .subscribe(
            (result) => {
              if (result.items && result.items.length > 0 && result.items[0].person) {
                this.knownFirstName = result.items[0].person.firstName;
                this.rememberLogin = true;
                this.login.setValue(this.knownLogin);
                if (this.knownLogin.includes('@')) {
                  const indexAt = this.knownLogin.indexOf('@');
                  let begin = this.knownLogin.substring(0, indexAt);
                  if (begin.length >= 5) {
                    begin = begin.substr(0,3) + '*'.repeat(begin.length - 3);
                  } else {
                    begin = begin.substr(0,1) + '*'.repeat(begin.length - 1);
                  }
                  const indexDomain = this.knownLogin.lastIndexOf('.');
                  let middle = this.knownLogin.substring(indexAt, indexDomain);
                  if (middle.length >= 3) {
                    middle = middle.substr(0,2) + '*'.repeat(middle.length - 3) + middle.substr(middle.length - 1);
                  }
                  this.knownLogin = begin + middle + this.knownLogin.substr(indexDomain);
                } else {
                  if (this.knownLogin.length >= 5) {
                    this.knownLogin = this.knownLogin.substr(0,3) + '*'.repeat(this.knownLogin.length - 3);
                  } else {
                    this.knownLogin = this.knownLogin.substr(0,1) + '*'.repeat(this.knownLogin.length - 1);
                  }
                }
              }
            },
            (error) => {

            },
          );
      });
    } else {
      this.pageLoading = false;
    }
  }

  ngAfterViewInit() {
    if (this.messageInfo) {
      this.modalService.open('infoModal');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      if (!this.isLoading && this.messageErreur) {
        this.modalService.open('errorModal');
      }
    }
  }

  connect(f: NgForm): void {
    this.isSubmitted = true;
    if (this.ginsc.valid) {
      this.triggerSubmit.emit({ login: this.login.value, password: this.password.value });
    }
  }

  saisirAutreLogin() {
    if (this.rememberLogin) {
      this.login.setValue(undefined);
      this.password.setValue(undefined);
      this.isSubmitted = false;
      this.rememberLogin = false;
    }
  }

  register() {
    this.triggerRegister.emit();
  }

  forgotLogin() {
    this.triggerForgotLogin.emit();
  }

  forgotPassword() {
    this.triggerForgotPassword.emit();
  }

  getMentionsLegales() {
    this.tealiumService.view('apz.web.login.mentionLegalLoad');
    this.router.navigateByUrl('/notice/' + this.saml);
  }

  getRGPD() {
    this.tealiumService.view('apz.web.login.rgpdLoad');
    this.router.navigateByUrl('/rgpd/' + this.saml);
  }

  getCGU() {
    this.tealiumService.view('apz.web.login.cguLoad');
    this.router.navigateByUrl('/cgu/' + this.saml);
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }
}
