export enum UserProfileTypeEnum {
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER'
}

export enum UpdateEmployeeContactTypeEnum {
  EMAIL_PRO = 'EMAIL_PRO',
  EMAIL_PERSO = 'EMAIL_PERSO',
  CELL_PHONE_PRO = 'CELL_PHONE_PRO',
  CELL_PHONE_PERSO = 'CELL_PHONE_PERSO',
  PHONE_PRO = 'PHONE_PRO',
}
