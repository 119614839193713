<div class="container detail-container p-2">
  <app-snackbar></app-snackbar>
  <div class="col-12 col-sm pb-3 text-center" [hidden]="!isSecondStep">
    <p>
      {{ 'APZ.CHANGEMENT_QUESTION.INTRUCTION_ID_PART2' | translate }}
    </p>
  </div>
  <div class="col-12 col-sm pb-3 text-center" [hidden]="isSecondStep">
    <p>
      {{ 'APZ.CHANGEMENT_QUESTION.INTRUCTION_ID_PART1' | translate }}<br />
      Saisissez votre mot de passe :
    </p>
  </div>
  <!-- row pour la vue mobile -->
  <div>
    <form name="passwordForm" [formGroup]="identifiantForm" (submit)="submitForm()" class="row justify-content-center">
      <div class="col-12 col-sm text-left" [hidden]="isSecondStep">
        <div class="form-group row d-flex justify-content-center align-items-center">
          <div class="col-10">
            <input
              [type]="hide ? 'password' : 'text'"
              class="form-control lock"
              name="currentPassword"
              formControlName="currentPassword"
              placeholder="Mot de passe"
            />
          </div>
          <div class="img-fluid mr-1 col-1" [ngClass]="hide ? 'hide' : 'show'" (click)="hide = !hide"></div>
          <div *ngIf="(isSubmitted || identifiantForm.get('currentPassword').touched) && identifiantForm.get('currentPassword').invalid">
            <small class="form-text text-danger" *ngIf="identifiantForm.get('currentPassword').errors.required">
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
          </div>
          <div class="w-100">
            <small class="form-text text-danger text-center" *ngIf="currentPasswordInvalid">
              {{ 'APZ.CHANGEMENT_MDP.MDP_INCORRECT' | translate }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm" [hidden]="!isSecondStep">
        <div *ngFor="let question of questions; let i = index" class=" row justify-content-center text-left">
          <div class="form-group col-12 col-sm-6">
            <div class=" custom-control custom-radio ">
              <input
                class="custom-control-input"
                type="radio"
                name="secretQuestion"
                id="{{ 'radio' + i }}"
                [value]="question.id"
                formControlName="secretQuestion"
              />
              <label class="custom-control-label question " for="{{ 'radio' + i }}">{{ question.label }}</label>
            </div>
          </div>
        </div>
        <div class="row justify-content-center py-4">
          <div class="col-12 col-sm-8">
            <input type="text" class="form-control " id="reponse" name="response" formControlName="response" placeholder="Ma réponse" />
            <div *ngIf="isSubmitted || identifiantForm.get('response').touched">
              <small
                class="form-text text-danger text-center"
                *ngIf="identifiantForm.get('response').errors && identifiantForm.get('response').errors.required"
              >
                {{ 'COMMON.FORM.REQUIRED' | translate }}
              </small>
              <small
                class="form-text text-danger text-center"
                *ngIf="identifiantForm.get('secretQuestion').errors && identifiantForm.get('secretQuestion').errors.required"
              >
                {{ 'COMMON.FORM.SECRET_QUESTION.REQUIRED' | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="text-center py-3">
      <nit-spinner-button
        *ngIf="!isSecondStep"
        (clicked)="goToSecondStep()"
        label="{{ 'CREATION_ACCOUNT.VALIDER' | translate | uppercase }}"
        [isLoading]="isLoading"
        css="btn btn-action px-5"
      >
      </nit-spinner-button>
      <nit-spinner-button
        *ngIf="isSecondStep"
        (clicked)="submitForm()"
        label="{{ 'CREATION_ACCOUNT.VALIDER' | translate | uppercase }}"
        [isLoading]="isLoading"
        css="btn btn-action px-5"
      >
      </nit-spinner-button>
    </div>
  </div>
</div>
