import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { ReadCitiesSignature } from '@shared/models/read-cities-signature.model';
import { CitiesDto } from '@shared/models/cities-dto.model';
import { AuthToken } from '@shared/models/authtoken.model';

@Injectable({
  providedIn: 'root',
})
export class ReferentialService {
  private url: string;

  constructor(readonly http: HttpClient) {
    this.url = '/NIT/' + (environment.localApi ? '' : 'referential/') + environment.versionApi + '/';
  }


  public readCities(signature: ReadCitiesSignature, token: AuthToken): Observable<CitiesDto> {
    let currentUrl = this.url;
    currentUrl += 'cities';

    if (signature.city || signature.postalCode || signature.sort) {
      currentUrl += '?';
    }
    if (signature.city) {
      currentUrl += '&city=' + signature.city;
    }
    if (signature.postalCode) {
      currentUrl += '&postalCode=' + signature.postalCode;
    }
    if (signature.sort) {
      currentUrl += '&sort=' + signature.sort;
    }
    const httpHeaders = this.getHttpHeader(token);

    return this.http.get<CitiesDto>(currentUrl, {headers: httpHeaders});
  }

  private getHttpHeader(token: AuthToken): HttpHeaders {
    return new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });
  }
}
