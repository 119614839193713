<div
    class="img-fluid"
    [ngClass]="{ 'apetiz-image': estAppetiz, 'cesu-image': estCesu, 'asp-image': !estAppetiz && !estCesu }"
    style="height: 100%; width: 100%"
>
    <div class="container">
        <div id="loginbox" style="max-width: 500px;" class="d-block mx-auto">
            <div class="form-row text-center">
                <div class="col-12 " style="padding-bottom: 4.5vh;">
                    <div
                        class="img-fluid"
                        [ngClass]="{
                            'apetiz-logo': estAppetiz,
                            'cesu-logo': estCesu,
                            'asp-logo': !estAppetiz && !estCesu
                        }"
                    ></div>
                    <div class="title" style="padding-top: 2.5vh;">
                        Saisie du code d'activation
                    </div>
                    <div class="subtitle" style="padding-top: 2.5vh;">
                        Vous avez reçu un code d'activation par SMS <br />
                        Saisissez le ci-dessous puis validez.
                    </div>
                </div>
                <div *ngIf="messageErreur !== null"  class="error">
                    {{ messageErreur }}
                </div>
            </div>
            <div>
                <form
                    #f="ngForm"
                    (submit)="validerInscription(f)"
                    [ngClass]="f.submitted ? 'was-validated' : 'needs-validation'"
                    novalidate
                >
                    <section [formGroup]="ginsc">
                        <div style="margin-bottom: 2.5vh">
                            <div class="input-group">
                                <input
                                    [formControl]="codeSMS"
                                    type="text"
                                    class="form-control input"
                                    placeholder="Code"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                />
                                <div *ngIf="f.submitted" class="invalid-feedback ">
                                    <div class="error">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                        >
                                            <defs>
                                                <style>
                                                    .a {
                                                        fill: none;
                                                        stroke: #b44b46;
                                                        stroke-width: 1.5px;
                                                    }
                                                    .b {
                                                        fill: #b44b46;
                                                    }
                                                </style>
                                            </defs>
                                            <g transform="translate(0 -0.5)">
                                                <circle
                                                    class="a"
                                                    cx="9.25"
                                                    cy="9.25"
                                                    r="9.25"
                                                    transform="translate(0.75 1.25)"
                                                />
                                                <path
                                                    class="b"
                                                    d="M9.363,12.63,9,5.35h2l-.364,7.28Zm1.6,1.37v1.792H9.041V14Z"
                                                />
                                            </g>
                                        </svg>
                                        Veuillez saisir votre code.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <br />
                    <br />
                    <div class="form-row text-center">
                        <div class="col-12">
                            <button type="submit" class="btn_prim" style="margin-bottom: 2.5vh">
                                VALIDER
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
