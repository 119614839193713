import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { ApplicationCodeEnum } from 'src/app/shared/enums/application-code.enum';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    saml: string;

    public applicationCode$: Observable<ApplicationCodeEnum>;

    public ApplicationCodeEnum = ApplicationCodeEnum;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly translateService: TranslateService,
        private readonly sessionQuery: SessionQuery,
        private readonly sessionStateService: SessionStateService,
    ) {
    }

    ngOnInit() {
        this.sessionStateService.setApplicationCode(this.route);

        this.applicationCode$ = this.sessionQuery.selectedApplicationCode
            .pipe(
                distinctUntilChanged(),
                filter(applicationCode => applicationCode && true),
            );

        this.sessionQuery.selectedSaml
            .pipe(
                distinctUntilChanged(),
                filter(saml => saml && true && saml !== this.saml),
                tap(saml => this.saml = saml),
            ).subscribe();
    }

    openRgpd() {
        if (this.translateService.currentLang === 'fr') {
            window.open('assets/pdf/noticeGDPR.pdf', '_blank');
        } else {
            window.open('assets/pdf/noticeGDPR_UK.pdf', '_blank');
        }
    }
}
