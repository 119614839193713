import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-info-siret',
  templateUrl: './info-siret.component.html',
  styleUrls: ['./info-siret.component.scss']
})
export class InfoSiretComponent implements OnInit {

  @Input() isStepOpen$: BehaviorSubject<boolean>;
  @Input() isLoading$: BehaviorSubject<boolean>
  @Input() saml: string;
  @Output() lookForAffilliate: EventEmitter<string> = new EventEmitter();

  public siretForm: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.siretForm = this.fb.group({ siret: ['', [Validators.required, Validators.pattern('[0-9]{14}')]] });
  }

  public validate(): void {
    this.lookForAffilliate.emit(this.siretForm.value.siret);
  }

  public formatSiret(dt: ClipboardEvent): void {
    if (dt && dt.clipboardData) {
      const reg: RegExp = new RegExp(' ', 'gu');
      const siret = dt.clipboardData.getData('text').replace(reg, '');
      this.siretForm.get('siret').setValue(siret);
      this.siretForm.updateValueAndValidity();
    }
  }

  public back(): void {
    this.router.navigateByUrl(`${RoutesEnum.LOGIN}/${this.saml}`);
  }

}
