<app-cesu-layout>
    <div class="row text-center pt-2 pt-md-4 pt-lg-5">
        <div class="col-12 cesu-page-title">
            {{ 'CESU.FORGOTTEN_LOGIN.TITLE' | translate }}
        </div>
        <hr class="cesu-separator"/>
        <div class="col-12" [innerHTML]="'CESU.FORGOTTEN_LOGIN.INFO' | translate">
        </div>
    </div>

    <form class="row text-center pt-4 pt-lg-5" [formGroup]="forgottenLoginForm" (ngSubmit)="sendForgottenLogin()">
        <div class="col-12 form-group">
            <div class="row no-gutters justify-content-center">
                <div class="col-12 col-md-8 col-lg-5">
                    <div *ngIf="errorMessage" class="alert alert-danger" [innerHTML]="errorMessage">
                    </div>
                    <input
                        class="form-control cesu-input"
                        [ngClass]="{ 'is-invalid': submitted && form.email.errors }"
                        type="text"
                        placeholder="{{ 'CESU.FORGOTTEN_LOGIN.PLACEHOLDER' | translate }}"
                        formControlName="email"
                    />
                    <div *ngIf="form.email.errors && submitted" class="invalid-feedback cesu-error">
                        <div *ngIf="form.email.errors.required">
                            <img src="../../../../assets/cesu/error_icon.svg"
                                 alt="Error"/>&nbsp;{{ 'COMMON.FORM.EMAIL.REQUIRED' | translate }}
                        </div>
                        <div *ngIf="form.email.errors.email">
                            <img src="../../../../assets/cesu/error_icon.svg"
                                 alt="Error"/>&nbsp;{{ 'COMMON.FORM.EMAIL.FORMAT' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-center pt-4">
            <button class="btn cesu-button cesu-flat-button"
                    type="submit">{{ 'CESU.FORGOTTEN_LOGIN.BUTTON' | translate }}</button>
        </div>
    </form>
</app-cesu-layout>
