<div class="spinner-loader-switch" *ngIf="isLoading">
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only"></span>
  </div>
</div>

<label class="switch">
  <input type="checkbox" [disabled]="disabled" [(ngModel)]="value" />
  <span class="slider round"></span>
</label>
