<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN">
    <app-cgu-asp [token]="saml" [applicationId]="applicationId"></app-cgu-asp>
</div>
<div
    *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ || (applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE">
    <app-cgu-apz [applicationId]="applicationId"></app-cgu-apz>
</div>
<div
    *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE
    || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR">
    <app-cgu-apz [applicationId]="applicationId"></app-cgu-apz>
</div>
<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
    <app-cesu-cgu></app-cesu-cgu>
</div>
<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.AFFILIE_APZ">
    <app-cgu-affilie-apz></app-cgu-affilie-apz>
</div>
