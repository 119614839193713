import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import { WeezenComponent } from '../../weezen/weezen.component';
// import { Favicons } from '../../../shared/favicons';
import { Router } from '@angular/router';
import {GatewayService} from '@services/gateway.service';
import {SiloApiService} from '@services/siloApi.service';
import {TranslateService} from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-notice-asp',
  templateUrl: './notice-asp.component.html',
  styleUrls: ['../../../../assets/css/asp-global.scss', './notice-asp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoticeAspComponent extends WeezenComponent implements OnInit {

  @Input() token: string;
  @Input() applicationId: 'SiteWeezen';
  notice: string;

  constructor(
    // fav: Favicons,
              private router: Router,
              private gatewayService: GatewayService,
              private siloApiService: SiloApiService,
              private translateService: TranslateService) {
    // super(fav);
    super();
  }

  ngOnInit() {
    this.gatewayService.getAccessToken() .subscribe(
      authToken => this.siloApiService.
      readLegalNotices(this.applicationId, authToken, this.translateService.currentLang)
        .subscribe((response) => {
          this.notice = response.content;
        })
    );
  }

  connection() {
    window.location.href = environment.redirectURIWee.replace('/token', '');
  }

}
