import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardDateMask'
})
export class CreditCardDateMaskPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value && value.length < 5 ? value.replace(/(\/)/g, '').replace(/(.{2})(.*)/g, '$1/$2')
      : value;
  }

}
