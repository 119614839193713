<div id="conecs-content">
    
    <app-info-card 
    [title]="title"
    [text]="text"
    ></app-info-card>
    <div class="mt-4">
        <form [formGroup]="formGroup" (onSubmit)="onSubmit()">
            <div class="form-group">
                <label class="form-label" for="conecs">
                    {{
                        "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CONECS.FORM.LABEL"
                          | translate
                      }} 
                </label>
                <div class="input-group conecs-input"
                [ngClass]="{'invalid-input': isBadConecs || (formGroup.get('conecs').invalid && formGroup.get('conecs').dirty)}">
                    <div class="input-group-prepend conecs-prefix">
                        <span class="input-group-text">C</span>
                    </div>
                    <input
                    class="form-control"
                    type="text"
                    formControlName="conecs"
                    placeholder="{{
                        'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CONECS.FORM.PLACEHOLDER'
                          | translate
                      }}"
                    />
                    
                </div>
                <div class="mx-2">
                    <div>
                        <small
                        class="text-error"
                        *ngIf="formGroup.get('conecs').errors?.pattern"
                    >
                        {{
                        "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CONECS.FORM.ERRORS.PATTERN"
                            | translate
                        }}
                    </small>
                    </div>
                    <div>
                        <small
                        class="text-error"
                        *ngIf="formGroup.get('conecs').errors?.maxlength"
                    >
                        {{
                        "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CONECS.FORM.ERRORS.MAXLENGTH"
                            | translate
                        }}
                    </small>
                    </div>
                    <div>
                        <small
                        class="text-error"
                        *ngIf="formGroup.get('conecs').errors?.required && formGroup.get('conecs').dirty"
                    >
                        {{
                        "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CONECS.FORM.ERRORS.REQUIRED"
                            | translate
                        }}
                    </small>
                    </div>
                    <div>
                        <small
                        class="text-error"
                        *ngIf="isBadConecs"
                    >
                        {{
                        "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CONECS.FORM.ERRORS.BAD_CONECS"
                            | translate
                        }}
                    </small>
                    </div>  
                </div>
            </div>

            <app-full-width-btn
            [text]="'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CONECS.FORM.BUTTON'"
            [disabled]="!formGroup.valid"
            (click$)="onSubmit()"
            
            ></app-full-width-btn>
        </form>
    </div>
    
</div>