import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { LoginService } from '@services/login/login.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { Affiliate } from '@shared/models/affiliate';
import { AuthToken } from '@shared/models/authtoken.model';
import { CreateTermsAndConditions } from '@shared/models/createTermsAndConditions.model';
import { MerchantsInvite } from '@shared/models/merchant-invite';
import { UserAffiliateCreateInviteLink } from '@shared/models/user-affiliate-create-invite-link';
import { MustMatch } from '@shared/validators/custom.validators';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';

const CODE_ERROR_DUPLICATE_LOGIN = 'ALREADY_EXIST_022';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit, OnDestroy {
  public isStepOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth > 576 ? false : true);
  public affiliate: Affiliate;
  public contactForm: FormGroup;
  public showConfirmPassword = false;
  public showPassword = false;
  public submittedForm = false;
  public saml: string;
  public isLoading = false;
  public isPanelOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLargePanel = false;
  public token: AuthToken;
  private applicationId: string;
  private hash: string;

  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly siloApiService: SiloApiService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sessionStateService: SessionStateService,
    private readonly sessionQuery: SessionQuery,
    private readonly gtds: GroundTruthDataService,
    private readonly loginService: LoginService,
    private readonly gatewayService: GatewayService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactForm = this.fb.group(
      {
        role: new FormControl('Proprietaire', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
        firstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z][a-zA-Z ]{1,38}$')]),
        lastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z][a-zA-Z ]{1,38}$')]),
        phoneNumber: new FormControl('', [
          Validators.minLength(1),
          Validators.maxLength(20),
          Validators.pattern('^(\\+33\\s?|0)[1-5](\\s?\\d{2}){4}$')
        ]),
        cellPhoneNumber: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
          Validators.pattern('^(\\+33\\s?|0)[67](\\s?\\d{2}){4}$')
        ]),
        password: ['', validatePassword()],
        confirmPassword: ['', Validators.required]
      },
      {
        validator: MustMatch('password', 'confirmPassword')
      }
    );
  }

  ngOnInit(): void {
    this.gtds
      .getIsSpinnerActivated()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.sessionStateService.setApplicationCodeFromMerchantsInvite(this.route);
    this.sessionStateService.setApplicationCode(this.route);

    this.sessionQuery.selectedApplicationCode
      .pipe(tap((applicationCode: ApplicationCodeEnum) => (this.applicationId = applicationCode)))
      .subscribe();

    this.gatewayService
      .getAccessToken()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((token) => {
          return this.sessionQuery.selectedMerchantsInvite.pipe(
            takeUntil(this.destroy$),
            switchMap((merchantsInvite: MerchantsInvite) => {
              this.hash = merchantsInvite.hash;
              this.contactForm.get('email').setValue(merchantsInvite.email);
              this.contactForm.get('email').updateValueAndValidity();
              this.saml = merchantsInvite.saml;
              let affiliteNumber = '';
              let compensating = '';
              const affiliateIdSplited = merchantsInvite?.affiliateId?.split('-');
              if (affiliateIdSplited && affiliateIdSplited?.length) {
                affiliteNumber = affiliateIdSplited[0];
                compensating = affiliateIdSplited[1];
              }
              return this.siloApiService.readAffiliatesByNumberAndCompensating(token, affiliteNumber, compensating);
            }),
            tap((res) => {
              this.affiliate = res?.length && res[0];
              if (this.affiliate?.managers?.length) {
                // redirection vers la mire de connexion
                this.router.navigate([`${RoutesEnum.MERCHANTS_INVITE_ACCOUNT_EXISTS}/${this.saml}`]);
              }
            })
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public openSwileGDPRNotice(): void {
    window.open('https://www.swile.co/fr-fr/legal/privacy', '_blank');
  }

  public openBimpliGDPRNotice(): void {
    window.open('https://www.bimpli.com/protections-des-donnees-personnelles/', '_blank');
  }

  public submitForm(): void {
    const formValue = this.contactForm.getRawValue();
    const userAffiliateCreate: UserAffiliateCreateInviteLink = {
      token: this.hash,
      login: formValue.email,
      email: formValue.email,
      password: formValue.password,
      applicationId: this.applicationId,
      title: 'MR',
      lastName: formValue?.lastName,
      firstName: formValue?.firstName,
      affiliateProfil: {
        affiliateId: this.affiliate.id,
        profil: 'AFF_ADMINISTRATEUR'
      },

      phoneNumber: formValue.phoneNumber ? formValue.phoneNumber : null,
      cellPhoneNumber: formValue.cellPhoneNumber,
      role: formValue?.role
    };
    this.gatewayService
      .getAccessToken()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((token) => {
          return this.siloApiService.createUserAffiliateFromLink(userAffiliateCreate, token).pipe(
            catchError((err) => {
              this.handleError(err);
              throw err;
            }),
            switchMap((userCreated) => {
              return this.createTermsAndConditionsAcceptances(userCreated?.id, token);
            }),
            switchMap(() => {
              return this.reCaptchaV3Service.execute('ConnecterAction');
            }),
            switchMap((aCaptcha) => {
              return this.loginService.authenticateAffiliateUser(formValue.email, formValue.password, token, aCaptcha);
            })
          );
        })
      )
      .subscribe();
  }

  private createTermsAndConditionsAcceptances(userId: number, aToken: AuthToken): Observable<string> {
    const body: CreateTermsAndConditions = {
      profileType: 'AFFILIATE',
      applicationId: this.applicationId
    };

    return this.siloApiService.createTermsAndConditionsAcceptances(userId, body, aToken).pipe(
      takeUntil(this.destroy$),
      catchError((err) => {
        this.handleError(err);
        throw err;
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error?.error?.errors[0].code === CODE_ERROR_DUPLICATE_LOGIN && this.applicationId === ApplicationCodeEnum.AFFILIE_APZ) {
      // redirection vers la mire de connexion
      this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`], {
        queryParams: { isKnowAffiliate: true }
      });
    } else {
      // redirection erreur technique
      this.router.navigate([`${RoutesEnum.TECHNICAL_ERROR}/${this.saml}`]);
    }
  }
}
