import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { AuthToken } from '@shared/models/authtoken.model';
import { AffiliateProfil, CreateTermsAndConditions } from 'nit-angular-lib';
import { UserAffiliateCreate } from '@shared/models/userAffiliateCreate.model';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AffiliateSignupService } from '../service/affiliate-signup.service';

const CODE_ERROR_DUPLICATE_LOGIN = 'ALREADY_EXIST_022';

@Component({
  selector: 'app-third-step',
  templateUrl: './third-step.component.html',
  styleUrls: ['./third-step.component.scss']
})
export class ThirdStepComponent implements OnInit, OnDestroy {
  public saml: string;
  public success: boolean;
  public messageErreur: string;
  public accountForm: FormGroup;
  public token: AuthToken;

  private readonly destroy$ = new Subject<boolean>();
  private applicationId: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionStateService: SessionStateService,
    private readonly sessionQuery: SessionQuery,
    private readonly router: Router,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly translate: TranslateService,
    private readonly gtds: GroundTruthDataService,
    private readonly affiliateSignupService: AffiliateSignupService
  ) {
    this.accountForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.success = true;
    this.sessionStateService.setApplicationCode(this.route);

    this.sessionQuery.selectedApplicationCode
      .pipe(tap((applicationCode: ApplicationCodeEnum) => (this.applicationId = applicationCode)))
      .subscribe();

    this.sessionQuery.selectedSaml
      .pipe(
        takeUntil(this.destroy$),
        tap((saml) => (this.saml = saml))
      )
      .subscribe();
  }

  public goBack(): void {
    this.router.navigateByUrl(`${RoutesEnum.SIGNUP_AFFILIE_SECOND_STEP}/${this.saml}`);
  }

  public createAccount(): void {
    this.gtds.setIsSpinnerActivated(true);
    const accountData = this.accountForm.getRawValue()?.account;
    this.gatewayService
      .getAccessToken()
      .pipe(
        finalize(() => this.gtds.setIsSpinnerActivated(false)),
        takeUntil(this.destroy$),
        switchMap((token) => {
          this.token = token;
          return this.createUserAffiliate(accountData?.login, accountData?.password);
        })
      )
      .subscribe(() => {
        this.success = true;
        this.router.navigateByUrl(`${RoutesEnum.SIGNUP_AFFILIE_FOURTH_STEP}/${this.saml}`);
      });
  }

  private createUserAffiliate(email: string, password: string): Observable<string> {
    const coordonnes = this.affiliateSignupService.coordonnee;
    const affiliateData = this.affiliateSignupService.selectedAffiliates;
    const affiliateProfils: AffiliateProfil[] = [];
    affiliateData?.forEach((affiliate) =>
      affiliateProfils.push({
        affiliateId: affiliate?.id,
        profil: 'AFF_ADMINISTRATEUR'
      })
    );
    const userAffiliateCreate: UserAffiliateCreate = {
      login: email,
      email,
      password,
      applicationId: this.applicationId,
      title: coordonnes?.civility,
      lastName: coordonnes?.lastName,
      firstName: coordonnes?.firstName,
      affiliateProfils,
      phoneNumber: null,
      cellPhoneNumber: null,
      role: coordonnes?.role
    };

    if (coordonnes?.phoneNumber) {
      userAffiliateCreate.phoneNumber = coordonnes?.phoneNumber;
    } else {
      delete userAffiliateCreate.phoneNumber;
    }

    if (coordonnes?.cellPhoneNumber) {
      userAffiliateCreate.cellPhoneNumber = coordonnes?.cellPhoneNumber;
    } else {
      delete userAffiliateCreate.cellPhoneNumber;
    }

    return this.siloApiService.createUserAffiliate(this.token, userAffiliateCreate).pipe(
      takeUntil(this.destroy$),
      switchMap((userCreated) => {
        this.affiliateSignupService.userId = userCreated?.id;
        this.affiliateSignupService.email = userAffiliateCreate?.email;
        localStorage.setItem('id', userCreated?.id?.toString());
        return this.createTermsAndConditionsAcceptances(userCreated?.id);
      }),
      catchError((err) => {
        this.handleError(err);
        throw err;
      })
    );
  }

  private createTermsAndConditionsAcceptances(userId: number): Observable<string> {
    const body: CreateTermsAndConditions = {
      profileType: 'AFFILIATE',
      applicationId: this.applicationId
    };

    return this.siloApiService.createTermsAndConditionsAcceptances(userId, body, this.token).pipe(
      takeUntil(this.destroy$),
      catchError((err) => {
        this.handleError(err);
        throw err;
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    this.success = false;
    if (error?.error?.errors === undefined) {
      this.messageErreur = error.message;
    } else {
      if (error?.error?.errors[0].code === CODE_ERROR_DUPLICATE_LOGIN && this.applicationId === ApplicationCodeEnum.AFFILIE_APZ) {
        this.messageErreur = this.translate.instant('AFFILIE_APZ.SIGNUP_AFFILIE.ERROR.LOGIN_ALREADY_EXIST');
      } else {
        this.messageErreur = this.translate.instant('AFFILIE_APZ.SIGNUP_AFFILIE.ERROR.RETRY');
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
