import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { CesuStateService } from '@services/store/cesu/cesu-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { CesuRegistrationStatusEnum } from '@shared/enums/cesu.enum';
import { UpdateEmployeeContactTypeEnum, UserProfileTypeEnum } from '@shared/enums/siloApi.enum';
import { AuthToken } from '@shared/models/authtoken.model';
import { EmployeeResponse } from '@shared/models/employeeResponse.model';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { UserCreated } from '@shared/models/user-created.model';
import { UserResponse } from '@shared/models/userResponse.model';
import { combineLatest, Observable } from 'rxjs';
import { catchError, concatMap, map, tap, switchMap } from 'rxjs/operators';
import {ReCaptchaV3Service} from "ng-recaptcha";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(
    private readonly gatewayService: GatewayService,
    private readonly siloApiService: SiloApiService,
    private readonly translateService: TranslateService,
    private readonly sessionQuery: SessionQuery,
    private readonly cesuStateService: CesuStateService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  authToken: AuthToken;
  userResponse: UserResponse;
  employeeResponse: EmployeeResponse;
  addUserProfile: any;

  private cesuRegistration(userResponse: UserResponse, employeeResponse: EmployeeResponse): void {
    if (employeeResponse.items.length !== 0) {
      this.cesuStateService.setCesuRegistrationEmployeeId(employeeResponse.items[0].id);
    }

    if (userResponse.items.length === 0 && employeeResponse.items.length !== 0) {
      this.cesuStateService.setCesuRegistrationStatus(CesuRegistrationStatusEnum.CREATE_ACCOUNT);
    } else if (userResponse.items.length !== 0 && employeeResponse.items.length !== 0) {
      if (userResponse.items[0].profiles.find((profile) => profile.applicationId === ApplicationCodeEnum.APZ)) {
        this.cesuStateService.setCesuRegistrationStatus(CesuRegistrationStatusEnum.APETIZ);
      } else if (userResponse.items[0].profiles.find((profile) => profile.applicationId === ApplicationCodeEnum.CESU_GRD_COMPTE)) {
        this.cesuStateService.setCesuRegistrationStatus(CesuRegistrationStatusEnum.CESU_GRD_COMPTE);
      } else if (userResponse.items[0].profiles.find((profile) => profile.applicationId === ApplicationCodeEnum.CESU)) {
        this.cesuStateService.setCesuRegistrationStatus(CesuRegistrationStatusEnum.CESU_DOMALIN);
        return;
      } else {
        this.cesuStateService.setCesuRegistrationStatus(CesuRegistrationStatusEnum.APETIZ);
      }

      this.gatewayService
        .getAccessToken()
        .pipe(
          concatMap((authToken: AuthToken) => {
            return this.siloApiService
              .addUserProfile(
                authToken,
                userResponse.items[0].id,
                this.sessionQuery.getValue().applicationCode,
                UserProfileTypeEnum.EMPLOYEE
              )
              .pipe(
                catchError((err) => {
                  throw err;
                })
              );
          }),
          catchError((err) => {
            throw err;
          })
        )
        .subscribe();
    } else {
      throw Error;
    }
  }

  /**
   * Use the user personal number to start the registration process
   * @param employeePersonalCode the user's personal number
   * @param birthDate the user's date of birth
   * @param productId the id of the product the user is trying to register to
   */
  public registrationPersonalNumber(employeePersonalCode: string, birthDate: Date, productId: string) {
    return this.gatewayService.getAccessToken().pipe(
      concatMap((token: AuthToken) => {
        return this.siloApiService
          .readUserByCriteria(token, {
            employeePersonalCode,
            birthDate,
            productId
          })
          .pipe(
            concatMap((userResponse: UserResponse) => {
              return this.reCaptchaV3Service.execute('GetEmployeesPersonalAction').pipe(
                concatMap((captcha: string) => {
                  return this.siloApiService.infosEmployeesPersonalNumber(token, employeePersonalCode, birthDate, productId, captcha).pipe(
                    tap((employeeResponse: EmployeeResponse) => {
                      // If the user has no employee data, the user cannot access the CESU application
                      if (employeeResponse.items.length === 0 || !employeeResponse.items[0].id) {
                        throw new Error();
                      }

                      if (this.sessionQuery.getValue().applicationCode === ApplicationCodeEnum.CESU) {
                        this.cesuRegistration(userResponse, employeeResponse);
                      }
                    }),
                    catchError((err) => {
                      throw err;
                    })
                  );
                }),
                catchError((err) => {
                  throw err;
                })
              );
            })
          );
      }),
      catchError(() => {
        throw new Error(this.translateService.instant('ERROR.REGISTRATION.FIRST_STEP.PERSONAL_NUMBER'));
      })
    );
  }

  /**
   * Check if the login selected by the user is still available
   * @param login the login to check
   * @return true if the login is not used, false if the login is used
   */
  public checkLogin(login: string, captchaResponse: string): Observable<boolean> {
    return this.gatewayService.getAccessToken().pipe(
      concatMap((authToken: AuthToken) => {
        return this.siloApiService
          .readUserByCriteria(
            authToken,
            {
              login
            },
            captchaResponse
          )
          .pipe(
            map((res) => {
              return res.items.length === 0;
            })
          );
      })
    );
  }

  public createAccountAndUpdatePersonalInformation(
    login: string,
    password: string,
    typeLogin: string,
    employeeId: string,
    personalMail: string,
    personalPhone: string,
    captcha: string,
    addProfiles?: boolean,
    aApplicationCode?: ApplicationCodeEnum
  ) {
    return this.gatewayService.getAccessToken().pipe(
      concatMap((authToken: AuthToken) => {
        return this.siloApiService
          .createUserAccount(
            login,
            password,
            typeLogin,
            authToken,
            employeeId,
            aApplicationCode ? aApplicationCode : this.sessionQuery.getValue().applicationCode,
            captcha
          )
          .pipe(
            concatMap((userCreated: UserCreated) => {
              let userProfile;
              let termsAndConditions;
              let updateEmail;
              let updatePhone;

              if (addProfiles) {
                userProfile = this.siloApiService
                  .addUserProfile(authToken, userCreated.id.toString(), ApplicationCodeEnum.CESU, UserProfileTypeEnum.EMPLOYEE)
                  .pipe(
                    catchError((err) => {
                      throw err;
                    })
                  )
                  .subscribe();
              }

              termsAndConditions = this.siloApiService
                .createTermsAndConditionsAcceptances(
                  userCreated.id,
                  {
                    profileType: 'EMPLOYEE',
                    applicationId: this.sessionQuery.getValue().applicationCode
                  },
                  authToken
                )
                .pipe(
                  catchError((err) => {
                    throw err;
                  })
                )
                .subscribe();

              if (personalMail) {
                updateEmail = this.siloApiService
                  .updateEmployeeContacts(authToken, employeeId, UpdateEmployeeContactTypeEnum.EMAIL_PERSO, personalMail)
                  .pipe(
                    catchError((err) => {
                      throw err;
                    })
                  )
                  .subscribe();
              }

              if (personalPhone) {
                updatePhone = this.siloApiService
                  .updateEmployeeContacts(authToken, employeeId, UpdateEmployeeContactTypeEnum.CELL_PHONE_PERSO, personalPhone)
                  .pipe(
                    catchError((err) => {
                      throw err;
                    })
                  )
                  .subscribe();
              }

              return [userCreated, userProfile, termsAndConditions, updateEmail, updatePhone];
            }),
            catchError((err) => {
              throw err;
            })
          );
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  /**
   * Use the user personal number to start the registration process
   * @param employeePersonalCode the user's personal number
   * @param birthDate the user's date of birth
   * @param productId the id of the product the user is trying to register to
   */
  public newRegistrationPersonalNumber(employeePersonalCode: string, birthDate: Date, productId: string, captchaResponse: string) {
    return this.gatewayService.getAccessToken().pipe(
      concatMap((authToken: AuthToken) => {
        this.authToken = authToken;

        const getUserResponse$ = this.siloApiService
          .readUserByCriteria(
            this.authToken,
            {
              employeePersonalCode,
              birthDate,
              productId
            },
            captchaResponse
          )
          .pipe(
            tap((user: UserResponse) => {
              this.userResponse = user;
            })
          );

        const getEmployeeResponse$ = this.reCaptchaV3Service.execute('CreationAccountAction').pipe(
          concatMap((token: string) => {
            return this.siloApiService.infosEmployeesPersonalNumber(this.authToken, employeePersonalCode, birthDate, productId, token).pipe(
              tap((employee: EmployeeResponse) => {
                // If the user has no employee data, the user cannot access the CESU application
                if (employee.items.length === 0 || !employee.items[0].id) {
                  throw new Error(this.translateService.instant('ERROR.REGISTRATION.FIRST_STEP.PERSONAL_NUMBER'));
                }
                this.employeeResponse = employee;
              })
            );
          })
        );

        // On combine l'ensemble des observable afin de pouvoir afficher la page
        return combineLatest([getEmployeeResponse$, getUserResponse$]).pipe(
          tap(() => {
            if (this.employeeResponse.items.length !== 0) {
              this.cesuStateService.setCesuRegistrationEmployeeId(this.employeeResponse.items[0].id);
              localStorage.setItem('registrationEmployeeId', this.employeeResponse.items[0].id);
            }

            if (this.userResponse.items.length === 0 && this.employeeResponse.items.length !== 0) {
              this.cesuStateService.setCesuRegistrationStatus(CesuRegistrationStatusEnum.CREATE_ACCOUNT);
            } else if (this.userResponse.items.length !== 0 && this.employeeResponse.items.length !== 0) {
              if (
                this.userResponse.items[0].profiles.find(
                  (profile) =>
                    profile.applicationId === ApplicationCodeEnum.APZ ||
                    profile.applicationId === ApplicationCodeEnum.CESU_GRD_COMPTE ||
                    profile.applicationId === ApplicationCodeEnum.CESU
                )
              ) {
                this.cesuStateService.setCesuRegistrationStatus(CesuRegistrationStatusEnum.CESU_DOMALIN);
                if (this.userResponse.items[0].active === 'PENDING_ACTIVATION') {
                  localStorage.setItem('id', this.userResponse.items[0].id);
                  throw new Error('COMPTE_PENDING_ACIVATION');
                } else {
                  throw new Error('COMPTE_ALREADY_EXIST');
                }
              } else {
                this.cesuStateService.setCesuRegistrationStatus(CesuRegistrationStatusEnum.APETIZ);
              }

              this.siloApiService
                .addUserProfile(authToken, this.userResponse.items[0].id, ApplicationCodeEnum.CESU, UserProfileTypeEnum.EMPLOYEE)
                .pipe(
                  catchError((err) => {
                    throw err;
                  })
                );
            }
          }),
          catchError((err: Error) => {
            throw new Error(err.message);
          })
        );
      })
    );
  }
}
