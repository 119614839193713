<app-apz-layout [title]="'Ma question secrète'" [saml]="saml">
  <form class="row justify-content-center py-3" name="passwordForm" [formGroup]="identifiantForm">
    <!-- encart gauche -->
    <app-ouvre-apetiz></app-ouvre-apetiz>

    <!-- encart droite -->
    <section class="col carte">
      <app-snackbar></app-snackbar>
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8">
          <p class="px-5 text-center">
            {{ 'APZ.CHANGEMENT_QUESTION.INTRUCTION_ID_PART1' | translate }}<br />
            Saisissez votre mot de passe :
          </p>
        </div>
      </div>
      <div class="row justify-content-center py-4">
        <div class="col-12 col-sm-8 ">
          <div class="form-group row d-flex justify-content-center align-items-center">
            <div class="col-10">
              <input
                [type]="hide ? 'password' : 'text'"
                class="form-control lock"
                name="currentPassword"
                formControlName="currentPassword"
                placeholder="Mot de passe"
              />
            </div>
            <div class="img-fluid mr-1 col-1" [ngClass]="hide ? 'hide' : 'show'" (click)="hide = !hide"></div>
            <div *ngIf="(isSubmitted || identifiantForm.get('currentPassword').touched) && identifiantForm.get('currentPassword').invalid">
              <small class="form-text text-danger" *ngIf="identifiantForm.get('currentPassword').errors.required">
                {{ 'COMMON.FORM.REQUIRED' | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center pb-3">
        <div class="col-12 col-sm-8">
          <p class="px-5 text-center">
            {{ 'APZ.CHANGEMENT_QUESTION.INTRUCTION_ID_PART2' | translate }}
          </p>
        </div>
      </div>
      <div class="col-12 col-sm">
        <div *ngFor="let question of questions; let i = index" class=" row justify-content-center text-left">
          <div class="form-group col-12 col-sm-6">
            <div class=" custom-control custom-radio ">
              <input
                class="custom-control-input"
                type="radio"
                name="secretQuestion"
                id="{{ 'radio' + i }}"
                [value]="question.id"
                formControlName="secretQuestion"
              />
              <label class="custom-control-label question " for="{{ 'radio' + i }}">{{ question.label }}</label>
            </div>
          </div>
        </div>
        <div class="row justify-content-center py-4">
          <div class="col-12 col-sm-8">
            <input type="text" class="form-control " id="reponse" name="response" formControlName="response" placeholder="Ma réponse" />
            <div *ngIf="isSubmitted || identifiantForm.get('response').touched">
              <small
                class="form-text text-danger text-center"
                *ngIf="identifiantForm.get('response').errors && identifiantForm.get('response').errors.required"
              >
                {{ 'COMMON.FORM.REQUIRED' | translate }}
              </small>
              <small
                class="form-text text-danger text-center"
                *ngIf="identifiantForm.get('secretQuestion').errors && identifiantForm.get('secretQuestion').errors.required"
              >
                {{ 'COMMON.FORM.SECRET_QUESTION.REQUIRED' | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center py-3">
        <nit-spinner-button
          (clicked)="submitForm()"
          label="{{ 'CREATION_ACCOUNT.VALIDER' | translate | uppercase }}"
          [isLoading]="isLoading"
          css="btn btn-action px-5"
        >
        </nit-spinner-button>
      </div>
    </section>
  </form>
</app-apz-layout>
