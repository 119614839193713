import { Injectable } from '@angular/core';
import { AuthToken } from '@shared/models/authtoken.model';
import { CheckUserAffiliateAccessRequest } from '@shared/models/check-user-affiliate-access-request';
import { CheckUserAffiliateAccessResponse } from '@shared/models/check-user-affiliate-access-response';
import { Observable } from 'rxjs';
import { MerchantsSignupService } from '../merchants-signup.service';

@Injectable({
  providedIn: 'root'
})
export class CheckAffiliateAccessService {

  constructor(private readonly merchantsSignupService: MerchantsSignupService) {}

  public checkAffiliateAccess(request: CheckUserAffiliateAccessRequest, token: AuthToken):
  Observable<CheckUserAffiliateAccessResponse> {
    return this.merchantsSignupService.checkAffiliateAccess(request, token);
  }
}
