import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { CELL_PHONE_NUMBER, CIVILITY, FIRSTNAME, LASTNAME, PHONE_NUMBER } from '../../affilie-apz/affilie-apz.component';

@Component({
  selector: 'app-identity-affilie-apz',
  templateUrl: './identity-affilie-apz.component.html',
  styleUrls: ['../../../../../assets/css/affilie-apz-global.scss', './identity-affilie-apz.component.scss']
})
export class IdentityAffilieApzComponent {
  // Variables
  @Input() saml: string;
  coordonneesForm: FormGroup = this.fb.group({
    civility: [localStorage.getItem(CIVILITY) ? localStorage.getItem(CIVILITY) : 'MR'],
    firstName: [localStorage.getItem(FIRSTNAME) ? localStorage.getItem(FIRSTNAME) : '', Validators.required],
    lastName: [localStorage.getItem(LASTNAME) ? localStorage.getItem(LASTNAME) : '', Validators.required],
    phoneNumber: [
      localStorage.getItem(PHONE_NUMBER) ? localStorage.getItem(PHONE_NUMBER) : '',
      [Validators.minLength(1), Validators.maxLength(20), Validators.pattern('^[0][0-9]{9}$|^[+][0-9]{1,4}[0-9]{1,16}$')]
    ],
    cellPhoneNumber: [
      localStorage.getItem(CELL_PHONE_NUMBER) ? localStorage.getItem(CELL_PHONE_NUMBER) : '',
      [Validators.minLength(1), Validators.maxLength(20), Validators.pattern('^[0][0-9]{9}$|^[+][0-9]{1,4}[0-9]{1,16}$')]
    ]
  });
  isSubmitted = false;

  // Constructor
  constructor(private fb: FormBuilder, private readonly router: Router) {}

  // Methods
  nextStep(): void {
    if (this.coordonneesForm.valid) {
      this.isSubmitted = true;
      localStorage.setItem(CIVILITY, this.coordonneesForm.value.civility);
      localStorage.setItem(FIRSTNAME, this.coordonneesForm.value.firstName);
      localStorage.setItem(LASTNAME, this.coordonneesForm.value.lastName);
      localStorage.setItem(PHONE_NUMBER, this.coordonneesForm.value.phoneNumber);
      localStorage.setItem(CELL_PHONE_NUMBER, this.coordonneesForm.value.cellPhoneNumber);
      this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/${this.saml}`);
    }
  }

  goBack(): void {
    this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_FIRST_STEP}/${this.saml}`);
    sessionStorage.setItem('BACK_FROM_CONTACT', 'true');
  }
}
