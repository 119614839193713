import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TealiumService } from '@services/tealium/tealium.service';
import { ModalService } from '@shared/components/modal/modal.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';

@Component({
  selector: 'app-third-step-bimpli-apetiz',
  templateUrl: './third-step-bimpli-apetiz.component.html',
  styleUrls: ['./third-step-bimpli-apetiz.component.scss', '../../../../../assets/css/bimpli-global.scss']
})
export class ThirdStepBimpliApetizComponent implements OnInit, OnChanges {

  @Output() reSend: EventEmitter<any[]> = new EventEmitter();
  @Output() validateCodeSMS: EventEmitter<any[]> = new EventEmitter();
  @Input() messageErreur: string;
  @Input() messageEmail: string;
  @Input() saml: string;
  @Input() type: string;
  @Input() compteur: number;
  @Input() email: string;
  @Input() typeLogin: string;
  @Input() isMobile: string;
  @Input() applicationId: string;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  codeSMS = new FormControl(undefined, [Validators.required]);

  constructor(private router: Router,
              private readonly tealiumService: TealiumService,
              private readonly modalService: ModalService) { }

  ngOnInit(): void {
    if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
      if (this.type === 'comiteo') {
        this.tealiumService.view('bimpli_benef.web.register.comiteo.activateAccountPageLoad');
      }else if (this.type === 'cado') {
        this.tealiumService.view('bimpli_benef.web.register.cado.activateAccountPageLoad');
      }else if (this.type === 'apetiz') {
        this.tealiumService.view('bimpli_benef.web.register.apetiz.activateAccountPageLoad');
      }
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      if (this.type === 'comiteo') {
        this.tealiumService.view('bimpli_mobile.web.register.comiteo.activateAccountPageLoad');
      }else if (this.type === 'cado') {
        this.tealiumService.view('bimpli_mobile.web.register.cado.activateAccountPageLoad');
      }else if (this.type === 'apetiz') {
        this.tealiumService.view('bimpli_mobile.web.register.apetiz.activateAccountPageLoad');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.compteur) {
      if (this.messageEmail && this.messageEmail !== '') {
        this.modalService.open('infoModal');
      }
    }
  }

  goLogin() {
    if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
      if (this.type === 'comiteo') {
        this.tealiumService.link('bimpli_benef.web.register.comiteo.backToLoginClick');
      }else if (this.type === 'cado') {
        this.tealiumService.link('bimpli_benef.web.register.cado.backToLoginClick');
      }else if (this.type === 'apetiz') {
        this.tealiumService.link('bimpli_benef.web.register.apetiz.backToLoginClick');
      }
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      if (this.type === 'comiteo') {
        this.tealiumService.link('bimpli_mobile.web.register.comiteo.backToLoginClick');
      }else if (this.type === 'cado') {
        this.tealiumService.link('bimpli_mobile.web.register.cado.backToLoginClick');
      }else if (this.type === 'apetiz') {
        this.tealiumService.link('bimpli_mobile.web.register.apetiz.backToLoginClick');
      }
    }

    if (this.isMobile) {
      window.location.href = 'app.beneficiaire.apetiz://accueil';
    } else {
      this.router.navigateByUrl('/login/' + this.saml);
    }
  }

  reSendEmail() {
    if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
      if (this.type === 'comiteo') {
        this.tealiumService.link('bimpli_benef.web.register.comiteo.reSendClick');
      }else if (this.type === 'cado') {
        this.tealiumService.link('bimpli_benef.web.register.cado.reSendClick');
      }else if (this.type === 'apetiz') {
        this.tealiumService.link('bimpli_benef.web.register.apetiz.reSendClick');
      }
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      if (this.type === 'comiteo') {
        this.tealiumService.link('bimpli_mobile.web.register.comiteo.reSendClick');
      }else if (this.type === 'cado') {
        this.tealiumService.link('bimpli_mobile.web.register.cado.reSendClick');
      }else if (this.type === 'apetiz') {
        this.tealiumService.link('bimpli_mobile.web.register.apetiz.reSendClick');
      }
    }
    this.reSend.emit();
  }

  resendSMS() {
    this.tealiumService.link('apz.web.registrationProcess.accountCreatedMobileSendSMSClick');
    this.reSend.emit();
  }

  validateCode() {
    this.tealiumService.link('apz.mobile.registrationProcess.accountCreatedMobileValidateCodeClick');
    if (this.codeSMS.valid) {
      this.validateCodeSMS.emit(this.codeSMS.value);
    }
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }

}
