import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TealiumService } from '../../../../shared/services/tealium/tealium.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-first-step-apz-component',
  templateUrl: './first-step-apz-component.component.html',
  styleUrls: [
    './first-step-apz-component.component.scss',
    '../../create-account-APZ.component.scss',
    '../../../../../assets/css/apz-global.scss'
  ]
})
export class FirstStepApzComponentComponent implements OnInit {
  @Output() validate: EventEmitter<any[]> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input() messageErreur: string;
  @Input() success: boolean;
  @Input() cardNumber: string;
  @Input() inlined = false;
  @Input() expirationDate: string;
  @Input() saml: string;

  constructor(protected fb: FormBuilder, private readonly tealiumService: TealiumService) {}

  ngOnInit() {
    this.tealiumService.view('apz.web.registrationProcess.registrationProcessPageLoad');
  }

  validateRelay(event) {
    this.tealiumService.link('apz.web.registrationProcess.validateRegistrationClick');
    this.validate.emit(event);
  }

  cancelRelay() {
    this.cancel.emit();
  }

  goOldInscription() {
    this.tealiumService.link('apz.web.registrationProcess.cardNotReceivedClick');
    window.location.href = environment.oldInscripionUrlAPZ;
  }
}
