<div class="img-row">
    <img src=" ../../../assets/asp/logo_wee.png" class="logo-img" />
</div>

<div class="header d-flex align-items-center justify-content-center">
    <p class="title">{{'NAVBAR.RGPD_RECORD' | translate}}</p>
</div>
<div class="container">
    <div class="card">
        <div class="card-body">
            <a href="https://www.weezen.fr/assets/pdf/noticeGDPR.pdf"> {{ 'NAVBAR.RGPD_RECORD' | translate }}</a>
        </div>
    </div>
</div>
  