import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from 'src/app/core/core.module';
import { InputCreditCardDateComponent } from './components/input-credit-card-date/input-credit-card-date.component';
import { InputCreditCardComponent } from './components/input-credit-card/input-credit-card.component';
import { HeaderModalComponent } from './components/modal/header-modal/header-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PasswordValidationComponent } from './components/password-validation/password-validation.component';
import { OuvreApetizComponent } from './components/promo/apz/ouvre-apetiz/ouvre-apetiz.component';
import { PromoAppMobileComponent } from './components/promo/apz/promo-app-mobile/promo-app-mobile.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SnackbarService } from './components/snackbar/snackbar.service';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { CreditCardDateMaskPipe } from './pipes/credit-card-date-mask.pipe';
import { CreditCardMaskPipe } from './pipes/credit-card-mask.pipe';
import { InputCodeComponent } from './components/input-code/input-code.component';
import { VersionComponent } from './components/version/version.component';

@NgModule({
  declarations: [
    ModalComponent,
    HeaderModalComponent,
    PasswordValidationComponent,
    PageNotFoundComponent,
    InputCreditCardComponent,
    OuvreApetizComponent,
    PromoAppMobileComponent,
    ToggleSwitchComponent,
    CreditCardMaskPipe,
    CreditCardDateMaskPipe,
    InputCreditCardDateComponent,
    SnackbarComponent,
    InputCodeComponent,
    VersionComponent
  ],
  imports: [CommonModule, CoreModule],
  exports: [
    ModalComponent,
    HeaderModalComponent,
    PasswordValidationComponent,
    InputCreditCardComponent,
    OuvreApetizComponent,
    PromoAppMobileComponent,
    ToggleSwitchComponent,
    CreditCardMaskPipe,
    CreditCardDateMaskPipe,
    InputCreditCardDateComponent,
    SnackbarComponent,
    InputCodeComponent,
    VersionComponent
  ],
  providers: [SnackbarService]
})
export class SharedModule {}
