import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountActivation } from '@shared/models/account-activation.model';
import { ChangePassword } from '@shared/models/change-password.model';
import { ForgottenPassword } from '@shared/models/forgotten-password.model';
import { MerchantsInvite } from '@shared/models/merchant-invite';
import { SignupAffiliateManager } from '@shared/models/signup-affiliate-manager';
import { combineLatest } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { ApplicationCodeEnum } from 'src/app/shared/enums/application-code.enum';
import { ProductEnum } from 'src/app/shared/enums/product.enum';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
// import { Favicons } from 'src/app/shared/favicons';
import { SessionStore } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionStateService {
  constructor(
    private sessionStore: SessionStore,
    // private readonly fav: Favicons,
    private readonly translateService: TranslateService,
  ) {
  }

  /**
   * Set the application code, product id and fav icon
   * @param applicationCode the application code to set the visual to
   */
  private setApplicationCodeStateAndFavIcon(applicationCode: string) {
    const applicationFields = applicationCode.split('-');
    if (applicationFields.length > 1) {
      this.sessionStore.update({isLocal: true});
    }
    switch (applicationFields[0]) {
      case ApplicationCodeEnum.CESU:
        this.sessionStore.update({ applicationCode: ApplicationCodeEnum.CESU });
        this.sessionStore.update({ productId: ProductEnum.CESU });
        // this.fav.activate('cesu');
        break;
      case ApplicationCodeEnum.WEEZEN:
        this.sessionStore.update({ applicationCode: ApplicationCodeEnum.WEEZEN });
        this.sessionStore.update({ productId: ProductEnum.WEEZEN });
        // this.fav.activate('wee');
        break;
      case ApplicationCodeEnum.AFFILIE_APZ:
        this.sessionStore.update({ applicationCode: ApplicationCodeEnum.AFFILIE_APZ });
        this.sessionStore.update({ productId: ProductEnum.APZ });
        // this.fav.activate('apz');
        break;
      case ApplicationCodeEnum.APZ_MOBILE:
        this.sessionStore.update({ applicationCode: ApplicationCodeEnum.APZ_MOBILE });
        this.sessionStore.update({ productId: ProductEnum.APZ });
        // this.fav.activate('apz');
        break;
      case ApplicationCodeEnum.BENEFIT_BENEF:
        this.sessionStore.update({ applicationCode: ApplicationCodeEnum.BENEFIT_BENEF });
        this.sessionStore.update({ productId: ProductEnum.BIMPLI });
        // this.fav.activate('apz');
        break;
      case ApplicationCodeEnum.BENEFIT_BENEF_TEST:
          this.sessionStore.update({ applicationCode: ApplicationCodeEnum.BENEFIT_BENEF });
          this.sessionStore.update({ productId: ProductEnum.BIMPLI_TEST });
          // this.fav.activate('apz');
          break;
      case ApplicationCodeEnum.BENEFIT_CAGNOTTE:
          this.sessionStore.update({ applicationCode: ApplicationCodeEnum.BENEFIT_CAGNOTTE });
          this.sessionStore.update({ productId: ProductEnum.BIMPLI });
          // this.fav.activate('apz');
          break;
      case ApplicationCodeEnum.BENEFIT_FINANCEUR:
          this.sessionStore.update({ applicationCode: ApplicationCodeEnum.BENEFIT_FINANCEUR });
          this.sessionStore.update({ productId: ProductEnum.BIMPLI });
          // this.fav.activate('apz');
          break;
        case ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST:
          this.sessionStore.update({ applicationCode: ApplicationCodeEnum.BENEFIT_FINANCEUR });
          this.sessionStore.update({ productId: ProductEnum.BIMPLI_TEST });
          // this.fav.activate('apz');
          break;
      case ApplicationCodeEnum.BENEFIT_MOBILE:
          this.sessionStore.update({ applicationCode: ApplicationCodeEnum.BENEFIT_MOBILE });
          this.sessionStore.update({ productId: ProductEnum.BIMPLI });
          // this.fav.activate('apz');
          break;
      case ApplicationCodeEnum.APZ:
      default:
        this.sessionStore.update({ applicationCode: ApplicationCodeEnum.APZ });
        this.sessionStore.update({ productId: ProductEnum.APZ });
        // this.fav.activate('apz');
        break;
    }
  }

  /**
   * Default method use to set the application code and saml
   * @param route the route to extract the saml from
   * @param separator the type of separator
   */
  public setApplicationCode(route: ActivatedRoute, separator: string = '|') {
    route.paramMap
      .pipe(
        map(params => params.get('saml')),
        filter(saml => saml && true),
        map(saml => {
          if (saml === 'undefined' || saml.length <= 1) {
            throw new Error('Impossible to decode the saml.');
          }
          this.sessionStore.update({ saml });
          const decoded = atob(saml);

          if (!decoded) {
            throw new Error('Impossible to decode the saml.');
          }

          const split = decoded.split(separator);
          if (split.length < 3) {
            throw new Error('Impossible to find the application code.');
          }

          return split[2];
        }),
        // filter(codeApplication => codeApplication && true && this.sessionStore.getValue().applicationCode !== codeApplication),
        tap(applicationCode => {
          this.setApplicationCodeStateAndFavIcon(applicationCode);
        }),
        catchError(err => {
          throw err;
        }),
      )
      .subscribe();
  }

  public setRegistrationStep(registrationStep: RegistrationStepEnum): void {
    this.sessionStore.update({ registrationStep });
  }

  public setForgottenLoginMail(forgottenLoginMail: string): void {
    this.sessionStore.update({ forgottenLoginMail });
  }

  /**
   * Register the login of the user after it was entered in the forgotten password page
   * @param forgottenPasswordLogin the login of the user that has forgotten his password
   */
  public setForgottenPasswordLogin(forgottenPasswordLogin: string): void {
    this.sessionStore.update({ forgottenPasswordLogin });
  }

  /**
   * Specific method to set application code from the forgotten password pages
   * @param route the url with the parameters to extract
   * @param separator the separator to use
   */
  public setApplicationCodeFromForgottenPassword(route: ActivatedRoute, separator: string = ';') {
    combineLatest([
      route.paramMap,
      route.queryParams,
    ])
      .pipe(
        map(([params, queryParams]) => {
          return [params.get('saml'), queryParams.hash];
        }),
        filter(([saml, hash]) => saml && hash && true),
        map(([saml, hash]) => {
          const samlFields = atob(saml).split(separator);

          const forgottenPasswordInfo = new ForgottenPassword();
          forgottenPasswordInfo.saml = saml;
          forgottenPasswordInfo.userId = samlFields[0];
          forgottenPasswordInfo.login = samlFields[1];
          forgottenPasswordInfo.tokenType = samlFields[3];
          forgottenPasswordInfo.hash = hash;

          this.sessionStore.update({ forgottenPasswordInfo });

          if (samlFields[4]) {
            this.translateService.use(samlFields[4].toLowerCase());
          }
          
          return samlFields[2];
        }),
        tap((codeApplication: string) => {
          this.setApplicationCodeStateAndFavIcon(codeApplication);
        }),
        catchError((err) => {
          throw err;
        }),
      )
      .subscribe();
  }

  /**
   * Specific method to set application code and pull user data from query param
   * @param route the url with the parameters to extract
   * @param separator the separator to use
   */
     public setApplicationCodeFromMerchantsInvite(route: ActivatedRoute, separator: string = ';') {
      combineLatest([
        route.paramMap,
        route.queryParams,
      ])
        .pipe(
          map(([params, queryParams]) => {
            return [params.get('saml'), queryParams.token, queryParams.hash];
          }),
          filter(([saml, token, hash]) => saml && token && hash && true),
          map(([saml, token, hash]) => {
            const samlFields = atob(token).split(separator);
            const merchantsInvite: MerchantsInvite = {
              saml,
              hash,
              token,
              affiliateId: samlFields[0],
              email: samlFields[1],
              tokenType: samlFields[3],
            }
            this.sessionStore.update({ merchantsInvite });

            if (samlFields[4]) {
              this.translateService.use(samlFields[4].toLowerCase());
            }

            return samlFields[2];
          }),
          tap((codeApplication: string) => {
            this.setApplicationCodeStateAndFavIcon(codeApplication);
          }),
          catchError((err) => {
            throw err;
          }),
        )
        .subscribe();
    }


  /**
   * Specific method to set application code from the  affiliate manager signup page
   * @param route the url with the parameters to extract
   * @param separator the separator to use
   */
   public setApplicationCodeFromAffiliateManagerSignup(route: ActivatedRoute, separator: string = ';') {
    combineLatest([
      route.paramMap,
      route.queryParams,
    ])
      .pipe(
        map(([params, queryParams]) => {
          return [params.get('saml'), queryParams.hash];
        }),
        filter(([saml, hash]) => saml && hash && true),
        map(([saml, hash]) => {
          const samlFields = atob(saml).split(separator);
          const signupAffiliateManager = new SignupAffiliateManager();
          signupAffiliateManager.saml = saml;
          signupAffiliateManager.userId = samlFields[0];
          signupAffiliateManager.login = samlFields[1];
          signupAffiliateManager.tokenType = samlFields[3];
          signupAffiliateManager.hash = hash;

          this.sessionStore.update({ signupAffiliateManager });

          if (samlFields[4]) {
            this.translateService.use(samlFields[4].toLowerCase());
          }
          return samlFields[2];
        }),
        tap((codeApplication: string) => {
          this.setApplicationCodeStateAndFavIcon(codeApplication);
        }),
        catchError((err) => {
          throw err;
        }),
      )
      .subscribe();
  }


  /**
   * Specific method to set application code from the account activation page
   * @param route the url with the parameters to extract
   * @param separator the separator to use
   */
  public setApplicationCodeFromAccountActivation(route: ActivatedRoute, separator: string = ';') {
    route.queryParams
      .pipe(
        map(params => {
          return params.hash;
        }),
        filter((hash: string) => hash && true),
        map((hash: string) => {
          const fields = atob(hash).split(separator);

          const accountActivationInfo = new AccountActivation();
          accountActivationInfo.hash = hash;
          accountActivationInfo.userId = fields[0];
          accountActivationInfo.login = fields[1];
          accountActivationInfo.userProfile = fields[3];

          this.sessionStore.update({ accountActivationInfo });

          if (fields[4]) {
            this.translateService.use(fields[4].toLowerCase());
          }

          return fields[2];
        }),
        tap((applicationCode: string) => {
          this.setApplicationCodeStateAndFavIcon(applicationCode);
        }),
      ).subscribe();
  }

  /**
   * Set the application code of the application using the route.
   * The method check the state of the route to decide which method to use to extract and set the application code from the route.
   * @param route the route to use for application code extraction
   */
  public setApplicationCodeFromUnknownRoute(route: ActivatedRoute) {
    combineLatest([
      route.paramMap,
      route.queryParams,
    ])
      .pipe(
        map(([params, queryParams]) => {
          return [params.get('saml'), queryParams.hash];
        }),
        tap(([saml, hash]) => {
          if (saml && hash) {
            this.setApplicationCodeFromForgottenPassword(route);
          } else if (saml && !hash) {
            this.setApplicationCode(route);
          } else if (!saml && hash) {
            this.setApplicationCodeFromAccountActivation(route);
          }
        }),
        catchError((err) => {
          throw err;
        }),
      )
      .subscribe();
  }

  /**
   * Set the application code from the change password page
   * @param route the route to use to extract the data
   * @param separator the separator to use
   */
  public setApplicationCodeFromChangePassword(route: ActivatedRoute, separator: string = ';') {
    route.paramMap
      .pipe(
        map(params => params.get('saml')),
        filter(saml => saml && true),
        map(saml => {
          const fields = atob(saml).split(separator);

          const changePasswordInfo: ChangePassword = {
            saml,
            login: fields[0],
            redirectUrl: fields[2],
          };

          this.sessionStore.update({ changePasswordInfo });

          if (fields[3]) {
            this.translateService.use(fields[3].toLowerCase());
          }

          return fields[1];
        }),
        tap(applicationCode => {
          this.setApplicationCodeStateAndFavIcon(applicationCode);
        }),
        catchError(err => {
          throw err;
        }),
      )
      .subscribe();
  }
}
