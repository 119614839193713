import { Component, Input, OnInit } from '@angular/core';
import { Adress } from '@shared/models/adress';
import { Affiliate } from '@shared/models/affiliate';

@Component({
  selector: 'app-commercial-infos-card',
  templateUrl: './commercial-infos-card.component.html',
  styleUrls: ['./commercial-infos-card.component.scss']
})
export class CommercialInfosCardComponent {

  @Input() affiliate: Affiliate;

  public getAffiliateAddress(address: Adress): string {
    return `${address?.street} ${address?.postalCode} ${address?.city}`;
  }

}
