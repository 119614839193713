import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { emailRegEx } from '@shared/helpers/regexp.helpers';
import { AuthToken } from '@shared/models/authtoken.model';
import {
  confirmationMailIsEqualValidator,
  confirmationPasswordIsEqualValidator
} from '@shared/validators/custom.validators';
import { sha256 } from 'js-sha256';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { UserCriteria } from 'nit-angular-lib';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { sha512 } from 'js-sha512';

@Component({
  selector: 'app-first-step-bimpli-cado',
  templateUrl: './first-step-bimpli-cado.component.html',
  styleUrls: ['./first-step-bimpli-cado.component.scss']
})
export class FirstStepBimpliCadoComponent implements OnInit, DoCheck {


  @Input() saml: string;
  @Input() type: string;
  @Input() messageErreur: string;
  @Input() isLoading: boolean;
  @Input() isMobile: boolean;
  @Input() applicationId: string;

  @Output() submitted: EventEmitter<any> = new EventEmitter();

  isConfirmMailValid: boolean;
  private emailDirty = false;
  valueCaptcha: string;

  token: AuthToken;
  alreadyUsedMail: boolean;
  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected fb: FormBuilder,
    protected translate: TranslateService,
    protected siloApiService: SiloApiService,
    protected gatewayService: GatewayService,
    private router: Router,
    protected tealiumService: TealiumService,
    private readonly formBuilder: FormBuilder,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  emailFormControl = this.formBuilder.control('', [Validators.required, Validators.pattern(emailRegEx)]);
  passwordFormControl = this.formBuilder.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(60)]);
  createAccountForm = this.formBuilder.group({
    login: this.emailFormControl,
    confirm_email: [{value: '', disabled: true}
      , [Validators.required,
        Validators.pattern(emailRegEx)
      ]],
    password: this.passwordFormControl,
    acceptCGU: [false, [Validators.requiredTrue]],
    confirm_password: [{value: '', disabled: true},
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(60),
        confirmationPasswordIsEqualValidator(this.passwordFormControl)
      ]]
    },
    { validator: confirmationMailIsEqualValidator('login', 'confirm_email')}
  );


  getCGU() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cgu/' + this.saml])
    );

    window.open(url, '_blank');
  }

  isLessThanEightChars(p: string): boolean {
    return p.length < 8;
  }

  isMoreThanSixtyChars(p: string): boolean {
    return p.length > 60;
  }

  hasAtLeastOneLowerCase(p: string): boolean {
    return p.toUpperCase() !== p;
  }

  hasAtLeastOneUpperCase(p: string): boolean {
    return p.toLowerCase() !== p;
  }

  hasAtLeastOneDigit(p: string): boolean {
    return /\d/.test(p);
  }

  hasAtLeastOneSpecialChar(p: string): boolean {
    const pwdRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return pwdRegex.test(p);
  }

  isCorrectPwd(p: string): boolean {
    return !this.isLessThanEightChars(p) && !this.isMoreThanSixtyChars(p) && (
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneSpecialChar(p))
    );
  }

  ngDoCheck(): void {
    this.isConfirmMailValid = (this.createAccountForm.get('confirm_email').dirty || this.createAccountForm.get('login').dirty) &&
      (this.createAccountForm.get('confirm_email').value.toUpperCase() === this.createAccountForm.get('login').value.toUpperCase());
    if (this.emailDirty !== this.createAccountForm.get('login').dirty) {
      this.emailDirty = this.createAccountForm.get('login').dirty;
      if (this.emailDirty) {
        this.createAccountForm.get('confirm_email').enable();
      }
    }
    if (this.createAccountForm.get('password').dirty) {
      this.createAccountForm.get('confirm_password').enable();
    }
  }

  validate(): void {
    if (this.createAccountForm.valid) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.token = authToken;
          this.recaptchaV3Service
            .execute('CreationAccountAction')
            .pipe(finalize(() => this.readUserByCriteria()))
            .subscribe((token) => (this.valueCaptcha = token));
        });
    }
  }


  private readUserByCriteria(): void {
    const userCriteria = new UserCriteria();
    userCriteria.login = this.emailFormControl.value;
    userCriteria.applicationId = this.applicationId;
    this.siloApiService.readUserByCriteria(this.token, userCriteria, this.valueCaptcha).subscribe((retour) => {
      if (retour.items.length === 0) {
        localStorage.setItem('login', this.createAccountForm.get('login').value);
        localStorage.setItem('password', sha256(this.createAccountForm.get('password').value));
        localStorage.setItem('passwordCado', sha512(this.createAccountForm.get('password').value));
        this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/${this.saml}/${this.type}`]);
      } else {
        const user = retour.items[0];
        if (user.active === 'PENDING_ACTIVATION') {
          localStorage.setItem('id', user.id);
          this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}/${this.type}`]);
        } else if (user.active === 'ACTIVE') {
          localStorage.removeItem('login');
          localStorage.removeItem('password');
          localStorage.setItem('accountAlreadyExist', 'Vous avez déjà un compte, connectez-vous avec vos identifiants Bimpli');
          this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
        }
      }
    });
  }

  back(): void {
    window.history.back();
  }

  ngOnInit(): void {
    if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
      if (this.type === 'comiteo') {
        this.tealiumService.view('bimpli_benef.web.register.comiteo.identifiantPageLoad');
      } else if (this.type === 'cado') {
        this.tealiumService.view('bimpli_benef.web.register.cado.identifiantPageLoad');
      }
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      if (this.type === 'comiteo') {
        this.tealiumService.view('bimpli_mobile.web.register.comiteo.identifiantPageLoad');
      } else if (this.type === 'cado') {
        this.tealiumService.view('bimpli_mobile.web.register.cado.identifiantPageLoad');
      }
    }
  }
}
