import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss']
})
export class RightPanelComponent {
  @Input() isPanelOpen$!: BehaviorSubject<boolean>;
  @Input() isLargePanel?: boolean;
  @Output() closeClicked$: EventEmitter<boolean> = new EventEmitter<boolean>();
}
