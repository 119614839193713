<app-cesu-layout>
    <div class="cesu-rgpd mb-5">
        <div class="cesu-page-title pt-5 pb-4">
            Protection des données personnelles
        </div>

        <div class="cesu-paragraph-title pl-4 pb-4">
            Démarche de mise en conformité
        </div>

        <div class="bg-white p-5">
            <p>L’adresse de notre site Web est : <a class="cesu-link" href="https://bimpli.com">https://bimpli.com</a>
            </p>

            <p>
                Bimpli, émetteur du titre CESU, construit avec ses clients, leurs bénéficiaires et les affiliés une
                relation de
                confiance forte et veille en permanence au respect de la réglementation sur la protection des Données
                personnelles. La sécurité et
                la confidentialité des données est une priorité pour Bimpli.
            </p>

            <p>
                Conformément aux exigences du Règlement Général sur la Protection des Données Personnelles (RGPD) entrée
                en vigueur le 25 mai 2018,
                Bimpli met en œuvre toutes les mesures nécessaires afin de garantir la protection de vos données
                personnelles.
            </p>

            <p>Le traitement des données à caractère personnel s’opère sur la base des fondements suivants :</p>

            <ul>
                <li>l’exécution de mesures précontractuelles prises à la demande du client ou de l’affilié ;</li>
                <li>l’exécution de la relation contractuelle liant le client ou l’affilié à Bimpli ;</li>
                <li>la réalisation des intérêts légitimes poursuivis par Bimpli ;</li>
                <li>
                    la conformité de Bimpli face aux obligations légales lui incombant en vertu de la législation et de
                    la réglementation
                    en vigueur ;
                </li>
                <li>votre consentement explicite.</li>
            </ul>
        </div>

        <div class="cesu-paragraph-title pl-4 pt-5 pb-4">
            Notice d’information
        </div>

        <div class="bg-white p-5">
            <p>
                La notice d’information relative aux données à caractère personnel présente les mesures qui nous
                permettent de garantir au mieux la
                protection de vos données personnelles.
            </p>

            <p>
                Lorsque vous nous communiquez vos données personnelles, vous autorisez expressément Bimpli à traiter ces
                données aux
                fins précisées dans la notice.
            </p>

            <p>
                Conformément à la législation relative à la protection des données, vous pouvez à tout moment, accéder
                aux informations vous
                concernant, vous opposer pour motif légitime à leur traitement, les faire rectifier, demander leur
                effacement, leur limitation, leur
                portabilité, ou communiquer des instructions sur leur sort en cas de décès.
            </p>

            <p>
                Les modalités d’exercice de ces droits sont disponibles dans la notice. Pour toute question relative aux
                présentes informations ou à
                la protection de vos données personnelles, vous pouvez vous adresser au Délégué à la Protection des
                Données de Bimpli –
                Adresse postale : <strong>Bimpli – 110 avenue de France – 75013 Paris - </strong>
                <a class="cesu-link" href="mailto:dpo-bimpli@natixis.com">dpo-bimpli@natixis.com</a>
            </p>

            <p><a class="cesu-link" href="assets/pdf/NOTICE_RGPD_APZ.pdf">Télécharger la notice d’information</a></p>
        </div>

        <div class="cesu-paragraph-title pl-4 pt-5 pb-4">
            Cookies
        </div>

        <div class="bg-white p-5">
            <p>
                Conformément à notre politique de confidentialité relative aux cookies et traceurs, vous disposez d’un
                droit de modification de vos
                préférences.
            </p>

            <p><a class="cesu-link">Gérer les paramètres relatifs aux cookies</a></p>
        </div>
    </div>
</app-cesu-layout>
