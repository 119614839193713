<div id="cgu-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row align-items-center justify-content-center w-100 h-100">
    <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-8 col-8 card shadow p-3">
      <span id="title" class="d-flex flex-row align-items-center justify-content-start w-100 p-1">{{ 'NAVBAR.CGU' | translate }} </span>
      <div id="cgu-text" class="mt-2 mb-2">
        <div class="p-3" [innerHTML]="cguContent"></div>
      </div>
      <div class="pt-4 d-flex flex-row align-items-center justify-content-center">
        <button type="button" class="btn btn-primary btn-block" (click)="goBack()">
          <div class="d-flex flex-row align-items-center justify-content-center">
            <span id="submit-btn-label">{{ 'CREATION_ACCOUNT.RETOUR' | translate }}</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
