import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
// import { Favicons } from '../../../../shared/favicons';
import { Router } from '@angular/router';
import { ModalService } from '../../../../shared/components/modal/modal.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { GroundTruthDataService } from 'src/app/shared/services/ground-truth-data.service';
import { WeezenComponent } from 'src/app/features/weezen/weezen.component';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-first-step-asp-component',
  templateUrl: './first-step-asp-component.component.html',
  styleUrls: [
    './first-step-asp-component.component.scss',
    '../../create-account.component.scss',
    '../../../../../assets/css/asp-global.scss',
  ],
})
export class FirstStepAspComponentComponent extends WeezenComponent implements OnInit, OnDestroy {
  cardForm = this.fb.group({
    numCard: this.fb.group({
      numCard: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[0-9]{4}')],
      ],
      numCard2: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[0-9]{4}')],
      ],
      numCard3: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[0-9]{4}')],
      ],
      numCard4: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[0-9]{4}')],
      ],
    }),
    expirationDate: this.fb.group({
      expirationDate: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(2),
          Validators.pattern('(0[1-9]|1[012])'),
        ],
      ],
      expirationDate2: [
        '',
        [Validators.required, Validators.minLength(2), Validators.maxLength(2), Validators.pattern('[0-9]{2}')],
      ],
    }),
  });

  @Output() validate: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input() messageErreur: string;
  @Input() success: boolean;
  @Input() saml: string;
  @ViewChild('expiration', { static: false }) expiraDateField: ElementRef;
  isLoading = false;
  mySubscription = new Subscription();
  handleToken: string;

  constructor(
    private fb: FormBuilder,
    // fav: Favicons,
    private router: Router,
    private modalService: ModalService,
    private gtds: GroundTruthDataService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    // super(fav);
    super();
  }

  ngOnInit() {
    const isCardActivated = this.gtds
      .getIsCardActivated()
      .pipe(
        distinctUntilChanged(),
        filter(response => response && true),
      )
      .subscribe(() => {
        this.modalService.open('cardAlreadyActivated');
      });

    this.mySubscription.add(isCardActivated);

    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe(response => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
  }

  send() {
    this.gtds.setIsSpinnerActivated(true);

    this.recaptchaV3Service.execute('CreationAccountAction')
      .pipe(finalize(() => this.callApi() ))
      .subscribe((token) => this.handleToken = token);
  }

  callApi() {
    const numCard = this.cardForm.get('numCard').get('numCard').value + this.cardForm.get('numCard').get('numCard2').value +
      this.cardForm.get('numCard').get('numCard3').value + this.cardForm.get('numCard').get('numCard4').value;
    const expirationDate = this.cardForm.get('expirationDate').get('expirationDate').value +
      this.cardForm.get('expirationDate').get('expirationDate2').value;
    const handleToken = this.handleToken;
    console.log('handleToken = ' + handleToken);
    this.validate.emit({ numCard, expirationDate, handleToken });
  }

  sortie() {
    this.cancel.emit();
  }

  calculeSize(event, taille) {
    if (event.srcElement.value.length === taille) {
      if (event.target.id === 'numCard4') {
        this.expiraDateField.nativeElement.focus();
      } else {
        const element = event.srcElement.nextElementSibling;
        if (element == null) {
          return;
        } else {
          element.focus();
        }
      }
    }
  }

  goToLogin() {
    window.location.href = environment.redirectURIWee.replace('/token', '');
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }
}
