import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-forgotten-password-mail',
  templateUrl: './forgotten-password-mail.component.html',
  styleUrls: ['./forgotten-password-mail.component.scss'],
})
export class ForgottenPasswordMailComponent implements OnInit {
  public applicationCode$: Observable<ApplicationCodeEnum>;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
  ) {
  }

  public ngOnInit(): void {
    this.sessionStateService.setApplicationCode(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;
  }

}
