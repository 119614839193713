import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import SecondStepApz from '../apz-second-step.class';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SiloApiService } from 'src/app/shared/services/siloApi.service';
import { GatewayService } from 'src/app/shared/services/gateway.service';
import { Router } from '@angular/router';
import { TealiumService } from '../../../../shared/services/tealium/tealium.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-second-step-apz-mobile',
  templateUrl: './second-step-apz-mobile.component.html',
  styleUrls: [
    './second-step-apz-mobile.component.scss',
    '../../create-account-APZ.component.scss',
    '../../../../../assets/css/apz-global.scss'
  ]
})
export class SecondStepApzMobileComponent extends SecondStepApz implements OnChanges, OnInit {
  @Input() saml: string;

  constructor(
    protected fb: FormBuilder,
    protected translate: TranslateService,
    protected siloApiService: SiloApiService,
    protected gatewayService: GatewayService,
    private router: Router,
    protected tealiumService: TealiumService,
    protected reCaptchaV3Service: ReCaptchaV3Service
  ) {
    super(fb, translate, siloApiService, tealiumService, gatewayService, reCaptchaV3Service);
  }
  ngOnInit(): void {
    this.tealiumService.view('apz.mobile.registrationProcess.chooseLoginPageLoad');
    if (localStorage.getItem('login')) {
      this.passwordForm.get('login').setValue(localStorage.getItem('login'));
      this.isSecondStep = true;
      localStorage.removeItem('login');
    }
    if (localStorage.getItem('password') && this.isSecondStep) {
      this.passwordForm.get('password').setValue(localStorage.getItem('password'));
      this.passwordForm.get('confirmPassword').setValue(localStorage.getItem('password'));
      this.isThirdStep = true;
      this.isSecondStep = false;
      localStorage.removeItem('password');
    }
    if (localStorage.getItem('secretQuestion') && localStorage.getItem('response') && this.isThirdStep) {
      this.passwordForm.get('secretQuestion').setValue(localStorage.getItem('secretQuestion'));
      this.passwordForm.get('response').setValue(localStorage.getItem('response'));
      this.isThirdStep = false;
      this.isFourthStep = true;
      localStorage.removeItem('secretQuestion');
      localStorage.removeItem('response');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mailPro) {
      this.init();
    }
  }

  goToCGU() {
    this.tealiumService.link('apz.mobile.registrationProcess.consultCGUClick');
    localStorage.setItem('login', this.passwordForm.get('login').value);
    localStorage.setItem('password', this.passwordForm.get('password').value);
    localStorage.setItem('secretQuestion', this.passwordForm.get('secretQuestion').value);
    localStorage.setItem('response', this.passwordForm.get('response').value);
    this.router.navigateByUrl('/cgu/' + this.saml);
  }

  acceptCGU() {
    this.tealiumService.link('apz.mobile.registrationProcess.acceptCGUClick');
    this.submitForm();
  }
}
