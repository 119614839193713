import { Component, Input, Output, OnInit, EventEmitter, HostListener } from '@angular/core';
import { UnlockOption } from '@shared/models/unclock-options';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss']
})
export class DropdownSelectComponent implements OnInit {

  public isOpen = false;

  @Input() options: UnlockOption[];
  @Input() selectDefaultText: string;
  @Input() isDefaultOption = true;
  @Input() selectLabel!: string;
  @Output() click$ = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.isOpen = false;
  }

  public toggleOptions($event): void {
    $event.stopPropagation();
    this.isOpen = !this.isOpen;
  }

  public chooseOption($event, i: number): void {
    $event.stopPropagation()
    this.selectDefaultText = this.options[i].name;
    this.isDefaultOption = false;
    this.toggleOptions($event);
    this.click$.emit(this.options[i].value);
  }

}
