import { Injectable } from '@angular/core';
import { Affiliate } from '@shared/models/affiliate';
import { AffiliateContact } from '@shared/models/affiliate-contact';
import { Coordonnee } from '@shared/models/coordonnee';

@Injectable({
  providedIn: 'root'
})
export class AffiliateSignupService {
  public selectedAffiliates: Affiliate[] = [];
  public coordonnee: Coordonnee;
  public email: string;
  public userId: number;

  constructor() {}
}
