import { Component, OnInit } from '@angular/core';
import { CesuService } from '@services/cesu/cesu.service';
import { TealiumService } from '@services/tealium/tealium.service';

@Component({
  selector: 'app-cesu-change-forgotten-pwd-success',
  templateUrl: './cesu-change-forgotten-pwd-success.component.html',
  styleUrls: ['./cesu-change-forgotten-pwd-success.component.scss', '../../../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuChangeForgottenPwdSuccessComponent implements OnInit {

  constructor(
    private readonly tealiumService: TealiumService,
    private readonly cesuService: CesuService,
  ) {
  }

  public ngOnInit(): void {
    this.tealiumService.view('cesu.forgottenPassword.newPasswordConfirmedPageLoad');
  }

  public goToLogin() {
    this.tealiumService.link('cesu.forgottenPassword.returnToLoginClick');
    this.cesuService.goToLogin();
  }
}
