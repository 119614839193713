import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ActiveUserEnum } from '@shared/enums/active-user.enum';
import { AgreementStatut } from '@shared/enums/agreement-statut.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { StatutSiret } from '@shared/enums/statut-siret.enum';
import { Affiliate } from '@shared/models/affiliate';
import { AffiliateContact } from '@shared/models/affiliate-contact';
import { AuthToken } from '@shared/models/authtoken.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AffiliateSignupService } from '../service/affiliate-signup.service';

const ID_COMPENSATING_CRT = 'CRT';
const PRODUCT_RANGE = 'CDT';
@Component({
  selector: 'app-first-step-signup-affilie',
  templateUrl: './first-step-signup-affilie.component.html',
  styleUrls: ['./first-step-signup-affilie.component.scss']
})
export class FirstStepSignupAffilieComponent implements OnInit, OnDestroy {
  public isStepOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    sessionStorage.getItem('BACK_FROM_CONTACT') === 'true' ? false : window.innerWidth > 576 ? false : true
  );
  public isPanelOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public saml: string;
  public affiliatesFound: Affiliate[] = [];
  public isLargePanel = false;

  public statutSiret: StatutSiret;
  public statutSirets = StatutSiret;

  private readonly destroy$ = new Subject<boolean>();
  private token: AuthToken;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly gatewayService: GatewayService,
    private readonly siloApiService: SiloApiService,
    private readonly gtds: GroundTruthDataService,
    private readonly affiliateSignupService: AffiliateSignupService
  ) {
    sessionStorage.removeItem('BACK_FROM_CONTACT');
  }

  ngOnInit(): void {
    this.sessionStateService.setApplicationCode(this.route);

    this.sessionQuery.selectedSaml
      .pipe(
        takeUntil(this.destroy$),
        tap((saml) => (this.saml = saml))
      )
      .subscribe();

    this.gtds
      .getIsSpinnerActivated()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading$.next(response);
      });
  }

  /**
   * Recupere les informations sur le siret saisi si nécessaire appelle readUserByCriteria
   * @param siret saisi par l'utilisateur
   */
  public findAffiliateBySiret(siret: string): void {
    this.gtds.setIsSpinnerActivated(true);
    this.gatewayService
      .getAccessToken()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.gtds.setIsSpinnerActivated(false)),
        switchMap((authToken) => {
          this.token = authToken;
          return this.siloApiService.readAffiliates(this.token, ID_COMPENSATING_CRT, siret);
        })
      )
      .subscribe((response: Affiliate[]) => {
        switch (!response?.length) {
          case true:
            this.statutSiret = this.statutSirets.AFFILIATE_NOT_FOUND;
            this.isPanelOpen$.next(true);
            break;
          case false:
            this.isLargePanel = false;
            const activeAffiliates = response.filter(
              (item) => item?.agreement?.status === AgreementStatut.ACTIVE && item?.affiliateType?.productRange?.name === PRODUCT_RANGE
            );
            const freeAffiliate = this.getFreeAffiliate(activeAffiliates);
            if (!activeAffiliates?.length) {
              this.statutSiret = this.statutSirets.NO_AFFILIATE_ACTIF;
            } else if (!freeAffiliate?.length) {
              if (this.getAffiliateManager(activeAffiliates, ActiveUserEnum.ACTIVE)) {
                this.statutSiret = this.statutSirets.AFFILIATE_ALREADY_OWNED;
              } else if (this.getAffiliateManager(activeAffiliates, ActiveUserEnum.STATUS_PENDING_ACTIVATION)) {
                const manager = this.getAffiliateManager(activeAffiliates, ActiveUserEnum.STATUS_PENDING_ACTIVATION);
                this.affiliateSignupService.email = manager?.email;
                this.affiliateSignupService.userId = manager?.id;
                this.router.navigateByUrl(`${RoutesEnum.SIGNUP_AFFILIE_FOURTH_STEP}/${this.saml}`);
                return;
              }
            } else if (freeAffiliate?.length === 1) {
              this.affiliatesFound = freeAffiliate;
              this.statutSiret = this.statutSirets.AFFILIATE_CONFIRM;
            } else {
              this.affiliatesFound = freeAffiliate;
              this.statutSiret = this.statutSirets.AFFILIATE_CONFIRM_LIST;
              this.isLargePanel = true;
            }
            this.isPanelOpen$.next(true);
            break;
          default:
            break;
        }
      });
  }

  public onSelect(affiliates: Affiliate[]): void {
    if (affiliates?.length) {
      this.affiliateSignupService.selectedAffiliates = affiliates;
      this.router.navigateByUrl(`${RoutesEnum.SIGNUP_AFFILIE_SECOND_STEP}/${this.saml}`);
    }
  }

  public openTab(url: string): void {
    window.open(url, '_blank');
  }

  private getAffiliateManager(affiliates: Affiliate[], userStatut: ActiveUserEnum): AffiliateContact {
    const managers: AffiliateContact[] = affiliates.reduce((prevValue: AffiliateContact[], currentValue: Affiliate) => {
      currentValue?.managers?.forEach((manager) => prevValue.push(manager));
      return prevValue;
    }, []);

    return managers?.find((manager) => manager?.active === userStatut);
  }

  private getFreeAffiliate(affiliates: Affiliate[]): Affiliate[] {
    const response: Affiliate[] = [];
    affiliates?.forEach((affiliate) => {
      if (affiliate?.managers?.length === 0) {
        response.push(affiliate);
      } else if (
        affiliate?.managers?.find(
          (manager) => manager?.active !== ActiveUserEnum.ACTIVE && manager?.active !== ActiveUserEnum.STATUS_PENDING_ACTIVATION
        )
      ) {
        response.push(affiliate);
      }
    });
    return response;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
