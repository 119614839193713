import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '@services/login/login.service';
import { CesuStateService } from '@services/store/cesu/cesu-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { LoginHelper } from '@shared/helpers/login.helper';
import { ChangePassword } from '@shared/models/change-password.model';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-cesu-change-pwd',
  templateUrl: './cesu-change-pwd.component.html',
  styleUrls: ['./cesu-change-pwd.component.scss', '../../../../../assets/scss/cesu/cesu-global.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CesuChangePwdComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationId: ApplicationCodeEnum;
  public changePasswordInfo: ChangePassword;

  public changePasswordForm: FormGroup;
  public submitted = false;
  public errorMessage: string;

  private valueCaptcha: string;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly loginService: LoginService,
    private readonly sessionQuery: SessionQuery,
    private readonly cesuStateService: CesuStateService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  public ngOnInit(): void {
    this.cesuStateService.setCesuShowFooter(false);

    const applicationIdSubscription = this.sessionQuery.selectedApplicationCode.pipe(
      tap((applicationId: ApplicationCodeEnum) => this.applicationId = applicationId),
    ).subscribe();

    this.subscription.add(applicationIdSubscription);

    const changePasswordInfoSubscription = this.sessionQuery.selectedChangePasswordInfo.pipe(
      tap((changePasswordInfo: ChangePassword) => this.changePasswordInfo = changePasswordInfo),
    ).subscribe();

    this.subscription.add(changePasswordInfoSubscription);

    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: [undefined, [Validators.required]],
        password: [undefined, [validatePassword()]],
        passwordConfirmation: [undefined, [Validators.required]],
      },
      { validators: [LoginHelper.checkPasswords] },
    );
  }

  public get form() {
    return this.changePasswordForm.controls;
  }

  public changePassword(): void {
    this.submitted = true;

    if (this.changePasswordForm.valid) {
      this.reCaptchaV3Service
      .execute('ConnecterAction').subscribe((token) => {
        this.loginService.changePassword(
          this.changePasswordInfo.login,
          this.applicationId,
          this.changePasswordForm.value.oldPassword,
          this.changePasswordForm.value.password,
          token
        ).pipe(
          tap(() => {
            this.router.navigate([RoutesEnum.CHANGE_PASSWORD_SUCCESS, this.changePasswordInfo.saml]);
          }),
          catchError((err) => {
            this.errorMessage = err.message;
            throw err;
          }),
        ).subscribe();
      });
    }
  }

  public goBack(): void {
    window.location.href = this.changePasswordInfo.redirectUrl;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();

    this.cesuStateService.setCesuShowFooter(true);
  }
}
