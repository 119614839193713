<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN">
  <app-first-step-asp-component
    (validate)="send($event.numCard, $event.expirationDate, null)"
    (cancel)="cancel()"
    [messageErreur]="messageErreur"
    [success]="success"
    [saml]="saml"
  >
  </app-first-step-asp-component>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ">
    <app-first-step-apz-component
        (validate)="send($event.numCard, $event.expirationDate, null)"
        (cancel)="cancel()"
        [messageErreur]="messageErreur"
        [success]="success"
        [saml]="saml"
        [cardNumber]="cardNumber"
        [expirationDate]="expirationDate"
    >
    </app-first-step-apz-component>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE">
  <div *ngIf="type === 'apetiz'">
      <app-first-step-bimpli-apetiz
          (validate)="send($event.numCard, $event.expirationDate, $event.refClient)"
          (cancel)="cancel()"
          [messageErreur]="messageErreur"
          [success]="success"
          [isLoading]="isLoading"
          [saml]="saml"
          [applicationId]="(applicationCode$ | async)"
          [cardNumber]="cardNumber"
          [isMobile]="isBimpliMobile"
          [expirationDate]="expirationDate"
      ></app-first-step-bimpli-apetiz>
  </div>
    <div *ngIf="type === 'cado' || type === 'comiteo'">
        <app-first-step-bimpli-cado
            [messageErreur]="messageErreur"
            [saml]="saml"
            [isLoading]="isLoading"
            [isMobile]="isBimpliMobile"
            [applicationId]="(applicationCode$ | async)"
            [type]="type"
        ></app-first-step-bimpli-cado>
    </div>
    <div *ngIf="type === 'cesu'">
        <app-first-step-bimpli-cesu
            [isMobile]="isBimpliMobile"
            [saml]="saml">
        </app-first-step-bimpli-cesu>
    </div>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST">
    <div *ngIf="type === 'cado' || type === 'comiteo'">
        <app-first-step-bimpli-financeur-cado
        [saml]="saml"
        ></app-first-step-bimpli-financeur-cado>
    </div>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE">
  <app-first-step-apz-mobile-component
    (validate)="send($event.numCard, $event.expirationDate, null)"
    (cancel)="cancel()"
    [messageErreur]="messageErreur"
    [success]="success"
    [cardNumber]="cardNumber"
    [expirationDate]="expirationDate"
  >
  </app-first-step-apz-mobile-component>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
  <app-cesu-first-step></app-cesu-first-step>
</div>

<div class="app-affilie-apz-component" *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.AFFILIE_APZ">
    <app-first-step-affilie-apz-component
        (cancel)="cancel()"
        [saml]="saml">
    </app-first-step-affilie-apz-component>
</div>
