import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ApplicationCodeEnum } from 'src/app/shared/enums/application-code.enum';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { AuthToken } from 'src/app/shared/models/authtoken.model';
import { GatewayService } from 'src/app/shared/services/gateway.service';
import { SiloApiService } from 'src/app/shared/services/siloApi.service';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-third-step',
  templateUrl: './third-step.component.html',
  styleUrls: ['./third-step.component.scss']
})
export class ThirdStepComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public applicationId: string;
  public saml: string;

  success: boolean;
  token: AuthToken;
  messageEmail: string;
  messageErreur: string;

  typeLogin: string;
  email: string;
  public ApplicationCodeEnum = ApplicationCodeEnum;
  isMobile: boolean;
  codeInvalide: boolean;
  type: string;
  compteur = 0;
  valueCaptcha: string;

  constructor(
    private readonly gatewayService: GatewayService,
    private readonly siloApiService: SiloApiService,
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private snackbarService: SnackbarService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly recaptchaV3Service: ReCaptchaV3Service
  ) {}


  getInfoUser(): void  {
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    // on recupere le type de login
    const numCard = localStorage.getItem('creditCard');
    const expireCard = localStorage.getItem('expireDate');
    const login = localStorage.getItem('login');
    this.email = login;
    const siret = localStorage.getItem('siret');
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.token = authToken;
      this.siloApiService.infosUser(numCard, expireCard, login, siret, authToken, this.valueCaptcha).subscribe(
        (res) => {
          const info: any = res;
          if (info && info.items.length > 0) {
            this.typeLogin = info.items[0].loginType;
            this.isMobile = this.typeLogin === 'PHONE';
            localStorage.setItem('id', info.items[0].id);
          }
        },
        (err) => {
          this.snackbarService.showDanger('Une erreur est survenue veuillez réassayer ultérieurement');
          this.messageErreur = err.error.errors[0].message;
        }
      );
    });
  }


  ngOnInit() {
    this.sessionStateService.setApplicationCode(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode.pipe(
      tap((applicationCode) =>
      {
        switch (applicationCode) {
          default:
            this.applicationId = applicationCode;
        }
      })
    );

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);

    const checkStepSubscription = combineLatest([this.sessionQuery.selectedApplicationCode, this.sessionQuery.selectedRegistrationStep])
      .pipe(
        take(1),
        tap(([applicationCode, registrationStep]) => {
          if (applicationCode === ApplicationCodeEnum.CESU && registrationStep !== RegistrationStepEnum.THIRD_STEP) {
            this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
          }
        })
      )
      .subscribe();

    this.subscription.add(checkStepSubscription);

    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.token = authToken;
        this.recaptchaV3Service
          .execute('ValidationCreateAccountAction')
          .pipe(finalize(() => this.getInfoUser()))
          .subscribe((token) => (this.valueCaptcha = token))
      });
  }

  reSendEmail() {
    this.gatewayService.getAccessToken().subscribe(
      (authToken) => {
        this.token = authToken;
        this.siloApiService.sendEmailActivationAccount(localStorage.getItem('id'), this.applicationId, authToken).subscribe(
          () => {
            this.success = true;
            this.compteur++;
            this.messageEmail = this.translate.instant('CREATION_ACCOUNT.THIRD_STEP.MAIL_SEND');
            this.snackbarService.showSuccess('Votre demande a bien été renvoyée');
          },
          (error) => {
            this.success = false;
            this.messageErreur = error.error.errors[0].message;
            this.snackbarService.showDanger(this.translate.instant('CREATION_ACCOUNT.THIRD_STEP.ERROR.NOT_SEND'));
          }
        );
      },
      (error) => {
        this.snackbarService.showDanger(this.translate.instant('CREATION_ACCOUNT.THIRD_STEP.ERROR.NOT_SEND'));
      }
    );
  }

  validateCodeSMS(code) {
    this.gatewayService.getAccessToken().subscribe(
      (authToken) => {
        this.siloApiService.verifierCodeSMS(code, authToken).subscribe(
          (res) => {
            this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_FOURTH_STEP}/${this.saml}`);
          },
          (err) => {
            this.codeInvalide = true;
            this.messageErreur = JSON.parse(err.error).errors[0].message;
          }
        );
      },
      (error) => {
        this.snackbarService.showDanger('Une erreur est survenue veuillez réassayer ultérieurement');
      }
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
