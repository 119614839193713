<div id="global-container" class="container-fluid vh-100 vw-100 pl-4 pr-4">
  <div class="d-flex flex-row align-items-center justify-content-start pt-4 pb-4">
    <button id="go-back-btn" type="button" class="btn btn-link" (click)="goBack()">
      <div class="d-flex flex-row align-items-center justify-content-center">
        <span id="go-back-icon" class="material-icons-outlined">
          arrow_back
        </span>
        <span id="go-back-label" class="ml-2"> {{ 'COMMON.FORM.BACK' | translate }}</span>
      </div>
    </button>
  </div>
  <span id="title-page">
    {{ 'AFFILIE_APZ.CHANGE_ID.TITLE' | translate }}
  </span>
  <form name="loginForm" [formGroup]="loginForm" class="d-flex flex-row align-items-center justify-content-start mt-2" novalidate>
    <!-- FIRST STEP -->
    <div id="first-step" *ngIf="isFirstStep">
      <label class="form-label description-txt form-element" for="currentPassword">{{
        'AFFILIE_APZ.CHANGE_ID.FIRST_STEP.TEXT' | translate
      }}</label>
      <div class="input-group form-element">
        <input
          [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'"
          class="form-control"
          [ngClass]="{
            'is-invalid': loginForm.get('currentPassword').invalid === true && loginForm.get('currentPassword').touched === true,
            'is-valid': loginForm.get('currentPassword').invalid === false && loginForm.get('currentPassword').touched === true
          }"
          id="currentPassword"
          name="currentPassword"
          formControlName="currentPassword"
          placeholder="{{ 'AFFILIE_APZ.CHANGE_ID.FIRST_STEP.PLACEHOLDER' | translate }}"
          required
          (keydown.enter)="goToSecondStep()"
        />
        <div class="input-group-append">
          <button
            *ngIf="(isPasswordDisplayed$ | async) === false"
            type="button"
            class="btn btn-light input-group-text"
            (click)="isPasswordDisplayed$.next(true)"
          >
            <i class="bi bi bi-eye-fill"></i>
          </button>
          <button
            *ngIf="(isPasswordDisplayed$ | async) === true"
            type="button"
            class="btn btn-light input-group-text"
            (click)="isPasswordDisplayed$.next(false)"
          >
            <i class="bi bi-eye-slash-fill"></i>
          </button>
        </div>
      </div>
      <small
        class="text-danger error-msg"
        *ngIf="
          loginForm.get('currentPassword').touched &&
          loginForm.get('currentPassword').invalid &&
          loginForm.get('currentPassword').errors.required
        "
      >
        {{ 'COMMON.FORM.REQUIRED' | translate }}
      </small>
      <small class="text-danger error-msg" *ngIf="currentPasswordInvalid">
        {{ 'AFFILIE_APZ.CHANGE_ID.FIRST_STEP.MDP_INCORRECT' | translate }}
      </small>
      <div class="d-flex flex-row align-items-center justify-content-start w-100 pt-3">
        <button type="button" class="btn btn-primary form-btn" (click)="goToSecondStep()">
          <div class="d-flex flex-row align-items-center justify-content-center">
            <div *ngIf="isLoading" class="spinner-border"></div>
            <span
              [ngClass]="{
                'ml-2': isLoading
              }"
              id="submit-btn-label"
              >{{ 'AFFILIE_APZ.CHANGE_ID.BTN.VALIDATE' | translate }}</span
            >
          </div>
        </button>
      </div>
    </div>
    <!-- SECOND STEP -->
    <div id="second-step" *ngIf="!isFirstStep">
      <label class="form-label description-txt form-element" for="login">{{ 'AFFILIE_APZ.CHANGE_ID.SECOND_STEP.TEXT' | translate }}</label>
      <input
        type="text"
        class="form-control form-element"
        [ngClass]="{
          'is-invalid': loginForm.get('newLogin').invalid === true && loginForm.get('newLogin').touched === true,
          'is-valid': loginForm.get('newLogin').invalid === false && loginForm.get('newLogin').touched === true
        }"
        name="login"
        id="login"
        formControlName="newLogin"
        placeholder="{{ 'AFFILIE_APZ.CHANGE_ID.SECOND_STEP.PLACEHOLDER' | translate }}"
        required
        (keydown.enter)="submitForm()"
      />
      <div *ngIf="loginForm.get('newLogin').touched && loginForm.get('newLogin').invalid">
        <small class="text-danger error-msg" *ngIf="loginForm.get('newLogin').errors.required">
          {{ 'COMMON.FORM.REQUIRED' | translate }}
        </small>
        <small class="text-danger error-msg" *ngIf="loginForm.get('newLogin').errors.pattern">
          {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
        </small>
      </div>
      <small class="text-danger error-msg" *ngIf="errorMessage">
        {{ errorMessage }}
      </small>
      <!-- VALIDATION -->
      <div class="d-flex flex-row align-items-center justify-content-start w-100 pt-3">
        <button type="button" class="btn btn-primary form-btn" (click)="submitForm()">
          <div class="d-flex flex-row align-items-center justify-content-center">
            <div *ngIf="isLoading" class="spinner-border"></div>
            <span
              [ngClass]="{
                'ml-2': isLoading
              }"
              id="submit-btn-label"
              >{{ 'AFFILIE_APZ.CHANGE_ID.BTN.VALIDATE' | translate }}</span
            >
          </div>
        </button>
      </div>
    </div>
  </form>
</div>
