import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@shared/components/modal/modal.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { ApplicationCodeEnum } from 'src/app/shared/enums/application-code.enum';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { AuthToken } from 'src/app/shared/models/authtoken.model';
import { UserResponse } from 'src/app/shared/models/userResponse.model';
import { GatewayService } from 'src/app/shared/services/gateway.service';
import { GroundTruthDataService } from 'src/app/shared/services/ground-truth-data.service';
import { SiloApiService } from 'src/app/shared/services/siloApi.service';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';
import { UserProfileTypeEnum } from '../../../shared/enums/siloApi.enum';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Erreurs, CodeErreurs } from 'src/app/shared/models/errors.model';

@Component({
  selector: 'app-first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.scss'],
})
export class FirstStepComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  token: AuthToken;
  success = true;
  messageErreur: string;
  cardNumber: string;
  expirationDate: string;
  estApetiz: boolean;
  isBimpliMobile: boolean;
  isLoading: boolean;

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public saml: string;
  public type: string;

  public ApplicationCodeEnum = ApplicationCodeEnum;
  captchaResponse: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly modalService: ModalService,
    private readonly gtds: GroundTruthDataService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly reCaptchaV3Service: ReCaptchaV3Service) {

      this.type = this.activatedRoute.snapshot.paramMap.get('type');
  }

  ngOnInit() {
    // On nétoie le storage
    localStorage.removeItem('creditCard');
    localStorage.removeItem('expireDate');
    localStorage.removeItem('refClient');
    localStorage.removeItem('id');
    localStorage.removeItem('login');

    this.sessionStateService.setApplicationCode(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    const samlSubscription = this.sessionQuery.selectedSaml
      .pipe(
        tap((saml) => {
          this.saml = saml;
          const decodedSaml = atob(saml).split('|');
          this.cardNumber = decodedSaml[3];
          this.expirationDate = decodedSaml[4];
        }),
      )
      .subscribe();

    this.subscription.add(samlSubscription);

    this.applicationCode$.subscribe((code) => {
      this.estApetiz = code === ApplicationCodeEnum.APZ || code === ApplicationCodeEnum.APZ_MOBILE
      || code === ApplicationCodeEnum.BENEFIT_BENEF || code === ApplicationCodeEnum.BENEFIT_MOBILE;
      this.isBimpliMobile = (code === ApplicationCodeEnum.BENEFIT_MOBILE);
    });

    const combineSubscription = combineLatest([
      this.sessionQuery.selectedApplicationCode,
      this.sessionQuery.selectedRegistrationStep,
    ])
      .pipe(
        take(1),
        tap(([applicationCode, registrationStep]) => {
          if (applicationCode === ApplicationCodeEnum.CESU && registrationStep !== RegistrationStepEnum.FIRST_STEP) {
            // Only redirect to the login page when the applicationCode is CESU
            this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
          }
        }),
      )
      .subscribe();

    this.subscription.add(combineSubscription);
  }

  /**
   * Fonction qui gère le bouton valider de l'étape 1.
   * Appel à deux services qui permettent de valider que la carte peut être activée ou pas
   * Appel au service qui permet de récupérer les informations de l'utilisateur
   */
  send(cardNumber, expirationDate, refClient) {
    this.reCaptchaV3Service
      .execute('CreationAccountAction')
      .pipe(finalize(() => this.envoyerInfo(cardNumber, expirationDate, refClient, this.captchaResponse)))
      .subscribe((token) => (this.captchaResponse = token));
  }

  envoyerInfo(cardNumber, expirationDate, refClient, captchaResponse) {
    this.isLoading = true;
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.token = authToken;
      this.siloApiService
        .infosUser(cardNumber, expirationDate, undefined, undefined, authToken, captchaResponse)
        .pipe(finalize(() => {this.gtds.setIsSpinnerActivated(false); this.isLoading = false;}))
        .subscribe(
          (retour) => {
            this.success = true;
            localStorage.setItem('creditCard', cardNumber);
            localStorage.setItem('expireDate', expirationDate);
            localStorage.setItem('refCLient', refClient);
            this.retourInfoUser(retour);
          },
          (error) => {
            this.success = false;
            if (error.error.errors[0] && error.error.errors[0].code === CodeErreurs.FORBIDDEN_ACTION_087) {
              this.modalService.open('errorModal');
              this.messageErreur = this.translate.instant('ASP.FORBIDDEN_ACTION_087');
            } else {
              if (this.type === 'apetiz') {
                this.modalService.open('errorModal');
              }
              this.messageErreur = this.translate.instant('CREATION_ACCOUNT.FIRST_STEP.ERROR');
            }
          },
        );
    });
  }

  retourInfoUser(userTable: UserResponse) {
    if (userTable.items.length === 0) {
      this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/${this.saml}/${this.type}`]);
    } else {
      userTable.items.forEach((user) => {
        localStorage.setItem('id', user.id);
        // si le compte utilisateur est actif on redirige vers l'écran de connection
        if (user.active === 'ACTIVE' || user.active === 'INACTIVE') {
          // Cas déjà bénéficiaire CESU
          if (this.estApetiz && user.profiles.filter(p => p.applicationId ==='SiteAPZ' && p.type ==='EMPLOYEE').length === 0 &&
          user.profiles.filter(p => (p.applicationId ==='SiteCESUBenef' || p.applicationId ==='SiteCESU') &&
          p.type ==='EMPLOYEE').length > 0) {
            this.gatewayService.getAccessToken().subscribe((authToken) => {
            this.siloApiService.addUserProfile(authToken, user.id, 'SiteAPZ', UserProfileTypeEnum.EMPLOYEE)
            .subscribe((res)=>{
            // tslint:disable-next-line: max-line-length
            localStorage.setItem('accountAlreadyExist', 'Compte déjà existant : vous pouvez directement vous connecter en utilisant vos identifiants CESU.');
            this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);}
            );
            });
            // Cas déjà gestionnaire APZ
          } else if (this.estApetiz && user.profiles.filter(p => p.applicationId ==='SiteAPZ' && p.type ==='EMPLOYEE').length === 0
                      && this.estApetiz && user.profiles.filter(p => p.applicationId ==='SiteAPZ' && p.type ==='MANAGER').length > 0) {
            this.gatewayService.getAccessToken().subscribe((authToken) => {
              this.siloApiService.addUserProfile(authToken, user.id, 'SiteAPZ', UserProfileTypeEnum.EMPLOYEE)
                .subscribe((res)=>{
                  // permet d'afficher une pop up d'information
                  this.gtds.setIsCardActivated(true);
                  localStorage.setItem('accountAlreadyExist', 'Vous avez déjà un compte, connectez-vous avec vos identifiants ' +
                  this.estApetiz ? 'Apetiz' : 'Bimpli');
                  this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
                });
              });
            // Cas déjà inscrit
          } else {
            // permet d'afficher une pop up d'information
            this.gtds.setIsCardActivated(true);
            localStorage.setItem('accountAlreadyExist', 'Vous avez déjà un compte, connectez-vous avec vos identifiants Apetiz');
            this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
          }
        } else if (user.active === 'PENDING_ACTIVATION') {
          this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}/${this.type}`]);
        } else {
          this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/${this.saml}/${this.type}`]);
        }
      });
    }
  }

  cancel() {
    window.history.back();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
