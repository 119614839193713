import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CesuRegistrationStatusEnum } from 'src/app/shared/enums/cesu.enum';
import { CesuState } from 'src/app/shared/states/cesu-state.model';


@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cesu' })
export class CesuStore extends Store<CesuState> {

  constructor() {
    super(createInitialState());
  }

}

export function createInitialState(): CesuState {
  return {
    cesuRegistrationStatus: CesuRegistrationStatusEnum.NONE,
    registrationLogin: undefined,
    registrationPassword: undefined,
    showFooter: true,
  } as CesuState;
}

