import { Component, OnInit } from '@angular/core';
// import { Favicons } from '../../../shared/favicons';
import { WeezenComponent } from '../../weezen/weezen.component';

@Component({
  selector: 'app-rgpd-asp',
  templateUrl: './rgpd-asp.component.html',
  styleUrls: ['../../../../assets/css/asp-global.scss', './rgpd-asp.component.scss']
})
export class RgpdAspComponent extends WeezenComponent implements OnInit {

  constructor(
    // fav: Favicons
    ) {
    // super(fav);
    super();
    window.open('https://www.weezen.fr/assets/pdf/noticeGDPR.pdf', '_blank');
  }

  ngOnInit() {

  }
}
