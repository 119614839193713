<app-apz-change-question
  *ngIf="applicationId === ApplicationCodeEnum.APZ_MOBILE"
  [login]="login"
  (submitted)="submit($event)"
></app-apz-change-question>
<app-apz-web-change-question
  *ngIf="applicationId === ApplicationCodeEnum.APZ"
  [login]="login"
  (submitted)="submit($event)"
  [saml]="saml"
></app-apz-web-change-question>
<app-bimpli-change-question
    *ngIf="applicationId === ApplicationCodeEnum.BENEFIT_MOBILE || applicationId === ApplicationCodeEnum.BENEFIT_BENEF
          || applicationId === ApplicationCodeEnum.BENEFIT_FINANCEUR|| applicationId === ApplicationCodeEnum.BENEFIT_CAGNOTTE"
  [login]="login"
    [applicationId]="applicationId"
  (submitted)="submit($event)"
  [saml]="saml"
    [messageErreur]="messageErreur"
></app-bimpli-change-question>
