import { FormGroup } from '@angular/forms';

export class LoginHelper {
  /**
   * Method use when redirecting the user to the login screen when the SAML is not available
   * (ex: activation-compte, confirmation-demande-mot-de-passe)
   * @param html the html content containing the form to submit
   */
  public static setPageHtml(html: string): void {
    document.body.innerHTML = html;
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.submit();
  }

  /**
   * Allow to check if the fields password and confirmationPassword of a form are the same or not
   * @param group the form on which the fields to check are
   */
  public static checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('passwordConfirmation').value;

    return pass === confirmPass ? null : { notSame: true };
  }
}
