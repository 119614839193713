import { Component, OnDestroy, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SessionQuery } from '@services/store/session/session.query';
import { Observable, Subscription } from 'rxjs';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { TealiumService } from '@services/tealium/tealium.service';
import { SessionStateService } from '@services/store/session/session-state.service';

@Component({
  selector: 'app-bimpli-choice',
  templateUrl: './bimpli-choice.component.html',
  styleUrls: ['./bimpli-choice.component.scss']
})
export class BimpliChoiceComponent implements OnInit, OnDestroy {

  constructor( private readonly sessionQuery: SessionQuery, private readonly router: Router,
               private readonly activatedRoute: ActivatedRoute,
               private readonly tealiumService: TealiumService,
               private readonly sessionStateService: SessionStateService) {
    this.saml = this.activatedRoute.snapshot.paramMap.get('saml');
  }

  public saml: string;
  public applicationId: string;
  private subscription: Subscription = new Subscription();
  public applicationCode$: Observable<ApplicationCodeEnum>;
  public ApplicationCodeEnum = ApplicationCodeEnum;

  ngOnInit(): void {
    this.sessionStateService.setApplicationCode(this.activatedRoute);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    const applicationCodeSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(
        tap((applicationCode: ApplicationCodeEnum) => {
          switch (applicationCode) {
            case ApplicationCodeEnum.BENEFIT_BENEF:
              this.tealiumService.view('bimpli_benef.web.register.registerChoicePageLoad');
              break;
            default:
              this.applicationId = applicationCode;
          }
        })
      )
      .subscribe();
  }

  back(): void {
    window.history.back();
  }

  gotoFirstStep(type: string): void {
    this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_FIRST_STEP}/${this.saml}/${type}`);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
