import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '@services/login/login.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { TealiumService } from '@services/tealium/tealium.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-cesu-forgotten-pwd',
  templateUrl: './cesu-forgotten-pwd.component.html',
  styleUrls: ['./cesu-forgotten-pwd.component.scss', '../../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuForgottenPwdComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationId: ApplicationCodeEnum;
  public saml: string;

  public forgottenPwdForm: FormGroup;
  public submitted = false;
  public success = false;
  public errorMessage: string;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly loginService: LoginService,
    private readonly tealiumService: TealiumService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  public ngOnInit(): void {
    const applicationIdSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(tap((applicationId) => (this.applicationId = applicationId)))
      .subscribe();

    this.subscription.add(applicationIdSubscription);

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml: string) => this.saml = saml)).subscribe();

    this.subscription.add(samlSubscription);

    this.forgottenPwdForm = this.formBuilder.group({
      login: [undefined, [Validators.required]],
    });

    this.tealiumService.view('cesu.forgottenPassword.pageLoad');
  }

  public get form() {
    return this.forgottenPwdForm.controls;
  }

  public sendRequestToChangeMail() {
    this.submitted = true;
    this.tealiumService.link('cesu.forgottenPassword.validateEmailClick');

    if (this.forgottenPwdForm.valid) {
      this.reCaptchaV3Service
      .execute('ConnecterAction').subscribe((token) => {
        this.loginService.resetPassword(this.applicationId, this.forgottenPwdForm.value.login, token)
        .pipe(
          tap(() => {
            this.success = true;
            this.sessionStateService.setForgottenPasswordLogin(this.forgottenPwdForm.value.login);
            this.router.navigate([`${RoutesEnum.FORGOTTEN_PASSWORD_MAIL}/${this.saml}`]);
          }),
          catchError((err) => {
            this.errorMessage = err.message;
            this.success = false;
            throw err;
          }),
        )
        .subscribe();
      });


    } else if (this.forgottenPwdForm.invalid) {
      this.success = false;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
