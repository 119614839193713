import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import decodeCodeApp from 'src/app/shared/helpers/decoder.helper';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { CodeErreurs, Erreurs } from '../../shared/models/errors.model';
import { GatewayService } from '../../shared/services/gateway.service';
import { SiloApiService } from '../../shared/services/siloApi.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { ModalService } from '@shared/components/modal/modal.service';
import { Title } from '@angular/platform-browser';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-changement-identifiant',
  templateUrl: './changement-identifiant.component.html',
  styleUrls: ['./changement-identifiant.component.scss']
})
export class ChangementIdentifiantComponent implements OnInit {
  public messageErreur: string;
  public login: string;
  public applicationId: string;
  public estAppetiz: boolean;
  public estCesu: boolean;
  public estASP: boolean;
  public redirectUrl: string;
  public estMobileAPZ: boolean;
  public estBimpli = false;
  public identifiantOK: boolean;
  public saml: string;
  public showConfimationModifLogin: boolean;
  estAffilie: boolean;
  public isLoading = false;

  private valueCaptcha: string;

  favIcon: HTMLLinkElement = document.querySelector('#favIcon');

  constructor(
    private route: ActivatedRoute,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
    private titleService: Title,
    private modalService: ModalService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    this.saml = this.route.snapshot.paramMap.get('saml');
    const samlDecoder = atob(this.saml);
    this.login = samlDecoder.split(';')[0];
    this.applicationId = samlDecoder.split(';')[1];
    this.redirectUrl = samlDecoder.split(';')[2];

    const decoded = decodeCodeApp(this.applicationId);
    if (decoded.estAppetizMobile) {
      this.applicationId = 'SiteAPZ';
    }
    if (ApplicationCodeEnum.BENEFIT_CAGNOTTE ===  this.applicationId || ApplicationCodeEnum.BENEFIT_FINANCEUR ===  this.applicationId
      || ApplicationCodeEnum.BENEFIT_BENEF ===  this.applicationId || ApplicationCodeEnum.BENEFIT_MOBILE ===  this.applicationId) {
      this.estBimpli = true;
      this.favIcon.href = '/assets/bimpli/favicon.ico';
      this.titleService.setTitle('Mon espace personnel Bimpli');
    }
    this.estAppetiz = decoded.estAppetiz;
    this.estCesu = decoded.estCesu;
    this.estMobileAPZ = decoded.estAppetizMobile;
    this.estASP = decoded.estASP;
    this.estAffilie = decoded.estAffilie;
    this.showConfimationModifLogin = false;
  }

  public updateUser(oldPwd: string, newId: string): void {
    this.isLoading = true;
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
      .execute('ConnecterAction')
      .pipe(finalize(() => {
        this.siloApiService.updateUser(this.login, newId, this.applicationId, oldPwd, null, authToken, this.valueCaptcha).subscribe(
          () => {
            this.identifiantOK = true;
            this.snackbarService.showSuccess('Mise à jour effectuée');
            this.redirection();
            this.isLoading = false;
          },
          (error) => this.gererErreurs(error)
        )
      }))
      .subscribe((token) => (this.valueCaptcha = token));
    },
      err => {
        this.isLoading = false;
        this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
      });

  }
  public redirection(): void {
    this.showConfimationModifLogin = true;
    if ( this.redirectUrl) {
      window.location.href =  this.redirectUrl;
    }
  }
  public gererErreurs(error: HttpErrorResponse): void {
    this.isLoading = false;
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = JSON.parse(error.error);
      if (errors && errors.errors && errors.errors.length > 0) {
        switch (errors.errors[0].code) {
          case CodeErreurs.UNEXPECTED_011:
            this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED_011');
            break;
          case CodeErreurs.INCORRECT_DATA_007:
            this.messageErreur = this.translateService.instant('COMMON.USER.NEWLOGIN_INCORRECT');
            break;
          case CodeErreurs.ALREADY_EXIST_022:
            this.messageErreur = this.translateService.instant('COMMON.USER.LOGIN_ALREADY_EXISTS');
            break;
          case CodeErreurs.FORBIDDEN_ACTION_010:
            this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.FORBIDDEN_ACTION_010');
            break;
          default:
            this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED') + errors.errors[0].code;
            break;
        }
      } else if (error.status === 500) {
        this.messageErreur = this.translateService.instant('COMMON.ERROR.500');
      }
    }
    if (this.estBimpli) {
      this.modalService.open('errorModal');
    } else {
      this.snackbarService.showDanger(this.messageErreur);
    }
  }
}
