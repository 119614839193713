import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TealiumService } from '@services/tealium/tealium.service';
import { ModalService } from '@shared/components/modal/modal.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { environment } from '../../../../../environments/environment.local';

@Component({
  selector: 'app-first-step-bimpli-apetiz',
  templateUrl: './bimpli-apetiz.component.html',
  styleUrls: ['./bimpli-apetiz.component.scss', '../../../../../assets/css/bimpli-global.scss']
})
export class BimpliApetizFirstStepComponent implements OnInit, OnChanges {

  cardForm = this.fb.group({
    numCard: [undefined, [Validators.required, Validators.minLength(16), Validators.maxLength(16), Validators.pattern('[0-9]{16}')]],
    expirationDate: [undefined, [Validators.required, Validators.minLength(4),
      Validators.maxLength(4), Validators.pattern('(0[1-9]|1[012])([0-9]{2})')]],
    refClient: [undefined, [Validators.required, Validators.minLength(1), Validators.maxLength(120)]],
  });

  @Output() validate: EventEmitter<any[]> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input() messageErreur: string;
  @Input() success: boolean;
  @Input() isLoading: boolean;
  @Input() cardNumber: string;
  @Input() inlined = false;
  @Input() expirationDate: string;
  @Input() isMobile: boolean;
  @Input() saml: string;
  @Input() applicationId: string;
  @ViewChild('expiration', { static: false }) expiraDateField: ElementRef;

  constructor(private fb: FormBuilder,
              private readonly tealiumService: TealiumService,
              private readonly modalService: ModalService ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messageErreur) {
      if (this.messageErreur) {
        this.modalService.open('errorModal');
      }
    }
  }

  ngOnInit() {
    if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
      this.tealiumService.view('bimpli_benef.web.register.apetiz.cardPageLoad');
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      this.tealiumService.view('bimpli_mobile.web.register.apetiz.cardPageLoad');
    }
    if (this.expirationDate && this.cardNumber) {
      this.cardForm.setValue({
        numCard: this.cardNumber,
        expirationDate: this.expirationDate
      });
    }
  }

  send() {
    if (!this.inlined) {
      this.tealiumService.link('apz.mobile.registrationProcess.validateRegistrationClick');
    }
    this.validate.emit(this.cardForm.getRawValue());
  }

  back(): void {
    window.history.back();
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }

}
