import { Component, OnInit } from '@angular/core';
// import { Favicons } from '../../shared/favicons';

@Component({
  selector: 'app-weezen',
  template: ''
})
export class WeezenComponent implements OnInit {

  // favicons: Favicons;


  /*constructor(private fav: Favicons) {
    this.favicons = fav;
    this.favicons.activate('wee');
  }*/

  ngOnInit() {
  }

}
