import { Affiliate, ReadAffiliatesParamQuery } from './affiliate';

export class MerchantSignupAffiliatesData {
  affiliatesQuery: ReadAffiliatesParamQuery;
  affiliates: Affiliate[];

  constructor(affiliatesQuery: ReadAffiliatesParamQuery, affiliates: Affiliate[]) {
    this.affiliatesQuery = affiliatesQuery;
    this.affiliates = affiliates;
  }
}
