import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
@Component({
  selector: 'app-merchants-signup',
  templateUrl: './merchants-signup.component.html',
  styleUrls: ['./merchants-signup.component.scss']
})
export class MerchantsSignupComponent implements OnInit, OnDestroy {
  public step = 1;
  public panelContent$ = new BehaviorSubject<string>(null);
  public isPanelOpen$ = new BehaviorSubject<boolean>(false);
  public email = '';

  public saml: string;
  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService
  ) {}

  ngOnInit(): void {
    this.sessionStateService.setApplicationCode(this.route);

    this.sessionQuery.selectedSaml
      .pipe(
        takeUntil(this.destroy$),
        tap((saml) => (this.saml = saml))
      )
      .subscribe();
  }

  public goToPanel(goTo: string): void {
    this.panelContent$.next(goTo);
  }

  public openPanel(content: string): void {
    this.goToPanel(content);
    this.isPanelOpen$.next(true);
  }

  public closePanel(): void {
    this.isPanelOpen$.next(false);
    this.goToPanel(null);
  }

  public goToCoordonnees(): void {
    this.step = 2;
  }

  public goToLastStep(email: string): void {
    this.email = email;
    this.step = 3;
  }

  public canAccess(): void {
    this.closePanel();
    this.goToCoordonnees();
  }

  public goToFirstStep(): void {
    this.step = 1;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
