import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class EmailHelper {
  static customEmailValidator(error: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value as string;
      return email ? (this.checkEmailGlobalStructure(email) ? null : { pattern: { value: error } }) : null;
    };
  }

  private static checkEmailGlobalStructure(email: string): boolean {
    return (
      this.rglEmail001(email) &&
      this.rglEmail002(email) &&
      this.rglEmail003(email) &&
      this.rglEmail004(email) &&
      this.rglEmail006(email) &&
      this.checkEmailLocalPart(email) &&
      this.checkEmailDomainNamePart(email) &&
      this.checkEmailExtensionDomainPart(email)
    );
  }

  private static rglEmail001(email: string): boolean {
    return email.substring(0, email.lastIndexOf('@')).length >= 1;
  }

  private static rglEmail002(email: string): boolean {
    const separatorIndex = email.lastIndexOf('@');
    return (
      separatorIndex !== -1 && email.substring(0, separatorIndex).length >= 1 && email.substring(separatorIndex, email.length).length >= 1
    );
  }

  private static rglEmail003(email: string): boolean {
    return email.substring(email.lastIndexOf('@'), email.lastIndexOf('.')).length >= 1;
  }

  private static rglEmail004(email: string): boolean {
    return email.substring(email.lastIndexOf('.'), email.length).length >= 1;
  }

  private static rglEmail006(email: string, expectedLength = 80): boolean {
    return email.length <= expectedLength;
  }

  private static checkEmailLocalPart(email: string): boolean {
    const localPart = email.substring(0, email.lastIndexOf('@'));
    return (
      this.rglEmail101(localPart) &&
      this.rglEmail102(localPart) &&
      this.rglEmail103(localPart) &&
      this.rglEmail104(localPart) &&
      this.rglEmail105(localPart) &&
      this.rglEmail106(localPart)
    );
  }

  private static rglEmail101(localPart: string): boolean {
    const reg = new RegExp(`[a-zA-Z.!#$%&'*/=?^_+-\`{]`, 'gmu');
    return reg.test(localPart);
  }

  private static rglEmail102(localPart: string): boolean {
    return localPart.charAt(0) !== '.' && localPart.charAt(localPart.length - 1) !== '.';
  }

  private static rglEmail103(localPart: string): boolean {
    const reg = new RegExp('[ ]', 'gmu');
    return reg.test(localPart) ? false : true;
  }

  private static rglEmail104(localPart: string): boolean {
    const reg = new RegExp('[ïçîèôéùള¿]', 'gmu');
    return reg.test(localPart) ? false : true;
  }

  private static rglEmail105(localPart: string): boolean {
    const reg = new RegExp('[.]{3,}', 'gmu');
    return reg.test(localPart) ? false : true;
  }

  private static rglEmail106(localPart: string): boolean {
    return localPart.length >= 1;
  }

  private static checkEmailDomainNamePart(email: string): boolean {
    const domainName = email.substring(email.lastIndexOf('@') + 1, email.lastIndexOf('.'));
    return (
      this.isEmailDomainName(email) &&
      this.isValidCharPosition(domainName, domainName.length - 1, '.') &&
      this.rglEmail201(domainName) &&
      this.rglEmail202(domainName) &&
      this.rglEmail203(domainName) &&
      this.rglEmail204(domainName) &&
      this.rglEmail205(domainName) &&
      this.rglEmail206(domainName) &&
      this.rglEmail207(domainName)
    );
  }

  private static rglEmail201(domainName: string): boolean {
    const reg = new RegExp('[a-zA-Z0-9.-]', 'gmu');
    return reg.test(domainName);
  }

  private static rglEmail202(domainName: string): boolean {
    return domainName.charAt(0) !== '-' && domainName.charAt(domainName.length - 1) !== '-';
  }

  private static rglEmail203(domainName: string): boolean {
    const reg = new RegExp('[ ]', 'gmu');
    return reg.test(domainName) ? false : true;
  }

  private static rglEmail204(domainName: string): boolean {
    const reg = new RegExp('[_¿+,]|(xn--|-7ka)', 'gmu');
    return reg.test(domainName) ? false : true;
  }

  private static rglEmail205(domainName: string): boolean {
    const reg = new RegExp('[.]{2,}', 'gmu');
    return reg.test(domainName) ? false : true;
  }

  private static rglEmail206(domainName: string): boolean {
    return domainName.length >= 1;
  }

  private static rglEmail207(domainName: string): boolean {
    const dotIndex = domainName.indexOf('.');
    return dotIndex !== -1 &&
      ((domainName.charAt(dotIndex + 1) && domainName.charAt(dotIndex + 1) === '-') ||
        (domainName.charAt(dotIndex - 1) && domainName.charAt(dotIndex - 1) === '-'))
      ? false
      : true;
  }

  private static isEmailDomainName(email: string): boolean {
    return email.lastIndexOf('@') < email.lastIndexOf('.');
  }

  private static checkEmailExtensionDomainPart(email: string): boolean {
    const extensionDomain = email.substring(email.lastIndexOf('.') + 1, email.length);
    return this.rglEmail301(extensionDomain) && this.rglEmail302(extensionDomain) && this.rglEmail303(extensionDomain);
  }

  private static rglEmail301(extensionDomain: string): boolean {
    const reg = new RegExp('[a-zA-Z]', 'gmu');
    return reg.test(extensionDomain);
  }

  private static rglEmail302(extensionDomain: string): boolean {
    const reg = new RegExp('[0-9- ]', 'gmu');
    return reg.test(extensionDomain) ? false : true;
  }

  private static rglEmail303(extensionDomain: string): boolean {
    return extensionDomain.length >= 1;
  }

  private static isValidCharPosition(data: string, position: number, char: string): boolean {
    return data.charAt(position) !== char;
  }
}
