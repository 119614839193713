import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
})
export class NoticeComponent implements OnInit {
  public applicationCode$: Observable<ApplicationCodeEnum>;

  saml: string;
  codApp: string;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
  ) {
  }

  public ngOnInit(): void {
    this.sessionStateService.setApplicationCodeFromUnknownRoute(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    this.saml = this.route.snapshot.paramMap.get('saml');
    const samlDecoder = atob(this.saml);
    this.codApp = samlDecoder.split('|')[2];
  }

}
