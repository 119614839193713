import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { finalize } from 'rxjs/operators';
import { GatewayService } from '../../../shared/services/gateway.service';
import { SiloApiService } from '../../../shared/services/siloApi.service';
import { TealiumService } from '../../../shared/services/tealium/tealium.service';

@Component({
  selector: 'app-apz-change-id',
  templateUrl: './apz-change-id.component.html',
  styleUrls: ['./apz-change-id.component.scss', '../../../../assets/css/apz-global.scss']
})
export class ApzChangeIdComponent implements OnInit {
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Input() errorMessage: string;
  @Input() identifiantOK: boolean;
  @Input() login: string;

  identifiantForm = this.fb.group({
    currentPassword: [undefined, Validators.required],
    newLogin: [undefined, Validators.required]
  });

  isSecondStep = false;
  hide = true;
  currentPasswordInvalid = false;
  private valueCaptcha: string;

  constructor(
    private fb: FormBuilder,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private readonly tealiumService: TealiumService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    this.tealiumService.view('apz.mobile.profile.changeLoginPageLoad');
  }

  goToSecondStep() {
    this.tealiumService.link('apz.mobile.profile.validateChangeLoginClick');
    if (this.identifiantForm.get('currentPassword').valid) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.reCaptchaV3Service
          .execute('UserPasswordCheckAction')
          .pipe(
            finalize(() => {
              this.siloApiService
                .userPasswordCheck(
                  this.login,
                  this.identifiantForm.get('currentPassword').value,
                  'SiteAPZMobile',
                  authToken,
                  this.valueCaptcha
                )
                .subscribe((retour) => {
                  this.currentPasswordInvalid = !retour.resultCheck;
                  this.isSecondStep = !this.currentPasswordInvalid;
                  if (this.isSecondStep) {
                    this.tealiumService.view('apz.mobile.profile.changeLoginChooseLoginPageLoad');
                  }
                });
            })
          )
          .subscribe((token) => (this.valueCaptcha = token));
      });
    }
  }

  submitForm() {
    this.tealiumService.link('apz.mobile.profile.validateChangeLoginChooseLoginClick');
    if (this.identifiantForm.valid) {
      this.submitted.emit(this.identifiantForm.getRawValue());
    }
  }
}
