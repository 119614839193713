<div class="card">
    <div class="card-header">
        <h2>Access Token Gateway</h2>
    </div>
    <div class="card-body">
        <div>
            <label>Access Token</label
            ><input [formControl]="accessToken" type="text" class="form-control" readonly="" />
        </div>
        <br />
        <div>
            <label>Expires in</label><input [formControl]="expiresIn" type="text" class="form-control" readonly="" />
        </div>
        <br />
        <div><label>Scopes</label><input [formControl]="scopes" type="text" class="form-control" readonly="" /></div>
        <br />
        <div>
            <label>Token Type</label><input [formControl]="tokenType" type="text" class="form-control" readonly="" />
        </div>
        <br />
    </div>
</div>
