import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { RoutesEnum } from '../../../../shared/enums/routes.enum';
import { EmailHelper } from '../../../../shared/helpers/email.helper';

@Component({
  selector: 'app-second-step-affilie-apz-component',
  templateUrl: './second-step-affilie-apz.component.html',
  styleUrls: ['../../../../../assets/css/affilie-apz-global.scss', './second-step-affilie-apz.component.scss']
})
export class SecondStepAffilieApzComponent implements OnInit, OnDestroy {
  // Variables
  @Input() saml: string;
  @Input() messageErreur: string;
  @Input() success: boolean;
  @Output() submitted: EventEmitter<any> = new EventEmitter();

  submittedForm = false;

  isLoading = false;
  mySubscription: Subscription = new Subscription();
  passwordEqual = false;
  loginEqual = false;

  passwordForm: FormGroup = this.fb.group({
    password: ['', validatePassword()],
    confirmPassword: ['', Validators.required],
    login: [
      '',
      [Validators.required, EmailHelper.customEmailValidator('AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.SECOND_STEP.INVALID_EMAIL_PATTERN')]
    ],
    confirmLogin: ['', Validators.required],
    cgu: [false, Validators.requiredTrue]
  });

  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Constructor
  constructor(private fb: FormBuilder, private gtds: GroundTruthDataService, private router: Router) {}

  // Methods
  ngOnInit(): void {
    this.success = true;
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
  }

  controlPassWord(): void {
    this.passwordEqual = this.passwordForm.get('password').value === this.passwordForm.get('confirmPassword').value;
  }

  controlLogin(): void {
    this.loginEqual = this.passwordForm.get('login').value === this.passwordForm.get('confirmLogin').value;
  }

  submitForm(): void {
    if (this.passwordForm.valid && this.passwordEqual && this.loginEqual) {
      this.submittedForm = true;
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit({ form: this.passwordForm.getRawValue() });
    }
  }

  goBack(): void {
    this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_IDENTITY_STEP}/${this.saml}`);
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }
}
