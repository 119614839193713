import { Component, OnInit } from '@angular/core';
import {tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {SessionStateService} from '@services/store/session/session-state.service';
import {Observable, Subscription} from 'rxjs';
import {ApplicationCodeEnum} from '@shared/enums/application-code.enum';
import {SessionQuery} from '@services/store/session/session.query';

@Component({
  selector: 'app-identity-step',
  templateUrl: './identity-step.component.html',
  styleUrls: ['./identity-step.component.scss']
})
export class IdentityStepComponent implements OnInit {

  private subscription: Subscription = new Subscription();
  public applicationCode$: Observable<ApplicationCodeEnum>;
  public saml: string;
  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionStateService: SessionStateService,
    private readonly sessionQuery: SessionQuery,
  ) { }

  ngOnInit(): void {
    this.sessionStateService.setApplicationCode(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    const samlSubscription = this.sessionQuery.selectedSaml
      .pipe(
        tap((saml) => {
          this.saml = saml;
          const decodedSaml = atob(saml).split('|');
        }),
      )
      .subscribe();

    this.subscription.add(samlSubscription);
  }

}
