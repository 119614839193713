import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '@services/login/login.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { TealiumService } from '@services/tealium/tealium.service';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { catchError, tap } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-cesu-forgottent-login',
  templateUrl: './cesu-forgottent-login.component.html',
  styleUrls: ['./cesu-forgottent-login.component.scss', '../../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuForgottentLoginComponent implements OnInit {

  public forgottenLoginForm: FormGroup;
  public submitted = false;
  public success = false;
  public errorMessage: string;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly loginService: LoginService,
    private readonly tealiumService: TealiumService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  ngOnInit(): void {
    this.forgottenLoginForm = this.formBuilder.group({
      email: [undefined, [Validators.required, Validators.email]],
    });

    this.tealiumService.view('cesu.forgottenLogin.pageLoad');
  }

  public get form() {
    return this.forgottenLoginForm.controls;
  }

  public sendForgottenLogin() {
    this.submitted = true;
    this.tealiumService.link('cesu.forgottenLogin.validateEmailClick');

    if (this.forgottenLoginForm.valid) {
      this.reCaptchaV3Service
      .execute('ConnecterAction').subscribe((token) => {
        this.loginService.forgottenLogin(
          this.forgottenLoginForm.value.email,
          this.sessionQuery.getValue().applicationCode,
          token
        ).pipe(
          tap(() => {
            this.sessionStateService.setForgottenLoginMail(this.forgottenLoginForm.value.email);
            this.router.navigate([`${RoutesEnum.FORGOTTEN_LOGIN_MAIL}/${this.sessionQuery.getValue().saml}`]);
          }),
          catchError((err) => {
            this.errorMessage = err.message;
            throw err;
          }),
        )
          .subscribe();
      })
      ;

    }
  }

}
