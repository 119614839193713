<div class="img-row">
    <img src=" ../../../assets/asp/logo_wee.png" class="logo-img" />
</div>

<div class="bandeau">
    {{'ASP.FORGOTTEN_ID.TITLE' | translate}}
</div>

<div *ngIf="errorMessage" class="subHeaderError d-flex align-items-center justify-content-center">
    <i class="icon-info mr-3 d-flex align-items-center"></i>
    <p class="m-0">{{errorMessage}}</p>
</div>

<form [formGroup]="forgottenLoginForm"  class="form-asp">
    <section class="col-12 col-md-8">
        <div class="form-group">
            <label for="identifiant"> {{'ASP.FORGOTTEN_ID.LABEL' | translate}}</label>
            <input type="email" class="form-control" id="identifiant" aria-describedby="idHelp" placeholder="Email"
                formControlName="identifiant">

            <div *ngIf="forgottenLoginForm.get('identifiant').touched && forgottenLoginForm.get('identifiant').invalid">
                <small class="form-text text-danger" *ngIf="forgottenLoginForm.get('identifiant').errors.required">
                    {{'COMMON.FORM.REQUIRED' | translate}}
                </small>
                <small class="form-text text-danger" *ngIf="forgottenLoginForm.get('identifiant').errors.email">
                    {{'COMMON.FORM.EMAIL_INVALID' | translate}}
                </small>
            </div>
        </div>

        <div class="actions">
            <button type="button" class="btn btn-secondary" (click)="cancel()">
                {{'COMMON.FORM.BACK' | translate | uppercase}}
            </button>
            <nit-spinner-button (clicked)="submit()" label="{{'COMMON.FORM.SUBMIT' | translate | uppercase}}" [disabled]="!forgottenLoginForm.valid" [isLoading]="isLoading" css="btn btn-primary btn-large" >
            </nit-spinner-button>
        </div>
    </section>
</form>

<!-- Modal -->
<app-modal id="confirmModal">
    <app-header-modal titleModal="{{'ASP.FORGOTTEN_ID.MODAL_HEADER' | translate}}" (modalClose)="cancelPopUp()">
    </app-header-modal>
    <div class="container pt-4 popUp text-center">
        <b>{{'ASP.FORGOTTEN_ID.MODAL_TEXT' | translate}}</b>
        <br />
        <p>{{'ASP.FORGOTTEN_ID.CHECK' | translate}}</p>
    </div>
    <div class="actions">
        <button type="button" class="btn btn-secondary"
            data-dismiss="modal" (click)="cancelPopUp()">{{'ASP.FORGOTTEN_ID.CLOSE' | translate}}</button>
    </div>
</app-modal>
