<app-cesu-layout>
    <div class="cesu-legal-notice">
        <div class="pt-5 pb-4 cesu-page-title">
            Mentions légales
        </div>

        <div class="bg-white p-5">
            <p>Vous êtes actuellement connecté au site
                <a class="cesu-link" href="https://www.bimpli.com">www.bimpli.com</a> qui est la propriété exclusive de
                <strong>Bimpli</strong>.</p>
            <p>Dénomination sociale : <strong>Bimpli, société par actions simplifiée au capital social de 1 002 700 euros,
                immatriculée au RCS Paris 833 672 413.</strong></p>
            <p>Siège social : <strong>110 avenue de France - 75013 Paris</strong></p>
            <p>Adresse e-mail : <a class="cesu-link"
                                   href="mailto:service.clients@bimpli.com">service.clients@bimpli.com</a></p>
            <p>Directeur de la publication : M. Stéphan Dixmier, Directeur Général</p>
            <p>NAF : <strong>6619B</strong></p>
            <p>SIREN : <strong>833 672 413</strong></p>
            <p>N° de TVA intracommunautaire : <strong>FR00833672413</strong></p>
            <p><strong>Bimpli</strong>, dans le cadre de son activité d’émetteur de CESU, est soumise…</p>
            <p>Hébergeur du site internet : Natixis Altaïr IT Shared Services, 4 rue Charles Gounod, 77185 LOGNES. Tél.
                01.58.19.11.00</p>
        </div>
    </div>
</app-cesu-layout>
