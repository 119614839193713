<div id="left-panel" class="h-100 container">
  <div class="p-2">
    <div class="mb-2 title text-left">
      {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LATERAL_PANEL.TITLE_LINE" | translate }}
    </div>
    <div class="subtitle text-left mt-4">
      {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LATERAL_PANEL.SUBTITLE_1" | translate }}
    </div>
    <div class="subtitle text-left mt-4">
      {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LATERAL_PANEL.SUBTITLE_2" | translate }}
    </div>
  </div>
</div>
