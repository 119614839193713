<div id="first-step-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row h-100">
    <div
      id="menu-container"
      class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 p-0"
      [ngClass]="{
        'col-12 col-sm-12': (isStepOpen$ | async) === true,
        'd-none d-md-block': (isStepOpen$ | async) === false
      }"
    >
      <app-affiliate-apz-stepper [step]="1" (isStepOpen)="isStepOpen$.next($event)"></app-affiliate-apz-stepper>
    </div>
    <div
      id="step-container"
      class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 w-100 h-100 p-3"
      [ngClass]="{
        'd-none d-md-block': (isStepOpen$ | async) === true,
        'col-12 col-sm-12': (isStepOpen$ | async) === false
      }"
    >
      <div id="coordonnees-wrapper" class="d-flex flex-column align-items-start justify-content-start w-100 h-100 p-3">
        <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="d-flex align-items-center justify-content-end mb-2 d-md-none">
            <span id="stepper-pos">{{ 'AFFILIE_APZ.SIGNUP_AFFILIATE_MANAGER.FIRST_STEP.STEP_SM' | translate }}</span>
          </div>
          <app-identity
            [parentForm]="coordonneesForm"
            [withRoles]="true"
            [initialData]="initialData"
            (coodonneeValidated)="nextStep()"
          ></app-identity>
        </div>
      </div>
    </div>
  </div>
</div>
