export enum RoutesEnum {
  LOGIN = 'login',
  ACTIVATE_ACCOUNT = 'activationCompte',
  CREATE_ACCOUNT = 'create-account',
  CREATE_ACCOUNT_CHOICE = 'create-account/choice',
  CREATE_ACCOUNT_FIRST_STEP = 'create-account/first-step',
  CREATE_ACCOUNT_FIRST_STEP_INFO = 'create-account/first-step-info',
  CREATE_ACCOUNT_SECOND_STEP = 'create-account/second-step',
  CREATE_ACCOUNT_THIRD_STEP = 'create-account/third-step',
  CREATE_ACCOUNT_FOURTH_STEP = 'create-account/fourth-step',
  CREATE_ACCOUNT_MAIL = 'create-account/mail',
  CREATE_ACCOUNT_IDENTITY_STEP = 'create-account/identity-step',
  SIGNUP_AFFILIE_FIRST_STEP = 'signup-affilie/first-step',
  SIGNUP_AFFILIE_SECOND_STEP = 'signup-affilie/second-step',
  SIGNUP_AFFILIE_THIRD_STEP = 'signup-affilie/third-step',
  SIGNUP_AFFILIE_FOURTH_STEP = 'signup-affilie/fourth-step',
  SIGNUP_AFFILIATE_MANAGER_FIRST_STEP = 'signup-affiliate-manager/first-step',
  SIGNUP_AFFILIATE_MANAGER_SECOND_STEP = 'signup-affiliate-manager/second-step',
  SIGNUP_AFFILIATE_MANAGER_THIRD_STEP = 'signup-affiliate-manager/third-step',
  MERCHANTS_INVITE_CONTACT_INFO = 'merchants-invite/contact-info',
  MERCHANTS_INVITE_ACCOUNT_EXISTS = 'merchants-invite/account-exists',
  TECHNICAL_ERROR = 'merchants-invite/technical-error',
  FORGOTTEN_PASSWORD = 'renouvellement-mot-de-passe',
  FORGOTTEN_PASSWORD_MAIL = 'renouvellement-mot-de-passe/mail',
  CHANGE_FORGOTTEN_PASSWORD = 'reinitMotDePasse',
  CHANGE_FORGOTTEN_PASSWORD_SUCCESS = 'reinitMotDePasse/success',
  FORGOTTEN_LOGIN = 'identifiant-oublie',
  FORGOTTEN_LOGIN_MAIL = 'identifiant-oublie/mail',
  CHANGE_PASSWORD = 'changement-mot-de-passe',
  CHANGE_PASSWORD_SUCCESS = 'changement-mot-de-passe/success',
  CHANGE_QUESTION = 'changement-question',
  LEGAL_NOTICE = 'notice',
  GDPR = 'rgpd',
  TERMS_AND_CONDITIONS = 'cgu',
  PIN_CODE = 'pin-code',
  CHECK_USER = 'check-user',
  VERSION = 'version',
  MERCHANTS_SIGNUP = 'merchants/signup',
  RIGHT_PANEL = 'panel',
  SIGNUP_SIRET_NOT_FOUND = 'siret-not-found',
  SIGNUP_SIRET_FOUND = 'siret-found',
  UNLOCK_AFFILIATE = 'unlock',
  UNLOCK_AFFILIATE_CRT = 'crt',
  UNLOCK_AFFILIATE_CONECS = 'conecs',
  UNLOCK_AFFILIATE_CNTR = 'cntr',
  UNLOCK_AFFILIATE_BILL= 'bill',
  UNLOCK_AFFILIATE_COLLECTION = 'collection',
  UNLOCK_AFFILIATE_BILL_INFOS= 'bill-infos',
  UNLOCK_AFFILIATE_COLLECTION_INFOS = 'collection-infos',
  MERCHANTS_SIGNUP_COORDONNEES = 'coordonnees',
  MERCHANTS_SIGNUP_RGPD = 'signup-rgpd',

}
