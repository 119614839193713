import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lateral-left-panel',
  templateUrl: './lateral-left-panel.component.html',
  styleUrls: ['./lateral-left-panel.component.scss']
})
export class LateralLeftPanelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
