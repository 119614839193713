import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MerchantSignupRightPanel } from '@shared/enums/merchants-signup-right-panel.enum';
import { Subject } from 'rxjs';
import { MerchantsSignupService } from '../merchants-signup.service';

@Component({
  selector: 'app-lateral-right-panel',
  templateUrl: './lateral-right-panel.component.html',
  styleUrls: ['./lateral-right-panel.component.scss']
})
export class LateralRightPanelComponent implements OnInit, OnDestroy {
  
  @Input() content!: string;
  @Output() closePanel$ = new EventEmitter<void>();
  @Output() goToPanel$ = new EventEmitter<string>();
  @Output() canAccess$ = new EventEmitter<void>();

  public MerchantSignupRightPanel = MerchantSignupRightPanel;


  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly merchantsSignupService: MerchantsSignupService,
  ) {}

  ngOnInit() {

  }

  public goToPanel(goTo: string): void {
    this.goToPanel$.emit(goTo);
  }

  public closePanel(): void {
    this.closePanel$.emit();
  }

  public canAccess(): void {
    this.canAccess$.emit();
  }

  ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
  }
    

}