import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@services/login/login.service';
import { SessionQuery } from '@services/store/session/session.query';
import { TealiumService } from '@services/tealium/tealium.service';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-cesu-forgotten-login-mail',
  templateUrl: './cesu-forgotten-login-mail.component.html',
  styleUrls: ['./cesu-forgotten-login-mail.component.scss', '../../../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuForgottenLoginMailComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public saml: string;
  public forgottenLoginMail: string;

  constructor(
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly tealiumService: TealiumService,
    private readonly sessionQuery: SessionQuery,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  ngOnInit(): void {
    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml: string) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);

    const forgottenLoginMailSubscription = this.sessionQuery.selectedForgottenLoginMail
      .pipe(
        tap((forgottenLoginMail: string) => {
          this.forgottenLoginMail = forgottenLoginMail;
        }),
      )
      .subscribe();

    this.subscription.add(forgottenLoginMailSubscription);

    this.tealiumService.view('cesu.forgottenLogin.confirmationEmailSentPageLoad');
  }

  public retrySendForgottenLogin() {
    this.reCaptchaV3Service
    .execute('ConnecterAction').subscribe((token) => {
      this.loginService
      .forgottenLogin(this.forgottenLoginMail, this.sessionQuery.getValue().applicationCode, token)
      .pipe(
        catchError((err) => {
          throw err;
        }),
      )
      .subscribe();
    })
    ;

  }

  public goToLogin() {
    this.tealiumService.link('cesu.forgottenLogin.returnToLoginClick');
    this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
