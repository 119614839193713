import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { Favicons } from '../../../../shared/favicons';
import { WeezenComponent } from '../../../weezen/weezen.component';
import { environment } from '../../../../../environments/environment';
import { GatewayService } from 'src/app/shared/services/gateway.service';

@Component({
  selector: 'app-fourth-step-asp-component',
  templateUrl: './fourth-step-asp-component.component.html',
  styleUrls: ['./fourth-step-asp-component.component.scss',
    '../../create-account.component.scss',
    '../../../../../assets/css/asp-global.scss']
})
export class FourthStepAspComponentComponent extends WeezenComponent implements OnInit {

  constructor(private router: Router,
    // fav: Favicons
    ) {
    // super(fav);
    super();
  }

  ngOnInit() {
  }

  connection() {
    window.location.href = environment.redirectURIWee.replace('/token', '/authent');
  }
}
