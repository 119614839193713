import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GatewayService } from '@services/gateway.service';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SiloApiService } from '@services/siloApi.service';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-affilie-apz-change-pwd',
  templateUrl: './affilie-apz-change-pwd.component.html',
  styleUrls: ['./affilie-apz-change-pwd.component.scss']
})
export class AffilieApzChangePwdComponent implements OnInit {
  @Input() login: string;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();

  isFirstStep = true;
  currentPasswordInvalid = false;
  passwordEqual = false;
  submittedForm = false;
  isLoading = false;
  private valueCaptcha: string;

  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  passwordForm = this.fb.group({
    currentPassword: [undefined, Validators.required],
    newPassword: [undefined, [validatePassword(), Validators.required]],
    confirmPassword: [undefined, [validatePassword(), Validators.required]]
  });

  mySubscription: Subscription = new Subscription();

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private gtds: GroundTruthDataService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });
    this.mySubscription.add(isSpinnerActivated);
  }

  goBack() {
    this.location.back();
  }

  goToSecondStep() {
    if (this.passwordForm.get('currentPassword').valid) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.reCaptchaV3Service
          .execute('UserPasswordCheckAction')
          .pipe(
            finalize(() => {
              this.siloApiService
                .userPasswordCheck(
                  this.login,
                  this.passwordForm.get('currentPassword').value,
                  ApplicationCodeEnum.AFFILIE_APZ,
                  authToken,
                  this.valueCaptcha
                )
                .subscribe((retour) => {
                  this.currentPasswordInvalid = !retour.resultCheck;
                  this.isFirstStep = this.currentPasswordInvalid;
                });
            })
          )
          .subscribe((token) => (this.valueCaptcha = token));
      });
    }
  }

  controlPassWord(): void {
    this.passwordEqual = this.passwordForm.get('newPassword').value === this.passwordForm.get('confirmPassword').value;
  }

  submitForm(): void {
    if (this.passwordForm.valid && this.passwordEqual) {
      this.submittedForm = true;
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit(this.passwordForm.getRawValue());
    }
  }
}
