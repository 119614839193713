import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-pagination-equals-n',
    templateUrl: './pagination-equals-n.component.html',
    styleUrls: ['./pagination-equals-n.component.scss'],
})
export class PaginationEqualsNComponent {
    @Input() totalPages!: number;
    @Output() currentPageEmitter = new EventEmitter<number>();
    paginationThreashold = 4;

    currentPage = 1;

    constructor() {}

    public getPages(): number[] {
        return Array.from(Array(this.totalPages - 2).keys()).map((page) => page + 2);
    }

    public nextPage(): void {
        this.currentPage++;
        this.emitPage();
    }

    public previousPage(): void {
        this.currentPage--;
        this.emitPage();
    }

    public changePage(num: number): void {
        if (this.currentPage !== num) {
            this.currentPage = num;
            this.emitPage();
        }
    }

    public isCurrentPage(num: number): boolean {
        return num === this.currentPage;
    }

    public isLastPage(): boolean {
        return this.currentPage === this.totalPages;
    }

    public isFirstPage(): boolean {
        return this.currentPage === 1;
    }

    public numberIsDisplayed(num: number): boolean {
        return (
            (this.currentPage <= this.paginationThreashold && ![6, 7].includes(num)) ||
            (this.currentPage > this.paginationThreashold && ![2, 3].includes(num))
        );
    }

    public etcDisplayed(position: string): boolean {
        return (
            (position === 'start' && this.currentPage > this.paginationThreashold) ||
            (position === 'end' && this.currentPage <= this.paginationThreashold)
        );
    }

    private emitPage(): void {
        this.currentPageEmitter.emit(this.currentPage);
    }
}
