import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GatewayService } from './shared/services/gateway.service';
import { AuthentificationService } from './shared/services/authentification.service';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faLock, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { SiloApiService } from './shared/services/siloApi.service';
import { CoreModule } from './core/core.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NitAngularLibModule } from 'nit-angular-lib';
import { environment } from 'src/environments/environment';
import localeFr from '@angular/common/locales/fr';
 // import { Favicons, BrowserFavicons, BROWSER_FAVICONS_CONFIG } from './shared/favicons';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule, RecaptchaLoaderService } from 'ng-recaptcha';
import { registerLocaleData } from '@angular/common';
import { CadoService } from './shared/services/cado.service';
import { RouterModule } from '@angular/router';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    SharedModule,
    FontAwesomeModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(options),
    NitAngularLibModule.forRoot(environment),
    RecaptchaV3Module,
    RecaptchaFormsModule
  ],
  exports: [ReactiveFormsModule, FormsModule, TranslateModule],
  providers: [GatewayService, AuthentificationService, SiloApiService, CadoService, RecaptchaLoaderService,
      /*
    {
      provide: Favicons,
      useClass: BrowserFavicons
    },
    */
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.GOOGLE_RECAPTCHA_KEY },
    {provide: LOCALE_ID, useValue: 'fr-FR' },
    /*,
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          apz: {
            type: 'image/x-icon',
            href: '/assets/images/favicons/favicon_apz.ico',
            isDefault: true
          },
          wee: {
            type: 'image/png',
            href: '/assets/images/favicons/favicon_wee.png'
          },
          cesu: {
            type: 'image/png',
            href: '/assets/images/favicons/favicon_cesu.png'
          }
        },
        cacheBusting: true
      }
    }*/],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faUser, faLock, faExclamationCircle);
  }
}
