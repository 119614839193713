<div id="cntr-content">
    
    <app-info-card 
    [title]="title"
    [text]="text"
    ></app-info-card>
    <div class="mt-4">
        <form [formGroup]="formGroup" (onSubmit)="onSubmit()">
            <div class="form-group">
                <label class="form-label" for="cntr">
                    {{
                        "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CNTR.FORM.LABEL"
                          | translate
                      }} 
                </label>
                <input
                class="form-control"
                type="text"
                formControlName="cntr"
                placeholder="{{
                    'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CNTR.FORM.PLACEHOLDER'
                      | translate
                  }}"
                  [ngClass]="{'invalid-input': isBadCntr || (formGroup.get('cntr').invalid && formGroup.get('cntr').dirty)}"
                  />
              </div>
              <div class="mx-2">
                <div>
                    <small
                    class="text-error"
                    *ngIf="formGroup.get('cntr').errors?.pattern"
                >
                    {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CNTR.FORM.ERRORS.PATTERN"
                        | translate
                    }}
                </small>
                </div>
                <div>
                    <small
                    class="text-error"
                    *ngIf="formGroup.get('cntr').errors?.maxlength"
                >
                    {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CNTR.FORM.ERRORS.MAXLENGTH"
                        | translate
                    }}
                </small>
                </div>
                <div>
                    <small
                    class="text-error"
                    *ngIf="formGroup.get('cntr').errors?.required && formGroup.get('cntr').dirty"
                >
                    {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CNTR.FORM.ERRORS.REQUIRED"
                        | translate
                    }}
                </small>
                </div>
                <div>
                    <small
                    class="text-error"
                    *ngIf="isBadCntr"
                >
                    {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CNTR.FORM.ERRORS.BAD_CNTR"
                        | translate
                    }}
                </small>
                </div>  
            </div>

            <app-full-width-btn
            [text]="'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CRT.FORM.BUTTON'"
            [disabled]="!formGroup.valid"
            (click$)="onSubmit()"
            
            ></app-full-width-btn>
        </form>
    </div>
    
</div>