import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
    public constructor(private translateService: TranslateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add Accept-Language on every request
        const lang = this.translateService.currentLang;
        request = request.clone({
            setHeaders: {
                'Accept-Language': lang ? lang : 'fr'
            }
        });

        return next.handle(request);
    }
}
