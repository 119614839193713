<div class="validator-container form-group">
  <!-- Strength -->
  <div
    class="progress progress-size mt-3 mb-3"
    [ngStyle]="{ 'background-color': progressDefaultColor }"
  >
    <div
      class="progress-bar text-center"
      role="progressbar"
      [ngStyle]="{
        width: (errors.force.value / 50) * 100 + '%',
        'background-color': errors.force.color
      }"
      [attr.aria-valuenow]="errors.force"
      aria-valuemin="0"
      aria-valuemax="50"
    ></div>
  </div>

  <!-- Error cases -->
  <div class="d-flex justify-content-between flex-wrap">
    <div class="d-flex flex-column">
      <small
        class="text-family"
        [ngClass]="
          errors.minLength && submitted
            ? 'text-danger font-weight-bold'
            : 'text-dark'
        "
      >
        <i *ngIf="errors.minLength" class="icon-croix mr-2"></i>
        <i *ngIf="!errors.minLength" class="icon-coche mr-2"></i>
        {{ "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.MIN_LENGTH" | translate }}
      </small>

      <small
        class="text-family"
        [ngClass]="
          errors.maxLength && submitted
            ? 'text-danger font-weight-bold'
            : 'text-dark'
        "
      >
        <i *ngIf="errors.maxLength" class="icon-croix mr-2"></i>
        <i *ngIf="!errors.maxLength" class="icon-coche mr-2"></i>
        {{ "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.MAX_LENGTH" | translate }}
      </small>
      <small
        class="text-family"
        [ngClass]="
          errors.hasNumber && submitted
            ? 'text-danger font-weight-bold'
            : 'text-dark'
        "
      >
        <i *ngIf="errors.hasNumber" class="icon-croix mr-2"></i>
        <i *ngIf="!errors.hasNumber" class="icon-coche mr-2"></i>
        {{ "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.HAS_NUMBER" | translate }}
      </small>
    </div>

    <div class="d-flex flex-column">
      <small
        class="text-family"
        [ngClass]="
          errors.hasCapitalCase && submitted
            ? 'text-danger font-weight-bold'
            : 'text-dark'
        "
      >
        <i *ngIf="errors.hasCapitalCase" class="icon-croix mr-2"></i>
        <i *ngIf="!errors.hasCapitalCase" class="icon-coche mr-2"></i>
        {{
          "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.HAS_CAPITALCASE" | translate
        }}
      </small>
      <small
        class="text-family"
        [ngClass]="
          errors.hasLowerCase && submitted
            ? 'text-danger font-weight-bold'
            : 'text-dark'
        "
      >
        <i *ngIf="errors.hasLowerCase" class="icon-croix mr-2"></i>
        <i *ngIf="!errors.hasLowerCase" class="icon-coche mr-2"></i>
        {{ "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.HAS_LOWERCASE" | translate }}
      </small>
      <small
        class="text-family"
        [ngClass]="
          errors.hasSpecialCharacter && submitted
            ? 'text-danger font-weight-bold'
            : 'text-dark'
        "
      >
        <i *ngIf="errors.hasSpecialCharacter" class="icon-croix mr-2"></i>
        <i *ngIf="!errors.hasSpecialCharacter" class="icon-coche mr-2"></i>
        {{
          "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.HAS_SPECIAL_CHARACTER"
            | translate
        }}
      </small>
      <small
        *ngIf="affLastPassword"
        class="text-family"
        [ngClass]="
          errors.hasChanged && submitted
            ? 'text-danger font-weight-bold'
            : 'text-dark'
        "
      >
        <i *ngIf="errors.hasChanged" class="icon-croix mr-2"></i>
        <i *ngIf="!errors.hasChanged" class="icon-coche mr-2"></i>
        {{ "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.HAS_CHANGED" | translate }}
      </small>
    </div>
  </div>
</div>
