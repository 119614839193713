import { Component, OnInit, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-rgpd-content',
  templateUrl: './rgpd-content.component.html',
  styleUrls: ['./rgpd-content.component.scss']
})
export class RgpdContentComponent {

   @Output() gotIt$ = new EventEmitter<void>();

  public gotIt(): void {
    this.gotIt$.emit();
  }

}
