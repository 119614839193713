<div class="container-fluid vw-100 vh-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3">
        <div class="d-flex flex-column">
            <img class="logo" src='../../../../assets/bimpli/logo.svg'  alt="Logo" />
            <span class="title-bp mt-0">Et Bim !</span>
            <span class="sub-title-bp">C’est la première fois qu’on se voit ?</span>
        </div>
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()">
                <i class="bi bi-x"></i>
                <span class="quit">Quitter</span>
            </div>
            <div *ngIf="(applicationCode$ | async) !== ApplicationCodeEnum.BENEFIT_FINANCEUR && (applicationCode$ | async) !== ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST">
                <span class="create">Créez votre compte</span><br>
                <span class="create-subtitle mt-4" >Pour créer un compte Bimpli, veuillez sélectionner un produit que vous possédez.</span>
            </div>
            <div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST">
                <span class="create">Sélectionnez le produit Bimpli qui vous intéresse</span>
            </div>
            <div class="div-button card mt-2 d-flex flex-row justify-content-between align-items-center mt-4" (click)="gotoFirstStep('apetiz')"
                *ngIf="(applicationCode$ | async) !== ApplicationCodeEnum.BENEFIT_FINANCEUR && (applicationCode$ | async) !== ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST">
                <div>
                    <img class="branding-card" src="/assets/bimpli/bimpli-card.png"/>
                    <span class="produit text-center ml-4">Carte Bimpli</span>
                </div>
                <i class="bi bi-arrow-right-short" ></i>
            </div>
            <div class="div-button card mt-2 d-flex flex-row justify-content-between align-items-center mt-4" (click)="gotoFirstStep('cado')">
                <div>
                    <img class="branding-card" src="/assets/bimpli/cado-card.png"/>
                    <span class="produit ml-4">{{(applicationCode$ | async) !== ApplicationCodeEnum.BENEFIT_FINANCEUR ? 'Carte Bimpli' : 'Bimpli'}} CADO</span>
                </div>
                <i class="bi bi-arrow-right-short"></i>
            </div>
            <div class="div-button card mt-2 d-flex flex-row justify-content-between align-items-center mt-4" (click)="gotoFirstStep('cesu')"
            *ngIf="(applicationCode$ | async) !== ApplicationCodeEnum.BENEFIT_FINANCEUR && (applicationCode$ | async) !== ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST">
                <div>
                    <img class="branding" src="/assets/bimpli/branding-cesu.png"/>
                    <span class="produit text-center ml-4">Compte CESU</span>
                </div>
                <i class="bi bi-arrow-right-short"></i>
            </div>
            <div class="div-button card mt-2 d-flex flex-row justify-content-between align-items-center mt-4" (click)="gotoFirstStep('comiteo')"
                *ngIf="(applicationCode$ | async) !== ApplicationCodeEnum.BENEFIT_FINANCEUR && (applicationCode$ | async) !== ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST">
                <div>
                    <img class="branding" src="/assets/bimpli/branding-comiteo.png"/>
                    <span class="produit text-center ml-4">Club avantages entreprise</span>
                </div>
                <i class="bi bi-arrow-right-short"></i>
            </div>
        </div>
    </div>
</div>
