import { Injectable } from '@angular/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { Affiliate, ReadAffiliatesParamQuery } from '@shared/models/affiliate';
import { AuthToken } from '@shared/models/authtoken.model';
import { CreateTermsAndConditions } from '@shared/models/createTermsAndConditions.model';
import { MerchantSignupAffiliatesData } from '@shared/models/merchant-signup-affiliates-data';
import { UserCreated } from '@shared/models/user-created.model';
import { UserAffiliateCreate } from '@shared/models/userAffiliateCreate.model';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MerchantsSignupService } from '../merchants-signup.service';

@Injectable({
  providedIn: 'root'
})
export class CoordonneesService {
  constructor(
    private readonly merchantsSignupService: MerchantsSignupService,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService
  ) {}

  getDisplayedAffiliates$(): Observable<MerchantSignupAffiliatesData[]> {
    return this.merchantsSignupService.identificationData$.pipe(
      switchMap((data) => {
        if (!!data.affiliatesQuery?.email) {
          // Cas identification par email
          return this.getDisplayedAffiliatesByEmail$(data).pipe(map((affiliatesData) => [affiliatesData]));
        } else if (!!data.affiliatesQuery?.siret) {
          return this.getDisplayedAffiliatesBySiret$(data);
        } else {
          return of([]);
        }
      })
    );
  }

  private getDisplayedAffiliatesByEmail$(data: MerchantSignupAffiliatesData): Observable<MerchantSignupAffiliatesData> {
    if (data.affiliates?.length > 1) {
      // Plusieurs affiliés trouvés
      // On recherche les centres de remboursement
      const queryParam = new ReadAffiliatesParamQuery();
      queryParam.email = data.affiliatesQuery.email;
      queryParam.isRefundCenter = true;
      return this.gatewayService.getAccessToken().pipe(
        switchMap((token) => {
          return this.siloApiService.readAffiliatesByQuery(queryParam, token);
        }),
        map((refundCenters) => {
          if (refundCenters?.length) {
            const affiliates = MerchantsSignupService.filterDisplayedAffiliates(refundCenters);
            return new MerchantSignupAffiliatesData(queryParam, MerchantsSignupService.filterDisplayedAffiliates(affiliates));
          } else {
            return data;
          }
        })
      );
    } else {
      // Un seul affilié, pas de recherche supplémentaire
      return of(data);
    }
  }

  private getDisplayedAffiliatesBySiret$(data: MerchantSignupAffiliatesData): Observable<MerchantSignupAffiliatesData[]> {
    const initialRefundCenters = data.affiliates?.filter((affiliate) => !!affiliate.affiliateType?.isRefundCenter);
    if (!initialRefundCenters?.length) {
      // Pas de refundCenter, on renvoie la liste initiale
      return of([data]);
    } else {
      // Pour chaque centre de remboursement, on récupère tous les centres de remboursement ayant le même père
      // On supprime les doublons
      const noDuplicateRefundCenter = initialRefundCenters.reduce((unique, item) => {
        if (!item.affiliateType.affiliateFatherId) {
          unique.push(item);
        } else {
          const sameFather = unique.find(
            (refundCenter) => refundCenter.affiliateType.affiliateFatherId === item.affiliateType.affiliateFatherId
          );
          if (!sameFather) {
            unique.push(item);
          }
        }
        return unique;
      }, [] as Affiliate[]);

      return combineLatest(noDuplicateRefundCenter.map((refundCenter) => this.getSameFatherRefundCenters$(refundCenter)));
    }
  }

  private getSameFatherRefundCenters$(refundCenter: Affiliate): Observable<MerchantSignupAffiliatesData> {
    if (!!refundCenter.affiliateType.affiliateFatherId) {
      const queryParam = new ReadAffiliatesParamQuery();
      queryParam.affiliateFatherId = refundCenter.affiliateType.affiliateFatherId;
      queryParam.isRefundCenter = true;
      // On recherche les centres de remboursement ayant le même père
      return this.gatewayService.getAccessToken().pipe(
        switchMap((token) => {
          return this.siloApiService.readAffiliatesByQuery(queryParam, token);
        }),
        map((refundCenters) => {
          const affiliates = MerchantsSignupService.filterDisplayedAffiliates(refundCenters);
          return new MerchantSignupAffiliatesData(queryParam, MerchantsSignupService.filterDisplayedAffiliates(affiliates));
        })
      );
    } else {
      return of(new MerchantSignupAffiliatesData(null, [refundCenter]));
    }
  }

  createUserAffiliate(userAffiliateCreate: UserAffiliateCreate, token: AuthToken): Observable<UserCreated> {
    return this.siloApiService.createUserAffiliate(token, userAffiliateCreate);
  }

  createTermsAndConditionsAcceptances(userId: number, body: CreateTermsAndConditions, token: AuthToken): Observable<string> {
    return this.siloApiService.createTermsAndConditionsAcceptances(userId, body, token);
  }

  downloadAffiliates(data: MerchantSignupAffiliatesData, token: AuthToken): Observable<Blob> {
    return this.siloApiService.downloadAffiliatesByQuery(data.affiliatesQuery, token);
  }
}
