<div class="img-row">
    <a><img src=" ../../../assets/asp/logo_wee.png" class="logo-img" (click)="connection()" /></a>
</div>

<div class="header d-flex align-items-center justify-content-center">
    <p class="title">{{'NAVBAR.LEGAL_NOTICE' | translate}}</p>
</div>
<div class="container">
    <div class="card">
        <div class="card-body">
            <div [innerHTML]="notice"></div>
            <button class="btn btn-secondary mt-4" (click)="connection()">
                {{'CREATION_ACCOUNT.RETOUR' | translate}}
            </button>
        </div>
    </div>
</div>
