import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SiloApiService } from '../../../shared/services/siloApi.service';
import { GatewayService } from '../../../shared/services/gateway.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';

@Component({
  selector: 'app-cgu-apz',
  templateUrl: './cgu-apz.component.html',
  styleUrls: ['./cgu-apz.component.scss']
})
export class CguApzComponent implements OnInit {
  @Input() applicationId;

  cguContent;

  constructor(private gatewayService: GatewayService, private siloApiService: SiloApiService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.gatewayService.getAccessToken().subscribe((authToken) =>
      this.siloApiService
        .readTermsAndConditions(this.applicationId, 'EMPLOYEE', authToken, this.translateService.currentLang)
        .subscribe((response) => {
          this.cguContent = response.items[0].content;
        })
    );
  }

  goBack() {
    if (
      this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF ||
      this.applicationId === ApplicationCodeEnum.BENEFIT_CAGNOTTE ||
      this.applicationId === ApplicationCodeEnum.BENEFIT_FINANCEUR
    ) {
      window.close();
    } else {
      window.history.back();
    }
  }
}
