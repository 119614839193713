<div *ngIf="isPanelOpen$ | async" id="panel" class="h-100 card" [ngClass]="{ large: isLargePanel === true, small: isLargePanel === false }">
  <div id="close-panel-container" class="d-flex flex-column align-items-start justify-content-center mb-4">
    <button id="close-panel-btn" type="button" class="btn btn-link" (click)="closeClicked$.emit(true)">
      <div class="d-flex flex-row align-items-center justify-content-center">
        <span id="close-panel-btn-icon" class="material-icons"> arrow_back </span>
      </div>
    </button>
  </div>
  <div class="p-16">
    <!-- content -->
    <ng-content></ng-content>
  </div>
</div>
<div id="overlay" (click)="closeClicked$.emit(true)" *ngIf="isPanelOpen$ | async"></div>
