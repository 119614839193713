import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { mailRegex } from 'nit-angular-lib';
import { Subscription } from 'rxjs';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { ModalService } from '@shared/components/modal/modal.service';

@Component({
  selector: 'app-bimpli-forgotten-login',
  templateUrl: './bimpli-forgotten-login.component.html',
  styleUrls: ['./bimpli-forgotten-login.component.scss']
})
export class BimpliForgottenLoginComponent implements OnInit, OnDestroy {
  @Input() messageErreur;
  @Input() notificationEnvoyer;
  @Input() estApetizMobile;
  @Input() saml;
  @Input() isLoading : boolean;
  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() recalled: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelled: EventEmitter<string> = new EventEmitter<string>();

  forgottenLoginForm = this.fb.group({
    identifiant: [undefined, [Validators.required, Validators.pattern(mailRegex)]]
  });

  mySubscription = new Subscription();

  constructor(protected fb: FormBuilder, private gtds: GroundTruthDataService, private snackbarService: SnackbarService,
              private readonly modalService: ModalService) {}

  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
  }

  back(): void {
    window.history.back();
  }

  submit() {
    if (this.forgottenLoginForm.valid) {
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit(this.forgottenLoginForm.get('identifiant').value);
    }
  }

  recall() {
    this.gtds.setIsSpinnerActivated(true);
    this.recalled.emit(this.forgottenLoginForm.get('identifiant').value);
    this.modalService.open('infoModal')
  }

  goLogin() {
    this.cancelled.emit();
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }
}
