<div
    *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ || (applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE"
    class="div-login"
>
    <app-fourth-step-apz
        [login]="accountActivationInfo.login"
        [estMobile]="(applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE"
    ></app-fourth-step-apz>
</div>

<div
  *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF
    || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE"
  class="div-login">
    <app-login [isAlreadyActived]="true" *ngIf="isAlreadyActived"></app-login>
  <app-fourth-step-bimpli-apetiz *ngIf="!isAlreadyActived"
    [login]="accountActivationInfo.login"
    [applicationId]="(applicationCode$ | async)"
    [success]="success"
    [isLoading]="isLoading"
    [estMobile]="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE"
  ></app-fourth-step-bimpli-apetiz>
</div>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN">
  <app-fourth-step-asp-component></app-fourth-step-asp-component>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
  <app-cesu-activation-compte></app-cesu-activation-compte>
</ng-container>

<div class="app-affilie-apz-component" *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.AFFILIE_APZ">
  <app-login [isAlreadyActived]="true" *ngIf="isAlreadyActived"></app-login>
  <app-fourth-step-affilie-apz-component *ngIf="!isAlreadyActived"></app-fourth-step-affilie-apz-component>
</div>
