import { Component } from '@angular/core';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  // tslint:disable-next-line: no-host-metadata-property
  host: { '[class.ngb-toasts]': 'true' }
})
export class ToastComponent {
  // Constructor
  constructor(public toastService: ToastService) {}

  // Methods
  remove(toast: any): void {
    this.toastService.remove(toast);
  }
}
