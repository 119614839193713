<div id="first-step-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row h-100">
    <div
      id="menu-container"
      class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 p-0"
      [ngClass]="{
        'col-12 col-sm-12': (isStepOpen$ | async) === true,
        'd-none d-md-block': (isStepOpen$ | async) === false || (isPanelOpen$ | async) === true
      }"
    >
      <app-affilie-apz [step]="1" [saml]="saml" (isStepOpen)="isStepOpen$.next($event)"></app-affilie-apz>
    </div>
    <div
      id="step-container"
      class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 p-0"
      [ngClass]="{
        'd-none d-md-block': (isStepOpen$ | async) === true || (isPanelOpen$ | async) === true,
        'col-12 col-sm-12': (isStepOpen$ | async) === false
      }"
    >
      <app-info-siret (lookForAffilliate)="findAffiliateBySiret($event)" [isStepOpen$]="isStepOpen$" [isLoading$]="isLoading$">
      </app-info-siret>
    </div>
  </div>
  <app-right-panel
    [isLargePanel]="isLargePanel"
    [isPanelOpen$]="isPanelOpen$"
    *ngIf="isPanelOpen$ | async"
    (closeClicked$)="isPanelOpen$.next(false)"
  >
    <ng-container [ngSwitch]="statutSiret">
      <app-affiliate-error
        *ngSwitchCase="'AFFILIATE_NOT_FOUND'"
        title="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_NOT_FOUND.TITLE' | translate }}"
        subtitle=" {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_NOT_FOUND.SUBTITLE_1' | translate }}"
        btnText="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_NOT_FOUND.CNRT' | translate }}"
        (btnClicked$)="openTab('https://www.cntr.fr/V2/home.php')"
      ></app-affiliate-error>
      <app-affiliate-error
        *ngSwitchCase="'NO_AFFILIATE_ACTIF'"
        title="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.NO_AFFILIATE_ACTIF.TITLE' | translate }}"
        subtitle=" {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.NO_AFFILIATE_ACTIF.DESCRIPTION' | translate }}"
        btnText="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.NO_AFFILIATE_ACTIF.CONTACT_FORM' | translate }}"
        (btnClicked$)="openTab('https://www.bimpli.com/contact/')"
      ></app-affiliate-error>
      <app-affiliate-error
        *ngSwitchCase="'AFFILIATE_ALREADY_OWNED'"
        title="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_ALREADY_OWNED' | translate }}"
      ></app-affiliate-error>
      <app-affiliate-confirm
        *ngSwitchCase="'AFFILIATE_CONFIRM'"
        [affiliate]="affiliatesFound?.length && affiliatesFound[0]"
        (selectedAffiliate)="onSelect($event)"
      ></app-affiliate-confirm>
      <app-affiliate-confirm-list
        *ngSwitchCase="'AFFILIATE_CONFIRM_LIST'"
        [affiliates]="affiliatesFound"
        (selectedAffiliates)="onSelect($event)"
      ></app-affiliate-confirm-list>
    </ng-container>
  </app-right-panel>
</div>
