import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { SessionQuery } from '@services/store/session/session.query';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { LoginHelper } from '@shared/helpers/login.helper';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CesuService {

  constructor(
    private readonly router: Router,
    private readonly gatewayService: GatewayService,
    private readonly sessionQuery: SessionQuery,
  ) {
  }

  public goToLogin() {
    if (this.sessionQuery.getValue().saml) {
      this.router.navigate([`${RoutesEnum.LOGIN}/${this.sessionQuery.getValue().saml}`]);
    } else if (this.sessionQuery.getValue().changePasswordInfo && this.sessionQuery.getValue().changePasswordInfo.redirectUrl) {
      window.location.href = this.sessionQuery.getValue().changePasswordInfo.redirectUrl;
    } else {
      this.gatewayService.authentificate(environment.clientIdCesu, 'token', environment.redirectURLCesu)
        .pipe(
          tap((htmlContent: string) => LoginHelper.setPageHtml(htmlContent)),
        )
        .subscribe();
    }
  }
}
