export const environment = {
  production: true,
  redirectURI: '%#IDP_URL_REDIRECT_TOKEN#%',
  gatewayEndPointAS: '',
  siloECUEndPoint: '%#SILO_ECU_END_POINT#%',
  gatewayBasicAuthAS: '%#GATEWAY_BASIC_AUTH_AS#%',
  gatewayEndPointRS: '%#GATEWAY_ENDPOINT_RS#%',
  clientIdWee: '%#CLIENT_ID_WEE#%',
  redirectURIWee: '%#WEE_URL_REDIRECT_TOKEN#%',
  clientIdCesu: '%#CLIENT_ID_CESU#%',
  redirectURLCesu: '%#CESU_URL_REDIRECT_TOKEN#%',
  clientIdAPZ: '%#CLIENT_ID_APZ#%',
  redirectURIAPZ: '%#APZ_URL_REDIRECT_TOKEN#%',
  clientIdAffilieAPZ: '%#CLIENT_ID_AFFILIE_APZ#%',
  redirectURLAffilieAPZ: '%#AFFILIE_APZ_URL_REDIRECT_TOKEN#%',
  clientIdBenefWeb: '%#CLIENT_ID_BENEF_WEB#%',
  redirectURLBenefWeb: '%#BENEF_WEB_URL_REDIRECT_TOKEN#%',
  clientIdFinanceurWeb: '%#CLIENT_ID_FINANCEUR_WEB#%',
  redirectURLFinanceurWeb: '%#FINANCEUR_WEB_URL_REDIRECT_TOKEN#%',
  clientIdBimpliMobile: '%#CLIENT_ID_BIMPLI_MOBILE#%',
  redirectURLBimpliMobile: '%#BIMPLI_MOBILE_URL_REDIRECT_TOKEN#%',
  localApi: false,
  versionApi: 'v1',
  tealiumAccount: '%#TEALIUM_ACCOUNT#%',
  tealiumProfile: '%#TEALIUM_PROFILE#%',
  tealiumBuildEnvironment: '%#TEALIUM_BUILD_ENVIRONMENT#%',
  GOOGLE_RECAPTCHA_KEY: '%#CAPTCHA_PUBLIC_KEY_WEEZEN#%',
  GOOGLE_RECAPTCHA_CADO: '%#CAPTCHA_PUBLIC_KEY_CADO#%',
  oldInscripionUrlAPZ: '%#APZ_OLD_URL_INSCRIPTION#%',
  samlAffilie: '%#SAML_AFFILIE#%',
  version: require('../../package.json').version,
};
