import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@services/app.service';
import { RoutesEnum } from '@shared/enums/routes.enum';

@Component({
  selector: 'app-cesu-footer',
  templateUrl: './cesu-footer.component.html',
  styleUrls: ['./cesu-footer.component.scss', '../../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuFooterComponent implements OnInit {

  constructor(
    private readonly route: ActivatedRoute,
    private readonly appService: AppService,
  ) {
  }

  ngOnInit() {
  }

  goToLegalNotice() {
    this.appService.goToNextLocation(this.route, RoutesEnum.LEGAL_NOTICE);
  }

  goToDataProtection() {
    this.appService.goToNextLocation(this.route, RoutesEnum.GDPR);
  }

  goToTermsAndConditions() {
    this.appService.goToNextLocation(this.route, RoutesEnum.TERMS_AND_CONDITIONS);
  }
}
