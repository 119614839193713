<div class="container detail-container p-2">
  <div class="col-12">
    <h4 class="text-center">
      {{ 'COMMON.USER.CHANGE_USER_OK' | translate }}
    </h4>
    <div class="row justify-content-center align-items-center">
      <img src="assets/apz/femme-pomme.png" />
    </div>
    <div class="row justify-content-center align-items-center">
      <p>
        {{ 'COMMON.USER.CHANGE_USER_OK' | translate }}
      </p>
    </div>
    <div class="text-center py-3 btn-change-id">
      <button *ngIf="redirectUrl" class="btn btn-action px-3" (click)="goLogin()" type="submit">
        {{ 'COMMON.USER.CONNECT' | translate }}
      </button>
    </div>
  </div>
</div>
