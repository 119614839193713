import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer-asp',
  templateUrl: './footer-asp.component.html',
  styleUrls: ['./footer-asp.component.scss']
})
export class FooterAspComponent implements OnInit {

  @Input() token: string;
  constructor(private translateService: TranslateService) { }

  ngOnInit() {
  }

  openRgpd() {
    if (this.translateService.currentLang === 'fr') {
      window.open('assets/pdf/noticeRGPD.pdf', '_blank');
    } else {
      window.open('assets/pdf/GDPRNotice_en.pdf', '_blank');
    }
  }
}
