import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent {

  @Input() title!: string;
  @Input() text!: string;
  @Input() linkText!: string;
  @Output() click$ = new EventEmitter<void>();

  public onClick(): void {
    this.click$.emit();
  }

}
