import { Component, OnInit, Input } from '@angular/core';
import { TealiumService } from '../../../shared/services/tealium/tealium.service';

@Component({
  selector: 'app-confirmation-mobile',
  templateUrl: './confirmation-mobile.component.html',
  styleUrls: ['./confirmation-mobile.component.scss', '../../../../assets/css/apz-global.scss']
})
export class ConfirmationMobileComponent implements OnInit {
  @Input() redirectUrl: string;

  constructor(private readonly tealiumService: TealiumService) {}

  ngOnInit(): void {
    this.tealiumService.view('apz.mobile.profile.changeLoginConfirmationPageLoad');
  }

  goLogin() {
    this.tealiumService.link('apz.mobile.profile.validateChangeLoginConfirmationClick');
    if (this.redirectUrl) {
      window.location.href = this.redirectUrl;
    }
  }
}
