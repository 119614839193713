<div id="dropdown-select" class="position-relative">
    <div *ngIf="!!selectLabel" id="select-label" class="mb-4 ">
        {{ selectLabel | translate }}
    </div>

    <button
    (click)="toggleOptions($event)"
    [ngClass]="{'open': isOpen}"
    id="select-button"
    class="btn align-items-center d-flex p-3 w-100"
    >
    <span class="select-text mr-1" [ngClass]="isDefaultOption ? 'default-option' : 'other-option'">{{ selectDefaultText }}</span>
    <span id="select-icon" class="material-icons ml-auto">{{ isOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</span>

    </button>
    <ng-container *ngIf="isOpen">
        <div id="options" class="p-2 mt-2">
            <button class="option p-2 btn text-left w-100" *ngFor="let option of options; index as i"
            [ngClass]="{'light-blue': i % 2 === 1}"
            
            (click)="chooseOption($event, i)">
                <span class="option-text ">{{ option.name }}</span>
            </button>
    </div>
    </ng-container>
    
    
</div>