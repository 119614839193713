import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-rgpd',
  templateUrl: './rgpd.component.html',
  styleUrls: ['./rgpd.component.scss'],
})
export class RgpdComponent implements OnInit {
  public applicationCode$: Observable<ApplicationCodeEnum>;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
  ) {
  }

  ngOnInit() {
    this.sessionStateService.setApplicationCodeFromUnknownRoute(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;
  }

}
