<div class="container-fluid vw-100 vh-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
        <div class="d-flex flex-column" *ngIf="!type || type === 'apetiz'">
            <span class="title-bp">Miam !</span>
            <span class="sub-title-bp">Vous avez choisi <br>carte Bimpli<br> (ex Apétiz) !</span>
        </div>
        <div class="d-flex flex-column" *ngIf="type === 'cado'">
            <span class="title-bp">Youpi !</span>
            <span class="sub-title-bp">Vous avez sélectionné <br>Cado !</span>
        </div>
        <div class="d-flex flex-column" *ngIf="type === 'comiteo'">
            <span class="title-bp">Happi !</span>
            <span class="sub-title-bp">Vous avez sélectionné <br>Espace entreprise et bons plans !</span>
        </div>
        <div class="d-flex flex-column" *ngIf="type === 'cesu'">
            <span class="title-bp">Happi !</span>
            <span class="sub-title-bp">Vous avez choisi <br>CESU !</span>
        </div>
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4 d-flex contenue">
        <div *ngIf="!messageErreur" class="bloc-center d-flex flex-column">

            <img src="/assets/bimpli/validation-compte.png" class="validation-compte"/>
            <span class="create mt-4">Merci !</span>
            <span class="create-second">On y est presque !</span>
            <span class="create-subtitle mt-4" *ngIf="email && (ApplicationCodeEnum.BENEFIT_FINANCEUR !== applicationId
                && ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST !== applicationId)">Pour finaliser la création de votre compte, merci de bien vouloir cliquer sur le bouton  
                ‘Activer mon compte’ depuis le mail que nous venons d’envoyer à l’adresse : {{email}}</span>
            <span class="create-subtitle mt-4" *ngIf="email && (ApplicationCodeEnum.BENEFIT_FINANCEUR === applicationId
                || ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST === applicationId)">
                Vous venez de recevoir un email d'activation à l'adresse : {{email}}. <br><br>
                Pour finaliser la création de votre compte Bimpli, cliquez sur le bouton  
                ‘Activer mon compte’ présent dans l'email.</span>

            <span class="create-subtitle mt-4" *ngIf="!email">Pour finaliser la création de votre compte, merci de bien vouloir cliquer sur le bouton  
                ‘Activer mon compte’ depuis votre email.</span>

            <button type="button" class="mb-4 mt-4 btn btn-secondary btn-block" (click)="goLogin()">
                Retour à l'accueil
            </button>

            <div class="d-flex flex-row justify-content-center align-items-center">
                <span class="paragraphe">Toujours pas de mail ?</span>
                <a class="btn-label ml-2" [routerLink]="" (click)="reSendEmail()">Renvoyer à nouveau</a>
            </div>

            <div class="d-block d-sm-none img-mobile-end position-fixed">
            </div>
        </div>

        <div *ngIf="messageErreur" class="bloc-center d-flex flex-column">
            <span class="create-subtitle mt-4">{{messageErreur}}</span>

            <button type="button" class="mb-4 mt-4 btn btn-secondary btn-block" (click)="goLogin()">
                Retour à l'accueil
            </button>

        </div>
    </div>
</div>

<app-modal id="infoModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/bimpli/validation-compte.png" />
        </div>
        <div class="mt-4 col-12 text-center">{{messageEmail}}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('infoModal')">FERMER</button>
        </div>
    </div>
</app-modal>
