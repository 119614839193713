import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, tap, switchMap } from 'rxjs/operators';
import { AuthToken } from '@shared/models/authtoken.model';
import { CodeErreurs, Erreurs } from 'src/app/shared/models/errors.model';
import { GatewayService } from 'src/app/shared/services/gateway.service';
import { SiloApiService } from 'src/app/shared/services/siloApi.service';
import { environment } from 'src/environments/environment';

import { SessionQuery } from 'src/app/shared/services/store/session/session.query';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  private valueCaptcha: string;

  constructor(
    private readonly translateService: TranslateService,
    private readonly gatewayService: GatewayService,
    private readonly siloApiService: SiloApiService,
    private sessionQuery: SessionQuery,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  public login(login, password, saml): Observable<string> {
    return this.gatewayService.getAccessToken().pipe(
      concatMap((authToken) => {
        return this.siloApiService.connecter(login, password, saml, authToken).pipe(
          tap((htmlContent) => {
            document.body.innerHTML = htmlContent;
            const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
            form.submit();
          }),
          catchError((err) => {
            throw err as HttpErrorResponse;
          }),
        );
      }),
      catchError((err) => {
        throw new Error(this.handleError(err));
      }),
    );
  }

  /**
   * Reset the password of a user using the application id from which the request is coming from and the email address of the user
   * @param applicationId the application id
   * @param login the login of the user
   */
  public resetPassword(applicationId: ApplicationCodeEnum, login: string, valueCaptcha: string) {

     return this.gatewayService.getAccessToken().pipe(
      concatMap((token: AuthToken) => {
        return this.siloApiService.demandeReinitialisationMotDePasse(login, applicationId, token, valueCaptcha)
          .pipe(
            catchError((err) => {
              throw err;
            }),
          );
      }),
      catchError((err) => {
        throw new Error(this.handleError(err));
      }),
    );
  }

  /**
   * When a user forget his login, send it to him on his mail address
   * @param email the email of the user
   * @param applicationId the application on which the user wishes to be connected to
   */
  public forgottenLogin(email: string, applicationId: ApplicationCodeEnum, valueCaptcha: string) {
    return this.gatewayService.getAccessToken()
      .pipe(
        concatMap((authToken: AuthToken) => {
          return this.siloApiService.demandeIdentifiantOublie(
            email,
            applicationId,
            authToken,
            valueCaptcha
          ).pipe(
            catchError((err) => {
              throw new Error(this.handleError(err));
            }),
          );
        }),
      );
  }

  /**
   * Change the password of the user after using forgotten password page
   * @param userId the id of user who has forgotten his password
   * @param password the new password of the user
   * @param hash the hash transmitted by the API to verify the authenticity
   * @param tokenType the type of token sent by the API
   */
  public setNewForgottenPassword(
    userId: string,
    password: string,
    hash: string,
    tokenType: string,
    valueCaptcha: string
  ) {

     return this.gatewayService
      .getAccessToken()
      .pipe(
        concatMap((authToken: AuthToken) => {
          return this.siloApiService
            .reinitialisationMotDePasse(userId, password, hash, tokenType, authToken, valueCaptcha)
            .pipe(
              catchError((err) => {
                throw err;
              }),
            );
        }),
      );
  }

  /**
   * Change the user's password
   * @param login the login of the user
   * @param applicationId the application id to which the user is trying to change the password
   * @param oldPassword the old password of the user
   * @param newPassword the new password of the user
   */
  public changePassword(login: string, applicationId: ApplicationCodeEnum, oldPassword: string, newPassword: string, valueCaptcha: string) {
    return this.gatewayService.getAccessToken().pipe(
      concatMap((authToken: AuthToken) => {
        return this.siloApiService.updateUser(login, null, applicationId, oldPassword, newPassword, authToken, valueCaptcha).pipe(
          catchError((err) => {
            throw new Error(this.handleError(err));
          }),
        );
      }),
    );
  }

  private handleError(err): string {
    if (!(err instanceof HttpErrorResponse)) {
      return this.translateService.instant('ERROR.UNEXPECTED_ISSUE');
    }

    const errors: Erreurs = err.error.startsWith('<') ? undefined : JSON.parse(err.error);

    // this.sessionQuery.getValue().applicationCode
    // this.sessionQuery.getValue().productId

    if (errors) {
      switch (errors.errors[0].code) {
        case CodeErreurs.INCORRECT_DATA_007:
          if (errors.errors[0].attribute === 'currentPassword') {
            return this.translateService.instant('ERROR.INCORRECT_DATA.CURRENT_PASSWORD');
          }
          return this.translateService.instant('ERROR.INVALID_DATA');
        case CodeErreurs.NOT_FOUND_025:
          return this.translateService.instant('');
        case CodeErreurs.NOT_FOUND_028:
          if (errors.errors[0].attribute === 'email') {
            return this.translateService.instant('ERROR.MAIL_NOT_FOUND');
          } else {
            return this.translateService.instant('ERROR.LOGIN_NOT_FOUND');
          }
        case CodeErreurs.FORBIDDEN_ACTION_040:
          return this.translateService.instant('ERROR.LOGIN.BLOCKED_ACCOUNT');
        case CodeErreurs.IMPOSSIBLE_ACTION_041:
          return this.translateService.instant('ERROR.LOGIN.PENDING_ACTIVATION');
        case CodeErreurs.IMPOSSIBLE_ACTION_013:
          return this.translateService.instant('ERROR.LOGIN.ACCOUNT_NOT_CREATED');
        case CodeErreurs.FORBIDDEN_ACTION_042:
        default:
          return this.translateService.instant('ERROR.UNEXPECTED_ISSUE_PARAMETERS', {
            status: errors.errors[0].code,
            statusText: errors.errors[0].message,
          });
      }
    } else {
      return this.translateService.instant('ERROR.UNEXPECTED_ISSUE_PARAMETERS', {
        status: err.status,
        statusText: err.statusText,
      });
    }
  }

  private getClientIdForWebAffilieApp(): string {
    return environment.clientIdAffilieAPZ
  }

  private getUrlRedirectForWebAffilieApp(aRedirectContext?: string): string {
    let lUrlParam = '';
    if (aRedirectContext) {
      lUrlParam = '?redirectContext=' + aRedirectContext
    }
    if (this.sessionQuery.getValue().isLocal) {
      return 'http://localhost:4200/token' + lUrlParam;
    } else {
      return environment.redirectURLAffilieAPZ + lUrlParam;
    }
  }

  private doLogin(login, password, saml, aAuthToken: AuthToken, aCaptcha: string): void {
    this.siloApiService.connecterWithCaptcha(login, password, saml, aAuthToken, aCaptcha).pipe(
      tap((htmlContent) => {
        document.body.innerHTML = htmlContent;
        const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
        form.submit();
      }),
      catchError((err) => {
        throw err as HttpErrorResponse;
      }),
    ).subscribe();
  }

  public authenticateAffiliateUser(aLogin: string, aPwd: string, aAuthToken: AuthToken, aCaptcha: string) : Observable<string> {
    const clientId = this.getClientIdForWebAffilieApp();
    const redirectUrl = this.getUrlRedirectForWebAffilieApp('merchant-invite');

    if (clientId && clientId !== '') {
      this.gatewayService
      .authentificate(clientId, 'token', redirectUrl)
      .subscribe((contenuHtml) => {
        const abc = document.createElement('formSAML');
        abc.innerHTML = contenuHtml;
        const form = abc.getElementsByTagName('form')[0] as HTMLFormElement;

        this.reCaptchaV3Service
          .execute('extractSAMLRequest')
          .pipe(
            switchMap((aCaptcha2) => {
              return this.siloApiService
              // tslint:disable-next-line: no-string-literal
              .extractSAMLRequest(aAuthToken, form.elements['SAMLRequest'].value, form.elements['appNameEncoded'].value, aCaptcha2);
            })
          ).subscribe(
            retour => {
              // retour.id is the SAML response body
              this.doLogin(aLogin, aPwd, retour.id, aAuthToken, aCaptcha);
            },
              err => {
                return of('Service indisponible veuillez réassayer ultérieurement');
              }
          );
    },
    err => {
      return of('Service indisponible veuillez réassayer ultérieurement');
    });
    } else {
      return of('Service indisponible veuillez réassayer ultérieurement');
    }
  }

}

