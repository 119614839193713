<div class="bloc-return d-flex align-items-center" (click)="back()">
    <i class="bi bi-arrow-left"></i>
    <span class="ml-2">Retour</span>
</div>
<div *ngIf="!notificationEnvoyer" id="sign-in-container" class="container-fluid vw-100 vh-100 p-0">
    <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
        <img id="logo" class="mb-2" src='../../../../assets/bimpli/logo.svg' alt="Logo" />
        <div class="card shadow mt-4">
            <span class="create">Identifiant oublié ?</span>
            <span class="create-subtitle mt-4 mb-4">Pas de problème, ce sont des choses qui arrivent.</span>
            <form name="form" role="form" (keydown.enter)="submit()" [formGroup]="forgottenLoginForm" novalidate>
                <div class="d-flex flex-column form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" name="email" placeholder="Saisissez votre adresse email" id="email"
                           formControlName="identifiant" maxlength="70"
                           onkeyup="this.value = this.value.toLowerCase();">
                </div>
                <button type="button" class="btn btn-primary btn-block" [disabled]="!forgottenLoginForm.valid" (click)="submit()">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div *ngIf="isLoading" class="spinner-border"></div>
                        <span [ngClass]="{'ml-2': isLoading }">Envoyer mon identifiant</span>
                    </div>
                </button>
            </form>
        </div>
    </div>
</div>

<div *ngIf="notificationEnvoyer" class="container-fluid vw-100 vh-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4 d-flex contenue">
        <div *ngIf="!messageErreur" class="bloc-center d-flex flex-column">

            <img src="/assets/bimpli/validation-compte.png" class="validation-compte"/>
            <span class="create mt-4">Email envoyé !</span>

            <span class="create-subtitle mt-4">Un lien contenant votre identifiant vous a été transmis par email.</span>

            <button type="button" class="mb-4 mt-4 btn btn-secondary btn-block" (click)="goLogin()">
                Retour à la page de connexion
            </button>

            <div class="d-flex flex-row justify-content-center align-items-center">
                <span class="paragraphe">Toujours pas de mail ?</span>
                <a class="btn-label ml-2" [routerLink]="" (click)="recall()">Renvoyer à nouveau</a>
            </div>

            <div class="d-block d-sm-none img-mobile-end position-fixed">
            </div>
        </div>

        <div *ngIf="messageErreur" class="bloc-center d-flex flex-column">
            <span class="create-subtitle mt-4">{{messageErreur}}</span>
            <button type="button" class="mb-4 mt-4 btn btn-secondary btn-block" (click)="goLogin()">
                Retour à l'accueil
            </button>
        </div>
    </div>
</div>


<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>


<app-modal id="infoModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/bimpli/validation-compte.png" />
        </div>
        <div class="mt-4 col-12 text-center">Nouvelle demande envoyée par email.</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('infoModal')">FERMER</button>
        </div>
    </div>
</app-modal>
