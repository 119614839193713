import { Injectable } from '@angular/core';
import { TealiumDynamicData } from '@shared/models/tealium-dynamic-data.model';
import * as tealiumTagConfig from '../../../../assets/tealium/tealiumTagConfig.json';
import { TealiumUtagService } from '../../../tealium/utag.service';


@Injectable({
  providedIn: 'root',
})
export class TealiumService {

  constructor(private readonly tealiumUtagService: TealiumUtagService) {
  }

  link(keys: string, dynamicData?: TealiumDynamicData) {
    this.tealiumUtagService.link(Object.assign('', this.getValue(keys), dynamicData));
  }

  view(keys: string, dynamicData?: TealiumDynamicData) {
    this.tealiumUtagService.view(Object.assign('', this.getValue(keys), dynamicData));
  }

  private getValue(keys: string) {
    let res = (tealiumTagConfig as any).default;
    keys.split('.').forEach(key => {
      if (res[key]) {
        res = res[key];
      } else {
        return res = undefined;
      }
    });

    return res;
  }
}
