<div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <div class="col-xxl-4 col-xl-4 col-lg-8 col-md-9 col-sm-10 col-10">
        <div class="d-flex flex-row align-items-center justify-content-between w-100 d-sm-flex d-md-none">
            <button id="back-sm-btn" type="button" class="btn btn-link p-0" (click)="isStepOpen$.next(true)">
                <div class="d-flex flex-row align-items-center justify-content-center">
                    <i id="back-sm-btn-icon" class="bi bi-arrow-left"></i>
                </div>
            </button>
            <span id="stepper-pos">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.STEP_SM' | translate
                }}</span>
        </div>
        <form class="d-flex flex-column align-items-center justify-content-center h-100 w-100" name="form" role="form"
            [formGroup]="siretForm" (submit)="validate()" (keydown.enter)="validate()">
            <div class="d-flex flex-column align-items-center justify-content-center w-100">
                <!-- message -->
                <div class="rectangle d-flex flex-column w-100 mb-4">
                    {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.MULTIPLE_COMPANIES.SUBTITLE_1' | translate }}
                    {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.MULTIPLE_COMPANIES.SUBTITLE_2' | translate }}
                </div>
                <!-- SIRET -->
                <div class="d-flex flex-column w-100">
                    <label class="form-label" for="siret">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.SIRET'
                        | translate }}</label>
                    <input type="text" class="form-control" name="siret" id="siret" formControlName="siret"
                        maxlength="14" [ngClass]="{
                'is-invalid': siretForm.get('siret').invalid === true && siretForm.get('siret').touched === true,
                'is-valid': siretForm.get('siret').invalid === false && siretForm.get('siret').touched === true
              }" placeholder="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.SIRET_PROMPT' | translate }}"
                        (paste)="formatSiret($event)" />
                    <div *ngIf="siretForm.get('siret').touched && siretForm.get('siret').invalid">
                        <small class="text-danger error-msg" *ngIf="siretForm.get('siret').errors.required">
                            {{ 'COMMON.FORM.REQUIRED' | translate }}
                        </small>
                        <small class="text-danger error-msg" *ngIf="siretForm.get('siret').errors.pattern">
                            {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.ERROR.SIRET_INVALID' | translate }}
                        </small>
                    </div>
                </div>
                <!-- SUBMIT -->
                <div class="pt-4 d-flex flex-row align-items-center justify-content-center w-100">
                    <button id="submit-btn" type="button" class="btn btn-primary btn-block"
                        [disabled]="!siretForm.valid || (isLoading$ | async)" (click)="validate()">
                        <div class="d-flex flex-row align-items-center justify-content-center">
                            <div *ngIf="(isLoading$ | async)" class="spinner-border"></div>
                            <span [ngClass]="{
                    'ml-2': (isLoading$ | async)
                  }" id="submit-btn-label">{{ 'COMMON.FORM.SEARCH' | translate }}</span>
                        </div>
                    </button>
                </div>
            </div>
            <div id="divider" class="w-100 mt-4 mb-4"></div>
            <div class="d-flex flex-row justify-content-center align-items-center w-100">
                <span id="submit-btn-label"> {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.ALREADY_ACCOUNT' |
                    translate }}</span>
                <a class="signin-link ml-2" (click)="back()">
                    {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.CONNEXION' | translate }}</a>
            </div>
        </form>
    </div>
</div>
