<div id="signup" class="container-fluid vw-100 vh-100">
    <div class="row h-100">
      <div
        id="left-side"
        class="col-xxl-3 col-xl-3 col-lg-4 col-md-4 d-none d-md-block p-0"
      >
        <app-lateral-left-panel> </app-lateral-left-panel>
      </div>
      <div
        id="content"
        class="col-xxl-9 col-xl-9 col-lg-8 col-md-8 col-12 col-sm-12 mt-80"
      >
      <div [ngSwitch]="step">
        <app-signup-home 
        *ngSwitchCase="1"
        (openPanel$)="openPanel($event)"
        (next$)="goToCoordonnees()"
        >
        </app-signup-home>  
        <app-coordonnees 
        *ngSwitchCase="2"
        [saml]="saml"
        (goToPanel$)="openPanel($event)"
        (success$)="goToLastStep($event)"
        >
        </app-coordonnees>
        <app-last-step
        *ngSwitchCase="3"
        [email]="email"
        (goBack$)="goToFirstStep()"
        >
        </app-last-step>
      </div>


      <ng-container *ngIf="isPanelOpen$ | async">
        <app-lateral-right-panel
        [content]="panelContent$ | async"
        (goToPanel$)="goToPanel($event)"
        (closePanel$)="closePanel()"
        (canAccess$)="canAccess()"
        >
        </app-lateral-right-panel>
      </ng-container>
      
      
      </div>
    </div>
  </div>

<router-outlet name="panel"></router-outlet>
