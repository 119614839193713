<div id="content" *ngIf="refresh$ | async">
  <div class="title-content">
    <div class="content-title">
      {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.TITLE" | translate }}
    </div>

    <div class="content-standard-text">
      {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.SUBTITLE" | translate }}
    </div>
  </div>
  <div class="form-content mt-48">
    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
      <div class="form-row mt-48">
        <div class="form-group col-md-6">
          <label class="form-label" for="siret">
            {{
              "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.SIRET.LABEL"
                | translate
            }}
          </label>
          <input
            class="form-control"
            type="text"
            id="siret"
            formControlName="siret"
            (input)="onSiretChange()"
            placeholder="{{
              'AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.SIRET.PLACEHOLDER'
                | translate
            }}"
            mask="000 000 000 00000"
            [ngClass]="{'invalid-input': (signupForm.get('siret').invalid && signupForm.get('siret').touched)}"
          />
          <div
            *ngIf="
              signupForm.get('siret').touched === true &&
              signupForm.get('siret').invalid
            "
            class="p-1"
          >
            <small
              class="text-error"
              *ngIf="signupForm.get('siret').errors.pattern"
            >
              {{
                "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.SIRET.ERRORS.PATTERN"
                  | translate
              }}
            </small>
          </div>
        </div>
      </div>
      <div
        class="form-row content-standard-text col-md-6 mt-48 justify-content-center"
      >
        <span>
          {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.OR" | translate }}
        </span>
      </div>
      <div class="form-row mt-48">
        <div class="form-group col-md-6 email">
          <label class="form-label" for="email">
            {{
              "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.EMAIL.LABEL"
                | translate
            }}
          </label>
          <input
            class="form-control"
            type="email"
            id="email"
            (input)="onEmailChange()"
            formControlName="email"
            placeholder="{{
              'AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.EMAIL.PLACEHOLDER'
                | translate
            }}"
            [ngClass]="{'invalid-input': isEmailNotFound || (signupForm.get('email').invalid && signupForm.get('email').touched)}"
          />
          <div
            *ngIf="
              signupForm.get('email').touched === true &&
              (signupForm.get('email').invalid || isEmailNotFound === true)
            "
            class="p-1"
          >
            <small
              class="text-error"
              *ngIf="signupForm.get('email').errors?.pattern"
            >
              {{
                "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.EMAIL.ERRORS.PATTERN"
                  | translate
              }}
            </small>
            <small
              class="text-error"
              *ngIf="isEmailNotFound === true"
            >
              {{
                "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.EMAIL.ERRORS.NOT_FOUND"
                  | translate
              }}
            </small>
          </div>
        </div>
      </div>

      <button
        id="search-btn"
        type="submit"
        class="btn w-50 mt-4 col-md-6"
        [disabled]="!signupForm.valid"
      >
        {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.SEARCH" | translate }}
      </button>
      <div class="d-flex mt-3">
        <span class="content-standard-text mr-2">{{
          "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.ALREADY_SUBSCRIBED"
            | translate
        }}</span>
        <a class="signin-link" (click)="signIn()">{{
          "AFFILIE_APZ.MERCHANTS_SIGNUP.CONTENT.FORM.SIGNIN" | translate
        }}</a>
      </div>
    </form>
  </div>
</div>
