<div class="container detail-container p-2">
  <app-snackbar></app-snackbar>
  <div class="text-center">
    <h2 class="py-4">
      {{ 'APZ.CREATION_ACCOUNT.THIRD_STEP.TITLE' | translate }}
    </h2>
  </div>
  <div class="d-flex justify-content-center">
    <img class="img-fluid" src="assets/apz/inscription_femme_pomme.png" />
  </div>
  <div class="encart p-3">
    <div *ngIf="typeLogin" class="text-center">
      <p *ngIf="!isMobile">
        {{ 'APZ.CREATION_ACCOUNT.THIRD_STEP.DETAIL_MAIL' | translate }}
      </p>
      <div *ngIf="isMobile">
        <p class="pb-3">
          {{ 'APZ.CREATION_ACCOUNT.THIRD_STEP.DETAIL_MOBILE' | translate }}
        </p>
        <p class="pb-3">Saisissez le code reçu par SMS ci-dessous</p>
        <input class="form-control lock" type="text" maxlength="6" [formControl]="codeSMS" placeholder="- - - - - -" />
        <div>
          <small class="form-text text-danger" *ngIf="codeSMS.touched && codeSMS.errors && codeSMS.errors.required">
            {{ 'COMMON.FORM.REQUIRED' | translate }}
          </small>
          <small class="form-text text-danger" *ngIf="codeInvalide">
            {{ 'APZ.CODE_PIN.CODE_INVALIDE' | translate }}
          </small>
        </div>
      </div>
    </div>
    <div *ngIf="!typeLogin" class="d-block text-center">
      <div class="spinner-border" role="status"></div>
    </div>
    <div *ngIf="typeLogin" class="text-center pt-3">
      <button *ngIf="!isMobile" class="btn btn-action" (click)="goToRedirect()" type="button">
        {{ 'APZ.CREATION_ACCOUNT.THIRD_STEP.UNDERSTAND' | translate }}
      </button>
      <button *ngIf="isMobile" class="btn btn-action" (click)="validateCode()">{{ 'COMMON.FORM.SUBMIT' | translate }}</button>
    </div>
  </div>

  <div *ngIf="typeLogin" class="col-12 py-4 text-center">
    <p *ngIf="!isMobile">
      {{ 'APZ.CREATION_ACCOUNT.THIRD_STEP.QUESTION_MAIL' | translate }}
      <span class="hightlight" (click)="reSendEmail()">{{ 'APZ.CREATION_ACCOUNT.THIRD_STEP.SEND_MAIL' | translate }}</span>
    </p>
    <p *ngIf="isMobile">
      {{ 'APZ.CREATION_ACCOUNT.THIRD_STEP.QUESTION_MOBILE' | translate }}
      <span class="hightlight" (click)="reSendEmail()">{{ 'APZ.CREATION_ACCOUNT.THIRD_STEP.SEND_MOBILE' | translate }}</span>
    </p>
  </div>
</div>
