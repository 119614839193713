import { Component, OnInit } from '@angular/core';
import { CesuService } from '@services/cesu/cesu.service';
import { TealiumService } from '@services/tealium/tealium.service';

@Component({
  selector: 'app-cesu-activation-compte',
  templateUrl: './cesu-activation-compte.component.html',
  styleUrls: ['./cesu-activation-compte.component.scss', '../../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuActivationCompteComponent implements OnInit {

  constructor(
    private readonly tealiumService: TealiumService,
    private readonly cesuService: CesuService,
  ) {
  }

  public ngOnInit(): void {
    this.tealiumService.view('cesu.registrationProcess.accountActivatedPageLoad');
  }

  public goToLogin(): void {
    this.tealiumService.link('cesu.registrationProcess.accountActivatedReturnLoginClick');
    this.cesuService.goToLogin();
  }
}
