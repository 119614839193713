import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GatewayService } from '../../../shared/services/gateway.service';
import { SiloApiService } from '../../../shared/services/siloApi.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Erreurs, CodeErreurs } from '../../../shared/models/errors.model';
import { TranslateService } from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-pin-code-apz',
  templateUrl: './pin-code-apz.component.html',
  styleUrls: ['./pin-code-apz.component.scss', '../../../../assets/css/apz-global.scss']
})
export class PinCodeApzComponent implements OnInit {
  @Input() login: string;
  @Input() redirectUrl: string;
  @Input() applicationId: string;

  constructor(
    private fb: FormBuilder,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}
  isSecondStep = false;
  hide = true;
  currentPasswordInvalid = false;
  codeInvalid = false;
  private valueCaptcha: string;

  identifiantForm = this.fb.group({
    currentPassword: [undefined, Validators.required],
    code: [undefined, Validators.required]
  });

  ngOnInit(): void {}

  goToSecondStep() {
    if (this.identifiantForm.get('currentPassword').valid) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.reCaptchaV3Service
          .execute('UserPasswordCheckAction')
          .pipe(
            finalize(() => {
              this.siloApiService
                .userPasswordCheck(
                  this.login,
                  this.identifiantForm.get('currentPassword').value,
                  this.applicationId,
                  authToken,
                  this.valueCaptcha
                )
                .subscribe(
                  (retour) => {
                    this.currentPasswordInvalid = !retour.resultCheck;
                    if (!this.currentPasswordInvalid) {
                      this.siloApiService.createOTP(this.login, this.applicationId, authToken).subscribe(
                        (result) => {
                          this.isSecondStep = true;
                          this.snackbarService.showSuccess('SMS envoyé : suivez maintenant les instructions contenues dans le SMS envoyé.');
                        },
                        (error) => {
                          this.snackbarService.showDanger('Erreur imprévue lors de l\'envoie veuillez réassayer plus tard');
                        }
                      );
                    }
                  },
                  (erreur) => {
                    this.gererErreurs(erreur);
                  }
                );
            })
          )
          .subscribe((token) => (this.valueCaptcha = token));
      });
    }
  }

  public gererErreurs(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.snackbarService.showDanger('Erreur inattendue : ' + error.error.message);
    } else {
      const errors: Erreurs = error.error;
      if (errors && errors.errors !== undefined) {
        switch (errors.errors[0].code) {
          case CodeErreurs.FORBIDDEN_ACTION_040:
            this.snackbarService.showDanger('Compte bloqué');
            break;
          default:
            this.snackbarService.showDanger('Erreur imprévue veuillez réassayer plus tard');
            break;
        }
      } else {
        this.snackbarService.showDanger('Erreur imprévue veuillez réassayer plus tard');
      }
    }
  }

  submitForm() {
    if (this.identifiantForm.valid) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.siloApiService
          .checkOTP(
            this.login,
            this.applicationId,
            this.identifiantForm.get('code').value,
            this.identifiantForm.get('currentPassword').value,
            authToken
          )
          .subscribe(
            (result) => {
              this.codeInvalid = false;
              if (this.redirectUrl) {
                window.location.href = this.redirectUrl;
              }
            },
            (error) => {
              this.codeInvalid = true;
            }
          );
      });
    }
  }
}
