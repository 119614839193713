import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { Coordonnee } from '@shared/models/coordonnee';
import { SignupAffiliateManager } from '@shared/models/signup-affiliate-manager';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

const COORDONNEE = 'coordonnee';
const EMAIL = 'email';

@Component({
  selector: 'app-first-step-signup-affiliate-manager',
  templateUrl: './first-step-signup-affiliate-manager.component.html',
  styleUrls: ['./first-step-signup-affiliate-manager.component.scss']
})
export class FirstStepSignupAffiliateManagerComponent implements OnInit {
  public isStepOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth > 576 ? false : true);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public coordonneesForm: FormGroup;
  public initialData: Coordonnee;

  private signupAffiliateManager: SignupAffiliateManager;
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly gatewayService: GatewayService,
    private readonly siloApiService: SiloApiService,
    private readonly sessionStateService: SessionStateService,
    private readonly sessionQuery: SessionQuery
  ) {
    this.coordonneesForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.sessionStateService.setApplicationCodeFromAffiliateManagerSignup(this.route);
    this.sessionQuery.selectedSignupAffiliateManager
      .pipe(
        takeUntil(this.destroy$),
        tap((signupAffiliateManager: SignupAffiliateManager) => (this.signupAffiliateManager = signupAffiliateManager))
      )
      .subscribe();

    this.gatewayService
      .getAccessToken()
      .pipe(switchMap((authToken) => this.siloApiService.readAffiliateManager(+this.signupAffiliateManager?.userId, authToken)))
      .subscribe((response) => {
        const coordonnees: Coordonnee = {
          civility: response?.title,
          firstName: response?.firstName,
          lastName: response?.lastName,
          phoneNumber: null
        };
        this.initialData = coordonnees;
        localStorage.setItem(EMAIL, JSON.stringify(response?.email));
      });
  }

  public nextStep(): void {
    if (this.coordonneesForm.getRawValue()?.coordonnee) {
      localStorage.setItem(COORDONNEE, JSON.stringify(this.coordonneesForm.getRawValue()?.coordonnee));
    }
    this.router.navigateByUrl(
      `${RoutesEnum.SIGNUP_AFFILIATE_MANAGER_SECOND_STEP}/${this.signupAffiliateManager?.saml}?hash=${this.signupAffiliateManager?.hash}`
    );
  }
}
