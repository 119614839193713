import { Component } from '@angular/core';
import { GatewayService } from '@services/gateway.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-fourth-step-affilie-apz-component',
  templateUrl: './fourth-step-affilie-apz.component.html',
  styleUrls: ['../../../../../assets/css/affilie-apz-global.scss', './fourth-step-affilie-apz.component.scss']
})
export class FourthStepAffilieApzComponent {
  // Constructor
  constructor(private gatewayService: GatewayService) {}

  // Methods
  goLogin(): void {
    this.gatewayService
      .authentificate(environment.clientIdAffilieAPZ, 'token', environment.redirectURLAffilieAPZ)
      .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
  }

  private setPageHtml(html: string): void {
    document.body.innerHTML = html;
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.submit();
  }
}
