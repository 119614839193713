import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Erreurs, CodeErreurs } from '../../shared/models/errors.model';
import { ReponseVerifierCode } from '../../shared/models/reponse.verifierCode.model';
import { SiloApiService } from '../../shared/services/siloApi.service';
import { GatewayService } from '../../shared/services/gateway.service';
import decodeCodeApp from 'src/app/shared/helpers/decoder.helper';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';

@Component({
    selector: 'app-activation-sms',
    templateUrl: './activation-sms.component.html',
    styleUrls: ['./activation-sms.component.scss']
})
export class ActivationSmsComponent implements OnInit {
    public codeSMS: FormControl;
    public ginsc: FormGroup;
    public messageErreur: string;
    private saml: string;
    private codeApplication: string;
    public estAppetiz: boolean;
    public estCesu: boolean;


    constructor(private router: Router, private route: ActivatedRoute,
                private formBuilder: FormBuilder,
                private siloApiService: SiloApiService,
                private gatewayService: GatewayService) {
    }

    ngOnInit() {
        this.codeSMS = this.formBuilder.control('', Validators.required);
        this.ginsc = this.formBuilder.group([this.codeSMS]);
        this.saml = this.route.snapshot.paramMap.get('saml');
        const samlDecoder = atob(this.saml);
        this.codeApplication = samlDecoder.split('|')[2];
        const decoded = decodeCodeApp(this.codeApplication);
        this.estAppetiz = decoded.estAppetiz || decoded.estAppetizMobile;
        this.estCesu = decoded.estCesu;
    }

    public validerInscription(f: NgForm): void {
        this.messageErreur = null;
        if (this.ginsc.valid) {
            this.gatewayService.getAccessToken()
                .subscribe(authToken =>
                    this.siloApiService
                        .verifierCodeSMS(this.codeSMS.value, authToken)
                        .subscribe(reponse => this.redirigerPageActivation(reponse), error => this.gererErreurs(error)));
        }
    }

    public redirigerPageActivation(reponse: string) {
        const reponseJSON: ReponseVerifierCode = JSON.parse(reponse);
        this.router.navigate([`${RoutesEnum.ACTIVATE_ACCOUNT}`], { queryParams: { hash: reponseJSON.hash } });
    }

    public gererErreurs(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            this.messageErreur = 'Erreur inatendue : ' + error.error.message;
        } else {
            const errors: Erreurs = JSON.parse(error.error);
            switch (errors.errors[0].code) {
                case CodeErreurs.UNEXPECTED_011:
                    this.messageErreur = 'Erreur technique';
                    break;
                case CodeErreurs.IMPOSSIBLE_ACTION_013:
                    this.messageErreur = 'Le code est incorrect. Veuillez vérifier et réessayer.';
                    break;
                case CodeErreurs.FORBIDDEN_ACTION_010:
                    this.messageErreur = 'Le code a expiré.';
                    break;
                default:
                    this.messageErreur = 'Erreur inattendue : ' + errors.errors[0].code;
                    break;
            }
        }
    }
}
