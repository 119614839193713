import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import decodeCodeApp from '../../shared/helpers/decoder.helper';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss']
})
export class PinCodeComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  public saml: string;
  public login: string;
  public applicationId: string;
  public redirectUrl: string;
  public estMobileAPZ: boolean;
  public estBimpli: boolean;
  public context: string;

  ngOnInit(): void {
    this.saml = this.route.snapshot.paramMap.get('saml');
    const samlDecoder = atob(this.saml);
    this.login = samlDecoder.split(';')[0];
    this.applicationId = samlDecoder.split(';')[1];
    this.redirectUrl = samlDecoder.split(';')[2];
    this.context = samlDecoder.split(';')[3] ? samlDecoder.split(';')[3] : '';

    const decoded = decodeCodeApp(this.applicationId);
    this.estMobileAPZ = decoded.estAppetizMobile;
    // liste des applications autorisées à contacter le parcours pin-code (otp sms)
    this.estBimpli = this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE 
      || this.applicationId === ApplicationCodeEnum.CESU 
      || this.applicationId === ApplicationCodeEnum.APZ;
  }
}
