import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { ToastService } from '@shared/toast/toast.service';
import { Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import { AffiliateSignupService } from '../service/affiliate-signup.service';

const FORBIDDEN_ACTION_010 = 'FORBIDDEN_ACTION_010';

@Component({
  selector: 'app-fourth-step-signup-affilie',
  templateUrl: './fourth-step-signup-affilie.component.html',
  styleUrls: ['./fourth-step-signup-affilie.component.scss']
})
export class FourthStepSignupAffilieComponent implements OnInit, OnDestroy {
  public saml: string;
  public email: string;

  private readonly destroy$ = new Subject<boolean>();
  private applicationId: string;
  private userId: string;

  constructor(
    private readonly sessionQuery: SessionQuery,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly sessionStateService: SessionStateService,
    private readonly gatewayService: GatewayService,
    private readonly snackbarService: SnackbarService,
    private readonly siloApiService: SiloApiService,
    private readonly translate: TranslateService,
    private readonly affiliateSignupService: AffiliateSignupService
  ) {
    if (this.affiliateSignupService.userId) {
      this.userId = this.affiliateSignupService.userId.toString();
    }
    this.email = this.affiliateSignupService.email;
  }

  ngOnInit(): void {
    this.sessionStateService.setApplicationCode(this.route);
    this.sessionQuery.selectedApplicationCode
      .pipe(
        takeUntil(this.destroy$),
        tap((applicationCode: ApplicationCodeEnum) => (this.applicationId = applicationCode))
      )
      .subscribe();

    this.sessionQuery.selectedSaml
      .pipe(
        takeUntil(this.destroy$),
        tap((saml) => (this.saml = saml))
      )
      .subscribe();
  }

  public reSendEmail(): void {
    this.gatewayService
      .getAccessToken()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((authToken) => this.siloApiService.sendEmailActivationAccount(this.userId, this.applicationId, authToken)),
        catchError((err) => {
          if (err?.error?.errors[0].code === FORBIDDEN_ACTION_010) {
            this.router.navigateByUrl(`${RoutesEnum.LOGIN}/${this.saml}`);
          } else {
            this.snackbarService.showDanger(this.translate.instant('AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FOURTH_STEP.ERROR.NOT_SEND'));
          }
          throw err;
        })
      )
      .subscribe(
        () => {
          this.snackbarService.showSuccess('Votre demande a bien été renvoyée');
          this.toastService.show(this.translateService.instant('AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FOURTH_STEP.RESEND_EMAIL'), {
            classname: 'bg-success text-light'
          });
        },
        (err) => this.snackbarService.showSuccess('Votre demande a bien été renvoyée')
      );
  }

  public goBack(): void {
    this.router.navigateByUrl(`${RoutesEnum.SIGNUP_AFFILIE_THIRD_STEP}/${this.saml}`);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
