import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators, NgForm, FormGroup } from '@angular/forms';
import { GatewayService } from '../../shared/services/gateway.service';

@Component({
    selector: 'app-authentification',
    templateUrl: './authentification.component.html',
    styleUrls: ['./authentification.component.scss']
})
export class AuthentificationComponent implements OnInit {

    public clientId: FormControl;
    public responseType: FormControl;
    public ginsc: FormGroup;
    public pageHtml: string;

    public constructor(private formBuilder: FormBuilder, private gatewayService: GatewayService) { }

    public ngOnInit(): void {
        this.clientId = this.formBuilder.control('6327ea0f-41a3-434a-965c-f28ceb5fe1cf', Validators.required);
        this.responseType = this.formBuilder.control('token', Validators.required);
        this.ginsc = this.formBuilder.group([this.clientId, this.responseType]);
    }

    public validerInscription(f: NgForm): void {
        if (this.ginsc.valid) {
            this.gatewayService.authentificate(this.clientId.value, this.responseType.value, undefined)
                .subscribe(contenuHtml => this.setPageHtml(contenuHtml));
        }
    }

    public setPageHtml(html: string): void {
        document.body.innerHTML = html;
        const form = (document.getElementsByTagName('form')[0] as HTMLFormElement);
        form.submit();
    }


}
