import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { WeezenComponent } from '../../../weezen/weezen.component';
// import { Favicons } from '../../../../shared/favicons';
import { GatewayService } from '../../../../shared/services/gateway.service';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-third-step-asp-component',
  templateUrl: './third-step-asp-component.component.html',
  styleUrls: ['./third-step-asp-component.component.scss',
    '../../create-account.component.scss',
    '../../../../../assets/css/asp-global.scss']
})
export class ThirdStepAspComponentComponent extends WeezenComponent implements OnInit {

  @Output() reSend: EventEmitter<any[]> = new EventEmitter();
  @Input() messageEmail: string;

  constructor(
    // fav: Favicons
    ) {
    // super(fav);
    super();
  }

  ngOnInit() {

  }

  reSendEmail() {
    this.reSend.emit();
  }

  goToLogin() {
    window.location.href = environment.redirectURIWee.replace('/token', '');
  }
}
