import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { MerchantSignupRightPanel } from '@shared/enums/merchants-signup-right-panel.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-siret-found',
  templateUrl: './siret-found.component.html',
  styleUrls: ['./siret-found.component.scss']
})
export class SiretFoundComponent {

  @Output() closePanel$ = new EventEmitter<void>();
  @Output() goToPanel$ = new EventEmitter<string>();


 public goBack(): void {
   this.closePanel$.next();
 }

 public signIn(): void {
  
}
}
