<div id="second-step-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row h-100">
    <div id="menu-container" class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 d-none d-md-block p-0">
      <app-affiliate-apz-stepper [step]="2"></app-affiliate-apz-stepper>
    </div>
    <div id="step-container" class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 p-0">
      <div id="info-container" class="d-flex flex-column align-items-start justify-content-start w-100 h-100 p-3">
        <div class="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
          <!-- BACK -->
          <div class="d-flex flex-row align-items-center justify-content-between w-100 mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
            <button id="go-back-btn" type="button" class="btn btn-link p-0" (click)="goBack()">
              <div class="d-flex flex-row align-items-center justify-content-center">
                <i id="go-back-btn-icon" class="bi bi-arrow-left"></i>
                <span class="ml-2" id="go-back-btn-label">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.BACK' | translate }}</span>
              </div>
            </button>
            <span id="stepper-pos" class="d-flex d-sm-flex d-md-none">{{
              'AFFILIE_APZ.SIGNUP_AFFILIATE_MANAGER.SECOND_STEP.STEP_SM' | translate
            }}</span>
          </div>
          <app-account-info
            [email]="email"
            [messageErreur]="messageErreur"
            [success]="success"
            [parentForm]="accountForm"
            (accountValidated)="createAccount()"
          >
          </app-account-info>
        </div>
      </div>
    </div>
  </div>
</div>
