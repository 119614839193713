import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SecretQuestion } from '@shared/models/secret-question.model';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SiloApiService } from '@services/siloApi.service';
import { GatewayService } from '@services/gateway.service';
import { TealiumService } from '@services/tealium/tealium.service';
import {distinctUntilChanged, finalize} from 'rxjs/operators';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { ModalService } from '@shared/components/modal/modal.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-bimpli-change-question',
  templateUrl: './bimpli-change-question.component.html',
  styleUrls: ['./bimpli-change-question.component.scss']
})
export class BimpliChangeQuestionComponent implements OnInit, OnDestroy {
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Input() login: string;
  @Input() saml: string;
  @Input() applicationId: string;
  @Input() messageErreur: string;

  identifiantForm = this.fb.group({
    currentPassword: [undefined, Validators.required],
    secretQuestion: [undefined, [Validators.required]],
    response: [undefined, [Validators.required]]
  });

  isLoading: boolean;
  isSubmitted: boolean;
  mySubscription = new Subscription();
  hide = true;
  questions: Array<SecretQuestion>;
  applicationCodeEnum = ApplicationCodeEnum;
  isActualPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private valueCaptcha: string;

  constructor(
    private fb: FormBuilder,
    private gtds: GroundTruthDataService,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private readonly tealiumService: TealiumService,
    private readonly modalService: ModalService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.profile.secreteQuestionLoad');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.profile.secreteQuestionLoad');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.profile.secreteQuestionLoad');
    }
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
        .execute('ReadSecretQuestionsAction')
        .pipe(
          finalize(() => {
            this.siloApiService.readSecretQuestions(authToken, this.valueCaptcha).subscribe((q) => {
              this.questions = q.items;
              this.reCaptchaV3Service
                .execute('ReadSecretQuestionsAction')
                .pipe(
                  finalize(() => {
                    this.siloApiService.readSecretQuestions(authToken, this.valueCaptcha, this.login, 'SiteAPZ').subscribe((qb) => {
                      if (qb.items && qb.items.length > 0) {
                        this.identifiantForm.get('secretQuestion').setValue(qb.items[0].id);
                      }
                    });
                  })
                )
                .subscribe((token) => (this.valueCaptcha = token));
            });
          })
        )
        .subscribe((token) => (this.valueCaptcha = token));
    });
  }

  back() {
    window.history.back();
  }

  submitForm() {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.link('bimpli_benef.web.profile.validateSecreteQuestionClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.link('bimpli_financeur.web.profile.validateSecreteQuestionClick');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.link('bimpli_mobile.web.profile.validateSecreteQuestionClick');
    }
    this.isSubmitted = true;
    if (this.identifiantForm.valid) {
      this.isSubmitted = false;
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit(this.identifiantForm.getRawValue());
    }
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }
}
