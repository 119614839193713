import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import SecondStepApz from '../apz-second-step.class';
import { Router } from '@angular/router';
import { SiloApiService } from 'src/app/shared/services/siloApi.service';
import { GatewayService } from 'src/app/shared/services/gateway.service';
import { EmployeesService } from 'nit-angular-lib';
import { TealiumService } from '../../../../shared/services/tealium/tealium.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-second-step-apz-component',
  templateUrl: './second-step-apz-component.component.html',
  styleUrls: [
    './second-step-apz-component.component.scss',
    '../../create-account-APZ.component.scss',
    '../../../../../assets/css/apz-global.scss'
  ]
})
export class SecondStepApzComponentComponent extends SecondStepApz implements OnChanges, OnInit {
  @Input() saml: string;
  @Input() messageErreur: string;

  constructor(
    protected fb: FormBuilder,
    protected translate: TranslateService,
    protected siloApiService: SiloApiService,
    protected gatewayService: GatewayService,
    private router: Router,
    protected tealiumService: TealiumService,
    protected readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
    super(fb, translate, siloApiService, tealiumService, gatewayService, reCaptchaV3Service);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mailPro) {
      this.init();
    }
  }
  ngOnInit() {
    this.tealiumService.view('apz.web.registrationProcess.createAccountPageLoad');
  }

  getCGU() {
    this.tealiumService.link('apz.web.registrationProcess.consultCGUClick');
    this.router.navigateByUrl('/cgu/' + this.saml);
  }

  validateForm() {
    this.tealiumService.link('apz.web.registrationProcess.validateCreateAccountClick');
    this.incorrectLogin = false;
    if (!this.passwordForm.get('loginSaisie').value && this.loginEstMail) {
      this.passwordForm.get('login').setValue(this.mailPro);
    }
    this.submitForm();
  }
}
