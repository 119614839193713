import { Injectable } from '@angular/core';
import { CesuRegistrationStatusEnum } from '@shared/enums/cesu.enum';
import { CesuStore } from './cesu.store';

@Injectable({ providedIn: 'root' })
export class CesuStateService {
  constructor(private cesuStore: CesuStore) {
  }

  public setCesuRegistrationStatus(cesuRegistrationStatus: CesuRegistrationStatusEnum) {
    this.cesuStore.update({ cesuRegistrationStatus });
  }

  public setCesuRegistrationEmployeeId(registrationEmployeeId: string) {
    this.cesuStore.update({ registrationEmployeeId });
  }

  public setCesuRegistrationLogin(registrationLogin: string) {
    this.cesuStore.update({ registrationLogin });
  }

  public setCesuRegistrationPassword(registrationPassword: string) {
    this.cesuStore.update({ registrationPassword });
  }

  public setCesuShowFooter(showFooter: boolean) {
    this.cesuStore.update({ showFooter });
  }
}
