<div class="etablissement-list">
  <div class="title-text mb-3">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_CONFIRM_LIST.TITLE' | translate }}</div>
  <div class="subtitle-text mb-4">
    {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_CONFIRM_LIST.DESCRIPTION' | translate }}
  </div>

  <form [formGroup]="form">
    <div formArrayName="affiliates" *ngFor="let affiliate of affiliateFormArray.controls; let i = index">
      <div
        class="affiliate-data row"
        [ngClass]="{
          stripped: i % 2 == 0
        }"
        [formGroupName]="i"
      >
        <div class="affiliate-identity col-sm-12 col-md-3 col-lg-3">
          <div class="name mb-2">{{ affiliates[i].name }}</div>
          <div class="siret mb-2">{{ codeCrt(affiliates[i]) }}</div>
          <div *ngIf="affiliates[i].affiliateType.isRefundCenter" class="type-etablissement">
            {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_CONFIRM_LIST.REFUND_CENTER' | translate }}
          </div>
          <div *ngIf="!affiliates[i].affiliateType.isRefundCenter" class="type-etablissement">
            {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_CONFIRM_LIST.ETABLISSEMENT' | translate }}
          </div>
        </div>
        <div class="location col-sm-12 col-md-3 col-lg-3">
          <ng-container *ngFor="let adresse of location(affiliates[i])">
            <div>{{ adresse }}</div>
          </ng-container>
        </div>
        <div class="crt-number col-sm-12 col-md-5 col-lg-5">
          <input
            type="text"
            class="form-control"
            formControlName="code"
            placeholder="Numéro d’affiliation CRT"
            [ngClass]="{ 'is-invalid': affiliate?.get('code').errors }"
            (blur)="codeBlur(i)"
          />
          <small class="text-danger font-weight-bold error-msg mt-1" *ngIf="affiliate?.get('code').errors?.required">
            {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_CONFIRM_LIST.ERRORS.CRT_REQUIRED' | translate }}
          </small>
          <small
            class="text-danger font-weight-bold error-msg mt-1"
            *ngIf="!affiliate?.get('code').errors?.required && affiliate?.get('code').errors?.codeInvalid"
          >
            {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_CONFIRM_LIST.ERRORS.CRT_CODE_INVALID' | translate }}
          </small>
          <small class="text-danger font-weight-bold error-msg mt-1" *ngIf="affiliate?.get('code').errors?.pattern">
            {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_CONFIRM_LIST.ERRORS.CRT_PATTERN' | translate }}
          </small>
        </div>
        <div class="checkbox-btn col-sm-12 col-md-1 col-lg-1 text-right">
          <input type="checkbox" formControlName="checked" (change)="changeCheckbox($event, i)" />
        </div>
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn confirm-btn mt-3" [disabled]="form?.invalid" (click)="submit()">
        <span>{{ btnText }}</span>
      </button>
    </div>
  </form>
</div>
