import { Component, EventEmitter, Output } from '@angular/core';
import { MerchantSignupRightPanel } from '@shared/enums/merchants-signup-right-panel.enum';
import { MerchantsSignupService } from '../../merchants-signup.service';

@Component({
  selector: 'app-unlock-by-bill-infos',
  templateUrl: './unlock-by-bill-infos.component.html',
  styleUrls: ['./unlock-by-bill-infos.component.scss']
})
export class UnlockByBillInfosComponent {

  @Output() goToPanel$ = new EventEmitter<string>();

  constructor(
      private readonly merchantsSignupService: MerchantsSignupService,
    ) {}

  
  public goBack(): void {
    this.merchantsSignupService.activatedChild.next('bill');
    this.goToPanel$.emit(MerchantSignupRightPanel.CHECK_ACCESS);
 }
 
}