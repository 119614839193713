import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SiloApiService } from '../../../shared/services/siloApi.service';
import { GatewayService } from '../../../shared/services/gateway.service';
import { TealiumService } from '../../../shared/services/tealium/tealium.service';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import { Erreurs, CodeErreurs } from 'src/app/shared/models/errors.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SecretQuestion } from '../../../shared/models/secret-question.model';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GroundTruthDataService } from '../../../shared/services/ground-truth-data.service';
import { TranslateService } from '@ngx-translate/core';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-apz-change-question',
  templateUrl: './apz-change-question.component.html',
  styleUrls: ['./apz-change-question.component.scss', '../../../../assets/css/apz-global.scss']
})
export class ApzChangeQuestionComponent implements OnInit, OnDestroy {
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Input() login: string;

  identifiantForm = this.fb.group({
    currentPassword: [undefined, Validators.required],
    secretQuestion: [undefined, [Validators.required]],
    response: [undefined, [Validators.required]]
  });

  isSubmitted: boolean;
  isLoading: boolean;
  isSecondStep = false;
  hide = true;
  currentPasswordInvalid = false;
  questions: Array<SecretQuestion>;
  mySubscription = new Subscription();
  messageErreur: string;
  private valueCaptcha: string;

  constructor(
    private fb: FormBuilder,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private readonly tealiumService: TealiumService,
    private snackbarService: SnackbarService,
    private gtds: GroundTruthDataService,
    private translateService: TranslateService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
        .execute('ReadSecretQuestionsAction')
        .pipe(
          finalize(() => {
            this.siloApiService.readSecretQuestions(authToken, this.valueCaptcha).subscribe((q) => {
              this.questions = q.items;
              this.reCaptchaV3Service
                .execute('ReadSecretQuestionsAction')
                .pipe(
                  finalize(() => {
                    this.siloApiService.readSecretQuestions(authToken, this.valueCaptcha, this.login, 'SiteAPZ').subscribe((qb) => {
                      if (qb.items && qb.items.length > 0) {
                        this.identifiantForm.get('secretQuestion').setValue(qb.items[0].id);
                      }
                    });
                  })
                )
                .subscribe((token) => (this.valueCaptcha = token));
            });
          })
        )
        .subscribe((token) => (this.valueCaptcha = token));
    });
  }

  goToSecondStep() {
    this.isSubmitted = true;
    if (this.identifiantForm.get('currentPassword').valid) {
      this.isSubmitted = false;
      this.gtds.setIsSpinnerActivated(true);
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.reCaptchaV3Service
          .execute('UserPasswordCheckAction')
          .pipe(
            finalize(() => {
              this.siloApiService
                .userPasswordCheck(
                  this.login,
                  this.identifiantForm.get('currentPassword').value,
                  'SiteAPZMobile',
                  authToken,
                  this.valueCaptcha
                )
                .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
                .subscribe(
                  (retour) => {
                    this.currentPasswordInvalid = !retour.resultCheck;
                    this.isSecondStep = !this.currentPasswordInvalid;
                  },
                  (error) => this.gererErreurs(error)
                );
            })
          )
          .subscribe((token) => (this.valueCaptcha = token));
      });
    }
  }

  submitForm() {
    this.isSubmitted = true;
    if (this.identifiantForm.valid) {
      this.isSubmitted = false;
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit(this.identifiantForm.getRawValue());
    }
  }

  public gererErreurs(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = error.error;
      switch (errors.errors[0].code) {
        case CodeErreurs.UNEXPECTED_011:
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED_011');
          break;
        case CodeErreurs.FORBIDDEN_ACTION_040:
          this.messageErreur =
            // tslint:disable-next-line: max-line-length
            'Votre compte est bloqué suite à vos différentes tentatives de connexion. Nous vous invitons à aller sur "Mot de passe oublié" pour réinitialiser votre mot de passe et débloquer votre compte.';
          break;
        default:
          this.messageErreur = this.messageErreur =
            this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED') + errors.errors[0].code;
          break;
      }
    }
    this.snackbarService.showDanger(this.messageErreur);
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }
}
