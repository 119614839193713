<div id="third-step-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row h-100">
    <div id="menu-container" class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 d-none d-md-block p-0">
      <app-affilie-apz [step]="3" [saml]="saml"></app-affilie-apz>
    </div>
    <div id="step-container" class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 p-0">
      <div class="d-flex flex-column align-items-start justify-content-start w-100 h-100 p-3">
        <!-- BACK -->
        <div class="d-flex flex-row align-items-center justify-content-between w-100 mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
          <button id="go-back-btn" type="button" class="btn btn-link p-0" (click)="goBack()">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <i id="go-back-btn-icon" class="bi bi-arrow-left"></i>
              <span class="ml-2" id="go-back-btn-label">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.THIRD_STEP.BACK' | translate }}</span>
            </div>
          </button>
          <span id="stepper-pos" class="d-flex d-sm-flex d-md-none">{{
            'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.THIRD_STEP.STEP_SM' | translate
          }}</span>
        </div>
        <!-- HELLO -->
        <div class="d-flex flex-column align-items-start justify-content-center mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
          <span id="third-step-title" class="d-flex flex-row align-items-start justify-content-center">{{
            'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.THIRD_STEP.HELLO' | translate
          }}</span>
          <span class="d-flex flex-row align-items-start justify-content-center third-step-sub-title"
            >{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.THIRD_STEP.HELLO_DESCRIPTION' | translate }} {{ email }}</span
          >
          <span class="third-step-sub-title mt-4">
            {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.THIRD_STEP.HELLO_DESCRIPTION_PS' | translate }}
          </span>
        </div>
        <div id="divider" class="mb-4 w-50"></div>
        <!-- RE SEND -->
        <div class="d-flex flex-row align-items-center justify-content-center">
          <span class="third-step-sub-title">
            {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.THIRD_STEP.RESEND_DESCRIPTION' | translate }}
          </span>
          <button class="btn btn-link" (click)="reSendEmail()">
            <span id="resend-btn-label">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.THIRD_STEP.RESEND_ACTION' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>
