<div class="background-from-app container-fluid vw-100 d-flex flex-column">
    <app-snackbar></app-snackbar>
    <div *ngIf="applicationId !== applicationCodeEnum.BENEFIT_MOBILE" class="bloc-action-back d-flex align-items-center" (click)="back()">
        <i class="bi bi-arrow-left"></i>
        <span class="ml-2">Retour</span>
    </div>
    <span class="create mt-4">Nouvelle question secrète</span>

    <form [formGroup]="identifiantForm" (keydown.enter)="submitForm()" class="justify-content-start col-12 col-md-6 p-0">
        <div class="inner_page mt-4">
            <label for="currentPassword">Mot de passe actuel</label>
            <div class="input-group">
                <input [type]="(isActualPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="currentPassword" placeholder="Saisir votre mot de passe actuel" id="currentPassword"
                       formControlName="currentPassword">
                <div class="input-group-append">
                    <button
                        *ngIf="(isActualPasswordDisplayed$ | async) === false"
                        type="button"
                        class="btn input-group-text"
                        (click)="isActualPasswordDisplayed$.next(true)"
                    >
                        <i class="bi bi bi-eye-fill"></i>
                    </button>
                    <button
                        *ngIf="(isActualPasswordDisplayed$ | async) === true"
                        type="button"
                        class="btn input-group-text"
                        (click)="isActualPasswordDisplayed$.next(false)">
                        <i class="bi bi-eye-slash-fill"></i>
                    </button>
                </div>
            </div>

            <div class="input-group mt-4">
                <label>Répondez à la question de votre choix :</label>
                <div *ngFor="let question of questions; let i = index" class="text-left">
                    <div class="mb-2 custom-control custom-radio ">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="secretQuestion"
                            id="{{ 'radio' + i }}"
                            [value]="question.id"
                            formControlName="secretQuestion"
                        />
                        <label class="list-radio custom-control-label question " for="{{ 'radio' + i }}">{{ question.label }}</label>
                    </div>
                </div>
            </div>

            <div class="input_group">
                <input type="text" class="form-control" id="reponse" name="response" formControlName="response" placeholder="Ma réponse" />
                <div *ngIf="isSubmitted || identifiantForm.get('response').touched">
                    <small
                        class="form-text text-danger"
                        *ngIf="identifiantForm.get('response').errors && identifiantForm.get('response').errors.required"
                    >
                        {{ 'COMMON.FORM.REQUIRED' | translate }}
                    </small>
                    <small
                        class="form-text text-danger"
                        *ngIf="identifiantForm.get('secretQuestion').errors && identifiantForm.get('secretQuestion').errors.required"
                    >
                        {{ 'COMMON.FORM.SECRET_QUESTION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>
        </div>
        <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!identifiantForm.valid" (click)="submitForm()">
            <div class="d-flex flex-row align-items-center justify-content-center">
                <div *ngIf="isLoading" class="spinner-border"></div>
                <span [ngClass]="{'ml-2': isLoading }">Continuer</span>
            </div>
        </button>
    </form>
</div>

<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>
