import { Component, OnDestroy, OnInit } from '@angular/core';
import { CesuStateService } from '@services/store/cesu/cesu-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ChangePassword } from '@shared/models/change-password.model';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-cesu-change-pwd-success',
  templateUrl: './cesu-change-pwd-success.component.html',
  styleUrls: ['./cesu-change-pwd-success.component.scss', '../../../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuChangePwdSuccessComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public changePasswordInfo: ChangePassword;

  constructor(
    private readonly sessionQuery: SessionQuery,
    private readonly cesuStateService: CesuStateService,
  ) {
  }

  ngOnInit(): void {
    this.cesuStateService.setCesuShowFooter(false);

    const changePasswordInfoSubscription = this.sessionQuery.selectedChangePasswordInfo
      .pipe(tap((changePasswordInfo: ChangePassword) => (this.changePasswordInfo = changePasswordInfo)))
      .subscribe();

    this.subscription.add(changePasswordInfoSubscription);
  }

  goBack() {
    window.location.href = this.changePasswordInfo.redirectUrl;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();

    this.cesuStateService.setCesuShowFooter(true);
  }
}
