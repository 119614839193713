import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SessionStore } from '@services/store/session/session.store';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { ProductEnum } from '@shared/enums/product.enum';
import { RegistrationStepEnum } from '@shared/enums/registration-step.enum';
import { AccountActivation } from '@shared/models/account-activation.model';
import { ChangePassword } from '@shared/models/change-password.model';
import { ForgottenPassword } from '@shared/models/forgotten-password.model';
import { MerchantsInvite } from '@shared/models/merchant-invite';
import { SignupAffiliateManager } from '@shared/models/signup-affiliate-manager';
import { SessionState } from '@shared/states/session-state.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {

  public selectedApplicationCode: Observable<ApplicationCodeEnum> = this.select(store => store.applicationCode);
  public selectedProductId: Observable<ProductEnum> = this.select(store => store.productId);
  public selectedSaml: Observable<string> = this.select(store => store.saml);
  public selectedType: Observable<string> = this.select(store => store.type);
  public selectedRegistrationStep: Observable<RegistrationStepEnum> = this.select(store => store.registrationStep);
  public selectedAccountActivationInfo: Observable<AccountActivation> = this.select(store => store.accountActivationInfo);
  public selectedForgottenLoginMail: Observable<string> = this.select(store => store.forgottenLoginMail);
  // Retrieve the login entered by the user in the forgotten password page
  public selectedForgottenPasswordLogin: Observable<string> = this.select(store => store.forgottenPasswordLogin);
  public selectedForgottenPasswordInfo: Observable<ForgottenPassword> = this.select(store => store.forgottenPasswordInfo);
  // Retrieve the change password information
  public selectedChangePasswordInfo: Observable<ChangePassword> = this.select(store => store.changePasswordInfo);
  public isLocal: Observable<boolean> = this.select(store => store.isLocal);
  public selectedSignupAffiliateManager: Observable<SignupAffiliateManager> = this.select(store => store.signupAffiliateManager);
  public selectedMerchantsInvite: Observable<MerchantsInvite> = this.select(store => store.merchantsInvite);


  constructor(protected store: SessionStore) {
    super(store);
  }
}
