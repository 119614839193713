import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { MerchantSignupRightPanel } from '@shared/enums/merchants-signup-right-panel.enum';
import { SignupCoordonneesForm } from '@shared/enums/signup-coordonnees-form';
import { Affiliate, ReadAffiliatesParamQuery } from '@shared/models/affiliate';
import { AuthToken } from '@shared/models/authtoken.model';
import { MerchantSignupAffiliatesData } from '@shared/models/merchant-signup-affiliates-data';
import { saveAs } from 'file-saver';
import { AffiliateProfil, CreateTermsAndConditions, UserAffiliateCreate } from 'nit-angular-lib';
import { Observable, Subject, combineLatest } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MerchantsSignupService } from '../merchants-signup.service';
import { CoordonneesService } from './coordonnees.service';

const CODE_ERROR_DUPLICATE_LOGIN = 'ALREADY_EXIST_022';

@Component({
  selector: 'app-coordonnees',
  templateUrl: './coordonnees.component.html',
  styleUrls: ['./coordonnees.component.scss']
})
export class CoordonneesComponent implements OnInit, OnDestroy {
  @Output() goToPanel$ = new EventEmitter<string>();
  @Output() success$ = new EventEmitter<string>();
  @Input() public saml: string;
  public success: boolean;
  public messageErreur: string;
  private readonly destroy$ = new Subject<boolean>();
  public token: AuthToken;

  refresh$: Observable<boolean>;
  affiliatesData: MerchantSignupAffiliatesData[];
  affiliates: Affiliate[];
  identificationQuery: ReadAffiliatesParamQuery;

  constructor(
    private readonly coordonneesService: CoordonneesService,
    private readonly translateService: TranslateService,
    private readonly gatewayService: GatewayService,
    private readonly merchantsSignupService: MerchantsSignupService
  ) {}
  ngOnInit(): void {
    const identificationQuery$ = this.merchantsSignupService.identificationData$.pipe(
      tap((identificationData) => {
        this.identificationQuery = identificationData.affiliatesQuery;
      })
    );

    const displayedAffiliates$ = this.coordonneesService.getDisplayedAffiliates$().pipe(
      tap((affiliatesData) => {
        this.affiliatesData = affiliatesData;
        this.affiliates = [];
        affiliatesData.map((data) => data.affiliates).forEach((affiliateList) => (this.affiliates = this.affiliates.concat(affiliateList)));
      })
    );

    this.refresh$ = combineLatest([identificationQuery$, displayedAffiliates$]).pipe(map(() => true));
  }

  submitContactForm(form: FormGroup): void {
    const formData = form?.getRawValue() as SignupCoordonneesForm;

    const affiliateProfils = this.affiliates.map((affiliate) => {
      return {
        affiliateId: affiliate?.id,
        profil: 'AFF_ADMINISTRATEUR'
      } as AffiliateProfil;
    });

    const userAffiliateCreate: UserAffiliateCreate = {
      login: formData.email,
      applicationId: 'SiteAffilie',
      title: formData.civility,
      lastName: formData.lastName,
      firstName: formData.firstName,
      password: formData.password,
      affiliateProfils,
      phoneNumber: formData.phoneNumber ? formData.phoneNumber : null,
      cellPhoneNumber: formData.cellPhoneNumber,
      email: formData.email
    };

    this.gatewayService
      .getAccessToken()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((token) => {
          return this.coordonneesService.createUserAffiliate(userAffiliateCreate, token).pipe(
            takeUntil(this.destroy$),
            switchMap((userCreated) => {
              return this.createTermsAndConditionsAcceptances(userCreated?.id, token);
            }),
            catchError((err) => {
              this.handleError(err);
              throw err;
            })
          );
        })
      )
      .subscribe(() => {
        this.success = true;
        this.success$.emit(formData.email);
      });
  }

  private createTermsAndConditionsAcceptances(userId: number, aToken: AuthToken): Observable<string> {
    const body: CreateTermsAndConditions = {
      profileType: 'AFFILIATE',
      applicationId: ApplicationCodeEnum.AFFILIE_APZ
    };

    return this.coordonneesService.createTermsAndConditionsAcceptances(userId, body, aToken).pipe(
      takeUntil(this.destroy$),
      catchError((err) => {
        this.handleError(err);
        throw err;
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    this.success = false;
    if (error?.error?.errors === undefined) {
      this.messageErreur = error.message;
    } else {
      if (error?.error?.errors[0].code === CODE_ERROR_DUPLICATE_LOGIN) {
        this.messageErreur = this.translateService.instant('AFFILIE_APZ.SIGNUP_AFFILIE.ERROR.LOGIN_ALREADY_EXIST');
      } else {
        this.messageErreur = this.translateService.instant('AFFILIE_APZ.SIGNUP_AFFILIE.ERROR.RETRY');
      }
    }
  }

  public showRgpd(): void {
    this.goToPanel$.emit(MerchantSignupRightPanel.RGPD);
  }

  public getAffiliateEmail(): string {
    return this.identificationQuery?.email;
  }

  public getTotalPages(affiliates: Affiliate[]): number {
    if (affiliates && affiliates?.length > 0) {
      return Math.trunc((affiliates.length - 1) / 5 + 1);
    }
    return 0;
  }

  public downloadAffiliates(data: MerchantSignupAffiliatesData): void {
    const fileName = 'Liste_Etablissement.xls';
    this.gatewayService
      .getAccessToken()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((token) => {
          const affiliates$ = this.coordonneesService.downloadAffiliates(data, token);
          return affiliates$.pipe(takeUntil(this.destroy$));
        })
      )
      .subscribe((res) => {
        return this.saveFile(res, fileName);
      });
  }

  public isRefundCenter(): boolean {
    return !!this.affiliatesData.find((data) => this.hasRefundCenter(data.affiliates));
  }

  private hasRefundCenter(affiliates: Affiliate[]): boolean {
    return !!affiliates.find((affiliate) => !!affiliate.affiliateType?.isRefundCenter);
  }

  private saveFile(file: Blob | string | null, fileNameWithExtension: string): void {
    saveAs(file ? file : '', fileNameWithExtension);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
