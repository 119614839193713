<div class="affiliate-autonome">
  <div class="title-text mb-3">
    {{ title }}
  </div>
  <div class="subtitle-text mb-5">
    {{ subtitle }}
  </div>
  <div class="affiliate-data">
    <div class="mb-3">
      <div class="subtitle-text">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_FOUND.NAME' | translate }}</div>
      <div class="title-text">{{ companyName }}</div>
      <div class="title-text">({{ name }})</div>
    </div>
    <div class="mb-3">
      <div class=" subtitle-text">
        {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_FOUND.LOCATION' | translate }}
      </div>
      <div class="location">{{ address }}</div>
    </div>
    <div class="w-100 text-right">
      <button type="button" class="btn confirm-btn" (click)="confirm()">
        <span>{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_FOUND.OK' | translate }}</span>
      </button>
    </div>
  </div>
</div>
