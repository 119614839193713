<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN">
  <app-notice-asp [token]="saml" [applicationId]="codApp"></app-notice-asp>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
  <app-cesu-notice></app-cesu-notice>
</ng-container>

<ng-container
  *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ || (applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE"
>
  <app-notice-apz></app-notice-apz>
</ng-container>
