import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GatewayService } from '@services/gateway.service';
import { MerchantSignupRightPanel } from '@shared/enums/merchants-signup-right-panel.enum';
import { Affiliate, ReadAffiliatesParamQuery } from '@shared/models/affiliate';
import { MerchantSignupAffiliatesData } from '@shared/models/merchant-signup-affiliates-data';
import { atLeastOneFieldIsNotEmptyValidator } from '@shared/validators/custom.validators';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MerchantsSignupService } from '../merchants-signup.service';

@Component({
  selector: 'app-signup-home',
  templateUrl: './signup-home.component.html',
  styleUrls: ['./signup-home.component.scss']
})
export class SignupHomeComponent implements OnInit, OnDestroy {
  refresh$: Observable<boolean>;

  @Output() openPanel$ = new EventEmitter<string>();
  @Output() next$ = new EventEmitter<void>();
  public signupForm: FormGroup;
  private destroy$ = new Subject<boolean>();
  public isEmailNotFound = false;
  public saml: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly merchantsSignupService: MerchantsSignupService,
    private readonly gatewayService: GatewayService
  ) {}

  ngOnInit() {
    this.initForm();

    this.refresh$ = this.merchantsSignupService.identificationData$.pipe(
      tap((data) => {
        if (!!data?.affiliatesQuery?.email) {
          this.manageCheckByEmailResponse(data.affiliates);
        } else if (!!data?.affiliatesQuery?.siret) {
          this.manageCheckBySiretResponse(data.affiliates);
        }
      }),
      map(() => true)
    );
  }

  onSubmit() {
    if (this.signupForm.valid) {
      const queryParam = new ReadAffiliatesParamQuery();

      if (!!this.signupForm.get('siret')?.value === true) {
        queryParam.siret = this.signupForm.get('siret')?.value;
      } else {
        queryParam.email = this.signupForm.get('email')?.value;
      }

      this.gatewayService
        .getAccessToken()
        .pipe(
          takeUntil(this.destroy$),
          switchMap((token) => {
            return this.merchantsSignupService.readAffiliatesByParamQuery(queryParam, token);
          }),
          tap((affiliates) => {
            this.merchantsSignupService.setIdentificationData(
              new MerchantSignupAffiliatesData(queryParam, MerchantsSignupService.filterDisplayedAffiliates(affiliates))
            );
          })
        )
        .subscribe();
    }
  }

  public signIn(): void {}

  public onSiretChange(): void {
    if (!!this.signupForm.get('siret')?.value) {
      this.signupForm.get('email').disable();
    } else {
      this.signupForm.get('email').enable();
    }
  }

  public onEmailChange(): void {
    // To remove "email-not-found" error message once we start changing the input
    if (this.isEmailNotFound) {
      this.isEmailNotFound = false;
    }
    if (!!this.signupForm.get('email')?.value) {
      this.signupForm.get('siret').disable();
    } else {
      this.signupForm.get('siret').enable();
    }
  }

  private initForm(): void {
    this.signupForm = this.fb.group(
      {
        siret: [{ value: '', disabled: false }, [Validators.pattern(/^\d{14}$/)]],
        email: [{ value: '', disabled: false }, [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]]
      },
      {
        validator: atLeastOneFieldIsNotEmptyValidator()
      }
    );
  }

  private manageCheckBySiretResponse(affiliates: Affiliate[]): void {
    if (affiliates && affiliates.length) {
      if (affiliates.some((affiliate) => affiliate?.managers && affiliate?.managers?.length)) {
        this.openPanel$.emit(MerchantSignupRightPanel.SIRET_FOUND);
        return;
      }

      const cdtAffiliate = affiliates?.find((affiliate) => affiliate.affiliateType?.productRange?.name === 'CDT');

      if (!!cdtAffiliate) {
        this.openPanel$.emit(MerchantSignupRightPanel.CHECK_ACCESS);
        return;
      }

      const cisAffiliate = affiliates?.find((affiliate) => affiliate.affiliateType?.productRange?.name === 'CIS');

      if (!!cisAffiliate) {
        this.next$.emit();
        return;
      }
    }
    this.openPanel$.emit(MerchantSignupRightPanel.SIRET_NOT_FOUND);
  }

  private manageCheckByEmailResponse(affiliates: Affiliate[]): void {
    if (!!affiliates?.length) {
      this.next$.emit();
    } else {
      this.isEmailNotFound = true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
