import { Input, Output, EventEmitter } from '@angular/core';
import { Employee } from 'src/app/shared/models/employee.model';
import { validatePassword } from 'src/app/shared/components/password-validation/password.helper';
import { Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserResponse } from 'src/app/shared/models/userResponse.model';
import { SiloApiService } from 'src/app/shared/services/siloApi.service';
import { GatewayService } from 'src/app/shared/services/gateway.service';
import { TealiumService } from '../../../shared/services/tealium/tealium.service';
import { finalize } from 'rxjs/operators';
import { SecretQuestion } from '../../../shared/models/secret-question.model';
import {cellPhoneRegex, mailRegex} from 'nit-angular-lib';
import {ReCaptchaV3Service} from 'ng-recaptcha';

export default class SecondStepApz {
  civilite: string;
  nom: string;
  prenom: string;
  email: string;
  phone: string;
  isSecondStep = false;
  isThirdStep = false;
  isFourthStep = false;
  mailProPresent = true;
  loginExistant = false;
  messageErreur: string;
  hide = true;
  hide2 = true;
  loginEstMail = true;
  incorrectLogin = false;
  isSubmitted: boolean;
  private valueCaptcha: string;

  questions: Array<SecretQuestion>;

  @Input() employee: Employee;
  @Input() cellPhonePro: string;
  @Input() mailPro: string;
  @Input() isLoading: boolean;

  @Output() retour: EventEmitter<any[]> = new EventEmitter();
  @Output() submitted: EventEmitter<any> = new EventEmitter();

  passwordForm = this.fb.group({
    password: [undefined, validatePassword()],
    confirmPassword: [undefined, [Validators.required]],
    login: [undefined],
    loginSaisie: [false],
    acceptCGU: [false],
    secretQuestion: [undefined, [Validators.required]],
    response: [undefined, [Validators.required]]
  });

  constructor(
    protected fb: FormBuilder,
    protected translate: TranslateService,
    protected siloApiService: SiloApiService,
    protected tealiumService: TealiumService,
    protected gatewayService: GatewayService,
    protected readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  init() {
    this.passwordForm.get('loginSaisie').setValue(false);
    this.mailProPresent = true;
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
        .execute('ReadSecretQuestionsAction')
        .pipe(
          finalize(() => {
            this.siloApiService.readSecretQuestions(authToken, this.valueCaptcha).subscribe((q) => (this.questions = q.items));
          })
        )
        .subscribe((token) => (this.valueCaptcha = token));
      if (this.mailPro == null) {
        this.passwordForm.get('loginSaisie').setValue(true);
        this.mailProPresent = false;
      } else {
        this.siloApiService.infosUser(undefined, undefined, this.mailPro, undefined, authToken).subscribe((retour) => {
          if (!this.isEmpty(retour)) {
            this.passwordForm.get('loginSaisie').setValue(true);
            this.mailProPresent = false;
          }
        });
      }
    });
  }

  passwordEqual(): boolean {
    return this.passwordForm.get('password').value === this.passwordForm.get('confirmPassword').value;
  }

  back() {
    this.retour.emit();
  }

  submitForm() {
    if (this.passwordForm.valid) {
      const type = this.loginEstMail ? 'EMAIL_PRO' : 'CELL_PHONE_PRO';
      this.submitted.emit({ form: this.passwordForm.getRawValue(), type });
    }
  }

  goToSecondStep() {
    this.tealiumService.link('apz.mobile.registrationProcess.validateChooseLoginClick');
    this.isSubmitted = true;
    if (!this.passwordForm.get('loginSaisie').value && this.loginEstMail) {
      this.passwordForm.get('login').setValue(this.mailPro);
    }
    if (
      this.passwordForm.get('login').valid &&
      ((this.loginEstMail && !this.passwordForm.get('loginSaisie').value) ||
        (this.loginEstMail && this.mailValid()) ||
        (!this.loginEstMail && this.mobileValid()))
    ) {
      this.isSubmitted = false;
      this.isLoading = true;
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.siloApiService
          .infosUser(undefined, undefined, this.passwordForm.get('login').value, null, authToken)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe((retour) => {
            this.loginExistant = !this.isEmpty(retour);
            this.isSecondStep = !this.loginExistant;
            if (this.isSecondStep) {
              this.tealiumService.view('apz.mobile.registrationProcess.choosePasswordPageLoad');
            }
          });
      });
    }
  }

  goToThirdStep() {
    this.isSubmitted = true;
    this.tealiumService.link('apz.mobile.registrationProcess.validateChoosePasswordClick');
    if (this.passwordForm.get('password').valid && this.passwordEqual()) {
      this.isSubmitted = false;
      this.isThirdStep = true;
      this.isSecondStep = false;
    }
  }

  goToFourthStep() {
    this.isSubmitted = true;
    if (this.passwordForm.get('secretQuestion').valid && this.passwordForm.get('response').valid) {
      this.isSubmitted = false;
      this.isThirdStep = false;
      this.isFourthStep = true;
      this.tealiumService.view('apz.mobile.registrationProcess.acceptCGUPageLoad');
    }
  }

  isEmpty(retour: UserResponse): boolean {
    return retour.items.length === 0;
  }

  inputClick() {
    this.passwordForm.get('loginSaisie').setValue(true);
  }

  setLoginEmail(val) {
    this.passwordForm.get('login').setValue(null);
    this.loginEstMail = val;
  }

  mailValid(): boolean {
    return mailRegex.test(this.passwordForm.get('login').value);
  }
  mobileValid(): boolean {
    return cellPhoneRegex.test(this.passwordForm.get('login').value);
  }
}
