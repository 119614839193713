import { Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthToken } from '@shared/models/authtoken.model';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { Router } from '@angular/router';
import { isAdultValidator } from '@shared/validators/custom.validators';
import { nameRegEx } from '@shared/helpers/regexp.helpers';
import { CreateTermsAndConditions } from '@shared/models/createTermsAndConditions.model';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { ModalService } from '@shared/components/modal/modal.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-second-step-bimpli-comiteo',
  templateUrl: './second-step-bimpli-comiteo.component.html',
  styleUrls: ['./second-step-bimpli-comiteo.component.scss']
})
export class SecondStepBimpliComiteoComponent implements OnInit {
  @Input() saml: string;
  @Input() application: string;
  messageErreur: string;
  token: AuthToken;
  userId: number;
  valueCaptcha: string;

  constructor( private readonly formBuilder: FormBuilder,
               private readonly gatewayService: GatewayService,
               private readonly siloApiService: SiloApiService,
               private readonly router: Router,
               private readonly tealiumService: TealiumService,
               private readonly modalService: ModalService,
               private readonly reCaptchaV3Service: ReCaptchaV3Service) {
  }

  individualInfosForm = this.formBuilder.group({
    title: ['MR', []],
    firstName: ['',
      [
        Validators.required,
        Validators.maxLength(30)
      ]],
    lastName: ['',
      [
        Validators.required,
        Validators.maxLength(30)
      ]],
    cellPhoneNumber: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0][6-7][0-9]{8}$/)]],
    equipmentCOMITEO: this.formBuilder.group({
      codeClient: ['', [Validators.required]],
      login: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })
  });

  ngOnInit(): void {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.application) {
      this.tealiumService.view('bimpli_benef.web.register.comiteo.personnalIdPageLoad');
    } else  if (ApplicationCodeEnum.BENEFIT_MOBILE === this.application) {
      this.tealiumService.view('bimpli_mobile.web.register.comiteo.personnalIdPageLoad');
    }
  }

  numericPhoneOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  formatName(event): boolean {
    const patt = nameRegEx;
    return patt.test(event.key);
  }

  back(): void {
    window.history.back();
  }

  createAccount(): void {
    const emailLocal = localStorage.getItem('login');
    const passwordLocal = localStorage.getItem('password');
    const firstStep = {login: emailLocal, password: passwordLocal, email: emailLocal, providerId: 'COMITEO',
      applicationId: this.application};
    const body = Object.assign(this.individualInfosForm.getRawValue(), firstStep);

    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.token = authToken;

      this.reCaptchaV3Service
      .execute('CreationAccountAction')
      .pipe(finalize(() => this.createUserAccount(body)))
      .subscribe((token) => (this.valueCaptcha = token));

    });
  }

  createUserAccount(body: any): void {
    this.siloApiService.createExternalUser(this.token, body, this.valueCaptcha).subscribe(
      (userId) =>  this.createTermsAndConditionsAcceptances(userId),
      (error) => {
        this.gestionErreur(error);
      }
    );
  }

  private createTermsAndConditionsAcceptances(userId) {
    this.userId = userId.id;
    localStorage.setItem('id', this.userId.toString());

    const profileTypeLocal = 'EMPLOYEE';

    const body: CreateTermsAndConditions = {
      profileType: profileTypeLocal,
      applicationId: this.application
    };
    this.siloApiService
      .createTermsAndConditionsAcceptances(userId.id, body,  this.token)
      .subscribe(
        () => {
          // Go to step 3
          this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}/comiteo`]);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }

  private gestionErreur(error: any) {
    if (error.error.errors) {
      console.log(error.error.errors[0].additionalInformation.message);
      if (error.error.errors[0].additionalInformation && error.error.errors[0].additionalInformation.message.includes('BAD_CREDENTIALS')) {
        this.messageErreur = 'Merci de renseigner les données d\'un compte comitéo valide';
      } else {
        this.messageErreur = error.error.errors[0].message;
      }
    } else {
      this.messageErreur = 'Données incorrectes';
    }
    this.modalService.open('errorModal');
  }

  openPopUp(id: string) {
    this.modalService.open(id);
  }
}
