import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GatewayService } from '@services/gateway.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { GroundTruthDataService } from '../../../shared/services/ground-truth-data.service';
import { SiloApiService } from '../../../shared/services/siloApi.service';

// RFC 5322
/* tslint:disable-next-line */
const mailRegex: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

@Component({
  selector: 'app-affilie-apz-change-id',
  templateUrl: './affilie-apz-change-id.component.html',
  styleUrls: ['./affilie-apz-change-id.component.scss']
})
export class AffilieApzChangeIdComponent implements OnInit, OnChanges {
  @Input() login: string;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Input() errorMessage: string;

  loginForm = this.fb.group({
    currentPassword: ['', [Validators.nullValidator, Validators.required]],
    newLogin: ['', [Validators.nullValidator, Validators.required, Validators.pattern(mailRegex)]]
  });
  isFirstStep = true;
  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading = false;
  mySubscription: Subscription = new Subscription();
  currentPasswordInvalid = false;
  private valueCaptcha: string;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private gtds: GroundTruthDataService,
    private gatewayService: GatewayService,
    private siloApiService: SiloApiService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });
    this.mySubscription.add(isSpinnerActivated);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.errorMessage && changes.errorMessage.currentValue) {
      this.gtds.setIsSpinnerActivated(false);
    }
  }

  goToSecondStep() {
    if (this.loginForm.get('currentPassword').valid) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.reCaptchaV3Service
          .execute('UserPasswordCheckAction')
          .pipe(
            finalize(() => {
              this.siloApiService
                .userPasswordCheck(
                  this.login,
                  this.loginForm.get('currentPassword').value,
                  ApplicationCodeEnum.AFFILIE_APZ,
                  authToken,
                  this.valueCaptcha
                )
                .subscribe((retour) => {
                  this.currentPasswordInvalid = !retour.resultCheck;
                  this.isFirstStep = this.currentPasswordInvalid;
                });
            })
          )
          .subscribe((token) => (this.valueCaptcha = token));
      });
    }
  }

  submitForm(): void {
    if (this.loginForm.valid) {
      this.submitted.emit(this.loginForm.getRawValue());
      this.gtds.setIsSpinnerActivated(true);
    }
  }

  goBack(): void {
    this.location.back();
  }
}
