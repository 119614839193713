import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CesuService } from '@services/cesu/cesu.service';
import { LoginService } from '@services/login/login.service';
import { SessionQuery } from '@services/store/session/session.query';
import { TealiumService } from '@services/tealium/tealium.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-cesu-forgotten-pwd-mail',
  templateUrl: './cesu-forgotten-pwd-mail.component.html',
  styleUrls: ['./cesu-forgotten-pwd-mail.component.scss', '../../../../../assets/scss/cesu/cesu-global.scss']
})
export class CesuForgottenPwdMailComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationId: ApplicationCodeEnum;
  public saml: string;
  public forgottenPasswordLogin: string;

  public success = false;
  public errorMessage: string;

  private valueCaptcha: string;

  constructor(
    private readonly router: Router,
    private readonly tealiumService: TealiumService,
    private readonly loginService: LoginService,
    private readonly cesuService: CesuService,
    private readonly sessionQuery: SessionQuery,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  public ngOnInit(): void {
    const applicationCodeSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(tap((applicationCode: ApplicationCodeEnum) => (this.applicationId = applicationCode)))
      .subscribe();

    this.subscription.add(applicationCodeSubscription);

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml: string) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);

    const forgottenPasswordLoginSubscription = this.sessionQuery.selectedForgottenPasswordLogin
      .pipe(tap((forgottenPasswordLogin: string) => (this.forgottenPasswordLogin = forgottenPasswordLogin)))
      .subscribe();

    this.subscription.add(forgottenPasswordLoginSubscription);

    this.tealiumService.view('cesu.forgottenPassword.confirmationEmailSentPageLoad');
  }

  retrySendForgottenPassword() {
    this.tealiumService.view('cesu.forgottenPassword.confirmationEmailSentResendEmailClick');
    this.reCaptchaV3Service
    .execute('ConnecterAction').subscribe((token) => {
      this.loginService
      .resetPassword(this.applicationId, this.forgottenPasswordLogin, token)
      .pipe(
        tap(() => {
          this.success = true;
        }),
        catchError((err) => {
          this.success = false;
          this.errorMessage = err.message;
          throw err;
        })
      )
      .subscribe();
    });

   /*  this.loginService
      .resetPassword(this.applicationId, this.forgottenPasswordLogin)
      .pipe(
        tap(() => {
          this.success = true;
        }),
        catchError((err) => {
          this.success = false;
          this.errorMessage = err.message;
          throw err;
        })
      )
      .subscribe(); */
  }

  public goToLogin(): void {
    this.tealiumService.view('cesu.forgottenPassword.confirmationEmailSentReturnToLoginClick');
    this.cesuService.goToLogin();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
