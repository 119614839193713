import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { Observable, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TealiumService } from '../../shared/services/tealium/tealium.service';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { ModalService } from '@shared/components/modal/modal.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-demande-identifiant',
  templateUrl: './demande-identifiant.component.html',
  styleUrls: ['./demande-identifiant.component.scss']
})
export class DemandeIdentifiantComponent implements OnInit {
  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public applicationId: ApplicationCodeEnum;
  public saml: string;

  public mail: FormControl;
  public ginsc: FormGroup;
  public messageErreur: string;
  public notificationEnvoyer: boolean;
  public estMobile: boolean;
  public estApetiz: boolean;
  public estBimpli: boolean;
  public estApetizMobile: boolean;
  public isLoading: boolean;
  public ApplicationCodeEnum = ApplicationCodeEnum;

  private valueCaptcha: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly translateService: TranslateService,
    private readonly gtds: GroundTruthDataService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly tealiumService: TealiumService,
    private readonly modalService: ModalService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    this.sessionStateService.setApplicationCode(this.route);

    this.mail = this.formBuilder.control('', Validators.required);
    this.ginsc = this.formBuilder.group([this.mail]);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    this.applicationCode$.subscribe((code) => {
      switch (code) {
        case ApplicationCodeEnum.APZ:
          this.estApetiz = true;
          this.tealiumService.view('apz.web.forgottenLogin.pageEmailLoad');
          break;
        case ApplicationCodeEnum.APZ_MOBILE:
          this.estApetizMobile = true;
          this.tealiumService.view('apz.mobile.forgottenLogin.pageEmailLoad');
          break;
        case ApplicationCodeEnum.BENEFIT_MOBILE:
          this.tealiumService.view('bimpli_mobile.web.forgottenLogin.pageLoad');
          this.estBimpli = true;
          break;
        case ApplicationCodeEnum.BENEFIT_BENEF:
          this.tealiumService.view('bimpli_benef.web.forgottenLogin.pageLoad');
          this.estBimpli = true;
          break;
        case ApplicationCodeEnum.BENEFIT_FINANCEUR:
          this.tealiumService.view('bimpli_financeur.web.forgottenLogin.pageLoad');
          this.estBimpli = true;
          break;
        case ApplicationCodeEnum.BENEFIT_CAGNOTTE:
          this.estBimpli = true;
          break;
      }
    });

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml: string) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);

    const applicationIdSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(
        tap((applicationId: ApplicationCodeEnum) => {
          switch(applicationId) {
            case ApplicationCodeEnum.APZ_MOBILE:
              this.applicationId = ApplicationCodeEnum.APZ;
              this.estMobile = true;
              break;
            default:
              this.applicationId = applicationId;
              this.estMobile = false;
              break;
          }
        })
      )
      .subscribe();

    this.subscription.add(applicationIdSubscription);
  }

  public validerInscription(f: NgForm): void {
    if (this.ginsc.valid) {
      this.demandeIdentifiantOublie(this.mail.value);
    }
  }

  public demandeIdentifiantOublie(email: string) {
    this.messageErreur = '';
    this.isLoading = true;
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.forgottenLogin.validateEmailClick');
    } else if (this.estApetizMobile) {
      this.tealiumService.link('apz.mobile.forgottenLogin.validateEmailClick');
    }
    this.notificationEnvoyer = false;
    this.reCaptchaV3Service
      .execute('ConnecterAction').subscribe((token) => {
        this.gatewayService.getAccessToken().subscribe((authToken) =>
        this.siloApiService
          .demandeIdentifiantOublie(email, this.applicationId, authToken, token)
          .pipe(finalize(() => {this.gtds.setIsSpinnerActivated(false); this.isLoading = false;}))
          .subscribe(
            (retour) => {
              this.notificationEnvoyer = true;
              if (this.estApetiz) {
                this.tealiumService.view('apz.web.forgottenLogin.confirmationEmailSentPageLoad');
              } else if (this.estApetizMobile) {
                this.tealiumService.view('apz.mobile.forgottenLogin.confirmationEmailSentPageLoad');
              }
              if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
                this.tealiumService.view('bimpli_benef.web.forgottenLogin.confirmationEmailSentPageLoad');
              } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
                this.tealiumService.view('bimpli_financeur.web.forgottenLogin.confirmationEmailSentPageLoad');
              } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
                this.tealiumService.view('bimpli_mobile.web.forgottenLogin.confirmationEmailSentPageLoad');
              }
            },
            (error) => this.gererErreurs(error)
          )
        );
      });


  }

  public renvoyerDemande(mail: string) {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.forgottenLogin.sendEmailClick');
    } else if (this.estApetizMobile) {
      this.tealiumService.link('apz.mobile.forgottenLogin.sendEmailClick');
    }
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.forgottenLogin.sendEmailClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.forgottenLogin.sendEmailClick');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.forgottenLogin.sendEmailClick');
    }
    this.reCaptchaV3Service
    .execute('ConnecterAction').subscribe((token) => {
      this.gatewayService.getAccessToken().subscribe((authToken) =>
      this.siloApiService
        .demandeIdentifiantOublie(mail, this.applicationId, authToken, token)
        .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
        .subscribe(
          (retour) => (this.notificationEnvoyer = true),
          (error) => this.gererErreurs(error)
        )
    );
    });

  }

  public gererErreurs(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = error.error.startsWith('<') ? undefined : JSON.parse(error.error);

      if (errors && errors.errors !== undefined) {
        switch (errors.errors[0].code) {
          case CodeErreurs.UNEXPECTED_011:
            this.messageErreur = this.translateService.instant('ASP.FORGOTTEN_ID.ERROR.UNEXPECTED_011');
            break;
          case CodeErreurs.NOT_FOUND_028:
            this.messageErreur = this.translateService.instant('ASP.FORGOTTEN_ID.ERROR.NOT_FOUND_028');
            break;
          case CodeErreurs.FORBIDDEN_ACTION_087:
            this.messageErreur = this.translateService.instant('ASP.FORBIDDEN_ACTION_087');
            break;
          default:
            this.messageErreur = this.translateService.instant('ASP.FORGOTTEN_ID.ERROR.UNEXPECTED') + errors.errors[0].code;
            break;
        }
      } else {
        this.messageErreur = 'Erreur ' + error.status + ' : ' + error.statusText;
      }
    }

    if (this.estBimpli) {
      this.modalService.open('errorModal');
    }

    if (this.estApetizMobile) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }

  goBack() {
    window.history.back();
  }

  goLogin() {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.forgottenLogin.returnToLoginClick');
    } else if (this.estApetizMobile) {
      this.tealiumService.link('apz.mobile.forgottenLogin.returnToLoginClick');
    }
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.forgottenLogin.backToConnexionClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.forgottenLogin.backToConnexionClick');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.forgottenLogin.backToConnexionClick');
    }

    // Cas mobile : l'appli intercepte cette url et redirige sur la bonne page
    if (this.estMobile) {
      window.location.href = 'app.beneficiaire.apetiz://accueil';
    } else {
      this.router.navigateByUrl(`${RoutesEnum.LOGIN}/${this.saml}`);
    }
  }

  public setPageHtml(html: string): void {
    document.body.innerHTML = html;
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.submit();
  }
}
