import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Affiliate } from '@shared/models/affiliate';

@Component({
  selector: 'app-affiliate-confirm',
  templateUrl: './affiliate-confirm.component.html',
  styleUrls: ['./affiliate-confirm.component.scss']
})
export class AffiliateConfirmComponent {
  @Input() affiliate: Affiliate;
  @Output() selectedAffiliate: EventEmitter<Affiliate[]> = new EventEmitter<Affiliate[]>();

  get companyName(): string {
    return this.affiliate?.company?.companyName;
  }

  get address(): string {
    return `${this.affiliate?.address?.street} ${this.affiliate?.address.postalCode} ${this.affiliate?.address.city}`;
  }

  get name(): string {
    return this.affiliate?.name;
  }

  get title(): string {
    if (this.affiliate.affiliateType?.isRefundCenter) {
      return this.translateService.instant('AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_FOUND.REFUND_CENTER.TITLE');
    }
    return this.translateService.instant('AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_FOUND.ETBLISSEMENT.TITLE');
  }

  get subtitle(): string {
    if (this.affiliate.affiliateType?.isRefundCenter) {
      return this.translateService.instant('AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_FOUND.REFUND_CENTER.DESCRIPTION');
    }
    return this.translateService.instant('AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FIRST_STEP.AFFILIATE_FOUND.ETBLISSEMENT.DESCRIPTION');
  }

  constructor(private readonly translateService: TranslateService) {}

  public confirm(): void {
    this.selectedAffiliate.emit([this.affiliate]);
  }
}
