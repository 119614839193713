import { Component, OnInit } from '@angular/core';
import { TealiumService } from '@services/tealium/tealium.service';

@Component({
  selector: 'app-cesu-notice',
  templateUrl: './cesu-notice.component.html',
  styleUrls: ['./cesu-notice.component.scss', '../../../../assets/scss/cesu/cesu-global.scss']
})
export class CesuNoticeComponent implements OnInit {
  constructor(private readonly tealiumService: TealiumService) {}

  ngOnInit(): void {
    this.tealiumService.view('cesu.footer.legalMentionsPageLoad');
  }
}
