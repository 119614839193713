<div id="collection-infos-container">
    <div class="p-16 mt-4">
        <div class="header">
            <app-go-back-btn (goBack$)="goBack()"></app-go-back-btn>
        </div>
        <div class="content">
            <div class="title text-left mb-4">
                {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.WHERE_TO_FIND.COLLECTION.TITLE"
                      | translate
                  }}
            </div>

            <div class="content-text text-left mb-4">
                {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.WHERE_TO_FIND.COLLECTION.TEXT"
                      | translate
                  }}
            </div>

            <div class="bold-text text-left mb-4">
                {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.WHERE_TO_FIND.COLLECTION.BOLD_TEXT"
                      | translate
                  }}
            </div>

            <div class="w-100 mb-4">
                <img id="facture-img" src="../../../../../assets/bimpli/remise.png" alt="Exemple de facture" />
            </div>
        </div>
        <app-full-width-btn
            [text]="'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.WHERE_TO_FIND.COLLECTION.BUTTON'"
            (click$)="goBack()"
            
            ></app-full-width-btn>
    </div>
</div>