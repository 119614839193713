import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardMask'
})
export class CreditCardMaskPipe implements PipeTransform {

  transform(cardNumber: string, ...args: unknown[]): string {
    return cardNumber ?
      cardNumber.toString().replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim()
      : '';
  }

}
