<div id="confirm-pwd-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row align-items-center justify-content-center h-100 w-100">
    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 card shadow p-4">
      <form class="w-100" role="form" [formGroup]="passwordForm">
        <!-- LIEN INVALIDE -->
        <div *ngIf="lienInvalide && !resendOk" class="d-flex flex-column align-items-start justify-content-around w-100">
          <div class="d-flex flex-column align-items-start justify-content-center mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
            <span id="confirm-pwd-title" class="d-flex flex-row align-items-start justify-content-center">{{
              'AFFILIE_APZ.CONFIRM_PWD.LINK_INVALID' | translate
            }}</span>
            <span class="d-flex flex-row align-items-start justify-content-center confirm-pwd-sub-title mb-2">{{
              'AFFILIE_APZ.CONFIRM_PWD.LINK_INVALID_PART_1' | translate
            }}</span>
            <span class="d-flex flex-row align-items-start justify-content-center confirm-pwd-sub-title">{{
              'AFFILIE_APZ.CONFIRM_PWD.LINK_INVALID_PART_2' | translate
            }}</span>
          </div>
          <div class="d-flex flex-column align-items-start justify-content-center">
            <!-- VALIDATION -->
            <button type="button" class="btn btn-primary" (click)="recall()">
              <div class="d-flex flex-row align-items-center justify-content-center">
                <span id="submit-btn-label">{{ 'AFFILIE_APZ.FORGOTTEN_PASSWORD.TITLE_BUTTON' | translate }}</span>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="lienInvalide && resendOk" class="d-flex flex-column align-items-start justify-content-around w-100">
          <div class="d-flex flex-column align-items-start justify-content-center">
            <span id="confirm-pwd-title" class="d-flex flex-row align-items-start justify-content-center">{{
              'AFFILIE_APZ.CONFIRM_PWD.SEND_NEW_PWD' | translate
            }}</span>
            <span class="d-flex flex-row align-items-start justify-content-center confirm-pwd-sub-title mb-2">{{
              'AFFILIE_APZ.FORGOTTEN_PASSWORD.SEND_MAIL' | translate
            }}</span>
            <span class="d-flex flex-row align-items-start justify-content-center confirm-pwd-sub-title">{{
              'AFFILIE_APZ.FORGOTTEN_PASSWORD.SEND_MAIL_CHECK' | translate
            }}</span>
          </div>
        </div>
        <!-- LIEN VALIDE  -->
        <div *ngIf="!lienInvalide && success" class="d-flex flex-column align-items-start justify-content-around w-100">
          <div class="d-flex flex-column align-items-start justify-content-center mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
            <span id="confirm-pwd-title" class="d-flex flex-row align-items-start justify-content-center">{{
              'AFFILIE_APZ.CONFIRM_PWD.PWD_CHANGED' | translate
            }}</span>
            <span class="d-flex flex-row align-items-start justify-content-center confirm-pwd-sub-title">{{
              'AFFILIE_APZ.CONFIRM_PWD.PWD_CHANGED_DESCRIPTION' | translate
            }}</span>
          </div>
          <div class="d-flex flex-column align-items-start justify-content-center">
            <!-- VALIDATION -->
            <button type="button" class="btn btn-primary" (click)="connect()">
              <div class="d-flex flex-row align-items-center justify-content-center">
                <span id="submit-btn-label">{{ 'COMMON.USER.CONNECT' | translate }}</span>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="!lienInvalide && !success" class="d-flex flex-column align-items-start justify-content-around h-100 w-100">
          <!-- ERROR MESSAGE -->
          <div *ngIf="errorMessage" class="d-flex flex-row align-items-start justify-content-center w-100 alert alert-danger error-msg">
            <i class="icon-info"></i>
            <span class="ml-2">{{ errorMessage }}</span>
          </div>
          <!-- HELLO -->
          <div class="d-flex flex-column align-items-start justify-content-center mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
            <span id="confirm-pwd-title" class="d-flex flex-row align-items-start justify-content-center">{{
              'AFFILIE_APZ.CONFIRM_PWD.HELLO' | translate
            }}</span>
            <span class="d-flex flex-row align-items-start justify-content-center confirm-pwd-sub-title">{{
              'AFFILIE_APZ.CONFIRM_PWD.HELLO_DESCRIPTION' | translate
            }}</span>
          </div>
          <div
            class="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column align-items-start justify-content-between mb-3 w-100"
          >
            <!-- PASSWORD -->
            <div class="d-flex flex-column input-form">
              <label class="form-label" for="password">{{
                'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.SECOND_STEP.PASSWORD' | translate
              }}</label>
              <div class="input-group">
                <input
                  [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'"
                  class="form-control"
                  id="password"
                  name="password"
                  formControlName="password"
                  placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.SECOND_STEP.PASSWORD_PROMPT' | translate }}"
                  (keyup)="controlPassWord()"
                  [ngClass]="{
                    'is-invalid': passwordForm.get('password').invalid === true && passwordForm.get('password').touched === true,
                    'is-valid': passwordForm.get('password').invalid === false && passwordForm.get('password').touched === true
                  }"
                />
                <div class="input-group-append">
                  <button
                    *ngIf="(isPasswordDisplayed$ | async) === false"
                    type="button"
                    class="btn btn-light input-group-text"
                    (click)="isPasswordDisplayed$.next(true)"
                  >
                    <i class="bi bi bi-eye-fill"></i>
                  </button>
                  <button
                    *ngIf="(isPasswordDisplayed$ | async) === true"
                    type="button"
                    class="btn btn-light input-group-text"
                    (click)="isPasswordDisplayed$.next(false)"
                  >
                    <i class="bi bi-eye-slash-fill"></i>
                  </button>
                </div>
              </div>
              <div *ngIf="passwordForm.get('password').touched && passwordForm.get('password').invalid">
                <small class="error-msg text-danger" *ngIf="passwordForm.get('password').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <!-- CONFIRM PASSWORD -->
            <div
              class="d-flex flex-column input-form ml-xxl-2 ml-xl-2 ml-lg-2 ml-md-0 ml-sm-0 ml-0 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3"
            >
              <label class="form-label" for="confirmPassword">{{
                'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.SECOND_STEP.CONFIRM_PASSWORD' | translate
              }}</label>
              <div class="input-group">
                <input
                  [type]="(isConfirmPasswordDisplayed$ | async) === false ? 'password' : 'text'"
                  class="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  formControlName="confirmPassword"
                  placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.SECOND_STEP.CONFIRM_PASSWORD_PROMPT' | translate }}"
                  (keyup)="controlPassWord()"
                  [ngClass]="{
                    'is-invalid':
                      (passwordForm.get('confirmPassword').invalid === true || !passwordEqual) &&
                      passwordForm.get('confirmPassword').touched === true,
                    'is-valid':
                      passwordForm.get('confirmPassword').invalid === false && passwordForm.get('confirmPassword').touched === true
                  }"
                  required
                />
                <div class="input-group-append">
                  <button
                    *ngIf="(isConfirmPasswordDisplayed$ | async) === false"
                    type="button"
                    class="btn btn-light input-group-text"
                    (click)="isConfirmPasswordDisplayed$.next(true)"
                  >
                    <i class="bi bi bi-eye-fill"></i>
                  </button>
                  <button
                    *ngIf="(isConfirmPasswordDisplayed$ | async) === true"
                    type="button"
                    class="btn btn-light input-group-text"
                    (click)="isConfirmPasswordDisplayed$.next(false)"
                  >
                    <i class="bi bi-eye-slash-fill"></i>
                  </button>
                </div>
              </div>
              <div class="d-flex flex-column" *ngIf="passwordForm.get('confirmPassword').touched">
                <small
                  class="error-msg text-danger"
                  *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required"
                >
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="error-msg text-danger" *ngIf="!passwordEqual">
                  {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column align-items-start justify-content-between w-100"
          >
            <!-- VALIDATION PASSWORD -->
            <div class="d-flex flex-column align-items-start justify-content-start input-form">
              <span class="d-flex flex-row form-label">{{ 'CREATION_ACCOUNT.SECOND_STEP.VALIDATION' | translate }}</span>
              <app-password-validation
                [passwordValue]="passwordForm.get('password').value"
                [affLastPassword]="false"
                [progressBar]="true"
                [submitted]="submittedForm"
              >
              </app-password-validation>
            </div>
            <div
              class="d-flex flex-column align-items-center justify-content-center input-form ml-xxl-2 ml-xl-2 ml-lg-2 ml-md-0 ml-sm-0 ml-0  mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3"
            >
              <!-- VALIDATION -->
              <button
                type="button"
                class="btn btn-primary btn-block"
                [disabled]="!passwordForm.valid || !passwordEqual || isLoading"
                (click)="submit()"
              >
                <div class="d-flex flex-row align-items-center justify-content-center">
                  <div *ngIf="isLoading" class="spinner-border"></div>
                  <span
                    [ngClass]="{
                      'ml-2': isLoading
                    }"
                    id="submit-btn-label"
                    >{{ 'AFFILIE_APZ.CONFIRM_PWD.TITLE_BUTTON' | translate }}</span
                  >
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
