<div *ngIf="isAlreadyExist" class="background-bimpli d-flex flex-column align-items-center justify-content-center vw-100 vh-100">
  <button (click)="redirectToLogin()" class="position-fixed d-flex align-items-center justify-content-center btn btn-secondary btn-block">
    Passer
  </button>
  <div class="card col-4 d-flex flex-column align-items-center justify-content-center">
    <span class="title-bimpli">Vous possédez déjà un compte Bimpli</span>
    <span class="sub-title mt-4"
      >Vous allez être redirigé à la page de connexion Bimpli, où vous pourrez vous connectez avec vos identifiants Bimpli habituels.</span
    >
    <img class="ico-timer mt-2" src="/assets/bimpli/ico-temps-ecoul.svg" />
    <span class="redirect mt-2">Redirection dans {{ timer }} secondes</span>
  </div>
</div>
<div *ngIf="!isAlreadyExist" class="container-fluid vw-100  p-0 m-0 row">
  <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
    <div class="d-flex flex-column">
      <span class="title-bp">Youpi !</span>
      <span class="sub-title-bp">Vous avez sélectionné <br />Cado !</span>
    </div>
  </div>
  <div class="d-block col-xs-12 col-sm-8 offset-sm-4">
    <div class="bloc-center d-flex flex-column">
      <div class="bloc-action-back d-flex align-items-center" (click)="back()">
        <i class="bi bi-arrow-left"></i>
        <span class="quit ml-2">Retour</span>
      </div>

      <div class="d-flex flex-column" *ngIf="!isFirstStep">
        <span class="create">Renseignez les informations suivantes</span>
        <span class="create-subtitle mt-4 mb-4"
          >Renseignez la dénomination de votre entreprise,le code postal, le numéro de SIRET et l'adresse postale.</span
        >
        <form [formGroup]="enterpriseInfoForm">
          <re-captcha [siteKey]="googleKey" size="invisible" #captchaRef="reCaptcha" (resolved)="validateEnterprise($event)"></re-captcha>

          <div class="inner_page">
            <div class="input_group" *ngIf="isCreation">
              <label for="denomination">Dénomination</label>
              <input
                type="text"
                class="form-control"
                name="denomination"
                placeholder="Saisir votre dénomination"
                id="denomination"
                formControlName="denomination"
                maxlength="70"
              />
            </div>
            <div class="input_group" *ngIf="isCreation">
              <label for="denomination">Code postal</label>
              <input
                type="text"
                class="form-control"
                name="codepostal"
                placeholder="Saisir votre code postal"
                id="codepostal"
                formControlName="codepostal"
                maxlength="5"
                (focusout)="readCitiesByZipCode()"
                (keypress)="numericPhoneOnly($event)"
              />
              <div *ngIf="enterpriseInfoForm.get('codepostal').dirty && enterpriseInfoForm.get('codepostal').errors">
                <small class="form-text text-danger" *ngIf="enterpriseInfoForm.get('codepostal').errors?.pattern">
                  Code postal incorrect
                </small>
              </div>
            </div>
            <div class="input_group">
              <label for="denomination">Numéro de SIRET</label>
              <input
                type="text"
                class="form-control"
                name="siret"
                placeholder="Saisir votre numéro de siret"
                id="siret"
                formControlName="siret"
                maxlength="14"
                (keypress)="numericPhoneOnly($event)"
              />
              <div *ngIf="enterpriseInfoForm.get('siret').dirty && enterpriseInfoForm.get('siret').errors">
                <small class="form-text text-danger" *ngIf="enterpriseInfoForm.get('siret').errors?.pattern">
                  Numérot de siret incorrect
                </small>
              </div>
            </div>
            <div class="input_group" *ngIf="isCreation">
              <label for="denomination">Adresse</label>
              <input
                type="text"
                class="form-control"
                name="adresse"
                placeholder="Saisir votre adresse"
                id="adresse"
                formControlName="adresse"
                maxlength="50"
              />
            </div>
            <div class="input_group" *ngIf="isCreation">
              <label for="denomination">Ville</label>

              <input
                id="ville"
                type="text"
                class="form-control"
                name="ville"
                placeholder="Ex: Paris"
                formControlName="ville"
                list="selectCities"
                maxlength="30"
                onkeyup="this.value = this.value.toUpperCase();"
              />

              <datalist *ngIf="cities && cities.size > 1" id="selectCities">
                <option *ngFor="let city of cities">
                  {{ city }}
                </option>
              </datalist>
            </div>
          </div>
          <button
            type="button"
            class="mb-4 btn btn-primary btn-block"
            [disabled]="!enterpriseInfoForm.valid"
            (click)="submitEnterpriseForm()"
          >
            <div class="d-flex flex-row align-items-center justify-content-center">
              <div *ngIf="isLoading" class="spinner-border"></div>
              <span [ngClass]="{ 'ml-2': isLoading }">Valider</span>
            </div>
          </button>
        </form>
      </div>

      <div *ngIf="isFirstStep" class="d-flex flex-column">
        <span class="create">Adresse email</span>
        <span class="create-subtitle mt-4 mb-4">Renseignez votre adresse email professionnelle.</span>
        <form [formGroup]="emailForm">
          <div class="inner_page">
            <div class="input_group">
              <label for="email">Adresse email *</label>
              <input
                type="text"
                class="form-control"
                name="email"
                placeholder="Saisir votre email"
                id="email"
                formControlName="email"
                maxlength="50"
              />
              <div *ngIf="emailForm.get('email').dirty && emailForm.get('email').errors">
                <small class="form-text text-danger" *ngIf="emailForm.get('email').errors?.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="form-text text-danger" *ngIf="emailForm.get('email').errors?.pattern">
                  Emal incorrect
                </small>
              </div>
            </div>
          </div>
          <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!emailForm.valid" (click)="validateFirstStep()">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <div *ngIf="isLoading" class="spinner-border"></div>
              <span [ngClass]="{ 'ml-2': isLoading }">Continuer</span>
            </div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<app-modal id="errorModal">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <span class="m-4 text-center title-pop-up">Email inconnu</span>
    <div class="col-12 detail text-center" html>{{ messageErreur }}</div>
    <div class="mt-4 mb-4 col-6">
      <button type="button" class="btn btn-primary btn-block" data-dismiss="modal" (click)="closePopin('errorModal')">J'ai compris</button>
    </div>
  </div>
</app-modal>

<app-modal id="intentModal">
  <div class="d-flex flex-column justify-content-center align-items-center p-4 intentModal">
    <div>
      <button type="button" class="btn btn-primary btn-block" (click)="goToSignupAffiliate()">
        Je souhaite créer mon espace commerçant
      </button>
    </div>
    <div class="mt-4">
      <button type="button" class="btn btn-primary btn-block" (click)="confirmIntent()">
        Je souhaite commander des titres pour mes salariés
      </button>
    </div>
  </div>
</app-modal>
