import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
    paginationN = 8;
    @Input() totalPages!: number;
    @Output() currentPageEmitter = new EventEmitter<number>();

    public updatePageNumber(num: number): void {
        this.currentPageEmitter.emit(num);
    }
}
