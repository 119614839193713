import { Component, EventEmitter, Output } from '@angular/core';
import { MerchantSignupRightPanel } from '@shared/enums/merchants-signup-right-panel.enum';
import { MerchantsSignupService } from '../../merchants-signup.service';

@Component({
  selector: 'app-unlock-by-collection-infos',
  templateUrl: './unlock-by-collection-infos.component.html',
  styleUrls: ['./unlock-by-collection-infos.component.scss']
})
export class UnlockByCollectionInfosComponent {

  @Output() goToPanel$ = new EventEmitter<string>();

  constructor(
    private readonly merchantsSignupService: MerchantsSignupService,
  ) {}
  
  public goBack(): void {
    this.merchantsSignupService.activatedChild.next('collection');
    this.goToPanel$.emit(MerchantSignupRightPanel.CHECK_ACCESS);
 }
 
}
