<div class="container detail-container p-2">
  <app-snackbar></app-snackbar>
  <div class="pb-3 text-left" [hidden]="!isSecondStep">
    <p>
      {{ 'APZ.CHANGEMENT_MDP.INTRUCTION_MDP_PART2' | translate }}
    </p>
  </div>
  <div class="pb-3 text-left" [hidden]="isSecondStep">
    <p>
      {{ 'APZ.CHANGEMENT_MDP.INTRUCTION_MDP_PART1' | translate }}
    </p>
  </div>
  <!-- row pour la vue mobile -->
  <div>
    <form name="passwordForm" [formGroup]="passwordForm" class="row justify-content-center">
      <div class="col-12 col-sm text-left" [hidden]="isSecondStep">
        <div class="form-group row d-flex justify-content-center align-items-center">
          <div class="col-10">
            <input
              [type]="hide ? 'password' : 'text'"
              class="form-control lock"
              name="currentPassword"
              formControlName="currentPassword"
              placeholder="Mot de passe"
            />
          </div>
          <div class="img-fluid mr-1 col-1" [ngClass]="hide ? 'hide' : 'show'" (click)="hide = !hide"></div>
          <div *ngIf="passwordForm.get('currentPassword').touched && passwordForm.get('currentPassword').invalid">
            <small class="form-text text-danger" *ngIf="passwordForm.get('currentPassword').errors.required">
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
          </div>
          <small class="form-text text-danger" *ngIf="currentPasswordInvalid">
            {{ 'APZ.CHANGEMENT_MDP.MDP_INCORRECT' | translate }}
          </small>
        </div>
      </div>
      <div class="col-12 col-sm text-left" [hidden]="!isSecondStep">
        <div class="form-group row d-flex justify-content-center align-items-center">
          <div class="col-10">
            <input
              [type]="hide2 ? 'password' : 'text'"
              class="form-control lock"
              name="newPassword"
              formControlName="newPassword"
              placeholder="Nouveau mot de passe"
            />
          </div>
          <div class="img-fluid mr-1 col-1" [ngClass]="hide2 ? 'hide' : 'show'" (click)="hide2 = !hide2"></div>
          <div *ngIf="passwordForm.get('newPassword').touched">
            <small
              class="form-text text-danger"
              *ngIf="passwordForm.get('newPassword').errors && passwordForm.get('newPassword').errors.required"
            >
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
          </div>
        </div>
        <div class="form-group row d-flex justify-content-center align-items-center">
          <div class="col-10">
            <input
              [type]="hide3 ? 'password' : 'text'"
              class="form-control lock"
              name="confirmPassword"
              formControlName="confirmPassword"
              placeholder="Confirmation"
              (keyup)="controlPassWord()"
            />
          </div>
          <div class="img-fluid mr-1 col-1" [ngClass]="hide3 ? 'hide' : 'show'" (click)="hide3 = !hide3"></div>
          <div *ngIf="passwordForm.get('confirmPassword').touched">
            <small
              class="form-text text-danger"
              *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required"
            >
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
            <small class="form-text text-danger" *ngIf="!passwordEqual">
              {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
            </small>
          </div>
        </div>
        <div class="col-12 infos-utilisateur">
          <p class="pt-2">{{ 'ASP.CHANGE_PWD.VALIDATION' | translate }}</p>
          <app-password-validation
            [passwordValue]="passwordForm.get('newPassword').value"
            [currentPassword]="passwordForm.get('currentPassword').value"
            [affLastPassword]="true"
            [progressBar]="true"
          >
          </app-password-validation>
        </div>
      </div>
    </form>
    <div class="text-center pt-3">
      <button class="btn btn-action" (click)="goToSecondStep()" type="submit" *ngIf="!isSecondStep">
        {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
      </button>
      <button class="btn btn-action" (click)="submitForm()" type="submit" *ngIf="isSecondStep">
        {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
      </button>
    </div>
  </div>
</div>
