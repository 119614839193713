<div id="info-menu-container" class="d-flex flex-column align-items-start justify-content-center w-100 p-3">
  <span id="title" class="mb-2">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.WELCOME' | translate }}</span>
  <span class="sub-title">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.SUBTITLE-4' | translate }}</span>
  <span id="divider" class="mt-3 w-25"></span>
</div>
<div id="menu-title-container" class="d-flex flex-column align-items-center justify-content-start w-100 p-3">
  <div class="d-flex flex-row align-items-center justify-content-start w-100 mb-4" [ngClass]="{ 'step-selected': step == 1 }">
    <i class="bi bi-arrow-right menu-icon" *ngIf="step == 1" g></i>
    <i class="bi bi-check-circle-fill menu-icon" *ngIf="step > 1"></i>
    <span class="ml-2 menu-title">
      {{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.COORDONNEES' | translate }}
    </span>
  </div>
  <div class="d-flex flex-row align-items-center justify-content-start w-100 mb-4" [ngClass]="{ 'step-selected': step == 2 }">
    <i class="bi bi-arrow-right menu-icon" *ngIf="step == 2"></i>
    <i class="bi bi-check-circle-fill menu-icon" *ngIf="step > 2"></i>
    <span class="ml-2 menu-title">
      {{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.MAIL_PASSWORD' | translate }}
    </span>
  </div>
  <div class="d-flex flex-row align-items-center justify-content-start w-100 mb-4" [ngClass]="{ 'step-selected': step == 3 }">
    <i class="bi bi-arrow-right menu-icon" *ngIf="step == 3"></i>
    <i class="bi bi-check-circle-fill menu-icon" *ngIf="step > 3"></i>
    <span class="ml-2 menu-title">
      {{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.ACTIVATION' | translate }}
    </span>
  </div>
  <button id="go-btn" type="button" class="btn btn-primary btn-block mt-5 d-block d-sm-block d-md-none" (click)="hideStep()">
    <div class="d-flex flex-row align-items-center justify-content-center">
      <span id="go-btn-label">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.GO' | translate }}</span>
    </div>
  </button>
</div>
