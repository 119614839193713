import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { ReponseVerifierToken } from '@shared/models/reponse.verifierToken';
import { Observable, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { TealiumService } from '../../shared/services/tealium/tealium.service';
import { ModalService } from '@shared/components/modal/modal.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-demande-mot-de-passe',
  templateUrl: './demande-mot-de-passe.component.html',
  styleUrls: ['./demande-mot-de-passe.component.sass']
})
export class DemandeMotDePasseComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public applicationId: ApplicationCodeEnum;
  public saml: string;

  public identifiant: FormControl;
  public ginsc: FormGroup;
  public messageErreur: string;
  public notificationEnvoyer: boolean;
  public estApetiz: boolean;
  public estApetizMobile: boolean;
  public estBimpli: boolean;
  public ApplicationCodeEnum = ApplicationCodeEnum;
  public receiver: string;
  public isLoading: boolean;


  private valueCaptcha: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly gtds: GroundTruthDataService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly tealiumService: TealiumService,
    private readonly modalService: ModalService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  public ngOnInit(): void {
    this.sessionStateService.setApplicationCode(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    this.applicationCode$.subscribe((code) => {
      switch (code) {
        case ApplicationCodeEnum.APZ:
          this.estApetiz = true;
          this.tealiumService.view('apz.web.forgottenPassword.pageLoad');
          break;
        case ApplicationCodeEnum.APZ_MOBILE:
          this.estApetizMobile = true;
          this.tealiumService.view('apz.mobile.forgottenPassword.pageLoad');
          break;
        case ApplicationCodeEnum.BENEFIT_MOBILE:
          this.estApetizMobile = true;
          this.tealiumService.view('bimpli_mobile.web.forgottenPassword.pageLoad');
          this.estBimpli = true;
          break;
        case ApplicationCodeEnum.BENEFIT_BENEF:
          this.tealiumService.view('bimpli_benef.web.forgottenPassword.pageLoad');
          this.estBimpli = true;
          break;
        case ApplicationCodeEnum.BENEFIT_FINANCEUR:
          this.tealiumService.view('bimpli_financeur.web.forgottenPassword.pageLoad');
          this.estBimpli = true;
          break;
        case ApplicationCodeEnum.BENEFIT_CAGNOTTE:
          this.estBimpli = true;
          break;
      }
    });

    const applicationIdSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(
        tap((applicationId) => {
          switch (applicationId) {
            case ApplicationCodeEnum.APZ_MOBILE:
              this.applicationId = ApplicationCodeEnum.APZ;
              break;
            default:
              this.applicationId = applicationId;
              break;
          }
        })
      )
      .subscribe();

    this.subscription.add(applicationIdSubscription);

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);

    this.identifiant = this.formBuilder.control('', Validators.required);
    this.ginsc = this.formBuilder.group([this.identifiant]);
    this.notificationEnvoyer = false;
  }

  public validerInscription(f: NgForm): void {
    this.messageErreur = null;
    if (this.ginsc.valid) {
      this.demandeReinitialisationMotDePasse(this.identifiant.value);
    }
  }

  public demandeReinitialisationMotDePasse(email) {
    this.messageErreur = '';
    this.isLoading = true;
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.forgottenPassword.validateEmailClick');
    } else if (this.estApetizMobile) {
      this.tealiumService.link('apz.mobile.forgottenPassword.validateEmailClick');
    }
    this.notificationEnvoyer = false;

    this.gatewayService.getAccessToken().subscribe((authToken) => {
    this.reCaptchaV3Service
    .execute('ConnecterAction')
    .pipe(finalize(() => {
      this.siloApiService
        .demandeReinitialisationMotDePasse(email, this.applicationId, authToken, this.valueCaptcha)
        .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
        .subscribe(
          (retour) => this.gererRetour(retour),
          (error) => this.gererErreurs(error)
        )
    }))
    .subscribe((token) => (this.valueCaptcha = token));
  },
    err => {

    });

  }

  public gererRetour(retour: string) {
    this.notificationEnvoyer = true;
    if (this.estApetiz) {
      this.tealiumService.view('apz.web.forgottenPassword.confirmationEmailSentPageLoad');
    } else if (this.estApetizMobile) {
      this.tealiumService.view('apz.mobile.forgottenPassword.confirmationEmailSentPageLoad');
    }
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.forgottenPassword.confirmationEmailSentPageLoad');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.forgottenPassword.confirmationEmailSentPageLoad');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.forgottenPassword.confirmationEmailSentPageLoad');
    }

    const retourJSON: ReponseVerifierToken = JSON.parse(retour);
    this.receiver = retourJSON.receiver;
    if (retourJSON.channel === 'PHONE' && this.applicationId !== ApplicationCodeEnum.AFFILIE_APZ
      && this.applicationId !== ApplicationCodeEnum.BENEFIT_FINANCEUR
      && this.applicationId !== ApplicationCodeEnum.BENEFIT_BENEF && this.applicationId !== ApplicationCodeEnum.BENEFIT_MOBILE) {
      this.router.navigate(['/demande-renouvellement-mot-de-passe-SMS/' + this.saml]);
    }
    this.isLoading = false;
  }

  public renvoyerDemande(identifiant?: any) {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.forgottenPassword.sendEmailClick');
    } else if (this.estApetizMobile) {
      this.tealiumService.link('apz.mobile.forgottenPassword.sendEmailClick');
    }
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.forgottenPassword.sendEmailClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.forgottenPassword.sendEmailClick');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.forgottenPassword.sendEmailClick');
    }
    const id = identifiant ? identifiant : this.identifiant.value;
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
    .execute('ConnecterAction')
    .pipe(finalize(() => {
      this.siloApiService
      .demandeReinitialisationMotDePasse(id, this.applicationId, authToken, this.valueCaptcha)
      .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
      .subscribe(
        (retour) => (this.notificationEnvoyer = true),
        (error) => {
          this.notificationEnvoyer = false;
          this.gererErreurs(error);
        }
      )
    }))
    .subscribe((token) => (this.valueCaptcha = token));
    }

  );

   /*  this.gatewayService.getAccessToken().subscribe((authToken) =>
      this.siloApiService
        .demandeReinitialisationMotDePasse(id, this.applicationId, authToken)
        .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
        .subscribe(
          (retour) => (this.notificationEnvoyer = true),
          (error) => {
            this.notificationEnvoyer = false;
            this.gererErreurs(error);
          }
        )
    ); */
  }

  public gererErreurs(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = JSON.parse(error.error);
      switch (errors.errors[0].code) {
        case CodeErreurs.UNEXPECTED_011:
          this.messageErreur = 'Erreur technique';
          break;
        case CodeErreurs.NOT_FOUND_028:
          this.messageErreur = this.translateService.instant('ASP.FORGOTTEN_PWD.ERROR.NOT_FOUND_028');
          break;
        case CodeErreurs.IMPOSSIBLE_ACTION_013:
          // tslint:disable-next-line: max-line-length
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.FORBIDDEN_ACTION_010');
          break;
        case CodeErreurs.FORBIDDEN_ACTION_010:
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.USER_INACTIVE');
          break;
        case CodeErreurs.FORBIDDEN_ACTION_087:
          this.messageErreur = this.translateService.instant('ASP.FORBIDDEN_ACTION_087');
          break;
        default:
          this.messageErreur = 'Erreur inattendue : ' + errors.errors[0].code;
          break;
      }
    }

    if (this.estBimpli) {
      this.modalService.open('errorModal');
    }

    if (this.estApetizMobile) {
      this.scrollToTop();
    }
    this.isLoading = false;
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }


  goBack() {
    window.history.back();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
