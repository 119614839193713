import { Component } from '@angular/core';

@Component({
  selector: 'app-ouvre-apetiz',
  templateUrl: './ouvre-apetiz.component.html',
  styleUrls: ['./ouvre-apetiz.component.scss']
})
export class OuvreApetizComponent {

  constructor() { }

}
