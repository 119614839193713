<div id="coordonnees-form">
  <form [formGroup]="contactForm">
    <div class="title mb-2 text-left">
      {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.CONTACT_INFO.TITLE" | translate }}
    </div>
    <div class="subtitle mb-3 text-left">
      {{
        "AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.CONTACT_INFO.SUBTITLE" | translate
      }}
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label class="form-label" for="civility">{{
          "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.CIVILITY" | translate
        }}</label>
        <select
          type="select"
          class="form-select form-control"
          name="civility"
          id="civility"
          [formControlName]="'civility'"
        >
          <option value="MR"
            >{{
              "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.CIVILITY_M"
                | translate
            }}
          </option>
          <option value="MS">{{
            "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.CIVILITY_MME"
              | translate
          }}</option>
        </select>
      </div>
    </div>
    <div class="form-row ">
      <div class="form-group col-md-6">
        <label>
          {{
            "AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.CONTACT_INFO.FORM.ROLE" | translate
          }}</label
        >
        <select
          type="select"
          class="form-select form-control"
          name="role"
          id="role"
          [formControlName]="'role'"
        >
          <option value="Dirigeant"
            >{{
              "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ROLE_DIRIGEANT"
                | translate
            }}
          </option>
          <option value="Comptable"
            >{{
              "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ROLE_COMPTABLE"
                | translate
            }}
          </option>
          <option value="Proprietaire"
            >{{
              "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ROLE_PROPRIETAIRE"
                | translate
            }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="email" class="email" [ngClass]="{'email-disabled': !!this.affiliateEmail}">{{
          "AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.CONTACT_INFO.FORM.EMAIL" | translate
        }}</label>
        <input
          type="text"
          class="form-control"
          name="email"
          id="email"
          [formControlName]="'email'"
          maxlength="38"
        />
        <div
          *ngIf="
            contactForm.get('email').touched === true &&
            contactForm.get('email').invalid
          "
        >
          <small
            class="text-danger bold"
            *ngIf="contactForm.get('email').errors.required"
          >
          {{ "COMMON.FORM.REQUIRED" | translate }}
        </small>
          <small
            class="text-danger bold"
            *ngIf="contactForm.get('email').errors.email"
          >
            {{ "COMMON.FORM.EMAIL.FORMAT" | translate }}
          </small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="firstName">{{
          "AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.CONTACT_INFO.FORM.FIRSTNAME"
            | translate
        }}</label>
        <input
          required
          type="text"
          class="form-control"
          name="firstName"
          id="firstName"
          [formControlName]="'firstName'"
          maxlength="38"
          [ngClass]="{
            'is-invalid':
              contactForm.get('firstName').invalid === true &&
              contactForm.get('firstName').touched === true,
            'is-valid':
              contactForm.get('firstName').invalid === false &&
              contactForm.get('firstName').touched === true
          }"
          placeholder="{{
            'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.FIRST_NAME_PROMPT'
              | translate
          }}"
        />
        <div
          *ngIf="
            contactForm.get('firstName').touched === true &&
            contactForm.get('firstName').invalid
          "
        >
          <small
            class="text-danger bold"
            *ngIf="contactForm.get('firstName').errors.required"
          >
            {{ "COMMON.FORM.REQUIRED" | translate }}
          </small>
          <small
            class="text-danger bold"
            *ngIf="contactForm.get('firstName').errors.pattern"
          >
            {{
              "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ERROR.FIRST_NAME_PATTERN"
                | translate
            }}
          </small>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="lastName">{{
          "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.LAST_NAME" | translate
        }}</label>
        <input
          required
          type="text"
          class="form-control"
          name="lastName"
          id="lastName"
          [formControlName]="'lastName'"
          maxlength="38"
          [ngClass]="{
            'is-invalid':
              contactForm.get('lastName').invalid === true &&
              contactForm.get('lastName').touched === true,
            'is-valid':
              contactForm.get('lastName').invalid === false &&
              contactForm.get('lastName').touched === true
          }"
          placeholder="{{
            'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.LAST_NAME_PROMPT'
              | translate
          }}"
        />
        <div
          *ngIf="
            contactForm.get('lastName').touched === true &&
            contactForm.get('lastName').invalid
          "
        >
          <small
            class="text-danger bold"
            *ngIf="
              contactForm.get('lastName').errors &&
              contactForm.get('lastName').errors.required
            "
          >
            {{ "COMMON.FORM.REQUIRED" | translate }}
          </small>
          <small
            class="text-danger bold"
            *ngIf="
              contactForm.get('lastName').errors &&
              contactForm.get('lastName').errors.pattern
            "
          >
            {{
              "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ERROR.LAST_NAME_PATTERN"
                | translate
            }}
          </small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="phoneNumber">{{
          "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.PHONE_NUMBER"
            | translate
        }}</label>
        <input
          type="tel"
          class="form-control"
          name="phoneNumber"
          id="phoneNumber"
          [formControlName]="'phoneNumber'"
          [ngClass]="{
            'is-invalid':
              contactForm.get('phoneNumber').invalid === true &&
              contactForm.get('phoneNumber').touched === true &&
              contactForm.get('phoneNumber').value,
            'is-valid':
              contactForm.get('phoneNumber').invalid === false &&
              contactForm.get('phoneNumber').touched === true &&
              contactForm.get('phoneNumber').value
          }"
          placeholder="{{
            'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.PHONE_NUMBER_PROMPT'
              | translate
          }}"
        />
        <div
          *ngIf="
            contactForm.get('phoneNumber').touched === true &&
            contactForm.get('phoneNumber').invalid
          "
        >
          <small
            class="text-danger bold"
            *ngIf="contactForm.get('phoneNumber').errors.pattern"
          >
            {{
              "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ERROR.PATTERN_PHONENUMBER"
                | translate
            }}
          </small>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="cellPhoneNumber">{{
          "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.CELL_PHONE_NUMBER"
            | translate
        }}</label>
        <input
          type="tel"
          class="form-control"
          name="cellPhoneNumber"
          id="cellPhoneNumber"
          [formControlName]="'cellPhoneNumber'"
          [ngClass]="{
            'is-invalid':
              contactForm.get('cellPhoneNumber').invalid === true &&
              contactForm.get('cellPhoneNumber').touched === true,
            'is-valid':
              contactForm.get('cellPhoneNumber').invalid === false &&
              contactForm.get('cellPhoneNumber').touched === true
          }"
          placeholder="{{
            'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.CELL_PHONE_NUMBER_PROMPT'
              | translate
          }}"
        />
        <div class="info mt-2">
          {{
            "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.PHONE_INFO"
              | translate
          }}
        </div>
        <div
          *ngIf="
            contactForm.get('cellPhoneNumber').touched === true &&
            contactForm.get('cellPhoneNumber').invalid
          "
        >
          <small
            class="text-danger bold"
            *ngIf="
              contactForm.get('cellPhoneNumber').errors &&
              contactForm.get('cellPhoneNumber').errors.required
            "
          >
            {{ "COMMON.FORM.REQUIRED" | translate }}
          </small>
          <small
            class="text-danger bold"
            *ngIf="contactForm.get('cellPhoneNumber').errors.pattern"
          >
            {{
              "AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ERROR.PATTERN_PHONENUMBER"
                | translate
            }}
          </small>
        </div>
      </div>
    </div>
    <div class="title text-left mt-4 mb-4">
      {{ "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.TITLE" | translate }}
    </div>
    <div class="form-row">
      <div class="form-group col-md-6 position-relative">
        <label for="password">{{
          "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.PWD" | translate
        }}</label>
        <input
          [type]="showPassword === false ? 'password' : 'text'"
          class="form-control"
          name="password"
          id="password"
          [formControlName]="'password'"
          [ngClass]="{
            'is-invalid':
              contactForm.get('password').invalid === true &&
              contactForm.get('password').touched === true,
            'is-valid':
              contactForm.get('password').invalid === false &&
              contactForm.get('password').touched === true
          }"
          placeholder="{{
            'AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.PWD' | translate
          }}"
        />
        <ng-container *ngIf="!showPassword">
          <img
            (click)="showPassword = !showPassword"
            class="icon-eye"
            src="/assets//affilie-apz/icon-visibility-off.svg"
          />
        </ng-container>
        <ng-container *ngIf="showPassword">
          <img
            (click)="showPassword = !showPassword"
            class="icon-eye"
            src="/assets//affilie-apz/icon-visibility-on.svg"
          />
        </ng-container>
        <div *ngIf="contactForm.get('password').touched">
          <small
            class="bold text-danger"
            *ngIf="
              contactForm.get('password').errors &&
              contactForm.get('password').errors.required
            "
          >
            {{ "COMMON.FORM.REQUIRED" | translate }}
          </small>
        </div>
      </div>
      <div class="form-group col-md-6 position-relative">
        <label for="confirmPassword">{{
          "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.CONFIRM_PWD" | translate
        }}</label>
        <input
          [type]="showConfirmPassword === false ? 'password' : 'text'"
          class="form-control"
          name="confirmPassword"
          id="confirmPassword"
          [formControlName]="'confirmPassword'"
          [ngClass]="{
            'is-invalid':
              contactForm.get('confirmPassword').invalid === true &&
              contactForm.get('confirmPassword').touched === true,
            'is-valid':
              contactForm.get('confirmPassword').invalid === false &&
              contactForm.get('confirmPassword').touched === true
          }"
          placeholder="{{
            'AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.PWD' | translate
          }}"
        />
        <ng-container *ngIf="!showConfirmPassword">
          <img
            (click)="showConfirmPassword = !showConfirmPassword"
            class="icon-eye"
            src="/assets//affilie-apz/icon-visibility-off.svg"
          />
        </ng-container>
        <ng-container *ngIf="showConfirmPassword">
          <img
            (click)="showConfirmPassword = !showConfirmPassword"
            class="icon-eye"
            src="/assets//affilie-apz/icon-visibility-on.svg"
          />
        </ng-container>
        <div *ngIf="contactForm.get('confirmPassword').touched">
          <small
            class="bold text-danger"
            *ngIf="
              contactForm.get('confirmPassword').errors &&
              contactForm.get('confirmPassword').errors.required
            "
          >
            {{ "COMMON.FORM.REQUIRED" | translate }}
          </small>
          <small
            class="bold text-danger"
            *ngIf="
              contactForm.get('confirmPassword').errors &&
              contactForm.get('confirmPassword').errors.mustMatch
            "
          >
            {{ "COMMON.PASSWORD.DO_NOT_MATCH" | translate }}
          </small>
        </div>
      </div>
      <div class="password-validator p-3 col-md-12 mt-3">
        <span class="d-flex flex-row text-validator">{{
          "AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.VALIDATION" | translate
        }}</span>
        <app-validate-password
          [passwordValue]="contactForm.get('password').value"
          [affLastPassword]="false"
          [progressBar]="true"
          [submitted]="submittedForm"
        ></app-validate-password>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="form-group col-md-12">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="cgu"
            name="cgu"
            formControlName="cgu"
          />
          <label id="cgu-label" class="ml-2" for="cgu">
            {{
              "AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.CGU_BEGIN"
                | translate
            }}
            <a
              [routerLink]="['/cgu/', saml]"
              target="_blank"
              rel="noopener"
              >{{
                "AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.CGU_LINK"
                  | translate
              }}</a
            >
            {{
              "AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.END"
                | translate
            }}
          </label>
        </div>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="form-group col-md-12 text-right">
        <div>
          <a
            target="_blank"
            class="rgpd mr-3"
            (click)="showRgpd()"
            >{{ "AFFILIE_APZ.MERCHANTS_INVITE.RGPD.MENTION" | translate }}
          </a>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="contactForm.invalid || isLoading"
            (click)="submitForm()"
          >
            <div>
              <div *ngIf="isLoading" class="spinner-border"></div>
              <span
                [ngClass]="{
                  'ml-2': isLoading
                }"
                >{{
                  "AFFILIE_APZ.MERCHANTS_INVITE.NEXT" | translate
                }}</span
              >
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>