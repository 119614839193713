<app-cesu-layout>
    <form class="row text-center justify-content-center pt-5" [formGroup]="cesuForm" (ngSubmit)="authentication()">
        <div class="col-12 cesu-page-title">
            Créer un compte CESU Domalin®
        </div>
        <hr class="cesu-separator"/>
        <div class="col-12">
            Merci de renseigner votre code personnel CESU ci-dessous :
        </div>
        <div class="col-lg-4 col-md-6 col-12 form-group pt-4">
            <div *ngIf="errorMessage" class="alert alert-danger">
                {{ errorMessage }}
            </div>
            <div class="row no-gutters justify-content-center">
                <input
                    type="text"
                    class="form-control cesu-input"
                    [ngClass]="{ 'is-invalid': submitted && form.cesu.errors }"
                    placeholder="Code personnel CESU"
                    aria-describedby="inputGroupPrepend"
                    formControlName="cesu"
                    required
                />
                <div *ngIf="form.cesu.errors && submitted" class="invalid-feedback cesu-error">
                    <div *ngIf="form.cesu.errors.required">
                        <object class="cesu-icon" type="image/svg+xml" [data]="errorIcon"></object>
                        &nbsp;Le code personnel CESU est obligatoire
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            Code personnel Cesu oublié
            <button class="btn rounded-pill cesu-tips" type="button" [ngbPopover]="popContent"
                    [popoverTitle]="popTitle">
                <!--                    <object class="cesu-icon" type="image/svg+xml" [data]="tipsIcon"></object>-->
            </button>
            <ng-template #popTitle>Où retrouver votre code personnel ?</ng-template>
            <ng-template #popContent>Votre code personnel est indiqué dans la lettre d’accueil que vous avez reçu
                par courrier et sur la couverture de votre carnet papier de CESU Domalin®.
                Il s’agit d’un numéro à 8 chiffres commençant par le chiffre 3.
            </ng-template>
        </div>
        <div class="col-lg-4 col-md-6 col-12 form-group pt-4">
            <div class="row no-gutters justify-content-center">
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control cesu-input cesu-input-group"
                        [ngClass]="{ 'is-invalid': submitted && form.dob.errors }"
                        placeholder="Date de naissance"
                        aria-describedby="inputGroupPrepend"
                        formControlName="dob"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        readonly
                        [value]="dateOfBirth"
                    />
                    <div class="input-group-append">
                        <button class="btn cesu-append-button" (click)="d.toggle()"
                                type="button">
                            <img src="../../../../../assets/cesu/calendar_icon.svg" alt="Calendar"/>
                        </button>
                    </div>
                    <div *ngIf="form.dob.errors && submitted" class="invalid-feedback cesu-error">
                        <div *ngIf="form.dob.errors.required">
                            <object class="cesu-icon" type="image/svg+xml" [data]="errorIcon"></object>
                            &nbsp;La date de naissance est obligatoire
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 pt-4">
            <button class="btn cesu-button cesu-flat-button" type="submit">Suivant</button>
        </div>
    </form>
    <div class="row justify-content-center pt-4">
        <div class="col-auto">
            <object type="image/svg+xml" [data]="activeStepIcon"></object>
        </div>
        <div class="col-auto">
            <object type="image/svg+xml" [data]="notActiveStepIcon"></object>
        </div>
        <div class="col-auto">
            <object type="image/svg+xml" [data]="notActiveStepIcon"></object>
        </div>
    </div>
</app-cesu-layout>
