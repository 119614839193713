export enum ApplicationCodeEnum {
    APZ = 'SiteAPZ',
    APZ_MOBILE = 'SiteAPZMobile',
    CESU = 'SiteCESUBenef',
    CESU_GRD_COMPTE = 'SiteCESU',
    WEEZEN = 'SiteWeezen',
    AFFILIE_APZ = 'SiteAffilie',
    BENEFIT_BENEF = 'BenefitPortailWebBeneficiaire',
    BENEFIT_FINANCEUR = 'BenefitPortailWebFinanceur',
    BENEFIT_MOBILE = 'BenefitApplicationMobile',
    BENEFIT_CAGNOTTE = 'LaCagnotte',
    BENEFIT_BENEF_TEST = 'BenefitPortailWebBeneficiaireTest',
    BENEFIT_FINANCEUR_TEST = 'BenefitPortailWebFinanceurTest',
}
