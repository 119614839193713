import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { salmon } from 'color-name';
import { Validators, FormBuilder } from '@angular/forms';
import {distinctUntilChanged, finalize} from 'rxjs/operators';
import { GroundTruthDataService } from '../../../shared/services/ground-truth-data.service';
import { SiloApiService } from '../../../shared/services/siloApi.service';
import { GatewayService } from '../../../shared/services/gateway.service';
import { Subscription } from 'rxjs';
import { SecretQuestion } from '../../../shared/models/secret-question.model';
import {TealiumService} from '@services/tealium/tealium.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-apz-web-change-question',
  templateUrl: './apz-web-change-question.component.html',
  styleUrls: ['./apz-web-change-question.component.scss', '../../../../assets/css/apz-global.scss']
})
export class ApzWebChangeQuestionComponent implements OnInit, OnDestroy {
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Input() login: string;
  @Input() saml: string;

  identifiantForm = this.fb.group({
    currentPassword: [undefined, Validators.required],
    secretQuestion: [undefined, [Validators.required]],
    response: [undefined, [Validators.required]]
  });

  isLoading: boolean;
  isSubmitted: boolean;
  mySubscription = new Subscription();
  hide = true;
  questions: Array<SecretQuestion>;
  private valueCaptcha: string;

  constructor(
    private fb: FormBuilder,
    private gtds: GroundTruthDataService,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private readonly tealiumService: TealiumService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    this.tealiumService.view('apz.web.profile.secreteQuestionLoad');
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
        .execute('ReadSecretQuestionsAction')
        .pipe(
          finalize(() => {
            this.siloApiService.readSecretQuestions(authToken, this.valueCaptcha).subscribe((q) => {
              this.questions = q.items;
              this.reCaptchaV3Service
                .execute('ReadSecretQuestionsAction')
                .pipe(
                  finalize(() => {
                    this.siloApiService.readSecretQuestions(authToken, this.valueCaptcha, this.login, 'SiteAPZ').subscribe((qb) => {
                      if (qb.items && qb.items.length > 0) {
                        this.identifiantForm.get('secretQuestion').setValue(qb.items[0].id);
                      }
                    });
                  })
                )
                .subscribe((token) => (this.valueCaptcha = token));
            });
          })
        )
        .subscribe((token) => (this.valueCaptcha = token));
    });
  }

  submitForm() {
    this.tealiumService.link('apz.web.profile.validateSecreteQuestionClick');
    this.isSubmitted = true;
    if (this.identifiantForm.valid) {
      this.isSubmitted = false;
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit(this.identifiantForm.getRawValue());
    }
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }
}
