import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SiloApiService } from '../../../shared/services/siloApi.service';
import { GatewayService } from '../../../shared/services/gateway.service';
import { TealiumService } from '../../../shared/services/tealium/tealium.service';
import {finalize} from 'rxjs/operators';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
    selector: 'app-apz-web-change-id',
    templateUrl: './apz-web-change-id.component.html',
    styleUrls: ['./apz-web-change-id.component.scss',
        '../../../../assets/css/apz-global.scss']
})
export class ApzWebChangeIdComponent implements OnInit {

    @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
    @Input() saml: string;
    @Input() errorMessage: string;
    @Input() identifiantOK: boolean;
    @Input() login: string;

    identifiantForm = this.fb.group({
        currentPassword: [undefined, Validators.required],
        newLogin: [undefined, Validators.required]
    });

    isSecondStep = false;
    hide = true;
    currentPasswordInvalid = false;
    private valueCaptcha: string;

    constructor(private fb: FormBuilder, private siloApiService: SiloApiService,
       private gatewayService: GatewayService,
       private readonly tealiumService: TealiumService,
       private readonly reCaptchaV3Service: ReCaptchaV3Service) {
    }

    ngOnInit() {
      this.tealiumService.view('apz.web.profile.changeLoginPageLoad');
    }

  goToSecondStep() {
    this.tealiumService.link('apz.web.profile.validateChangeLoginClick');
    if (this.identifiantForm.get('currentPassword').valid) {
      this.gatewayService.getAccessToken().subscribe(authToken => {
        this.reCaptchaV3Service
          .execute('UserPasswordCheckAction')
          .pipe(finalize(() => {
            this.siloApiService.userPasswordCheck(this.login, this.identifiantForm.get('currentPassword').value,
              'SiteAPZMobile', authToken, this.valueCaptcha).subscribe(retour => {
              this.currentPasswordInvalid = !retour.resultCheck;
              this.isSecondStep = !this.currentPasswordInvalid;
              if (this.isSecondStep) {
                this.tealiumService.view('apz.web.profile.changeLoginChooseLoginPageLoad');
              }
            });
          }))
          .subscribe((token) => (this.valueCaptcha = token));
      });
    }
  }

    submitForm() {
      this.tealiumService.link('apz.web.profile.validateChangeLoginChooseLoginClick');
        if (this.identifiantForm.valid) {
            this.submitted.emit(this.identifiantForm.getRawValue());
        }
    }

}
