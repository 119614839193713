import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ApplicationCodeEnum } from 'src/app/shared/enums/application-code.enum';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';

@Component({
    selector: 'app-create-account-mail',
    templateUrl: './create-account-mail.component.html',
    styleUrls: ['./create-account-mail.component.scss'],
})
export class CreateAccountMailComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    public applicationCode$: Observable<ApplicationCodeEnum>;
    public saml: string;

    public ApplicationCodeEnum = ApplicationCodeEnum;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly sessionQuery: SessionQuery,
        private readonly sessionStateService: SessionStateService,
    ) {
    }

    public ngOnInit(): void {
        this.sessionStateService.setApplicationCode(this.route);

        this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

        const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap(saml => (this.saml = saml))).subscribe();

        this.subscription.add(samlSubscription);

        // tslint:disable-next-line: deprecation
        const checkStepSubscription = combineLatest(
            this.sessionQuery.selectedApplicationCode,
            this.sessionQuery.selectedRegistrationStep,
        )
            .pipe(
                take(1),
                tap(([applicationCode, registrationStep]) => {
                    if (
                        applicationCode === ApplicationCodeEnum.CESU &&
                        registrationStep !== RegistrationStepEnum.MAIL_STEP
                    ) {
                        return this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
                    }
                }),
            )
            .subscribe();

        this.subscription.add(checkStepSubscription);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
