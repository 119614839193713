import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';

@Component({
  selector: 'app-fourth-step',
  templateUrl: './fourth-step.component.html',
  styleUrls: ['./fourth-step.component.scss']
})
export class FourthStepComponent implements OnInit {

  @Input() login: string;
  saml: string;
  applicationId: string;
  estASP: boolean;
  estAPZ: boolean;
  estAPZMobile: boolean;
  estBimpli: boolean;
  estBimpliMobile: boolean;

  constructor(private route: ActivatedRoute) {
    this.saml = this.route.snapshot.paramMap.get('saml');
    const samlDecoder = atob(this.saml);
    this.applicationId = samlDecoder.split('|')[2];

    if (this.applicationId === 'SiteWeezen') {
      this.estASP = true;
    }
    if (this.applicationId === 'SiteAPZMobile') {
      this.estAPZMobile = true;
    }
    if (this.applicationId === ApplicationCodeEnum.BENEFIT_CAGNOTTE || this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF
      || this.applicationId === ApplicationCodeEnum.BENEFIT_FINANCEUR) {
      this.estBimpli = true;
      this.estBimpliMobile = false;
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      this.estBimpli = true;
      this.estBimpliMobile = true;
    }

    if (this.applicationId === 'SiteAPZ') {
      this.estAPZ = true;
    }
  }

  ngOnInit(): void {
  }

}
