import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
// import { Favicons } from '@shared/favicons';
import { WeezenComponent } from '../../weezen/weezen.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cgu-asp',
  templateUrl: './cgu-asp.component.html',
  styleUrls: ['../../../../assets/css/asp-global.scss', './cgu-asp.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CguAspComponent extends WeezenComponent implements OnInit {
  cgu: string;
  @Input() token: string;
  @Input() applicationId = ApplicationCodeEnum.WEEZEN;

  constructor(
    // fav: Favicons,
    private router: Router,
    private gatewayService: GatewayService,
    private siloApiService: SiloApiService,
    private translateService: TranslateService,
  ) {
    // super(fav);
    super();
  }

  ngOnInit() {
    this.gatewayService.getAccessToken().subscribe((authToken) =>
      this.siloApiService
        .readTermsAndConditions(this.applicationId, 'EMPLOYEE', authToken, this.translateService.currentLang)
        .subscribe((response) => {
          this.cgu = response.items[0].content;
        }),
    );
  }

  connection() {
    window.location.href = environment.redirectURIWee.replace('/token', '');
  }

  retour() {
    if (history.length !== 1) {
      history.back();
    } else {
      window.close();
    }
  }
}
