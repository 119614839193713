import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  hasCapitalCaseRegex,
  hasLowerCaseLetterRegex,
  hasNumberRegex,
  hasSpecialCharacterRegex,
} from '@shared/components/password-validation/password.helper';

@Component({
  selector: 'app-validate-password',
  templateUrl: './validate-password.component.html',
  styleUrls: ['./validate-password.component.scss'],
})
export class ValidatePasswordComponent implements OnInit, OnChanges {
  @Input() passwordValue: string;
  @Input() currentPassword: string;
  @Input() affLastPassword = true;
  @Input() progressBar = false;
  @Input() submitted = true;

  private crossIco: IconDefinition;

  progressDefaultColor = '#e9ecef';

  controlCount = 6;

  errors = {
    hasNumber: true,
    hasCapitalCase: true,
    hasLowerCase: true,
    hasSpecialCharacter: true,
    hasChanged: true,
    minLength: true,
    maxLength: true,
    force: {
      value: 0,
      color: 'red',
    },
  };

  constructor(library: FaIconLibrary) {
    this.crossIco = library.getIconDefinition('fas', 'times');
  }

  ngOnInit() {
    if (this.progressBar === true) {
      this.progressDefaultColor = '#FF3951';
    }
    if (this.affLastPassword) {
      this.controlCount = 7;
    }
    this.validate();
  }

  ngOnChanges(): void {
    this.validate();
  }

  validate() {
    if (this.passwordValue !== null) {
      this.errors.hasCapitalCase =
        this.passwordValue.match(hasCapitalCaseRegex) === null;
      this.errors.hasLowerCase =
        this.passwordValue.match(hasLowerCaseLetterRegex) === null;
      this.errors.hasNumber = this.passwordValue.match(hasNumberRegex) === null;
      if (this.affLastPassword) {
        if (this.passwordValue !== null && this.currentPassword !== null) {
          this.errors.hasChanged = this.passwordValue === this.currentPassword;
        }
      }
      this.errors.hasSpecialCharacter =
        this.passwordValue.match(hasSpecialCharacterRegex) === null;
      this.errors.minLength =
        !this.passwordValue ||
        this.passwordValue.length === 0 ||
        this.passwordValue.length < 8;
      this.errors.maxLength =
        !this.passwordValue ||
        this.passwordValue.length === 0 ||
        this.passwordValue.length > 15;
      if (this.progressBar === true) {
        this.errors.force.value = this.measureProgress(this.passwordValue);
        this.errors.force.color = '#B8E986';
      } else {
        this.errors.force.value = this.measureStrength(this.passwordValue);
        this.errors.force.color = this.getColor(this.errors.force.value);
      }
    }
  }

  measureProgress(password: string) {
    let progress = 0;

    for (const [key, value] of Object.entries(this.errors)) {
      if (value === false) {
        progress += 1;
      }
    }

    return (progress * 50) / this.controlCount;
  }

  measureStrength(p: string) {
    let force = 0;
    const regex = /[!?*/"_$µ()+=#@&-]/g; // "
    const lowerLetters = hasLowerCaseLetterRegex.test(p);
    const upperLetters = hasCapitalCaseRegex.test(p);
    const numbers = hasNumberRegex.test(p);
    const symbols = hasSpecialCharacterRegex.test(p);

    const flags = [lowerLetters, upperLetters, numbers, symbols];
    const passedMatches = flags.filter((isMatchedFlag: boolean) => {
      return isMatchedFlag === true;
    }).length;

    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // penalty (short password)
    force = p.length <= 8 ? Math.min(force, 10) : force;

    // penalty (poor variety of characters)
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 40) : force;

    return force;
  }

  getColor(s: number): any {
    const colors = ['#FF3951', '#F90', '#B8E986'];
    let idx = 0;
    if (s <= 10) {
      idx = 0;
    } else if (s <= 20) {
      idx = 1;
    } else if (s <= 30) {
      idx = 1;
    } else if (s <= 40) {
      idx = 1;
    } else {
      idx = 2;
    }
    return colors[idx];
  }
}
