<div id="go-back-container"
    class="d-flex d-sm-flex d-md-none flex-column align-items-start justify-content-center w-100">
    <button id="go-back-btn" type="button" class="btn btn-link" (click)="back()">
        <div class="d-flex flex-row align-items-center justify-content-center">
            <i id="go-back-btn-icon" class="bi bi-arrow-left"></i>
            <span class="ml-2" id="go-back-btn-label">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.BACK' | translate
                }}</span>
        </div>
    </button>
</div>
<div id="info-menu-container" class="d-flex flex-column align-items-start justify-content-center w-100 p-3">
    <span id="title" class="mb-2">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.WELCOME' | translate }}</span>
    <span class="sub-title">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.SUBTITLE-1' | translate }}</span>
    <span id="divider" class="mt-3 w-25"></span>
</div>
<div id="menu-title-container" class="d-flex flex-column align-items-center justify-content-start w-100 p-3">
    <div class="d-flex flex-row align-items-center justify-content-start w-100 mb-4"
        [ngClass]="{ 'step-selected': step == 1 && (isStepOpen$ | async) === false }">
        <i class="bi bi-arrow-right menu-icon" *ngIf="step == 1 && (isStepOpen$ | async) === false"></i>
        <i class="bi bi-check-circle-fill menu-icon" *ngIf="step > 1 && (isStepOpen$ | async) === false"></i>
        <span class="ml-2 menu-title">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.SIRET' | translate }}</span>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-start w-100 mb-4"
        [ngClass]="{ 'step-selected': step == 1.5 }">
        <i class="bi bi-arrow-right menu-icon" *ngIf="step == 1.5"></i>
        <i class="bi bi-check-circle-fill menu-icon" *ngIf="step > 1.5"></i>
        <span class="ml-2 menu-title">
            {{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.COORDONNEES' | translate }}
        </span>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-start w-100 mb-4"
        [ngClass]="{ 'step-selected': step == 2 }">
        <i class="bi bi-arrow-right menu-icon" *ngIf="step == 2"></i>
        <i class="bi bi-check-circle-fill menu-icon" *ngIf="step > 2"></i>
        <span class="ml-2 menu-title">
            {{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.MAIL_PASSWORD' | translate }}
        </span>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-start w-100 mb-4"
        [ngClass]="{ 'step-selected': step == 3 }">
        <i class="bi bi-arrow-right menu-icon" *ngIf="step == 3"></i>
        <i class="bi bi-check-circle-fill menu-icon" *ngIf="step > 3"></i>
        <span class="ml-2 menu-title">
            {{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.ACTIVATION' | translate }}
        </span>
    </div>
    <button id="go-btn" type="button" class="btn btn-primary btn-block mt-5 d-block d-sm-block d-md-none"
        (click)="hideStep()">
        <div class="d-flex flex-row align-items-center justify-content-center">
            <span id="go-btn-label">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.LEFT_PANEL.GO' | translate }}</span>
        </div>
    </button>
</div>
