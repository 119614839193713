<div class="img-row">
    <a><img src=" ../../../assets/asp/logo_wee.png" class="logo-img" (click)="goToLogin()"/></a>
</div>
<div class="header d-flex align-items-center justify-content-center">
    <p class="title">{{'CREATION_ACCOUNT.TITLE' | translate}}</p>
</div>
<div class="subHeader d-flex align-items-center justify-content-center" *ngIf="messageEmail !== '' && messageEmail !== undefined">
    <i class="icon-info mr-3"></i>
    <p>{{messageEmail}}</p>    
</div>
<div class="d-flex background-blanc justify-content-center align-items-center p-4">
    <div class="row images-header d-flex justify-content-between align-items-center">
        <i class="icon-carte icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
        <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
        <i class="icon-profil icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
        <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
        <i class="icon-envoi-mail icon rounded-circle d-flex align-items-center justify-content-center"></i>
        <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
        <i class="icon-active-card icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
    </div>
</div>

<div class="container detail-container">
    <div class="container text-center">
        <div class="row">
            <div class="col-12 title-card">
                {{'CREATION_ACCOUNT.THIRD_STEP.MESSAGERIE' | translate}}
            </div>
            <div class="col-12 title-bold pt-4">
                {{'CREATION_ACCOUNT.THIRD_STEP.INSCRIPTION' | translate}}<br>
                {{'CREATION_ACCOUNT.THIRD_STEP.MAIL' | translate}}
            </div>
            <div class="col-12 pt-4">
                <p class="sub-title">{{'CREATION_ACCOUNT.THIRD_STEP.RENVOI_MAIL' | translate}}
                    <u (click)="reSendEmail()">{{'CREATION_ACCOUNT.THIRD_STEP.RENVOI_MAIL_SUITE' | translate}}</u>
                </p>
            </div>
        </div>
    </div>                
</div>
