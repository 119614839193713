import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { SiloApiService } from '@services/siloApi.service';
import { GatewayService } from '@services/gateway.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { ModalService } from '@shared/components/modal/modal.service';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';
import { BehaviorSubject } from 'rxjs';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { SessionQuery } from '@services/store/session/session.query';
import { ChangePassword } from '@shared/models/change-password.model';
import { Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-bimpli-change-pwd',
  templateUrl: './bimpli-change-pwd.component.html',
  styleUrls: ['./bimpli-change-pwd.component.scss']
})
export class BimpliChangePwdComponent implements OnInit {

  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() retour: EventEmitter<any[]> = new EventEmitter();
  @Input() errorMessage: string;
  @Input() saml: string;
  @Input() passwordOk: boolean;
  @Input() login: string;
  @Input() applicationId: string;

  passwordEqual = false;
  doNotMatch = false;
  public oldPassword: FormControl;
  public password: FormControl;
  public confirm: FormControl;
  public ginsc: FormGroup;
  private subscription = new Subscription();
  public changePasswordInfo: ChangePassword;
  public messageErreur: string;
  public isLoading: boolean;

  private valueCaptcha: string;

  passwordForm = this.fb.group({
    currentPassword: ['', Validators.required],
    newPassword: ['', validatePassword()],
    confirmPassword: ['', validatePassword()],
  });

  isActualPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  applicationCodeEnum = ApplicationCodeEnum;

  constructor(private fb: FormBuilder, private siloApiService: SiloApiService,
              private gatewayService: GatewayService,
              private readonly tealiumService: TealiumService,
              private readonly modalService: ModalService,
              private translateService: TranslateService,
              private readonly sessionQuery: SessionQuery,
              private snackbarService: SnackbarService,
              private readonly reCaptchaV3Service: ReCaptchaV3Service) {
  }

  ngOnInit(): void {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.profile.changePasswordPageLoad');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.profile.changePasswordPageLoad');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.profile.changePasswordPageLoad');
    }

    const changePasswordInfoSubscription = this.sessionQuery.selectedChangePasswordInfo
      .pipe(tap((changePasswordInfo: ChangePassword) => (this.changePasswordInfo = changePasswordInfo)))
      .subscribe();

    this.subscription.add(changePasswordInfoSubscription);
  }

  submitForm() {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.profile.validateChangePasswordChoosePasswordClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.profile.validateChangePasswordChoosePasswordClick');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.profile.validateChangePasswordChoosePasswordClick');
    }
    if (this.passwordForm.valid && this.doNotMatch && this.passwordEqual) {
      this.submitted.emit(this.passwordForm.getRawValue());
    }
  }

  arePasswordsDifferent() {
    if (this.passwordForm.valid && this.passwordForm.get(['currentPassword']).value !== this.passwordForm.get(['newPassword']).value) {
      this.doNotMatch = true;
    } else {
      this.doNotMatch = null;
    }
  }

  isLessThanEightChars(p: string): boolean {
    return p.length < 8;
  }

  isMoreThanSixtyChars(p: string): boolean {
    return p.length > 60;
  }

  hasAtLeastOneLowerCase(p: string): boolean {
    return p.toUpperCase() !== p;
  }

  hasAtLeastOneUpperCase(p: string): boolean {
    return p.toLowerCase() !== p;
  }

  hasAtLeastOneDigit(p: string): boolean {
    return /\d/.test(p);
  }

  hasAtLeastOneSpecialChar(p: string): boolean {
    const pwdRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return pwdRegex.test(p);
  }

  public validerUpdatePwd(): void {
    this.messageErreur = null;
    this.isLoading = true;
    this.updateUser(this.passwordForm.get('currentPassword').value, this.passwordForm.get('newPassword').value);
  }

  public updateUser(oldPwd, newPwd) {

    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
      .execute('ConnecterAction')
      .pipe(finalize(() => {
        this.siloApiService
        .updateUser(this.login, null, this.applicationId, oldPwd, newPwd, authToken, this.valueCaptcha)
        .subscribe(retour => { this.passwordOk = true; this.redirection(); }, error => this.gererErreurs(error),)
      }))
      .subscribe((token) => (this.valueCaptcha = token));
    },
      err => {
        this.isLoading = false;
        this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
      });

   /*  this.gatewayService
      .getAccessToken()
      .subscribe(authToken => this.siloApiService
        .updateUser(this.login, null, this.applicationId, oldPwd, newPwd, authToken, this.valueCaptcha)
        .subscribe(retour => { this.passwordOk = true; this.redirection(); }, error => this.gererErreurs(error))); */
  }

  public redirection() {
    this.isLoading = false;
    this.snackbarService.showSuccess('Mise à jour effectuée');
    if (this.changePasswordInfo.redirectUrl) {
      window.location.href = this.changePasswordInfo.redirectUrl;
    }
  }
  public gererErreurs(error: HttpErrorResponse) {
    this.isLoading = false;
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = JSON.parse(error.error);
      switch (errors.errors[0].code) {
        case CodeErreurs.UNEXPECTED_011:
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED_011');
          break;
        case CodeErreurs.INCORRECT_DATA_007:
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.INCORRECT_DATA_007');
          break;
        case CodeErreurs.FORBIDDEN_ACTION_010:
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.FORBIDDEN_ACTION_010');
          break;
        default:
          this.messageErreur = this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED')
            + errors.errors[0].code;
          break;
      }
    }
    this.modalService.open('errorModal');
  }

  back() {
    window.history.back();
  }

  closePopin(id: string){
    this.modalService.close(id);
  }
}
