<div id="unlock-container">
    <div class="p-16 mt-4">
        <div class="header">
            <app-go-back-btn (goBack$)="goBack()"></app-go-back-btn>
        </div>
        <div class="content">
            <div class="title text-left">
                {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CONTAINER.TITLE"
                      | translate
                  }}
            </div>
            <div class="content-text mt-4 mb-2">
            </div>

            <div id="selectOptions" class="my-2">
                <app-dropdown-select
                [options]="options"
                [selectDefaultText]= "defaultOption ? defaultOption : 'Choisir'"
                [isDefaultOption] = "!!!defaultOption"
                [selectLabel]="'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.CONTAINER.SELECT_LABEL'"
                (click$)="unlock($event)"
                >
                </app-dropdown-select>
            </div>
            <div class="mt-4">
                <ng-container [ngSwitch]="content">
                    <app-unlock-by-collection 
                    *ngSwitchCase="'collection'"
                    (goToPanel$)="goToPanel($event)"
                    (canAccess$)="canAccess()"
                    >
                    </app-unlock-by-collection>
                    <app-unlock-by-cntr 
                    *ngSwitchCase="'cntr'"
                    (canAccess$)="canAccess()"
                    >
                    </app-unlock-by-cntr>
                    <app-unlock-by-conecs 
                    *ngSwitchCase="'conecs'"
                    (canAccess$)="canAccess()"
                    >
                    </app-unlock-by-conecs>
                    <app-unlock-by-bill 
                    *ngSwitchCase="'bill'"
                    (goToPanel$)="goToPanel($event)"
                    (canAccess$)="canAccess()"
                    >
                    </app-unlock-by-bill>
                </ng-container>

            </div>
        </div>
    </div>
</div>