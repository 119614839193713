<div class="background-from-app container-fluid vh-100 vw-100 d-flex flex-column">
    <app-snackbar></app-snackbar>
    <span class="create" *ngIf="!isSecondStep">Mot de passe</span>
    <span class="create" *ngIf="isSecondStep">Code de vérification</span>
    <span class="create-subtitle mt-4 mb-4" *ngIf="!isSecondStep && context != 'show-card-number'">Saisissez votre mot de passe pour confirmer l’opération </span>
    <span class="create-subtitle mt-4 mb-4" *ngIf="!isSecondStep && context === 'show-card-number'">Saisissez votre mot de passe pour confirmer le renvoi de votre numéro de carte </span>
    <span class="create-subtitle mt-4 mb-4" *ngIf="isSecondStep && otpType === 'OTP'">Veuillez saisir le code que nous venons de vous envoyer par SMS.</span>
    <span class="create-subtitle mt-4 mb-4" *ngIf="isSecondStep && otpType === 'OTPMAIL'">Veuillez saisir le code que nous venons de vous envoyer par email.</span>

    <form #passwordForm name="passwordForm" [formGroup]="identifiantForm" (submit)="submitForm()" class="justify-content-start col-12 col-md-6 p-0">
        <div [hidden]="isSecondStep">
            <input type="password" class="form-control" name="password" placeholder="Saisir votre mot de passe" id="password"
                   formControlName="currentPassword">

            <div *ngIf="identifiantForm.get('currentPassword').touched && identifiantForm.get('currentPassword').invalid">
                <small class="form-text text-danger" *ngIf="identifiantForm.get('currentPassword').errors.required">
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
            </div>
            <small class="form-text text-danger" *ngIf="currentPasswordInvalid">
                {{ 'APZ.CHANGEMENT_MDP.MDP_INCORRECT' | translate }}
            </small>
        </div>
        <div [hidden]="!isSecondStep">

            <app-input-code [codeLength]="6"
                            [validCode]="!codeInvalid"
                            [disabled]="otpSubmitting"
                            (codeChanged)="onCodeChanged($event)">
            </app-input-code>
            <span *ngIf="codeInvalid" class="d-flex row justify-content-center mt-2 text-center text-danger">
                Code non valide
            </span>
        </div>
        <button type="button" class="mt-4 btn btn-primary btn-block" [disabled]="identifiantForm.get('currentPassword').errors" *ngIf="!isSecondStep" (click)="goToSecondStep()">
            <div class="d-flex flex-row align-items-center justify-content-center">
                <div *ngIf="isLoading" class="spinner-border"></div>
                <span [ngClass]="{'ml-2': isLoading}">{{ 'CREATION_ACCOUNT.VALIDER' | translate }}</span>
            </div>
        </button>

        <button type="button" class="mt-4 btn btn-primary btn-block" [disabled]="identifiantForm.get('code').errors" *ngIf="isSecondStep" (click)="submitForm()">
            <div class="d-flex flex-row align-items-center justify-content-center">
                <div *ngIf="isLoading" class="spinner-border"></div>
                <span [ngClass]="{'ml-2': isLoading}">{{ 'CREATION_ACCOUNT.VALIDER' | translate }}</span>
            </div>
        </button>
    </form>
</div>
