<div class="img-row">
    <a><img src=" ../../../assets/asp/logo_wee.png" class="logo-img" (click)="connection()" /></a>
</div>

<div class="header d-flex align-items-center justify-content-center">
    <p class="title">{{'NAVBAR.CGU' | translate}}</p>
</div>
<div class="container">
    <div class="card">
        <button class="btn btn-secondary mt-4" (click)="retour()">
            {{'CREATION_ACCOUNT.RETOUR' | translate}}
        </button>
        <div class="card-body">
            <div class="cgu" [innerHTML]="cgu"></div>
        </div>
        <button class="btn btn-secondary mb-4" (click)="retour()">
            {{'CREATION_ACCOUNT.RETOUR' | translate}}
        </button>
    </div>
</div>