<app-asp-change-pwd
  *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN"
  (submitted)="updateUser($event.currentPassword, $event.newPassword)"
  (retour)="back()"
  [errorMessage]="messageErreur"
  [passwordOk]="passwordOk"
>
</app-asp-change-pwd>

<app-apz-change-pwd
  *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE"
  (submitted)="updateUser($event.currentPassword, $event.newPassword)"
  (retour)="back()"
  [errorMessage]="messageErreur"
  [passwordOk]="passwordOk"
  [login]="login"
>
</app-apz-change-pwd>

<app-apz-web-change-pwd
  *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ && !showConfirmationModifPwd"
  (submitted)="updateUser($event.currentPassword, $event.newPassword)"
  (retour)="back()"
  [errorMessage]="messageErreur"
  [passwordOk]="passwordOk"
  [login]="login"
  [saml]="saml"
>
</app-apz-web-change-pwd>

<app-bimpli-change-pwd
    *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF
          || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR|| (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE"
    (submitted)="updateUser($event.currentPassword, $event.newPassword)"
    (retour)="back()"
    [errorMessage]="messageErreur"
    [passwordOk]="passwordOk"
    [login]="login"
    [saml]="saml"
    [applicationId]="(applicationCode$ | async)"
>
</app-bimpli-change-pwd>

<app-confirmation-changement-user
  *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ && showConfirmationModifPwd"
  [saml]="saml"
  title="{{ 'COMMON.USER.CHANGE_USER_PWD' | translate }}"
  [isLogin]="false"
></app-confirmation-changement-user>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
  <app-cesu-change-pwd></app-cesu-change-pwd>
</ng-container>
<app-affilie-apz-change-pwd
    *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.AFFILIE_APZ"
    (submitted)="updateUser($event.currentPassword, $event.newPassword)"
    [login]="login"
>
</app-affilie-apz-change-pwd>
