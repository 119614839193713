<div class="container-fluid vw-100 vh-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4" *ngIf="!isLoading">
        <div class="bloc-center d-flex flex-column" *ngIf="success">

            <img src="/assets/bimpli/validation-compte.png" class="validation-compte"/>
            <span class="create mt-4">Merci !</span>
            <span class="create-second">Votre compte a été créé avec succès !</span>
            <span class="create-subtitle mt-4">Rendez vous dès à présent dans votre espace pour découvrir vos avantages</span>

            <button type="button" class="mb-4 mt-4 btn btn-secondary btn-block" (click)="goLogin()">
                Se connecter
            </button>

        </div>
        <div class="bloc-center d-flex flex-column" *ngIf="!success">
            <img src="/assets/bimpli/validation-compte.png" class="validation-compte"/>
            <span class="create mt-4">Erreur !</span>
            <span class="create-subtitle mt-4">Un problème a été rencontré durant l'activation de votre compte. Merci de réessayer ultérieurement.</span>

            <button type="button" class="mb-4 mt-4 btn btn-secondary btn-block" (click)="goLogin()">
                Se connecter
            </button>

        </div>
    </div>
</div>
