<div id="siret-nit-found">
    <div class="p-16 mt-4">
        <div class="header">
            <app-go-back-btn (goBack$)="goBack()"></app-go-back-btn>
        </div>
        <div class="content">
            <div class="title text-left">
                {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.SIRET_NOT_FOUND.TITLE"
                      | translate
                  }}
            </div>
            <div class="content-text mt-4">
                {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.SIRET_NOT_FOUND.TEXT.LINE1"
                      | translate
                  }}
            </div>
            <div class="content-text mt-4">
                {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.SIRET_NOT_FOUND.TEXT.LINE2"
                      | translate
                  }}
            </div>
            <div class="content-text mt-4 mb-2">
                {{
                    "AFFILIE_APZ.MERCHANTS_SIGNUP.SIRET_NOT_FOUND.TEXT.LINE3"
                      | translate
                  }}
            </div>
            <button class="btn py-3 mt-4 w-100">
                <span class="btn-text">
                    {{
                        "AFFILIE_APZ.MERCHANTS_SIGNUP.SIRET_NOT_FOUND.BUTTON.CNTR_WEBSITE"
                          | translate
                      }}
                </span>
            </button>
        </div>
    </div>

    
</div>