<div class="img-row">
  <a><img src=" ../../../assets/asp/logo_wee.png" class="logo-img" (click)="goToLogin()"/></a>
</div>
<div class="header d-flex align-items-center justify-content-center">
  <p class="title">{{ 'CREATION_ACCOUNT.TITLE' | translate }}</p>
</div>

<div *ngIf="!success" class="subHeaderError d-flex align-items-center justify-content-center">
  <i class="icon-info mr-3"></i>
  <p>{{ messageErreur }}</p>
</div>

<div class="d-flex background-blanc justify-content-center align-items-center p-4">
  <div class="row images-header d-flex justify-content-between align-items-center">
    <i class="icon-carte icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
    <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
    <i class="icon-profil icon rounded-circle d-flex align-items-center justify-content-center"></i>
    <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
    <i class="icon-envoi-mail icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
    <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
    <i class="icon-active-card icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
  </div>
</div>

<div class="container detail-container">
  <div class="pb-3">
    <p class="title-card text-left">{{ 'CREATION_ACCOUNT.SECOND_STEP.INFOS_PERSO' | translate }}</p>
  </div>
  <div class="row row-no-margin infos-utilisateur pt-3 pb-3">
    <div class="col-12">
      <label>{{ 'CREATION_ACCOUNT.SECOND_STEP.CIVILITE' | translate }}</label>
      <p class="sub-label">{{ employee.title }}</p>
    </div>
    <div class="col-6 pt-3">
      <label>{{ 'CREATION_ACCOUNT.SECOND_STEP.NOM' | translate }}</label>
      <p class="sub-label">{{ employee.lastName }}</p>
    </div>
    <div class="col-6 pt-3">
      <label>{{ 'CREATION_ACCOUNT.SECOND_STEP.EMAIL' | translate }}</label>
      <p class="sub-label">{{ mailPro }}</p>
    </div>
    <div class="col-6 pt-3">
      <label>{{ 'CREATION_ACCOUNT.SECOND_STEP.PRENOM' | translate }}</label>
      <p class="sub-label">{{ employee.firstName }}</p>
    </div>
    <div class="col-6 pt-3">
      <label>{{ 'CREATION_ACCOUNT.SECOND_STEP.PHONE' | translate }}</label>
      <p class="sub-label">{{ cellPhonePro }}</p>
    </div>
  </div>
  <div class="pt-5 pb-3">
    <p class="title-card text-left">{{ 'CREATION_ACCOUNT.SECOND_STEP.ID_CONNECT' | translate }}</p>
  </div>
  <!-- row pour la vue descktop -->
  <div class="d-none d-md-block">
    <div class="row">
      <div class="col-6">
        <form name="passwordForm" [formGroup]="passwordForm" (submit)="submitForm()" class="row justify-content-center">
          <div class="col-12 col-sm">
            <div class="form-group">
              <label for="login" class="form-label titre">{{ 'CREATION_ACCOUNT.SECOND_STEP.LOGIN' | translate }}</label>
              <input
                type="text"
                class="form-control form-control-edit value inputLogin"
                name="login"
                formControlName="login"
                placeholder="email@mail.fr"
              />
              <div *ngIf="passwordForm.get('login').touched && passwordForm.get('login').invalid">
                <small class="form-text text-danger" *ngIf="passwordForm.get('login').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="form-text text-danger" *ngIf="passwordForm.get('login').errors.pattern">
                  {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
                </small>
              </div>
            </div>
            <div class="form-group">
              <label for="password" class="form-label titre">{{ 'CREATION_ACCOUNT.SECOND_STEP.MOT_DE_PASSE' | translate }}</label>
              <input
                type="password"
                class="form-control value inputLogin"
                name="password"
                formControlName="password"
                placeholder="******"
                (keyup)="controlPassWord()"
              />
              <div *ngIf="passwordForm.get('password').touched && passwordForm.get('password').invalid">
                <small class="form-text text-danger" *ngIf="passwordForm.get('password').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <div class="form-group">
              <label for="confirmPassword" class="form-label titre">{{
                'CREATION_ACCOUNT.SECOND_STEP.MOT_DE_PASSE_CONFIRM' | translate
              }}</label>
              <input
                type="password"
                class="form-control value inputLogin"
                name="confirmPassword"
                formControlName="confirmPassword"
                placeholder="******"
                (keyup)="controlPassWord()"
              />
              <div *ngIf="passwordForm.get('confirmPassword').touched">
                <small
                  class="form-text text-danger"
                  *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required"
                >
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="form-text text-danger" *ngIf="!passwordEqual">
                  {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
                </small>
              </div>
            </div>
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input inputCGU" id="cgu" name="cgu" formControlName="cgu" />
              <label class="form-check-label" for="cgu">
                {{ 'CREATION_ACCOUNT.SECOND_STEP.CGU_CHECKBOX' | translate }}
                <a [routerLink]="['/cgu/', token]" target="_blank" rel="noopener">{{ 'CREATION_ACCOUNT.SECOND_STEP.CGU_LINK' | translate }}</a>
              </label>
            </div>
          </div>
        </form>
      </div>
      <div class="col-6">
        <div class="infos-utilisateur">
          <p class="pt-2 valid-mdp">{{ 'CREATION_ACCOUNT.SECOND_STEP.VALIDATION' | translate }}</p>
          <app-password-validation [passwordValue]="passwordForm.get('password').value" [affLastPassword]="false" [progressBar]="true">
          </app-password-validation>
        </div>
      </div>
    </div>
  </div>

  <!-- row pour la vue mobile -->
  <div class="d-md-none p-2">
    <form name="passwordForm" [formGroup]="passwordForm" (submit)="submitForm()" class="row justify-content-center">
      <div class="col-12 col-sm">
        <div class="form-group inputLogin">
          <label for="login" class="form-label titre inputLogin">{{ 'CREATION_ACCOUNT.SECOND_STEP.LOGIN' | translate }}</label>
          <input type="text" class="form-control form-control-edit value inputLogin" id="login" name="login" formControlName="login" />
          <div *ngIf="passwordForm.get('login').touched && passwordForm.get('login').invalid">
            <small class="form-text text-danger" *ngIf="passwordForm.get('login').errors.required">
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
            <small class="form-text text-danger" *ngIf="passwordForm.get('login').errors.pattern">
              {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
            </small>
          </div>
        </div>
        <div class="form-group inputLogin">
          <label for="password" class="form-label titre ">{{ 'CREATION_ACCOUNT.SECOND_STEP.MOT_DE_PASSE' | translate }}</label>
          <input
            type="password"
            class="form-control value inputLogin"
            id="password"
            name="password"
            formControlName="password"
            placeholder="******"
            (keyup)="controlPassWord()"
          />
          <div *ngIf="passwordForm.get('password').touched && passwordForm.get('password').invalid">
            <small class="form-text text-danger" *ngIf="passwordForm.get('password').errors.required">
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
          </div>
        </div>
        <div class="infos-utilisateur pt-3 ">
          <p class="pt-2 valid-mdp">{{ 'CREATION_ACCOUNT.SECOND_STEP.VALIDATION' | translate }}</p>
          <app-password-validation [passwordValue]="passwordForm.get('password').value" [affLastPassword]="false" [progressBar]="true">
          </app-password-validation>
        </div>
        <div class="form-group pt-3 inputLogin">
          <label for="confirmPassword" class="form-label titre ">{{
            'CREATION_ACCOUNT.SECOND_STEP.MOT_DE_PASSE_CONFIRM' | translate
          }}</label>
          <input
            type="password"
            class="form-control value inputLogin"
            id="confirmPassword"
            name="confirmPassword"
            formControlName="confirmPassword"
            placeholder="******"
            (keyup)="controlPassWord()"
          />
          <div *ngIf="passwordForm.get('confirmPassword').touched">
            <small
              class="form-text text-danger"
              *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required"
            >
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
            <small class="form-text text-danger" *ngIf="!passwordEqual">
              {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
            </small>
          </div>
        </div>
      </div>

      <div class="form-check">
        <input type="checkbox" class="form-check-input inputCGU" id="cguMobil" name="cgu" formControlName="cgu" />
        <label class="form-check-label" for="cguMobil">
          {{ 'CREATION_ACCOUNT.SECOND_STEP.CGU_CHECKBOX' | translate }}
          <a [routerLink]="['/cgu/', token]">{{ 'CREATION_ACCOUNT.SECOND_STEP.CGU_LINK' | translate }}</a>
        </label>
      </div>
    </form>
  </div>

  <div class="d-none d-md-block">
    <div class="d-flex justify-content-center align-items-center">
      <div class="btn-group-container actions">
        <button class="btn btn-secondary" (click)="back()">
          {{ 'CREATION_ACCOUNT.RETOUR' | translate }}
        </button>
        <nit-spinner-button
          (clicked)="submitForm()"
          label="{{ 'CREATION_ACCOUNT.VALIDER' | translate }}"
          [disabled]="passwordForm.invalid || !passwordEqual"
          [isLoading]="isLoading"
          css="btn btn-primary btn-large"
        >
        </nit-spinner-button>
      </div>
    </div>
  </div>

  <div class="d-md-none d-flex justify-content-center">
    <div class="actions btn-group-container">
      <div class="col-12">
        <nit-spinner-button
          (clicked)="submitForm()"
          label="{{ 'CREATION_ACCOUNT.VALIDER' | translate }}"
          [disabled]="passwordForm.invalid || !passwordEqual"
          [isLoading]="isLoading"
          css="btn btn-primary btn-large"
        >
        </nit-spinner-button>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-secondary mt-2" (click)="back()">
          {{ 'CREATION_ACCOUNT.RETOUR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
