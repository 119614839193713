import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SiloApiService } from '@services/siloApi.service';
import { GatewayService } from '@services/gateway.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { BehaviorSubject } from 'rxjs';
import { SessionQuery } from '@services/store/session/session.query';
import { ModalService } from '@shared/components/modal/modal.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-bimpli-change-id',
  templateUrl: './bimpli-change-id.component.html',
  styleUrls: ['./bimpli-change-id.component.scss']
})
export class BimpliChangeIdComponent implements OnInit {

  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Input() saml: string;
  @Input() applicationId: string;
  @Input() errorMessage: string;
  @Input() identifiantOK: boolean;
  @Input() login: string;
  @Input() messageErreur: string;
  @Input() isLoading: string;
  isLoadingComp = false;

  identifiantForm = this.fb.group({
    currentPassword: [undefined, Validators.required],
    newLogin: [undefined, Validators.required]
  });

  applicationCodeEnum = ApplicationCodeEnum;
  isSecondStep = false;
  hide = true;
  currentPasswordInvalid = false;
  isActualPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private valueCaptcha: string;

  constructor(private fb: FormBuilder, private siloApiService: SiloApiService,
              private gatewayService: GatewayService,
              private readonly tealiumService: TealiumService,
              private readonly sessionQuery: SessionQuery,
              private readonly modalService: ModalService,
              private readonly reCaptchaV3Service: ReCaptchaV3Service) {

  }

  ngOnInit() {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.profile.changePasswordPageLoad');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.profile.changePasswordPageLoad');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.profile.changePasswordPageLoad');
    }
  }

  goToSecondStep() {
    this.isLoadingComp = true;
    if (this.identifiantForm.get('currentPassword').valid) {
      this.gatewayService.getAccessToken().subscribe(authToken => {
          this.reCaptchaV3Service
            .execute('UserPasswordCheckAction')
            .pipe(finalize(() => {
              this.siloApiService.userPasswordCheck(this.login, this.identifiantForm.get('currentPassword').value,
                this.applicationId, authToken, this.valueCaptcha).subscribe(retour => {
                  this.currentPasswordInvalid = !retour.resultCheck;
                  this.isSecondStep = !this.currentPasswordInvalid;
                  this.isLoadingComp = false;
                },
                () => this.isLoadingComp = false);
            }))
            .subscribe((token) => (this.valueCaptcha = token));
        },
        () => this.isLoadingComp = false);
    }

  }

  back() {
    if (this.isSecondStep) {
      this.isSecondStep = false;
    } else {
      window.history.back();
    }
  }

  submitForm() {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.profile.validateChangeIdClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.profile.validateChangeIdClick');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.profile.validateChangeIdClick');
    }
    if (this.identifiantForm.valid) {
      this.submitted.emit(this.identifiantForm.getRawValue());
    }
  }

  closePopin(id: string){
    this.modalService.close(id);
  }
}
