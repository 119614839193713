import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { AuthToken } from '@shared/models/authtoken.model';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { ForgottenPassword } from '@shared/models/forgotten-password.model';
import { Observable, Subscription } from 'rxjs';
import { catchError, concatMap, finalize, tap } from 'rxjs/operators';
import { TealiumService } from '../../shared/services/tealium/tealium.service';
import { GroundTruthDataService } from '../../shared/services/ground-truth-data.service';
import { environment } from '../../../environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-confirmation-demande-mot-de-passe',
  templateUrl: './confirmation-demande-mot-de-passe.component.html',
  styleUrls: ['./confirmation-demande-mot-de-passe.component.scss']
})
export class ConfirmationDemandeMotDePasseComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;

  public password: FormControl;
  public confirm: FormControl;
  public ginsc: FormGroup;
  public messageErreur: string;
  private login: string;
  private hash: string;
  private applicationId: string;
  public waiting: boolean;
  public error: boolean;
  private userPasswordReissueId: string;
  public success: boolean;
  public lienInvalide: boolean;
  public typeToken: string;
  public language: string;
  public estReinit: boolean;
  resendOk = false;
  public estApetiz: boolean;
  public estApetizMobile: boolean;
  public ApplicationCodeEnum = ApplicationCodeEnum;
  private valueCaptcha: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly translateService: TranslateService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly tealiumService: TealiumService,
    private gtds: GroundTruthDataService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    this.lienInvalide = false;
    this.success = false;
    this.error = false;
    this.waiting = true;
    this.password = this.formBuilder.control('', [
      Validators.required
      /*Validators.pattern(/^[a-z0-9]([._-]?[a-z0-9])*@[a-z0-9]([._-]?[a-z0-9])*\.[a-z]{2,}$/i)*/
    ]);
    this.confirm = this.formBuilder.control('', [
      Validators.required,
      (ctrl) => {
        return ctrl.value && ctrl.value !== this.password.value ? { ident: true } : {};
      }
    ]);
    this.ginsc = this.formBuilder.group([this.password, this.confirm]);

    this.sessionStateService.setApplicationCodeFromForgottenPassword(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    this.applicationCode$.subscribe((code) => {
      switch (code) {
        case ApplicationCodeEnum.APZ:
          this.estApetiz = true;
          this.tealiumService.view('apz.web.forgottenPassword.newPasswordPageLoad');
          break;
        case ApplicationCodeEnum.APZ_MOBILE:
          this.estApetizMobile = true;
          this.tealiumService.view('apz.mobile.forgottenPassword.newPasswordPageLoad');
          break;
        case ApplicationCodeEnum.BENEFIT_BENEF:
          this.tealiumService.view('bimpli_benef.web.forgottenPassword.newPasswordPageLoad');
          break;
        case ApplicationCodeEnum.BENEFIT_FINANCEUR:
          this.tealiumService.view('bimpli_financeur.web.forgottenPassword.newPasswordPageLoad');
          break;
      }
    });

    const applicationCodeSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(tap((applicationCode: ApplicationCodeEnum) => (this.applicationId = applicationCode)))
      .subscribe();

    this.subscription.add(applicationCodeSubscription);

    const forgottenPasswordInfoSubscription = this.sessionQuery.selectedForgottenPasswordInfo
      .pipe(
        tap((forgottenPasswordInfo: ForgottenPassword) => {
          this.userPasswordReissueId = forgottenPasswordInfo.userId;
          this.login = forgottenPasswordInfo.login;
          this.typeToken = forgottenPasswordInfo.tokenType;
          this.hash = forgottenPasswordInfo.hash;
        })
      )
      .subscribe();

    this.subscription.add(forgottenPasswordInfoSubscription);

    this.estReinit = this.typeToken === 'reinit_mdp_idp';

   /*  this.gatewayService
    .getAccessToken()
    .pipe(
      concatMap((authToken: AuthToken) => {
        return this.siloApiService
          .verifierTokenDemandeReinitialisationMotDePasse(this.login, this.applicationId, this.hash, this.typeToken, authToken,"")
          .pipe(
            catchError((err) => {
              this.gererErreurs(err);
              throw err;
            }),
            finalize(() => (this.waiting = false))
          );
      })
    )
    .subscribe(); */

     this.gatewayService
    .getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
      .execute('ConnecterAction')
      .pipe(finalize(() => {
        this.siloApiService
            .verifierTokenDemandeReinitialisationMotDePasse
            (this.login, this.applicationId, this.hash, this.typeToken, authToken, this.valueCaptcha)
            .pipe(
              catchError((err) => {
                this.gererErreurs(err);
                throw err;
              }),
              finalize(() => (this.waiting = false))
            ).subscribe();
       }))
      .subscribe((token) => (this.valueCaptcha = token));
    },
    err => {
      this.waiting = false;
    });
  }

  public renvoyer() {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.forgottenPassword.sendEmailNewPasswordClick');
    } else if (this.estApetizMobile) {
      this.tealiumService.link('apz.mobile.forgottenPassword.sendEmailNewPasswordClick');
    }

    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
      .execute('ConnecterAction')
      .pipe(finalize(() => {
        return this.siloApiService
        .demandeReinitialisationMotDePasse(this.login, this.applicationId, authToken, this.valueCaptcha)
        .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
        .pipe(
          tap(() => (this.resendOk = true)),
          catchError((err) => {
            this.gererErreurs(err);
            throw err;
          })
        );
      }))
      .subscribe((token) => (this.valueCaptcha = token));
    },
      err => {

      });
  }

  public validerInscription(f: NgForm): void {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.forgottenPassword.validateNewPasswordClick');
    } else if (this.estApetizMobile) {
      this.tealiumService.link('apz.mobile.forgottenPassword.validateNewPasswordClick');
    }
    this.messageErreur = null;
    if (this.ginsc.valid) {

      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.reCaptchaV3Service
        .execute('ConnecterAction')
        .pipe(finalize(() => {
          this.siloApiService
          .reinitialisationMotDePasse
          (this.userPasswordReissueId, this.password.value, this.hash, this.typeToken, authToken, this.valueCaptcha)
          .subscribe(
            (retour) => (this.success = true),
            (error) => this.gererErreurs(error)
          )
        }))
        .subscribe((token) => (this.valueCaptcha = token));
      },
        err => {
          this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
        });
    }
  }

  public validerInscriptionAsp(password: string): void {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.forgottenPassword.validateNewPasswordClick');
    } else if (this.estApetizMobile) {
      this.tealiumService.link('apz.mobile.forgottenPassword.validateNewPasswordClick');
    }
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
      .execute('ConnecterAction')
      .pipe(finalize(() => {
        this.siloApiService
        .reinitialisationMotDePasse(this.userPasswordReissueId, password, this.hash, this.typeToken, authToken, this.valueCaptcha)
        .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
        .subscribe(
          (retour) => {
            this.success = true;
            if (this.estApetiz) {
              this.tealiumService.view('apz.web.forgottenPassword.newPasswordConfirmedPageLoad');
            } else if (this.estApetizMobile) {
              this.tealiumService.view('apz.mobile.forgottenPassword.newPasswordConfirmedPageLoad');
            }
            if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
              this.tealiumService.view('bimpli_benef.web.forgottenPassword.newPasswordConfirmedPageLoad');
            } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
              this.tealiumService.view('bimpli_financeur.web.forgottenPassword.newPasswordConfirmedPageLoad');
            } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
              this.tealiumService.view('bimpli_mobile.web.forgottenPassword.newPasswordConfirmedPageLoad');
            }
          },
          (error) => this.gererErreurs(error)
        )
      }))
      .subscribe((token) => (this.valueCaptcha = token));
    },
    err => {
      this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
    });

    /* this.gatewayService.getAccessToken().subscribe((authToken) =>
      this.siloApiService
        .reinitialisationMotDePasse(this.userPasswordReissueId, password, this.hash, this.typeToken, authToken)
        .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
        .subscribe(
          (retour) => {
            this.success = true;
            if (this.estApetiz) {
              this.tealiumService.view('apz.web.forgottenPassword.newPasswordConfirmedPageLoad');
            } else if (this.estApetizMobile) {
              this.tealiumService.view('apz.mobile.forgottenPassword.newPasswordConfirmedPageLoad');
            }
            if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
              this.tealiumService.view('bimpli_benef.web.forgottenPassword.newPasswordConfirmedPageLoad');
            } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
              this.tealiumService.view('bimpli_financeur.web.forgottenPassword.newPasswordConfirmedPageLoad');
            } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
              this.tealiumService.view('bimpli_mobile.web.forgottenPassword.newPasswordConfirmedPageLoad');
            }
          },
          (error) => this.gererErreurs(error)
        )
    ); */
  }

  public gererErreurs(error: HttpErrorResponse) {
    this.waiting = false;
    this.error = true;
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inattendue : ' + error.error.message;
    } else {
      if (error.error.errors === undefined) {
        this.messageErreur = 'Erreur inattendue : ' + error.message;
      } else {
        const errors: Erreurs = JSON.parse(error.error);
        switch (errors.errors[0].code) {
          case CodeErreurs.UNEXPECTED_011:
            this.messageErreur = 'Erreur technique';
            break;
          case CodeErreurs.FORBIDDEN_ACTION_010:
            this.messageErreur = `Le lien n'est plus valide`;
            this.lienInvalide = true;
            if (this.estApetiz) {
              this.tealiumService.view('apz.web.forgottenPassword.newPasswordExpiredPageLoad');
            } else if (this.estApetizMobile) {
              this.tealiumService.view('apz.mobile.forgottenPassword.newPasswordExpiredPageLoad');
            }
            break;
          case CodeErreurs.NOT_FOUND_028:
            this.messageErreur = `L'identifiant est inconnu`;
            break;
          default:
            this.messageErreur = 'Erreur inattendue : ' + errors.errors[0].code;
            break;
        }
      }
    }
  }

  loginWee() {
    if (this.applicationId === 'SiteWeezen') {
      this.gatewayService
        .authentificate(environment.clientIdWee, 'token', environment.redirectURIWee)
        .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
    }
  }

  setPageHtml(html: string): void {
    document.body.innerHTML = html;
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.submit();
  }

  loginAPZ() {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.forgottenPassword.returnToLoginClick');
      this.gatewayService
        .authentificate(environment.clientIdAPZ, 'token', environment.redirectURIAPZ)
        .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
    } else if (this.estApetizMobile) {
      this.tealiumService.link('apz.mobile.forgottenPassword.returnToLoginClick');
      window.location.href = 'app.beneficiaire.apetiz://accueil';
    }
  }


  loginAffilieApz() {
    this.gatewayService
      .authentificate(environment.clientIdAffilieAPZ, 'token', environment.redirectURLAffilieAPZ)
      .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loginBenefits() {
    if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
      this.gatewayService
        .authentificate(environment.clientIdBenefWeb, 'token', environment.redirectURLBenefWeb)
        .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
    }
    else if (this.applicationId === ApplicationCodeEnum.BENEFIT_FINANCEUR) {
      this.gatewayService
        .authentificate(environment.clientIdFinanceurWeb, 'token', environment.redirectURLFinanceurWeb)
        .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
    }
  }
}
