import {
    Validators,
    ValidatorFn,
    ValidationErrors,
    AbstractControl
} from '@angular/forms';

export const hasNumberRegex: RegExp = /\d/;
export const hasCapitalCaseRegex: RegExp = /[A-Z]/;
export const hasLowerCaseLetterRegex: RegExp = /[a-z]/;
export const hasSpecialCharacterRegex: RegExp = /[!?*/"_$µ()+=#@&-]/;

export function validatePassword(): ValidatorFn {
    return Validators.compose([
        Validators.required,
        patternValidator(hasNumberRegex, { hasNumber: true }),
        patternValidator(hasCapitalCaseRegex, { hasCapitalCase: true }),
        patternValidator(hasLowerCaseLetterRegex, { hasLowerCaseLetter: true }),
        patternValidator(hasSpecialCharacterRegex, { hasSpecialCharacter: true }),
        Validators.minLength(8),
        Validators.maxLength(15)
    ]);
}

export function patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            // if control is empty return no error
            return null;
        }

        // test the value of the control against the regexp supplied
        const valid = regex.test(control.value);

        // if true, return no error (no error), else return error passed in the second parameter
        return valid ? null : error;
    };
}
