<app-cesu-layout>
    <div class="row text-center pt-2 pt-md-4 pt-lg-5">
        <div class="col-12 cesu-page-title">
            {{ 'CESU.CHANGE_PASSWORD.SUCCESS.TITLE' | translate }}
        </div>
        <hr class="cesu-separator">
        <div class="col-12 pt-4 pt-lg-5">
            <button class="btn cesu-button cesu-flat-button"
                    (click)="goBack()">{{ 'CESU.CHANGE_PASSWORD.SUCCESS.BUTTON' | translate }}</button>
        </div>
    </div>
</app-cesu-layout>
