<nav>
  <div>apetiz</div>
</nav>

<section class="row justify-content-center align-items-center bg-white py-5">
  <div class="col-12 text-center">
    <h2 class="py-5">Votre compte a été créé avec succès</h2>
    <img src="./assets/apz/inscription_femme_pomme.png" />
  </div>

  <div class="carte">
    Rendez vous dès à présent dans votre espace pour découvrir vos avantages
    <br />
    <button (click)="goLogin()">
      JE ME CONNECTE
    </button>
  </div>
</section>
