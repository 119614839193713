import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Affiliate } from '@shared/models/affiliate';

@Component({
  selector: 'app-affiliate-confirm-list',
  templateUrl: './affiliate-confirm-list.component.html',
  styleUrls: ['./affiliate-confirm-list.component.scss']
})
export class AffiliateConfirmListComponent implements OnInit {
  @Input() affiliates: Affiliate[] = [];
  @Output() selectedAffiliates: EventEmitter<Affiliate[]> = new EventEmitter<Affiliate[]>();

  public form: FormGroup;

  get affiliateFormArray() {
    return this.form.controls.affiliates as FormArray;
  }

  get btnText(): string {
    const totalSelected = this.affiliateFormArray.controls
      .map((control) => control?.get('checked')?.value)
      .reduce((prev, next) => (next ? prev + next : prev), 0);

    if (totalSelected === 1) {
      return 'Confirmer la sélection ';
    }
    if (totalSelected > 1) {
      return `Confirmer les ${totalSelected} sélectionnés`;
    }

    return 'Confirmer';
  }

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      affiliates: new FormArray([], minSelectedCheckboxes(1))
    });
  }

  ngOnInit(): void {
    this.addAffiliateCheckboxes();
  }

  public location(affiliate: Affiliate): string[] {
    const locations = [];

    if (affiliate?.company?.companyName) {
      locations.push(affiliate?.company?.companyName);
    }
    if (affiliate?.address?.street) {
      locations.push(affiliate?.address?.street);
    }
    if (affiliate?.address?.locality) {
      locations.push(affiliate?.address?.locality);
    }
    if (affiliate?.address?.postalCode && affiliate?.address?.city) {
      locations.push(`${affiliate?.address?.postalCode}  ${affiliate?.address?.city}`);
    }

    return locations;
  }

  public codeCrt(affiliate: Affiliate): string {
    const affiliateNumber = affiliate?.number.toString();
    return `XX XXX ${affiliateNumber.substring(5)}`;
  }

  public changeCheckbox($event: any, index: number): void {
    if ($event.target.checked) {
      this.affiliateFormArray.controls[index]
        ?.get('code')
        ?.setValidators([Validators.required, Validators.pattern('^((?!\\*).)*$'), codeCrtValidator(index, this.affiliates)]);
    } else {
      this.affiliateFormArray.controls[index]?.get('code')?.clearValidators();
    }
    this.affiliateFormArray.controls[index]?.get('code')?.updateValueAndValidity();
  }

  public codeBlur(index: number): void {
    if (this.affiliateFormArray.controls[index].get('code')?.value) {
      this.affiliateFormArray.controls[index].patchValue({
        checked: true
      });
      this.affiliateFormArray.controls[index]
        ?.get('code')
        ?.setValidators([Validators.required, , Validators.pattern('^((?!\\*).)*$'), codeCrtValidator(index, this.affiliates)]);
    } else {
      this.affiliateFormArray.controls[index].patchValue({
        checked: false
      });
      this.affiliateFormArray.controls[index]?.get('code')?.clearValidators();
    }
    this.affiliateFormArray.controls[index]?.get('code')?.updateValueAndValidity();
  }

  public submit(): void {
    if (this.form.invalid) {
      return;
    }
    const selectedAffiliates = this.form.value.affiliates
      .map((item: { code: string; checked: boolean }, i: number) => (item?.checked ? this.affiliates[i] : null))
      .filter((v: Affiliate) => v !== null);
    this.selectedAffiliates.emit(selectedAffiliates);
  }

  private addAffiliateCheckboxes() {
    this.affiliates.forEach(() =>
      this.affiliateFormArray.push(
        new FormGroup({
          code: new FormControl(null),
          checked: new FormControl(false)
        })
      )
    );
  }
}

function codeCrtValidator(index: number, affiliates: Affiliate[]): ValidatorFn {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    if (affiliates[index]?.number?.toString() !== c.value) {
      return { codeInvalid: true };
    }
    return null;
  };
}

function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map((control) => control?.get('checked')?.value)
      .reduce((prev, next) => (next ? prev + next : prev), 0);

    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}
