import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-go-back-btn',
  templateUrl: './go-back-btn.component.html',
  styleUrls: ['./go-back-btn.component.scss']
})
export class GoBackBtnComponent {

  constructor() { }

  @Input() text!: string;
  @Output() goBack$ = new EventEmitter<void>();


}
