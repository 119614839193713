import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { combineLatest } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  private codAppliSubject = new Subject<string>();
  codAppli = this.codAppliSubject.asObservable();

  constructor(
    private readonly router: Router,
  ) {

  }

  setCodAppli(codAppli: string) {
    this.codAppliSubject.next(codAppli);
  }

  clearCodAppli() {
    this.codAppliSubject.next('');
  }

  /**
   * Change location and conserve the url format
   * @param currentLocation the current route containing specific parameter
   * @param nextLocation the new page that the user want to access
   */
  public goToNextLocation(currentLocation: ActivatedRoute, nextLocation: RoutesEnum) {
    combineLatest([
      currentLocation.paramMap,
      currentLocation.queryParams,
    ])
      .pipe(
        map(([params, queryParams]) => {
          return [params.get('saml'), queryParams.hash];
        }),
        tap(([saml, hash]) => {
          if (saml && hash) {
            // Format used in the forgotten password page
            this.router.navigate([nextLocation, saml], {
              queryParams: {
                hash,
              },
            });
          } else if (saml && !hash) {
            // The default format used throughout the application
            this.router.navigate([nextLocation, saml]);
          } else if (!saml && hash) {
            // Format used in the activate account page
            this.router.navigate([nextLocation], {
              queryParams: {
                hash,
              },
            });
          }
        }),
        catchError((err) => {
          throw err;
        }),
      )
      .subscribe();
  }
}
