<div *ngIf="!success" class="subHeaderError d-flex align-items-center justify-content-center">
  <i class="icon-info mr-3"></i>
  <p>{{ messageErreur }}</p>
</div>

<div class="detail-container container">
  <p [ngClass]="inlined ? 'text-center' : 'text-left'">{{ 'APZ.CREATION_ACCOUNT.FIRST_STEP.CARTE' | translate }}</p>

  <form name="form" role="form" (ngSubmit)="send()" [formGroup]="cardForm" novalidate>
    <div class="row justify-content-md-center">
      <div class="col-sm-12 col-md-8 text-center pb-4">
        <div class="d-flex d-flex justify-content-center">
          <app-input-credit-card formControlName="numCard"></app-input-credit-card>
        </div>
        <div class="text-center" *ngIf="cardForm.get('numCard').touched && cardForm.get('numCard').invalid">
          <small class="form-text text-danger" *ngIf="cardForm.get('numCard').errors?.pattern">
            {{ 'COMMON.FORM.NUM_CARTE_PATTERN' | translate }}
          </small>
          <small class="form-text text-danger" *ngIf="cardForm.get('numCard').errors?.required">
            {{ 'COMMON.FORM.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 text-center">
        <div class="d-flex justify-content-center">
          <app-input-credit-card-date formControlName="expirationDate"></app-input-credit-card-date>
        </div>
        <div class="text-center" *ngIf="cardForm.get('expirationDate').touched && cardForm.get('expirationDate').invalid">
          <small class="form-text text-danger" *ngIf="cardForm.get('expirationDate').errors?.pattern">
            {{ 'COMMON.FORM.DATE_PATTERN' | translate }}
          </small>
          <small class="form-text text-danger" *ngIf="cardForm.get('expirationDate').errors?.required">
            {{ 'COMMON.FORM.REQUIRED' | translate }}
          </small>
        </div>
      </div>
    </div>

    <div class="d-none d-md-block">
      <div class="btn-group-container d-flex justify-content-center align-items-center">
        <button class="btn btn-action" type="submit" [disabled]="cardForm.invalid">
          {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
        </button>
      </div>
    </div>

    <div class="d-md-none btn-group-container text-center">
      <button class="btn btn-action" type="submit" [disabled]="cardForm.invalid">
        {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
      </button>
    </div>
  </form>
</div>
