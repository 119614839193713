import { Injectable } from '@angular/core';
import { AuthToken } from '../models/authtoken.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class GatewayService {

    public constructor(private http: HttpClient) { }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            alert(`An error occurred: ${error.error.message}`);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            alert(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

    public getAccessToken(): Observable<AuthToken> {

        const url = environment.gatewayEndPointAS + '/api/oauth/token';

        // const oauth2_client_id = '64380c4d-2677-4205-b926-b77f44f77c4c';
        // const oauth2_client_secret = '9e3886a4-4ba2-463e-a856-665ab8524e71';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: environment.gatewayBasicAuthAS,
            })
        };

        const body = new URLSearchParams();
        body.set('grant_type', 'client_credentials');

        return this.http.post<AuthToken>(url, body.toString(), httpOptions);
    }

    public authentificate(clientId: string, responseType: string, redirect: string): Observable<string> {

        let params = new HttpParams();
        params = params.set('client_id', clientId);
        params = params.set('redirect_uri', redirect !== undefined ? redirect : environment.redirectURI);
        params = params.set('response_type', responseType);
        params = params.set('grant_type', 'urn:ietf:params:oauth:grant-type:saml2-bearer');

        return this.http.get('/api/oauth/nit/authorize', { params, responseType: 'text' }).pipe(catchError(this.handleError));
    }

    public authorize(SAMLResponse: string): Observable<string> {

      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    };

      const body = new URLSearchParams();

      body.set('SAMLResponse', SAMLResponse);

      const url = environment.gatewayEndPointAS + '/api/oauth/nit/authorize';

      return this.http.post<string>(url,body.toString(), httpOptions);

    }

}
