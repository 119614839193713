import { Component, AfterViewInit, ElementRef, EventEmitter, Output, Input, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
// import { Favicons } from '../../../shared/favicons';
import { Subscription } from 'rxjs';
import { GroundTruthDataService } from '../../../shared/services/ground-truth-data.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { WeezenComponent } from '../../weezen/weezen.component';


@Component({
  selector: 'app-asp',
  templateUrl: './asp.component.html',
  styleUrls: ['../../../../assets/css/asp-global.scss', './asp.component.scss']
})
export class AspComponent extends WeezenComponent implements AfterViewInit, OnDestroy {
  @Output() signIn: EventEmitter<any> = new EventEmitter();
  @Output() register: EventEmitter<any> = new EventEmitter();
  @Output() resetPassword: EventEmitter<any> = new EventEmitter();
  @Output() resetLogin: EventEmitter<any> = new EventEmitter();
  @Input() errorMessage: string;
  @ViewChild('menuEnglish', { static: true }) menuEnglish: ElementRef;
  @ViewChild('menuFrench', { static: true }) menuFrench: ElementRef;

  isLoading = false;
  mySubscription = new Subscription();

  signInForm = this.fb.group({
    login: [undefined, Validators.compose([Validators.required])],
    password: [undefined, Validators.required],
  });

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private gtds: GroundTruthDataService,
    // fav: Favicons
  ) {
    // super(fav);
    super();
    const isSpinnerActivated = this.gtds.getIsSpinnerActivated().pipe(
      distinctUntilChanged()
    ).subscribe(response => { this.isLoading = response; });

    this.mySubscription.add(isSpinnerActivated);
  }

  ngAfterViewInit() {
    setTimeout(() => this.elementRef.nativeElement.querySelector('#login').focus(), 0);
  }

  setLang(language: string) {
    if (language === 'fr') {
      this.menuFrench.nativeElement.className = 'pointer';
      this.menuEnglish.nativeElement.className = 'textLight pointer';
    } else {
      this.menuFrench.nativeElement.className = 'textLight pointer';
      this.menuEnglish.nativeElement.className = 'pointer';
    }
    this.translateService.use(language);
  }

  cancel() {
    this.errorMessage = null;
    this.signInForm.patchValue({
      username: null,
      password: null
    });
  }

  submit() {
    this.gtds.setIsSpinnerActivated(true);
    this.errorMessage = null;
    if (this.signInForm.valid) {
      this.signIn.emit(this.signInForm.getRawValue());
    } else {
      this.errorMessage = this.translateService.instant('ASP.INVALID_FORM');
    }
  }

  firstConnexion() {
    this.errorMessage = null;
    this.register.emit(null);
  }

  requestResetPassword() {
    this.resetPassword.emit(null);
  }

  requestResetLogin() {
    this.resetLogin.emit(null);
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }
}
