import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TealiumService } from '@services/tealium/tealium.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';


@Component({
  selector: 'app-cesu-create-account-mail',
  templateUrl: './cesu-create-account-mail.component.html',
  styleUrls: ['./cesu-create-account-mail.component.scss', '../../../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuCreateAccountMailComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public mailIcon;

  public saml: string;

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly router: Router,
    private readonly tealiumService: TealiumService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
  ) {
  }

  public ngOnInit(): void {
    this.mailIcon = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cesu/mail_icon.svg');

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap(saml => this.saml = saml)).subscribe();

    this.subscription.add(samlSubscription);

    this.tealiumService.view('cesu.registrationProcess.accountCreatedPageLoad');
  }

  public goToLogin(): void {
    this.tealiumService.link('cesu.registrationProcess.accountCreatedReturnLoginClick');
    this.sessionStateService.setRegistrationStep(RegistrationStepEnum.NONE);
    this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
