import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CadoService } from '@services/cado.service';
import { GatewayService } from '@services/gateway.service';
import { ReferentialService } from '@services/referential.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { emailRegEx } from '@shared/helpers/regexp.helpers';
import { ReadCitiesSignature } from '@shared/models/read-cities-signature.model';
import { ReCaptchaV3Service, RecaptchaComponent } from 'ng-recaptcha';
import { UserCriteria } from 'nit-angular-lib';
import { interval } from 'rxjs';
import { finalize, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ModalService } from '../../../../shared/components/modal/modal.service';
import { AuthToken } from '../../../../shared/models/authtoken.model';
import { SiloApiService } from '../../../../shared/services/siloApi.service';

@Component({
  selector: 'app-first-step-bimpli-financeur-cado',
  templateUrl: './first-step-bimpli-financeur-cado.component.html',
  styleUrls: ['./first-step-bimpli-financeur-cado.component.scss']
})
export class FirstStepBimpliFinanceurCadoComponent implements OnInit {
  @ViewChild('captchaRef') public captchaRef: RecaptchaComponent;

  constructor(
    private readonly gatewayService: GatewayService,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
    private readonly siloApiService: SiloApiService,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly referentielService: ReferentialService,
    private readonly cadoService: CadoService,
    private readonly formBuilder: FormBuilder
  ) {
    this.isCreation = localStorage.getItem('type') === 'creation';
  }

  @Input() saml: string;
  isLoading = false;
  isFirstStep = true;
  isAlreadyExist = false;
  timer = 10;
  messageErreur: string;
  isCreation: boolean;
  cities = new Set<string>();
  googleKey = environment.GOOGLE_RECAPTCHA_CADO;
  captchaCADO: string;

  enterpriseInfoForm = this.formBuilder.group({
    denomination: [''],
    siret: ['', [Validators.pattern('[0-9]{14}')]],
    codepostal: ['', [Validators.minLength(5), Validators.maxLength(5)]],
    adresse: [''],
    ville: ['']
  });

  emailForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(emailRegEx)]]
  });
  token: AuthToken;
  valueCaptcha: string;

  ngOnInit(): void {}

  goToStep2(): void {
    if (this.enterpriseInfoForm.valid) {
      this.isFirstStep = false;
    }
  }

  validateFirstStep(): void {
    if (this.emailForm.valid) {
      this.isLoading = true;
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.token = authToken;
        this.recaptchaV3Service
          .execute('CreationAccountAction')
          .pipe(finalize(() => this.readUserByCriteria()))
          .subscribe((token) => (this.valueCaptcha = token));
      });
    }
  }

  private readUserByCriteria(): void {
    const userCriteria = new UserCriteria();
    userCriteria.login = this.emailForm.get('email').value;
    userCriteria.applicationId = ApplicationCodeEnum.BENEFIT_FINANCEUR;
    this.siloApiService.readUserByCriteria(this.token, userCriteria, this.valueCaptcha).subscribe((retour) => {
      if (retour.items.length === 0) {
        localStorage.setItem('login', this.emailForm.get('email').value);
        this.isFirstStep = false;
      } else {
        const user = retour.items[0];
        if (user.active === 'PENDING_ACTIVATION') {
          this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}/cado`]);
        } else if (user.active === 'ACTIVE') {
          localStorage.removeItem('login');
          localStorage.removeItem('password');
          this.isAlreadyExist = true;
          interval(1000)
            .pipe(takeWhile(() => this.timer >= 0))
            .subscribe(() => {
              this.timer = this.timer - 1;
              if (this.timer === 0) {
                this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
              }
            });
        }
      }
      this.isLoading = false;
    });
  }

  readCitiesByZipCode(): void {
    const signature = new ReadCitiesSignature();
    signature.postalCode = this.enterpriseInfoForm.get('codepostal').value;
    signature.sort = 'city,desc';

    if (!signature.postalCode || (signature.postalCode && signature.postalCode.length < 5)) {
      return;
    }

    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.token = authToken;
      this.referentielService.readCities(signature, authToken).subscribe(
        (villes) => {
          const currentCity: string = this.enterpriseInfoForm.get('ville').value;

          this.cities.clear();

          villes.items.forEach((elt) => {
            this.cities.add(elt.city);
          });

          let containsCurrentCity = false;
          this.cities.forEach((elt) => {
            if (elt === currentCity) {
              containsCurrentCity = true;
            }
          });

          if (!containsCurrentCity) {
            this.enterpriseInfoForm.get('ville').setValue(null);
          }

          if (this.cities && this.cities.size === 1) {
            const iterator = this.cities.values();
            this.enterpriseInfoForm.get('ville').setValue(iterator.next().value);
          }
        },
        (errors) => {
          /* gestion des erreurs */
        }
      );
    });
  }

  redirectToLogin() {
    this.timer = 0;
    this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
  }

  numericPhoneOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  back(): void {
    if (this.isFirstStep) {
      window.history.back();
    } else {
      this.isFirstStep = true;
    }
  }

  submitEnterpriseForm(): void {
    if (this.enterpriseInfoForm.valid) {
      if (this.isCreation) {
        this.captchaRef.execute();
      } else {
        this.isLoading = true;
        const siret = this.enterpriseInfoForm.get('siret').value;

        this.gatewayService
          .getAccessToken()
          .pipe(
            switchMap((authToken) => {
              this.token = authToken;
              // On regarde s'il s'agit d'un affilié Bimpli
              return this.siloApiService.readAffiliatesBySiret(authToken, siret);
            }),
            tap((affiliates) => {
              if (!!affiliates?.length) {
                this.modalService.open('intentModal');
              } else {
                this.captchaRef.execute();
              }
            }),
            take(1),
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe();
      }
    }
  }

  validateEnterprise(captchaResponse: string): void {
    this.captchaCADO = captchaResponse;
    if (this.enterpriseInfoForm.valid) {
      this.isLoading = true;
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.token = authToken;
        this.siloApiService.readCustomer(authToken, this.enterpriseInfoForm.get('siret').value, 'NIT').subscribe((customers) => {
          if (customers.items.length === 0) {
            this.recaptchaV3Service
              .execute('FindLoginAndSiretAction')
              .pipe(finalize(() => this.readUserAccountByriteria()))
              .subscribe((token) => (this.valueCaptcha = token));
          } else {
            this.messageErreur = 'Le siret est déjà connu comme client Bimpli. Merci de vous authentifier avec votre identifiant bimpli';
            this.modalService.open('errorModal');
            this.isLoading = false;
          }
        });
      });
    }
  }

  readUserAccountByriteria(): void {
    this.cadoService
      .readUserAccountsByCriteria(this.token, this.enterpriseInfoForm.get('siret').value, this.captchaCADO)
      .subscribe((users) => {
        localStorage.setItem('entreprise', JSON.stringify(this.enterpriseInfoForm.getRawValue()));
        if (users.items.length === 0 && this.isCreation) {
          this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/${this.saml}/cado`]);
        } else {
          // On vérifie que l'utilisateur saisi à l'étape 1 est présent dans la liste sinon on affiche un message d'erreur
          const userFilter = users.items.filter((user: any) => user.login === this.emailForm.get('email').value);
          if (userFilter.length === 0) {
            localStorage.setItem('type', 'creation');
            this.isCreation = true;
          } else {
            localStorage.setItem('type', 'synchronisation');
            this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/${this.saml}/cado`]);
          }

          this.isLoading = false;
        }
      });
  }

  closePopin(id: string): void {
    this.modalService.close('errorModal');
  }

  goToSignupAffiliate(): void {
    this.modalService.close('intentModal');
    this.router.navigate([`${RoutesEnum.SIGNUP_AFFILIE_FIRST_STEP}/${environment.samlAffilie}`]);
  }

  confirmIntent(): void {
    this.modalService.close('intentModal');
    this.captchaRef.execute();
  }
}
