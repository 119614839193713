<div *ngIf="estApetizMobile" class="img-fluid div-login">
  <app-snackbar></app-snackbar>
  <div class="container">
    <form name="passwordForm" [formGroup]="forgottenLoginForm" class="d-block mx-auto">
      <div class="form-row text-center">
        <div class="col-12 ">
          <div class="img-fluid apetiz-logo"></div>
          <div class="title pt-5 pb-4">
            Identifiant oublié
          </div>
        </div>
      </div>
      <div *ngIf="!notificationEnvoyer">
        <div class=" text-center">
          <p>
            Vous avez oublié votre identifiant ?<br />
            Saisissez ci-dessous votre email et validez<br />
            Vous allez recevoir votre identifiant.
          </p>
        </div>
        <div class="w-100 row justify-content-center py-4">
          <input
            type="text"
            class="form-control mail"
            id="identifiant"
            name="identifiant"
            formControlName="identifiant"
            placeholder="Email"
          />
          <div class="w-100" *ngIf="forgottenLoginForm.get('identifiant').touched && forgottenLoginForm.get('identifiant').errors">
            <small class="form-text text-danger text-center" *ngIf="forgottenLoginForm.get('identifiant').errors.required">
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
            <small class="form-text text-danger text-center" *ngIf="forgottenLoginForm.get('identifiant').errors.pattern">
              {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
            </small>
          </div>
        </div>
        <div class="text-center py-3">
          <nit-spinner-button
            (clicked)="submit()"
            label="{{ 'CREATION_ACCOUNT.VALIDER' | translate | uppercase }}"
            [isLoading]="isLoading"
            [disabled]="forgottenLoginForm.invalid"
            css="btn btn-action px-5"
          >
          </nit-spinner-button>
        </div>
      </div>
      <div *ngIf="notificationEnvoyer">
        <h4 class="text-center">Demande envoyée avec succès !</h4>
        <img class="img-fluid py-4" src="assets/apz/pointilles_paperplane.svg" />
        <div class="row justify-content-center encart p-3">
          <div *ngIf="isLoading" class="d-block text-center">
            <div class="spinner-border" role="status"></div>
          </div>
          <p *ngIf="!isLoading" class="subtitle ">
            Vous trouverez dans votre boite de réception un email avec votre identifiant. <br />
            Si vous ne recevez pas notre email, vérifiez dans les courriers indésirables de votre messagerie ou
            <a [routerLink]="" (click)="recall()">cliquez-ici</a> pour recevoir un nouvel email
          </p>
        </div>
      </div>
    </form>
  </div>
</div>
<div *ngIf="!estApetizMobile">
  <app-apz-layout [title]="'Identifiant oublié'" [saml]="saml">
    <form class="row justify-content-center py-3" name="passwordForm" [formGroup]="forgottenLoginForm">
      <!-- encart gauche -->
      <app-ouvre-apetiz></app-ouvre-apetiz>

      <!-- encart droite -->
      <section class="col carte">
        <app-snackbar></app-snackbar>
        <div *ngIf="!notificationEnvoyer">
          <div class="pb-3 text-center">
            <p>
              Vous avez oublié votre identifiant ?<br />
              Saisissez ci-dessous votre email et validez<br />
              Vous allez recevoir votre identifiant.
            </p>
          </div>
          <div class="w-100 row justify-content-center py-4">
            <input
              type="text"
              class="form-control mail"
              id="identifiant"
              name="identifiant"
              formControlName="identifiant"
              placeholder="Email"
            />
            <div class="w-100" *ngIf="forgottenLoginForm.get('identifiant').touched && forgottenLoginForm.get('identifiant').errors">
              <small class="form-text text-danger text-center" *ngIf="forgottenLoginForm.get('identifiant').errors.required">
                {{ 'COMMON.FORM.REQUIRED' | translate }}
              </small>
              <small class="form-text text-danger text-center" *ngIf="forgottenLoginForm.get('identifiant').errors.pattern">
                {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
              </small>
            </div>
          </div>
          <div class="text-center py-3">
            <nit-spinner-button
              (clicked)="submit()"
              label="{{ 'CREATION_ACCOUNT.VALIDER' | translate | uppercase }}"
              [isLoading]="isLoading"
              [disabled]="forgottenLoginForm.invalid"
              css="btn btn-action px-5"
            >
            </nit-spinner-button>
          </div>
        </div>
        <div *ngIf="notificationEnvoyer">
          <h4 class="text-center">Demande envoyée avec succès !</h4>
          <img class="img-fluid py-4" src="assets/apz/pointilles_paperplane.svg" />
          <div class="row justify-content-center encart p-3">
            <div *ngIf="isLoading" class="d-block text-center">
              <div class="spinner-border" role="status"></div>
            </div>
            <p *ngIf="!isLoading" class="subtitle ">
              Vous trouverez dans votre boite de réception un email avec votre identifiant. <br />
              Si vous ne recevez pas notre email, vérifiez dans les courriers indésirables de votre messagerie ou
              <a [routerLink]="" (click)="recall()">cliquez-ici</a> pour recevoir un nouvel email
            </p>
          </div>
        </div>
      </section>
    </form>
  </app-apz-layout>
</div>
