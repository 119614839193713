<div *ngIf="estApetizMobile" class="img-fluid div-login">
  <app-snackbar></app-snackbar>
  <div class="container">
    <form name="passwordForm" [formGroup]="forgottenPwdForm" class="d-block mx-auto">
      <div class="form-row text-center">
        <div class="col-12 ">
          <div class="img-fluid apetiz-logo"></div>
          <div class="title pt-5 pb-4">
            Mot de passe oublié
          </div>
        </div>
      </div>
      <div *ngIf="!notificationEnvoyer">
        <div *ngIf="!isSecondStep">
          <div class=" text-center">
            <p>
              {{ 'APZ.DEMANDE_MDP.INSTRUCTION_PART1' | translate }}
            </p>
          </div>
          <div class="w-100 row justify-content-center py-4">
            <input
              type="text"
              class="form-control mail"
              id="identifiant"
              name="identifiant"
              formControlName="identifiant"
              placeholder="Identifiant"
            />
            <div class="w-100" *ngIf="forgottenPwdForm.get('identifiant').touched">
              <small
                class="form-text text-danger text-center"
                *ngIf="forgottenPwdForm.get('identifiant').errors && forgottenPwdForm.get('identifiant').errors.required"
              >
                {{ 'COMMON.FORM.REQUIRED' | translate }}
              </small>
            </div>
          </div>
        </div>
        <div *ngIf="isSecondStep">
          <div class="pb-3 text-center">
            <p>
              {{ 'APZ.DEMANDE_MDP.INSTRUCTION_PART2' | translate }}
            </p>
          </div>
          <h4 class="text-center">{{ secretQuestion.label }}</h4>
          <div class="col-12 col-sm">
            <div class="w-100 row justify-content-center py-4">
              <input
                type="text"
                class="form-control question"
                id="reponse"
                name="response"
                formControlName="response"
                placeholder="Ma réponse"
              />
              <div class="w-100" *ngIf="forgottenPwdForm.get('response').touched">
                <small
                  class="form-text text-danger text-center"
                  *ngIf="forgottenPwdForm.get('response').errors && forgottenPwdForm.get('response').errors.required"
                >
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="form-text text-danger text-center" *ngIf="false">
                  {{ 'COMMON.FORM.SECRET_QUESTION.REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!isSecondStep" class="text-center py-3">
          <nit-spinner-button
            (clicked)="goToSecondStep()"
            label="{{ 'CREATION_ACCOUNT.VALIDER' | translate | uppercase }}"
            [isLoading]="isLoading"
            [disabled]="this.forgottenPwdForm.get('identifiant').invalid"
            css="btn btn-action px-5"
          >
          </nit-spinner-button>
        </div>
        <div *ngIf="isSecondStep" class="text-center py-3">
          <nit-spinner-button
            (clicked)="validateSecondStep()"
            label="{{ 'CREATION_ACCOUNT.VALIDER' | translate | uppercase }}"
            [isLoading]="isLoading"
            [disabled]="forgottenPwdForm.invalid"
            css="btn btn-action px-5"
          >
          </nit-spinner-button>
        </div>
      </div>
      <div *ngIf="notificationEnvoyer">
        <h4 class="text-center">Demande de réinitialisation envoyée avec succès !</h4>
        <img class="img-fluid py-4" src="assets/apz/pointilles_paperplane.svg" />
        <div class="row justify-content-center encart p-3">
          <div *ngIf="isLoading" class="d-block text-center">
            <div class="spinner-border" role="status"></div>
          </div>
          <p *ngIf="!isLoading" class="subtitle ">
            Vous trouverez dans votre boite de réception, {{ receiver }}, un email avec un lien sécurisé pour modifier votre mot de passe.
            <br />
            Si vous ne recevez pas notre email, vérifiez dans les courriers indésirables de votre messagerie ou
            <a [routerLink]="" (click)="recall()">cliquez-ici</a> pour recevoir un nouvel email
          </p>
        </div>
      </div>
    </form>
  </div>
</div>
<div *ngIf="!estApetizMobile">
  <app-apz-layout [title]="'Mot de passe oublié'" [saml]="saml">
    <form class="row justify-content-center py-3" name="passwordForm" [formGroup]="forgottenPwdForm">
      <!-- encart gauche -->
      <app-ouvre-apetiz></app-ouvre-apetiz>

      <!-- encart droite -->
      <section class="col carte">
        <app-snackbar></app-snackbar>
        <div *ngIf="!notificationEnvoyer">
          <div *ngIf="!isSecondStep">
            <div class="pb-3 text-center">
              <p>
                {{ 'APZ.DEMANDE_MDP.INSTRUCTION_PART1' | translate }}
              </p>
            </div>
            <div class="w-100 row justify-content-center py-4">
              <input
                type="text"
                class="form-control mail"
                id="identifiant"
                name="identifiant"
                formControlName="identifiant"
                placeholder="Identifiant"
              />
              <div class="w-100" *ngIf="forgottenPwdForm.get('identifiant').touched">
                <small
                  class="form-text text-danger text-center"
                  *ngIf="forgottenPwdForm.get('identifiant').errors && forgottenPwdForm.get('identifiant').errors.required"
                >
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div *ngIf="isSecondStep">
            <div class="pb-3 text-center">
              <p>
                {{ 'APZ.DEMANDE_MDP.INSTRUCTION_PART2' | translate }}
              </p>
            </div>
            <h4 class="text-center">{{ secretQuestion.label }}</h4>
            <div class="col-12 col-sm">
              <div class="w-100 row justify-content-center py-4">
                <input
                  type="text"
                  class="form-control question"
                  id="reponse"
                  name="response"
                  formControlName="response"
                  placeholder="Ma réponse"
                />
                <div class="w-100" *ngIf="forgottenPwdForm.get('response').touched">
                  <small
                    class="form-text text-danger text-center"
                    *ngIf="forgottenPwdForm.get('response').errors && forgottenPwdForm.get('response').errors.required"
                  >
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                  </small>
                  <small class="form-text text-danger text-center" *ngIf="false">
                    {{ 'COMMON.FORM.SECRET_QUESTION.REQUIRED' | translate }}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isSecondStep" class="text-center py-3">
            <nit-spinner-button
              (clicked)="goToSecondStep()"
              label="{{ 'CREATION_ACCOUNT.VALIDER' | translate | uppercase }}"
              [isLoading]="isLoading"
              [disabled]="this.forgottenPwdForm.get('identifiant').invalid"
              css="btn btn-action px-5"
            >
            </nit-spinner-button>
          </div>
          <div *ngIf="isSecondStep" class="text-center py-3">
            <nit-spinner-button
              (clicked)="validateSecondStep()"
              label="{{ 'CREATION_ACCOUNT.VALIDER' | translate | uppercase }}"
              [isLoading]="isLoading"
              [disabled]="forgottenPwdForm.invalid"
              css="btn btn-action px-5"
            >
            </nit-spinner-button>
          </div>
        </div>
        <div *ngIf="notificationEnvoyer">
          <h4 class="text-center">Demande de réinitialisation envoyée avec succès !</h4>
          <img class="img-fluid py-4" src="assets/apz/pointilles_paperplane.svg" />
          <div class="row justify-content-center encart p-3">
            <div *ngIf="isLoading" class="d-block text-center">
              <div class="spinner-border" role="status"></div>
            </div>
            <p *ngIf="!isLoading" class="subtitle ">
              Vous trouverez dans votre boite de réception, {{ receiver }}, un email avec un lien sécurisé pour modifier votre mot de passe.
              <br />
              Si vous ne recevez pas notre email, vérifiez dans les courriers indésirables de votre messagerie ou
              <a [routerLink]="" (click)="recall()">cliquez-ici</a> pour recevoir un nouvel email
            </p>
          </div>
        </div>
      </section>
    </form>
  </app-apz-layout>
</div>
