<ng-container
    *ngIf="!sso && (
        (applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE)"
>
    <app-apz-login
        [messageErreur]="messageErreur"
        [isMobile]="(applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE"
        [isSubmitted]="isSubmitted"
        [isLoading]="isLoading"
        [saml]="saml"
        (triggerForgotLogin)="forgotLogin()"
        (triggerForgotPassword)="forgotPassword()"
        (triggerRegister)="register()"
        (triggerSubmit)="submit($event.login, $event.password)"
    ></app-apz-login>
</ng-container>
<ng-container
  *ngIf="
  !sso && (
    (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE ||
    (applicationCode$ | async) === ApplicationCodeEnum.APZ ||
    (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF ||
    (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE ||
    (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR ||
    (applicationCode$ | async) === ApplicationCodeEnum.CESU
   )
  ">
  <app-bimpli-login-component
    [messageErreur]="messageErreur"
    [isMobile]="((applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE)"
    [isSubmitted]="isSubmitted"
    [isLoading]="isLoading"
    [saml]="saml"
    [libelleType]="libelleType"
    [otp]="otp"
    [askOTP]="askOTP"
    [otpType]="otpType"
    [maskedEmail]="maskedEmail"
    [maskedPhone]="maskedPhone"
    (triggerForgotLogin)="forgotLogin()"
    (triggerForgotPassword)="forgotPassword()"
    (triggerRegister)="register()"
    (triggerSubmit)="submit($event.login, $event.password, $event.remember, $event.otpCode)">
  </app-bimpli-login-component>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN">
  <app-asp
    (signIn)="submit($event.login, $event.password)"
    (register)="register()"
    (resetPassword)="forgotPassword()"
    (resetLogin)="forgotLogin()"
    [errorMessage]="messageErreur">
  </app-asp>
  <app-footer></app-footer>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.AFFILIE_APZ">
    <app-affilie-apz-login
        (signIn)="submit($event.login, $event.password)"
        (register)="register()"
        (resetPassword)="forgotPassword()"
        [errorMessage]="messageErreur"
        [isAlreadyActived]="isAlreadyActived"
    >
    </app-affilie-apz-login>
</ng-container>
