import { AfterViewInit, Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SiloApiService } from '@services/siloApi.service';
import { ModalService } from '@shared/components/modal/modal.service';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { emailRegEx, nameRegEx } from '@shared/helpers/regexp.helpers';
import { AuthToken } from '@shared/models/authtoken.model';
import { confirmationPasswordIsEqualValidator } from '@shared/validators/custom.validators';
import { sha256 } from 'js-sha256';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CreateTermsAndConditions } from 'nit-angular-lib';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ApplicationCodeEnum } from '../../../../shared/enums/application-code.enum';
import { GatewayService } from '../../../../shared/services/gateway.service';
import { TranslateService } from '@ngx-translate/core';
import { sha512 } from 'js-sha512';

@Component({
  selector: 'app-second-step-bimpli-financeur-cado',
  templateUrl: './second-step-bimpli-financeur-cado.component.html',
  styleUrls: ['./second-step-bimpli-financeur-cado.component.scss']
})
export class SecondStepBimpliFinanceurCadoComponent implements OnInit, DoCheck, AfterViewInit {

  private readonly SECTEUR_PUBLIC = 'PUBLIC_SECTOR';

  @Input() saml: string;

  listType = ['WORK_COUNCIL', 'ENTERPRISE', 'PUBLIC_SECTOR'];

  token: AuthToken;
  userId: number;
  isLoading = false;
  affCodeActivation = false;
  valueCaptcha: string;
  chorusPublicCheckError = false;
  chorusPriveCheckError = false;


  constructor( private readonly formBuilder: FormBuilder,
               private readonly router: Router,
               private readonly modalService: ModalService,
               private readonly gatewayService: GatewayService,
               private readonly siloApiService: SiloApiService,
               private readonly reCaptchaV3Service: ReCaptchaV3Service,
               private readonly translateService: TranslateService
              ) {
    this.step = 1;
  }

  messageErreur: string;
  step: number;
  scheduledStartDate: any;
  individualInfosForm = this.formBuilder.group({
    title: ['MR', []],
    firstName: ['',
      [
        Validators.required,
        Validators.maxLength(30)
      ]],
    lastName: ['',
      [
        Validators.required,
        Validators.maxLength(30)
      ]],
    type: ['WORK_COUNCIL', [Validators.required]],
    numMarche: ['', [Validators.maxLength(10)]],
  });

  enterpriseForm = this.formBuilder.group({
    naf: [''],
    effectif: ['']
  });

  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  emailFormControl = this.formBuilder.control(localStorage.getItem('login'), [Validators.required, Validators.pattern(emailRegEx)]);
  passwordFormControl = this.formBuilder.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(60)]);
  createAccountForm = this.formBuilder.group({
    login: this.emailFormControl,
    password: this.passwordFormControl,
    acceptCGU: [false, [Validators.requiredTrue]],
    acceptShare: [false, [Validators.requiredTrue]],
    confirm_password: [{value: '', disabled: true},
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(60),
        confirmationPasswordIsEqualValidator(this.passwordFormControl)
      ]]
    }
  );

  ngOnInit(): void {
    const type = localStorage.getItem('type');
    if (type === 'synchronisation') {
      this.step = 2;
    }
  }

  ngAfterViewInit() {
    const entreprise = JSON.parse(localStorage.getItem('entreprise'));
    if (entreprise?.siret
      && /^[1-2]/.test(entreprise?.siret)) {
      this.individualInfosForm.get('type').setValue(this.SECTEUR_PUBLIC);
    }
  }

  formatName(event): boolean {
    const patt = nameRegEx;
    return patt.test(event.key);
  }

  goToStep2(numberStep: number): void {
    this.step = numberStep;
  }

  numericPhoneOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  back(): void {
    if (this.step === 2) {
      this.step = 1;
    } else  if (this.step === 3) {
      this.step = 2;
    } else {
      window.history.back();
    }
  }

  ngDoCheck(): void {
    if (this.createAccountForm.get('password').dirty) {
      this.createAccountForm.get('confirm_password').enable();
    }
  }

  getCGU() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cgu/' + this.saml])
    );

    window.open(url, '_blank');
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }

  validate(): void {
    this.isLoading = true;

    // on récupère le premier formulaire
    const entreprise = JSON.parse(localStorage.getItem('entreprise'));
    const company = {siret: entreprise.siret, type: this.individualInfosForm.get('type').value, name: entreprise.denomination,
      apeCode: this.enterpriseForm.get('naf').value, headCount: this.enterpriseForm.get('effectif').value,
      marketNumber: this.individualInfosForm.get('numMarche').value};

    if (this.enterpriseForm.get('naf').value === '') {
      delete company.apeCode;
    }
    if (this.enterpriseForm.get('effectif').value === '') {
      delete company.headCount;
    }
    if (!this.isSecteurPublicSelected() || this.individualInfosForm.get('numMarche').value === '') {
      delete company.marketNumber;
    }

    const equipementCADOFIN = {company: Object.assign(company), functionId: 2};

    const body = {login: this.createAccountForm.get('login').value,
      firstName: this.individualInfosForm.get('firstName').value, lastName: this.individualInfosForm.get('lastName').value,
      password: sha256(this.createAccountForm.get('password').value), passwordCado: sha512(this.createAccountForm.get('password').value),
      email: this.createAccountForm.get('login').value, providerId: 'TC', applicationId: ApplicationCodeEnum.BENEFIT_FINANCEUR,
      title: this.individualInfosForm.get('title').value, equipmentCADOFIN: equipementCADOFIN
    };

    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.token = authToken;

      this.reCaptchaV3Service
      .execute('CreationAccountAction')
      .pipe(finalize(() => this.createUserAccount(body)))
      .subscribe((token) => (this.valueCaptcha = token));

    });
  }

  createUserAccount(body: any): void {
    this.siloApiService.createExternalUser(this.token, body, this.valueCaptcha).subscribe(
        (userId) =>  this.createTermsAndConditionsAcceptances(userId),
        (error) => {
          this.gestionErreur(error);
        }
      );
  }

  private createTermsAndConditionsAcceptances(userId) {
    this.userId = userId.id;
    localStorage.setItem('id', this.userId.toString());

    const profileTypeLocal = 'MANAGER';

    const body: CreateTermsAndConditions = {
      profileType: profileTypeLocal,
      applicationId: ApplicationCodeEnum.BENEFIT_FINANCEUR
    };
    this.siloApiService
      .createTermsAndConditionsAcceptances(userId.id, body,  this.token)
      .subscribe(
        () => {
          // Go to step 3
          this.isLoading = false;
          localStorage.removeItem('password');
          this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}/cado`]);
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  private gestionErreur(error: any) {
    if (error.error.errors) {
      console.log(error.error.errors[0].additionalInformation.message);
      if (error.error.errors[0].additionalInformation && error.error.errors[0].additionalInformation.message.includes('BAD_CREDENTIALS')) {
        this.messageErreur = 'Merci de renseigner les données d\'un compte comitéo valide';
      } else {
        this.messageErreur = error.error.errors[0].message;
      }
    } else {
      this.messageErreur = 'Données incorrectes';
    }
    this.isLoading = false;
    this.modalService.open('errorModal');
  }

  isLessThanEightChars(p: string): boolean {
    return p.length < 8;
  }

  isMoreThanSixtyChars(p: string): boolean {
    return p.length > 60;
  }

  hasAtLeastOneLowerCase(p: string): boolean {
    return p.toUpperCase() !== p;
  }

  hasAtLeastOneUpperCase(p: string): boolean {
    return p.toLowerCase() !== p;
  }

  hasAtLeastOneDigit(p: string): boolean {
    return /\d/.test(p);
  }

  hasAtLeastOneSpecialChar(p: string): boolean {
    const pwdRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return pwdRegex.test(p);
  }

  isCorrectPwd(p: string): boolean {
    return !this.isLessThanEightChars(p) && !this.isMoreThanSixtyChars(p) && (
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneSpecialChar(p))
    );
  }

  isSecteurPublicSelected(): boolean {
    return this.individualInfosForm.get('type').value === this.SECTEUR_PUBLIC;
  }

  onCustomerTypeChange(newCustomerType: string) {
    const entreprise = JSON.parse(localStorage.getItem('entreprise'));
    if (newCustomerType === this.SECTEUR_PUBLIC) {
      this.chorusPriveCheckError = false;
      this.chorusPublicCheckError = entreprise?.siret && !/^[1-2]/.test(entreprise?.siret);
    } else {
      this.chorusPriveCheckError = entreprise?.siret && /^[1-2]/.test(entreprise?.siret);
      this.chorusPublicCheckError = false;
    }
  }

  isIndividualInfosFormValid(): boolean {
    return !this.chorusPublicCheckError
      && !this.chorusPriveCheckError
      && !!this.individualInfosForm.get('firstName').value
      && !!this.individualInfosForm.get('lastName').value;
  }

  getChorusPublicErrorText(): string {
    const entreprise = JSON.parse(localStorage.getItem('entreprise'));
    return this.translateService.instant('CREATION_ACCOUNT.SECOND_STEP.SIRET_CHORUS_ERR', {
      siret: entreprise?.siret
    });
  }

  getChorusPriveErrorText(): string {
    const entreprise = JSON.parse(localStorage.getItem('entreprise'));
    return this.translateService.instant('CREATION_ACCOUNT.SECOND_STEP.SIRET_CHORUS_PRIVE_ERR', {
      siret: entreprise?.siret
    });
  }
}
