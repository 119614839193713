<div id="identity-step-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row h-100">
    <div id="menu-container" class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 d-none d-md-block p-0">
      <app-affilie-apz [step]="1.5" [saml]="saml"></app-affilie-apz>
    </div>
    <div id="step-container" class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 p-0">
      <div id="info-container" class="d-flex flex-column align-items-start justify-content-start w-100 h-100 p-3">
        <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
          <!-- BACK -->
          <div class="d-flex flex-row align-items-center justify-content-between w-100 mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
            <button id="go-back-btn" type="button" class="btn btn-link p-0" (click)="goBack()">
              <div class="d-flex flex-row align-items-center justify-content-center">
                <i id="go-back-btn-icon" class="bi bi-arrow-left"></i>
                <span class="ml-2" id="go-back-btn-label">{{
                  'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.BACK' | translate
                }}</span>
              </div>
            </button>
            <span id="stepper-pos" class="d-flex d-sm-flex d-md-none">{{
              'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.STEP_SM' | translate
            }}</span>
          </div>
          <!-- HELLO -->
          <div class="d-flex flex-column align-items-start justify-content-center mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
            <span id="identity-title" class="d-flex flex-row align-items-start justify-content-center">{{
              'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.HELLO' | translate
            }}</span>
            <span id="identity-sub-title" class="d-flex flex-row align-items-start justify-content-center">{{
              'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.HELLO_DESCRIPTION' | translate
            }}</span>
          </div>
          <form class="w-100" name="form" role="form" [formGroup]="coordonneesForm" (submit)="nextStep()" (keydown.enter)="nextStep()">
            <div
              class="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column align-items-start justify-content-between mb-4"
            >
              <!-- CIVILITY -->
              <div class="d-flex flex-column civility-input mb-3">
                <label class="form-label" for="civility">{{
                  'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.CIVILITY' | translate
                }}</label>
                <select type="select" class="form-select form-control" name="civility" id="civility" [formControlName]="'civility'">
                  <option value="MR">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.CIVILITY_M' | translate }}</option>
                  <option value="MS">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.CIVILITY_MME' | translate }}</option>
                </select>
              </div>
              <!-- FIRST NAME -->
              <div class="d-flex flex-column identity-input mb-3">
                <label class="form-label" for="firstName">{{
                  'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.FIRST_NAME' | translate
                }}</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  name="firstName"
                  id="firstName"
                  [formControlName]="'firstName'"
                  maxlength="38"
                  [ngClass]="{
                    'is-invalid': coordonneesForm.get('firstName').invalid === true && coordonneesForm.get('firstName').touched === true,
                    'is-valid': coordonneesForm.get('firstName').invalid === false && coordonneesForm.get('firstName').touched === true
                  }"
                  placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.FIRST_NAME_PROMPT' | translate }}"
                />
                <div *ngIf="coordonneesForm.get('firstName').touched === true && coordonneesForm.get('firstName').invalid">
                  <small class="text-danger error-msg" *ngIf="coordonneesForm.get('firstName').errors.required">
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                  </small>
                </div>
              </div>
              <!-- LAST NAME -->
              <div class="d-flex flex-column identity-input">
                <label class="form-label" for="lastName">{{
                  'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.LAST_NAME' | translate
                }}</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  name="lastName"
                  id="lastName"
                  [formControlName]="'lastName'"
                  maxlength="38"
                  [ngClass]="{
                    'is-invalid': coordonneesForm.get('lastName').invalid === true && coordonneesForm.get('lastName').touched === true,
                    'is-valid': coordonneesForm.get('lastName').invalid === false && coordonneesForm.get('lastName').touched === true
                  }"
                  placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.LAST_NAME_PROMPT' | translate }}"
                />
                <div *ngIf="coordonneesForm.get('lastName').touched === true && coordonneesForm.get('lastName').invalid">
                  <small class="text-danger error-msg" *ngIf="coordonneesForm.get('lastName').errors.required">
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                  </small>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column align-items-start justify-content-between"
            >
              <!-- PHONE NUMBER -->
              <div class="d-flex flex-column phone-input mb-3">
                <label class="form-label" for="phoneNumber">{{
                  'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.PHONE_NUMBER' | translate
                }}</label>
                <input
                  type="tel"
                  pattern="^[0][0-9]{9}$|^[+][0-9]{1,4}[0-9]{1,16}$"
                  class="form-control"
                  name="phoneNumber"
                  id="phoneNumber"
                  minlength="1"
                  maxlength="20"
                  [formControlName]="'phoneNumber'"
                  [ngClass]="{
                    'is-invalid':
                      coordonneesForm.get('phoneNumber').invalid === true &&
                      coordonneesForm.get('phoneNumber').touched === true &&
                      coordonneesForm.get('phoneNumber').value,
                    'is-valid':
                      coordonneesForm.get('phoneNumber').invalid === false &&
                      coordonneesForm.get('phoneNumber').touched === true &&
                      coordonneesForm.get('phoneNumber').value
                  }"
                  placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.PHONE_NUMBER_PROMPT' | translate }}"
                />
                <div *ngIf="coordonneesForm.get('phoneNumber').touched === true && coordonneesForm.get('phoneNumber').invalid">
                  <small class="text-danger error-msg" *ngIf="coordonneesForm.get('phoneNumber').errors.pattern">
                    {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.ERROR.PATTERN_PHONENUMBER' | translate }}
                  </small>
                </div>
              </div>
              <!-- CELL PHONE NUMBER -->
              <div class="d-flex flex-column phone-input">
                <label class="form-label" for="cellPhoneNumber">{{
                  'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.CELL_PHONE_NUMBER' | translate
                }}</label>
                <input
                  type="tel"
                  pattern="^[0][0-9]{9}$|^[+][0-9]{1,4}[0-9]{1,16}$"
                  class="form-control"
                  name="cellPhoneNumber"
                  id="cellPhoneNumber"
                  minlength="1"
                  maxlength="20"
                  [formControlName]="'cellPhoneNumber'"
                  [ngClass]="{
                    'is-invalid':
                      coordonneesForm.get('cellPhoneNumber').invalid === true &&
                      coordonneesForm.get('cellPhoneNumber').touched === true &&
                      coordonneesForm.get('cellPhoneNumber').value,
                    'is-valid':
                      coordonneesForm.get('cellPhoneNumber').invalid === false &&
                      coordonneesForm.get('cellPhoneNumber').touched === true &&
                      coordonneesForm.get('cellPhoneNumber').value
                  }"
                  placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.CELL_PHONE_NUMBER_PROMPT' | translate }}"
                />
                <div *ngIf="coordonneesForm.get('cellPhoneNumber').touched === true && coordonneesForm.get('cellPhoneNumber').invalid">
                  <small class="text-danger error-msg" *ngIf="coordonneesForm.get('cellPhoneNumber').errors.pattern">
                    {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.IDENTITY_STEP.ERROR.PATTERN_PHONENUMBER' | translate }}
                  </small>
                </div>
              </div>
            </div>
            <!-- SUBMIT -->
            <div class="d-flex flex-row align-items-center justify-content-start mt-4">
              <button
                type="button"
                class="btn btn-primary"
                [disabled]="
                  !coordonneesForm.valid ||
                  isSubmitted ||
                  (!coordonneesForm.get('phoneNumber').value && !coordonneesForm.get('phoneNumber').value)
                "
                (click)="nextStep()"
              >
                <div class="d-flex flex-row align-items-center justify-content-center">
                  <div *ngIf="isSubmitted" class="spinner-border"></div>
                  <span
                    [ngClass]="{
                      'ml-2': isSubmitted
                    }"
                    id="submit-btn-label"
                    >{{ 'COMMON.FORM.CONTINUE' | translate }}</span
                  >
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
