import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UpdateEmployeeContactTypeEnum, UserProfileTypeEnum } from '@shared/enums/siloApi.enum';
import { Affiliate, ReadAffiliatesParamQuery } from '@shared/models/affiliate';
import { AffiliateResponse } from '@shared/models/affiliate-response';
import { AuthToken } from '@shared/models/authtoken.model';
import { CheckUserAffiliateAccessRequest } from '@shared/models/check-user-affiliate-access-request';
import { CheckUserAffiliateAccessResponse } from '@shared/models/check-user-affiliate-access-response';
import { CreateUserAffiliateLinkRequest } from '@shared/models/create-user-affiliate-link-request';
import { CreateUserAffiliateLinkResponse } from '@shared/models/create-user-affiliate-link-response';
import { CreateTermsAndConditions } from '@shared/models/createTermsAndConditions.model';
import { EmployeeResponse } from '@shared/models/employeeResponse.model';
import { InfoGreffeData } from '@shared/models/infoGreffeData';
import { PasswordCheck } from '@shared/models/passwordCheck.model';
import { ReadTerms } from '@shared/models/readTerms.model';
import { UserAffiliateCreateInviteLink } from '@shared/models/user-affiliate-create-invite-link';
import { UserCreated } from '@shared/models/user-created.model';
import { UserAffiliateCreate } from '@shared/models/userAffiliateCreate.model';
import { UserResponse } from '@shared/models/userResponse.model';
import { sha256 } from 'js-sha256';
import {
  AffiliateManager,
  CreateAdhesionRequestRequest,
  Employee,
  LegalNotices,
  TermsAndConditions,
  UpdateUsersIdentityAndContacts,
  UserActivationCreate,
  UserActivationResponse,
  UserCriteria,
  dateFormat
} from 'nit-angular-lib';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CustomerResponse } from '../models/customerResponse.model';
import { OTPId } from '../models/opt-check.model';
import { SecretQuestions } from '../models/secret-question.model';
import { GatewayService } from './gateway.service';

@Injectable()
export class SiloApiService {
  private readonly urlUserIDP: string;
  private readonly urlUserIDPIhs: string;
  private readonly urlUser: string;
  private readonly urlApplication: string;
  private readonly urlEmployee: string;
  private readonly urlAffiliate: string;
  private readonly urlAdhesionRequest: string;
  private readonly urlReferential: string;
  private readonly urlCustomers: string;

  public constructor(private http: HttpClient, private gatewayService: GatewayService, @Inject('env') env) {
    this.urlUserIDP = environment.gatewayEndPointRS + '/NIT/' + (env.localApi ? '' : 'userIDP/') + env.versionApi;
    this.urlUserIDPIhs = '/NIT/' + (env.localApi ? '' : 'userIDP/') + env.versionApi;
    this.urlEmployee = environment.gatewayEndPointRS + '/NIT/' + (env.localApi ? '' : 'employee/') + env.versionApi;
    this.urlUser = environment.gatewayEndPointRS + '/NIT/' + (env.localApi ? '' : 'user/') + env.versionApi;
    this.urlApplication = environment.gatewayEndPointRS + '/NIT/' + (env.localApi ? '' : 'application/') + env.versionApi;
    this.urlAffiliate = environment.gatewayEndPointRS + '/NIT/' + (env.localApi ? '' : 'affiliate/') + env.versionApi;
    this.urlAdhesionRequest = environment.gatewayEndPointRS + '/NIT/' + (env.localApi ? '' : 'adhesionRequest/') + env.versionApi;
    this.urlReferential = environment.gatewayEndPointRS + '/NIT/' + (env.localApi ? '' : 'referential/') + env.versionApi;
    this.urlCustomers = environment.gatewayEndPointRS + '/NIT/' + (env.localApi ? '' : 'customer/') + env.versionApi;
  }

  private getHttpHeader(token: AuthToken): HttpHeaders {
    return new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });
  }

  private getHttpHeaderWithCaptcha(token: AuthToken, captchaResponse: string): HttpHeaders {
    return new HttpHeaders({ Authorization: 'Bearer ' + token.access_token, recaptchaResponse: captchaResponse });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      alert(`An error occurred: ${error.error.message}`);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      alert(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public activerCompte(hash: string, token: AuthToken): Observable<any> {
    const hashDecoder = atob(hash);
    const active = true;
    const userActivationId = hashDecoder.split(';')[0];
    const login = hashDecoder.split(';')[1];
    const applicationId = hashDecoder.split(';')[2];
    const profileType = hashDecoder.split(';')[3];
    const body = { applicationId, active, login, profileType };
    const url = this.urlUserIDP + '/userActivations/' + userActivationId;
    const httpOptions = { headers: this.getHttpHeader(token) };
    return this.http.patch<any>(url, body, httpOptions);
  }

  public demandeReinitialisationMotDePasse(
    login: string,
    applicationId: string,
    token: AuthToken,
    valueCaptcha: string
  ): Observable<string> {
    const body = { applicationId, login };
    const url = this.urlUserIDP + '/userPasswordReissues';
    const httpHeaders = this.getHttpHeaderWithCaptcha(token, valueCaptcha);
    return this.http.post(url, body, {
      headers: httpHeaders,
      responseType: 'text'
    });
  }

  public demandeIdentifiantOublie(email: string, applicationId: string, token: AuthToken, valueCaptcha: string): Observable<string> {
    const body = { email, applicationId };
    const url = this.urlUserIDP + '/userLoginRequests';
    const httpHeaders = this.getHttpHeaderWithCaptcha(token, valueCaptcha);
    return this.http.post(url, body, {
      headers: httpHeaders,
      responseType: 'text'
    });
  }

  public verifierTokenDemandeReinitialisationMotDePasse(
    login: string,
    applicationId: string,
    hash: string,
    type: string,
    token: AuthToken,
    valueCaptcha: string
  ): Observable<string> {
    const body = { applicationId, login, token: hash, type };
    const url = this.urlUserIDP + '/userPasswordReissueCheck';
    const httpHeaders = this.getHttpHeaderWithCaptcha(token, valueCaptcha);
    return this.http.post(url, body, {
      headers: httpHeaders,
      responseType: 'text'
    });
  }

  public reinitialisationMotDePasse(
    userPasswordReissueId: string,
    password: string,
    hash: string,
    type: string,
    token: AuthToken,
    valueCaptcha: string
  ): Observable<string> {
    const body = { password: sha256(password), hash, type };
    const url = this.urlUserIDP + '/userPasswordReissue/' + userPasswordReissueId;
    const httpHeaders = this.getHttpHeaderWithCaptcha(token, valueCaptcha);
    return this.http.patch(url, body, {
      headers: httpHeaders,
      responseType: 'text'
    });
  }

  public verifierCodeSMSReinit(code: string, token: AuthToken): Observable<string> {
    const url = this.urlUserIDP + '/userReissuePasswordCodeCheck';
    const httpHeaders = this.getHttpHeader(token);
    const body = { code };
    return this.http.post(url, body, {
      headers: httpHeaders,
      responseType: 'text'
    });
  }

  public verifierCodeSMS(code: string, token: AuthToken): Observable<string> {
    const url = this.urlUserIDP + '/checkingActivationCode';
    const httpHeaders = this.getHttpHeader(token);
    const body = { code };
    return this.http.post(url, body, { headers: httpHeaders, responseType: 'text' });
  }

  public connecter(login: string, password: string, samlRequest: string, token: AuthToken): Observable<string> {
    const url = this.urlUserIDPIhs + '/connection';
    const httpHeaders = this.getHttpHeader(token);
    const body = { login, password: sha256(password), samlRequest };
    return this.http.post(url, body, { headers: httpHeaders, responseType: 'text' });
  }

  public connecterWithCaptcha(login: string, password: string, samlRequest: string, token: AuthToken, captcha: string, otpCode?: string): Observable<string> {
    const url = this.urlUserIDPIhs + '/connection';
    const httpHeaders = this.getHttpHeaderWithCaptcha(token, captcha);
    const body: any = { login, password: sha256(password), samlRequest };
    if (otpCode) {
      body.otpCode = otpCode;
    }
    return this.http.post(url, body, { headers: httpHeaders, responseType: 'text' });
  }

  public connecterSSO(samlRequest: string, token: AuthToken): Observable<string> {
    const url = this.urlUserIDPIhs + '/connectionSso';
    const httpHeaders = this.getHttpHeader(token);
    const body = { samlRequest };
    return this.http.post(url, body, { headers: httpHeaders, responseType: 'text' });
  }

  public checkCookieSession(token: AuthToken): Observable<string> {
    const url = this.urlUserIDPIhs + '/connectionSso';
    const httpHeaders = this.getHttpHeader(token);
    return this.http.get(url, { headers: httpHeaders, responseType: 'text' });
  }

  public updateUser(
    login: string,
    newLogin: string,
    applicationId: string,
    currentPassword: string,
    newPassword: string,
    token: AuthToken,
    valueCaptcha: string
  ): Observable<string> {
    const body = {
      applicationId,
      login,
      newLogin,
      currentPassword: currentPassword != null ? sha256(currentPassword) : currentPassword,
      newPassword: newPassword != null ? sha256(newPassword) : newPassword
    };
    const url = this.urlUserIDP + '/users/me';
    const httpHeaders = this.getHttpHeaderWithCaptcha(token, valueCaptcha);
    return this.http.patch(url, body, {
      headers: httpHeaders,
      responseType: 'text'
    });
  }

  public infosUser(
    pCardNumber: string,
    pExpirationDate: string,
    login: string,
    siret: string,
    token: AuthToken,
    captchaResponse?: string
  ): Observable<UserResponse> {
    let httpHeaders;
    if (captchaResponse && captchaResponse !== '') {
      httpHeaders = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token, recaptchaResponse: captchaResponse });
    } else {
      httpHeaders = this.getHttpHeader(token);
    }
    let httpParams = new HttpParams();
    if (pCardNumber !== undefined && pCardNumber !== null) {
      httpParams = httpParams.set('cardNumber', pCardNumber).set('expirationDate', pExpirationDate);
    }
    if (login !== undefined && (pCardNumber === undefined || pCardNumber === null)) {
      httpParams = httpParams.set('login', login);
    }
    if (siret !== undefined && siret !== null) {
      httpParams = httpParams.set('siret', siret);
    }
    return this.http.get<UserResponse>(this.urlUserIDP + '/users', {
      headers: httpHeaders,
      params: httpParams
    });
  }

  public readUserByCriteria(token: AuthToken, userCriteria: UserCriteria, captchaResponse?: string): Observable<UserResponse> {
    const url = this.urlUserIDP + '/users';

    let httpHeaders;
    if (captchaResponse && captchaResponse !== '') {
      httpHeaders = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token, recaptchaResponse: captchaResponse });
    } else {
      httpHeaders = this.getHttpHeader(token);
    }

    let httpParams = new HttpParams();
    if (userCriteria.cardNumber) {
      httpParams = httpParams.append('cardNumber', userCriteria.cardNumber);
    }
    if (userCriteria.expirationDate) {
      httpParams = httpParams.append('expirationDate', userCriteria.expirationDate);
    }
    if (userCriteria.login) {
      httpParams = httpParams.append('login', userCriteria.login);
    }
    if (userCriteria.employeePersonalCode) {
      httpParams = httpParams.append('employeePersonalCode', userCriteria.employeePersonalCode);
    }
    if (userCriteria.productId) {
      httpParams = httpParams.append('productId', userCriteria.productId);
    }
    if (userCriteria.birthDate) {
      httpParams = httpParams.append('birthDate', dateFormat(userCriteria.birthDate));
    }
    if (userCriteria.siret) {
      httpParams = httpParams.append('siret', userCriteria.siret);
    }

    return this.http.get<UserResponse>(url, { headers: httpHeaders, params: httpParams });
  }

  public readCustomer(token: AuthToken, siret: string, emetteur: string): Observable<CustomerResponse> {
    const url = this.urlCustomers + '/customers';
    const httpHeaders = this.getHttpHeader(token);

    let httpParams = new HttpParams();
    if (siret) {
      httpParams = httpParams.append('siret', siret);
    }

    if (emetteur) {
      httpParams = httpParams.append('issuerId', emetteur);
    }

    return this.http.get<CustomerResponse>(url, { headers: httpHeaders, params: httpParams });
  }

  public infosEmployees(pCardNumber: string, pExpirationDate: string, token: AuthToken, refClient: any, captcha: string): Observable<EmployeeResponse> {
    let httpHeaders : HttpHeaders;
    if (captcha && captcha !== '') {
      httpHeaders = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token, recaptchaResponse: captcha });
    } else {
      httpHeaders = this.getHttpHeader(token);
    }
    const httpParams = new HttpParams().set('cardNumber', pCardNumber).set('expirationDate', pExpirationDate).set('customerNumber', refClient);
    return this.http.get<EmployeeResponse>(this.urlEmployee + '/employees', {
      headers: httpHeaders,
      params: httpParams
    });
  }

  public infosEmployeesPersonalNumber(
    token: AuthToken,
    personalNumber: string,
    birthDate: Date,
    productId: string,
    captcha: string
  ): Observable<EmployeeResponse> {
    let httpHeaders : HttpHeaders;
    if (captcha && captcha !== '') {
      httpHeaders = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token, recaptchaResponse: captcha });
    } else {
      httpHeaders = this.getHttpHeader(token);
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('employeePersonalCode', personalNumber);
    httpParams = httpParams.set('birthDate', dateFormat(birthDate));
    httpParams = httpParams.set('productId', productId);

    return this.http.get<EmployeeResponse>(this.urlEmployee + '/employees', {
      headers: httpHeaders,
      params: httpParams
    });
  }

  public findEmployee(id: string, token: AuthToken): Observable<Employee> {
    const httpHeaders = this.getHttpHeader(token);
    return this.http.get<Employee>(this.urlEmployee + '/employees/' + id, {
      headers: httpHeaders
    });
  }

  public createUserAccount(
    login: string,
    password: string,
    typeLogin: string,
    token: AuthToken,
    employeeId: string,
    applicationId: string,
    captcha: string,
    phone?: string,
    secretQuestionId?: string,
    response?: string
  ): Observable<UserCreated> {
    const body =
      typeLogin === ''
        ? {
            applicationId,
            login,
            password: sha256(password),
            cellPhoneNumber: phone,
            secretQuestionsResponses:
              secretQuestionId && response
                ? [
                    {
                      response: sha256(response.toLocaleUpperCase()),
                      secretQuestionId
                    }
                  ]
                : []
          }
        : {
            applicationId,
            login,
            password: sha256(password),
            typeLogin,
            cellPhoneNumber: phone,
            secretQuestionsResponses:
              secretQuestionId && response
                ? [
                    {
                      response: sha256(response.toLocaleUpperCase()),
                      secretQuestionId
                    }
                  ]
                : []
          };

    const url = this.urlUserIDP + '/employees/' + employeeId + '/users';
    let httpHeaders : HttpHeaders;
    if (captcha && captcha !== '') {
      httpHeaders = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token, recaptchaResponse: captcha });
    } else {
      httpHeaders = this.getHttpHeader(token);
    }
    return this.http.post<UserCreated>(url, body, {
      headers: httpHeaders,
      responseType: 'json'
    });
  }

  public createUserAffiliate(token: AuthToken, userAffiliateCreate: UserAffiliateCreate): Observable<UserCreated> {
    const url = this.urlUserIDP + '/usersAffiliate';
    const httpHeaders = this.getHttpHeader(token);
    userAffiliateCreate.password = sha256(userAffiliateCreate.password);
    return this.http.post<UserCreated>(url, userAffiliateCreate, { headers: httpHeaders, responseType: 'json' });
  }

  public createUserAffiliateFromLink(
    userAffiliateCreateInviteLink: UserAffiliateCreateInviteLink,
    token: AuthToken
  ): Observable<UserCreated> {
    const httpHeaders = this.getHttpHeader(token);
    const url = this.urlUserIDP + '/usersAffiliateFromInviteLink';
    userAffiliateCreateInviteLink.password = sha256(userAffiliateCreateInviteLink.password);
    return this.http.post<UserCreated>(url, userAffiliateCreateInviteLink, { headers: httpHeaders, responseType: 'json' });
  }

  public createUserAffiliateLink(userId: number, affiliateId: string, token: AuthToken): Observable<CreateUserAffiliateLinkResponse> {
    const createUserAffiliateLinkRequest: CreateUserAffiliateLinkRequest = {
      userId
    };
    const url = this.urlUserIDP + '/affiliates/' + affiliateId + '/users';
    const httpHeaders = this.getHttpHeader(token);
    return this.http.post<CreateUserAffiliateLinkResponse>(url, createUserAffiliateLinkRequest, {
      headers: httpHeaders,
      responseType: 'json'
    });
  }

  public readAffiliateManager(affiliateManagerId: number, token: AuthToken): Observable<AffiliateManager> {
    const httpHeaders = this.getHttpHeader(token);
    const url = this.urlAffiliate + '/affiliateManagers/' + affiliateManagerId;
    return this.http.get<AffiliateManager>(url, { headers: httpHeaders });
  }

  updateUsersIdentityAndContacts(
    email: string,
    userInformationsToUpdate: UpdateUsersIdentityAndContacts,
    token: AuthToken
  ): Observable<UpdateUsersIdentityAndContacts> {
    const url = this.urlUser + '/users/me/identityAndContacts';
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + token.access_token,
      sub: email
    });

    return this.http.patch<UpdateUsersIdentityAndContacts>(url, userInformationsToUpdate, { headers: httpHeaders });
  }

  public activateUser(
    pUserActivationId: string,
    pUserActivation: UserActivationCreate,
    token: AuthToken
  ): Observable<UserActivationResponse> {
    const url = this.urlUserIDP + '/userActivations/' + pUserActivationId;
    const httpHeaders = this.getHttpHeader(token);
    return this.http.patch<UserActivationResponse>(url, pUserActivation, { headers: httpHeaders });
  }

  public createExternalUser(token: AuthToken, body: any, captcha: string): Observable<UserCreated> {
    const url = this.urlUserIDP + '/usersProvider';

    let httpHeaders;
    if (captcha && captcha !== '') {
      httpHeaders = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token, recaptchaResponse: captcha });
    } else {
      httpHeaders = this.getHttpHeader(token);
    }

    return this.http.post<UserCreated>(url, body, { headers: httpHeaders, responseType: 'json' });
  }

  public extractSAMLRequest(token: AuthToken, id: string, appNameEncoded: string, captchaResponse: string): Observable<{ id: string }> {
    const body = { id, appNameEncoded };

    const url = this.urlUserIDP + '/extractSAMLRequest';
    const httpHeaders = this.getHttpHeaderWithCaptcha(token, captchaResponse);
    return this.http.post<{ id: string }>(url, body, {
      headers: httpHeaders,
      responseType: 'json'
    });
  }

  public sendEmailActivationAccount(id: string, applicationId: string, token: AuthToken): Observable<string> {
    const body = { applicationId, id };
    const url = this.urlUserIDP + '/userActivations';
    const httpHeaders = this.getHttpHeader(token);
    return this.http.post(url, body, {
      headers: httpHeaders,
      responseType: 'text'
    });
  }

  public readTermsAndConditionsAcceptances(userId: string, token: AuthToken): Observable<ReadTerms> {
    const httpHeaders = this.getHttpHeader(token);
    return this.http.get<ReadTerms>(this.urlUser + '/users/' + userId + '/termsAndConditionsAcceptances', {
      headers: httpHeaders
    });
  }

  public createTermsAndConditionsAcceptances(userId: number, profileType: CreateTermsAndConditions, token: AuthToken): Observable<string> {
    const body = profileType;
    const endpoint = this.urlUser + '/users/' + userId + '/termsAndConditionsAcceptances';
    const httpHeaders = this.getHttpHeader(token);
    return this.http.post(endpoint, body, {
      headers: httpHeaders,
      responseType: 'text'
    });
  }

  public userPasswordCheck(
    login: string,
    password: string,
    applicationId: string,
    token: AuthToken,
    captcha: string
  ): Observable<PasswordCheck> {
    const body = { login, password: sha256(password), applicationId };
    const endpoint = this.urlUserIDP + '/userPasswordCheck';
    const httpHeaders = this.getHttpHeaderWithCaptcha(token, captcha);
    return this.http.post<PasswordCheck>(endpoint, body, {
      headers: httpHeaders
    });
  }

  public createOTP(login: string, applicationId: string, token: AuthToken, context?: string): Observable<any> {
    const body = { login, applicationId, context };
    const endpoint = this.urlUserIDP + '/otpRequest';
    const httpHeaders = this.getHttpHeader(token);
    return this.http.post<any>(endpoint, body, {
      headers: httpHeaders
    });
  }

  public checkOTP(login: string, applicationId: string, otp: string, password: string, token: AuthToken): Observable<OTPId> {
    const body = { login, applicationId, otp, password: sha256(password) };
    const endpoint = this.urlUserIDP + '/otpCheck';
    const httpHeaders = this.getHttpHeader(token);
    return this.http.post<OTPId>(endpoint, body, {
      headers: httpHeaders
    });
  }

  public getUrlFromMinifiedToken(miniToken: string, token: AuthToken): Observable<string> {
    const endpoint = this.urlUserIDP + '/getUrlFromMinifiedToken';
    let params = new HttpParams();
    const httpHeaders = this.getHttpHeader(token);
    params = params.set('minifiedToken', miniToken);
    return this.http.get(endpoint, { params, responseType: 'text', headers: httpHeaders });
  }

  public readTermsAndConditions(
    applicationId: string,
    pProfileType: string,
    token: AuthToken,
    contentLanguage?: string
  ): Observable<TermsAndConditions> {
    const endpoint = this.urlApplication + '/applications/' + applicationId + '/termsAndConditions';
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + token.access_token,
      'Accept-Language': contentLanguage ? contentLanguage : 'fr'
    });
    const httpOptions = {
      headers: httpHeaders,
      params: { profileType: pProfileType }
    };

    return this.http.get<TermsAndConditions>(endpoint, httpOptions);
  }

  public readLegalNotices(applicationId: string, token: AuthToken, contentLanguage?: string): Observable<LegalNotices> {
    const endpoint = this.urlApplication + '/applications/' + applicationId + '/legalNotices/latest';
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + token.access_token,
      'Accept-Language': contentLanguage ? contentLanguage : 'fr'
    });
    const httpOptions = {
      headers: httpHeaders,
      params: {}
    };

    return this.http.get<LegalNotices>(endpoint, httpOptions);
  }

  /**
   * Add a profile to a specific user
   * @param token the authentication token
   * @param userId the user to add the new profile to
   * @param applicationId the application id of the profile to add
   * @param type the type of the profile to add (EMPLOYEE / MANAGER)
   */
  public addUserProfile(token: AuthToken, userId: string, applicationId: string, type: UserProfileTypeEnum) {
    const url = this.urlUserIDP + '/users/' + userId + '/profiles';

    const httpHeaders = this.getHttpHeader(token);

    const body = { applicationId, type };

    return this.http.post<any>(url, body, {
      headers: httpHeaders
    });
  }

  /**
   * Update the employee contact information
   * @param token the authentication token
   * @param employeeId the id of the employee to update the contact of
   * @param contactId the type of contact information to update
   * @param value the new value to use
   */
  public updateEmployeeContacts(token: AuthToken, employeeId: string, contactId: UpdateEmployeeContactTypeEnum, value: string) {
    const url = this.urlEmployee + '/employees/' + employeeId + '/contacts/' + contactId;

    const httpHeaders = this.getHttpHeader(token);

    const body = { value };

    return this.http.put(url, body, {
      headers: httpHeaders
    });
  }

  public readSecretQuestions(token: AuthToken, captcha: string, login?: string, applicationId?: string): Observable<SecretQuestions> {
    const httpHeaders = this.getHttpHeaderWithCaptcha(token, captcha);
    const httpOptions = {
      headers: httpHeaders,
      params: {}
    };
    if (login && applicationId) {
      httpOptions.params = { login, applicationId };
    }
    const endpoint = this.urlUserIDP + '/secretQuestions';
    return this.http.get<SecretQuestions>(endpoint, httpOptions);
  }

  public updateUserSecretQuestionsResponses(
    token: AuthToken,
    login: string,
    applicationId: string,
    password: string,
    response: string,
    secretQuestionId: string
  ): Observable<any> {
    const url = this.urlUserIDP + '/userSecretQuestionsResponses';
    const httpHeaders = this.getHttpHeader(token);
    const body = {
      applicationId,
      login,
      password: sha256(password),
      secretQuestionsResponses: [{ response: sha256(response.toLocaleUpperCase()), secretQuestionId }]
    };
    return this.http.put<any>(url, body, {
      headers: httpHeaders
    });
  }

  public userSecretQuestionResponseChecks(
    token: AuthToken,
    login: string,
    applicationId: string,
    response: string,
    secretQuestionId: string
  ): Observable<PasswordCheck> {
    const url = this.urlUserIDP + '/userSecretQuestionResponseChecks';
    const httpHeaders = this.getHttpHeader(token);
    const body = {
      applicationId,
      login,
      response: sha256(response.toLocaleUpperCase()),
      secretQuestionId
    };
    return this.http.post<PasswordCheck>(url, body, {
      headers: httpHeaders
    });
  }

  public readAffiliates(token: AuthToken, compensatingId: string, siret: string): Observable<Affiliate[]> {
    const url = this.urlAffiliate + '/affiliates?compensatingId=' + compensatingId + '&siret=' + siret;
    const httpHeaders = this.getHttpHeader(token);
    return this.http
      .get<AffiliateResponse>(url, { headers: httpHeaders })
      .pipe(map((response) => response.items));
  }

  readAffiliatesByNumberAndCompensating(token: AuthToken, affiliateNumber: string, compensatingId: string): Observable<Affiliate[]> {
    let paramsHttp = new HttpParams();
    if (affiliateNumber) {
      paramsHttp = paramsHttp.append('affiliateNumber', affiliateNumber);
    }
    if (compensatingId) {
      paramsHttp = paramsHttp.append('compensatingId', compensatingId);
    }
    const httpHeaders = this.getHttpHeader(token);
    return this.http
      .get<AffiliateResponse>(this.urlAffiliate + '/affiliates', { headers: httpHeaders, params: paramsHttp })
      .pipe(map((response) => response.items));
  }

  readAffiliatesBySiret(token: AuthToken, siret: string): Observable<Affiliate[]> {
    let paramsHttp = new HttpParams();
    if (siret) {
      paramsHttp = paramsHttp.append('siret', siret);
    }
    const httpHeaders = this.getHttpHeader(token);
    return this.http
      .get<AffiliateResponse>(this.urlAffiliate + '/affiliates', { headers: httpHeaders, params: paramsHttp })
      .pipe(map((response) => response.items));
  }

  public readInfoGreffeData(token: AuthToken, siret: string): Observable<InfoGreffeData> {
    const url = this.urlReferential + '/infoGreffeData/' + siret;
    const httpHeaders = this.getHttpHeader(token);
    return this.http.get<InfoGreffeData>(url, { headers: httpHeaders });
  }

  public createAdhesionRequest(
    token: AuthToken,
    createAdhesionRequestRequest: CreateAdhesionRequestRequest,
    applicationId: string
  ): Observable<UserCreated> {
    const url = this.urlAdhesionRequest + '/adhesionRequests';
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + token.access_token,
      appname: applicationId
    });
    return this.http.post<UserCreated>(url, createAdhesionRequestRequest, {
      headers: httpHeaders
    });
  }

  public checkAffiliateAccess(
    request: CheckUserAffiliateAccessRequest,
    affiliateId: string,
    token: AuthToken
  ): Observable<CheckUserAffiliateAccessResponse> {
    const httpHeaders = this.getHttpHeader(token);
    return this.http.post<CheckUserAffiliateAccessResponse>(
      `${this.urlAffiliate}/affiliates/${affiliateId}/checkUserAffiliateAccess`,
      request,
      {
        headers: httpHeaders,
        responseType: 'json'
      }
    );
  }

  readAffiliatesByQuery(queryParam: ReadAffiliatesParamQuery, token: AuthToken): Observable<Affiliate[]> {
    const httpHeaders = this.getHttpHeader(token);
    let params = new HttpParams();

    if (!!queryParam?.siret) {
      params = params.set('siret', queryParam.siret);
    }
    if (!!queryParam?.email) {
      params = params.set('email', queryParam.email);
    }
    if (queryParam?.isRefundCenter !== undefined && queryParam?.isRefundCenter !== null) {
      params = params.set('isRefundCenter', queryParam.isRefundCenter.toString());
    }
    if (!!queryParam?.affiliateFatherId) {
      params = params.set('affiliateFatherId', queryParam.affiliateFatherId);
    }

    return this.http
      .get<AffiliateResponse>(`${this.urlAffiliate}/affiliates`, { headers: httpHeaders, params })
      .pipe(map((response) => response.items));
  }

  downloadAffiliatesByQuery(queryParam: ReadAffiliatesParamQuery, token: AuthToken): Observable<Blob> {
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + token.access_token,
      Accept: 'application/octet-stream'
    });

    let params = new HttpParams();

    if (!!queryParam?.siret) {
      params = params.set('siret', queryParam.siret);
    }
    if (!!queryParam?.email) {
      params = params.set('email', queryParam.email);
    }
    if (queryParam?.isRefundCenter !== undefined && queryParam?.isRefundCenter !== null) {
      params = params.set('isRefundCenter', queryParam.isRefundCenter.toString());
    }
    if (!!queryParam?.affiliateFatherId) {
      params = params.set('affiliateFatherId', queryParam.affiliateFatherId);
    }

    return this.http.get<Blob>(`${this.urlAffiliate}/affiliates`, { headers: httpHeaders, params, responseType: 'blob' as 'json' });
  }
}
