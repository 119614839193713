import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { environment } from '../../../../../environments/environment';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';

@Component({
  selector: 'app-fourth-step-bimpli-apetiz',
  templateUrl: './fourth-step-bimpli-apetiz.component.html',
  styleUrls: ['./fourth-step-bimpli-apetiz.component.scss']
})
export class FourthStepBimpliApetizComponent implements OnInit {

  @Input() saml: string;
  @Input() estMobile: boolean;
  @Input() applicationId: string;
  @Input() success: boolean;
  @Input() login: string;
  @Input() isLoading: string;

  loginIsEmail: boolean;
  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(private router: Router, private gatewayService: GatewayService, private readonly tealiumService: TealiumService) {}

  ngOnInit(): void {
    if (this.login) {
      this.loginIsEmail = this.login.includes('@');
    }
    if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
      this.tealiumService.view('bimpli_benef.web.register.vaildateAccountPageLoad');
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      this.tealiumService.view('bimpli_mobile.web.register.vaildateAccountPageLoad');
    }
  }

  goLogin() {
    if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
      this.tealiumService.link('bimpli_benef.web.register.backToLoginClick');
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      this.tealiumService.link('bimpli_mobile.web.register.backToLoginClick');
    }
    // Cas mobile : l'appli intercepte cette url et redirige sur la bonne page
    if (this.estMobile) {
      window.location.href = 'app.beneficiaire.apetiz://accueil';
    } else {
      if (this.applicationId === ApplicationCodeEnum.APZ || this.applicationId === ApplicationCodeEnum.APZ_MOBILE) {
          this.gatewayService
            .authentificate(environment.clientIdAPZ, 'token', environment.redirectURIAPZ)
            .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
      } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
          this.gatewayService
            .authentificate(environment.clientIdBenefWeb, 'token', environment.redirectURLBenefWeb)
            .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
      } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_FINANCEUR){
          this.gatewayService
            .authentificate(environment.clientIdFinanceurWeb, 'token', environment.redirectURLFinanceurWeb)
            .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
      }
    }
  }

  public setPageHtml(html: string): void {
    document.body.innerHTML = html;
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.submit();
  }
}
