import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GatewayService } from '../../../../shared/services/gateway.service';
import { environment } from '../../../../../environments/environment';
import { TealiumService } from '../../../../shared/services/tealium/tealium.service';

@Component({
  selector: 'app-fourth-step-apz',
  templateUrl: './fourth-step-apz.component.html',
  styleUrls: ['./fourth-step-apz.component.scss']
})
export class FourthStepApzComponent implements OnInit {
  @Input() saml: string;
  @Input() estMobile: boolean;
  @Input() login: string;

  loginIsEmail: boolean;

  constructor(private router: Router, private gatewayService: GatewayService, private readonly tealiumService: TealiumService) {}

  ngOnInit(): void {
    if (this.login) {
      this.loginIsEmail = this.login.includes('@');
    }
    if (this.estMobile) {
      if (this.loginIsEmail) {
        this.tealiumService.view('apz.mobile.registrationProcess.accountActivatedEmailPageLoad');
      } else {
        this.tealiumService.view('apz.mobile.registrationProcess.accountActivatedMobilePageLoad');
      }
    } else {
      if (this.loginIsEmail) {
        this.tealiumService.view('apz.web.registrationProcess.accountActivatedEmailPageLoad');
      } else {
        this.tealiumService.view('apz.web.registrationProcess.accountActivatedMobilePageLoad');
      }
    }
  }

  goLogin() {
    // Cas mobile : l'appli intercepte cette url et redirige sur la bonne page
    if (this.estMobile) {
      if (this.loginIsEmail) {
        this.tealiumService.link('apz.mobile.registrationProcess.accountActivatedEmailReturnLoginClick');
      } else {
        this.tealiumService.link('apz.mobile.registrationProcess.accountActivatedMobileReturnLoginClick');
      }
      window.location.href = 'app.beneficiaire.apetiz://accueil';
    } else {
      if (this.loginIsEmail) {
        this.tealiumService.link('apz.web.registrationProcess.accountActivatedEmailReturnLoginClick');
      } else {
        this.tealiumService.link('apz.web.registrationProcess.accountActivatedMobileReturnLoginClick');
      }
      this.gatewayService
        .authentificate(environment.clientIdAPZ, 'token', environment.redirectURIAPZ)
        .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
    }
  }

  public setPageHtml(html: string): void {
    document.body.innerHTML = html;
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.submit();
  }
}
