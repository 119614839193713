<div class="container-fluid vw-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
        <div class="d-flex flex-column">
            <span class="title-bp">Youpi !</span>
            <span class="sub-title-bp">Vous avez sélectionné <br>Cado !</span>
        </div>
    </div>

    <div class="d-block col-xs-12 col-sm-8 offset-sm-4" *ngIf="step === 1">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Informations entreprise</span>
            <span class="create-subtitle mt-4 mb-4">Complétez les champs suivants: </span>

            <form [formGroup]="enterpriseForm" (keydown.enter)="goToStep2(2)">
                <div class="inner_page">
                    <div class="form-group">
                        <label for="naf">Code NAF</label>
                        <input type="text" name="naf"
                               placeholder="Saisir votre code NAF"
                               id="naf" maxlength="30" class="form-control"
                               formControlName="naf">
                    </div>
                    <div class="input_group">
                        <label for="effectif">Effectif</label>
                        <input type="text" name="effectif"
                               placeholder="Saisir votre effectif"
                               id="effectif" maxlength="30" class="form-control"
                               formControlName="effectif" (keypress)="numericPhoneOnly($event)">
                    </div>
                </div>
                <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!enterpriseForm.valid" (click)="goToStep2(2)">
                    <span>Continuer</span>
                </button>
            </form>
        </div>
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4" *ngIf="step === 2">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Infos gestionnaire</span>
            <span class="create-subtitle mt-4 mb-4">Complétez les champs suivants: </span>

            <form [formGroup]="individualInfosForm" (keydown.enter)="goToStep2(2)">
                <div class="inner_page">
                    <div class="mb-4">
                        <div class="d-flex">
                            <div class="full-radio radio-button">
                                <input id="male" value="MR" type="radio" name="title"
                                       formControlName="title">
                                <label for="male">{{'USUAL.GENDER_VALUES.MR' | translate}}</label>
                            </div>
                            <div class="full-radio radio-button">
                                <input id="female" value="MS" type="radio" name="title"
                                       formControlName="title">
                                <label for="female">{{'USUAL.GENDER_VALUES.MS' | translate}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="prenom">{{'USUAL.FIRSTNAME' | translate}} *</label>
                        <input type="text" name="prenom"
                               placeholder="{{'USUAL.PH_FIRSTNAME' | translate}}"
                               id="prenom" maxlength="30" class="form-control"
                               formControlName="firstName" (keypress)="formatName($event)">
                        <div *ngIf="(individualInfosForm.get('firstName').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('firstName').errors">Prénom requis</small>
                        </div>
                    </div>
                    <div class="input_group">
                        <label for="nom">{{'USUAL.LASTNAME' | translate}} *</label>
                        <input type="text" name="nom"
                               placeholder="{{'USUAL.PH_LASTNAME' | translate}}"
                               id="nom" maxlength="30" class="form-control"
                               formControlName="lastName" (keypress)="formatName($event)">
                        <div *ngIf="(individualInfosForm.get('lastName').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('lastName').errors">Nom requis</small>
                        </div>
                    </div>
                </div>

                <div class="input_group">
                    <div class="mb-4">
                        <label for="type">Type * </label>
                        <div class="d-flex">
                            <select (change)="onCustomerTypeChange($event.target.value)"
                                    class="form-select form-control" formControlName="type" id="type">
                                <option *ngFor="let type of listType"
                                        [value]="type">{{'CREATION_ACCOUNT.SECOND_STEP.CUSTOMER_TYPE_ENUM.' + type | translate}}</option>
                            </select>
                        </div>
                        <small *ngIf="chorusPublicCheckError" class="error">{{getChorusPublicErrorText()}}</small>
                        <small *ngIf="chorusPriveCheckError" class="error">{{getChorusPriveErrorText()}}</small>
                    </div>
                </div>
                <div *ngIf="this.isSecteurPublicSelected() && !chorusPublicCheckError" class="input_group">
                    <label for="numMarche">{{'CREATION_ACCOUNT.SECOND_STEP.NUM_MARCHE' | translate}}</label>
                    <input formControlName="numMarche" id="numMarche"
                           name="numMarche" class="form-control" maxlength="10"
                           placeholder="{{'CREATION_ACCOUNT.SECOND_STEP.NUM_MARCHE_HOLDER' | translate}}" type="text">
                </div>

                <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!isIndividualInfosFormValid()" (click)="goToStep2(3)">
                    <span>Continuer</span>
                </button>
            </form>
        </div>
    </div>

    <div class="d-block col-xs-12 col-sm-8 offset-sm-4" *ngIf="step === 3">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Créez votre Identifiant Bimpli</span>
            <span class="create-subtitle mt-4 mb-4">Définissez vos identifiants Bimpli.</span>

            <form [formGroup]="createAccountForm" (keydown.enter)="validate()">
                <div class="inner_page">
                    <div class="input_group">
                        <label for="email">Email</label>
                        <input type="email" disabled="true" class="form-control" name="email" placeholder="Saisir votre email" id="email"
                               formControlName="login" maxlength="70"
                               onkeyup="this.value = this.value.toLowerCase();">
                        <div *ngIf="(createAccountForm.get('login').dirty && createAccountForm.get('login').errors)">
                            <small class="form-text text-danger" *ngIf="createAccountForm.get('login').errors?.required">Email
                                requis</small>
                            <small class="form-text text-danger" *ngIf="createAccountForm.get('login').errors?.pattern">Email
                                invalide</small>
                        </div>
                    </div>
                    <div class="input_group">
                        <label for="password">Mot de passe</label>
                        <div class="input-group">
                            <input [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="password" placeholder="Saisir votre mot de passe" id="password"
                                   formControlName="password" maxlength="60" minlength="8">
                            <div class="input-group-append">
                                <button
                                    *ngIf="(isPasswordDisplayed$ | async) === false"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isPasswordDisplayed$.next(true)">
                                    <i class="bi bi bi-eye-fill"></i>
                                </button>
                                <button
                                    *ngIf="(isPasswordDisplayed$ | async) === true"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isPasswordDisplayed$.next(false)">
                                    <i class="bi bi-eye-slash-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="error-message p-4 mb-4">
                        <div class="validation mb-4">Validation de votre mot de passe : </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : this.isLessThanEightChars(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                          'icon-check' : !this.isLessThanEightChars(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.EIGHT_CHARS' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneLowerCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                          'icon-check' : this.hasAtLeastOneLowerCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.LOWER_CASE' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneUpperCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                'icon-check' : this.hasAtLeastOneUpperCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.UPPER_CASE' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneDigit(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                'icon-check' : this.hasAtLeastOneDigit(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.DIGIT' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneSpecialChar(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                'icon-check' : this.hasAtLeastOneSpecialChar(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.SPECIAL_CHAR' | translate}}</span>
                            </i>
                        </div>
                    </div>
                    <div class="input_group">
                        <label for="confirm_password">Confirmation du mot de passe</label>
                        <div class="input-group">
                            <input [type]="(isConfirmPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="confirm_password"
                                   placeholder=" Saisir à nouveau votre mot de passe" id="confirm_password"
                                   formControlName="confirm_password">
                            <div class="input-group-append">
                                <button
                                    *ngIf="(isConfirmPasswordDisplayed$ | async) === false"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isConfirmPasswordDisplayed$.next(true)">
                                    <i class="bi bi bi-eye-fill"></i>
                                </button>
                                <button
                                    *ngIf="(isConfirmPasswordDisplayed$ | async) === true"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isConfirmPasswordDisplayed$.next(false)">
                                    <i class="bi bi-eye-slash-fill"></i>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="createAccountForm.get('confirm_password').dirty">
                            <small class="form-text text-danger"
                                   *ngIf="createAccountForm.get('confirm_password').value != createAccountForm.get('password').value">Mot de passe de confirmation invalide</small>
                        </div>
                    </div>

                    <div class="error-message p-4 mb-4">
                        <div class="validation mb-4">Partage de données et CGU de Bimpli</div>
                        <div class="form-check pl-0 row align-items-center justify-content-center">
                            <input class="mr-2 ml-2 form-check-input" type="checkbox" formControlName="acceptShare" id="acceptShare"/>
                            <label class="col cgu" for="acceptShare"> J’accepte le partage d’informations de mes données Cadostore et mon compte Bimpli.</label>
                        </div>
                        <div class="form-check pl-0 row align-items-center justify-content-center">
                            <input class="mr-2 ml-2 form-check-input" type="checkbox" formControlName="acceptCGU" id="acceptCGU"/>
                            <label class="col cgu" for="acceptCGU"> J'accepte les <a (click)="getCGU()">Conditions générales d'utilisation</a> de Bimpli.</label>
                        </div>
                    </div>
                </div>
                <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!createAccountForm.valid" (click)="validate()">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div *ngIf="isLoading" class="spinner-border"></div>
                        <span [ngClass]="{'ml-2': isLoading }">Continuer</span>
                    </div>
                </button>
            </form>
        </div>
    </div>
</div>


<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>

