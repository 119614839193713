import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '@services/login/login.service';
import { SessionQuery } from '@services/store/session/session.query';
import { TealiumService } from '@services/tealium/tealium.service';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { LoginHelper } from '@shared/helpers/login.helper';
import { ForgottenPassword } from '@shared/models/forgotten-password.model';
import { Subscription } from 'rxjs';
import { catchError, tap, finalize } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-cesu-confirmation-pwd',
  templateUrl: './cesu-confirmation-pwd.component.html',
  styleUrls: ['./cesu-confirmation-pwd.component.scss', '../../../../assets/scss/cesu/cesu-global.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CesuConfirmationPwdComponent implements OnInit, OnDestroy {
  @Input() waiting: boolean;
  @Input() invalidLink: boolean;
  @Output() reSendForgottenPassword: EventEmitter<any> = new EventEmitter<any>();

  private subscription = new Subscription();

  public forgottenPasswordInfo: ForgottenPassword;

  public passwordForm: FormGroup;
  public submitted = false;
  public errorMessage: string;

  private valueCaptcha: string;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly loginService: LoginService,
    private readonly tealiumService: TealiumService,
    private readonly sessionQuery: SessionQuery,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group(
      {
        password: [undefined, [validatePassword()]],
        passwordConfirmation: [undefined, [Validators.required]]
      },
      { validators: [LoginHelper.checkPasswords] }
    );

    const forgottenPasswordSubscription = this.sessionQuery.selectedForgottenPasswordInfo
      .pipe(tap((forgottenPasswordInfo: ForgottenPassword) => (this.forgottenPasswordInfo = forgottenPasswordInfo)))
      .subscribe();

    this.subscription.add(forgottenPasswordSubscription);

    if (this.invalidLink) {
      this.tealiumService.view('cesu.forgottenPassword.newPasswordInvalidLinkPageLoad');
    } else {
      this.tealiumService.view('cesu.forgottenPassword.newPasswordPageLoad');
    }
  }

  public get form() {
    return this.passwordForm.controls;
  }

  public registerNewPassword() {
    this.submitted = true;
    this.tealiumService.link('cesu.forgottenPassword.validateNewPasswordClick');

    if (this.passwordForm.valid) {

      this.reCaptchaV3Service
      .execute('ConnecterAction')
      .pipe(finalize(() => {
        this.loginService
        .setNewForgottenPassword(
          this.forgottenPasswordInfo.userId,
          this.passwordForm.value.password,
          this.forgottenPasswordInfo.hash,
          this.forgottenPasswordInfo.tokenType,
          this.valueCaptcha
        )
        .pipe(
          tap(() => {
            this.router.navigate([`${RoutesEnum.CHANGE_FORGOTTEN_PASSWORD_SUCCESS}/${this.forgottenPasswordInfo.saml}`], {
              queryParams: { hash: this.forgottenPasswordInfo.hash }
            });
          }),
          catchError((err) => {
            throw err;
          })
        )
        .subscribe();
    }
      ))
      .subscribe((token) => (this.valueCaptcha = token));

    }
  }

  public resendMail(): void {
    this.tealiumService.link('cesu.forgottenPassword.newPasswordInvalidLinkResendMailClick');
    this.reSendForgottenPassword.emit();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
