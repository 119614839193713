<div id="global-container" class="container-fluid vh-100 vw-100 pl-4 pr-4">
  <div class="d-flex flex-row align-items-center justify-content-start pt-4 pb-4">
    <button id="go-back-btn" type="button" class="btn btn-link" (click)="goBack()">
      <div class="d-flex flex-row align-items-center justify-content-center">
        <span id="go-back-icon" class="material-icons-outlined">
          arrow_back
        </span>
        <span id="go-back-label" class="ml-2"> {{ 'COMMON.FORM.BACK' | translate }}</span>
      </div>
    </button>
  </div>
  <span id="title-page">
    {{ 'AFFILIE_APZ.CHANGE_ID.TITLE' | translate }}
  </span>
  <form name="passwordForm" [formGroup]="passwordForm" class="d-flex flex-row align-items-center justify-content-start mt-2" novalidate>
    <!-- FIRST STEP -->
    <div id="first-step" *ngIf="isFirstStep">
      <label class="form-label description-txt form-element" for="currentPassword">{{
        'AFFILIE_APZ.CHANGE_PWD.FIRST_STEP.TEXT' | translate
      }}</label>
      <div class="input-group form-element">
        <input
          [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'"
          class="form-control  form-element"
          id="currentPassword"
          name="currentPassword"
          formControlName="currentPassword"
          placeholder="{{ 'AFFILIE_APZ.LOGIN.PASSWORD_PROMPT' | translate }}"
          required
          (keydown.enter)="goToSecondStep()"
        />
        <div class="input-group-append">
          <button
            *ngIf="(isPasswordDisplayed$ | async) === false"
            type="button"
            class="btn btn-light input-group-text"
            (click)="isPasswordDisplayed$.next(true)"
          >
            <i class="bi bi bi-eye-fill"></i>
          </button>
          <button
            *ngIf="(isPasswordDisplayed$ | async) === true"
            type="button"
            class="btn btn-light input-group-text"
            (click)="isPasswordDisplayed$.next(false)"
          >
            <i class="bi bi-eye-slash-fill"></i>
          </button>
        </div>
      </div>
      <small
        class="text-danger error-msg"
        *ngIf="
          passwordForm.get('currentPassword').touched &&
          passwordForm.get('currentPassword').invalid &&
          passwordForm.get('currentPassword').errors.required
        "
      >
        {{ 'COMMON.FORM.REQUIRED' | translate }}
      </small>
      <small class="text-danger error-msg" *ngIf="currentPasswordInvalid">
        {{ 'APZ.CHANGEMENT_MDP.MDP_INCORRECT' | translate }}
      </small>
      <div class="d-flex flex-row align-items-center justify-content-start w-100 pt-3">
        <button type="button" class="btn btn-primary form-btn" (click)="goToSecondStep()">
          <div class="d-flex flex-row align-items-center justify-content-center">
            <div *ngIf="isLoading" class="spinner-border"></div>
            <span
              [ngClass]="{
                'ml-2': isLoading
              }"
              id="submit-btn-label"
              >{{ 'AFFILIE_APZ.CHANGE_ID.BTN.VALIDATE' | translate }}</span
            >
          </div>
        </button>
      </div>
    </div>
    <!-- SECOND STEP -->
    <div id="second-step" *ngIf="!isFirstStep">
      <div class="d-flex flex-column align-items-start justify-content-center w-100">
        <!-- PASSWORD -->
        <div class="d-flex flex-column align-items-start justify-content-center w-100">
          <label class="form-label description-txt form-element" for="newPassword">{{
            'AFFILIE_APZ.CHANGE_PWD.SECOND_STEP.TEXT' | translate
          }}</label>
          <div class="input-group">
            <input
              [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'"
              class="form-control form-element"
              id="newPassword"
              name="newPassword"
              formControlName="newPassword"
              placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.SECOND_STEP.PASSWORD_PROMPT' | translate }}"
              (keyup)="controlPassWord()"
              [ngClass]="{
                'is-invalid': passwordForm.get('newPassword').invalid === true && passwordForm.get('newPassword').touched === true,
                'is-valid': passwordForm.get('newPassword').invalid === false && passwordForm.get('newPassword').touched === true
              }"
            />
            <div class="input-group-append">
              <button
                *ngIf="(isPasswordDisplayed$ | async) === false"
                type="button"
                class="btn btn-light input-group-text"
                (click)="isPasswordDisplayed$.next(true)"
              >
                <i class="bi bi bi-eye-fill"></i>
              </button>
              <button
                *ngIf="(isPasswordDisplayed$ | async) === true"
                type="button"
                class="btn btn-light input-group-text"
                (click)="isPasswordDisplayed$.next(false)"
              >
                <i class="bi bi-eye-slash-fill"></i>
              </button>
            </div>
          </div>
          <small
            class="error-msg text-danger"
            *ngIf="
              passwordForm.get('newPassword').touched &&
              passwordForm.get('newPassword').invalid &&
              passwordForm.get('newPassword').errors.required
            "
          >
            {{ 'COMMON.FORM.REQUIRED' | translate }}
          </small>
        </div>
        <!-- CONFIRM PASSWORD -->
        <div class="d-flex flex-column align-items-start justify-content-center w-100 mt-2">
          <label class="form-label description-txt form-element" for="confirmPassword">{{
            'AFFILIE_APZ.CHANGE_PWD.SECOND_STEP.TEXT_CONFIRM' | translate
          }}</label>
          <div class="input-group">
            <input
              [type]="(isConfirmPasswordDisplayed$ | async) === false ? 'password' : 'text'"
              class="form-control form-element"
              id="confirmPassword"
              name="confirmPassword"
              formControlName="confirmPassword"
              placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.SECOND_STEP.CONFIRM_PASSWORD_PROMPT' | translate }}"
              (keyup)="controlPassWord()"
              [ngClass]="{
                'is-invalid':
                  (passwordForm.get('confirmPassword').invalid === true || !passwordEqual) &&
                  passwordForm.get('confirmPassword').touched === true,
                'is-valid': passwordForm.get('confirmPassword').invalid === false && passwordForm.get('confirmPassword').touched === true
              }"
              required
            />
            <div class="input-group-append">
              <button
                *ngIf="(isConfirmPasswordDisplayed$ | async) === false"
                type="button"
                class="btn btn-light input-group-text"
                (click)="isConfirmPasswordDisplayed$.next(true)"
              >
                <i class="bi bi bi-eye-fill"></i>
              </button>
              <button
                *ngIf="(isConfirmPasswordDisplayed$ | async) === true"
                type="button"
                class="btn btn-light input-group-text"
                (click)="isConfirmPasswordDisplayed$.next(false)"
              >
                <i class="bi bi-eye-slash-fill"></i>
              </button>
            </div>
          </div>
          <small
            class="error-msg text-danger"
            *ngIf="
              passwordForm.get('confirmPassword').touched &&
              passwordForm.get('confirmPassword').errors &&
              passwordForm.get('confirmPassword').errors.required
            "
          >
            {{ 'COMMON.FORM.REQUIRED' | translate }}
          </small>
          <small
            class="error-msg text-danger"
            *ngIf="passwordForm.get('confirmPassword').touched && passwordForm.get('confirmPassword').errors && !passwordEqual"
          >
            {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
          </small>
        </div>
      </div>
      <div class="d-flex flex-column align-items-start justify-content-center w-100 mt-2">
        <!-- VALIDATION PASSWORD -->
        <div class="d-flex flex-column align-items-start justify-content-start">
          <span class="d-flex flex-row form-label">{{ 'CREATION_ACCOUNT.SECOND_STEP.VALIDATION' | translate }}</span>
          <app-password-validation
            [passwordValue]="passwordForm.get('newPassword').value"
            [affLastPassword]="false"
            [progressBar]="true"
            [submitted]="submittedForm"
          >
          </app-password-validation>
        </div>
        <!-- VALIDATION -->
        <div class="d-flex flex-row align-items-center justify-content-start w-100">
          <button type="button" class="btn btn-primary form-btn" [disabled]="!passwordForm.valid || !passwordEqual" (click)="submitForm()">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <div *ngIf="isLoading" class="spinner-border"></div>
              <span
                [ngClass]="{
                  'ml-2': isLoading
                }"
                id="submit-btn-label"
                >{{ 'CREATION_ACCOUNT.VALIDER' | translate }}</span
              >
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
