<div class="background-from-app container-fluid vw-100 d-flex flex-column">
    <app-snackbar></app-snackbar>
    <div *ngIf="applicationId !== applicationCodeEnum.BENEFIT_MOBILE" class="bloc-action-back d-flex align-items-center" (click)="back()">
        <i class="bi bi-arrow-left"></i>
        <span class="ml-2">Retour</span>
    </div>
    <span class="create mt-4">Nouveau mot de passe</span>

    <form [formGroup]="passwordForm" (keydown.enter)="validerUpdatePwd()" class="justify-content-start col-12 col-md-6 p-0">
        <div class="inner_page mt-4">
            <label for="currentPassword">Mot de passe actuel</label>
            <div class="input-group">
                <input [type]="(isActualPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="currentPassword" placeholder="Saisir votre mot de passe actuel" id="currentPassword"
                       formControlName="currentPassword">
                <div class="input-group-append">
                    <button
                        *ngIf="(isActualPasswordDisplayed$ | async) === false"
                        type="button"
                        class="btn input-group-text"
                        (click)="isActualPasswordDisplayed$.next(true)"
                    >
                        <i class="bi bi bi-eye-fill"></i>
                    </button>
                    <button
                        *ngIf="(isActualPasswordDisplayed$ | async) === true"
                        type="button"
                        class="btn input-group-text"
                        (click)="isActualPasswordDisplayed$.next(false)">
                        <i class="bi bi-eye-slash-fill"></i>
                    </button>
                </div>
            </div>

            <label class="mt-4" for="password">Nouveau mot de passe</label>
            <div class="input-group">
                <input [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="password" placeholder="Saisir votre nouveau mot de passe" id="password"
                       formControlName="newPassword" maxlength="60" minlength="8">
                <div class="input-group-append">
                    <button
                        *ngIf="(isPasswordDisplayed$ | async) === false"
                        type="button"
                        class="btn input-group-text"
                        (click)="isPasswordDisplayed$.next(true)"
                    >
                        <i class="bi bi bi-eye-fill"></i>
                    </button>
                    <button
                        *ngIf="(isPasswordDisplayed$ | async) === true"
                        type="button"
                        class="btn input-group-text"
                        (click)="isPasswordDisplayed$.next(false)">
                        <i class="bi bi-eye-slash-fill"></i>
                    </button>
                </div>
            </div>

            <div class="form-group mt-4">
                <label for="confirm_password">Confirmez votre nouveau mot de passe</label>
                <div class="input-group">
                    <input [type]="(isConfirmPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="confirm_password"
                           placeholder=" Saisir à nouveau votre nouveau mot de passe" id="confirm_password"
                           formControlName="confirmPassword">
                    <div class="input-group-append">
                        <button
                            *ngIf="(isConfirmPasswordDisplayed$ | async) === false"
                            type="button"
                            class="btn input-group-text"
                            (click)="isConfirmPasswordDisplayed$.next(true)"
                        >
                            <i class="bi bi bi-eye-fill"></i>
                        </button>
                        <button
                            *ngIf="(isConfirmPasswordDisplayed$ | async) === true"
                            type="button"
                            class="btn input-group-text"
                            (click)="isConfirmPasswordDisplayed$.next(false)">
                            <i class="bi bi-eye-slash-fill"></i>
                        </button>
                    </div>
                </div>
                <div *ngIf="passwordForm.get('confirmPassword').dirty">
                    <small class="form-text text-danger"
                           *ngIf="passwordForm.get('confirmPassword').value != passwordForm.get('newPassword').value">Mot de passe de confirmation invalide</small>
                </div>
            </div>

            <div class="error-message p-4 mb-4">
                <div class="validation mb-4">Validation de votre mot de passe : </div>
                <div class="message_item">
                    <i [ngClass]="{'icon-cross' : this.isLessThanEightChars(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty || !passwordForm.get('newPassword').dirty,
                              'icon-check' : !this.isLessThanEightChars(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty}">
                        <span>{{'COMMON.FORM.PASS_ERR.EIGHT_CHARS' | translate}}</span>
                    </i>
                </div>
                <div class="message_item">
                    <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneLowerCase(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty || !passwordForm.get('newPassword').dirty,
                              'icon-check' : this.hasAtLeastOneLowerCase(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty}">
                        <span>{{'COMMON.FORM.PASS_ERR.LOWER_CASE' | translate}}</span>
                    </i>
                </div>
                <div class="message_item">
                    <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneUpperCase(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty || !passwordForm.get('newPassword').dirty,
                    'icon-check' : this.hasAtLeastOneUpperCase(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty}">
                        <span>{{'COMMON.FORM.PASS_ERR.UPPER_CASE' | translate}}</span>
                    </i>
                </div>
                <div class="message_item">
                    <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneDigit(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty || !passwordForm.get('newPassword').dirty,
                    'icon-check' : this.hasAtLeastOneDigit(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty}">
                        <span>{{'COMMON.FORM.PASS_ERR.DIGIT' | translate}}</span>
                    </i>
                </div>
                <div class="message_item">
                    <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneSpecialChar(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty || !passwordForm.get('newPassword').dirty,
                    'icon-check' : this.hasAtLeastOneSpecialChar(passwordForm.get('newPassword').value) && passwordForm.get('newPassword').dirty}">
                        <span>{{'COMMON.FORM.PASS_ERR.SPECIAL_CHAR' | translate}}</span>
                    </i>
                </div>
            </div>
        </div>
        <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!passwordForm.valid" (click)="validerUpdatePwd()">
            <div class="d-flex flex-row align-items-center justify-content-center">
                <div *ngIf="isLoading" class="spinner-border"></div>
                <span [ngClass]="{'ml-2': isLoading }">Continuer</span>
            </div>
        </button>
    </form>
</div>

<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>
