import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-siret-not-found',
  templateUrl: './siret-not-found.component.html',
  styleUrls: ['./siret-not-found.component.scss']
})
export class SiretNotFoundComponent {

  @Output() closePanel$ = new EventEmitter<void>();

  public goBack(): void {
    this.closePanel$.next();
  }
}
