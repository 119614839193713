<app-cesu-layout>
  <ng-container *ngIf="waiting && !invalidLink">
    <div class="row text-center pt-2 pt-sm-4 pt-lg-5">
      <div class="col-12 cesu-page-title">
        En attente
      </div>
      <hr class="cesu-separator" />
      <div class="col-12">
        <div class="spinner-border text-primary cesu-spinner" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!waiting && invalidLink">
    <div class="row text-center pt-2 pt-sm-4 pt-lg-5">
      <div class="col-12 cesu-page-title">
        Lien invalide
      </div>
      <hr class="cesu-separator" />
      <div class="col-12">
        Une nouvelle demande de modification de mot de passe est peut-être en cours.
        <br />
        <br />
        Ou
        <br />
        <br />
        La durée de validité, de 2h, du lien de changement de mot de passe a été dépassée.
        <br />
        <br />
        <a class="font-weight-bold" (click)="resendMail()">Cliquez ici pour recevoir un nouveau lien dans votre boîte mail.</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!waiting && !invalidLink">
    <div class="row text-center pt-2 pt-sm-4 pt-lg-5">
      <div class="col-12 cesu-page-title">
        Choisissez votre nouveau mot de passe
      </div>
      <hr class="cesu-separator" />
    </div>

    <form class="row pt-4 pt-lg-5" [formGroup]="passwordForm" (ngSubmit)="registerNewPassword()">
      <div class="col-12">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-7 col-12 form-group">
            <div class="alert alert-danger" *ngIf="errorMessage">
              {{ errorMessage }}
            </div>
            <input
              class="form-control cesu-input"
              [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
              type="password"
              placeholder="Mot de passe"
              formControlName="password"
            />
            <div *ngIf="form.password.errors && submitted" class="invalid-feedback cesu-error">
              <div *ngIf="form.password.errors.required">
                <img src="../../../../assets/cesu/error_icon.svg" alt="Error" />
                &nbsp;Le mot de passe est obligatoire
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-7 col-12 form-group">
            <input
              class="form-control cesu-input"
              [ngClass]="{ 'is-invalid': submitted && (form.passwordConfirmation.errors || passwordForm.errors) }"
              type="password"
              placeholder="Confirmation du mot de passe"
              formControlName="passwordConfirmation"
            />
            <div *ngIf="form.passwordConfirmation.errors && submitted" class="invalid-feedback cesu-error">
              <div *ngIf="form.passwordConfirmation.errors.required">
                <img src="../../../../assets/cesu/error_icon.svg" alt="Error" />
                &nbsp;La confirmation du mot de passe est obligatoire
              </div>
            </div>
            <div *ngIf="passwordForm.errors && !form.passwordConfirmation.errors && submitted" class="invalid-feedback cesu-error">
              <div *ngIf="passwordForm.errors.notSame">
                <img src="../../../../assets/cesu/error_icon.svg" alt="Error" />
                &nbsp;Les mots de passe ne sont pas les mêmes.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row justify-content-center">
          <div class="col-auto">
            <app-password-validation
              [passwordValue]="form.password.value"
              [affLastPassword]="false"
              [progressBar]="true"
            ></app-password-validation>
          </div>
        </div>
      </div>
      <div class="col-12 text-center pt-4">
        <button class="btn cesu-button cesu-flat-button" type="submit">Valider</button>
      </div>
    </form>
  </ng-container>
</app-cesu-layout>
