import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-full-width-btn',
  templateUrl: './full-width-btn.component.html',
  styleUrls: ['./full-width-btn.component.scss']
})
export class FullWidthBtnComponent {
  
  @Input() text: string;
  @Input() disabled!: boolean;
  @Output() click$ = new EventEmitter<void>();

  public onClick(): void {
    this.click$.emit();
  }

}
