<app-cesu-layout xmlns="http://www.w3.org/1999/html">
    <div class="cesu-cgu">
        <div class="cesu-page-title pt-5 pb-4">
            Conditions générales d’utilisation
        </div>

        <div class="cesu-container-title pl-4 pb-4">
            Information sur les produits et les services
        </div>

        <div class="bg-white p-5">
            <p>
                Aucune information apparaissant sur le présent site ne saurait être considérée comme une offre de
                services ou de produits émanant de Bimpli. Toute personne désireuse de se procurer un des
                services ou produits présentés ici est priée de contacter le service concerné afin de s’informer de la
                disponibilité du service ou produit en question ainsi que des conditions contractuelles et des tarifs
                qui lui sont applicables.<br>
                L’accès aux produits et services décrits sur le présent site peut faire l’objet de restrictions à
                l’égard de certaines personnes ou dans certains pays, notamment en raison de contraintes légales ou
                réglementaires. L’utilisateur est prié de s’assurer qu’il est juridiquement autorisé à se connecter au
                présent site dans le pays à partir duquel la connexion est établie.<br>
                Bimpli s’efforce d’assurer l’exactitude et la mise à jour des informations diffusées sur
                son site, dont elle se réserve le droit de corriger ou de mettre à jour, à tout moment et sans préavis,
                le contenu. Elle ne peut cependant en garantir l’exhaustivité ou l’absence de modification par un tiers
                (virus, intrusion). En outre, elle décline toute responsabilité en cas d’erreur ou d’omission dans ces
                informations.<br>
                Le présent site vous est fourni sur la base d’un service « en l’état de l’art » et accessible en
                fonction de sa disponibilité, la société Bimpli n’étant aucunement responsable d’une
                quelconque difficulté de transmission ou de toute perturbation du réseau.
            </p>

            <p>
                Bimpli ne fournit aucune garantie expresse ou implicite, notamment et non limitativement,
                sur le fait que le présent site soit conforme à vos attentes, dans le présent et à l’avenir, ni ne
                garantit qu’il soit ininterrompu, opportun, sûr, ou dépourvu de toute erreur, ni que les résultats qui
                peuvent être obtenus en utilisant le présent site soient exacts et fiables, dans le présent et à
                l’avenir.<br>
                Bimpli ne garantit aucunement la qualité et/ou la licéité du contenu non créé par elle, et
                se dégage de toute responsabilité quant aux éventuels contestations, actions ou recours de tiers se
                prévalant, notamment et non limitativement, de droits privatifs sur ledit contenu et/ou son utilisation.<br>
                Aucun conseil et aucune information, oraux et/ou écrits et/ou vidéos, obtenus par vous lors de
                l’utilisation du site ne sont susceptibles de créer des garanties et responsabilités non expressément
                prévues.<br>
                La responsabilité de l’utilisation de tout ou partie des informations contenues dans ce site et les
                sites qui lui sont liés est uniquement et intégralement celle de l’utilisateur.
            </p>
        </div>

        <div class="cesu-container-title pl-4 pt-5 pb-4">
            Protection des données personnelles
        </div>

        <div class="bg-white p-5">
            <p>A partir du présent site, Bimpli, en sa qualité de responsable de traitement, peut être
                amené à collecter et traiter des informations vous identifiant (par exemple : votre nom, vos coordonnées
                personnelles, votre adresse IP).</p>

            <p>
                Ces informations sont ci-après dénommées ‘Données à Caractère Personnel’ ou ‘Données’.<br>
                Dans ce but, Bimpli veille en permanence au respect des règles légales sur la protection
                des Données à caractère Personnel (le Règlement général sur la protection des Données 2016/679 du 27
                avril 2016 et la loi Informatique et libertés 78-17 du 6 janvier 1978 modifiée) et entend assurer une
                gouvernance responsable de ses fichiers informatiques ainsi que la plus grande transparence sur les
                traitements de Données qu’elle opère.<br>
                Bimpli a désigné un Délégué à la Protection des Données Personnelles (ou DPO). Il veille à
                ce que les traitements des Données à caractère personnel mis en œuvre par Bimpli respectent
                la réglementation applicable.
            </p>

            <p>
                <span class="font-weight-bold">Qui sont les personnes dont les Données sont collectées ?</span><br>
                Les utilisateurs du présent site.
            </p>

            <p>
                <span
                    class="font-weight-bold">Quelles Données Natixis utilise-t-elle et d’où viennent-elles ?</span><br>
                Seules des Données d’identification, de contact et de connexion seront recueillies par Natixis
                Intertitres dans le cadre des services proposés sur le présent site. Par ailleurs, toutes les Données
                recueillies seront fournies par les utilisateurs du site.
            </p>

            <p>
                <span class="font-weight-bold">A quelles fins et sur quels fondements vos Données sont-elles collectées et utilisées ?</span><br>
                Le traitement de Données opérés par Bimpli sont faits sur la base d’un ou plusieurs des
                fondements suivants :
            </p>

            <ul>
                <li>l’exécution de mesures précontractuelles prises à la demande du client ou de l’affilié ;</li>
                <li>l’exécution de la relation contractuelle liant le client ou l’affilié à Bimpli ;</li>
                <li>la réalisation des intérêts légitimes poursuivis par Bimpli ;</li>
                <li>la conformité de Bimpli face aux obligations légales lui incombant en vertu de la
                    législation et de la réglementation en vigueur ;
                </li>
                <li>votre consentement explicite.</li>
            </ul>

            <p>
                Ainsi, en remplissant notre formulaire de contact, vous consentez à ce que Bimpli recueille
                et traite vos Données afin de pouvoir vous identifier et répondre à votre demande.
            </p>

            <p>
                <span class="font-weight-bold">Qui a accès à vos Données ?</span><br>
                Bimpli prend toutes les mesures nécessaires pour garantir la sécurité des Données qu’elle a
                collectées mais aussi leur confidentialité, c’est-à-dire s’assurer que seules les personnes autorisées y
                accèdent.<br>
                Seules les personnes habilitées en raison de leurs activités au sein des services compétents de Natixis
                Intertitres, en charge des traitements correspondants, ont accès à vos Données dans la limite de leurs
                habilitations.<br>
                De même, nos prestataires informatiques peuvent avoir accès à vos Données en tant que de besoin et de
                manière sécurisée dans le cadre de l’exécution de leur prestation ou de notre collaboration.<br>
                Certaines autorités se verront également communiquer vos Données, conformément aux lois et
                réglementations applicables.
            </p>

            <p>
                <span class="font-weight-bold">Combien de temps sont conservées vos Données ?</span><br>
                Données en lien avec le formulaire de contact : maximum 1 an<br>
                <br>
                Pour plus d’information, reportez-vous à la notice d’information relative aux données à caractère
                personnel figurant sur le présent site.
            </p>

            <p>
                <span class="font-weight-bold">Quels sont vos droits sur vos Données?</span><br>
                Dans les limites et conditions autorisées par la réglementation en vigueur, vous pouvez:<br>
                – Accéder à l’ensemble de vos Données,<br>
                – Rectifier, mettre à jour et effacer vos Données, sous réserve de motifs légitimes,<br>
                – Vous opposer au traitement de vos Données pour motifs légitimes et au traitement de vos Données à des
                fins de prospection sans aucun motif,<br>
                – Demander la portabilité de vos Données, pour les traitements reposant sur votre consentement ou sur
                l’exécution d’un contrat conclu ou à conclure,<br>
                – Demander une limitation des traitements que nous opérons relatifs à vos Données,<br>
                – Retirer votre consentement à tout moment (pour tout traitement soumis à votre consentement),<br>
                – Introduire une réclamation auprès d’une autorité de contrôle compétente, à savoir celle du pays de
                l’Espace Economique Européen dans lequel se trouve votre résidence habituelle, ou votre lieu de travail
                ou le lieu où la violation présumée de la réglementation aurait été commise (en France, la CNIL :
                www.cnil.fr).<br>
                En outre, vous avez la possibilité de nous communiquer des directives relatives à la conservation, à
                l’effacement et à la communication de vos Données après votre décès, lesquelles directives peuvent être
                enregistrées également auprès « d’un tiers de confiance numérique certifié ». Ces directives, ou sorte
                de « testament numérique », peuvent désigner une personne chargée de leur exécution ; à défaut, vos
                héritiers seront désignés.
            </p>

            <p>
                <span class="font-weight-bold">Comment exercer vos droits ?</span><br>
                Pour exercer vos droits, merci de contacter notre Délégué à la Protection Des Données par courrier
                électronique ou postal en indiquant, nom, prénom, coordonnées de contact et en fournissant une copie de
                votre pièce d’identité.
            </p>

            <table class="table table-bordered text-center col-lg-6">
                <tbody>
                <tr>
                    <td colspan="3">Délégué à la Protection des Données</td>
                </tr>
                <tr>
                    <td>Adresse postale</td>
                    <td>Courriel</td>
                </tr>
                <tr>
                    <td>
                        Bimpli<br>
                        110 avenue de France<br>
                        75013 Paris
                    </td>
                    <td>dpo-bimpli@natixis.com</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="cesu-container-title pl-4 pt-5 pb-4">
            Cookies et traceurs
        </div>

        <div class="bg-white p-5">
            <p>
                Des cookies et des technologies similaires sont utilisés sur le site bimpli.com. Ceux-ci peuvent
                être déposés ou lus sur votre terminal (votre ordinateur, votre tablette, votre smartphone (téléphone
                mobile), etc.), sous réserve des choix que vous avez exprimés au préalable concernant les Cookies et que
                vous pouvez modifier à tout moment.
            </p>

            <span class="cesu-paragraph-title">1. Définitions</span><br>
            <span class="font-weight-bold">Qu’est-ce qu’un Cookie ? :</span><br>
            <br>
            <p>
                Un « Cookie » est un petit fichier texte enregistré sur votre terminal lors de votre visite sur notre
                site bimpli.com. Il est alors renvoyé à notre site bimpli.com à chacune de vos visites
                ultérieures ou bien à un autre site qui reconnaît ce cookie. Il a notamment comme but de collecter des
                informations relatives à votre navigation sur les sites ou vous adresser des services personnalisés.
                Ainsi, lorsque vous visitez le site bimpli.com pour chercher, lire ou télécharger de l’information,
                certains renseignements vous concernant peuvent être recueillis, tels le nom du domaine et de
                l’ordinateur hôte à partir duquel vous naviguez sur internet, l’adresse du protocole internet (IP) de
                l’ordinateur utilisé, la date et l’heure de la navigation et les URL à partir desquelles vous êtes
                passé.
            </p>

            <p>
                D’autres technologies similaires peuvent également être utilisées. Le terme de cookies doit être entendu
                au sens large comme couvrant l’ensemble des cookies et autres traceurs déposés et/ou lus, par exemple,
                lors de la consultation d’un site Internet ou de l’installation ou de l’utilisation d’un logiciel ou
                d’une application mobile.
            </p>

            <span class="font-weight-bold">Différents émetteurs possibles</span><br>
            <p><span class="font-weight-bold">Les Cookies de bimpli.com (cookies « first party ») :</span> Il
                s’agit des Cookies déposés par bimpli.com sur votre terminal pour répondre à des besoins de
                navigation et d’optimisation sur notre site bimpli.com.</p>

            <p><span class="font-weight-bold">Les Cookies tiers (ou « third party ») :</span> Il s’agit des Cookies
                déposés par des sociétés tierces, telles que des prestataires ou des partenaires. À titre d’exemple&nbsp;:
            </p>

            <p>
                Une page web peut contenir des composants stockés sur des serveurs d’autres domaines (images ou autres
                contenus intégrés : vidéos YouTube, diaporamas Flickr…). Ces sites peuvent déposer leurs propres Cookies
                lors de la récupération de ces composants externes à l’occasion de la navigation.<br>
                Un site internet peut utiliser les services d’une société tierce pour collecter des informations
                permettant d’analyser son audience, d’améliorer son ergonomie, de réaliser des études marketing ou de
                vous proposer des produits et services adaptés. Cette société définit alors ses propres cookies pour
                effectuer ces services.<br>
                Un site internet peut également utiliser un réseau tiers de publicité pour diffuser de la publicité.
                Aucun service de publicité n’est utilisé par le site bimpli.com.
            </p>

            <span
                class="cesu-paragraph-title">2. Différents types de Cookies utilisés sur le site bimpli.com</span><br>
            <p>
                Différents types de Cookies sont utilisés sur le site bimpli.com avec des finalités distinctes.
            </p>

            <p>
                <span class="font-weight-bold">Les Cookies strictement nécessaires ou facilitant la communication en ligne :</span>
                Il s’agit des Cookies utiles au fonctionnement du site bimpli.com et déposés par bimpli.com.
                Ils vous permettent d’utiliser les principales fonctionnalités du site bimpli.com (par exemple
                Cookies de session ou de gestion de langues). Ils enregistrent des informations relatives à la
                navigation sur le site bimpli.com effectuée à partir de l’ordinateur sur lequel est stocké le
                Cookie et ne sont pas conservés plus de 1 mois.
            </p>

            <p>
                Les « Cookies essentiels » à l’utilisation du site bimpli.com :
            </p>

            <table class="table table-bordered text-center col-lg-6">
                <tbody>
                <tr>
                    <td class="font-weight-bold">NOM COOKIE</td>
                    <td class="font-weight-bold">FONCTION</td>
                </tr>
                <tr>
                    <td>JSESSIONID</td>
                    <td>Identifiant de session</td>
                </tr>
                <tr>
                    <td>acceptCookie</td>
                    <td>Gestion des cookies</td>
                </tr>
                </tbody>
            </table>

            <p>
                <span class="font-weight-bold">Les Cookies de mesure d’audience :</span> Il s’agit de Cookies déposés à
                des fins statistiques. En particulier, nous mesurons le nombre de pages vues, le nombre de visites,
                ainsi que l’activité des visiteurs sur le site et leur fréquence de retour grâce aux solutions d’AT
                Internet®. Ces Cookies ne sont pas conservés plus de 13 mois.
            </p>

            <p>
                Les « Cookies de mesure d’audience » du site bimpli.com :
            </p>

            <table class="table table-bordered text-center">
                <tbody>
                <tr class="font-weight-bold">
                    <td>NOM COOKIE</td>
                    <td>FONCTION</td>
                    <td>FOURNISSEUR</td>
                </tr>
                <tr>
                    <td>_st</td>
                    <td>Mesure d'audience</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>_ss</td>
                    <td>Mesure d'audience</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>_pn</td>
                    <td>Mesure d'audience</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>_sn</td>
                    <td>Mesure d'audience</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>v_id</td>
                    <td>Tracking (suivi de l’activité et des actions )</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>ses_id</td>
                    <td>Session</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>utag_main_mycookievar</td>
                    <td>Cookie personnalisé</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>OPTOUTMULTI</td>
                    <td>E-privacy (conservation du consentement)</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>s_cc</td>
                    <td>Session</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>s_sq</td>
                    <td>Session</td>
                    <td>Tealium IQ</td>
                </tr>
                <tr>
                    <td>s_vi</td>
                    <td>Tracking (suivi de l’activité et des actions)</td>
                    <td>Tealium IQ</td>
                </tr>
                </tbody>
            </table>

            <span class="cesu-paragraph-title">3. Gérer les Cookies</span><br>

            <p>
                Vous pouvez à tout moment personnaliser les cookies du site bimpli.com :
            </p>

            <p>
                <a class="cesu-link">Gestion des cookies</a>
            </p>

            <p>
                Vous trouverez également sur le site de la CNIL les informations pour contrôler les cookies via les
                paramètres de votre navigateur :<br>
                <a class="cesu-link" href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</a>
            </p>
        </div>

        <div class="cesu-container-title pl-4 pt-5 pb-4">
            Propriété intellectuelle
        </div>

        <div class="bg-white p-5">
            <p>
                La présentation et le contenu du présent site constituent, ensemble, une œuvre protégée par les lois
                françaises et internationales en vigueur sur la propriété intellectuelle, dont Bimpli est
                titulaire des droits y afférant.<br>
                Les dénominations ou appellations, les logos ainsi que les slogans sont, sauf indications particulières,
                des marques déposées par le Groupe Banque Populaire ou Bimpli ou des tiers ayant autorisé
                cette dernière à les utiliser.<br>
                Les dessins, photographies, images, textes et autres documentations représentées sur le présent site
                sont objets de droits de propriété intellectuelle et sont, selon les cas, propriété de Natixis
                Intertitres ou de tiers ayant autorisé cette dernière à les utiliser.<br>
                Il en est de même des bases de données figurant au sein du site, dont le contenu est protégé notamment
                par les dispositions du Code de la propriété intellectuelle relatives à la protection juridique des
                bases de données.<br>
                Aucune exploitation commerciale, reproduction, représentation, utilisation, adaptation, modification,
                incorporation, traduction, commercialisation, partielle ou intégrale de ces éléments ne pourra en être
                faite sans l’accord préalable et écrit de Bimpli, à l’exception de l’utilisation pour un
                usage privé sous réserve des dispositions différentes voire plus restrictives du Code de la propriété
                intellectuelle et aux conditions suivantes :<br>
            </p>

            <ul>
                <li>utilisation à des fins d’information seulement,</li>
                <li>utilisation à des fins non commerciales,</li>
                <li>respect de l’intégrité des documents reproduits (ni modification, ni altération d’aucune sorte),
                </li>
                <li>mention des droits d’auteur de Bimpli lisible sur chaque exemplaire.</li>
            </ul>

            <p>
                La violation de l’un des droits de propriété intellectuelle est un délit de contrefaçon pouvant engager
                la responsabilité civile et pénale du contrefacteur, conformément aux dispositions du Code de la
                propriété intellectuelle.
            </p>
        </div>

        <div class="cesu-container-title pl-4 pt-5 pb-4">
            Liens hypertextes et virus
        </div>

        <div class="bg-white p-5">
            <p>
                Les liens hypertextes, éventuellement mis en place dans le cadre du présent site en direction d’autres
                sites présents sur le réseau Internet, ne sauraient engager la responsabilité de Natixis
                Intertitres.<br>
                En outre, les utilisateurs et visiteurs du site Bimpli ne peuvent mettre en place un
                hyperlien en direction du présent site sans l’autorisation expresse et préalable de Bimpli.<br>
                En tout état de cause, tout site ayant un lien hypertexte avec le présent site n’est pas sous le
                contrôle de Bimpli.<br>
                Bimpli ne peut en aucun cas être tenu pour responsable des sites ayant un lien hypertexte
                avec le présent site et décline toute responsabilité quant à leur contenu et à leur utilisation.<br>
                De façon générale, il appartient à l’utilisateur de prendre les précautions nécessaires pour s’assurer
                que le site sélectionné n’est pas infesté de virus ou de tout autre parasite de nature destructive. En
                aucun cas, Bimpli ne pourra être tenu responsable des dommages directs ou indirects
                résultant de l’usage de son site ou d’autres sites qui lui sont liés.
            </p>
        </div>

        <div class="cesu-container-title pl-4 pt-5 pb-4">
            Clause attributive de compétences
        </div>

        <div class="bg-white p-5">
            <p>Ce site est soumis à la loi française. En cas de litige, les tribunaux français seront seuls
                compétents.</p>
        </div>
    </div>
</app-cesu-layout>
