import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { MustMatch } from '@shared/validators/custom.validators';

@Component({
  selector: 'app-coordonnees-form',
  templateUrl: './coordonnees-form.component.html',
  styleUrls: ['./coordonnees-form.component.scss']
})
export class CoordonneesFormComponent implements OnInit {

  public contactForm: FormGroup;
  public showConfirmPassword = false;
  public showPassword = false;
  public submittedForm = false;
  public isLoading = false;

  @Input() saml!: string;
  @Input() affiliateEmail!: string;
  @Output() submitForm$ = new EventEmitter<FormGroup>();
  @Output() showRgpd$ = new EventEmitter<void>();
  @Output() openCgu$ = new EventEmitter<void>();

  constructor(private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public submitForm(): void {
    const formValue = this.contactForm.getRawValue();
    this.submitForm$.emit(this.contactForm);
  }

  public showRgpd(): void {
    this.showRgpd$.emit();
  }

  public openCgu(): void {
    this.openCgu$.emit();
  }

  private initForm(): void {
    this.contactForm = this.fb.group(
      {
        civility: new FormControl('MR'),
        role: new FormControl('Proprietaire', Validators.required),
        email: new FormControl(
          { value: this.affiliateEmail ? this.affiliateEmail : '' , disabled: !!this.affiliateEmail 
        }, 
        [
          Validators.required,
          Validators.email,
        ]),
        firstName: new FormControl('', [
          Validators.required,
          Validators.pattern('^[a-zA-Z][a-zA-Z ]{1,38}$'),
        ]),
        lastName: new FormControl('', [
          Validators.required,
          Validators.pattern('^[a-zA-Z][a-zA-Z ]{1,38}$'),
        ]),
        phoneNumber: new FormControl('', [
          Validators.minLength(1),
          Validators.maxLength(12),
          Validators.pattern('^[0](?!6|7)[1-9]{1}[0-9]{8}$|^[+][33]{1}(?!6|7)[1-9]{1}[0-9]{9}$'), 
        ]),
        cellPhoneNumber: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(12),
          Validators.pattern('^[0][6-7][0-9]{8}$|^[+][33]{1}[0-9]{10}$'),
        ]),
        password: ['', validatePassword()],
        confirmPassword: ['', Validators.required],
        cgu: [false, Validators.requiredTrue],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }
}
