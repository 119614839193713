<div class="background-from-app container-fluid vh-100 vw-100 d-flex flex-column">
    <app-snackbar></app-snackbar>
    <div *ngIf="applicationId !== applicationCodeEnum.BENEFIT_MOBILE" class="bloc-action-back d-flex align-items-center" (click)="back()">
        <i class="bi bi-arrow-left"></i>
        <span class="ml-2">Retour</span>
    </div>
    <span class="create mt-4">Nouvel identifiant</span>
    <span class="create-subtitle mt-4 mb-4" *ngIf="!isSecondStep">Saisissez votre mot de passe actuel pour pouvoir définir un nouvel identifiant :</span>
    <span class="create-subtitle mt-4 mb-4" *ngIf="isSecondStep">Saisissez une adresse mail valide qui sera votre nouvel identifiant. Un mail vous sera envoyé pour confirmer cette opération :</span>

    <form #passwordForm name="passwordForm" [formGroup]="identifiantForm" (submit)="submitForm()" class="justify-content-start col-12 col-md-6 p-0">
        <div class="input-group" [hidden]="isSecondStep">
            <input [type]="(isActualPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="password" placeholder="Saisir votre mot de passe actuel" id="password"
                   formControlName="currentPassword">
            <div class="input-group-append">
                <button
                    *ngIf="(isActualPasswordDisplayed$ | async) === false"
                    type="button"
                    class="btn input-group-text"
                    (click)="isActualPasswordDisplayed$.next(true)"
                >
                    <i class="bi bi bi-eye-fill"></i>
                </button>
                <button
                    *ngIf="(isActualPasswordDisplayed$ | async) === true"
                    type="button"
                    class="btn input-group-text"
                    (click)="isActualPasswordDisplayed$.next(false)">
                    <i class="bi bi-eye-slash-fill"></i>
                </button>
            </div>
        </div>
        <div *ngIf="identifiantForm.get('currentPassword').touched && identifiantForm.get('currentPassword').invalid">
            <small class="form-text text-danger" *ngIf="identifiantForm.get('currentPassword').errors.required">
                {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
        </div>
        <small class="form-text text-danger" *ngIf="currentPasswordInvalid">
            {{ 'APZ.CHANGEMENT_MDP.MDP_INCORRECT' | translate }}
        </small>
        <div [hidden]="!isSecondStep">
            <input
                type="text" class="form-control user"
                name="newLogin" formControlName="newLogin" placeholder="Nouvel identifiant">

            <div *ngIf="identifiantForm.get('newLogin').touched && identifiantForm.get('newLogin').invalid">
                <small class="form-text text-danger" *ngIf="identifiantForm.get('newLogin').errors.required">
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
            </div>
        </div>
        <button type="button" class="mt-4 btn btn-primary btn-block" [disabled]="identifiantForm.get('currentPassword').errors" *ngIf="!isSecondStep" (click)="goToSecondStep()">
            <div class="d-flex flex-row align-items-center justify-content-center">
                <div *ngIf="isLoading || isLoadingComp" class="spinner-border"></div>
                <span [ngClass]="{'ml-2': isLoading || isLoadingComp}">{{ 'CREATION_ACCOUNT.VALIDER' | translate }}</span>
            </div>
        </button>

        <button type="button" class="mt-4 btn btn-primary btn-block" [disabled]="identifiantForm.get('newLogin').errors" *ngIf="isSecondStep" (click)="submitForm()">
            <div class="d-flex flex-row align-items-center justify-content-center">
                <div *ngIf="isLoading || isLoadingComp" class="spinner-border"></div>
                <span [ngClass]="{'ml-2': isLoading || isLoadingComp}">{{ 'CREATION_ACCOUNT.VALIDER' | translate }}</span>
            </div>
        </button>
    </form>
</div>

<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>
