import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { Coordonnee } from '@shared/models/coordonnee';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AffiliateSignupService } from '../service/affiliate-signup.service';

@Component({
  selector: 'app-second-step-signup-affilie',
  templateUrl: './second-step-signup-affilie.component.html',
  styleUrls: ['./second-step-signup-affilie.component.scss']
})
export class SecondStepSignupAffilieComponent implements OnInit, OnDestroy {
  public coordonneesForm: FormGroup;
  public initialData: Coordonnee;
  public saml: string;

  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionStateService: SessionStateService,
    private readonly sessionQuery: SessionQuery,
    private readonly router: Router,
    private readonly affiliateSignupService: AffiliateSignupService
  ) {
    this.coordonneesForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.sessionQuery.selectedSaml
      .pipe(
        takeUntil(this.destroy$),
        tap((saml) => (this.saml = saml))
      )
      .subscribe();

    this.sessionStateService.setApplicationCode(this.route);
    if (this.affiliateSignupService.coordonnee) {
      this.initialData = this.affiliateSignupService.coordonnee;
    }
  }

  public nextStep(): void {
    if (this.coordonneesForm.getRawValue()?.coordonnee) {
      this.affiliateSignupService.coordonnee = this.coordonneesForm.getRawValue()?.coordonnee as Coordonnee;
    }
    this.router.navigateByUrl(`${RoutesEnum.SIGNUP_AFFILIE_THIRD_STEP}/${this.saml}`);
  }

  public goBack(): void {
    this.router.navigateByUrl(`${RoutesEnum.SIGNUP_AFFILIE_FIRST_STEP}/${this.saml}`);
    sessionStorage.setItem('BACK_FROM_CONTACT', 'true');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
