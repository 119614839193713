import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Affiliate } from '@shared/models/affiliate';

@Component({
  selector: 'app-coordonnees-cards',
  templateUrl: './coordonnees-cards.component.html',
  styleUrls: ['./coordonnees-cards.component.scss']
})
export class CoordonneesCardsComponent implements OnInit {

  @Input() affiliates!: Affiliate[];
  @Input() totalPages!: number;
  @Output() download$ = new EventEmitter<void>();
  public currentPage = 1;


  constructor() { }

  ngOnInit(): void {
    const quotient = this.affiliates
  }

  public updateAffiliates(pageNumber): void {
    this.currentPage = pageNumber;
    this.affiliates = this.getAffiliates();
  }

  public getAffiliates(): Affiliate[] {

    const startIndex = (this.currentPage - 1) * 5;
    const remainingElements = this.affiliates.length - startIndex;
    const numberOfElementsToReturn = Math.min(5, remainingElements);
    return this.affiliates.slice(startIndex, startIndex + numberOfElementsToReturn);
  }

  public download(): void {
    this.download$.emit();
  }

}
