import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoutesEnum } from '../../../../shared/enums/routes.enum';
import { ToastService } from '../../../../shared/toast/toast.service';
import { EMAIL } from '../../affilie-apz/affilie-apz.component';

@Component({
  selector: 'app-third-step-affilie-apz-component',
  templateUrl: './third-step-affilie-apz.component.html',
  styleUrls: ['../../../../../assets/css/affilie-apz-global.scss', './third-step-affilie-apz.component.scss']
})
export class ThirdStepAffilieApzComponent implements OnInit {
  // Variables
  @Input() saml: string;
  @Output() reSend: EventEmitter<any[]> = new EventEmitter();
  email: string;

  // Constructor
  constructor(private router: Router, private toastService: ToastService, private translateService: TranslateService) {}

  // Methods
  ngOnInit(): void {
    this.email = localStorage.getItem(EMAIL);
  }

  reSendEmail(): void {
    this.reSend.emit();
    this.toastService.show(this.translateService.instant('AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.THIRD_STEP.RESEND_EMAIL'), {
      classname: 'bg-success text-light',
      delay: 5000
    });
  }

  goBack(): void {
    this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/${this.saml}`);
  }
}
