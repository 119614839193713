import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { validatePassword } from '../../../shared/components/password-validation/password.helper';
import { WeezenComponent } from '../../weezen/weezen.component';
// import { Favicons } from '../../../shared/favicons';
import { ModalService } from '../../../shared/components/modal/modal.service';

@Component({
  selector: 'app-asp-confirmation-pwd',
  templateUrl: './asp-confirmation-pwd.component.html',
  styleUrls: ['../../../../assets/css/asp-global.scss', './asp-confirmation-pwd.component.scss']
})
export class AspConfirmationPwdComponent extends WeezenComponent implements OnInit, OnChanges {

  passwordEqual = false;

  @Output() submitConfirmation: EventEmitter<any[]> = new EventEmitter();
  @Output() loginWee: EventEmitter<any> = new EventEmitter();
  @Output() renvoyerDemande: EventEmitter<any> = new EventEmitter();

  @Input() success: boolean;
  @Input() error: boolean;
  @Input() waiting: boolean;
  @Input() lienInvalide: boolean;
  @Input() resendOk: boolean;
  @Input() estReinit: boolean;

  passwordForm = this.fb.group({
    password: [undefined, validatePassword()],
    confirmPassword: [undefined, [Validators.required]],
  });
  constructor(private fb: FormBuilder,
    // fav: Favicons,
    private modalService: ModalService) {
    // super(fav);
    super();
  }

  submitForm() {
    if (this.passwordForm.valid && this.passwordEqual === true) {
      this.submitConfirmation.emit(this.passwordForm.getRawValue());
    }
  }

  controlPassWord() {
    this.passwordEqual = this.passwordForm.get('password').value === this.passwordForm.get('confirmPassword').value;
  }


  ngOnInit() {
  }

  login() {
    this.loginWee.emit();
  }

  renvoyer() {
    this.renvoyerDemande.emit();
  }

  cancelPopUp() {
    this.modalService.close('resendLinkModal');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resendOk !== undefined && changes.resendOk.currentValue) {
      this.modalService.open('resendLinkModal');
    }
  }
}
