import { Injectable } from '@angular/core';
import { SiloApiService } from '@services/siloApi.service';
import { AgreementStatut } from '@shared/enums/agreement-statut.enum';
import { Affiliate, ReadAffiliatesParamQuery } from '@shared/models/affiliate';
import { AuthToken } from '@shared/models/authtoken.model';
import { CheckUserAffiliateAccessRequest } from '@shared/models/check-user-affiliate-access-request';
import { CheckUserAffiliateAccessResponse } from '@shared/models/check-user-affiliate-access-response';
import { MerchantSignupAffiliatesData } from '@shared/models/merchant-signup-affiliates-data';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MerchantsSignupService {

  constructor(private readonly siloApiService: SiloApiService) {}
  private identificationDataSubject = new BehaviorSubject<MerchantSignupAffiliatesData>(null);
  identificationData$ = this.identificationDataSubject.asObservable();

  public activatedChild = new BehaviorSubject<string>(null);

  static filterDisplayedAffiliates(affiliates: Affiliate[]) {
    return affiliates?.filter(
      (affiliate) =>
        ['CDT', 'CIS'].includes(affiliate.affiliateType?.productRange?.name) && affiliate.agreement?.status === AgreementStatut.ACTIVE
    );
  }

  readAffiliatesByParamQuery(queryParam: ReadAffiliatesParamQuery, token: AuthToken): Observable<Affiliate[]> {
    return this.siloApiService.readAffiliatesByQuery(queryParam, token);
  }

  setIdentificationData(data: MerchantSignupAffiliatesData) {
    this.identificationDataSubject.next(data);
  }

  checkAffiliateAccess(request: CheckUserAffiliateAccessRequest, token: AuthToken): Observable<CheckUserAffiliateAccessResponse> {
    // On recherche l'affilié CDT
    const affiliateCdt = this.identificationDataSubject
      .getValue()
      ?.affiliates.find((affiliate) => affiliate.affiliateType?.productRange?.name === 'CDT');
    return this.siloApiService.checkAffiliateAccess(request, affiliateCdt?.id, token);
  }
}
