import { Component, EventEmitter, Input, OnInit, Output, DoCheck } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { CesuStateService } from '@services/store/cesu/cesu-state.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { ModalService } from '@shared/components/modal/modal.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RegistrationStepEnum } from '@shared/enums/registration-step.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { emailRegEx } from '@shared/helpers/regexp.helpers';
import { SecretQuestion } from '@shared/models/secret-question.model';
import { confirmationPasswordIsEqualValidator, confirmationMailIsEqualValidator } from '@shared/validators/custom.validators';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { RegistrationService } from '../../../../shared/services/registration/registration.service';
import { CesuQuery } from '../../../../shared/services/store/cesu/cesu.query';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-second-step-bimpli-cesu',
  templateUrl: './second-step-bimpli-cesu.component.html',
  styleUrls: ['./second-step-bimpli-cesu.component.scss']
})
export class SecondStepBimpliCesuComponent implements OnInit, DoCheck {

  @Input() saml: string;
  @Input() messageErreur: string;
  @Input() application: string;
  @Output() submitted: EventEmitter<any> = new EventEmitter();

  isConfirmMailValid: boolean;
  private emailDirty = false;
  alreadyUsedMail: boolean;
  isLoading = false;
  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  employeeId: string;

  constructor(
    protected fb: FormBuilder,
    protected translate: TranslateService,
    protected siloApiService: SiloApiService,
    protected gatewayService: GatewayService,
    private router: Router,
    private readonly modalService: ModalService,
    protected tealiumService: TealiumService,
    private readonly formBuilder: FormBuilder,
    private readonly registrationService: RegistrationService,
    private readonly cesuStateService: CesuStateService,
    private readonly sessionStateService: SessionStateService,
    private readonly translateService: TranslateService,
    private readonly cesuQuery: CesuQuery,
    private readonly recaptchaV3Service: ReCaptchaV3Service
  ) { }

  emailFormControl = this.formBuilder.control('', [Validators.required, Validators.pattern(emailRegEx)]);
  passwordFormControl = this.formBuilder.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(60)]);
  createAccountForm = this.formBuilder.group({
    login: this.emailFormControl,
    confirm_email: [{ value: '', disabled: true }
      , [Validators.required,
      Validators.pattern(emailRegEx)
    ]],
    password: this.passwordFormControl,
    acceptCGU: [false, [Validators.requiredTrue]],
    phone: ['', [Validators.required, Validators.maxLength(13), Validators.pattern(/^((0[6,7])|(\+33\d)|(\+508\d)|(\+32\d)|(\+34\d))\d{8}$/)]],
    confirm_password: [{ value: '', disabled: true },
    [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(60),
      confirmationPasswordIsEqualValidator(this.passwordFormControl)
    ]]
  },
    { validator: confirmationMailIsEqualValidator('login', 'confirm_email') }
  );

  captchaResponse: string;


  ngOnInit(): void {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.application) {
      this.tealiumService.view('bimpli_benef.web.register.apetiz.identifiantPageLoad');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.application) {
      this.tealiumService.view('bimpli_mobile.web.register.apetiz.identifiantPageLoad');
    }
    this.employeeId = localStorage.getItem('registrationEmployeeId');
  }

  getCGU() {
    // this.tealiumService.link('apz.web.registrationProcess.consultCGUClick');
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cgu/' + this.saml])
    );

    window.open(url, '_blank');
  }

  isLessThanEightChars(p: string): boolean {
    return p.length < 8;
  }

  isMoreThanSixtyChars(p: string): boolean {
    return p.length > 60;
  }

  hasAtLeastOneLowerCase(p: string): boolean {
    return p.toUpperCase() !== p;
  }

  hasAtLeastOneUpperCase(p: string): boolean {
    return p.toLowerCase() !== p;
  }

  hasAtLeastOneDigit(p: string): boolean {
    return /\d/.test(p);
  }

  hasAtLeastOneSpecialChar(p: string): boolean {
    const pwdRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return pwdRegex.test(p);
  }

  isCorrectPwd(p: string): boolean {
    return !this.isLessThanEightChars(p) && !this.isMoreThanSixtyChars(p) && (
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneSpecialChar(p))
    );
  }

  ngDoCheck(): void {

    this.isConfirmMailValid = (this.createAccountForm.get('confirm_email').dirty || this.createAccountForm.get('login').dirty) &&
      (this.createAccountForm.get('confirm_email').value.toUpperCase() === this.createAccountForm.get('login').value.toUpperCase());
    if (this.emailDirty !== this.createAccountForm.get('login').dirty) {
      this.emailDirty = this.createAccountForm.get('login').dirty;
      if (this.emailDirty) {
        this.createAccountForm.get('confirm_email').enable();
      }
    }
    if (this.createAccountForm.get('password').dirty) {
      this.createAccountForm.get('confirm_password').enable();
    }

    if (this.messageErreur && this.messageErreur !== '') {
      this.modalService.open('errorModal');
    }
  }

  validateForm() {

    localStorage.setItem('login', this.createAccountForm.get('login').value);
    this.submitForm();
  }

  submitForm() {
    if (this.createAccountForm.valid) {
      const type = '';
      const employeeId = this.employeeId;
      this.submitted.emit({ form: this.createAccountForm.getRawValue(), type, employeeId});
    }
  }

  closePopin(id: string) {
    this.messageErreur = '';
    this.modalService.close(id);
  }

  back(): void {
    window.history.back();
  }

  numericPhoneOnly(event): boolean {
    const patt = /^([+0-9])$/;
    return patt.test(event.key);
  }
}
