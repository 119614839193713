import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MerchantSignupRightPanel } from '@shared/enums/merchants-signup-right-panel.enum';
import { UnlockOption } from '@shared/models/unclock-options';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MerchantsSignupService } from '../../merchants-signup.service';

@Component({
  selector: 'app-unlock-container',
  templateUrl: './unlock-container.component.html',
  styleUrls: ['./unlock-container.component.scss']
})
export class UnlockContainerComponent implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject<boolean>();
  @Output() goToPanel$ = new EventEmitter<string>();
  @Output() closePanel$ = new EventEmitter<void>();
  @Output() canAccess$ = new EventEmitter<void>();

  public options: UnlockOption[];
  public defaultOption!: string;
  public content: string;

  constructor(
      private readonly http: HttpClient,
      private readonly merchantsSignupService: MerchantsSignupService
    ) {
  }
  ngOnInit(): void {
    this.http.get<UnlockOption[]>('assets/data/check-affiliate-access-options.json')
    .pipe(takeUntil(this.destroy$))
    .subscribe(options => {
      this.options = options;

      const chosenOption = options?.find(option => option?.value === this.merchantsSignupService.activatedChild.getValue());
      this.defaultOption = chosenOption?.name;
      this.content = chosenOption?.value;
    });
  }

  public goToPanel(goTo: string): void {
    this.goToPanel$.emit(goTo);
  }

 public goBack(): void {
    this.closePanel$.emit();
 }

 public canAccess(): void {
  this.canAccess$.emit();
 }

 public unlock(method: string): void {
  this.content = method;
 }

 ngOnDestroy(): void {
  this.merchantsSignupService.activatedChild.next(null);
  this.destroy$.next(true);
  this.destroy$.unsubscribe();
 }

}
