import { Component, OnInit } from '@angular/core';
import { GatewayService } from '@services/gateway.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-third-step-signup-affiliate-manager',
  templateUrl: './third-step-signup-affiliate-manager.component.html',
  styleUrls: ['./third-step-signup-affiliate-manager.component.scss']
})
export class ThirdStepSignupAffiliateManagerComponent {
  // Constructor
  constructor(private gatewayService: GatewayService) {}

  // Methods
  public goLogin(): void {
    this.gatewayService
      .authentificate(environment.clientIdAffilieAPZ, 'token', environment.redirectURLAffilieAPZ)
      .subscribe((contenuHtml) => this.setPageHtml(contenuHtml));
  }

  private setPageHtml(html: string): void {
    document.body.innerHTML = html;
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.submit();
  }
}
