<!-- HELLO -->
<div class="d-flex flex-column align-items-start justify-content-center mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3">
  <span id="third-step-title" class="d-flex flex-row align-items-start justify-content-center mb-3">{{
    'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.HELLO' | translate
  }}</span>
  <span id="third-step-sub-title" class="d-flex flex-row align-items-start justify-content-center">{{
    'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.HELLO_DESCRIPTION' | translate
  }}</span>
</div>
<form class="w-100" name="form" role="form" [formGroup]="passwordForm" (submit)="submitForm()" (keydown.enter)="submitForm()">
  <div class="d-flex flex-column align-items-center justify-content-between w-100 h-100">
    <!-- ERROR MESSAGE -->
    <div *ngIf="!success" class="d-flex flex-row align-items-start justify-content-center w-100 alert alert-danger error-msg">
      <i class="icon-info"></i>
      <span class="ml-2">{{ messageErreur }}</span>
    </div>
    <div
      class="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column align-items-start justify-content-between mb-3 w-100"
    >
      <!-- LOGIN -->
      <div class="d-flex flex-column input-form">
        <label class="form-label" for="login">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.SECOND_STEP.LOGIN' | translate }}</label>
        <input
          type="text"
          class="form-control"
          id="login"
          name="login"
          formControlName="login"
          placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.SECOND_STEP.LOGIN_PROMPT' | translate }}"
          (keyup)="controlLogin()"
          [ngClass]="{
            'is-invalid': passwordForm.get('login').invalid === true && passwordForm.get('login').touched === true,
            'is-valid': passwordForm.get('login').invalid === false && passwordForm.get('login').touched === true
          }"
        />
        <div *ngIf="passwordForm.get('login').touched && passwordForm.get('login').invalid">
          <small class="error-msg text-danger" *ngIf="passwordForm.get('login').errors.required">
            {{ 'COMMON.FORM.REQUIRED' | translate }}
          </small>
          <small class="error-msg text-danger" *ngIf="passwordForm.get('login').errors?.email">
            {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
          </small>
        </div>
      </div>
      <!-- CONFIRM LOGIN -->
      <div
        class="d-flex flex-column input-form ml-xxl-2 ml-xl-2 ml-lg-2 ml-md-0 ml-sm-0 ml-0 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3"
      >
        <label class="form-label" for="confirmLogin">{{
          'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.CONFIRM_LOGIN' | translate
        }}</label>
        <input
          type="text"
          class="form-control"
          id="confirmLogin"
          name="confirmLogin"
          formControlName="confirmLogin"
          placeholder="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.CONFIRM_LOGIN_PROMPT' | translate }}"
          (keyup)="controlLogin()"
          [ngClass]="{
            'is-invalid':
              (passwordForm.get('confirmLogin').invalid === true || !loginEqual) && passwordForm.get('confirmLogin').touched === true,
            'is-valid': passwordForm.get('confirmLogin').invalid === false && passwordForm.get('confirmLogin').touched === true
          }"
          required
        />
        <div class="d-flex flex-column" *ngIf="passwordForm.get('confirmLogin').touched">
          <small
            class="error-msg text-danger"
            *ngIf="passwordForm.get('confirmLogin').errors && passwordForm.get('confirmLogin').errors.required"
          >
            {{ 'COMMON.FORM.REQUIRED' | translate }}
          </small>
          <small class="error-msg text-danger" *ngIf="passwordForm.get('confirmLogin').errors?.email">
            {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
          </small>
          <small class="error-msg text-danger" *ngIf="!loginEqual">
            {{ 'COMMON.FORM.MAIL_DO_NOT_MATCH' | translate }}
          </small>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column align-items-start justify-content-between mb-3 w-100"
    >
      <!-- PASSWORD -->
      <div class="d-flex flex-column input-form">
        <label class="form-label" for="password">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.PASSWORD' | translate }}</label>
        <div class="input-group">
          <input
            [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'"
            class="form-control"
            id="password"
            name="password"
            formControlName="password"
            placeholder="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.PASSWORD_PROMPT' | translate }}"
            (keyup)="controlPassWord()"
            [ngClass]="{
              'is-invalid': passwordForm.get('password').invalid === true && passwordForm.get('password').touched === true,
              'is-valid': passwordForm.get('password').invalid === false && passwordForm.get('password').touched === true
            }"
          />
          <div class="input-group-append">
            <button
              *ngIf="(isPasswordDisplayed$ | async) === false"
              type="button"
              class="btn btn-light input-group-text"
              (click)="isPasswordDisplayed$.next(true)"
            >
              <i class="bi bi bi-eye-fill"></i>
            </button>
            <button
              *ngIf="(isPasswordDisplayed$ | async) === true"
              type="button"
              class="btn btn-light input-group-text"
              (click)="isPasswordDisplayed$.next(false)"
            >
              <i class="bi bi-eye-slash-fill"></i>
            </button>
          </div>
        </div>
        <div *ngIf="passwordForm.get('password').touched && passwordForm.get('password').invalid">
          <small class="error-msg text-danger" *ngIf="passwordForm.get('password').errors.required">
            {{ 'COMMON.FORM.REQUIRED' | translate }}
          </small>
        </div>
      </div>
      <!-- CONFIRM PASSWORD -->
      <div
        class="d-flex flex-column input-form ml-xxl-2 ml-xl-2 ml-lg-2 ml-md-0 ml-sm-0 ml-0 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3"
      >
        <label class="form-label" for="confirmPassword">{{
          'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.CONFIRM_PASSWORD' | translate
        }}</label>
        <div class="input-group">
          <input
            [type]="(isConfirmPasswordDisplayed$ | async) === false ? 'password' : 'text'"
            class="form-control"
            id="confirmPassword"
            name="confirmPassword"
            formControlName="confirmPassword"
            placeholder="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.CONFIRM_PASSWORD_PROMPT' | translate }}"
            (keyup)="controlPassWord()"
            [ngClass]="{
              'is-invalid':
                (passwordForm.get('confirmPassword').invalid === true || !passwordEqual) &&
                passwordForm.get('confirmPassword').touched === true,
              'is-valid': passwordForm.get('confirmPassword').invalid === false && passwordForm.get('confirmPassword').touched === true
            }"
            required
          />
          <div class="input-group-append">
            <button
              *ngIf="(isConfirmPasswordDisplayed$ | async) === false"
              type="button"
              class="btn btn-light input-group-text"
              (click)="isConfirmPasswordDisplayed$.next(true)"
            >
              <i class="bi bi bi-eye-fill"></i>
            </button>
            <button
              *ngIf="(isConfirmPasswordDisplayed$ | async) === true"
              type="button"
              class="btn btn-light input-group-text"
              (click)="isConfirmPasswordDisplayed$.next(false)"
            >
              <i class="bi bi-eye-slash-fill"></i>
            </button>
          </div>
        </div>
        <div class="d-flex flex-column" *ngIf="passwordForm.get('confirmPassword').touched">
          <small
            class="error-msg text-danger"
            *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required"
          >
            {{ 'COMMON.FORM.REQUIRED' | translate }}
          </small>
          <small class="error-msg text-danger" *ngIf="!passwordEqual">
            {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
          </small>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column align-items-start justify-content-between w-100 mt-3"
    >
      <!-- VALIDATION PASSWORD -->
      <div class="d-flex flex-column align-items-start justify-content-start input-form data p-3">
        <span class="d-flex flex-row form-label">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.VALIDATION' | translate }}</span>
        <app-password-validation
          [passwordValue]="passwordForm.get('password').value"
          [affLastPassword]="false"
          [progressBar]="true"
          [submitted]="submittedForm"
        >
        </app-password-validation>
      </div>
      <div
        class="d-flex flex-column align-items-start justify-content-start input-form ml-xxl-2 ml-xl-2 ml-lg-2 ml-md-0 ml-sm-0 ml-0  mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3"
      >
        <!-- CGU -->
        <div class="form-group d-flex flex-row align-items-center justify-content-start data pl-3 pt-3 pr-3">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="cgu" name="cgu" formControlName="cgu" />
            <label id="cgu-label" class="ml-2" for="cgu">
              {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.CGU_BEGIN' | translate }}
              <a [routerLink]="['/cgu/', saml]" target="_blank" rel="noopener">{{
                'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.CGU_LINK' | translate
              }}</a>
              {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.END' | translate }}
            </label>
          </div>
        </div>
        <!-- VALIDATION -->
        <div class="d-flex flex-row align-items-center justify-content-start w-100">
          <button
            type="button"
            class="btn btn-primary btn-block"
            [disabled]="!passwordForm.valid || !passwordEqual || !loginEqual || isLoading"
            (click)="submitForm()"
          >
            <div class="d-flex flex-row align-items-center justify-content-center">
              <div *ngIf="isLoading" class="spinner-border"></div>
              <span
                [ngClass]="{
                  'ml-2': isLoading
                }"
                id="submit-btn-label"
                >{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.THIRD_STEP.CONTINUE' | translate }}</span
              >
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
