<app-cesu-layout>
    <div class="row no-gutters text-center justify-content-center pt-lg-5 pt-md-4 pt-2">
        <div class="col-12 cesu-page-title">
            {{ 'CESU.FORGOTTEN_PASSWORD_MAIL.TITLE' | translate }}
        </div>
        <hr class="cesu-separator"/>
        <div class="col-12 alert alert-success" *ngIf="success">
            {{ 'CESU.FORGOTTEN_PASSWORD_MAIL.SUCCESS' | translate }}
        </div>
        <div class="col-12 alert alert-danger" *ngIf="!success && errorMessage">
            {{ this.errorMessage }}
        </div>
        <div class="col-12" [innerHTML]="'CESU.FORGOTTEN_PASSWORD_MAIL.INFO' | translate"></div>
        <a class="font-weight-bold"
           (click)="retrySendForgottenPassword()">{{ 'CESU.FORGOTTEN_PASSWORD_MAIL.RESEND' | translate }}</a>
        <div class="col-12 pt-lg-5 pt-4">
            <img alt="mail_icon" class="cesu-big-icon" src="../../../../../assets/cesu/mail_icon.svg"/>
        </div>
        <div class="col-12 pt-lg-5 pt-4">
            <button class="btn cesu-button cesu-flat-button" (click)="goToLogin()">
                {{ 'CESU.FORGOTTEN_PASSWORD_MAIL.BUTTON' | translate}}
            </button>
        </div>
    </div>
</app-cesu-layout>
