import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SecretQuestion } from '@shared/models/secret-question.model';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { SiloApiService } from '@services/siloApi.service';
import { GatewayService } from '@services/gateway.service';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { Router } from '@angular/router';
import { ModalService } from '@shared/components/modal/modal.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { TealiumService } from '@services/tealium/tealium.service';

@Component({
  selector: 'app-bimpli-forgotten-pwd',
  templateUrl: './bimpli-forgotten-pwd.component.html',
  styleUrls: ['./bimpli-forgotten-pwd.component.scss']
})
export class BimpliForgottenPwdComponent implements OnInit, OnDestroy {
  @Input() messageErreur;
  @Input() notificationEnvoyer;
  @Input() estApetizMobile;
  @Input() applicationId;
  @Input() saml;
  @Input() isLoading;
  @Input() receiver;
  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() recalled: EventEmitter<string> = new EventEmitter<string>();

  isSecondStep: boolean;
  secretQuestion: SecretQuestion;
  applicationCodeEnum = ApplicationCodeEnum;

  forgottenPwdForm = this.fb.group({
    identifiant: [undefined, [Validators.required]]
  });

  mySubscription = new Subscription();

  constructor(protected fb: FormBuilder, private gtds: GroundTruthDataService, private snackbarService: SnackbarService,
              private readonly siloApiService: SiloApiService,
              private readonly gatewayService: GatewayService,
              private readonly translateService: TranslateService,
              private readonly router: Router,
              private readonly tealiumService: TealiumService,
              private readonly modalService: ModalService) {}

  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((retour) => {
        this.isLoading = retour;
      });

    this.mySubscription.add(isSpinnerActivated);
  }

  submit() {
    if (this.forgottenPwdForm.valid) {
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit(this.forgottenPwdForm.get('identifiant').value);
    }
  }

  recall() {
    this.gtds.setIsSpinnerActivated(true);
    this.recalled.emit(this.forgottenPwdForm.get('identifiant').value);
    this.modalService.open('infoModal')
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }

  public gererErreurs(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = error.error;
      switch (errors.errors[0].code) {
        case CodeErreurs.UNEXPECTED_011:
          this.messageErreur = 'Erreur technique';
          break;
        case CodeErreurs.NOT_FOUND_028:
          this.messageErreur = this.translateService.instant('ASP.FORGOTTEN_PWD.ERROR.NOT_FOUND_028');
          break;
      }
    }
    this.snackbarService.showDanger(this.messageErreur);
  }

  back(): void {
    window.history.back();
  }

  goLogin() {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.link('bimpli_benef.web.forgottenPassword.backToConnexionClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.link('bimpli_financeur.web.forgottenPassword.backToConnexionClick');
    }

    this.router.navigateByUrl('/login/' + this.saml);
  }
}
