<ng-container
    *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ || (applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE"
>
    <app-apz-forgotten-login
        (submitted)="demandeIdentifiantOublie($event)"
        (recalled)="renvoyerDemande($event)"
        (cancelled)="goLogin()"
        [messageErreur]="messageErreur"
        [notificationEnvoyer]="notificationEnvoyer"
        [estApetizMobile]="(applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE"
        [saml]="saml"
    >
    </app-apz-forgotten-login>
</ng-container>
<ng-container
*ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE ||
(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR"
>
  <app-bimpli-forgotten-login
    (submitted)="demandeIdentifiantOublie($event)"
    (recalled)="renvoyerDemande($event)"
    (cancelled)="goLogin()"
    [messageErreur]="messageErreur"
    [isLoading]="isLoading"
    [notificationEnvoyer]="notificationEnvoyer"
    [estApetizMobile]="(applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE "
    [saml]="saml"
  >
  </app-bimpli-forgotten-login>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN">
  <app-asp-forgotten-id
    (submitted)="demandeIdentifiantOublie($event)"
    (recalled)="renvoyerDemande($event)"
    (cancelled)="goBack()"
    [errorMessage]="messageErreur"
    [notificationEnvoyer]="notificationEnvoyer"
  >
  </app-asp-forgotten-id>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
  <app-cesu-forgottent-login></app-cesu-forgottent-login>
</ng-container>
