import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { GroundTruthDataService } from '../../../shared/services/ground-truth-data.service';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import { SiloApiService } from '../../../shared/services/siloApi.service';
import { GatewayService } from '../../../shared/services/gateway.service';
import { SecretQuestion } from '../../../shared/models/secret-question.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Erreurs, CodeErreurs } from '../../../shared/models/errors.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-apz-forgotten-password',
  templateUrl: './apz-forgotten-password.component.html',
  styleUrls: ['./apz-forgotten-password.component.scss', '../../../../assets/css/apz-global.scss']
})
export class ApzForgottenPasswordComponent implements OnInit, OnChanges, OnDestroy {
  @Input() messageErreur;
  @Input() notificationEnvoyer;
  @Input() estApetizMobile;
  @Input() saml;
  @Input() receiver;
  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() recalled: EventEmitter<string> = new EventEmitter<string>();

  isLoading: boolean;
  isSecondStep: boolean;
  secretQuestion: SecretQuestion;

  forgottenPwdForm = this.fb.group({
    identifiant: [undefined, [Validators.required]],
    response: [undefined, [Validators.required]]
  });

  mySubscription = new Subscription();

  constructor(protected fb: FormBuilder, private gtds: GroundTruthDataService, private snackbarService: SnackbarService,
      private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((retour) => {
        this.isLoading = retour;
      });

    this.mySubscription.add(isSpinnerActivated);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messageErreur) {
      if (this.messageErreur) {
        this.snackbarService.showDanger(this.messageErreur);
      } else {
        this.snackbarService.hide();
      }
    }
  }

  goToSecondStep() {
    if (this.forgottenPwdForm.get('identifiant').valid) {
      this.snackbarService.hide();
      this.gtds.setIsSpinnerActivated(true);
      this.submit();
      /*this.gatewayService.getAccessToken()
      .subscribe((authToken) => {
          this.siloApiService.readSecretQuestions(authToken, this.forgottenPwdForm.get('identifiant').value, 'SiteAPZ')
          .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
          .subscribe((qb) => {
            if (qb.items && qb.items.length > 0) {
              this.secretQuestion = qb.items[0];
              this.isSecondStep = true;
            } else {
              this.submit();
            }
        }, (error)=> {
          this.gererErreurs(error);
        });
      });*/
    }
  }

  validateSecondStep() {
    if (this.forgottenPwdForm.valid) {
      this.snackbarService.hide();
      this.gtds.setIsSpinnerActivated(true);
      this.gatewayService.getAccessToken()
      .subscribe((authToken) => {
          this.siloApiService.userSecretQuestionResponseChecks(authToken,
             this.forgottenPwdForm.get('identifiant').value, 'SiteAPZ',
              this.forgottenPwdForm.get('response').value, this.secretQuestion.id)
              .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
              .subscribe((result) => {
                  if (result.resultCheck) {
                    this.submit();
                  } else {
                    this.snackbarService.showDanger('Réponse incorrecte veuillez réassayer');
                  }
              }, (error)=> {
                this.gererErreurs(error);
              });
      });
    }
  }

  submit() {
    this.gtds.setIsSpinnerActivated(true);
    this.submitted.emit(this.forgottenPwdForm.get('identifiant').value);
  }

  recall() {
    this.gtds.setIsSpinnerActivated(true);
    this.recalled.emit(this.forgottenPwdForm.get('identifiant').value);
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }

  public gererErreurs(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = error.error;
      switch (errors.errors[0].code) {
        case CodeErreurs.UNEXPECTED_011:
          this.messageErreur = 'Erreur technique';
          break;
        case CodeErreurs.NOT_FOUND_028:
          this.messageErreur = this.translateService.instant('ASP.FORGOTTEN_PWD.ERROR.NOT_FOUND_028');
          break;
      }
    }
    this.snackbarService.showDanger(this.messageErreur);
  }
}
