<div id="third-step-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row h-100">
    <div id="menu-container" class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 d-none d-md-block p-0">
      <app-affilie-apz [step]="3" [saml]="saml"></app-affilie-apz>
    </div>
    <div id="step-container" class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 p-0">
      <div class="d-flex flex-column align-items-start justify-content-start w-100 h-100 p-3">
        <!-- HELLO -->
        <div class="d-flex flex-column align-items-start justify-content-center mb-xxl-5 mb-xl-5 mb-lg-5 mb-md-3 mb-sm-3 mb-3 text-wrapper">
          <span id="fourth-step-title" class="d-flex flex-row align-items-start justify-content-center mb-3">{{
            'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FOURTH_STEP.HELLO' | translate
          }}</span>
          <span class="d-flex flex-row align-items-start justify-content-center fourth-step-sub-title"
            >{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FOURTH_STEP.HELLO_DESCRIPTION' | translate }}
            {{
              email
                ? ('AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FOURTH_STEP.HELLO_DESCRIPTION_FOLLOWING_IF_EMAIL' | translate) + email
                : ('AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FOURTH_STEP.HELLO_DESCRIPTION_FOLLOWING_IF_NO_EMAIL' | translate)
            }}</span
          >
          <span class="fourth-step-sub-title mt-4">
            {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FOURTH_STEP.HELLO_DESCRIPTION_PS' | translate }}
          </span>
        </div>
        <div id="divider" class="mb-4 w-50"></div>
        <!-- RE SEND -->
        <div class="px-3 row d-sm-flex align-items-sm-center">
          <span class="fourth-step-sub-title">
            {{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FOURTH_STEP.RESEND_DESCRIPTION' | translate }}
          </span>
          <button class="btn btn-link ml-auto" (click)="reSendEmail()">
            <span id="resend-btn-label">{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.FOURTH_STEP.RESEND_ACTION' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>
