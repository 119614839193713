import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-affilie-apz-confirmation-pwd',
  templateUrl: './affilie-apz-confirmation-pwd.component.html',
  styleUrls: ['../../../../assets/css/affilie-apz-global.scss', './affilie-apz-confirmation-pwd.component.scss']
})
export class AffilieApzConfirmationPwdComponent implements OnInit, OnDestroy {
  // Variables
  @Output() submitConfirmation: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() goLogin: EventEmitter<any> = new EventEmitter<any>();
  @Output() renvoyerDemande: EventEmitter<any> = new EventEmitter<any>();

  @Input() errorMessage: string;
  @Input() success: boolean;
  @Input() lienInvalide: boolean;
  @Input() resendOk: boolean;

  isLoading = false;
  mySubscription: Subscription = new Subscription();
  isSubmitted = false;
  passwordEqual = false;

  passwordForm: FormGroup = this.fb.group({
    password: ['', validatePassword()],
    confirmPassword: ['', [Validators.required]]
  });

  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  submittedForm = false;

  // Constructor
  constructor(private fb: FormBuilder, private gtds: GroundTruthDataService) {}

  // Methods
  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
  }

  submit(): void {
    if (this.passwordForm.valid && this.passwordEqual === true) {
      this.submittedForm = true;
      this.gtds.setIsSpinnerActivated(true);
      this.submitConfirmation.emit(this.passwordForm.getRawValue());
    }
  }

  controlPassWord(): void {
    this.passwordEqual = this.passwordForm.get('password').value === this.passwordForm.get('confirmPassword').value;
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }

  connect(): void {
    this.goLogin.emit();
  }

  recall(): void {
    this.gtds.setIsSpinnerActivated(true);
    this.renvoyerDemande.emit();
  }
}
