<div class="container-fluid vw-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
        <div class="d-flex flex-column">
            <span class="title-bp">Happi !</span>
            <span class="sub-title-bp">Vous avez sélectionné <br>Espace entreprise et bons plans !</span>
        </div>
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Identité personnelle</span>
            <span class="create-subtitle mt-4 mb-4">Saisissez votre civilité, votre code site et application puis votre code d’activation.</span>

            <form [formGroup]="individualInfosForm" (keydown.enter)="createAccount()">
                <div class="inner_page">
                    <div class="mb-2">
                        <label for="civilite">{{'USUAL.GENDER' | translate}} *</label>
                        <div class="d-flex">
                            <div class="full-radio radio-button">
                                <input id="male" value="MR" type="radio" name="title"
                                       formControlName="title">
                                <label for="male">{{'USUAL.GENDER_VALUES.MR' | translate}}</label>
                            </div>
                            <div class="full-radio radio-button">
                                <input id="female" value="MS" type="radio" name="title"
                                       formControlName="title">
                                <label for="female">{{'USUAL.GENDER_VALUES.MS' | translate}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="prenom">{{'USUAL.FIRSTNAME' | translate}} *</label>
                        <input type="text" name="prenom"
                               placeholder="{{'USUAL.PH_FIRSTNAME' | translate}}"
                               id="prenom" maxlength="30" class="form-control"
                               formControlName="firstName" (keypress)="formatName($event)">
                        <div *ngIf="(individualInfosForm.get('firstName').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('firstName').errors">Prénom requis</small>
                        </div>
                    </div>
                    <div class="input_group">
                        <label for="nom">{{'USUAL.LASTNAME' | translate}} *</label>
                        <input type="text" name="nom"
                               placeholder="{{'USUAL.PH_LASTNAME' | translate}}"
                               id="nom" maxlength="30" class="form-control"
                               formControlName="lastName" (keypress)="formatName($event)">
                        <div *ngIf="(individualInfosForm.get('lastName').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('lastName').errors">Nom requis</small>
                        </div>
                    </div>
                    <div class="input_group">
                        <label class="d-inline-block" for="phone">{{'USUAL.PHONE' | translate}} *</label>
                        <input type="tel" name="phone"
                               placeholder="{{'USUAL.PH_PHONE' | translate}}"
                               id="phone" class="form-control"
                               formControlName="cellPhoneNumber" maxlength="10" (keypress)="numericPhoneOnly($event)">
                        <div *ngIf="(individualInfosForm.get('cellPhoneNumber').touched && individualInfosForm.get('cellPhoneNumber').errors)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('cellPhoneNumber').errors.required">{{'COMMON.FORM.REQUIRED' | translate}}</small>
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('cellPhoneNumber').errors.pattern">{{'COMMON.FORM.ERROR.PATTERN_CELLPHONENUMBER' | translate}}</small>
                        </div>
                    </div>
                    <hr>
                    <div class="input_group" [formGroup]="individualInfosForm.get('equipmentCOMITEO')">
                        <div class="d-flex justify-content-between">
                            <label for="codeCli">Code site et application *</label>
                            <span class="link" (click)="openPopUp('popupInfo')">Code site et application ?</span>
                        </div>

                        <input type="text" name="codeCli"
                               placeholder="Saisir votre Code site et application"
                               id="codeCli" maxlength="30" class="form-control"
                               formControlName="codeClient">
                        <div *ngIf="(individualInfosForm.get('equipmentCOMITEO').get('codeClient').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('equipmentCOMITEO').get('codeClient').errors">Code site et application requis</small>
                        </div>
                    </div>
                    <div class="input_group" [formGroup]="individualInfosForm.get('equipmentCOMITEO')">
                        <label for="id">Identifiant ou email *</label>
                        <input type="text" name="id"
                               placeholder="Saisir votre identifiant ou email"
                               id="id" maxlength="30" class="form-control"
                               formControlName="login">
                        <div *ngIf="(individualInfosForm.get('equipmentCOMITEO').get('login').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('equipmentCOMITEO').get('login').errors">Identifiant ou email requis</small>
                        </div>
                    </div>
                    <div class="input_group" [formGroup]="individualInfosForm.get('equipmentCOMITEO')">
                        <div class="d-flex justify-content-between">
                            <label for="password">Mot de passe *</label>
                            <span class="link" (click)="openPopUp('popupInfoMdp')">Mot de passe ?</span>
                        </div>
                        <input type="password" name="password"
                               placeholder="Saisir votre mot de passe"
                               id="password" maxlength="30" class="form-control"
                               formControlName="password">
                        <div *ngIf="(individualInfosForm.get('equipmentCOMITEO').get('password').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('equipmentCOMITEO').get('password').errors">Password requis</small>
                        </div>
                    </div>
                </div>
                <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!individualInfosForm.valid" (click)="createAccount()">
                    <span>Continuer</span>
                </button>
            </form>
        </div>
    </div>
</div>

<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>

<app-modal id="popupInfo">
    <div class="d-flex flex-column p-4">
        <span class="title-popup text-center">Où trouver le Code site et application ?</span>
        <span class="subtitle-popup mt-2">Retrouvez votre code site et application sur votre Espace entreprise & bons plans :
            <br><br><span class="subtitle-popup mt-2">> Sur la page connexion.</span>
            <br><span class="subtitle-popup mt-2">> Dans votre profil utilisateur.</span>
            <br><span class="subtitle-popup mt-2">> Dans le bas de page de votre site entreprise.</span>
        </span>

        <div class="mt-4 mb-4">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('popupInfo')">FERMER</button>
        </div>
    </div>
</app-modal>

<app-modal id="popupInfoMdp">
    <div class="d-flex flex-column p-4">
        <span class="title-popup text-center">Mot de passe oublié ? </span>
        <span class="subtitle-popup mt-2">Nous vous invitons à contacter directement votre entreprise.</span>

        <div class="mt-4 mb-4">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('popupInfoMdp')">FERMER</button>
        </div>
    </div>
</app-modal>
