<div class="container detail-container">
  <div class="pb-3 text-left" [hidden]="isSecondStep || isThirdStep || isFourthStep">
    <p class="title-card ">{{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.BONJOUR' | translate }} {{ employee?.firstName }},</p>
    <p>
      {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.INSTRUCTION_LOGIN' | translate }}
    </p>
  </div>
  <div class="pb-1 text-left" [hidden]="!isSecondStep">
    <p>
      {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.INSTRUCTION_MDP' | translate }}
    </p>
  </div>
  <div class="pb-1 text-center" [hidden]="!isThirdStep">
    <p class="pl-0">
      Pour apporter une sécurité supplémentaire à votre compte Apetiz, répondez à une des questions suivantes :
    </p>
  </div>
  <!-- row pour la vue mobile -->
  <div class="pb-3">
    <div class="col-12 cgu-section p-3" [hidden]="!isFourthStep">
      <label class="cgu"
        >Les conditions générales d'utilisation doivent être acceptées par tout utilisateur souhaitant accéder à l'application
        Apetiz.</label
      >
      <div class="row justify-content-end">
        <div class="col clickable text-right d-flex align-items-center justify-content-end">
          <a [routerLink]="" class="card-link" (click)="goToCGU()">Consulter les CGU</a>
          <img src="assets/apz/arrow-forward.svg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="p-2">
    <form name="passwordForm" [formGroup]="passwordForm" (submit)="submitForm()" class="row justify-content-center">
      <div *ngIf="false" class="btn-group pb-4" role="group" [hidden]="isSecondStep || isThirdStep">
        <button type="button" [ngClass]="loginEstMail ? 'btn btn-selected' : 'btn'" (click)="loginEstMail = true">
          {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.EMAIL' | translate }}
        </button>
        <button type="button" [ngClass]="loginEstMail ? 'btn' : 'btn btn-selected'" (click)="loginEstMail = false">
          {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.MOBILE' | translate }}
        </button>
      </div>

      <!--  choix MOBILE -->
      <div class="col-12 col-sm text-left" [hidden]="isSecondStep || isThirdStep || isFourthStep">
        <div [hidden]="loginEstMail || isLoading">
          <div class="pb-4 form-group row d-flex justify-content-center align-items-center">
            <input
              type="text"
              class="form-control phone"
              id="phone"
              name="login"
              formControlName="login"
              placeholder="ex : 06 06 06 06 06"
              mask="00 00 00 00 00"
            />
            <div *ngIf="(isSubmitted || passwordForm.get('login').touched) && !passwordForm.get('login').value">
              <small class="form-text text-danger text-center">
                {{ 'COMMON.FORM.REQUIRED' | translate }}
              </small>
            </div>
            <div *ngIf="(isSubmitted || passwordForm.get('login').touched) && !mobileValid()">
              <small class="form-text text-danger text-center">
                {{ 'COMMON.FORM.MOBILE_INVALID' | translate }}
              </small>
            </div>
            <div *ngIf="loginExistant">
              <small class="form-text text-danger text-center">
                {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.LOGIN_EXISTANT' | translate }}
              </small>
            </div>
          </div>
        </div>
        <!-- choix EMAIL -->
        <div class="row justify-content-center px-3" [hidden]="!loginEstMail || isLoading">
          <div class="form-group">
            <div class="custom-control custom-radio pb-4" *ngIf="mailProPresent">
              <input
                class="custom-control-input"
                type="radio"
                name="loginSaisie"
                id="exampleRadios1"
                [value]="false"
                formControlName="loginSaisie"
              />
              <label class="custom-control-label" for="exampleRadios1">{{ this.mailPro }}</label>
            </div>
            <div class="custom-control custom-radio pb-4 ">
              <input
                class="custom-control-input"
                type="radio"
                name="loginSaisie"
                id="exampleRadios2"
                formControlName="loginSaisie"
                [value]="true"
              />
              <label class="custom-control-label" for="exampleRadios2">
                <input
                  type="text"
                  class="form-control mail"
                  id="login"
                  name="login"
                  formControlName="login"
                  [placeholder]= "this.mailProPresent?'Un autre email de votre choix':'Un email de votre choix'"
                  (click)="inputClick()"
                />
              </label>
              <div
                *ngIf="
                  passwordForm.get('loginSaisie').value &&
                  (isSubmitted || passwordForm.get('login').touched) &&
                  !passwordForm.get('login').value
                "
              >
                <small class="form-text text-danger text-center">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
              </div>
              <div *ngIf="passwordForm.get('loginSaisie').value && (isSubmitted || passwordForm.get('login').touched) && !mailValid()">
                <small class="form-text text-danger text-center">
                  {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
                </small>
              </div>
              <div *ngIf="loginExistant">
                <small class="form-text text-danger text-center">
                  {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.LOGIN_EXISTANT' | translate }}
                </small>
              </div>
              <p class="info-autre-email" *ngIf="mailProPresent">
                {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.INSTRUCTION_EMAIL' | translate }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isLoading" class="d-block text-center pb-4">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>

      <!-- choix mot de passe -->
      <div class="col-12 col-sm text-left" [hidden]="!isSecondStep">
        <div class="form-group row d-flex justify-content-center align-items-center">
          <div class="col-10">
            <input
              [type]="hide ? 'password' : 'text'"
              class="form-control lock"
              name="password"
              formControlName="password"
              placeholder="Mot de passe"
            />
          </div>
          <div class="img-fluid mr-1 col-1" [ngClass]="hide ? 'hide' : 'show'" (click)="hide = !hide"></div>
          <div *ngIf="(isSubmitted || passwordForm.get('password').touched) && passwordForm.get('password').invalid">
            <small class="form-text text-danger" *ngIf="passwordForm.get('password').errors.required">
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
          </div>
        </div>
        <div class="form-group row d-flex justify-content-center align-items-center">
          <div class="col-10">
            <input
              [type]="hide2 ? 'password' : 'text'"
              class="form-control lock"
              name="confirmPassword"
              formControlName="confirmPassword"
              placeholder="Confirmation"
            />
          </div>
          <div class="img-fluid mr-1 col-1" [ngClass]="hide2 ? 'hide' : 'show'" (click)="hide2 = !hide2"></div>
          <div *ngIf="isSubmitted || passwordForm.get('confirmPassword').touched">
            <small
              class="form-text text-danger"
              *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required"
            >
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
            <small class="form-text text-danger" *ngIf="!passwordEqual()">
              {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
            </small>
          </div>
        </div>
        <div class="infos-utilisateur pt-3">
          <p class="pt-2 valid-mdp">{{ 'CREATION_ACCOUNT.SECOND_STEP.VALIDATION' | translate }}</p>
          <app-password-validation [passwordValue]="passwordForm.get('password').value" [affLastPassword]="false" [progressBar]="true">
          </app-password-validation>
        </div>
      </div>
      <!-- Question secrete -->
      <div class="col-12 col-sm " [hidden]="!isThirdStep">
        <div *ngFor="let question of questions; let i = index" class=" row justify-content-center text-left">
          <div class="form-group col-12 col-sm-6">
            <div class=" custom-control custom-radio ">
              <input
                class="custom-control-input"
                type="radio"
                name="secretQuestion"
                id="{{ 'radio' + i }}"
                [value]="question.id"
                formControlName="secretQuestion"
              />
              <label class="custom-control-label question " for="{{ 'radio' + i }}">{{ question.label }}</label>
            </div>
          </div>
        </div>
        <div class="row justify-content-center py-4">
          <div class="col-12 col-sm-8">
            <input type="text" class="form-control response" id="reponse" name="response" formControlName="response" placeholder="Ma réponse*" />
            <div *ngIf="isSubmitted || passwordForm.get('response').touched">
              <small
                class="form-text text-danger text-center"
                *ngIf="passwordForm.get('response').errors && passwordForm.get('response').errors.required"
              >
                {{ 'COMMON.FORM.REQUIRED' | translate }}
              </small>
              <small
                class="form-text text-danger text-center"
                *ngIf="passwordForm.get('secretQuestion').errors && passwordForm.get('secretQuestion').errors.required"
              >
                {{ 'COMMON.FORM.SECRET_QUESTION.REQUIRED' | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- CGU -->
      <div class="col-12 col-sm text-left" [hidden]="!isFourthStep">
        <div class="row justify-content-center align-content-center">
          <label class="col-9 text-left cgu">J'ai lu et j'accepte les conditions générales d'utilisation de l'application Apetiz</label>
          <div class="col-3 d-flex align-items-center">
            <app-toggle-switch formControlName="acceptCGU"></app-toggle-switch>
          </div>
        </div>
      </div>
    </form>
    <div class="text-center pt-3">
      <button class="btn btn-action" (click)="goToSecondStep()" type="submit" *ngIf="!isSecondStep && !isThirdStep && !isFourthStep">
        {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
      </button>
      <button class="btn btn-action" (click)="goToThirdStep()" type="submit" *ngIf="isSecondStep">
        {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
      </button>
      <button class="btn btn-action" (click)="goToFourthStep()" type="submit" *ngIf="isThirdStep">
        {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
      </button>
      <button
        class="btn btn-action "
        (click)="acceptCGU()"
        type="submit"
        *ngIf="isFourthStep"
        [disabled]="!passwordForm.get('acceptCGU').value"
      >
        {{ 'CREATION_ACCOUNT.VALIDER' | translate }}
      </button>
    </div>
  </div>
</div>
