import { Component, OnInit } from '@angular/core';
import { SiloApiService } from '../../../shared/services/siloApi.service';
import { GatewayService } from '../../../shared/services/gateway.service';

@Component({
  selector: 'app-notice-apz',
  templateUrl: './notice-apz.component.html',
  styleUrls: ['./notice-apz.component.scss']
})
export class NoticeApzComponent implements OnInit {

  noticeContent: string;


  constructor( private gatewayService: GatewayService,
    private siloApiService: SiloApiService) { }

  ngOnInit(): void {
    this.gatewayService.getAccessToken() .subscribe(
      authToken => this.siloApiService.
      readLegalNotices('SiteAPZ', authToken, 'FR')
        .subscribe((response) => {
          this.noticeContent = response.content;
        })
    );
  }

  goBack() {
    window.history.back();
  }
}
