import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-affiliate-error',
  templateUrl: './affiliate-error.component.html',
  styleUrls: ['./affiliate-error.component.scss']
})
export class AffiliateErrorComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() btnText: string;
  @Output() btnClicked$: EventEmitter<boolean> = new EventEmitter<boolean>();
}
