<div class="container-fluid vw-100 vh-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3">
    </div>
    <div *ngIf="!waiting"class="d-block col-xs-12 col-sm-8 offset-sm-4">
        <div class="bloc-center d-flex flex-column">
            <div *ngIf="!lienInvalide && messageErreur">
                <span class="create-subtitle"> {{ messageErreur }}</span>
            </div>
            <div *ngIf="lienInvalide">
                <div class="create-subtitle" *ngIf="!isLoading">
                    Le lien n'est plus valide : une nouvelle demande de modification de mot de passe est peut-être en cours.
                    <br />
                    <br />
                    Le lien a expiré : la durée de validité du lien est de 2h, la demande de modification de mot de passe est peut-être antérieure à
                    2h
                    <br />
                    <br />
                    Pour recevoir un nouvel email avec un nouveau lien :
                    <a [routerLink]="" (click)="recall()">cliquez-ici</a>
                </div>
                <div *ngIf="isLoading" class="d-block text-center">
                    <div class="spinner-border" role="status"></div>
                </div>
            </div>
        </div>
        <div *ngIf="success" class="bloc-center d-flex flex-column">
            <img src="/assets/bimpli/validation-compte.png" class="validation-compte"/>
            <span class="create"> Mot de passe réinitialisé avec succès !</span>
            <span class="create-subtitle mt-4 mb-4">Veuillez utiliser dès à présent votre nouveau mot de passe pour vous connecter.</span>
            <button type="button" class="mb-4 btn btn-primary btn-block" (click)="connect()">
                <div class="d-flex flex-row align-items-center justify-content-center">
                    <div *ngIf="isLoading" class="spinner-border"></div>
                    <span [ngClass]="{'ml-2': isLoading }">Retour à la page de connexion</span>
                </div>
            </button>

            <div class="d-block d-sm-none img-mobile-end position-fixed">
            </div>
        </div>
        <div *ngIf="!lienInvalide && !success && !messageErreur" class="bloc-center d-flex flex-column">
            <form [formGroup]="passwordForm" (keydown.enter)="submit()" >
                <span class="create">Nouveau mot de passe</span><br>
                <span class="create-subtitle mt-4 mb-4">Promis, on ne regarde pas…</span>
                <div class="inner_page">
                    <div class="form-group">
                        <label for="password">Nouveau mot de passe</label>
                        <div class="input-group">
                            <input  [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'"
                                    class="form-control" name="password" placeholder="Saisir votre nouveau mot de passe" id="password"
                                    formControlName="password" maxlength="60" minlength="8">
                            <div class="input-group-append">
                                <button
                                    *ngIf="(isPasswordDisplayed$ | async) === false"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isPasswordDisplayed$.next(true)"
                                >
                                    <i class="bi bi bi-eye-fill"></i>
                                </button>
                                <button
                                    *ngIf="(isPasswordDisplayed$ | async) === true"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isPasswordDisplayed$.next(false)">
                                    <i class="bi bi-eye-slash-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="error-message p-4 mb-4">
                        <div class="validation mb-4">Confirmation du votre mot de passe : </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : this.isLessThanEightChars(passwordForm.get('password').value) && passwordForm.get('password').dirty || !passwordForm.get('password').dirty,
                              'icon-check' : !this.isLessThanEightChars(passwordForm.get('password').value) && passwordForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.EIGHT_CHARS' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneLowerCase(passwordForm.get('password').value) && passwordForm.get('password').dirty || !passwordForm.get('password').dirty,
                              'icon-check' : this.hasAtLeastOneLowerCase(passwordForm.get('password').value) && passwordForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.LOWER_CASE' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneUpperCase(passwordForm.get('password').value) && passwordForm.get('password').dirty || !passwordForm.get('password').dirty,
                    'icon-check' : this.hasAtLeastOneUpperCase(passwordForm.get('password').value) && passwordForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.UPPER_CASE' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneDigit(passwordForm.get('password').value) && passwordForm.get('password').dirty || !passwordForm.get('password').dirty,
                    'icon-check' : this.hasAtLeastOneDigit(passwordForm.get('password').value) && passwordForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.DIGIT' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneSpecialChar(passwordForm.get('password').value) && passwordForm.get('password').dirty || !passwordForm.get('password').dirty,
                    'icon-check' : this.hasAtLeastOneSpecialChar(passwordForm.get('password').value) && passwordForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.SPECIAL_CHAR' | translate}}</span>
                            </i>
                        </div>
                    </div>

                    <div class="form-group d-flex flex-column">
                        <label for="confirm_password">Confirmation du mot de passe</label>
                        <div class="input-group">
                            <input [type]="(isConfirmPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="confirm_password"
                                   placeholder=" Saisir à nouveau votre mot de passe" id="confirm_password"
                                   formControlName="confirmPassword">

                            <div class="input-group-append">
                                <button
                                    *ngIf="(isConfirmPasswordDisplayed$ | async) === false"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isConfirmPasswordDisplayed$.next(true)"
                                >
                                    <i class="bi bi bi-eye-fill"></i>
                                </button>
                                <button
                                    *ngIf="(isConfirmPasswordDisplayed$ | async) === true"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isConfirmPasswordDisplayed$.next(false)">
                                    <i class="bi bi-eye-slash-fill"></i>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="passwordForm.get('confirmPassword').dirty">
                            <small class="form-text text-danger"
                                   *ngIf="passwordForm.get('confirmPassword').value != passwordForm.get('password').value">Mot de passe de confirmation invalide</small>
                        </div>
                    </div>
                </div>
                <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!passwordForm.valid" (click)="submit()">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div *ngIf="isLoading" class="spinner-border"></div>
                        <span [ngClass]="{'ml-2': isLoading }">Valider mon nouveau mot de passe</span>
                    </div>
                </button>
            </form>
        </div>
    </div>
    <div *ngIf="waiting" class="loader"></div>
</div>
