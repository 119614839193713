<app-cesu-layout>
    <!-- Create account -->
    <ng-container *ngIf="(registrationStatus$ | async) === CesuRegistrationStatusEnum.CREATE_ACCOUNT">
        <div class="row text-center pt-2 pt-sm-4 pt-lg-5">
            <div class="col-12 cesu-page-title">
                Créer un compte CESU Domalin®
            </div>
            <hr class="cesu-separator"/>
            <div class="col-12">
                Choisissez l’email et le mot de passe que<br/>
                vous utiliserez pour vous connecter.
            </div>
        </div>

        <form class="row pt-4 pt-lg-5" [formGroup]="registrationForm" (ngSubmit)="submitRegistration()">
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-7 col-12 form-group">
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <input
                            class="form-control cesu-input"
                            [ngClass]="{ 'is-invalid': submitted && form.login.errors }"
                            type="text"
                            placeholder="Identifiant"
                            formControlName="login"
                        />
                        <div *ngIf="form.login.errors && submitted" class="invalid-feedback cesu-error">
                            <div *ngIf="form.login.errors.required">
                                <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                                &nbsp;L'identifiant est obligatoire
                            </div>
                            <div *ngIf="form.login.errors.email">
                                <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                                &nbsp;L'identifiant doit être un email
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-7 col-12 form-group">
                        <input
                            class="form-control cesu-input"
                            [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
                            type="password"
                            placeholder="Mot de passe"
                            formControlName="password"
                        />
                        <div *ngIf="form.password.errors && submitted" class="invalid-feedback cesu-error">
                            <div *ngIf="form.password.errors.required">
                                <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                                &nbsp;Le mot de passe est obligatoire
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-7 col-12 form-group">
                        <input
                            class="form-control cesu-input"
                            [ngClass]="{ 'is-invalid': submitted && (form.passwordConfirmation.errors || registrationForm.errors) }"
                            type="password"
                            placeholder="Confirmation du mot de passe"
                            formControlName="passwordConfirmation"
                        />
                        <div *ngIf="form.passwordConfirmation.errors && submitted"
                             class="invalid-feedback cesu-error">
                            <div *ngIf="form.passwordConfirmation.errors.required">
                                <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                                &nbsp;La confirmation du mot de passe est obligatoire
                            </div>
                        </div>
                        <div *ngIf="registrationForm.errors && !form.passwordConfirmation.errors && submitted"
                             class="invalid-feedback cesu-error">
                            <div *ngIf="registrationForm.errors.notSame">
                                <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                                &nbsp;Les mots de passe ne sont pas les mêmes.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <app-password-validation
                            [passwordValue]="form.password.value"
                            [affLastPassword]="false"
                            [progressBar]="true"
                        ></app-password-validation>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-7 col-12 form-check">
                        <label class="form-check-label" [ngClass]="{ 'is-invalid': submitted && form.cgu.errors }">
                            <input type="checkbox" formControlName="cgu"/> En cochant cette case, je reconnais avoir
                            pris connaissance des conditions
                            générales d’utilisation et en avoir accepté les termes.
                        </label>
                        <div *ngIf="form.cgu.errors && submitted" class="invalid-feedback cesu-error">
                            <div *ngIf="form.cgu.errors.required">
                                <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                                &nbsp;Vous devez accepter les conditions générales d'utilisation
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center pt-4">
                <button class="btn cesu-button cesu-flat-button" type="submit">Suivant</button>
            </div>
        </form>

        <div class="row justify-content-center pt-4">
            <div class="col-auto">
                <object type="image/svg+xml" [data]="notActiveStepIcon"></object>
            </div>
            <div class="col-auto">
                <object type="image/svg+xml" [data]="activeStepIcon"></object>
            </div>
            <div class="col-auto">
                <object type="image/svg+xml" [data]="notActiveStepIcon"></object>
            </div>
        </div>
    </ng-container>

    <!-- Apetiz or GRD_COMPTE -->
    <ng-container *ngIf="(registrationStatus$ | async) === CesuRegistrationStatusEnum.APETIZ ||
        (registrationStatus$ | async) === CesuRegistrationStatusEnum.CESU_GRD_COMPTE">
        <ng-container *ngIf="(registrationStatus$ | async) === CesuRegistrationStatusEnum.APETIZ">
            <div class="row text-center pt-2 pt-sm-4 pt-lg-5">
                <div class="col-12 cesu-page-title">
                    Vous possédez un compte Apetiz !
                </div>
                <hr class="cesu-separator"/>
                <div class="col-12">
                    Apetiz et CESU Domalin® sont des marques du groupe Natixis.<br/>
                    Dans le but de simplifier vos accès à nos services, utilisez les identifiants de votre<br/>
                    compte Apetiz pour vous connecter à votre espace CESU Domalin®.
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="(registrationStatus$ | async) === CesuRegistrationStatusEnum.CESU_GRD_COMPTE">
            <div class="row text-center pt-2 pt-sm-4 pt-lg-5">
                <div class="col-12 cesu-page-title">
                    Vous possédez un compte sur le site de commande<br/>
                    CESU Domalin® (commande.cesudomalin.com)
                </div>
                <hr class="cesu-separator"/>
                <div class="col-12">
                    Essayez de vous connecter directement avec votre identifiant et votre mot de passe.
                </div>
            </div>
        </ng-container>

        <form class="row pt-4 pt-lg-5" [formGroup]="registrationForm" (ngSubmit)="login()">
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-7 col-12 form-group">
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <input
                            class="form-control cesu-input"
                            type="text"
                            [ngClass]="{ 'is-invalid': submitted && form.login.errors }"
                            placeholder="Identifiant"
                            formControlName="login"
                        />
                        <div *ngIf="form.login.errors && submitted" class="invalid-feedback cesu-error">
                            <div *ngIf="form.login.errors.required">
                                <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                                &nbsp;L'identifiant est obligatoire
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-7 col-12 form-group">
                        <input
                            class="form-control cesu-input"
                            type="password"
                            [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
                            placeholder="Mot de passe"
                            formControlName="password"
                        />
                        <div *ngIf="form.password.errors && submitted" class="invalid-feedback cesu-error">
                            <div *ngIf="form.password.errors.required">
                                <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                                &nbsp;Le mot de passe est obligatoire
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-right">
                        <a class="cesu-link"
                           (click)="goToForgottenLogin()">{{ 'CESU.LOGIN.AUTHENTICATION.FORGOTTEN_LOGIN' | translate }}</a>
                    </div>
                    <div class="col-6 text-left">
                        <a class="cesu-link"
                           (click)="goToForgottenPwd()">{{ 'CESU.LOGIN.AUTHENTICATION.FORGOTTEN_PASSWORD' | translate }}</a>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center pt-4">
                <button class="btn cesu-button cesu-flat-button" type="submit">Se connecter</button>
            </div>
        </form>

        <div class="row justify-content-center pt-4">
            <div class="col-auto">
                <object type="image/svg+xml" [data]="notActiveStepIcon"></object>
            </div>
            <div class="col-auto">
                <object type="image/svg+xml" [data]="activeStepIcon"></object>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="(registrationStatus$ | async) === CesuRegistrationStatusEnum.CESU_DOMALIN">
        <div class="row text-center justify-content-center pt-lg-5 pt-md-4 pt-2">
            <div class="col-12 cesu-page-title">
                Votre compte est déjà activé !
            </div>
            <hr class="cesu-separator">
            <div class="col-12">
                Merci de vous connecter directement avec votre email et votre mot de passe.
            </div>

            <div class="col-12 pt-lg-5 pt-4">
                <button class="btn cesu-button cesu-flat-button" (click)="goToLogin()">
                    Se Connecter
                </button>
            </div>
        </div>
    </ng-container>
</app-cesu-layout>
