<div id="panel" class="card shadow h-100">
    <ng-container [ngSwitch]="content">
        <app-unlock-container 
        *ngSwitchCase="MerchantSignupRightPanel.CHECK_ACCESS"
        (closePanel$)="closePanel()"
        (goToPanel$)="goToPanel($event)"
        (canAccess$)="canAccess()"
        >
        </app-unlock-container>
        <app-siret-found 
        *ngSwitchCase="MerchantSignupRightPanel.SIRET_FOUND"
        (closePanel$)="closePanel()"
        (goToPanel$)="goToPanel($event)"
        >
        </app-siret-found>
        <app-siret-not-found 
        *ngSwitchCase="MerchantSignupRightPanel.SIRET_NOT_FOUND"
        (closePanel$)="closePanel()"
        (goToPanel$)="goToPanel($event)"
        >
        </app-siret-not-found>
        <app-rgpd-container 
        *ngSwitchCase="MerchantSignupRightPanel.RGPD"
        (closePanel$)="closePanel()"
        (goToPanel$)="goToPanel($event)"
        >
        </app-rgpd-container>
        <app-unlock-by-collection-infos 
        *ngSwitchCase="MerchantSignupRightPanel.COLLECTION_INFOS"
        (closePanel$)="closePanel()"
        (goToPanel$)="goToPanel($event)"
        >
        </app-unlock-by-collection-infos>
        <app-unlock-by-bill-infos 
        *ngSwitchCase="MerchantSignupRightPanel.BILL_INFOS"
        (closePanel$)="closePanel()"
        (goToPanel$)="goToPanel($event)"
        >
        </app-unlock-by-bill-infos>
    </ng-container>
</div>
<div id="overlay" (click)="closePanel()"></div>
