import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SiloApiService } from '@services/siloApi.service';
import { GatewayService } from '@services/gateway.service';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import {finalize} from 'rxjs/operators';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-pin-code-bimpli',
  templateUrl: './pin-code-bimpli.component.html',
  styleUrls: ['./pin-code-bimpli.component.scss']
})
export class PinCodeBimpliComponent implements OnInit {
  @Input() login: string;
  @Input() redirectUrl: string;
  @Input() context: string;
  @Input() applicationId: string;

  constructor(
    private fb: FormBuilder,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private snackbarService: SnackbarService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}
  isSecondStep = false;
  hide = true;
  currentPasswordInvalid = false;
  codeInvalid = false;
  isLoading: boolean;
  private valueCaptcha: string;
  otpType: string;
  otpSubmitting: boolean = false;

  identifiantForm = this.fb.group({
    currentPassword: [undefined, Validators.required],
    code: [undefined, [Validators.required, Validators.minLength(6)]]
  });

  ngOnInit(): void {}

  goToSecondStep() {
    this.isLoading = true;
    if (this.identifiantForm.get('currentPassword').valid) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.reCaptchaV3Service
          .execute('UserPasswordCheckAction')
          .pipe(
            finalize(() => {
              this.siloApiService
                .userPasswordCheck(
                  this.login,
                  this.identifiantForm.get('currentPassword').value,
                  this.applicationId,
                  authToken,
                  this.valueCaptcha
                )
                .subscribe(
                  (retour) => {
                    this.currentPasswordInvalid = !retour.resultCheck;
                    if (!this.currentPasswordInvalid) {
                      this.siloApiService.createOTP(this.login, this.applicationId, authToken, this.context).subscribe(
                        (result) => {
                          this.isSecondStep = true;
                          this.isLoading = false;
                          this.otpType = result.otpType;
                          if (result.otpType === 'OTPMAIL') {
                            this.snackbarService.showSuccess(
                              'Email envoyé à ces adresses : ' + result.email?.join(', ') + ' : suivez maintenant les instructions contenues dans le mail envoyé.'
                            );
                          } else {
                            this.snackbarService.showSuccess(
                              'SMS envoyé au ' + result.phone + ' : suivez maintenant les instructions contenues dans le SMS envoyé.'
                            );
                          }
                        },
                        (error) => {
                          this.isLoading = false;
                          if (error.error.errors[0] && error.error.errors[0].code === 'IMPOSSIBLE_ACTION_013') {
                            this.snackbarService.showDanger('Des informations sont manquantes pour pouvoir accéder à votre demande. Merci de contacter le support client.');
                          } else {
                            this.snackbarService.showDanger('Erreur imprévue lors de l\'envoie veuillez réassayer plus tard');
                          }
                        }
                      );
                    } else {
                      this.isLoading = false;
                    }
                  },
                  (erreur) => {
                    this.gererErreurs(erreur);
                  }
                );
            })
          )
          .subscribe((token) => (this.valueCaptcha = token));
      });
    }
  }

  public gererErreurs(error: HttpErrorResponse) {
    this.isLoading = false;
    if (error.error instanceof ErrorEvent) {
      this.snackbarService.showDanger('Erreur inattendue : ' + error.error.message);
    } else {
      const errors: Erreurs = error.error;
      if (errors && errors.errors !== undefined) {
        switch (errors.errors[0].code) {
          case CodeErreurs.FORBIDDEN_ACTION_040:
            this.snackbarService.showDanger('Compte bloqué');
            break;
          default:
            this.snackbarService.showDanger('Erreur imprévue veuillez réassayer plus tard');
            break;
        }
      } else {
        this.snackbarService.showDanger('Erreur imprévue veuillez réassayer plus tard');
      }
    }
  }

  submitForm() {
    if (this.otpSubmitting) {
      return;
    }
    this.otpSubmitting = true;
    if (this.identifiantForm.valid) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.siloApiService
          .checkOTP(
            this.login,
            this.applicationId,
            this.identifiantForm.get('code').value,
            this.identifiantForm.get('currentPassword').value,
            authToken
          )
          .subscribe(
            (result) => {
              this.codeInvalid = false;
              if (this.redirectUrl) {
                window.location.href = this.redirectUrl;
              }
            },
            (error) => {
              this.codeInvalid = true;
              this.otpSubmitting = false;
            }
          );
      });
    }
  }

  back() {
    if (this.isSecondStep) {
      this.isSecondStep = false;
    } else {
      window.history.back();
    }
  }

  onCodeChanged(code: string) {
    if (code.length === 0) {
      this.codeInvalid = false;
    }
    this.identifiantForm.get('code').setValue(code);
  }
}
