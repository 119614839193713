<div id="menu-stepper" class="h-100">
  <div class="sub-title">
    {{ "AFFILIE_APZ.MERCHANTS_INVITE.LEFSITE.SUBTITLE" | translate }}
  </div>
  <img
    src="../../../../assets/images/logo-bimpli-swile.svg"
    alt="Bimpli x Swile"
    class="mb-4"
  />
  <div class="divider w-75 mt-3 mb-5"></div>
  <div class="titre mb-3 ">
    {{ "AFFILIE_APZ.MERCHANTS_INVITE.LEFSITE.INFO" | translate }}
  </div>
  <div
    class="d-flex align-items-center mb-2"
    [ngClass]="{ 'current-step': step === 1 }"
  >
    <div class="step">1</div>
    <div class="ml-2 step-libelle">
      {{ "AFFILIE_APZ.MERCHANTS_INVITE.LEFSITE.COORDONNEES" | translate }}
    </div>
  </div>
  <div
    class="d-flex align-items-center mb-2"
    [ngClass]="{ 'current-step': step === 2 }"
  >
    <div class="step">2</div>
    <div class="ml-2 step-libelle">
      {{ "AFFILIE_APZ.MERCHANTS_INVITE.LEFSITE.PRESTATION" | translate }}
    </div>
  </div>
  <div
    class="d-flex align-items-center mb-2"
    [ngClass]="{ 'current-step': step === 3 }"
  >
    <div class="step">3</div>
    <div class="ml-2 step-libelle">
      {{ "AFFILIE_APZ.MERCHANTS_INVITE.LEFSITE.CONTRAT" | translate }}
    </div>
  </div>
</div>
