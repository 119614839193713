import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { ReponseVerifierCode } from '@shared/models/reponse.verifierCode.model';
import decodeCodeApp from 'src/app/shared/helpers/decoder.helper';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-confirmation-demande-mot-de-passe-sms',
  templateUrl: './confirmation-demande-mot-de-passe-sms.component.html',
  styleUrls: ['./confirmation-demande-mot-de-passe-sms.component.scss', '../../../assets/css/apz-global.scss'],
})
export class ConfirmationDemandeMotDePasseSmsComponent implements OnInit {
  public codeSMS: FormControl;
  public ginsc: FormGroup;
  public messageErreur: string;
  public saml: string;
  private codeApplication: string;
  public estApetizMobile: boolean;
  public isLoading : boolean;

  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder,
              private siloApiService: SiloApiService, private gatewayService: GatewayService,
              private snackbarService: SnackbarService) {
  }

  ngOnInit() {
    this.codeSMS = this.formBuilder.control(undefined, [Validators.required]);
    this.ginsc = this.formBuilder.group([this.codeSMS]);
    this.saml = this.route.snapshot.paramMap.get('saml');
    const samlDecoder = atob(this.saml);
    this.codeApplication = samlDecoder.split('|')[2];
    const decoded = decodeCodeApp(this.codeApplication);
    this.estApetizMobile = decoded.estAppetizMobile;
  }


  public submit(): void {
    this.messageErreur = undefined;
    if (this.ginsc.valid) {
      this.snackbarService.hide();
      this.isLoading = true;
      this.gatewayService
        .getAccessToken()
        .subscribe(authToken => this.siloApiService
          .verifierCodeSMSReinit(this.codeSMS.value, authToken)
          .pipe(finalize(() => this.isLoading = false))
          .subscribe(reponse => this.redirigerPageActivation(reponse), error => this.gererErreurs(error)));
    }
  }

  public redirigerPageActivation(reponse: string) {
    const reponseJSON: ReponseVerifierCode = JSON.parse(reponse);
    this.router.navigate([`${RoutesEnum.CHANGE_FORGOTTEN_PASSWORD}/${reponseJSON.hash}`], { queryParams: { hash: reponseJSON.token } });
  }

  public gererErreurs(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = JSON.parse(error.error);
      switch (errors.errors[0].code) {
        case CodeErreurs.UNEXPECTED_011:
          this.messageErreur = 'Erreur technique';
          break;
        case CodeErreurs.IMPOSSIBLE_ACTION_041:
          this.messageErreur = 'Le code est incorrect. Veuillez vérifier et réessayer.';
          break;
        case CodeErreurs.FORBIDDEN_ACTION_010:
          this.messageErreur = 'Le code a expiré.';
          break;
        default:
          this.messageErreur = 'Erreur inattendue : ' + errors.errors[0].code;
          break;
      }
    }
    this.snackbarService.showDanger(this.messageErreur);
  }
}
