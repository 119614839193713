<app-apz-change-id
  *ngIf="estMobileAPZ && !showConfimationModifLogin"
  (submitted)="updateUser($event.currentPassword, $event.newLogin)"
  [errorMessage]="messageErreur"
  [identifiantOK]="identifiantOK"
  [login]="login"
>
</app-apz-change-id>
<app-apz-web-change-id
  *ngIf="estAppetiz && !showConfimationModifLogin"
  (submitted)="updateUser($event.currentPassword, $event.newLogin)"
  [errorMessage]="messageErreur"
  [identifiantOK]="identifiantOK"
  [login]="login"
  [saml]="saml"
>
</app-apz-web-change-id>
<app-affilie-apz-change-id
  *ngIf="estAffilie && !showConfimationModifLogin"
  [login]="login"
  [errorMessage]="messageErreur"
  (submitted)="updateUser($event.currentPassword, $event.newLogin)"
></app-affilie-apz-change-id>
<app-confirmation-changement-user
  *ngIf="estAppetiz && showConfimationModifLogin"
  [saml]="saml"
  title="{{ 'COMMON.USER.CHANGE_USER_OK' | translate }}"
  [isLogin]="true"
></app-confirmation-changement-user>
<app-confirmation-mobile *ngIf="estMobileAPZ && showConfimationModifLogin" [redirectUrl]="redirectUrl"> </app-confirmation-mobile>
<app-bimpli-change-id
    *ngIf="estBimpli"
    (submitted)="updateUser($event.currentPassword, $event.newLogin)"
    [errorMessage]="messageErreur"
    [identifiantOK]="identifiantOK"
    [applicationId]="applicationId"
    [login]="login"
    [isLoading]="isLoading"
    [messageErreur]="messageErreur"
    [saml]="saml"
>
</app-bimpli-change-id>
