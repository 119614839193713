import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-last-step',
  templateUrl: './last-step.component.html',
  styleUrls: ['./last-step.component.scss']
})
export class LastStepComponent implements OnInit {

  @Input() email!: string;
  @Output() reSend$: EventEmitter<void> = new EventEmitter();
  @Output() goBack$: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  reSendEmail(): void {
    this.reSend$.emit();
  }

  goBack(): void {
    this.goBack$.emit();
  }

}
