<div class="cesu-footer bg-white mt-auto">
    <div class="container h-100">
        <div class="row align-items-center pt-3 pb-3">
            <div class="col-lg-auto col-md-6 col-12">
                <a (click)="goToLegalNotice()">{{ 'CESU.FOOTER.LEGAL_NOTICE' | translate }}</a>
            </div>
            <div class="col-lg-auto col-md-6 col-12">
                <a (click)="goToDataProtection()">{{ 'CESU.FOOTER.DATA_PROTECTION' | translate }}</a>
            </div>
            <div class="col-lg-auto col-md-6 col-12">
                <a (click)="goToTermsAndConditions()">{{ 'CESU.FOOTER.CGU' | translate }}</a>
            </div>
            <div class="col-lg col-md-12 col-12">
                <div class="float-lg-right">{{ 'CESU.FOOTER.COPYRIGHT' | translate }}</div>
            </div>
        </div>
    </div>
</div>
