<app-cesu-layout>
    <div class="row text-center justify-content-center pt-lg-5 pt-md-4 pt-2">
        <div class="col-12 cesu-page-title">
            {{ 'CESU.FORGOTTEN_LOGIN_MAIL.TITLE' | translate }}
        </div>
        <hr class="cesu-separator"/>
        <div class="col-12" [innerHTML]="'CESU.FORGOTTEN_LOGIN_MAIL.INFO' | translate"></div>
        <a class="font-weight-bold"
           (click)="retrySendForgottenLogin()">{{ 'CESU.FORGOTTEN_LOGIN_MAIL.RESEND' | translate }}</a>
        <div class="col-12 pt-lg-5 pt-4">
            <img class="cesu-big-icon" src="../../../../../assets/cesu/mail_icon.svg"/>
        </div>
        <div class="col-12 pt-lg-5 pt-4">
            <button class="btn cesu-button cesu-flat-button" (click)="goToLogin()">
                {{ 'CESU.FORGOTTEN_LOGIN_MAIL.BUTTON' | translate}}
            </button>
        </div>
    </div>
</app-cesu-layout>
