import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GroundTruthDataService {

    private isCardActivated = new BehaviorSubject<boolean>(false);
    private isSpinnerActivated = new BehaviorSubject<boolean>(false);

    getIsCardActivated(): Observable<boolean> {
        return this.isCardActivated.asObservable();
    }

    setIsCardActivated(value: boolean) {
        this.isCardActivated.next(value);
    }

    getIsSpinnerActivated(): Observable<boolean> {
        return this.isSpinnerActivated.asObservable();
    }

    setIsSpinnerActivated(value: boolean) {
        this.isSpinnerActivated.next(value);
    }


}
