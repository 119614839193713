<div class="container vw-100 vh-100" *ngIf="refresh$ | async">
  <div class="logo"><img src="/assets//affilie-apz/logo.svg" /></div>
  <div class="row">
    <div class="col-md-6">
      <img src="/assets//affilie-apz/visuel.svg" />
    </div>
    <div class="col-md-6">
      <div class="d-flex flex-column">
        <div class="text">
          {{ 'AFFILIE_APZ.MERCHANTS_INVITE.ACCOUNT_EXISTS.TEXT' | translate }}
        </div>
        <button type="button" class="btn btn-primary" (click)="gotoConnection()">
          {{ 'AFFILIE_APZ.MERCHANTS_INVITE.ACCOUNT_EXISTS.BUTTON' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
>
