import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  // Variables
  toasts: any[] = [];

  // Methods
  show(text: string, options: any = {}): void {
    this.toasts.push({ text, ...options });
  }

  remove(toast: any): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
