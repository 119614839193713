import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { mailRegex } from 'nit-angular-lib';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-affilie-apz-forgotten-pwd',
  templateUrl: './affilie-apz-forgotten-pwd.component.html',
  styleUrls: ['../../../../assets/css/affilie-apz-global.scss', './affilie-apz-forgotten-pwd.component.scss']
})
export class AffilieApzForgottenPwdComponent implements OnInit, OnDestroy {
  // Variables
  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();
  @Input() notificationEnvoyer: boolean;
  @Input() errorMessage: string;

  forgottenPwdForm: FormGroup = this.fb.group({
    login: ['', [Validators.required, Validators.pattern(mailRegex)]]
  });
  isLoading = false;
  mySubscription: Subscription = new Subscription();
  isSubmitted = false;

  // Constructor
  constructor(private fb: FormBuilder, private gtds: GroundTruthDataService) {}

  // Methods
  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });
    this.mySubscription.add(isSpinnerActivated);
  }

  submit(): void {
    this.isSubmitted = true;
    this.notificationEnvoyer = false;
    this.errorMessage = undefined;
    if (this.forgottenPwdForm.valid) {
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit(this.forgottenPwdForm.get('login').value);
    }
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }
}
