import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
import { SessionState } from 'src/app/shared/states/session-state.model';


@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }
}

export function createInitialState(): SessionState {
  return {
    saml: undefined,
    type: undefined,
    applicationCode: undefined,
    productId: undefined,
    registrationStep: RegistrationStepEnum.NONE,
    accountActivationInfo: undefined,
    forgottenLoginMail: undefined,
    forgottenPasswordLogin: undefined,
    forgottenPasswordInfo: undefined,
    changePasswordInfo: undefined,
    signupAffiliateManager: undefined,
    merchantsInvite: undefined,
    isLocal: undefined
  } as SessionState;
}
