<div class="img-row">
    <a><img src=" ../../../assets/asp/logo_wee.png" class="logo-img" (click)="goToLogin()" /></a>
</div>

<div class="header d-flex align-items-center justify-content-center">
    <p class="title">{{'CREATION_ACCOUNT.TITLE' | translate}}</p>
</div>
<div *ngIf="!success" class="subHeaderError d-flex align-items-center justify-content-center">
    <i class="icon-info mr-3"></i>
    <p>{{messageErreur}}</p>
</div>
<div class="d-flex background-blanc justify-content-center align-items-center p-4">
    <div class="row images-header d-flex justify-content-between align-items-center">
        <i class="icon-carte icon rounded-circle d-flex align-items-center justify-content-center"></i>
        <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
        <i class="icon-profil icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
        <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
        <i class="icon-envoi-mail icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
        <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
        <i class="icon-active-card icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
    </div>
</div>

<div class="container detail-container">
    <p class="title-card text-left">{{'CREATION_ACCOUNT.FIRST_STEP.CARTE' | translate}}</p>
    <p class="texte text-left pb-3 pt-3">{{'CREATION_ACCOUNT.FIRST_STEP.MESSAGE_CONFIRM' | translate}}</p>

    <!-- Vue Descktop -->
    <div class="d-none d-md-block">
        <form name="form" role="form" [formGroup]="cardForm" novalidate>
            <div class="row">
                <div class="col-sm-12 col-md-6 text-left p-0">
                    <label for="numCard" class="texte">{{'CREATION_ACCOUNT.FIRST_STEP.NUM_CARTE' | translate}}</label>
                    <div class="row" [formGroup]="cardForm.get('numCard')">
                        <input type="text" inputmode="numeric" maxlength="4" formControlName="numCard"
                               class="num-card col-sm-12 col-md-2" id="numCard" placeholder="****"
                               (keyup)="calculeSize($event, 4)" onclick="this.value=''" />
                        <input type="text" inputmode="numeric" maxlength="4" formControlName="numCard2"
                               class="num-card col-sm-12 col-md-2" id="numCard2" placeholder="****"
                               (keyup)="calculeSize($event, 4)" onclick="this.value=''" />
                        <input type="text" inputmode="numeric" maxlength="4" formControlName="numCard3"
                               class="num-card col-sm-12 col-md-2" id="numCard3" placeholder="****"
                               (keyup)="calculeSize($event, 4)" onclick="this.value=''" />
                        <input type="text" inputmode="numeric" maxlength="4" formControlName="numCard4"
                               class="num-card col-sm-12 col-md-2" id="numCard4" placeholder="****"
                               (keyup)="calculeSize($event, 4)" onclick="this.value=''" />
                    </div>
                    <div class="text-left" *ngIf="cardForm.get('numCard').invalid">
                        <small class="form-text text-danger">
                            {{'COMMON.FORM.REQUIRED' | translate}}
                        </small>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 text-left p-0">
                    <label for="expirationDate"
                           class="texte">{{'CREATION_ACCOUNT.FIRST_STEP.DATE_EXPE' | translate}}</label>
                    <div class="row" [formGroup]="cardForm.get('expirationDate')">
                        <input type="text" #expiration inputmode="numeric" maxlength="2" formControlName="expirationDate"
                               class="expire-card col-2" id="expirationDate" placeholder="mm" (keyup)="calculeSize($event, 2)"
                               onclick="this.value=''" />
                        <input type="text" inputmode="numeric" maxlength="2" formControlName="expirationDate2"
                               class="expire-card col-2" id="expirationDate2" placeholder="aa" (keyup)="calculeSize($event, 2)"
                               onclick="this.value=''" />
                    </div>
                    <div class="text-left" *ngIf="cardForm.get('expirationDate').invalid">
                        <small class="form-text text-danger"
                               *ngIf="cardForm.get('expirationDate').get('expirationDate').errors?.pattern">
                            {{'COMMON.FORM.MONTH_PATTERN' | translate}}
                        </small>
                        <small class="form-text text-danger" *ngIf="cardForm.get('expirationDate').invalid">
                            {{'COMMON.FORM.REQUIRED' | translate}}
                        </small>
                    </div>
                </div>
            </div>
            <div class="actions btn-group-container d-flex justify-content-center align-items-center">
                <button class="btn btn-secondary" (click)="sortie()">
                    {{'CREATION_ACCOUNT.RETOUR' | translate}}
                </button>
                <nit-spinner-button (clicked)="send()" label="{{'CREATION_ACCOUNT.VALIDER' | translate}}"
                                    [disabled]="cardForm.invalid" [isLoading]="isLoading" css="btn btn-primary btn-large">
                </nit-spinner-button>
            </div>
        </form>
    </div>
    <div class="d-md-none">
        <form name="form" role="form" [formGroup]="cardForm" novalidate>
            <div class="row">
                <label for="numCard" class="texte">{{'CREATION_ACCOUNT.FIRST_STEP.NUM_CARTE' | translate}}</label>
            </div>
            <div class="row" [formGroup]="cardForm.get('numCard')">
                <input type="text" inputmode="numeric" maxlength="4" formControlName="numCard"
                       class="num-card col p-0" id="numCard" placeholder="****"
                       (keyup)="calculeSize($event, 4)" onclick="this.value=''" />
                <input type="text" inputmode="numeric" maxlength="4" formControlName="numCard2"
                       class="num-card col p-0" id="numCard2" placeholder="****"
                       (keyup)="calculeSize($event, 4)" onclick="this.value=''" />
                <input type="text" inputmode="numeric" maxlength="4" formControlName="numCard3"
                       class="num-card col p-0" id="numCard3" placeholder="****"
                       (keyup)="calculeSize($event, 4)" onclick="this.value=''" />
                <input type="text" inputmode="numeric" maxlength="4" formControlName="numCard4"
                       class="num-card col p-0" id="numCard4" placeholder="****"
                       (keyup)="calculeSize($event, 4)" onclick="this.value=''" />
            </div>
            <div class="row">
                <small class="form-text text-danger" *ngIf="cardForm.get('numCard').invalid">
                    {{'COMMON.FORM.REQUIRED' | translate}}
                </small>
            </div>
            <div class="row pt-4">
                <label for="expirationDate"
                       class="texte">{{'CREATION_ACCOUNT.FIRST_STEP.DATE_EXPE' | translate}}</label>
            </div>
            <div class="row" [formGroup]="cardForm.get('expirationDate')">
                <input type="text" #expiration inputmode="numeric" maxlength="2" formControlName="expirationDate"
                       class="expire-card col-2 p-0" id="expirationDate" placeholder="mm" (keyup)="calculeSize($event, 2)"
                       onclick="this.value=''" />
                <input type="text" inputmode="numeric" maxlength="2" formControlName="expirationDate2"
                       class="expire-card col-2 p-0" id="expirationDate2" placeholder="aa" (keyup)="calculeSize($event, 2)"
                       onclick="this.value=''" />
            </div>
            <div class="row">
                <div class="text-left" *ngIf="cardForm.get('expirationDate').invalid">
                    <small class="form-text text-danger"
                           *ngIf="cardForm.get('expirationDate').get('expirationDate').errors?.pattern">
                        {{'COMMON.FORM.MONTH_PATTERN' | translate}}
                    </small>
                    <small class="form-text text-danger" *ngIf="cardForm.get('expirationDate').invalid">
                        {{'COMMON.FORM.REQUIRED' | translate}}
                    </small>
                </div>
            </div>

            <div class="d-flex justify-content-center mt-4 row">
                    <nit-spinner-button (clicked)="send()" label="{{'CREATION_ACCOUNT.VALIDER' | translate }}"
                                        [disabled]="cardForm.invalid" [isLoading]="isLoading" css="btn btn-primary btn-large">
                    </nit-spinner-button>
                    <button class="btn btn-large btn-secondary mt-2" (click)="sortie()">
                        {{'CREATION_ACCOUNT.RETOUR' | translate}}
                    </button>


            </div>
        </form>
    </div>
</div>

<app-modal id="cardAlreadyActivated">
    <app-header-modal titleModal="{{ 'CREATION_ACCOUNT.FIRST_STEP.MODAL_HEADER' | translate }}"
        (modalClose)="closeModal('cardAlreadyActivated')">
    </app-header-modal>
    <div class="container popUp">
        <div class="pt-3 d-flex justify-content-center">
            <p>{{ 'CREATION_ACCOUNT.FIRST_STEP.MODAL_TEXT' | translate }}</p>
        </div>

        <div class="btn-group-container-modal col-12">
            <button id="goHomeBtn" class="btn btn-primary btn-lg btn-large" type="submit" (click)="goToLogin()">
                {{ 'CREATION_ACCOUNT.FIRST_STEP.MODAL_BTN' | translate }}
            </button>
        </div>
    </div>
</app-modal>
