<div id="first-step-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-row h-100">
    <div
      id="menu-container"
      class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 p-0"
      [ngClass]="{
        'col-12 col-sm-12': (isStepOpen$ | async) === true,
        'd-none d-md-block': (isStepOpen$ | async) === false || (isPanelOpen$ | async) === true
      }"
    >
      <app-affilie-apz [step]="1" [saml]="saml" (isStepOpen)="isStepOpen$.next($event)"></app-affilie-apz>
    </div>
    <div
      id="step-container"
      class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 p-0"
      [ngClass]="{
        'd-none d-md-block': (isStepOpen$ | async) === true || (isPanelOpen$ | async) === true,
        'col-12 col-sm-12': (isStepOpen$ | async) === false
      }"
    >
      <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
        <div class="col-xxl-4 col-xl-4 col-lg-8 col-md-9 col-sm-10 col-10">
          <div class="d-flex flex-row align-items-center justify-content-between w-100 d-sm-flex d-md-none">
            <button id="back-sm-btn" type="button" class="btn btn-link p-0" (click)="isStepOpen$.next(true)">
              <div class="d-flex flex-row align-items-center justify-content-center">
                <i id="back-sm-btn-icon" class="bi bi-arrow-left"></i>
              </div>
            </button>
            <span id="stepper-pos">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.STEP_SM' | translate }}</span>
          </div>
          <form
            class="d-flex flex-column align-items-center justify-content-center h-100 w-100"
            name="form"
            role="form"
            [formGroup]="siretForm"
            (submit)="validate()"
            (keydown.enter)="validate()"
          >
            <div class="d-flex flex-column align-items-center justify-content-center w-100">
              <!-- SIRET -->
              <div class="d-flex flex-column w-100">
                <label class="form-label" for="siret">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.SIRET' | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  name="siret"
                  id="siret"
                  formControlName="siret"
                  maxlength="14"
                  [ngClass]="{
                    'is-invalid': siretForm.get('siret').invalid === true && siretForm.get('siret').touched === true,
                    'is-valid': siretForm.get('siret').invalid === false && siretForm.get('siret').touched === true
                  }"
                  placeholder="{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.SIRET_PROMPT' | translate }}"
                  (paste)="formatSiret($event)"
                />
                <div *ngIf="siretForm.get('siret').touched && siretForm.get('siret').invalid">
                  <small class="text-danger error-msg" *ngIf="siretForm.get('siret').errors.required">
                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                  </small>
                  <small class="text-danger error-msg" *ngIf="siretForm.get('siret').errors.pattern">
                    {{ 'AFFILIE_APZ.CREATION_ACCOUNT.ERROR.SIRET_INVALID' | translate }}
                  </small>
                </div>
              </div>
              <!-- SUBMIT -->
              <div class="pt-4 d-flex flex-row align-items-center justify-content-center w-100">
                <button type="button" class="btn btn-primary btn-block" [disabled]="!siretForm.valid || isLoading" (click)="validate()">
                  <div class="d-flex flex-row align-items-center justify-content-center">
                    <div *ngIf="isLoading" class="spinner-border"></div>
                    <span
                      [ngClass]="{
                        'ml-2': isLoading
                      }"
                      id="submit-btn-label"
                      >{{ 'COMMON.FORM.SEARCH' | translate }}</span
                    >
                  </div>
                </button>
              </div>
            </div>
            <div id="divider" class="w-100 mt-4 mb-4"></div>
            <div class="d-flex flex-row justify-content-center align-items-center w-100">
              <span id="submit-btn-label"> {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.ALREADY_ACCOUNT' | translate }}</span>
              <a class="signin-link ml-2" (click)="back()">
                {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.CONNEXION' | translate }}</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
    <div *ngIf="isPanelOpen$ | async" id="panel" class="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12 h-100 card shadow">
      <div class="d-flex flex-column align-items-start justify-content-center w-100">
        <button id="close-panel-btn" type="button" class="btn btn-link" (click)="closePanel()">
          <div class="d-flex flex-row align-items-center justify-content-center">
            <i id="close-panel-btn-icon " class="bi bi-arrow-left"></i>
            <span class="ml-2" id="close-panel-btn-label">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.CLOSE' | translate }}</span>
          </div>
        </button>
      </div>
      <!-- CONTINUE -->
      <div *ngIf="isAffiliateFound" class="d-flew flex-column align-items-center justify-content-center p-3 data">
        <span id="found-data-title" class="d-flex flex-row align-items-center justify-content-start">{{
          'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.SIRET_FOUND.TITLE' | translate
        }}</span>
        <span class="d-flex flex-row align-items-center justify-content-start mb-4 secondary-data-info">{{
          'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.SIRET_FOUND.DESCRIPTION' | translate
        }}</span>
        <div id="etablissement-data" class="p-3">
          <div class="d-flew flex-column align-items-center justify-content-start mb-2">
            <span class="d-flex flex-row align-items-center justify-content-start secondary-data-info">{{
              'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.SIRET_FOUND.NAME' | translate
            }}</span>
            <span id="company-name" class="d-flex flex-row align-items-center justify-content-start">{{ companyName }}</span>
          </div>
          <div class="d-flew flex-column align-items-center justify-content-start mb-2">
            <span class="d-flex flex-row align-items-center justify-content-start secondary-data-info">{{
              'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.SIRET_FOUND.LOCATION' | translate
            }}</span>
            <span id="address" class="d-flex flex-row align-items-center justify-content-start">{{ address }}</span>
          </div>
          <div class="d-flex flex-column align-items-end justify-content-center w-100">
            <button id="close-panel-btn" type="button" class="btn btn-primary" (click)="nextStep()">
              <div class="d-flex flex-row align-items-center justify-content-center">
                <span id="submit-btn-label">{{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.SIRET_FOUND.OK' | translate }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <!-- AFFILIATE ALREADY EXIST -->
      <div *ngIf="isKnowAffiliate" class="d-flew flex-column align-items-center justify-content-center p-3 data">
        <span class="d-flex flex-row align-items-center justify-content-start already-exist-title">{{
          'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.ALREADY_AFFILIATE.TITLE' | translate
        }}</span>
        <span class="d-flex flex-row align-items-center justify-content-start secondary-data-info mb-2">{{
          'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.ALREADY_AFFILIATE.DESCRIPTION' | translate
        }}</span>
        <div class="d-flex flex-row align-items-center justify-content-start">
          <button class="btn btn-link secondary-data-info">
            {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.INVALID_SIRET_LINK' | translate }}
          </button>
        </div>
      </div>
      <!-- INVALID SIRET -->
      <div *ngIf="isSiretInvalide" class="d-flew flex-column align-items-center justify-content-center p-3 data">
        <span class="d-flex flex-row align-items-center justify-content-start already-exist-title">{{
          'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.INVALID_SIRET.TITLE' | translate
        }}</span>
        <span class="d-flex flex-row align-items-center justify-content-start secondary-data-info mb-2">{{
          'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.INVALID_SIRET.DESCRIPTION' | translate
        }}</span>
        <div class="d-flex flex-row align-items-center justify-content-start">
          <button class="btn btn-link secondary-data-info">
            {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.INVALID_SIRET_LINK' | translate }}
          </button>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-start">
          <button class="btn btn-link secondary-data-info">
            {{ 'AFFILIE_APZ.CREATION_ACCOUNT.RIGHT_PANEL.FIRST_STEP.SIRET_NOT_RECOGNIZE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="overlay" (click)="isPanelOpen$.next(false)" *ngIf="isPanelOpen$ | async"></div>
