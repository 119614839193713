import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthToken } from '@shared/models/authtoken.model';
import { CadoUserResponse } from '@shared/models/cadoUserResponse.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GatewayService } from './gateway.service';

@Injectable()
export class CadoService {

  private readonly urlUserIDP: string;

  public constructor(private http: HttpClient, private gatewayService: GatewayService, @Inject('env') env) {
    this.urlUserIDP = environment.gatewayEndPointRS + '/CADO/' + (env.localApi ? '' : 'userIDP/') + env.versionApi;
  }

  private getHttpHeader(token: AuthToken): HttpHeaders {
    return new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });
  }

  public readUserAccountsByCriteria(token: AuthToken, siret: string, captchaResponse?: string): Observable<CadoUserResponse> {
    const url = this.urlUserIDP + '/userAccounts';
    let httpHeaders;
    if (captchaResponse && captchaResponse !== '') {
      httpHeaders = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token, 'Recaptcha-Response': captchaResponse });
    } else {
      httpHeaders = this.getHttpHeader(token);
    }

    let httpParams = new HttpParams();
    if (siret) {
      httpParams = httpParams.append('siret', siret);
    }

    return this.http.get<CadoUserResponse>(url, { headers: httpHeaders, params: httpParams });
  }
}
