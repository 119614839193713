import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NitAngularLibModule } from 'nit-angular-lib';
import { environment } from 'src/environments/environment';
import { LangInterceptor } from './lang.interceptor';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    NitAngularLibModule.forRoot(environment),
    NgbPopoverModule,
    NgbDatepickerModule,
  ],
  exports: [HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    NgbPopoverModule,
    NgbDatepickerModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
  ],
})
export class CoreModule {
}
