import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-affiliate-apz-stepper',
  templateUrl: './affiliate-apz-stepper.component.html',
  styleUrls: ['../../../../assets/css/affilie-apz-global.scss', './affiliate-apz-stepper.component.scss']
})
export class AffiliateApzStepperComponent {
  @Input() step = 0;
  @Output() isStepOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  isStepOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth > 576 ? false : true);

  public hideStep(): void {
    this.isStepOpen.emit(false);
  }
}
