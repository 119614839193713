<div class="img-row">
    <img class="logo-img" src=" ../../../assets/asp/logo_wee.png">
</div>
<div class="bandeau">
    {{'ASP.CHANGE_PWD.TITLE' | translate }}
</div>
<div *ngIf="errorMessage" class="subHeaderError d-flex align-items-center justify-content-center">
    <i class="icon-info mr-3 d-flex align-items-center"></i>
    <p class="m-0">{{errorMessage}}</p>
</div>

<!-- row pour la vue descktop -->
<div class="d-none d-md-block">
    <form name="passwordForm" [formGroup]="passwordForm" (submit)="submitForm()" class="row justify-content-center form-asp">
        <section class="col-12 col-md-8">
            <div class="row">
                <div class="col-6">
                    <div class="col-12 col-sm">
                        <div class="form-group">
                            <label for="currentPassword" class="form-label"> {{ 'COMMON.PASSWORD.CURRENT_PWD' | translate }}</label>
                            <input type="password" class="form-control" id="currentPassword" name="currentPassword"
                                formControlName="currentPassword" placeholder="******" (keyup)="arePasswordsDifferent()"/>
                            <div *ngIf="passwordForm.get('currentPassword').touched && passwordForm.get('currentPassword').invalid">
                                <small class="form-text text-danger" *ngIf="passwordForm.get('currentPassword').errors.required">
                                    {{'COMMON.FORM.REQUIRED' | translate}}
                                </small>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="newPassword" class="form-label titre">{{ 'ASP.CHANGE_PWD.MOT_DE_PASSE' | translate }}</label>
                            <input type="password" class="form-control value" id="newPassword" name="newPassword"
                                formControlName="newPassword" placeholder="******" (keyup)="isNewPasswordIsConfirmed()" />
                            <div *ngIf="passwordForm.get('newPassword').touched">
                                <small class="form-text text-danger"
                                    *ngIf="passwordForm.get('newPassword').errors && passwordForm.get('newPassword').errors.required">
                                    {{'COMMON.FORM.REQUIRED' | translate}}
                                </small>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="confirmPassword" class="form-label titre">{{ 'ASP.CHANGE_PWD.MOT_DE_PASSE_CONFIRM' | translate }}</label>
                            <input type="password" class="form-control value" id="confirmPassword" name="confirmPassword" formControlName="confirmPassword" placeholder="******" (keyup)="isNewPasswordIsConfirmed()" />
                            <div *ngIf="passwordForm.get('confirmPassword').touched">
                                <small class="form-text text-danger"
                                    *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required">
                                    {{'COMMON.FORM.REQUIRED' | translate}}
                                </small>
                                <small class="form-text text-danger" *ngIf="!passwordEqual">
                                    {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
                                </small>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-6 infos-utilisateur">
                    <p class="pt-2">{{'ASP.CHANGE_PWD.VALIDATION' | translate}}</p>
                    <app-password-validation [passwordValue]="passwordForm.get('newPassword').value" [currentPassword]="passwordForm.get('currentPassword').value" [affLastPassword]="true" [progressBar]="true">
                    </app-password-validation>
                </div>
            </div>
            <div class="actions pt-5">
                <button class="btn btn-secondary" (click)="back()">
                    {{'COMMON.FORM.BACK' | translate | uppercase}}
                </button>
                <button type="submit" class="btn btn-primary" [disabled]="!passwordForm.valid || !passwordEqual || !doNotMatch">
                    {{'COMMON.FORM.SUBMIT' | translate | uppercase}}
                </button>
            </div>

            <div class="legal pt-5">
                {{ 'ASP.CHANGE_PWD.MANDATORY' | translate }}
            </div>
        </section>
    </form>
</div>
<!-- row pour la vue mobile -->
<div class="d-md-none p-2 bg-blanc">
    <form name="passwordForm" [formGroup]="passwordForm" (submit)="submitForm()" class="justify-content-center">
        <div class="row m-0">
            <div class="col-12">
                <div class="form-group">
                    <label for="currentPassword" class="form-label"> {{ 'COMMON.PASSWORD.CURRENT_PWD' | translate }}</label>
                    <input type="password" class="form-control" name="currentPassword"
                        formControlName="currentPassword" placeholder="******" (keyup)="arePasswordsDifferent()"/>
                    <div *ngIf="passwordForm.get('currentPassword').touched && passwordForm.get('currentPassword').invalid">
                        <small class="form-text text-danger" *ngIf="passwordForm.get('currentPassword').errors.required">
                            {{'COMMON.FORM.REQUIRED' | translate}}
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="newPassword" class="form-label titre">{{ 'ASP.CHANGE_PWD.MOT_DE_PASSE' | translate }}</label>
                    <input type="password" class="form-control value" name="newPassword"
                        formControlName="newPassword" placeholder="******" (keyup)="isNewPasswordIsConfirmed()" />
                    <div *ngIf="passwordForm.get('newPassword').touched">
                        <small class="form-text text-danger"
                            *ngIf="passwordForm.get('newPassword').errors && passwordForm.get('newPassword').errors.required">
                            {{'COMMON.FORM.REQUIRED' | translate}}
                        </small>
                    </div>
                </div>
            </div>        
            <div class="col-12 infos-utilisateur">
                <p class="pt-2">{{'ASP.CHANGE_PWD.VALIDATION' | translate}}</p>
                <app-password-validation [passwordValue]="passwordForm.get('newPassword').value" [currentPassword]="passwordForm.get('currentPassword').value" [affLastPassword]="true" [progressBar]="true">
                </app-password-validation>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="confirmPassword" class="form-label titre">{{ 'ASP.CHANGE_PWD.MOT_DE_PASSE_CONFIRM' | translate }}</label>
                    <input type="password" class="form-control value" name="confirmPassword" formControlName="confirmPassword" placeholder="******" (keyup)="isNewPasswordIsConfirmed()" />
                    <div *ngIf="passwordForm.get('confirmPassword').touched">
                        <small class="form-text text-danger"
                            *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required">
                            {{'COMMON.FORM.REQUIRED' | translate}}
                        </small>
                        <small class="form-text text-danger" *ngIf="!passwordEqual">
                            {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions col-12 pt-3">    
            <button type="submit" class="btn btn-primary" [disabled]="!passwordForm.valid || !passwordEqual || !doNotMatch">
                {{'COMMON.FORM.SUBMIT' | translate | uppercase}}
            </button>
            <button class="btn btn-secondary mt-2" (click)="back()">
                {{'COMMON.FORM.BACK' | translate | uppercase}}
            </button>
        </div>

        <div class="legal pt-5">
            {{ 'ASP.CHANGE_PWD.MANDATORY' | translate }}
        </div>
    </form>    
</div>

<!-- Modal -->
<app-modal id="confirmModal">
    <app-header-modal titleModal="{{'ASP.CHANGE_PWD.MODAL_HEADER' | translate}}" (modalClose)="cancelPopUp()">
    </app-header-modal>
    <div class="container pt-4 popUp text-center">
        <b>{{'ASP.CHANGE_PWD.MODAL_TEXT' | translate}}</b>
        <br />
        <p>{{'ASP.CHANGE_PWD.MODAL_NOW' | translate}}</p>
    </div>
    <div class="actions">
        <button type="button" class="btn btn-secondary"
            data-dismiss="modal" (click)="cancelPopUp()">{{'ASP.FORGOTTEN_PWD.CLOSE' | translate}}</button>
    </div>
</app-modal>
