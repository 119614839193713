import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss'],
})
export class CguComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public saml: string;
  public applicationId: ApplicationCodeEnum;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
  ) {
  }

  public ngOnInit(): void {
    this.sessionStateService.setApplicationCodeFromUnknownRoute(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    const applicationIdSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(tap((applicationId) => {
        switch(applicationId) {
          default:
            this.applicationId = applicationId;
            break;
        }
        }))
      .subscribe();

    this.subscription.add(applicationIdSubscription);

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
