<div class="signin-container">
    <div class="parallax d-none d-md-flex">
      <div class="hookContainer">
        <div class="wee"><img src="assets/asp/we_cut.png" alt="WEE" /></div>
      </div>
    </div>
    <div class="col-12 col-md right-panel">
      <!-- Sign in  -->
      <form class="form login" role="form" [formGroup]="signInForm" (submit)="submit()" (keydown.enter)="submit()">
        <div class="row justify-content-center signin-block">
          <div class="col-12 d-flex justify-content-end pr-5">
            <p #menuFrench (click)="setLang('fr')">{{ 'COMMON.LANGUAGE.FRENCH' | translate }} /</p>
            &nbsp;
            <p class="textLight" #menuEnglish (click)="setLang('en')">
              {{ 'COMMON.LANGUAGE.ENGLISH' | translate }}
            </p>
          </div>
          <div class="col-12 col-lg-8">
            <div class="row justify-content-center">
              <img src=" ../../../assets/asp/logo_wee.png" class="logo-img" />
            </div>
            <div class="alert alert-danger" *ngIf="errorMessage">
              {{ errorMessage }}
            </div>

            <!-- Login -->
            <div class="form-group">
              <label class="login-label" for="login">{{ 'ASP.EMAIL' | translate }}</label>
              <input
                type="text"
                class="form-control"
                name="login"
                id="login"
                formControlName="login"
                placeholder="{{ 'ASP.EMAIL' | translate }}"
              />
            </div>
            <div class="row">
              <div class="col" *ngIf="signInForm.get('login').touched && signInForm.get('login').invalid">
                <small class="form-text text-danger" *ngIf="signInForm.get('login').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="form-text text-danger" *ngIf="signInForm.get('login').errors.email">
                  {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
                </small>
              </div>
              <div class="col text-right">
                <a class="forget-link" (click)="requestResetLogin()">{{ 'ASP.FORGOT_LOGIN' | translate }}</a>
              </div>
            </div>

            <!-- Password -->
            <div class="form-group">
              <label for="password">{{ 'ASP.PASSWORD' | translate }}</label>
              <input
                type="password"
                class="form-control"
                name="password"
                id="password"
                formControlName="password"
                placeholder="{{ 'ASP.PASSWORD' | translate }}"
              />
            </div>
            <div class="row">
              <div class="col" *ngIf="signInForm.get('password').touched && signInForm.get('password').invalid">
                <small class="form-text text-danger" *ngIf="signInForm.get('password').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col text-right">
                <a class="forget-link" (click)="requestResetPassword()">
                  {{ 'ASP.FORGOT_PASSWORD' | translate }}
                </a>
              </div>
            </div>
          </div>

          <!-- Submit -->
          <div class="col-12 col-lg-8 pt-4 d-flex justify-content-center">
            <nit-spinner-button
              (clicked)="submit()"
              label="{{ 'ASP.CONNECTION' | translate }}"
              type="submit"
              [disabled]="!signInForm.valid"
              [isLoading]="isLoading"
              css="btn btn-primary btn-block"
            >
            </nit-spinner-button>
          </div>
        </div>
      </form>

      <!-- Register -->
      <div class="row justify-content-center register-block align-items-end">
        <div class="col-12 col-lg-8 register align-self-center">
          <p class="text-left pb-3">
            <b> {{ 'ASP.FIRST_CONNEXION' | translate }}</b
            ><br />
            {{ 'ASP.FIRST_CONNEXION_DESCRIPTION' | translate }}
          </p>
          <button type="button" class="btn btn-secondary btn-block" (click)="firstConnexion()">
            {{ 'ASP.SIGN_IN' | translate | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </div>
