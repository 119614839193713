import { Adress } from './adress';
import { AffiliateContact } from './affiliate-contact';
import { AffiliateType } from './affiliate-type';
import { Agreement } from './agreement';
import { Company } from './company';

export interface Affiliate {
  id: string;
  number: number;
  ownerUserId: number;
  compensatingId: string;
  externalReference?: string;
  webRefund?: boolean;
  name?: string;
  affiliateType?: AffiliateType;
  address: Adress;
  company: Company;
  agreement?: Agreement;
  managers?: Array<AffiliateContact>;
}

export class ReadAffiliatesParamQuery {
  constructor(
    public compensatingId?: string,
    public affiliateNumber?: string,
    public lastName?: string,
    public firstName?: string,
    public birthDate?: string,
    public postalCode?: string,
    public webRefund?: string,
    public sort?: string,
    public siret?: string,
    public email?: string,
    public isRefundCenter?: boolean,
    public affiliateFatherId?: string
  ) {}
}
