<div *ngIf="isAlreadyExist" class="background-bimpli d-flex flex-column align-items-center justify-content-center vw-100 vh-100">
    <button (click)="redirectToLogin();" class="position-fixed d-flex align-items-center justify-content-center btn btn-secondary btn-block">Passer</button>
    <div class="card col-4 d-flex flex-column align-items-center justify-content-center">
        <span class="title-bimpli">Vous possédez déjà un compte Bimpli</span>
        <span class="sub-title mt-4">Vous allez être redirigé à la page de connexion Bimpli, où vous pourrez vous connectez avec vos identifiants CESU habituels.</span>
        <img class="ico-timer mt-2" src="/assets/bimpli/ico-temps-ecoul.svg">
        <span class="redirect mt-2">Redirection dans {{timer}} secondes</span>
    </div>
</div>

<div *ngIf="!isAlreadyExist" class="container-fluid vw-100 vh-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3">
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()" *ngIf="!isMobile">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Créer un compte Bimpli CESU</span>
            <span class="create-subtitle mt-4 mb-4">Merci de renseigner votre code personnel CESU ainsi que votre date de naissance.</span>

            <form name="cesuForm" role="cesuForm" (keydown.enter)="send()" (ngSubmit)="send()" [formGroup]="cesuForm">
                <div class="d-flex flex-column form-group">
                    <label>
                        Code personnel CESU *
                    </label>
                    <input type="text" class="form-control" name="cesu" placeholder="Code personnel CESU" id="cesu"
                        formControlName="cesu">
                    <div *ngIf="cesuForm.get('cesu').touched && cesuForm.get('cesu').invalid">
                        <small class="form-text text-danger" *ngIf="cesuForm.get('cesu').errors?.required">
                            {{ 'COMMON.FORM.REQUIRED' | translate }}
                        </small>
                    </div>
                    <span class="link text-right mt-2" (click)="openPopUp('popUpCodePersonnel')">Code personnel CESU oublié ?</span>
                </div>

                <div class="d-flex flex-column form-group">
                    <label for="birthdate" class="birthday-label">{{'USUAL.BIRTHDATE' | translate}}
                        *</label>
                    <div class="input-group">
                        <input placeholder="Saisie de la date dd/mm/aaaa" class="form-control"
                               formControlName="birthdate"
                               name="birthdate" ngbDatepicker #birthdate="ngbDatepicker"
                               [startDate]="scheduledStartDate" [minDate]="{year: 1900, month:1, day: 1}" [maxDate]="{year: 2022, month:1, day: 19}">
                        <div class="input-group-append">
                            <button class="bi bi-calendar btn btn-outline-secondary calendar"
                                    (click)="birthdate.toggle()" type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="(cesuForm.get('birthdate').touched)">
                        <small class="form-text text-danger"
                               *ngIf="cesuForm.get('birthdate').errors">Date de naissance incorrect</small>
                    </div>
                </div>

                <button type="button" class="btn btn-primary btn-block" [disabled]="!cesuForm.valid" (click)="send()">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div *ngIf="isLoading" class="spinner-border"></div>
                        <span [ngClass]="{'ml-2': isLoading }">Continuer</span>
                    </div>
                </button>
            </form>
        </div>
    </div>
</div>

<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>


<app-modal id="popUpCodePersonnel">
    <div class="d-flex flex-column p-4">
        <span class="title-popup text-center">Où retrouver votre code personnel ?</span>
        <span class="subtitle-popup mt-2">Votre code personnel est indiqué dans la lettre d’accueil que vous avez reçu par courrier et sur la couverture
            de votre carnet papier de CESU. Il s’agit d’un numéro à 8 chiffres commençant par le chiffre 3.</span>

        <div class="mt-4 mb-4">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('popUpCodePersonnel')">FERMER</button>
        </div>
    </div>
</app-modal>
