import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { version } from '../../../../package.json';
import {TealiumService} from '@services/tealium/tealium.service';

@Component({
  selector: 'app-apz-layout',
  templateUrl: './apz.component.html',
  styleUrls: ['./apz.component.scss']
})
export class ApzComponent {
  @Input() title: string;
  @Input() saml: string;
  @Input() showBack = true;

  public version: string = version;

  constructor(private router: Router, private readonly tealiumService: TealiumService) { }

  goLogin() {
    this.router.navigateByUrl('/login/' + this.saml);
  }

  goBack() {
    if (this.title === 'Identifiant oublié'){
      this.tealiumService.link('apz.web.forgottenLogin.returnToLoginClick');
    }
    window.history.back();
  }

  getMentionsLegales() {
    this.router.navigateByUrl('/notice/' + this.saml);
  }

  getRGPD() {
    this.router.navigateByUrl('/rgpd/' + this.saml);
  }

  getCGU() {
    this.router.navigateByUrl('/cgu/' + this.saml);
  }


}
