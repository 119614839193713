<app-apz-forgotten-password
    *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ || (applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE"
    (submitted)="demandeReinitialisationMotDePasse($event)"
    (recalled)="renvoyerDemande($event)"
    [messageErreur]="messageErreur"
    [notificationEnvoyer]="notificationEnvoyer"
    [estApetizMobile]="estApetizMobile"
    [saml]="saml"
    [receiver]="receiver"
>
</app-apz-forgotten-password>
<app-bimpli-forgotten-pwd
  *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE ||
  (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR"
      (submitted)="demandeReinitialisationMotDePasse($event)"
      (recalled)="renvoyerDemande($event)"
      [messageErreur]="messageErreur"
      [applicationId]="(applicationCode$ | async)"
      [notificationEnvoyer]="notificationEnvoyer"
      [estApetizMobile]="estApetizMobile"
      [saml]="saml"
      [receiver]="receiver"
      [isLoading]="isLoading"
>
</app-bimpli-forgotten-pwd>

<app-asp-forgotten-pwd
  *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN"
  (submitted)="demandeReinitialisationMotDePasse($event)"
  (recalled)="renvoyerDemande($event)"
  (cancelled)="goBack()"
  [errorMessage]="messageErreur"
  [notificationEnvoyer]="notificationEnvoyer"
>
</app-asp-forgotten-pwd>

<app-cesu-forgotten-pwd *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU"> </app-cesu-forgotten-pwd>

<app-affilie-apz-forgotten-pwd
    *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.AFFILIE_APZ"
    (submitted)="demandeReinitialisationMotDePasse($event)"
    [errorMessage]="messageErreur"
    [notificationEnvoyer]="notificationEnvoyer"
>
</app-affilie-apz-forgotten-pwd>
