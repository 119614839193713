import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RoutesEnum } from '../../../shared/enums/routes.enum';

export const SIRET = 'siret';
export const INFO_GREFFE_DATA = 'infoGreffeData';
export const CIVILITY = 'civility';
export const FIRSTNAME = 'firstName';
export const LASTNAME = 'lastName';
export const PHONE_NUMBER = 'phoneNumber';
export const CELL_PHONE_NUMBER = 'cellPhoneNumber';
export const EMAIL = 'email';
@Component({
  selector: 'app-affilie-apz',
  templateUrl: './affilie-apz.component.html',
  styleUrls: ['../../../../assets/css/affilie-apz-global.scss', './affilie-apz.component.scss']
})
export class AffilieApzComponent {
  // Variables
  @Input() step = 0;
  @Input() saml: string;
  @Output() isStepOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  isStepOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth > 576 ? false : true);

  // Constructor
  constructor(private router: Router) {}

  // Methods
  back(): void {
    this.router.navigateByUrl(`${RoutesEnum.LOGIN}/${this.saml}`);
  }

  hideStep(): void {
    this.isStepOpen.emit(false);
  }
}
