import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@services/app.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { TealiumService } from '@services/tealium/tealium.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { TealiumUtagService } from './tealium/utag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'tsp-idp';
  saml: string;
  public applicationCode$: Observable<ApplicationCodeEnum>;
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');

  constructor(
    translate: TranslateService,
    private readonly sessionQuery: SessionQuery,
               private readonly activatedRoute: ActivatedRoute,
               private readonly tealiumService: TealiumService,
               private readonly sessionStateService: SessionStateService,
               private titleService: Title,
    private readonly tealiumUtagService: TealiumUtagService,
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('fr');

    // Permet de récupérer le code Application dans le token.
    // le token peut contenir soit un '|' soit un ';'
    this.sessionStateService.setApplicationCode(this.activatedRoute);
    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;
    const applicationCodeSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(
        tap((applicationCode: ApplicationCodeEnum) => {
          if (ApplicationCodeEnum.BENEFIT_CAGNOTTE ===  applicationCode || ApplicationCodeEnum.BENEFIT_FINANCEUR ===  applicationCode
            || ApplicationCodeEnum.BENEFIT_BENEF ===  applicationCode || ApplicationCodeEnum.BENEFIT_MOBILE ===  applicationCode
            || ApplicationCodeEnum.AFFILIE_APZ ===  applicationCode || ApplicationCodeEnum.APZ ===  applicationCode) {
          this.favIcon.href = '/assets/bimpli/favicon.ico';
          this.titleService.setTitle('Mon espace personnel Bimpli');
         }
        })
      )
      .subscribe();

    this.tealiumUtagService.setConfig({
      account: environment.tealiumAccount,
      profile: environment.tealiumProfile,
      environment: environment.tealiumBuildEnvironment,
    });
  }
}
