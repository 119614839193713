import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { TermsAndConditions } from 'nit-angular-lib';
import { AuthToken } from '../../../shared/models/authtoken.model';

@Component({
  selector: 'app-cgu-affilie-apz',
  templateUrl: './cgu-affilie-apz.component.html',
  styleUrls: ['./cgu-affilie-apz.component.scss']
})
export class CguAffilieApzComponent implements OnInit {
  // Variables
  cgu: string;
  cguContent: string;

  // Constructor
  constructor(private gatewayService: GatewayService, private siloApiService: SiloApiService, private translateService: TranslateService) {}

  // Methods
  ngOnInit(): void {
    this.gatewayService.getAccessToken().subscribe((authToken: AuthToken) =>
      this.siloApiService
        .readTermsAndConditions(ApplicationCodeEnum.AFFILIE_APZ, 'AFFILIATE', authToken, this.translateService.currentLang)
        .subscribe((response: TermsAndConditions) => {
          this.cguContent = response.items[0].content;
        })
    );
  }

  goBack(): void {
    window.close();
  }
}
