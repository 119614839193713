import { Component, OnInit } from '@angular/core';
import { SessionQuery } from '../../shared/services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Observable } from 'rxjs';
import { SessionStateService } from '../../shared/services/store/session/session-state.service';
import { ActivatedRoute } from '@angular/router';
import { GatewayService } from '../../shared/services/gateway.service';
import { SiloApiService } from '../../shared/services/siloApi.service';
import { finalize } from 'rxjs/operators';
import { GroundTruthDataService } from '../../shared/services/ground-truth-data.service';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Erreurs, CodeErreurs } from '../../shared/models/errors.model';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@shared/components/modal/modal.service';

@Component({
  selector: 'app-changement-question',
  templateUrl: './changement-question.component.html',
  styleUrls: ['./changement-question.component.scss']
})
export class ChangementQuestionComponent implements OnInit {
  public ApplicationCodeEnum = ApplicationCodeEnum;
  public saml: string;
  public login: string;
  public applicationId: string;
  public redirectUrl: string;
  public messageErreur: string;
  public estBimpli: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly gtds: GroundTruthDataService,
    private readonly modalService: ModalService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.saml = this.route.snapshot.paramMap.get('saml');
    const samlDecoder = atob(this.saml);
    this.login = samlDecoder.split(';')[0];
    this.applicationId = samlDecoder.split(';')[1];

    if (ApplicationCodeEnum.BENEFIT_CAGNOTTE ===  this.applicationId || ApplicationCodeEnum.BENEFIT_FINANCEUR ===  this.applicationId
      || ApplicationCodeEnum.BENEFIT_BENEF ===  this.applicationId || ApplicationCodeEnum.BENEFIT_MOBILE ===  this.applicationId) {
      this.estBimpli = true;
    }

    this.redirectUrl = samlDecoder.split(';')[2];
  }

  submit(form) {
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.siloApiService
        .updateUserSecretQuestionsResponses(
          authToken,
          this.login,
          this.applicationId,
          form.currentPassword,
          form.response,
          form.secretQuestion
        )
        .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
        .subscribe(
          () => {
            this.snackbarService.showSuccess('Mise à jour effectué');
            if (this.redirectUrl) {
              window.location.href = this.redirectUrl;
            }
          },
          (error) => this.gererErreurs(error)
        );
    });
  }

  public gererErreurs(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = error.error;
      switch (errors.errors[0].code) {
        case CodeErreurs.UNEXPECTED_011:
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED_011');
          break;
        case CodeErreurs.INCORRECT_DATA_007:
          this.messageErreur = this.translateService.instant('COMMON.FORM.PASSWORD_INVALID');
          break;
        default:
          this.messageErreur = this.messageErreur =
            this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED') + errors.errors[0].code;
          break;
      }
    }
    if (this.estBimpli) {
      this.modalService.open('errorModal');
    } else {
      this.snackbarService.showDanger(this.messageErreur);
    }
  }
}
