<div class="container-fluid vw-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3">
        <div class="d-flex flex-column">
            <span class="title-bp">Youpi !</span>
            <span class="sub-title-bp">Vous avez sélectionné <br>Cado !</span>
        </div>
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4" (keydown.enter)="goToFirstStep()">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()" *ngIf="!isMobile">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Avez-vous votre CADO Carte à portée de main ? </span>
            <div class="bloc-card d-flex mt-4 p-4 justify-content-center align-items-center">
                <img class="card-sample" src="/assets/bimpli/card-sample.png"/>
            </div>
            <span class="create-subtitle mt-4 mb-4">Pour vous authentifier, nous allons avoir besoin du code de votre CADO carte dans une prochaine étape.<br><br>
                Sans ce code, vous ne serez pas en mesure de créer votre compte Bimpli. Si c'est le cas, merci de réessayer plus tard. 😉</span>

            <button type="button" class="btn btn-primary btn-block mb-4" (click)="goToFirstStep()">
                <div class="d-flex flex-row align-items-center justify-content-center">
                    <span>Continuer</span>
                </div>
            </button>

        </div>
    </div>
</div>
