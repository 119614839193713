import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { GroundTruthDataService } from '../../../shared/services/ground-truth-data.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import {mailRegex} from 'nit-angular-lib';

@Component({
  selector: 'app-apz-forgotten-login',
  templateUrl: './apz-forgotten-login.component.html',
  styleUrls: ['./apz-forgotten-login.component.scss', '../../../../assets/css/apz-global.scss']
})
export class ApzForgottenLoginComponent implements OnInit, OnChanges, OnDestroy {
  @Input() messageErreur;
  @Input() notificationEnvoyer;
  @Input() estApetizMobile;
  @Input() saml;
  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() recalled: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelled: EventEmitter<string> = new EventEmitter<string>();

  isLoading: boolean;

  forgottenLoginForm = this.fb.group({
    identifiant: [undefined, [Validators.required, Validators.pattern(mailRegex)]]
  });

  mySubscription = new Subscription();

  constructor(protected fb: FormBuilder, private gtds: GroundTruthDataService, private snackbarService: SnackbarService) {}

  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messageErreur) {
      if (this.messageErreur) {
        this.snackbarService.showDanger(this.messageErreur);
      } else {
        this.snackbarService.hide();
      }
    }
  }

  submit() {
    if (this.forgottenLoginForm.valid) {
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit(this.forgottenLoginForm.get('identifiant').value);
    }
  }

  recall() {
    this.gtds.setIsSpinnerActivated(true);
    this.recalled.emit(this.forgottenLoginForm.get('identifiant').value);
  }

  goLogin() {
    this.cancelled.emit();
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }
}
