<div class="card">
    <div class="card-header">
        <h2>Demande d'authentification aux APIs TSP</h2>
    </div>
    <div class="card-body">
        <form #f="ngForm" (submit)="validerInscription(f)">
            <section [formGroup]="ginsc">
                <div>
                    <label>Client ID</label><input [formControl]="clientId" type="text" class="form-control" />
                    <span *ngIf="!clientId.valid && f.submitted" style="color: red">obligatoire</span>
                </div>
                <br />
                <div>
                    <label>Response Type</label><input [formControl]="responseType" type="text" class="form-control" />
                    <span *ngIf="!responseType.valid && f.submitted" style="color: red">obligatoire</span>
                </div>
            </section>
            <br />
            <div>
                <button type="submit" class="btn btn-primary">Envoie demande</button>
            </div>
        </form>
    </div>
</div>
