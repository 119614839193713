import { Component, OnInit } from '@angular/core';
import { CesuService } from '@services/cesu/cesu.service';
import { CesuQuery } from '@services/store/cesu/cesu.query';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cesu-layout',
  templateUrl: './cesu.component.html',
  styleUrls: ['./cesu.component.scss', '../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuComponent implements OnInit {
  public showFooter$: Observable<boolean>;

  constructor(
    private readonly cesuService: CesuService,
    private readonly cesuQuery: CesuQuery,
  ) {
  }

  public ngOnInit(): void {
    this.showFooter$ = this.cesuQuery.selectedShowFooter;
  }

  public goToLogin() {
    this.cesuService.goToLogin();
  }

}
