import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promo-app-mobile',
  templateUrl: './promo-app-mobile.component.html',
  styleUrls: ['./promo-app-mobile.component.scss']
})
export class PromoAppMobileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
