<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN && callApiIsDone">
  <app-second-step-asp-component
    [employee]="employee"
    [cellPhonePro]="cellPhonePro"
    [mailPro]="mailPro"
    (retour)="back()"
    (submitted)="submit($event.form)"
    [token]="saml"
    [messageErreur]="messageErreur"
    [success]="success"
  >
  </app-second-step-asp-component>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE">
    <app-second-step-apz-mobile
        [saml]="saml"
        (retour)="back()"
        (submitted)="submit($event.form, $event.type)"
    >
    </app-second-step-apz-mobile>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ">
    <app-second-step-apz-component
        (retour)="back()"
        [messageErreur]="messageErreur"
        (submitted)="submit($event.form, $event.type)"
        [saml]="saml"
    >
    </app-second-step-apz-component>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE ||
        (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE">
    <div *ngIf="type === 'apetiz'">
      <app-second-step-bimpli-apetiz
          (submitted)="submit($event.form, $event.type)"
          [saml]="saml"
          [isLoading]="isLoading"
          [application]="applicationCode$ | async"
          [messageErreur]="messageErreur">
      </app-second-step-bimpli-apetiz>
    </div>
    <div *ngIf="type === 'cado'">
      <app-second-step-bimpli-cado
        [application]="applicationCode$ | async"
        [saml]="saml">
      </app-second-step-bimpli-cado>
    </div>
    <div *ngIf="type === 'comiteo'">
        <app-second-step-bimpli-comiteo
            [application]="applicationCode$ | async"
            [saml]="saml">
        </app-second-step-bimpli-comiteo>
    </div>
    <div *ngIf="type === 'cesu'">
        <app-second-step-bimpli-cesu
        (submitted)="submit($event.form, $event.type, $event.employeeId)"
            [saml]="saml"
            [application]="applicationCode$ | async"
            [messageErreur]="messageErreur">
        </app-second-step-bimpli-cesu>
    </div>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR_TEST">
    <div *ngIf="type === 'cado'">
        <app-second-step-bimpli-financeur-cado
            [saml]="saml">
        </app-second-step-bimpli-financeur-cado>
    </div>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
  <app-cesu-second-step></app-cesu-second-step>
  <app-cesu-footer></app-cesu-footer>
</div>

<div class="app-affilie-apz-component" *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.AFFILIE_APZ">
  <app-second-step-affilie-apz-component
    (submitted)="submit($event.form, $event.type)"
    [saml]="saml"
    [messageErreur]="messageErreur"
    [success]="success"
  ></app-second-step-affilie-apz-component>
</div>
