<div class="container-fluid vw-100 vh-100">
  <div class="row h-100">
    <div id="left-side" class="col-xxl-3 col-xl-3 col-lg-4 col-md-4 d-none d-md-block p-0">
      <app-merchant-stepper [step]="1"></app-merchant-stepper>
    </div>
    <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-8 p-0 col-12 col-sm-12">
      <div class="form-content ">
        <div class="titre mb-2">
          {{ 'AFFILIE_APZ.MERCHANTS_INVITE.INFO_COMMERCIALE.TITLE' | translate }}
        </div>
        <div class="sub-title">
          {{ 'AFFILIE_APZ.MERCHANTS_INVITE.INFO_COMMERCIALE.SUBTITLE' | translate }}
        </div>
        <div class="row mt-3 commerce-info" *ngIf="affiliate">
          <div class="col-md-3 col-sm-12 mb-2" *ngIf="affiliate.company">
            <div class="label">
              {{ 'AFFILIE_APZ.MERCHANTS_INVITE.INFO_COMMERCIALE.NOM_COMMERCIAL' | translate }}
            </div>
            <div class="label-content">{{ affiliate.company.companyName }}</div>
          </div>
          <div class="col-md-3 col-sm-12 mb-2">
            <div class="label">
              {{ 'AFFILIE_APZ.MERCHANTS_INVITE.INFO_COMMERCIALE.NOM_LEGAL' | translate }}
            </div>
            <div class="label-content">{{ affiliate.name }}</div>
          </div>
          <div class="col-md-3 col-sm-12 mb-2" *ngIf="affiliate.address">
            <div class="label">
              {{ 'AFFILIE_APZ.MERCHANTS_INVITE.INFO_COMMERCIALE.ADRESSE' | translate }}
            </div>
            <div class="label-content">{{ affiliate.address.street }}</div>
            <div class="label-content">
              <span>{{ affiliate.address.postalCode }}</span> &nbsp;
              <span>{{ affiliate.address.city }}</span>
            </div>
          </div>
          <div class="col-md-3 col-sm-12 mb-2" *ngIf="affiliate.company">
            <div class="label">
              {{ 'AFFILIE_APZ.MERCHANTS_INVITE.INFO_COMMERCIALE.SIRET' | translate }}
            </div>
            <div class="label-content">{{ affiliate.company.siret }}</div>
          </div>
        </div>

        <form [formGroup]="contactForm" class="mt-5">
          <div class="titre mb-2">
            {{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.TITLE' | translate }}
          </div>
          <div class="sub-title mb-3">
            {{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.SUBTITLE' | translate }}
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label> {{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ROLE' | translate }}</label>
              <select type="select" class="form-select form-control" name="role" id="role" [formControlName]="'role'">
                <option value="Dirigeant">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ROLE_DIRIGEANT' | translate }} </option>
                <option value="Comptable">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ROLE_COMPTABLE' | translate }} </option>
                <option value="Proprietaire">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ROLE_PROPRIETAIRE' | translate }} </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="email" class="email">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.EMAIL' | translate }}</label>
              <input type="text" class="form-control" name="email" id="email" [formControlName]="'email'" maxlength="38" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="firstName">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.FIRST_NAME' | translate }}</label>
              <input
                required
                type="text"
                class="form-control"
                name="firstName"
                id="firstName"
                [formControlName]="'firstName'"
                maxlength="38"
                [ngClass]="{
                  'is-invalid': contactForm.get('firstName').invalid === true && contactForm.get('firstName').touched === true,
                  'is-valid': contactForm.get('firstName').invalid === false && contactForm.get('firstName').touched === true
                }"
                placeholder="{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.FIRST_NAME_PROMPT' | translate }}"
              />
              <div *ngIf="contactForm.get('firstName').touched === true && contactForm.get('firstName').invalid">
                <small class="text-danger bold" *ngIf="contactForm.get('firstName').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="text-danger bold" *ngIf="contactForm.get('firstName').errors.pattern">
                  {{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ERROR.FIRST_NAME_PATTERN' | translate }}
                </small>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="lastName">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.LAST_NAME' | translate }}</label>
              <input
                required
                type="text"
                class="form-control"
                name="lastName"
                id="lastName"
                [formControlName]="'lastName'"
                maxlength="38"
                [ngClass]="{
                  'is-invalid': contactForm.get('lastName').invalid === true && contactForm.get('lastName').touched === true,
                  'is-valid': contactForm.get('lastName').invalid === false && contactForm.get('lastName').touched === true
                }"
                placeholder="{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.LAST_NAME_PROMPT' | translate }}"
              />
              <div *ngIf="contactForm.get('lastName').touched === true && contactForm.get('lastName').invalid">
                <small class="text-danger bold" *ngIf="contactForm.get('lastName').errors && contactForm.get('lastName').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="text-danger bold" *ngIf="contactForm.get('lastName').errors && contactForm.get('lastName').errors.pattern">
                  {{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ERROR.LAST_NAME_PATTERN' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="phoneNumber">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.PHONE_NUMBER' | translate }}</label>
              <input
                type="tel"
                class="form-control"
                name="phoneNumber"
                id="phoneNumber"
                [formControlName]="'phoneNumber'"
                [ngClass]="{
                  'is-invalid':
                    contactForm.get('phoneNumber').invalid === true &&
                    contactForm.get('phoneNumber').touched === true &&
                    contactForm.get('phoneNumber').value,
                  'is-valid':
                    contactForm.get('phoneNumber').invalid === false &&
                    contactForm.get('phoneNumber').touched === true &&
                    contactForm.get('phoneNumber').value
                }"
                placeholder="{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.PHONE_NUMBER_PROMPT' | translate }}"
              />
              <div *ngIf="contactForm.get('phoneNumber').touched === true && contactForm.get('phoneNumber').invalid">
                <small class="text-danger bold" *ngIf="contactForm.get('phoneNumber').errors.pattern">
                  {{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ERROR.PATTERN_PHONENUMBER' | translate }}
                </small>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="cellPhoneNumber">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.CELL_PHONE_NUMBER' | translate }}</label>
              <input
                type="tel"
                class="form-control"
                name="cellPhoneNumber"
                id="cellPhoneNumber"
                [formControlName]="'cellPhoneNumber'"
                [ngClass]="{
                  'is-invalid': contactForm.get('cellPhoneNumber').invalid === true && contactForm.get('cellPhoneNumber').touched === true,
                  'is-valid': contactForm.get('cellPhoneNumber').invalid === false && contactForm.get('cellPhoneNumber').touched === true
                }"
                placeholder="{{ 'AFFILIE_APZ.SIGNUP_AFFILIE.RIGHT_PANEL.SECOND_STEP.CELL_PHONE_NUMBER_PROMPT' | translate }}"
              />
              <div class="info mt-2">
                {{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.PHONE_INFO' | translate }}
              </div>
              <div *ngIf="contactForm.get('cellPhoneNumber').touched === true && contactForm.get('cellPhoneNumber').invalid">
                <small
                  class="text-danger bold"
                  *ngIf="contactForm.get('cellPhoneNumber').errors && contactForm.get('cellPhoneNumber').errors.required"
                >
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="text-danger bold" *ngIf="contactForm.get('cellPhoneNumber').errors.pattern">
                  {{ 'AFFILIE_APZ.MERCHANTS_INVITE.COORDONNEES.ERROR.PATTERN_CELLPHONENUMBER' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div class="titre mt-4 mb-4">
            {{ 'AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.TITLE' | translate }}
          </div>
          <div class="sub-title mb-3">
            {{ 'AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.SUBTITLE' | translate }}
          </div>
          <div class="form-row">
            <div class="form-group col-md-6 position-relative">
              <label for="password">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.PWD' | translate }}</label>
              <input
                [type]="showPassword === false ? 'password' : 'text'"
                class="form-control"
                name="password"
                id="password"
                [formControlName]="'password'"
                [ngClass]="{
                  'is-invalid': contactForm.get('password').invalid === true && contactForm.get('password').touched === true,
                  'is-valid': contactForm.get('password').invalid === false && contactForm.get('password').touched === true
                }"
                placeholder="{{ 'AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.PWD' | translate }}"
              />
              <ng-container *ngIf="!showPassword">
                <img (click)="showPassword = !showPassword" class="icon-eye" src="/assets//affilie-apz/icon-visibility-off.svg" />
              </ng-container>
              <ng-container *ngIf="showPassword">
                <img (click)="showPassword = !showPassword" class="icon-eye" src="/assets//affilie-apz/icon-visibility-on.svg" />
              </ng-container>
              <div *ngIf="contactForm.get('password').touched">
                <small class="bold text-danger" *ngIf="contactForm.get('password').errors && contactForm.get('password').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <div class="form-group col-md-6 position-relative">
              <label for="confirmPassword">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.CONFIRM_PWD' | translate }}</label>
              <input
                [type]="showConfirmPassword === false ? 'password' : 'text'"
                class="form-control"
                name="confirmPassword"
                id="confirmPassword"
                [formControlName]="'confirmPassword'"
                [ngClass]="{
                  'is-invalid': contactForm.get('confirmPassword').invalid === true && contactForm.get('confirmPassword').touched === true,
                  'is-valid': contactForm.get('confirmPassword').invalid === false && contactForm.get('confirmPassword').touched === true
                }"
                placeholder="{{ 'AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.PWD' | translate }}"
              />
              <ng-container *ngIf="!showConfirmPassword">
                <img
                  (click)="showConfirmPassword = !showConfirmPassword"
                  class="icon-eye"
                  src="/assets//affilie-apz/icon-visibility-off.svg"
                />
              </ng-container>
              <ng-container *ngIf="showConfirmPassword">
                <img
                  (click)="showConfirmPassword = !showConfirmPassword"
                  class="icon-eye"
                  src="/assets//affilie-apz/icon-visibility-on.svg"
                />
              </ng-container>
              <div *ngIf="contactForm.get('confirmPassword').touched">
                <small
                  class="bold text-danger"
                  *ngIf="contactForm.get('confirmPassword').errors && contactForm.get('confirmPassword').errors.required"
                >
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small
                  class="bold text-danger"
                  *ngIf="contactForm.get('confirmPassword').errors && contactForm.get('confirmPassword').errors.mustMatch"
                >
                  {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
                </small>
              </div>
            </div>
            <div class="password-validator p-3 col-md-12 mt-3">
              <span class="d-flex flex-row text-validator">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.PASSWORD.VALIDATION' | translate }}</span>
              <app-validate-password
                [passwordValue]="contactForm.get('password').value"
                [affLastPassword]="false"
                [progressBar]="true"
                [submitted]="submittedForm"
              ></app-validate-password>
            </div>
          </div>
          <div class="float-right">
            <span class="confidentialite"> {{ 'AFFILIE_APZ.MERCHANTS_INVITE.confidentialite' | translate }}</span>
            <a class="link ml-2" target="_blank" (click)="openSwileGDPRNotice()">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.swile' | translate }} </a>
            <span class="confidentialite">{{ 'AFFILIE_APZ.MERCHANTS_INVITE.ET' | translate }}</span>
            <a class="link ml-2" target="_blank" (click)="openBimpliGDPRNotice()"
              >{{ 'AFFILIE_APZ.MERCHANTS_INVITE.bimpli' | translate }}
            </a>
            <button type="button" class="btn btn-primary ml-2" [disabled]="contactForm.invalid || isLoading" (click)="submitForm()">
              <div>
                <div *ngIf="isLoading" class="spinner-border"></div>
                <span
                  [ngClass]="{
                    'ml-2': isLoading
                  }"
                  >{{ 'AFFILIE_APZ.MERCHANTS_INVITE.NEXT' | translate }}</span
                >
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
