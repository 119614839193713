import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalService } from '@shared/components/modal/modal.service';
import { validatePassword } from '@shared/components/password-validation/password.helper';
// import { Favicons } from '@shared/favicons';
import { WeezenComponent } from '../../../weezen/weezen.component';

@Component({
  selector: 'app-asp-change-pwd',
  templateUrl: './asp-change-pwd.component.html',
  styleUrls: ['../../../../../assets/css/asp-global.scss', './asp-change-pwd.component.scss'],
})
export class AspChangePwdComponent extends WeezenComponent implements OnInit, OnChanges {

  passwordEqual = false;
  doNotMatch = false;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() retour: EventEmitter<any[]> = new EventEmitter();
  @Input() errorMessage: string;
  @Input() passwordOk: boolean;

  passwordForm = this.fb.group({
    currentPassword: [undefined, validatePassword()],
    newPassword: [undefined, validatePassword()],
    confirmPassword: [undefined, validatePassword()],
  });

    constructor(private fb: FormBuilder,
      // fav: Favicons,
      private modalService: ModalService) {
        // super(fav);
        super();
    }

    submitForm() {
        if (this.passwordForm.valid && this.doNotMatch && this.passwordEqual) {
            this.submitted.emit(this.passwordForm.getRawValue());
        }
    }

    arePasswordsDifferent() {
        if (this.passwordForm.valid && this.passwordForm.get(['currentPassword']).value !== this.passwordForm.get(['newPassword']).value) {
            this.doNotMatch = true;
        } else {
            this.doNotMatch = null;
        }
    }

    isNewPasswordIsConfirmed() {
        this.passwordEqual = this.passwordForm.get('newPassword').value === this.passwordForm.get('confirmPassword').value;
        this.arePasswordsDifferent();
    }

    ngOnInit(): void {
    }

    back() {
        this.retour.emit();
    }

    cancelPopUp() {
        this.modalService.close('confirmModal');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.passwordOk !== undefined && changes.passwordOk.currentValue) {
            this.modalService.open('confirmModal');
        }
    }

}
