<div id="sign-in-container" class="container-fluid vw-100 vh-100 p-0">
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <img id="logo" class="mb-2" src='../../../../assets/bimpli/logo.svg' alt="Logo" />
    <div class="card shadow p-3 border-0">
      <form class="h-100 w-100" role="form" [formGroup]="signInForm" (submit)="submit()" (keydown.enter)="submit()">
        <div class="d-flex flex-column align-items-center justify-content-center h-100 w-100">
          <!-- ERROR MESSAGE -->
          <div *ngIf="(errorMessage$ | async) || isAlreadyActived" class="alert alert-danger error-msg">
            <span *ngIf="errorMessage$ | async">{{ errorMessage$ | async }}</span>
            <span *ngIf="isAlreadyActived"> {{ 'AFFILIE_APZ.LOGIN.ALREADY_ACTIVED' | translate }}</span>
          </div>
          <!-- Welcome -->
          <span id="title" class="d-flex flex-row align-items-center justify-content-start w-100 mb-2 font-weight-bold"
            >{{ 'AFFILIE_APZ.LOGIN.HELLO' | translate }}
          </span>
          <span id="sub-title" class="d-flex flex-row align-items-center justify-content-start w-100 mb-2">{{
            'AFFILIE_APZ.LOGIN.HELLO_DESCRIPTION' | translate
          }}</span>
          <div class="d-flex flex-column w-100 mt-3">
            <!-- Login -->
            <div class="d-flex flex-column">
              <label for="login" class="form-label">{{ 'AFFILIE_APZ.LOGIN.LOGIN' | translate }}</label>
              <input
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': signInForm.get('login').invalid === true && signInForm.get('login').touched === true,
                  'is-valid': signInForm.get('login').invalid === false && signInForm.get('login').touched === true
                }"
                name="login"
                id="login"
                formControlName="login"
                (input)="rememberMe()"
                placeholder="{{ 'AFFILIE_APZ.LOGIN.LOGIN_PROMPT' | translate }}"
              />
              <div *ngIf="signInForm.get('login').touched && signInForm.get('login').invalid">
                <small class="text-danger error-msg" *ngIf="signInForm.get('login').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="text-danger  error-msg" *ngIf="signInForm.get('login').errors.pattern">
                  {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
                </small>
              </div>
            </div>

            <div class="d-flex flex-column w-100 mt-3">
              <!-- Password -->
              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-items-center justify-content-between">
                  <label for="password" class="form-label">{{ 'AFFILIE_APZ.LOGIN.PASSWORD' | translate }}</label>
                  <a class="signin-link forgot-password" (click)="requestResetPassword()">{{ 'AFFILIE_APZ.LOGIN.FORGOT_PASSWORD' | translate }}</a>
                </div>
                <div class="input-group">
                  <input
                    [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': signInForm.get('password').invalid === true && signInForm.get('password').touched === true,
                      'is-valid': signInForm.get('password').invalid === false && signInForm.get('password').touched === true
                    }"
                    name="password"
                    id="password"
                    formControlName="password"
                    placeholder="{{ 'AFFILIE_APZ.LOGIN.PASSWORD_PROMPT' | translate }}"
                  />
                  <div class="input-group-append">
                    <button
                      *ngIf="(isPasswordDisplayed$ | async) === false"
                      type="button"
                      class="btn btn-light input-group-text"
                      (click)="isPasswordDisplayed$.next(true)"
                    >
                      <i class="bi bi bi-eye-fill"></i>
                    </button>
                    <button
                      *ngIf="(isPasswordDisplayed$ | async) === true"
                      type="button"
                      class="btn btn-light input-group-text"
                      (click)="isPasswordDisplayed$.next(false)"
                    >
                      <i class="bi bi-eye-slash-fill"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="signInForm.get('password').touched && signInForm.get('password').invalid">
                <small class="text-danger  error-msg" *ngIf="signInForm.get('password').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <!-- Remember -->
            <div class="pt-2 d-flex flex-row align-items-center justify-content-start">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="remember-me"
                  [(ngModel)]="isRememberMe"
                  (change)="rememberMe()"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-label mb-0" for="remember-me">{{ 'AFFILIE_APZ.LOGIN.REMEMBER' | translate }}</label>
              </div>
            </div>
            <!-- Submit -->
            <div class="pt-4 pb-4 d-flex flex-row align-items-center justify-content-center separator">
              <button type="button" class="btn btn-primary btn-block h50" [disabled]="!signInForm.valid || isLoading" (click)="submit()">
                <div class="d-flex flex-row align-items-center justify-content-center">
                  <div *ngIf="isLoading" class="spinner-border"></div>
                  <span
                    [ngClass]="{
                      'ml-2': isLoading
                    }"
                    id="submit-btn-label"
                    >{{ 'AFFILIE_APZ.LOGIN.CONNECTION' | translate }}</span
                  >
                </div>
              </button>
            </div>
          </div>

          <!-- New affiliate -->
          <div id="new-affiliate-container" class="d-flex flex-row justify-content-center align-items-center pt-4">
            <span id="new-affiliate-label"> {{ 'AFFILIE_APZ.LOGIN.FIRST_CONNEXION' | translate }}</span>
            <a class="signin-link  ml-2" (click)="firstConnexion()"> {{ 'AFFILIE_APZ.LOGIN.SIGN_IN' | translate }}</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
