import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CesuService } from '@services/cesu/cesu.service';
import { LoginService } from '@services/login/login.service';
import { RegistrationService } from '@services/registration/registration.service';
import { CesuStateService } from '@services/store/cesu/cesu-state.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { LoginHelper } from '@shared/helpers/login.helper';
import { Observable, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { CesuRegistrationStatusEnum } from 'src/app/shared/enums/cesu.enum';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { CesuQuery } from 'src/app/shared/services/store/cesu/cesu.query';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cesu-second-step',
  templateUrl: './cesu-second-step.component.html',
  styleUrls: ['./cesu-second-step.component.scss', '../../../../../assets/scss/cesu/cesu-global.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CesuSecondStepComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public activeStepIcon;
  public notActiveStepIcon;

  public registrationStatus$: Observable<CesuRegistrationStatusEnum>;
  public saml: string;
  public registrationStatus: CesuRegistrationStatusEnum;

  public registrationForm: FormGroup;
  public submitted = false;
  public errorMessage: string;

  public CesuRegistrationStatusEnum = CesuRegistrationStatusEnum;

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly registrationService: RegistrationService,
    private readonly tealiumService: TealiumService,
    private readonly cesuService: CesuService,
    private readonly cesuQuery: CesuQuery,
    private readonly sessionQuery: SessionQuery,
    private readonly cesuStateService: CesuStateService,
    private readonly sessionStateService: SessionStateService,
    private readonly translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.activeStepIcon = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cesu/active_step_icon.svg');
    this.notActiveStepIcon = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cesu/not_active_step_icon.svg');

    this.registrationStatus$ = this.cesuQuery.selectedCesuRegistrationStatus;

    const registrationStatusSubscription = this.cesuQuery.selectedCesuRegistrationStatus
      .pipe(
        distinctUntilChanged(),
        filter((registrationStatus: CesuRegistrationStatusEnum) => registrationStatus && true),
        tap((registrationStatus) => {
          this.registrationStatus = registrationStatus;

          switch (registrationStatus) {
            case CesuRegistrationStatusEnum.CREATE_ACCOUNT:
              this.registrationForm = this.formBuilder.group(
                {
                  login: [undefined, [Validators.required, Validators.email]],
                  password: [undefined, [validatePassword()]],
                  passwordConfirmation: [undefined, [Validators.required]],
                  cgu: [false, [Validators.requiredTrue]]
                },
                { validator: [LoginHelper.checkPasswords] }
              );

              this.tealiumService.view('cesu.registrationProcess.chooseLoginPageLoad');
              break;
            case CesuRegistrationStatusEnum.APETIZ:
            case CesuRegistrationStatusEnum.CESU_GRD_COMPTE:
              this.registrationForm = this.formBuilder.group({
                login: [undefined, [Validators.required]],
                password: [undefined, [Validators.required]]
              });

              if (registrationStatus === CesuRegistrationStatusEnum.APETIZ) {
                this.tealiumService.view('cesu.registrationProcess.apetizLoginPageLoad');
              } else if (registrationStatus === CesuRegistrationStatusEnum.CESU_GRD_COMPTE) {
                this.tealiumService.view('cesu.registrationProcess.cesuOrderLoginPageLoad');
              }

              break;
          }
        })
      )
      .subscribe();

    this.subscription.add(registrationStatusSubscription);

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);
  }

  public get form() {
    return this.registrationForm.controls;
  }

  public submitRegistration(): void {
    this.submitted = true;
    this.tealiumService.link('cesu.registrationProcess.validateChooseLoginClick');

    if (this.registrationForm.valid) {
      // Check login/password/passwordConfirmation
      // Go to user information update page
      this.registrationService
        .checkLogin(this.registrationForm.value.login, '')
        .pipe(
          tap((checkLogin: boolean) => {
            if (checkLogin) {
              this.sessionStateService.setRegistrationStep(RegistrationStepEnum.THIRD_STEP);
              this.cesuStateService.setCesuRegistrationLogin(this.registrationForm.value.login);
              this.cesuStateService.setCesuRegistrationPassword(this.registrationForm.value.password);
              return this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}`]);
            } else {
              this.errorMessage = this.translateService.instant('CREATION_ACCOUNT.SECOND_STEP.ERROR.ALREADY_EXIST');
            }
          }),
          catchError((err) => {
            this.errorMessage = 'Login already used';
            throw err;
          })
        )
        .subscribe();
    }
  }

  public login(): void {
    // Log in to the CESU app
    this.submitted = true;

    if (this.registrationStatus === CesuRegistrationStatusEnum.APETIZ) {
      this.tealiumService.link('cesu.registrationProcess.apetizLoginClick');
    } else if (this.registrationStatus === CesuRegistrationStatusEnum.CESU_GRD_COMPTE) {
      this.tealiumService.link('cesu.registrationProcess.cesuOrderLoginClick');
    }

    if (this.registrationForm.valid) {
      this.loginService
        .login(this.registrationForm.value.login, this.registrationForm.value.password, this.saml)
        .pipe(
          catchError((err: Error) => {
            this.errorMessage = err.message;
            throw err;
          })
        )
        .subscribe();
    }
  }

  public goToLogin(): void {
    this.cesuService.goToLogin();
  }

  goToForgottenPwd() {
    if (this.registrationStatus === CesuRegistrationStatusEnum.APETIZ) {
      this.tealiumService.link('cesu.registrationProcess.apetizLoginForgottenPasswordClick');
    } else if (this.registrationStatus === CesuRegistrationStatusEnum.CESU_GRD_COMPTE) {
      this.tealiumService.link('cesu.registrationProcess.cesuOrderLoginForgottenPasswordClick');
    }

    return this.router.navigate([`${RoutesEnum.FORGOTTEN_PASSWORD}/${this.saml}`]);
  }

  goToForgottenLogin() {
    if (this.registrationStatus === CesuRegistrationStatusEnum.APETIZ) {
      this.tealiumService.link('cesu.registrationProcess.apetizLoginForgottenLoginClick');
    } else if (this.registrationStatus === CesuRegistrationStatusEnum.CESU_GRD_COMPTE) {
      this.tealiumService.link('cesu.registrationProcess.cesuOrderLoginForgottenLoginClick');
    }

    return this.router.navigate([`${RoutesEnum.FORGOTTEN_LOGIN}/${this.saml}`]);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
