import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { EmailHelper } from '@shared/helpers/email.helper';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeLast, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit, OnDestroy, OnChanges {
  @Input() email?: string;
  @Input() saml: string;
  @Input() messageErreur: string;
  @Input() success: boolean;
  @Input() parentForm: FormGroup;
  @Output() accountValidated: EventEmitter<boolean> = new EventEmitter();

  public passwordForm: FormGroup;
  public passwordEqual = false;
  public loginEqual = false;
  public submittedForm = false;
  public isLoading = false;

  public isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly destroy$ = new Subject<boolean>();

  constructor(private readonly fb: FormBuilder, private gtds: GroundTruthDataService) {
    this.passwordForm = this.fb.group({
      password: ['', validatePassword()],
      confirmPassword: ['', Validators.required],
      login: ['', [Validators.required, Validators.email]],
      confirmLogin: ['', [Validators.required, Validators.email]],
      cgu: [false, Validators.requiredTrue]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.email?.currentValue) {
      if (this.passwordForm.get('login')) {
        this.passwordForm.get('login').setValue(this.email);
        this.passwordForm.get('login').markAllAsTouched();
        this.passwordForm.get('login').disable();
      }

      if (this.passwordForm.get('confirmLogin')) {
        this.passwordForm.get('confirmLogin').setValue(this.email);
        this.passwordForm.get('confirmLogin').markAllAsTouched();
        this.passwordForm.get('confirmLogin').disable();
      }
      this.controlLogin();
    }
  }

  ngOnInit(): void {
    this.gtds
      .getIsSpinnerActivated()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    if (this.parentForm) {
      this.parentForm.addControl('account', this.passwordForm);
      this.parentForm.updateValueAndValidity();
    }
  }

  public controlPassWord(): void {
    this.passwordEqual = this.passwordForm.get('password').value === this.passwordForm.get('confirmPassword').value;
  }

  public controlLogin(): void {
    this.loginEqual = this.passwordForm.get('login').value === this.passwordForm.get('confirmLogin').value;
  }

  public submitForm(): void {
    if (this.passwordForm.valid && this.passwordEqual && this.loginEqual) {
      this.submittedForm = true;
      this.accountValidated.emit(true);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
