import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GatewayService } from '@services/gateway.service';
import { MerchantSignupRightPanel } from '@shared/enums/merchants-signup-right-panel.enum';
import { CheckUserAffiliateAccessRequest } from '@shared/models/check-user-affiliate-access-request';
import { CheckUserAffiliateAccessResponse } from '@shared/models/check-user-affiliate-access-response';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CheckAffiliateAccessService } from '../check-affiliate-access-service';

@Component({
  selector: 'app-unlock-by-collection',
  templateUrl: './unlock-by-collection.component.html',
  styleUrls: ['./unlock-by-collection.component.scss']
})
export class UnlockByCollectionComponent implements OnInit, OnDestroy {

  @Output() goToPanel$ = new EventEmitter<string>();
  @Output() canAccess$ = new EventEmitter<void>();

  private readonly destroy$ = new Subject<boolean>();
  public readonly title = 'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.COLLECTION.TITLE';
  public readonly text = 'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.COLLECTION.TEXT';
  public readonly linkText = 'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.COLLECTION.LINK';
  public formGroup: FormGroup;
  public isBadCollection = false;


  constructor(
    private readonly fb: FormBuilder,
    private readonly checkAffiliateAccessService: CheckAffiliateAccessService,
    private readonly gatewayService: GatewayService
    ) {

  }
  ngOnInit(): void {
      this.initForm();
  }

  public onSubmit(): void {

    const checkUserAffiliateAccessRequest = {} as CheckUserAffiliateAccessRequest;
    checkUserAffiliateAccessRequest.collectionNumber = this.formGroup.get('collection')?.value;
    this.gatewayService
    .getAccessToken()
    .pipe(
      takeUntil(this.destroy$),
      switchMap((token) => {
        return this.checkAffiliateAccessService.checkAffiliateAccess(checkUserAffiliateAccessRequest, token)
        .pipe(
        takeUntil(this.destroy$),
        switchMap((res: CheckUserAffiliateAccessResponse) => {
        this.checkAccess(res?.canAccess);
        return of();
        }),
      );
      })
    ).subscribe();
  }

  public showDetails(): void {
    this.goToPanel$.emit(MerchantSignupRightPanel.COLLECTION_INFOS);
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      collection: [
        { value: '', disabled: false },
        [
          Validators.pattern(/^[a-z0-9]+$/i),
          Validators.maxLength(50),
          Validators.required
        ]
      ]
    })
  }

  private checkAccess(canAccess: boolean): void {
    if(canAccess) {
      this.canAccess$.emit();
    }
    else {
      this.isBadCollection = true;
    }
  }

 ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.unsubscribe();
 }
}
