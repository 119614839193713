<ng-container
    *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ || (applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE"
>
    <app-apz-confirmation-password
        (submitConfirmation)="validerInscriptionAsp($event.password)"
        [waiting]="waiting"
        [error]="error"
        [success]="success"
        [lienInvalide]="lienInvalide"
        (goLogin)="loginAPZ()"
        (renvoyerDemande)="renvoyer()"
        [messageErreur]="messageErreur"
    >
    </app-apz-confirmation-password>
</ng-container>
<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE ||
    (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR">
  <app-bimpli-confirmation-pwd
    (submitConfirmation)="validerInscriptionAsp($event.password)"
    [waiting]="waiting"
    [error]="error"
    [success]="success"
    [lienInvalide]="lienInvalide"
    (goLogin)="loginBenefits()"
    (renvoyerDemande)="renvoyer()"
    [messageErreur]="messageErreur">
  </app-bimpli-confirmation-pwd>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN">
  <app-asp-confirmation-pwd
    (submitConfirmation)="validerInscriptionAsp($event.password)"
    [resendOk]="resendOk"
    [waiting]="waiting"
    [error]="error"
    [success]="success"
    [lienInvalide]="lienInvalide"
    [estReinit]="estReinit"
    (loginWee)="loginWee()"
    (renvoyerDemande)="renvoyer()"
  >
  </app-asp-confirmation-pwd>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
  <app-cesu-confirmation-pwd [waiting]="waiting" [invalidLink]="lienInvalide" (reSendForgottenPassword)="renvoyer()">
  </app-cesu-confirmation-pwd>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.AFFILIE_APZ">
    <app-affilie-apz-confirmation-pwd
        (submitConfirmation)="validerInscriptionAsp($event.password)"
        [success]="success"
        [lienInvalide]="lienInvalide"
        (goLogin)="loginAffilieApz()"
        (renvoyerDemande)="renvoyer()"
        [errorMessage]="messageErreur"
        [resendOk]="resendOk"
    >
    </app-affilie-apz-confirmation-pwd>
</ng-container>
