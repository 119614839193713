import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDatepickerI18n,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n } from '@services/datepicker/datepicker.service';
import { RegistrationService } from '@services/registration/registration.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { CesuStateService } from 'src/app/shared/services/store/cesu/cesu-state.service';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';

@Component({
  selector: 'app-cesu-first-step',
  templateUrl: './cesu-first-step.component.html',
  styleUrls: ['./cesu-first-step.component.scss', '../../../../../assets/scss/cesu/cesu-global.scss'],
  providers: [NgbDatepickerConfig, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
})
export class CesuFirstStepComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public errorIcon;
  public tipsIcon;
  public activeStepIcon;
  public notActiveStepIcon;

  public saml: string;

  public cesuForm: FormGroup;
  public submitted = false;
  public errorMessage: string;
  public dateOfBirth: string;

  constructor(
    private readonly formatter: NgbDateParserFormatter,
    private readonly formBuilder: FormBuilder,
    private readonly domSanitizer: DomSanitizer,
    private readonly router: Router,
    private readonly config: NgbDatepickerConfig,
    private readonly tealiumService: TealiumService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly cesuStateService: CesuStateService,
    private readonly registrationService: RegistrationService,
  ) {
    config.minDate = { year: 1900, month: 1, day: 1 };
    config.maxDate = { year: new Date().getFullYear() + 1, month: 12, day: 31 };
  }

  ngOnInit() {
    this.cesuForm = this.formBuilder.group({
      cesu: [undefined, Validators.required],
      dob: ['', Validators.required],
    });

    const dateOfBirthSubscription = this.cesuForm.get('dob').valueChanges.pipe(
      tap((value: NgbDateStruct) => {

        const year: string = value.year.toString();
        let month: string = value.month.toString();
        let day: string = value.day.toString();

        if (value.month <= 9) {
          month = '0' + month;
        }

        if (value.day <= 9) {
          day = '0' + day;
        }

        this.dateOfBirth = day + '-' + month + '-' + year;
      }),
    ).subscribe();

    this.subscription.add(dateOfBirthSubscription);

    this.errorIcon = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cesu/error_icon.svg');
    this.tipsIcon = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cesu/tips_icon.svg');
    this.activeStepIcon = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cesu/active_step_icon.svg');
    this.notActiveStepIcon = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cesu/not_active_step_icon.svg');

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);

    this.tealiumService.view('cesu.registrationProcess.registrationProcessPageLoad');
  }

  public get form() {
    return this.cesuForm.controls;
  }

  public authentication(): void {
    this.submitted = true;
    this.tealiumService.link('cesu.registrationProcess.validateRegistrationClick');

    if (this.cesuForm.valid) {
      this.registrationService.registrationPersonalNumber(
        this.cesuForm.value.cesu,
        new Date(this.formatter.format(this.cesuForm.value.dob)),
        'CES',
      )
        .pipe(
          tap(() => {
            this.sessionStateService.setRegistrationStep(RegistrationStepEnum.SECOND_STEP);
            this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/${this.saml}`]);
          }),
          catchError((err) => {
            this.errorMessage = err.message;
            throw err;
          }),
        )
        .subscribe();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
