<div class="cesu-container" [ngClass]="{'cesu-container-no-footer' : (showFooter$ | async) === false}">
    <div class="container">
        <div class="row pt-2 pt-md-4 pt-lg-5">
            <div class="col text-center">
                <a (click)="goToLogin()">
                    <img class="cesu-img" src="assets/cesu/LOGO_BIMPLI_CESU.svg" alt="Cesu Domalin">
                </a>
            </div>
        </div>

        <ng-content></ng-content>
    </div>
</div>
<ng-container *ngIf="(showFooter$ | async) === true">
    <app-cesu-footer></app-cesu-footer>
</ng-container>
