<div id="rgpd-content">
    <div class="title">
        {{ 'RGPD.TITLE' | translate }}
    </div>

    <div class="content">
        {{ 'RGPD.TEXT' | translate }}
    </div>

    <app-full-width-btn
    [text]="'RGPD.BTN'"
    (click$)="gotIt()"
    >

    </app-full-width-btn>
</div>