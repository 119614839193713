import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
// import { Favicons } from '../../../shared/favicons';
import { WeezenComponent } from '../../weezen/weezen.component';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { ModalService } from '../../../shared/components/modal/modal.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { GroundTruthDataService } from '../../../shared/services/ground-truth-data.service';

@Component({
  selector: 'app-asp-forgotten-pwd',
  templateUrl: './asp-forgotten-pwd.component.html',
  styleUrls: ['../../../../assets/css/asp-global.scss', './asp-forgotten-pwd.component.scss']
})
export class AspForgottenPwdComponent extends WeezenComponent implements OnInit, OnChanges, OnDestroy {

  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() recalled: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelled: EventEmitter<any> = new EventEmitter<any>();
  @Input() errorMessage: string;
  @Input() notificationEnvoyer: boolean;

  forgottenPwdForm = this.fb.group({
    identifiant: [undefined, Validators.compose([Validators.required])],
  });

  isLoading = false;

  mySubscription = new Subscription();

  constructor(private fb: FormBuilder,
    // fav: Favicons,
     private modalService: ModalService, private gtds: GroundTruthDataService) {
    // super(fav);
    super();
  }

  ngOnInit() {
    const isSpinnerActivated = this.gtds.getIsSpinnerActivated().pipe(
      distinctUntilChanged()
    ).subscribe(response => { this.isLoading = response; });

    this.mySubscription.add(isSpinnerActivated);
  }

  submit() {
    if (this.forgottenPwdForm.valid) {
      this.gtds.setIsSpinnerActivated(true);
      this.submitted.emit(this.forgottenPwdForm.get('identifiant').value);
    }
  }

  cancel() {
    this.cancelled.emit();
  }

  recall() {
    this.recalled.emit(this.forgottenPwdForm.get('identifiant').value);
  }

  cancelPopUp() {
    this.modalService.close('confirmModal');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.notificationEnvoyer !== undefined && changes.notificationEnvoyer.currentValue) {
      this.modalService.open('confirmModal');
    }
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }

}
