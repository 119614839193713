import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { sha256 } from 'js-sha256';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthentificationService {


    public constructor(private http: HttpClient) { }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            alert(`An error occurred: ${error.error.message}`);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            alert(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

    public connecter(login: string, password: string, samlRequest: string): Observable<string> {
        const url = environment.siloECUEndPoint + '/authentification-web/rest/authentification/API/compte/'
            + login + '/' + sha256(password) + '/' + samlRequest + '/connecter-api';
        const httpOptions = {
            headers: new HttpHeaders()
        };
        return this.http.post(url, httpOptions, { responseType: 'text' });
    }

    public rerouter(miniToken: string): Observable<string> {
        const url = environment.siloECUEndPoint + '/mail-web/rest/routing/get-url-from-minified-token';
        let params = new HttpParams();
        params = params.set('minifiedToken', miniToken);
        return this.http.get(url, { params, responseType: 'text' });
    }
}
