import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { confirmationPasswordIsEqualValidator } from '@shared/validators/custom.validators';

@Component({
  selector: 'app-bimpli-confirmation-pwd',
  templateUrl: './bimpli-confirmation-pwd.component.html',
  styleUrls: ['./bimpli-confirmation-pwd.component.scss']
})
export class BimpliConfirmationPwdComponent implements OnInit, OnDestroy, DoCheck
{
@Output() submitConfirmation: EventEmitter<any[]> = new EventEmitter();
@Output() goLogin: EventEmitter<any> = new EventEmitter();
@Output() renvoyerDemande: EventEmitter<any> = new EventEmitter();

@Input() success: boolean;
@Input() error: boolean;
@Input() messageErreur: string;
@Input() waiting: boolean;
@Input() lienInvalide: boolean;

  passwordFormControl = this.fb.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(60)]);

  passwordForm = this.fb.group({
    password: this.passwordFormControl,
    confirmPassword: [{value: '', disabled: true},
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(60),
        confirmationPasswordIsEqualValidator(this.passwordFormControl)
      ]]
  });

  mySubscription = new Subscription();
  isLoading: boolean;
  hide = true;
  hide2 = true;

  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private fb: FormBuilder, private gtds: GroundTruthDataService) {}

  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

  this.mySubscription.add(isSpinnerActivated);
}

  submit() {
    if (this.passwordForm.valid && this.isCorrectPwd(this.passwordForm.get('password').value)) {
      this.gtds.setIsSpinnerActivated(true);
      this.submitConfirmation.emit(this.passwordForm.getRawValue());
    }
  }

  recall() {
    this.gtds.setIsSpinnerActivated(true);
    this.renvoyerDemande.emit();
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }

  connect() {
    this.goLogin.emit();
  }

  isLessThanEightChars(p: string): boolean {
    return p.length < 8;
  }

  isMoreThanSixtyChars(p: string): boolean {
    return p.length > 60;
  }

  isCorrectPwd(p: string): boolean {
    return !this.isLessThanEightChars(p) && !this.isMoreThanSixtyChars(p) && (
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneSpecialChar(p))
    );
  }

  hasAtLeastOneLowerCase(p: string): boolean {
    return p.toUpperCase() !== p;
  }

  hasAtLeastOneUpperCase(p: string): boolean {
    return p.toLowerCase() !== p;
  }

  hasAtLeastOneDigit(p: string): boolean {
    return /\d/.test(p);
  }

  hasAtLeastOneSpecialChar(p: string): boolean {
    const pwdRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return pwdRegex.test(p);
  }

  ngDoCheck(): void {
    if (this.passwordForm.get('password').dirty) {
      this.passwordForm.get('confirmPassword').enable();
    }
  }
}
