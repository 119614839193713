<div class="container-fluid vw-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
        <div class="d-flex flex-column">
            <span class="title-bp">Youpi !</span>
            <span class="sub-title-bp">Vous avez sélectionné <br>Cado !</span>
        </div>
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4" *ngIf="step === 1">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Identité personnelle</span>
            <span class="create-subtitle mt-4 mb-4">Sélectionnez votre civilité et saisissez votre prénom et nom.</span>

            <form [formGroup]="individualInfosForm" (keydown.enter)="goToStep2(2)">
                <div class="inner_page">
                    <div class="mb-4">
                        <label for="civilite">{{'USUAL.GENDER' | translate}} *</label>
                        <div class="d-flex">
                            <div class="full-radio radio-button">
                                <input id="male" value="MR" type="radio" name="title"
                                       formControlName="title">
                                <label for="male">{{'USUAL.GENDER_VALUES.MR' | translate}}</label>
                            </div>
                            <div class="full-radio radio-button">
                                <input id="female" value="MS" type="radio" name="title"
                                       formControlName="title">
                                <label for="female">{{'USUAL.GENDER_VALUES.MS' | translate}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="prenom">{{'USUAL.FIRSTNAME' | translate}} *</label>
                        <input type="text" name="prenom"
                               placeholder="{{'USUAL.PH_FIRSTNAME' | translate}}"
                               id="prenom" maxlength="30" class="form-control"
                               formControlName="firstName" (keypress)="formatName($event)">
                        <div *ngIf="(individualInfosForm.get('firstName').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('firstName').errors">Prénom requis</small>
                        </div>
                    </div>
                    <div class="input_group">
                        <label for="nom">{{'USUAL.LASTNAME' | translate}} *</label>
                        <input type="text" name="nom"
                               placeholder="{{'USUAL.PH_LASTNAME' | translate}}"
                               id="nom" maxlength="30" class="form-control"
                               formControlName="lastName" (keypress)="formatName($event)">
                        <div *ngIf="(individualInfosForm.get('lastName').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="individualInfosForm.get('lastName').errors">Nom requis</small>
                        </div>
                    </div>
                </div>
                <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!individualInfosForm.valid" (click)="goToStep2(2)">
                    <span>Continuer</span>
                </button>
            </form>
        </div>
    </div>

    <div class="d-block col-xs-12 col-sm-8 offset-sm-4" *ngIf="step === 2">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Compléter votre profil</span>
            <span class="create-subtitle mt-4 mb-4">Pour continuer, merci de compléter votre profilavec les informations demandées ci-dessous.</span>

            <form [formGroup]="profilForm" (keydown.enter)="createAccount()">
                <div class="inner_page">
                    <div class="form-group">
                        <label for="birthdate" class="birthday-label">{{'USUAL.BIRTHDATE' | translate}}
                            *</label>
                        <div class="input-group">
                            <input placeholder="Saisie de la date dd/mm/aaaa" class="form-control"
                                   formControlName="birthdate"
                                   name="birthdate" ngbDatepicker #birthdate="ngbDatepicker"
                                   [startDate]="scheduledStartDate" [minDate]="{year: 1900, month:1, day: 1}">
                            <div class="input-group-append">
                                <button class="bi bi-calendar btn btn-outline-secondary calendar"
                                        (click)="birthdate.toggle()" type="button"></button>
                            </div>
                        </div>
                        <div *ngIf="(profilForm.get('birthdate').touched)">
                            <small class="form-text text-danger"
                                   *ngIf="profilForm.get('birthdate').errors">Date de naissance incorrect</small>
                        </div>
                    </div>

                    <div class="form-group phone-bloc">
                        <label class="d-inline-block" for="phone">Numéro de téléphone portable *</label>
                        <input type="tel" name="phone"
                               placeholder="{{'USUAL.PH_PHONE' | translate}}"
                               id="phone" class="form-control"
                               formControlName="cellPhoneNumber" maxlength="10" (keypress)="numericPhoneOnly($event)">
                        <div *ngIf="(profilForm.get('cellPhoneNumber').touched && profilForm.get('cellPhoneNumber').errors)">
                            <small class="form-text text-danger"
                                   *ngIf="profilForm.get('cellPhoneNumber').errors.required">Numéro de téléphone requis</small>
                            <small class="form-text text-danger"
                                   *ngIf="profilForm.get('cellPhoneNumber').errors.pattern">Le format du téléphone doit être 0(6 ou 7) XX XX XX XX</small>
                        </div>
                    </div>
                    <div class="error-message p-4 mb-4">
                        <span class="message-info">
                            * Votre numéro de mobile est obligatoire pour sécuriser votre compte.<br><br>
                            Il servira notamment à valider vos paiements pour les enseignes disponibles en bons d’achat.<br>
                            Ce numéro ne sera pas utilisé à des fins publicitaires ou commerciales.
                        </span>
                    </div>
                </div>
                <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!profilForm.valid" (click)="createAccount()">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div *ngIf="isLoading" class="spinner-border"></div>
                        <span [ngClass]="{'ml-2': isLoading }">Continuer</span>
                    </div>
                </button>
            </form>
        </div>
    </div>

    <div class="d-block col-xs-12 col-sm-8 offset-sm-4" *ngIf="step === 3">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Code CADO carte</span>
            <span class="create-subtitle mt-4 mb-4">Pour relier votre compte CADO à votre nouveau compte Bimpli, nous avons besoin de vous authentifier via votre carte CADO.</span>

            <form [formGroup]="activationForm" (ngSubmit)="createAccount()" (keydown.enter)="createAccount()">
                <div class="input_group" [formGroup]="activationForm.get('card')">
                    <label for="idCode"> Code Cado Carte * </label>
                    <input
                        #idCode
                        type="text"
                        id="idCode"
                        formControlName="id"
                        class="form-control"
                        mask="000 0000 0000 0000 0000" [showMaskTyped]="true" placeHolderCharacter="x"
                    />
                    <div
                        class="error-container"
                        *ngIf="(activationForm.get('card').get('id').dirty || activationForm.get('card').get('id').touched) && activationForm.get('card').get('id').errors"
                    >
                        <small class="form-text text-danger" *ngIf="!activationForm.get('card').get('id').errors.pattern && activationForm.get('card').get('id').errors.tooShort">
                            {{ "ACTIVATION.ID_CODE.ERROR.TOO_SHORT" | translate: {value: activationForm.get('card').get("id").errors?.tooShort.value} }}
                        </small>
                        <small class="form-text text-danger" *ngIf="!activationForm.get('card').get('id').errors.pattern && activationForm.get('card').get('id').errors.tooLong">
                            {{ "ACTIVATION.ID_CODE.ERROR.TOO_LONG" | translate: {value: activationForm.get('card').get("id").errors?.tooLong} }}
                        </small>
                    </div>

                    <div class="error-message mt-4 mb-4 p-4">
                        <div class="row col-12 pb-2"><span class="cgu title-error-mess">Où trouver ce code ?</span></div>
                        <div class="row">
                            <span class="cgu col-6 col-sm-8">Le code se trouve au dos de votre carte. Veuillez saisir le code à 19 chiffres inscrit sous le code barre.</span>
                            <img class="card-sample col-6 col-sm-4" src="/assets/bimpli/card-sample.png"/>
                        </div>
                    </div>
                </div>
                <div class="input_group" [formGroup]="activationForm.get('card')" *ngIf="affCodeActivation">
                    <div class="d-flex justify-content-between">
                        <label for="activationCode">
                            Code d'activation
                        </label>
                        <span class="link" (click)="openPopUp('popUpCodeActivation')">Code d'activation ?</span>
                    </div>
                    <input type="number"  placeholder="Saisir votre code d'activation" class="form-control" id="activationCode"
                           formControlName="activationCode" (keypress)="numericPhoneOnly($event)"/>
                </div>
                <div>
                    <div class="error-message mt-4 mb-4 p-4">
                        <div class="row m-0 p-0 col-12 pb-2"><span class="cgu title-error-mess">Actualités & offres de CADO Store</span></div>
                        <div class="form-check row align-items-center justify-content-center" >
                            <input class="mr-2 ml-2 form-check-input" type="checkbox" formControlName="smsOptin" id="smsOptin"/>
                            <label class="col cgu" for="smsOptin"> Je souhaite recevoir par SMS des offres commerciales et événements des produits CADO Carte.</label>
                        </div>
                        <div class="form-check row align-items-center justify-content-center">
                            <input class="mr-2 ml-2 form-check-input" type="checkbox" formControlName="mailOptin" id="mailOptin"/>
                            <label class="col cgu" for="mailOptin"> Je souhaite recevoir des offres des enseignes partenaires et être informé des actualités de Titre CADO.</label>
                        </div>
                    </div>
                </div>

                <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!activationForm.valid" (click)="createAccount()">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div *ngIf="isLoading" class="spinner-border"></div>
                        <span [ngClass]="{'ml-2': isLoading }">Continuer</span>
                    </div>
                </button>
            </form>
        </div>
    </div>
</div>

<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>

<app-modal id="popUpCodeActivation">
    <div class="d-flex flex-column p-4">
        <span class="title-popup text-center">Où trouver le code d'activation ?</span>
        <span class="subtitle-popup mt-2">Le code d’activation vous a été communiqué de vive voix par votre financeur ou par email.</span>
        <span class="subtitle-popup mt-2">1. Pensez à vérifier vos spam si vous pensez l'avoir reçu par email.</span>
        <span class="subtitle-popup mt-2">2. Toujours pas de code ? Veuillez contacter votre financeur Cado.</span>

        <div class="mt-4 mb-4">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('popUpCodeActivation')">FERMER</button>
        </div>
    </div>
</app-modal>
