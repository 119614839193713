import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { AccountActivation } from '@shared/models/account-activation.model';
import { AuthToken } from '@shared/models/authtoken.model';
import { Coordonnee } from '@shared/models/coordonnee';
import { SignupAffiliateManager } from '@shared/models/signup-affiliate-manager';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {
  EmployeeContact,
  TypeContactEnum,
  UpdateUsersIdentityAndContacts,
  CiviliteEnum,
  UserActivationCreate,
  UserActivationResponse
} from 'nit-angular-lib';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap, finalize } from 'rxjs/operators';

const EMAIL = 'email';
const COORDONNEE = 'coordonnee';

@Component({
  selector: 'app-second-step-signup-affiliate-manager',
  templateUrl: './second-step-signup-affiliate-manager.component.html',
  styleUrls: ['./second-step-signup-affiliate-manager.component.scss']
})
export class SecondStepSignupAffiliateManagerComponent implements OnInit {
  public email: string;
  public success: boolean;
  public messageErreur: string;
  public accountForm: FormGroup;
  public token: AuthToken;
  private valueCaptcha: string;

  private signupAffiliateManager: SignupAffiliateManager;
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly sessionStateService: SessionStateService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sessionQuery: SessionQuery,
    private readonly gtds: GroundTruthDataService,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
    this.accountForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.success = true;
    this.email = JSON.parse(localStorage.getItem(EMAIL));
    this.sessionStateService.setApplicationCodeFromAffiliateManagerSignup(this.route);
    this.sessionQuery.selectedSignupAffiliateManager
      .pipe(
        takeUntil(this.destroy$),
        tap((signupAffiliateManager: SignupAffiliateManager) => (this.signupAffiliateManager = signupAffiliateManager))
      )
      .subscribe();
  }

  public goBack(): void {
    this.router.navigateByUrl(
      `${RoutesEnum.SIGNUP_AFFILIATE_MANAGER_FIRST_STEP}/${this.signupAffiliateManager?.saml}?hash=${this.signupAffiliateManager?.hash}`
    );
  }

  public createAccount(): void {
    this.gtds.setIsSpinnerActivated(true);
    const accountData = this.accountForm.getRawValue()?.account;

/*     this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
      .execute('ConnecterAction')
      .pipe(finalize(() => {

      }))
      .subscribe((token) => (this.valueCaptcha = token));
    },
      err => {
        this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
      });
    }; */

    this.gatewayService
      .getAccessToken()
      .pipe(
        tap((authtoken: AuthToken) => {
          this.token = authtoken;
        }),
        switchMap(() => this.updateUsersIdentityAndContacts()),
        switchMap(() => this.reCaptchaV3Service.execute('ConnecterAction')),
        switchMap((valueCaptcha: string) => this.reinitialisationMotDePasse(accountData?.password, valueCaptcha)),
        switchMap(() => this.activateUser())
      )
      .subscribe(() => {
        this.gtds.setIsSpinnerActivated(false);
        this.nextStep();
      });
  }

  private updateUsersIdentityAndContacts(): Observable<UpdateUsersIdentityAndContacts> {
    const contacts: EmployeeContact[] = [];
    const email = JSON.parse(localStorage.getItem(EMAIL));
    const coordonnees: Coordonnee = JSON.parse(localStorage.getItem(COORDONNEE));
    contacts.push({
      id: TypeContactEnum.EMAIL_PRO,
      value: email
    });

    if (coordonnees.phoneNumber) {
      contacts.push({
        id: TypeContactEnum.PHONE_PRO,
        value: coordonnees?.phoneNumber
      });
    }

    if (coordonnees.cellPhoneNumber) {
      contacts.push({
        id: TypeContactEnum.CELL_PHONE_PRO,
        value: coordonnees?.cellPhoneNumber
      });
    }

    const userInformationsToUpdate: UpdateUsersIdentityAndContacts = {
      contacts,
      person: {
        title: coordonnees?.civility as CiviliteEnum,
        firstName: coordonnees?.firstName,
        lastName: coordonnees?.lastName
      }
    };

    return this.siloApiService.updateUsersIdentityAndContacts(email, userInformationsToUpdate, this.token);
  }

  private reinitialisationMotDePasse(password: string, captchaValue: string): Observable<string> {
    return this.siloApiService.reinitialisationMotDePasse(
      this.signupAffiliateManager?.userId,
      password,
      this.signupAffiliateManager?.hash,
      'reinit_mdp_idp',
      this.token,
      captchaValue
    );
  }

  private activateUser(): Observable<UserActivationResponse> {
    const login = JSON.parse(localStorage.getItem(EMAIL));
    const pUserActivation: UserActivationCreate = {
      applicationId: ApplicationCodeEnum.AFFILIE_APZ,
      active: true,
      profileType: 'AFFILIATE',
      login
    };

    return this.siloApiService.activateUser(this.signupAffiliateManager?.userId, pUserActivation, this.token);
  }

  private nextStep(): void {
    this.router.navigateByUrl(
      `${RoutesEnum.SIGNUP_AFFILIATE_MANAGER_THIRD_STEP}/${this.signupAffiliateManager?.saml}?hash=${this.signupAffiliateManager?.hash}`
    );
  }
}
