import {Component, Input, OnInit} from '@angular/core';
import { GatewayService } from '../../shared/services/gateway.service';
import { environment } from '../../../environments/environment';
import { TealiumService } from '../../shared/services/tealium/tealium.service';

@Component({
    selector: 'app-confirmation-changement-user',
    templateUrl: './confirmation-changement-user.component.html',
    styleUrls: ['./confirmation-changement-user.component.scss',
        '../../../assets/css/apz-global.scss']
})
export class ConfirmationChangementUserComponent implements OnInit {

    redirectURL: string;
    @Input() saml: string;
    @Input() title: string;
    @Input() isLogin: boolean;

    constructor(private gatewayService : GatewayService,
      private readonly tealiumService: TealiumService) {
    }

    ngOnInit() {
      if (this.isLogin) {
        this.tealiumService.view('apz.web.profile.changeLoginConfirmationPageLoad');
      } else {
        this.tealiumService.view('apz.web.profile.changePasswordConfirmationPageLoad');
      }
        const samlDecoder = atob(this.saml);
        this.redirectURL = samlDecoder.split(';')[2];
    }

    goLogin() {
      if (this.isLogin) {
      this.tealiumService.link('apz.web.profile.validateChangeLoginConfirmationClick');
      } else {
      this.tealiumService.link('apz.web.profile.validateChangePasswordConfirmationClick');
      }
      this.gatewayService.authentificate(environment.clientIdAPZ, 'token', environment.redirectURIAPZ)
        .subscribe(contenuHtml => this.setPageHtml(contenuHtml));
    }

    public setPageHtml(html: string): void {
      document.body.innerHTML = html;
      const form = (document.getElementsByTagName('form')[0] as HTMLFormElement);
      form.submit();
    }

}
