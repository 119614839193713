import { Component, OnInit } from '@angular/core';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { SessionQuery } from '@services/store/session/session.query';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { SessionStateService } from '@services/store/session/session-state.service';
import { Observable } from 'rxjs';
import { TealiumService } from '@services/tealium/tealium.service';

@Component({
  selector: 'app-first-step-info-bimpli-cado',
  templateUrl: './first-step-info-bimpli-cado.component.html',
  styleUrls: ['./first-step-info-bimpli-cado.component.scss']
})
export class FirstStepInfoBimpliCadoComponent implements OnInit {

  constructor( private readonly sessionQuery: SessionQuery, private readonly router: Router,
               private readonly activatedRoute: ActivatedRoute,
               private readonly tealiumService: TealiumService,
               private readonly sessionStateService: SessionStateService) {
    this.saml = this.activatedRoute.snapshot.paramMap.get('saml');
  }

  public saml: string;
  public applicationCode$: Observable<ApplicationCodeEnum>;
  public applicationId: string;
  public isMobile: boolean;

  ngOnInit(): void {

    this.sessionStateService.setApplicationCode(this.activatedRoute);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    const applicationCodeSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(
        tap((applicationCode: ApplicationCodeEnum) => {
          switch (applicationCode) {
            case ApplicationCodeEnum.BENEFIT_MOBILE:
              this.isMobile = true;
              this.tealiumService.view('bimpli_mobile.web.register.cado.infoPageLoad');
              break;
            case ApplicationCodeEnum.BENEFIT_BENEF:
              this.tealiumService.view('bimpli_benef.web.register.cado.infoPageLoad');
              break;
            default:
              this.applicationId = applicationCode;
          }
        })
      )
      .subscribe();
  }

  goToFirstStep() {
    this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_FIRST_STEP}/${this.saml}/cado`);
  }

  back(): void {
    window.history.back();
  }

}
