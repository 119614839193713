<div id="content">
  <div class="title-content mb-4">
    <div class="content-title mb-4">
      {{ 'AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.TITLE' | translate }}
    </div>

    <div class="content-standard-text mb-4">
      {{ 'AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.SUBTITLE' | translate }}
    </div>
  </div>
  <ng-container *ngIf="refresh$ | async">
    <ng-container *ngIf="isRefundCenter()">
      <div class="downloadBlock">
        <div class="content-standard-text">
          {{ 'AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.MULTIPLE_AFFILIATES.TEXT' | translate }}
        </div>
      </div>
    </ng-container>

    <div class="cards" *ngFor="let data of affiliatesData">
      <app-coordonnees-cards
        [affiliates]="data.affiliates"
        [totalPages]="getTotalPages(data.affiliates)"
        (download$)="downloadAffiliates(data)"
      >
      </app-coordonnees-cards>
    </div>

    <div class="form-content mt-4">
      <app-coordonnees-form
        [saml]="saml"
        [affiliateEmail]="getAffiliateEmail()"
        (submitForm$)="submitContactForm($event)"
        (showRgpd$)="showRgpd()"
      ></app-coordonnees-form>
    </div>
  </ng-container>
</div>
