<app-cesu-layout>
    <div class="row text-center pt-2 pt-sm-4 pt-lg-5">
        <div class="col-12 cesu-page-title">
            Choisissez votre nouveau mot de passe
        </div>
        <hr class="cesu-separator"/>
    </div>

    <form class="row pt-4 pt-lg-5" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
        <div class="col-12">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7 col-12 form-group">
                    <div class="alert alert-danger" *ngIf="errorMessage">
                        {{ errorMessage }}
                    </div>
                    <input
                        class="form-control cesu-input"
                        [ngClass]="{ 'is-invalid': submitted && form.oldPassword.errors }"
                        type="password"
                        placeholder="Ancien mot de passe"
                        formControlName="oldPassword"
                    />
                    <div *ngIf="form.oldPassword.errors && submitted" class="invalid-feedback cesu-error">
                        <div *ngIf="form.oldPassword.errors.required">
                            <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                            &nbsp;Votre ancien mot de passe est obligatoire
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7 col-12 form-group">
                    <input
                        class="form-control cesu-input"
                        [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
                        type="password"
                        placeholder="Mot de passe"
                        formControlName="password"
                    />
                    <div *ngIf="form.password.errors && submitted" class="invalid-feedback cesu-error">
                        <div *ngIf="form.password.errors.required">
                            <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                            &nbsp;Le mot de passe est obligatoire
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7 col-12 form-group">
                    <input
                        class="form-control cesu-input"
                        [ngClass]="{ 'is-invalid': submitted && (form.passwordConfirmation.errors || changePasswordForm.errors) }"
                        type="password"
                        placeholder="Confirmation du mot de passe"
                        formControlName="passwordConfirmation"
                    />
                    <div *ngIf="form.passwordConfirmation.errors && submitted"
                         class="invalid-feedback cesu-error">
                        <div *ngIf="form.passwordConfirmation.errors.required">
                            <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                            &nbsp;La confirmation du mot de passe est obligatoire
                        </div>
                    </div>
                    <div *ngIf="changePasswordForm.errors && !form.passwordConfirmation.errors && submitted"
                         class="invalid-feedback cesu-error">
                        <div *ngIf="changePasswordForm.errors.notSame">
                            <img src="../../../../../assets/cesu/error_icon.svg" alt="Error"/>
                            &nbsp;Les mots de passe ne sont pas les mêmes.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row justify-content-center">
                <div class="col-auto cesu-password-validation">
                    <app-password-validation
                        [passwordValue]="form.password.value"
                        [affLastPassword]="false"
                        [progressBar]="true"
                    ></app-password-validation>
                </div>
            </div>
        </div>
        <div class="col-12 text-center pt-4">
            <button class="btn cesu-button cesu-stroked-button mr-1" type="button" (click)="goBack()">Retour</button>
            <button class="btn cesu-button cesu-flat-button ml-1" type="submit">Valider</button>
        </div>
    </form>
</app-cesu-layout>
