 import { Component, DoCheck, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { UpdateEmployeeContactTypeEnum } from '@shared/enums/siloApi.enum';
import { dateRegEx, emailRegEx } from '@shared/helpers/regexp.helpers';
import { AuthToken } from '@shared/models/authtoken.model';
import { CreateTermsAndConditions } from '@shared/models/createTermsAndConditions.model';
import { confirmationPasswordIsEqualValidator, isAdultValidator } from '@shared/validators/custom.validators';
import { sha256 } from 'js-sha256';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { UserCriteria } from 'nit-angular-lib';
import { BehaviorSubject, interval } from 'rxjs';
import { finalize, takeWhile } from 'rxjs/operators';
import { ModalService } from '../../../shared/components/modal/modal.service';
 import { UserActivationCreate } from '@shared/models/userActivationCreate.model';
 import { CustomDateParserFormatter } from '@shared/custom/date.formater';
 import { CustomAdapter } from '@shared/custom/date.adapter';
 import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
 import { sha512 } from 'js-sha512';

@Component({
  selector: 'app-ecarte-activation-compte',
  templateUrl: './ecarte-activation-compte.component.html',
  styleUrls: ['./ecarte-activation-compte.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class EcarteActivationCompteComponent implements OnInit, DoCheck {

  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConfirmPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isConfirmMailValid: boolean;
  token: AuthToken;
  isLoading = false;
  saml: string;
  applicationId: string;
  userId: string;
  employeeId: string;
  messageErreur: string;
  success = false;
  valueCaptcha: string;
  isAlreadyExist = false;
  isCompteNotExist = false;
  timer = 10;
  isCharging = true;

  isKBI = false;
  scheduledStartDate: any;

  constructor(
    protected fb: FormBuilder,
    protected translate: TranslateService,
    protected siloApiService: SiloApiService,
    protected gatewayService: GatewayService,
    private router: Router,
    private route: ActivatedRoute,
    protected tealiumService: TealiumService,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: ModalService,
    private readonly recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.saml = this.route.snapshot.paramMap.get('saml');
  }

  emailFormControl = this.formBuilder.control({value: '', disabled: true},  [Validators.required, Validators.pattern(emailRegEx)]);
  passwordFormControl = this.formBuilder.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(60)]);
  createAccountForm = this.formBuilder.group({
    login: this.emailFormControl,
    password: this.passwordFormControl,
    acceptCGU: [false, [Validators.requiredTrue]],
    birthdate: ['', [Validators.pattern(dateRegEx), isAdultValidator] ],
    confirm_password: [{value: '', disabled: true},
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(60),
        confirmationPasswordIsEqualValidator(this.passwordFormControl)
      ]]
    }
  );

  getCGU() {
    const token = btoa('||'+ this.applicationId);
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cgu/' + token])
    );

    window.open(url, '_blank');
  }

  isLessThanEightChars(p: string): boolean {
    return p.length < 8;
  }

  isMoreThanSixtyChars(p: string): boolean {
    return p.length > 60;
  }

  hasAtLeastOneLowerCase(p: string): boolean {
    return p.toUpperCase() !== p;
  }

  hasAtLeastOneUpperCase(p: string): boolean {
    return p.toLowerCase() !== p;
  }

  hasAtLeastOneDigit(p: string): boolean {
    return /\d/.test(p);
  }

  hasAtLeastOneSpecialChar(p: string): boolean {
    const pwdRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return pwdRegex.test(p);
  }

  isCorrectPwd(p: string): boolean {
    return !this.isLessThanEightChars(p) && !this.isMoreThanSixtyChars(p) && (
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneDigit(p) && this.hasAtLeastOneSpecialChar(p)) ||
      (this.hasAtLeastOneLowerCase(p) && this.hasAtLeastOneUpperCase(p) && this.hasAtLeastOneSpecialChar(p))
    );
  }

  ngDoCheck(): void {
    if (this.createAccountForm.get('password').dirty) {
      this.createAccountForm.get('confirm_password').enable();
    }
  }

  validate(): void {
    if (this.createAccountForm.valid) {
      this.isLoading = true;
      this.gatewayService.getAccessToken().subscribe((token: AuthToken) =>
       {
        this.token = token;
        this.activateUser().pipe().subscribe(
          () => this.finaliserActivation(),
          (error) => {
            this.isLoading = false;
            if (error.error.errors[0] && error.error.errors[0].
              additionalInformation.message.includes('already activated')) {
                this.messageErreur = 'Le compte est déjà activé';
                this.modalService.open('errorModal');
            }
          }
        );}
      )
    }
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }

  private finaliserActivation() {
    this.createTermsAndConditionsAcceptances().pipe().subscribe();
    this.isLoading = false;
    this.success = true;
  }

  private activateUser() {
    const pUserActivation: UserActivationCreate = {
      applicationId: this.applicationId,
      active: true,
      profileType: 'EMPLOYEE',
      login: this.createAccountForm.get('login').value,
      password: sha256(this.createAccountForm.get('password').value),
      passwordCado: sha512(this.createAccountForm.get('password').value)
    };

    if (this.isKBI) {
      pUserActivation.cellPhoneNumber = this.createAccountForm.get('phone').value;
      pUserActivation.birthDate =  this.createAccountForm.get('birthdate').value;
    }

    return this.siloApiService.activateUser(this.userId, pUserActivation, this.token).pipe();
  }

  private createTermsAndConditionsAcceptances() {
    const profileTypeLocal = 'EMPLOYEE';

    const body: CreateTermsAndConditions = {
      profileType: profileTypeLocal,
      applicationId: this.applicationId
    };

    return this.siloApiService
      .createTermsAndConditionsAcceptances(Number(this.userId), body,  this.token);
  }

  ngOnInit(): void {
    this.scheduledStartDate = {
      year: new Date().getFullYear() - 37,
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };

    // Découper la samlRequest pour récupérer les informations
    const samlDecoded = atob(this.saml).split(';');
    this.createAccountForm.get('login').setValue(samlDecoded[1]);
    this.userId = samlDecoded[0];
    this.applicationId = samlDecoded[2];
    this.employeeId = samlDecoded[4];

    if (samlDecoded.length >= 7) {
      const productType = samlDecoded[6];
      if (productType === 'KBI') {
        this.isKBI = true;
        this.createAccountForm.get('birthdate').setValidators(Validators.required);
      }
    }

    // on recherche l'utilisateur
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.token = authToken;
        this.recaptchaV3Service
          .execute('CreationAccountAction')
          .pipe(finalize(() => this.readUserByCriteria(samlDecoded)))
          .subscribe((token) => (this.valueCaptcha = token));
    });
  }

  private readUserByCriteria(samlDecoded): void {
    const userCriteria = new UserCriteria();
    userCriteria.applicationId = this.applicationId;
    userCriteria.login = samlDecoded[1];
    this.siloApiService.readUserByCriteria(this.token, userCriteria, this.valueCaptcha)
    .pipe (finalize(() => this.isCharging = false)).subscribe((retour) => {
      if (retour.items.length !== 0 && retour.items[0].active === 'ACTIVE') {
        this.isAlreadyExist = true;
        interval(1000).pipe(
          takeWhile(() => this.timer >= 0)).subscribe(() => {
            this.timer = this.timer - 1;
            if (this.timer === 0){
              const token = btoa('||'+ this.applicationId);
              this.router.navigate([`${RoutesEnum.LOGIN}/${token}`]);
            }
        });
      } else if (retour.items.length === 0) {
        this.isCompteNotExist = true;
      }
    });
  }

  redirectToLogin() {
    const token = btoa('||'+ this.applicationId);
    this.router.navigate([`${RoutesEnum.LOGIN}/${token}`]);
  }

  create() {
    const token = btoa('||'+ this.applicationId);
    this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_CHOICE}/${token}`]);
  }

}


