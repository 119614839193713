import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { SiloApiService } from '@services/siloApi.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { AuthToken } from '@shared/models/authtoken.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Address, UserCriteria } from 'nit-angular-lib';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { INFO_GREFFE_DATA, SIRET } from '../../affilie-apz/affilie-apz.component';

const ID_COMPENSATING_CRT = 'CRT';
const STATUS_PENDING_ACTIVATION = 'PENDING_ACTIVATION';
const STATUS_ACTIVE = 'ACTIVE';
@Component({
  selector: 'app-first-step-affilie-apz-component',
  templateUrl: './first-step-affilie-apz.component.html',
  styleUrls: ['../../../../../assets/css/affilie-apz-global.scss', './first-step-affilie-apz.component.scss']
})
export class FirstStepAffilieApzComponent implements OnInit {
  // Variables
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input() saml: string;
  isKnowAffiliate = false;
  isSiretInvalide = false;
  isAffiliateFound = false;
  siretForm: FormGroup = this.fb.group({ siret: ['', [Validators.required, Validators.pattern('[0-9]{14}')]] });
  isLoading = false;
  mySubscription: Subscription = new Subscription();
  token: AuthToken;
  valueCaptcha: string;
  companyName: string;
  address: string;
  isPanelOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isStepOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    sessionStorage.getItem('BACK_FROM_CONTACT') === 'true' ? false : window.innerWidth > 576 ? false : true
  );

  // Constructor
  constructor(
    private fb: FormBuilder,
    private gtds: GroundTruthDataService,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private readonly router: Router
  ) {
    sessionStorage.removeItem('BACK_FROM_CONTACT');
  }

  // Methods
  ngOnInit(): void {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        this.isLoading = response;
      });

    this.mySubscription.add(isSpinnerActivated);
  }

  validate(): void {
    if (this.siretForm.valid) {
      this.isKnowAffiliate = false;
      this.isSiretInvalide = false;
      this.isAffiliateFound = false;
      this.valueCaptcha = null;

      this.gtds.setIsSpinnerActivated(true);
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.token = authToken;
        this.siloApiService.readAffiliates(this.token, ID_COMPENSATING_CRT, this.siretForm.value.siret).subscribe((retour) => {
          if (retour.length === 0) {
            this.isKnowAffiliate = false;
            this.recaptchaV3Service
              .execute('CreationAccountAction')
              .pipe(finalize(() => this.readUserByCriteria()))
              .subscribe((token) => (this.valueCaptcha = token));
          } else {
            this.isPanelOpen$.next(true);
            this.gtds.setIsSpinnerActivated(false);
            this.isKnowAffiliate = true;
          }
        });
      });
    }
  }

  private readUserByCriteria(): void {
    const userCriteria = new UserCriteria();
    userCriteria.siret = this.siretForm.value.siret;
    userCriteria.applicationId = ApplicationCodeEnum.AFFILIE_APZ;
    this.siloApiService.readUserByCriteria(this.token, userCriteria, this.valueCaptcha).subscribe((retour) => {
      if (retour.items.length === 0) {
        this.readInfoGreffeData();
      } else {
        const user = retour.items[0];
        if (user.active === STATUS_PENDING_ACTIVATION) {
          this.gtds.setIsSpinnerActivated(false);
          this.isKnowAffiliate = true;
          this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}`);
        } else if (user.active === STATUS_ACTIVE) {
          this.gtds.setIsSpinnerActivated(false);
          this.isKnowAffiliate = true;
          this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`], { queryParams: { isKnowAffiliate: this.isKnowAffiliate } });
        }
      }
    });
  }

  private readInfoGreffeData(): void {
    this.siloApiService
      .readInfoGreffeData(this.token, this.siretForm.value.siret)
      .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
      .subscribe(
        (retour) => {
          if (retour.companyName !== null && retour.companyName !== undefined) {
            this.isAffiliateFound = true;
            this.companyName = retour.companyName;
            this.address = this.convertAdress(retour.address);
            localStorage.setItem(SIRET, this.siretForm.value.siret);
            localStorage.setItem(INFO_GREFFE_DATA, JSON.stringify(retour));
          } else {
            this.isSiretInvalide = true;
          }
          this.isPanelOpen$.next(true);
        },
        () => {
          this.isSiretInvalide = true;
          this.isPanelOpen$.next(true);
        }
      );
  }

  private convertAdress(address: Address): string {
    return `${address.street} ${address.postalCode} ${address.city}`;
  }

  formatSiret(dt: ClipboardEvent): void {
    if (dt && dt.clipboardData) {
      const reg: RegExp = new RegExp(' ', 'gu');
      const siret = dt.clipboardData.getData('text').replace(reg, '');
      this.siretForm.get('siret').setValue(siret);
      this.siretForm.updateValueAndValidity();
    }
  }

  nextStep(): void {
    this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_IDENTITY_STEP}/${this.saml}`);
  }

  closePanel(): void {
    this.isPanelOpen$.next(false);
  }

  back(): void {
    this.router.navigateByUrl(`${RoutesEnum.LOGIN}/${this.saml}`);
  }
}
