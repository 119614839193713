import { Component, OnInit, Output, Input, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { validatePassword } from '../../../../shared/components/password-validation/password.helper';
import { Employee } from '../../../../shared/models/employee.model';
import { WeezenComponent } from '../../../weezen/weezen.component';
// import { Favicons } from '../../../../shared/favicons';
import { Subscription } from 'rxjs';
import { GroundTruthDataService } from '../../../../shared/services/ground-truth-data.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import {mailRegex} from 'nit-angular-lib';

@Component({
  selector: 'app-second-step-asp-component',
  templateUrl: './second-step-asp-component.component.html',
  styleUrls: ['./second-step-asp-component.component.scss',
    '../../create-account.component.scss',
    '../../../../../assets/css/asp-global.scss']
})
export class SecondStepAspComponentComponent extends WeezenComponent implements OnInit, OnDestroy {

  civilite: string;
  nom: string;
  prenom: string;
  email: string;
  phone: string;
  passwordEqual = false;

  @Input() employee: Employee;
  @Input() cellPhonePro: string;
  @Input() mailPro: string;
  @Input() token: string;
  @Input() messageErreur: string;
  @Input() success: boolean;

  @Output() retour: EventEmitter<any[]> = new EventEmitter();

  @Output() submitted: EventEmitter<any> = new EventEmitter();

  passwordForm = this.fb.group({
    password: [undefined, validatePassword()],
    confirmPassword: [undefined, [Validators.required]],
    login: [undefined, [Validators.required, Validators.pattern(mailRegex)]],
    cgu: [false, [Validators.requiredTrue]]
  });

  isLoading = false;
  mySubscription = new Subscription();

  constructor(private fb: FormBuilder,
     // fav: Favicons,
      private gtds: GroundTruthDataService, private router: Router) {
    // super(fav);
    super();
  }

  ngOnInit() {
    this.passwordForm.get('login').setValue(this.mailPro);
    const isSpinnerActivated = this.gtds.getIsSpinnerActivated().pipe(
      distinctUntilChanged()
    ).subscribe(response => { this.isLoading = response; });

    this.mySubscription.add(isSpinnerActivated);
  }

  controlPassWord() {
    this.passwordEqual = this.passwordForm.get('password').value === this.passwordForm.get('confirmPassword').value;
  }

  back() {
    this.retour.emit();
  }

  submitForm() {
    this.gtds.setIsSpinnerActivated(true);

    this.submitted.emit({ form: this.passwordForm.getRawValue() });

  }

  goToLogin() {
    window.location.href = environment.redirectURIWee.replace('/token', '');
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }

}
