import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-header-modal',
    templateUrl: './header-modal.component.html',
    styleUrls: ['./header-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderModalComponent implements OnInit {
    @Input() titleModal: string;
    @Output() modalClose: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    closeModal() {
        this.modalClose.emit();
    }
}
