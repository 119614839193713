<div *ngIf="!isCharging">
    <div *ngIf="isAlreadyExist" class="background-bimpli d-flex flex-column align-items-center justify-content-center vw-100 vh-100">
        <button (click)="redirectToLogin();" class="position-fixed d-flex align-items-center justify-content-center btn btn-secondary btn-block">Passer</button>
        <div class="card col-4 d-flex flex-column align-items-center justify-content-center">
            <span class="title-bimpli" *ngIf="isAlreadyExist">Vous possédez déjà un compte Bimpli</span>
            <span class="sub-title mt-4" *ngIf="isAlreadyExist">Vous allez être redirigé à la page de connexion Bimpli, où vous pourrez vous connectez avec vos identifiants bimpli.</span>
            <img class="ico-timer mt-2" src="/assets/bimpli/ico-temps-ecoul.svg">
            <span class="redirect mt-2">Redirection dans {{timer}} secondes</span>
        </div>
    </div>


    <div *ngIf='!success && (!isAlreadyExist)'>

        <div *ngIf="isCompteNotExist" class="container-fluid vw-100  p-0 m-0 row">
            <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
                <div class="d-flex flex-column">
                    <img class="logo" src='../../../../assets/bimpli/logo.svg'  alt="Logo" />
                    <span class="title-bp">Et Bim !</span>
                    <span class="sub-title-bp">C'est la première fois qu'on se voit</span>
                </div>
            </div>
            <div class="d-block col-xs-12 col-sm-8 offset-sm-4">
                <div class="bloc-center d-flex flex-column">
                    <span> <img src="/assets/bimpli/validation-compte.png" class="validation-compte"/></span>
                    <span class="create">Zut !</span>
                    <span class="create-subtitle bold mt-3 mb-3">Vous avez dépassé le délai de création de compte e-carte BIMPLI</span>
                    <span class="create-subtitle mt-3 mb-3">Pas d'inquiétude ! 🙂<br>
                        Vous allez très bientôt recevoir votre carte BIMPLI et votre code confidentiel auprès de votre employeur*.</span>

                    <span class="ask mb-3"> Vous avez votre carte en main ?</span>
                    <button type="button" class="mb-4 btn btn-primary btn-block" (click)="create()">
                        <div class="d-flex flex-row align-items-center justify-content-center">
                            <div *ngIf="isLoading" class="spinner-border"></div>
                            <span [ngClass]="{'ml-2': isLoading }">Créer mon compte</span>
                        </div>
                    </button>

                    <span class="label-nb">* Les dates de réception de la carte et du code peuvent varier de quelques jours selon les délais postaux.</span>
                    <div class="d-block d-sm-none img-mobile-end position-fixed"></div>
                </div>
            </div>
        </div>
        <div *ngIf="!isCompteNotExist" class="container-fluid vw-100  p-0 m-0 row">
            <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
                <div class="d-flex flex-column">
                    <img class="logo" src='../../../../assets/bimpli/logo.svg'  alt="Logo" />
                    <span class="title-bp">Et Bim !</span>
                    <span class="sub-title-bp">C'est la première fois qu'on se voit</span>
                </div>
            </div>
            <div class="d-block col-xs-12 col-sm-8 offset-sm-4">
                <div class="bloc-center d-flex flex-column">

                    <span class="create">Identifiants Bimpli</span>
                    <span class="create-subtitle mt-3 mb-3">Définissez vos identifiants Bimpli.</span>

                    <form [formGroup]="createAccountForm" (keydown.enter)="validate()">
                        <div class="inner_page">
                            <input type="text" class="form-control" name="login"
                                    id="login" formControlName="login"
                                    maxlength="70" >
                            <div class="mt-4">
                                <label for="password">Mot de passe</label>
                                <div class="input-group">
                                    <input [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="password" placeholder="Saisir votre mot de passe" id="password"
                                            formControlName="password" maxlength="60" minlength="8">
                                    <div class="input-group-append">
                                        <button *ngIf="(isPasswordDisplayed$ | async) === false"type="button"
                                            class="btn input-group-text"(click)="isPasswordDisplayed$.next(true)">
                                            <i class="bi bi bi-eye-fill"></i>
                                        </button>
                                        <button *ngIf="(isPasswordDisplayed$ | async) === true" type="button"
                                            class="btn input-group-text" (click)="isPasswordDisplayed$.next(false)">
                                            <i class="bi bi-eye-slash-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="error-message mt-4 p-4 mb-4">
                                <div class="validation mb-4">Validation de votre mot de passe : </div>
                                <div class="message_item">
                                    <i [ngClass]="{'icon-cross' : this.isLessThanEightChars(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                                    'icon-check' : !this.isLessThanEightChars(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                        <span>{{'COMMON.FORM.PASS_ERR.EIGHT_CHARS' | translate}}</span>
                                    </i>
                                </div>
                                <div class="message_item">
                                    <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneLowerCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                                    'icon-check' : this.hasAtLeastOneLowerCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                        <span>{{'COMMON.FORM.PASS_ERR.LOWER_CASE' | translate}}</span>
                                    </i>
                                </div>
                                <div class="message_item">
                                    <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneUpperCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                        'icon-check' : this.hasAtLeastOneUpperCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                        <span>{{'COMMON.FORM.PASS_ERR.UPPER_CASE' | translate}}</span>
                                    </i>
                                </div>
                                <div class="message_item">
                                    <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneDigit(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                        'icon-check' : this.hasAtLeastOneDigit(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                        <span>{{'COMMON.FORM.PASS_ERR.DIGIT' | translate}}</span>
                                    </i>
                                </div>
                                <div class="message_item">
                                    <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneSpecialChar(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                        'icon-check' : this.hasAtLeastOneSpecialChar(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                        <span>{{'COMMON.FORM.PASS_ERR.SPECIAL_CHAR' | translate}}</span>
                                    </i>
                                </div>
                            </div>
                            <div class="input_group">
                                <label for="confirm_password">Confirmation du mot de passe</label>
                                <div class="input-group">
                                    <input [type]="(isConfirmPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="confirm_password"
                                        placeholder=" Saisir à nouveau votre mot de passe" id="confirm_password"
                                        formControlName="confirm_password">
                                    <div class="input-group-append">
                                        <button *ngIf="(isConfirmPasswordDisplayed$ | async) === false"type="button"
                                            class="btn input-group-text"(click)="isConfirmPasswordDisplayed$.next(true)">
                                            <i class="bi bi bi-eye-fill"></i>
                                        </button>
                                        <button *ngIf="(isConfirmPasswordDisplayed$ | async) === true" type="button"
                                            class="btn input-group-text" (click)="isConfirmPasswordDisplayed$.next(false)">
                                            <i class="bi bi-eye-slash-fill"></i>
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="createAccountForm.get('confirm_password').dirty">
                                    <small class="form-text text-danger"
                                            *ngIf="createAccountForm.get('confirm_password').value != createAccountForm.get('password').value">Mot de passe de confirmation invalide</small>
                                </div>
                            </div>

                            <div *ngIf="isKBI" class="input-group mb-4">
                                <label for="birthdate">Date de naissance</label>
                                <div class="input-group">
                                    <input id="birthdate" placeholder="Saisie de la date dd/mm/aaaa" class="form-control"
                                           formControlName="birthdate"
                                           name="birthdate" ngbDatepicker #birthdate="ngbDatepicker"
                                           [startDate]="scheduledStartDate" [minDate]="{year: 1900, month:1, day: 1}" [maxDate]="{year: 2022, month:1, day: 19}">
                                    <div class="input-group-append">
                                        <button class="bi bi-calendar btn btn-outline-secondary calendar"
                                                (click)="birthdate.toggle()" type="button"></button>
                                    </div>
                                </div>
                            </div>

                            <div class="error-message p-4 mb-4">
                                <div class="form-check pl-0 row align-items-center justify-content-center">
                                    <input class="mr-2 ml-2 form-check-input" type="checkbox" formControlName="acceptCGU" id="acceptCGU"/>
                                    <label class="col cgu" for="acceptCGU"> En cochant cette case vous acceptez les <a (click)="getCGU()">Conditions générales d'utilisation</a> de Bimpli.</label>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!createAccountForm.valid" (click)="validate()">
                            <div class="d-flex flex-row align-items-center justify-content-center">
                                <div *ngIf="isLoading" class="spinner-border"></div>
                                <span [ngClass]="{'ml-2': isLoading }">Continuer</span>
                            </div>
                        </button>
                    </form>
                </div>
            /</div>
        </div>
    </div>

    <div *ngIf='success'>
        <app-fourth-step-bimpli-apetiz [estMobile]="false" [applicationId]="applicationId" [success]="true"></app-fourth-step-bimpli-apetiz>
    </div>
</div>

<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>
