import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { SessionStore } from '@services/store/session/session.store';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { ChangePassword } from '@shared/models/change-password.model';
import { CodeErreurs, Erreurs } from '@shared/models/errors.model';
import { Observable, Subscription } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-changement-mot-de-passe',
  templateUrl: './changement-mot-de-passe.component.html',
  styleUrls: ['./changement-mot-de-passe.component.scss'],
})
export class ChangementMotDePasseComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public applicationId: ApplicationCodeEnum;
  public saml: string;
  public login: string;
  public redirectUrl: string;

  private valueCaptcha: string;

  public messageErreur: string;
  public passwordOk = false;
  public showConfirmationModifPwd: boolean;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly translateService: TranslateService,
    private readonly snackbarService: SnackbarService,
    private readonly sessionStore: SessionStore,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  ngOnInit() {
    this.sessionStateService.setApplicationCodeFromChangePassword(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    const applicationIdSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(tap((applicationCode: ApplicationCodeEnum) => {
        console.log(applicationCode);
        if (applicationCode === ApplicationCodeEnum.APZ_MOBILE) {
          this.applicationId = ApplicationCodeEnum.APZ;
        } else {
          this.applicationId = applicationCode;
        }
      }))
      .subscribe();

    this.subscription.add(applicationIdSubscription);

    const changePasswordInfoSubscription = this.sessionQuery.selectedChangePasswordInfo
      .pipe(
        tap((changePasswordInfo: ChangePassword) => {
          this.saml = changePasswordInfo.saml;
          this.login = changePasswordInfo.login;
          this.redirectUrl = changePasswordInfo.redirectUrl;
        }),
      )
      .subscribe();

    this.subscription.add(changePasswordInfoSubscription);

    this.showConfirmationModifPwd = false;
  }

  public updateUser(oldPwd, newPwd) {

    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.reCaptchaV3Service
      .execute('ConnecterAction')
      .pipe(finalize(() => {
        this.siloApiService.updateUser(this.login, null, this.applicationId, oldPwd, newPwd, authToken, this.valueCaptcha).subscribe(
          (retour) => {
            this.passwordOk = true;
            this.redirection();
            this.snackbarService.showSuccess('Mise à jour effectuée');
          },
          (error) => this.gererErreurs(error),
        )
      }))
      .subscribe((token) => (this.valueCaptcha = token));
    },
      err => {
        this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
      });

   /*  this.gatewayService.getAccessToken().subscribe((authToken) =>
      this.siloApiService.updateUser(this.login, null, this.applicationId, oldPwd, newPwd, authToken, this.valueCaptcha).subscribe(
        (retour) => {
          this.passwordOk = true;
          this.redirection();
          this.snackbarService.showSuccess('Mise à jour effectuée');
        },
        (error) => this.gererErreurs(error),
      ),
    ); */
  }

  public redirection() {
    if (this.redirectUrl) {
      window.location.href = this.redirectUrl;
    } else if (this.applicationId === ApplicationCodeEnum.APZ) {
      this.showConfirmationModifPwd = true;
    }
  }

  public gererErreurs(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inatendue : ' + error.error.message;
    } else {
      const errors: Erreurs = JSON.parse(error.error);
      switch (errors.errors[0].code) {
        case CodeErreurs.UNEXPECTED_011:
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED_011');
          break;
        case CodeErreurs.INCORRECT_DATA_007:
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.INCORRECT_DATA_007');
          break;
        case CodeErreurs.FORBIDDEN_ACTION_010:
          this.messageErreur = this.translateService.instant('ASP.CHANGE_PWD.ERROR.FORBIDDEN_ACTION_010');
          break;
        default:
          this.messageErreur = this.messageErreur =
            this.translateService.instant('ASP.CHANGE_PWD.ERROR.UNEXPECTED') + errors.errors[0].code;
          break;
      }
    }
    this.snackbarService.showDanger(this.messageErreur);
  }

  back() {
    window.history.back();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();

    // Removes the changePassword info from the store
    this.sessionStore.update({ changePasswordInfo: undefined });
  }

}
