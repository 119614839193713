<div id="card-content">
  <table class="table table-borderless">
    <thead class="table-header">
      <th class="header-text text-nowrap pb-0">
        {{
          "AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.CARD_INFO.COMMERCIAL_NAME"
            | translate
        }}
      </th>
      <th class="header-text text-nowrap pb-0">
        {{
          "AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.CARD_INFO.LEGAL_NAME"
            | translate
        }}
      </th>
      <th class="header-text pb-0">
        {{
          "AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.CARD_INFO.ADDRESS"
            | translate
        }}
      </th>
      <th class="header-text pb-0">
        {{
          "AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.CARD_INFO.SIRET"
            | translate
        }}
      </th>
    </thead>
    <tbody class="table-body">
      <tr>
        <td class="content-text pt-1">{{ affiliate?.company?.companyName }}</td>
        <td class="content-text pt-1">{{ affiliate?.name }}</td>
        <td class="content-text pt-1">{{ getAffiliateAddress(affiliate?.address) }}</td>
        <td class="content-text pt-1">{{ affiliate?.company?.siret }}</td>
      </tr>
      
    </tbody>
  </table>
</div>
