import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-rgpd-container',
  templateUrl: './rgpd-container.component.html',
  styleUrls: ['./rgpd-container.component.scss']
})
export class RgpdContainerComponent {

  @Output() closePanel$ = new EventEmitter<void>();

  public gotIt(): void {
    this.closePanel$.emit();
  }
}
