import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-forgotten-login-mail',
  templateUrl: './forgotten-login-mail.component.html',
  styleUrls: ['./forgotten-login-mail.component.scss'],
})
export class ForgottenLoginMailComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public saml: string;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
  ) {
  }

  ngOnInit(): void {
    this.sessionStateService.setApplicationCode(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml: string) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);

    const checkLocationSubscription = combineLatest([
      this.sessionQuery.selectedApplicationCode,
      this.sessionQuery.selectedForgottenLoginMail,
    ])
      .pipe(
        take(1),
        tap(([applicationCode, forgottenLoginMail]) => {
          if (applicationCode === ApplicationCodeEnum.CESU && !forgottenLoginMail) {
            this.router.navigate([`${RoutesEnum.FORGOTTEN_LOGIN}/${this.saml}`]);
          }
        }),
      )
      .subscribe();

    this.subscription.add(checkLocationSubscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
