import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { CesuRegistrationStatusEnum } from 'src/app/shared/enums/cesu.enum';
import { CesuState } from 'src/app/shared/states/cesu-state.model';
import { CesuStore } from './cesu.store';

@Injectable({ providedIn: 'root' })
export class CesuQuery extends Query<CesuState> {
  public selectedCesuRegistrationStatus: Observable<CesuRegistrationStatusEnum> = this.select((store) => store.cesuRegistrationStatus);

  public selectedRegistrationLogin: Observable<string> = this.select((store) => store.registrationLogin);

  public selectedShowFooter: Observable<boolean> = this.select(store => store.showFooter);

  constructor(protected store: CesuStore) {
    super(store);
  }
}
