import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { AccountActivation } from '@shared/models/account-activation.model';
import { AuthToken } from '@shared/models/authtoken.model';
import { Observable, Subscription } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-activation-compte',
  templateUrl: './activation-compte.component.html',
  styleUrls: ['./activation-compte.component.scss'],
})
export class ActivationCompteComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public accountActivationInfo: AccountActivation;
  public isAlreadyActived = false;
  public isLoading = true;

  public message: string;
  public success: boolean;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  private IMPOSSIBLE_ACTION_041 = 'IMPOSSIBLE_ACTION_041';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly gatewayService: GatewayService,
    private readonly siloApiService: SiloApiService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService) {
    this.sessionStateService.setApplicationCodeFromAccountActivation(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    const accountActivationSubscription = this.sessionQuery.selectedAccountActivationInfo.pipe(
      tap((accountActivationInfo: AccountActivation) => this.accountActivationInfo = accountActivationInfo),
    ).subscribe();

    this.subscription.add(accountActivationSubscription);
  }

  ngOnInit() {
    this.gatewayService
      .getAccessToken()
      .pipe(
        concatMap((authToken: AuthToken) => {
          return this.siloApiService.activerCompte(this.accountActivationInfo.hash, authToken)
            .pipe(
              tap(() => {this.success = true; this.isLoading = false;}),
              catchError((err) => {
                this.success = false;
                this.isLoading = false;
                const errorCode = err.error.errors[0].code;
                if (errorCode === this.IMPOSSIBLE_ACTION_041) {
                  this.isAlreadyActived = true;
                }
                throw err;
              }),
            );
        }),
      ).subscribe();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}


