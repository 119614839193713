<app-apz-layout [title]="'Je crée mon compte'" [saml]="saml" class="container-fluid">
  <div class="row justify-content-center conteneur">
    <app-ouvre-apetiz></app-ouvre-apetiz>

    <section class="carte col text-center bg-white">
      <app-snackbar></app-snackbar>
      <!-- Cas email -->
      <div *ngIf="!isMobile" class="text-center">
        <p *ngIf="typeLogin">
          Un mail a été envoyé a cette adresse email afin de confirmer la création du compte
        </p>
        <p *ngIf="!typeLogin">
          Impossible de renvoyer les informations d'activations.
        </p>
        <img src="./assets/apz/paperplane.svg" class="img img-responsive Path-Copy" />
        <button class="btn btn-action" (click)="goLogin()">Retourner à la page de connexion</button>
        <a *ngIf="typeLogin" class="nav-link" (click)="reSendEmail()">Cliquez ici pour renvoyer le mail</a>
      </div>

      <!-- Cas CELL_PHONE_PRO -->
      <div *ngIf="isMobile" class="text-center">
        <p>
          Un sms a été envoyé au numero indiqué afin de confirmer la création du compte
        </p>

        <img src="./assets/apz/paperplane.svg" class="img img-responsive Path-Copy" />

        <p>Saisissez le code reçu par SMS ci-dessous</p>
        <div class="row justify-content-center">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span>
                <img class="lock" src="./assets/apz/lock.png" />
              </span>
            </div>
            <input type="text" [formControl]="codeSMS" placeholder="- - - - - -" />
          </div>
        </div>

        <br />
        <button class="btn" (click)="validateCode()">Valider</button>

        <p class="d-flex flex-row justify-content-center align-items-center">
          SMS non reçu ?
          <a class="nav-link" (click)="resendSMS()">
            Renvoyez moi le SMS
          </a>
        </p>
      </div>
    </section>
  </div>
</app-apz-layout>
