import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateAdapter, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { GatewayService } from '@services/gateway.service';
import { RegistrationService } from '@services/registration/registration.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { CustomAdapter } from '@shared/custom/date.adapter';
import { CustomDateParserFormatter } from '@shared/custom/date.formater';
import { RegistrationStepEnum } from '@shared/enums/registration-step.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { dateRegEx } from '@shared/helpers/regexp.helpers';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { interval, of } from 'rxjs';
import { tap, catchError, takeWhile, finalize } from 'rxjs/operators';
import { ModalService } from '../../../../shared/components/modal/modal.service';

@Component({
  selector: 'app-first-step-bimpli-cesu',
  templateUrl: './first-step-bimpli-cesu.component.html',
  styleUrls: ['./first-step-bimpli-cesu.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class FirstStepBimpliCesuComponent implements OnInit {

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly tealiumService: TealiumService,
    private readonly sessionStateService: SessionStateService,
    private readonly registrationService: RegistrationService,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  @Input() isMobile: boolean;
  @Input() saml: string;

  isLoading: boolean;
  cesuForm = this.formBuilder.group({
    cesu: ['', Validators.required],
    birthdate: ['', [Validators.required, Validators.pattern(dateRegEx)] ]
  });
  scheduledStartDate: any;
  messageErreur: string;
  isAlreadyExist = false;
  timer = 10;
  captchaResponse: string;

  ngOnInit(): void {
    this.scheduledStartDate = {
      year: new Date().getFullYear() - 37,
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }

  send() {

      this.recaptchaV3Service
        .execute('CreationAccountAction')
        .pipe(finalize(() => this.envoyerFormulaire()))
        .subscribe((token) => (this.captchaResponse = token));
  }

  envoyerFormulaire() {
    if (this.cesuForm.valid) {
      this.tealiumService.link('cesu.registrationProcess.validateRegistrationClick');
      this.isLoading = true;
      if (this.cesuForm.valid) {
        this.registrationService.newRegistrationPersonalNumber(
          this.cesuForm.value.cesu,
          new Date(this.cesuForm.value.birthdate),
          'CES',
          this.captchaResponse
        )
          .pipe(
            tap(() => {
              this.sessionStateService.setRegistrationStep(RegistrationStepEnum.SECOND_STEP);
              this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/${this.saml}/cesu`]);
              this.isLoading = false;
            }),
            catchError((err) => {
              console.log(err);
              if (err.message === 'COMPTE_ALREADY_EXIST') {
                this.isAlreadyExist = true;
                interval(1000).pipe(
                  takeWhile(() => this.timer >= 0)).subscribe(() => {
                    this.timer = this.timer - 1;
                    if (this.timer === 0){
                      this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
                    }
                });

              } else if (err.code === 'INVALID_FORMAT_002') {
                this.messageErreur = 'Le format de la date est invalide';
                this.modalService.open('errorModal');
                this.isLoading = false;

              } else if (err.message === 'COMPTE_PENDING_ACIVATION') {
                this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}/cesu`]);
              } else {
                this.messageErreur = err.message;
                this.modalService.open('errorModal');
                this.isLoading = false;
              }
              return of();
            }),
          )
          .subscribe();
      }
    }
  }

  back(): void {
    window.history.back();
  }

  openPopUp(id: string) {
    this.modalService.open(id);
  }

  redirectToLogin() {
    this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
  }

  isBirdthDateValide() {
    return this.cesuForm && this.cesuForm.valid;
  }
}
