import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rgpd-apz',
  templateUrl: './rgpd-apz.component.html',
  styleUrls: ['./rgpd-apz.component.scss']
})
export class RgpdApzComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goBack() {
    window.history.back();
  }
}
