<app-apz-layout [title]="'Modifier mon mot de passe'" [saml]="saml">
  <div class="row justify-content-center py-3">
    <app-promo-app-mobile></app-promo-app-mobile>
    <div class="row blocChangePwd justify-content-top ">
      <section class="col-12">
        <div *ngIf="errorMessage !== null" class="error" style="padding-top: 2.5vh;text-align: center;">
          {{ errorMessage }}
        </div>
      </section>
      <form name="passwordForm" [formGroup]="passwordForm" (submit)="submitForm()" class="row justify-content-center">
        <section class="col-12">
          <div class="row">
            <div class="col-6 block-pwd-6">
              <div class="col-12 block-pwd-12">
                <div class="form-group row d-flex justify-content-center align-items-center">
                  <div class="col-10">
                    <input
                      [type]="hideOld ? 'password' : 'text'"
                      class="form-control lock"
                      id="currentPassword"
                      name="currentPassword"
                      formControlName="currentPassword"
                      placeholder="ancien mot de passe"
                      (keyup)="arePasswordsDifferent()"
                    />
                  </div>
                  <div class="img-fluid col-1" [ngClass]="hideOld ? 'hide' : 'show'" (click)="hideOld = !hideOld"></div>
                  <div *ngIf="passwordForm.get('currentPassword').touched && passwordForm.get('currentPassword').invalid">
                    <small class="form-text text-danger" *ngIf="passwordForm.get('currentPassword').errors.required">
                      {{ 'COMMON.FORM.REQUIRED' | translate }}
                    </small>
                  </div>
                </div>

                <div class="form-group row d-flex justify-content-center align-items-center">
                  <div class="col-10">
                    <input
                      [type]="hideNew ? 'password' : 'text'"
                      class="form-control value lock"
                      id="newPassword"
                      name="newPassword"
                      formControlName="newPassword"
                      placeholder="nouveau mot de passe"
                      (keyup)="isNewPasswordIsConfirmed()"
                    />
                  </div>
                  <div class="img-fluid col-1" [ngClass]="hideNew ? 'hide' : 'show'" (click)="hideNew = !hideNew"></div>
                  <div *ngIf="passwordForm.get('newPassword').touched">
                    <small
                      class="form-text text-danger"
                      *ngIf="passwordForm.get('newPassword').errors && passwordForm.get('newPassword').errors.required"
                    >
                      {{ 'COMMON.FORM.REQUIRED' | translate }}
                    </small>
                  </div>
                </div>

                <div class="form-group row d-flex justify-content-center align-items-center">
                  <div class="col-10">
                    <input
                      [type]="hideConfirmNew ? 'password' : 'text'"
                      class="form-control value lock"
                      id="confirmPassword"
                      name="confirmPassword"
                      formControlName="confirmPassword"
                      placeholder="confirmation"
                      (keyup)="isNewPasswordIsConfirmed()"
                    />
                  </div>
                  <div
                    class="img-fluid col-1"
                    [ngClass]="hideConfirmNew ? 'hide' : 'show'"
                    (click)="hideConfirmNew = !hideConfirmNew"
                  ></div>
                  <div *ngIf="passwordForm.get('confirmPassword').touched">
                    <small
                      class="form-text text-danger"
                      *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required"
                    >
                      {{ 'COMMON.FORM.REQUIRED' | translate }}
                    </small>
                    <small class="form-text text-danger" *ngIf="!passwordEqual">
                      {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 infos-utilisateur">
              <p class="pt-2">{{ 'ASP.CHANGE_PWD.VALIDATION' | translate }}</p>
              <app-password-validation
                [passwordValue]="passwordForm.get('newPassword').value"
                [currentPassword]="passwordForm.get('currentPassword').value"
                [affLastPassword]="true"
                [progressBar]="true"
              >
              </app-password-validation>
            </div>
          </div>
          <div class="text-center pt-3 btn-change-pwd">
            <!-- <button class="btn btn-secondary" (click)="back()">
                            {{'COMMON.FORM.BACK' | translate | uppercase}}
                        </button>-->
            <button type="submit" class="btn btn-action" [disabled]="!passwordForm.valid || !passwordEqual || !doNotMatch">
              {{ 'COMMON.FORM.SUBMIT' | translate | uppercase }}
            </button>
          </div>
        </section>
      </form>
    </div>
  </div>
</app-apz-layout>
