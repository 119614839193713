import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TealiumService } from '../../../../shared/services/tealium/tealium.service';
import { isEmpty } from '@datorama/akita';

@Component({
  selector: 'app-third-step-apz-component',
  templateUrl: './third-step-apz-component.component.html',
  styleUrls: ['./third-step-apz-component.component.scss',
    '../../create-account-APZ.component.scss',
    '../../../../../assets/css/apz-global.scss']
})
export class ThirdStepApzComponentComponent  implements OnInit {

  @Output() reSend: EventEmitter<any[]> = new EventEmitter();
  @Output() validateCodeSMS: EventEmitter<any[]> = new EventEmitter();
  @Input() messageErreur: string;
  @Input() messageEmail: string;
  @Input() saml: string;
  @Input() typeLogin: string;
  @Input() isMobile: string;


  codeSMS = new FormControl(undefined, [Validators.required]);

  constructor(private router: Router,
    private readonly tealiumService: TealiumService) { }

  ngOnInit() {
    if (!this.isMobile) {
      this.tealiumService.view('apz.web.registrationProcess.accountCreatedEmailPageLoad');
    } else {
      this.tealiumService.view('apz.web.registrationProcess.accountCreatedMobilePageLoad');
    }
  }

  goLogin() {
    this.tealiumService.link('apz.web.registrationProcess.accountCreatedEmailReturnLoginClick');
    this.router.navigateByUrl('/login/' + this.saml);
  }

  reSendEmail() {
    this.tealiumService.link('apz.web.registrationProcess.accountCreatedEmailSendEmailClick');
    this.reSend.emit();
  }

  resendSMS() {
    this.tealiumService.link('apz.web.registrationProcess.accountCreatedMobileSendSMSClick');
    this.reSend.emit();
  }

  validateCode() {
    this.tealiumService.link('apz.mobile.registrationProcess.accountCreatedMobileValidateCodeClick');
    if (this.codeSMS.valid) {
      this.validateCodeSMS.emit(this.codeSMS.value);
    }
  }
}
