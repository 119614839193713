import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TealiumService } from '../../../../shared/services/tealium/tealium.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-third-step-apz-mobile',
  templateUrl: './third-step-apz-mobile.component.html',
  styleUrls: ['./third-step-apz-mobile.component.scss',
    '../../create-account-APZ.component.scss',
    '../../../../../assets/css/apz-global.scss']
})
export class ThirdStepApzMobileComponent implements OnInit {

  @Output() reSend: EventEmitter<any[]> = new EventEmitter();
  @Input() messageEmail: string;
  @Input() typeLogin: string;
  @Input() isMobile: string;
  @Output() validateCodeSMS: EventEmitter<any[]> = new EventEmitter();
  @Input() saml: string;
  @Input() codeInvalide: boolean;

  codeSMS = new FormControl(undefined, [Validators.required, Validators.minLength(6)]);

  constructor(private router: Router,
    private readonly tealiumService: TealiumService) { }

  ngOnInit() {
    if (!this.isMobile) {
      this.tealiumService.view('apz.mobile.registrationProcess.accountCreatedEmailPageLoad');
    } else {
      this.tealiumService.view('apz.mobile.registrationProcess.accountCreatedMobilePageLoad');
    }
  }

  reSendEmail() {
    if (!this.isMobile) {
      this.tealiumService.link('apz.mobile.registrationProcess.accountCreatedEmailSendEmailClick');
    } else {
      this.tealiumService.link('apz.mobile.registrationProcess.accountCreatedMobileSendSMSClick');
    }
    this.reSend.emit();
  }

  goToRedirect() {
    this.tealiumService.link('apz.mobile.registrationProcess.accountCreatedEmailReturnLoginClick');
    window.location.href = 'app.beneficiaire.apetiz://accueil';
  }

  validateCode() {
    this.tealiumService.link('apz.mobile.registrationProcess.accountCreatedMobileValidateCodeClick');
    if (this.codeSMS.valid) {
      this.validateCodeSMS.emit(this.codeSMS.value);
    }
  }

}
