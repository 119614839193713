import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit {
  public accessToken: FormControl;
  public expiresIn: FormControl;
  public scopes: FormControl;
  public tokenType: FormControl;
  private accessTokenValue: string;
  private expiresInValue: string;
  private scopesValue: string;
  private tokenTypeValue: string;


  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.route.fragment.subscribe(fragment => {
      const params = new URLSearchParams(fragment);
      this.accessTokenValue = params.get('access_token');
      this.expiresInValue = params.get('expires_in');
      this.scopesValue = params.get('scopes');
      this.tokenTypeValue = params.get('token_type');
    });
  }

  ngOnInit() {
    this.accessToken = this.formBuilder.control(this.accessTokenValue, Validators.required);
    this.expiresIn = this.formBuilder.control(this.expiresInValue, Validators.required);
    this.scopes = this.formBuilder.control(this.scopesValue, Validators.required);
    this.tokenType = this.formBuilder.control(this.tokenTypeValue, Validators.required);
  }

}
