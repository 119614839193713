<div class="container vw-100 vh-100">
  <div class="logo"><img src="/assets//affilie-apz/logo.svg" /></div>
  <div class="row">
    <div class="col-md-6">
      <img src="/assets//affilie-apz/visuel.svg" />
    </div>
    <div class="col-md-6">
      <div class="d-flex flex-column">
        <div class="titre">Erreur technique</div>
        <div class="oups">Oups…</div>
        <div class="text">
          On dirait qu’il y a un problème technique de notre côté. Revenez plus
          tard. Entre temps vous pouvez visiter notre site web
        </div>
        <button type="button" class="btn btn-primary" (click)="gotoPortal()">
          Visiter notre site web
        </button>
      </div>
    </div>
  </div>
</div>
>
