import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { MerchantSignupRightPanel } from '@shared/enums/merchants-signup-right-panel.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { CheckUserAffiliateAccessRequest } from '@shared/models/check-user-affiliate-access-request';
import { CheckUserAffiliateAccessResponse } from '@shared/models/check-user-affiliate-access-response';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CheckAffiliateAccessService } from '../check-affiliate-access-service';

@Component({
  selector: 'app-unlock-by-bill',
  templateUrl: './unlock-by-bill.component.html',
  styleUrls: ['./unlock-by-bill.component.scss']
})
export class UnlockByBillComponent implements OnInit, OnDestroy {

  @Output() goToPanel$ = new EventEmitter<string>();
  @Output() canAccess$ = new EventEmitter<void>();

  private readonly destroy$ = new Subject<boolean>();
  public readonly title = 'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.BILL.TITLE';
  public readonly linkText = 'AFFILIE_APZ.MERCHANTS_SIGNUP.UNLOCK.BILL.LINK';
  public isBadBill = false;

  public formGroup: FormGroup;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly checkAffiliateAccessService: CheckAffiliateAccessService,
    private readonly gatewayService: GatewayService
    ) {

  }
  ngOnInit(): void {
      this.initForm();
  }

  public onSubmit(): void {
    const checkUserAffiliateAccessRequest = {} as CheckUserAffiliateAccessRequest;
    checkUserAffiliateAccessRequest.billNumber = this.formGroup.get('bill')?.value;

    this.gatewayService
    .getAccessToken()
    .pipe(
      takeUntil(this.destroy$),
      switchMap((token) => {
        return this.checkAffiliateAccessService.checkAffiliateAccess(checkUserAffiliateAccessRequest, token)
        .pipe(
        takeUntil(this.destroy$),
        switchMap((res: CheckUserAffiliateAccessResponse) => {
        this.checkAccess(res?.canAccess);
        return of();
        }),
      );
      })
    ).subscribe();
  }

  public showInfos(): void {
    this.goToPanel$.emit(MerchantSignupRightPanel.BILL_INFOS);
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      bill: [
        { value: '', disabled: false },
        [
          Validators.pattern(/^\d+$/),
          Validators.maxLength(25),
          Validators.required
        ]
      ]
    })
  }

  private checkAccess(canAccess: boolean): void {
    if(canAccess) {
      console.log('can Access bill');
      this.canAccess$.emit();
    }
    else {
      this.isBadBill = true;
    }
  }

 ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.unsubscribe();
 }
}
