<app-cesu-layout>
    <div class="row text-center justify-content-center pt-lg-5 pt-md-4 pt-2">
        <div class="col-12 cesu-page-title">
            Inscription validée !
        </div>
        <hr class="cesu-separator">
        <div class="col-12">
            Un email de confirmation vous a été envoyé.<br/>
            Pour activer votre compte, veuillez cliquer sur le lien présent dans l’email.
        </div>
        <div class="col-12 pt-lg-5 pt-4">
            <object class="cesu-big-icon" type="image/svg+xml" [data]="mailIcon"></object>
        </div>
        <div class="col-12 pt-lg-5 pt-4">
            <button class="btn cesu-button cesu-flat-button" (click)="goToLogin()">Retour à la page de connexion
            </button>
        </div>
    </div>
</app-cesu-layout>
