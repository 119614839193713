import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RegistrationService } from '@services/registration/registration.service';
import { CesuQuery } from '@services/store/cesu/cesu.query';
import { TealiumService } from '@services/tealium/tealium.service';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'app-cesu-third-step',
  templateUrl: './cesu-third-step.component.html',
  styleUrls: ['./cesu-third-step.component.scss', '../../../../../assets/scss/cesu/cesu-global.scss'],
})
export class CesuThirdStepComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public activeStepIcon;
  public notActiveStepIcon;

  public errorMessage;

  public saml: string;
  public login: string;

  public submitted = false;
  public userUpdateForm: FormGroup;

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly tealiumService: TealiumService,
    private readonly registrationService: RegistrationService,
    private readonly sessionQuery: SessionQuery,
    private readonly cesuQuery: CesuQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  public ngOnInit(): void {
    this.activeStepIcon = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cesu/active_step_icon.svg');
    this.notActiveStepIcon = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cesu/not_active_step_icon.svg');

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);

    const loginSubscription = this.cesuQuery.selectedRegistrationLogin.pipe(tap((login) => (this.login = login))).subscribe();

    this.subscription.add(loginSubscription);

    this.userUpdateForm = this.formBuilder.group({
      email: [this.login, [Validators.required, Validators.email]],
      phone: [undefined],
    });

    this.tealiumService.view('cesu.registrationProcess.updateUserContactInfoPageLoad');
  }

  public get form() {
    return this.userUpdateForm.controls;
  }

  public registerUser(): void {
    this.submitted = true;
    this.tealiumService.link('cesu.registrationProcess.validateUpdateUserContactInfoClick');

    // Call update user
    // Updates login and password
    // Update user information
    // Send mail
    if (this.userUpdateForm.valid) {
      this.reCaptchaV3Service.execute('CreateAccountAction').subscribe((captcha) => {
        this.registrationService
          .createAccountAndUpdatePersonalInformation(
            this.cesuQuery.getValue().registrationLogin,
            this.cesuQuery.getValue().registrationPassword,
            '',
            this.cesuQuery.getValue().registrationEmployeeId,
            this.userUpdateForm.value.email,
            this.userUpdateForm.value.phone,
            captcha,
            true,
            null
          )
          .pipe(
            tap(() => {
              this.sessionStateService.setRegistrationStep(RegistrationStepEnum.MAIL_STEP);
              this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_MAIL}/${this.saml}`]);
            }),
            catchError((err) => {
              this.errorMessage = 'Un problème est survenu lors de la mise à jour de vos données. Merci de contacter le support.';
              throw err;
            })
          )
          .subscribe();
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
