import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStateService } from '@services/store/session/session-state.service';
import { SessionQuery } from '@services/store/session/session.query';
import { SessionStore } from '@services/store/session/session.store';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-change-pwd-success',
  templateUrl: './change-pwd-success.component.html',
  styleUrls: ['./change-pwd-success.component.scss'],
})
export class ChangePwdSuccessComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;

  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionStore: SessionStore,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
  ) {
  }

  ngOnInit(): void {
    this.sessionStateService.setApplicationCodeFromChangePassword(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();

    // Removes the changePassword info from the store
    this.sessionStore.update({ changePasswordInfo: undefined });
  }

}
