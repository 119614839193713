<app-cesu-layout>
  <div class="row text-center justify-content-center pt-2 pt-md-4 pt-lg-5">
    <div class="col-12 cesu-page-title">
      Mise à jour de vos informations personnelles
    </div>
    <hr class="cesu-separator" />
    <div class="col-12">
      Vous pouvez mettre à jour vos informations personnelles si elles<br />
      ne sont pas exactes ou incomplètes.
    </div>
    <div class="col-lg-4 col-md-6 col-12 pt-4 pt-lg-5" *ngIf="errorMessage">
      <div class="alert alert-danger">
        {{ errorMessage }}
      </div>
    </div>
  </div>

  <form class="row pt-4 pt-lg-5" [formGroup]="userUpdateForm" (ngSubmit)="registerUser()">
    <div class="col-12 form-group">
      <div class="row no-gutters justify-content-center">
        <label class="col-12 col-md-4 col-lg-2 col-form-label align-self-center">
          Email Personnel<span class="float-md-right">&nbsp;*&nbsp;</span>
        </label>
        <div class="col-12 col-md-8 col-lg-5">
          <input
            class="form-control cesu-input"
            [ngClass]="{ 'is-invalid': submitted && form.email.errors }"
            type="text"
            placeholder="martin.dupont@laposte.net"
            formControlName="email"
          />
          <div *ngIf="submitted && form.email.errors" class="invalid-feedback cesu-error">
            <div *ngIf="form.email.errors.required">
              <img src="../../../../../assets/cesu/error_icon.svg" alt="Error" />
              &nbsp;L'email obligatoire
            </div>
            <div *ngIf="form.email.errors.email">
              <img src="../../../../../assets/cesu/error_icon.svg" alt="Error" />
              &nbsp;L'email doit être au bon format
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 form-group">
      <div class="row no-gutters justify-content-center">
        <label class="col-12 col-md-4 col-lg-2 align-self-center">Téléphone personnel</label>
        <div class="col-12 col-md-8 col-lg-5">
          <input class="form-control cesu-input" type="tel" placeholder="0612345678" formControlName="phone" />
        </div>
      </div>
    </div>
    <div class="col-12 text-center pt-4 pt-lg-5">
      <button class="btn cesu-button cesu-flat-button" type="submit">Valider</button>
    </div>
  </form>

  <div class="row justify-content-center pt-4 pt-lg-5">
    <div class="col-auto">
      <object type="image/svg+xml" [data]="notActiveStepIcon"></object>
    </div>
    <div class="col-auto">
      <object type="image/svg+xml" [data]="notActiveStepIcon"></object>
    </div>
    <div class="col-auto">
      <object type="image/svg+xml" [data]="activeStepIcon"></object>
    </div>
  </div>
</app-cesu-layout>
