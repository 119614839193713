import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-credit-card',
  templateUrl: './input-credit-card.component.html',
  styleUrls: ['../../../../assets/css/bimpli-global.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCreditCardComponent),
      multi: true
    }
  ],
})

export class InputCreditCardComponent implements ControlValueAccessor {
  @Input() val: string;
  @Input() disabled: boolean;
  value: string;

  changeCallback = (data: any) => { };

  touchCallback = () => { };

  onChange(event) {
    this.changeCallback(event.target.value.replace(/ /g, ''));
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.changeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.touchCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
