<div class="container-fluid vw-100  p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3 position-fixed">
        <div class="d-flex flex-column">
            <span class="title-bp">Happi !</span>
            <span class="sub-title-bp" *ngIf="type=== 'comiteo'">Vous avez sélectionné <br>Espace entreprise et bons plans !</span>
            <span class="sub-title-bp" *ngIf="type=== 'cado'">Vous avez sélectionné <br>Cado !</span>
        </div>
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()" *ngIf="!isMobile">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Identifiants Bimpli</span>
            <span class="create-subtitle mt-4 mb-4">Définissez vos identifiants Bimpli.</span>

            <form [formGroup]="createAccountForm" (keydown.enter)="validate()">
                <div class="inner_page">
                    <div class="input_group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control" name="email" placeholder="Saisir votre email" id="email"
                               formControlName="login" maxlength="70" (focus)="[alreadyUsedMail=false]"
                               onkeyup="this.value = this.value.toLowerCase();">
                        <div *ngIf="(createAccountForm.get('login').dirty && createAccountForm.get('login').errors) || alreadyUsedMail">
                            <small class="form-text text-danger" *ngIf="createAccountForm.get('login').errors?.required">Email
                                requis</small>
                            <small class="form-text text-danger" *ngIf="createAccountForm.get('login').errors?.pattern">Email
                                invalide</small>
                            <small class="form-text text-danger" *ngIf="alreadyUsedMail">
                                {{'COMMON.FORM.EXISTING_MAIL_ERROR_1' | translate}}
                                <a routerLink="/authent">{{'LOGIN.MDP_FORGOT' | translate}}</a>
                                {{'COMMON.FORM.EXISTING_MAIL_ERROR_2' | translate}}
                            </small>
                        </div>
                    </div>
                    <div class="input_group">
                        <label for="confirm_email">Confirmation Email</label>
                        <input type="email" class="form-control" name="confirm_email" placeholder="Confirmer votre email"
                               id="confirm_email" formControlName="confirm_email"
                               maxlength="70" [class]="{'ng-valid': isConfirmMailValid}"
                               onkeyup="this.value = this.value.toLowerCase();">
                        <div *ngIf="(createAccountForm.get('confirm_email').dirty || createAccountForm.get('login').dirty) &&
                (createAccountForm.get('confirm_email').value.toUpperCase() != createAccountForm.get('login').value.toUpperCase() )">
                            <small class="form-text text-danger"
                                   *ngIf="createAccountForm.get('confirm_email').value.toUpperCase() != createAccountForm.get('login').value.toUpperCase()">Email de confirmation invalide</small>
                        </div>
                    </div>
                    <div class="input_group">
                        <label for="password">Mot de passe</label>
                        <div class="input-group">
                            <input [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="password" placeholder="Saisir votre mot de passe" id="password"
                                   formControlName="password" maxlength="60" minlength="8">
                            <div class="input-group-append">
                                <button
                                    *ngIf="(isPasswordDisplayed$ | async) === false"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isPasswordDisplayed$.next(true)">
                                    <i class="bi bi bi-eye-fill"></i>
                                </button>
                                <button
                                    *ngIf="(isPasswordDisplayed$ | async) === true"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isPasswordDisplayed$.next(false)">
                                    <i class="bi bi-eye-slash-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="error-message p-4 mb-4">
                        <div class="validation mb-4">Validation de votre mot de passe : </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : this.isLessThanEightChars(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                          'icon-check' : !this.isLessThanEightChars(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.EIGHT_CHARS' | translate}}</span>
                            </i>
                        </div>
                        <!--
                                      <div class="message_item">
                                          <i [ngClass]="{'icon-cross' : this.isMoreThanSixtyChars(createAccountForm.get('password').value) || !createAccountForm.get('password').dirty,
                                    'icon-check' : !this.isMoreThanSixtyChars(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                            <span>Au plus 60 caractère</span>
                          </i>
                        </div>
                        -->
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneLowerCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                          'icon-check' : this.hasAtLeastOneLowerCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.LOWER_CASE' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneUpperCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                'icon-check' : this.hasAtLeastOneUpperCase(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.UPPER_CASE' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneDigit(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                'icon-check' : this.hasAtLeastOneDigit(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.DIGIT' | translate}}</span>
                            </i>
                        </div>
                        <div class="message_item">
                            <i [ngClass]="{'icon-cross' : !this.hasAtLeastOneSpecialChar(createAccountForm.get('password').value) && createAccountForm.get('password').dirty || !createAccountForm.get('password').dirty,
                'icon-check' : this.hasAtLeastOneSpecialChar(createAccountForm.get('password').value) && createAccountForm.get('password').dirty}">
                                <span>{{'COMMON.FORM.PASS_ERR.SPECIAL_CHAR' | translate}}</span>
                            </i>
                        </div>
                    </div>
                    <div class="input_group">
                        <label for="confirm_password">Confirmation du mot de passe</label>
                        <div class="input-group">
                            <input [type]="(isConfirmPasswordDisplayed$ | async) === false ? 'password' : 'text'" class="form-control" name="confirm_password"
                                   placeholder=" Saisir à nouveau votre mot de passe" id="confirm_password"
                                   formControlName="confirm_password">
                            <div class="input-group-append">
                                <button
                                    *ngIf="(isConfirmPasswordDisplayed$ | async) === false"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isConfirmPasswordDisplayed$.next(true)">
                                    <i class="bi bi bi-eye-fill"></i>
                                </button>
                                <button
                                    *ngIf="(isConfirmPasswordDisplayed$ | async) === true"
                                    type="button"
                                    class="btn input-group-text"
                                    (click)="isConfirmPasswordDisplayed$.next(false)">
                                    <i class="bi bi-eye-slash-fill"></i>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="createAccountForm.get('confirm_password').dirty">
                            <small class="form-text text-danger"
                                   *ngIf="createAccountForm.get('confirm_password').value != createAccountForm.get('password').value">Mot de passe de confirmation invalide</small>
                        </div>
                    </div>

                    <div class="error-message p-4 mb-4">
                        <div class="form-check pl-0 row align-items-center justify-content-center">
                            <input class="mr-2 ml-2 form-check-input" type="checkbox" formControlName="acceptCGU" id="acceptCGU"/>
                            <label class="col cgu" for="acceptCGU"> En cochant cette case vous acceptez les <a (click)="getCGU()">Conditions générales d'utilisation</a> de Bimpli.</label>
                        </div>
                    </div>
                </div>
                <button type="button" class="mb-4 btn btn-primary btn-block" [disabled]="!createAccountForm.valid" (click)="validate()">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div *ngIf="isLoading" class="spinner-border"></div>
                        <span [ngClass]="{'ml-2': isLoading }">Continuer</span>
                    </div>
                </button>
            </form>
        </div>
    </div>
</div>
