<div class="apz-container">
  <!-- navbar -->
  <nav class="apz-nav">
    <div class="rectangle" (click)="goLogin()">apetiz</div>
  </nav>

  <!--  en tête -->
  <section class="row apz-entete">
    <a class="col back" (click)="goBack()" *ngIf="showBack"> <img src="../../../assets/apz/arrow-back.png" class="pr-2" />Retour</a>
    <div class="col-12 title">
      {{ title }}
    </div>
  </section>

  <!-- body -->
  <section class="apz-body">
    <ng-content></ng-content>
  </section>

  <!-- footer -->
  <section class="col-12 apz-footer">
    <a (click)="getMentionsLegales()">Mentions Légales</a>
    <a (click)="getRGPD()">Données RGPD</a>
    <a (click)="getCGU()">Données CGU</a>
    <small class="text-white version">Version : {{ version }}</small>
  </section>
</div>
