<div id="sign-in-container" class="container-fluid vw-100 vh-100 p-0">
    <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
        <img id="logo" class="mb-2" src='../../../../assets/bimpli/logo.svg' alt="Logo" />
        <div *ngIf="libelleType" class="type-compte">
            {{libelleType}}
        </div>
        <div class="card shadow mt-4">
            <span *ngIf="!rememberLogin && !askOTP" class="create d-none d-sm-block">Bonjour !</span>

            <form #f="ngForm" class="h-100 w-100" role="form"(submit)="connect(f)" (keydown.enter)="connect(f)">
                <div class="d-flex flex-column w-100">
                    <div *ngIf="rememberLogin && !askOTP" id="title" class="create">Bonjour {{knownFirstName | titlecase}} !</div>
                    <div *ngIf="rememberLogin && !askOTP" class="mt-2 login-reminder">{{knownLogin}}</div>
                    <div class="d-flex flex-column w-100 mt-3">
                        <!-- Login -->
                        <div *ngIf="!rememberLogin && !askOTP" class="form-group">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <label for="login">Identifiant</label>
                                <a class="link" (click)="forgotLogin()">Identifiant oublié ?</a>
                            </div>

                            <input type="text" class="form-control" name="login"
                                id="login" [formControl]="login" placeholder="Saisissez votre identifiant"/>

                            <div *ngIf="login.touched && login.invalid">
                                <small class="text-danger error-msg" *ngIf="login.errors.required">
                                    {{ 'COMMON.FORM.REQUIRED' | translate }}
                                </small>
                                <small class="text-danger  error-msg" *ngIf="login.errors.pattern">
                                    {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
                                </small>
                            </div>
                        </div>

                        <div class="d-flex flex-column w-100">
                            <!-- Password -->
                            <div *ngIf="!askOTP" class="form-group d-flex flex-column">
                                <div class="d-flex flex-row align-items-center justify-content-between">
                                    <label for="password">{{ 'AFFILIE_APZ.LOGIN.PASSWORD' | translate }}</label>
                                    <a class="link" (click)="forgotPassword()">{{ 'AFFILIE_APZ.LOGIN.FORGOT_PASSWORD' | translate }}</a>
                                </div>
                                <div class="input-group">
                                    <input
                                        [type]="(isPasswordDisplayed$ | async) === false ? 'password' : 'text'"
                                        class="form-control" name="password" id="password"
                                        [formControl]="password"
                                        placeholder="{{ 'AFFILIE_APZ.LOGIN.PASSWORD_PROMPT' | translate }}"
                                    />
                                    <div class="input-group-append">
                                        <button
                                            *ngIf="(isPasswordDisplayed$ | async) === false"
                                            type="button"
                                            class="btn input-group-text"
                                            (click)="isPasswordDisplayed$.next(true)"
                                        >
                                            <i class="bi bi bi-eye-fill"></i>
                                        </button>
                                        <button
                                            *ngIf="(isPasswordDisplayed$ | async) === true"
                                            type="button"
                                            class="btn input-group-text"
                                            (click)="isPasswordDisplayed$.next(false)"
                                        >
                                            <i class="bi bi-eye-slash-fill"></i>
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="password.touched && password.invalid">
                                    <small class="text-danger  error-msg" *ngIf="password.errors.required">
                                        {{ 'COMMON.FORM.REQUIRED' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-column w-100">
                            <!-- OTP -->
                            <div *ngIf="askOTP" class="form-group d-flex flex-column">
                                <div class="d-flex flex-row align-items-center justify-content-between">
                                    <label for="otp">{{ otpType === "OTPMAIL" ? ('AFFILIE_APZ.LOGIN.OTPMAIL' | translate : {maskedEmail : this.maskedEmail})
                                        :  ('AFFILIE_APZ.LOGIN.OTPSMS' | translate : {maskedPhone : this.maskedPhone}) }}</label>
                                </div>
                                <div class="input-group">
                                    <input
                                        type='text'
                                        class="form-control"
                                        name="otp"
                                        id="otp"
                                        maxlength="6"
                                        (input)="onInput($event)"
                                        [formControl]="otp"
                                        placeholder="{{ 'AFFILIE_APZ.LOGIN.OTP_PROMPT' | translate }}"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Remember -->
                        <div *ngIf="!isMobile && !askOTP" class="pt-2 d-flex flex-row align-items-center justify-content-start">
                            <div class="form-check pl-0">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="remember-me"
                                    [(ngModel)]="checkBoxRember"
                                    [ngModelOptions]="{ standalone: true }"
                                />
                                <label class="form-label mb-0 ml-1" for="remember-me">{{ 'AFFILIE_APZ.LOGIN.REMEMBER' | translate }}</label>
                            </div>
                        </div>

                        <!-- Submit -->
                        <div class="pt-4 d-flex flex-row align-items-center justify-content-center">
                            <button type="button" class="btn btn-primary btn-block" [disabled]="!f.valid || isLoading" (click)="connect(f)">
                                <div class="d-flex flex-row align-items-center justify-content-center">
                                    <div *ngIf="isLoading" class="spinner-border"></div>
                                    <span
                                        [ngClass]="{
                      'ml-2': isLoading
                    }"
                                    >{{ 'COMMON.USER.CONNECT' | translate }}</span
                                    >
                                </div>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="!askOTP" class="d-flex flex-row justify-content-center align-items-center">
                        <hr color="#a7a8a9" width="100%">
                    </div>

                    <div  class="error-message p-2" *ngIf="(rememberLogin || !isMobile) && !askOTP">
                        <label style="font-weight: bold" *ngIf="!rememberLogin && !isMobile">Créer un compte</label>
                        <!-- New affiliate -->
                        <div *ngIf="rememberLogin" class="new-affiliate-container" class="d-flex flex-row align-items-center">
                            <span class="new-affiliate-label"> Vous n’êtes pas {{knownFirstName | titlecase}} ?</span>
                            <a class="register" class="link ml-3" (click)="saisirAutreLogin()">Changer de compte</a>
                        </div>
                        <div *ngIf="!rememberLogin && !isMobile  && !(libelleType === 'ENTREPRISE')" class="new-affiliate-container" class="d-flex flex-row align-items-center">
                            <span class="new-affiliate-label"> Première connexion ?</span>
                            <a class="register" class="link ml-3" (click)="register()">Je m’inscris !</a>
                        </div>
                        <div *ngIf="libelleType === 'ENTREPRISE'" class="new-affiliate-container" class="d-flex flex-row align-items-center">
                            <span class="new-affiliate-label"> Première connexion ?</span>
                            <a class="register" class="link ml-3" (click)="register()">Créer un compte</a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<app-modal id="infoModal">
    <div class="float-right">
        <button type="button" class="btn text-right" (click)="closePopin('infoModal')">
            <img src="assets/images/close.svg" class="img-fluid quit" alt="" />
        </button>
    </div>
    <div class="popin text-center">
        <div class="icon-container">
            <img class="img-fluid" src="/assets/images/info.svg" />
        </div>
        <div>{{ messageInfo }}</div>
        <div class="actions-container">
            <button type="button" class="btn btn-action btn-login"
                    data-dismiss="modal" (click)="closePopin('infoModal')">FERMER</button>
        </div>
    </div>
</app-modal>

<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>
