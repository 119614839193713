import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GroundTruthDataService } from '@services/ground-truth-data.service';
import { mailRegex } from 'nit-angular-lib';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-affilie-apz-login',
  templateUrl: './affilie-apz-login.component.html',
  styleUrls: ['../../../../assets/css/affilie-apz-global.scss', './affilie-apz-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AffilieApzLoginComponent implements OnInit, OnChanges, OnDestroy {
  // Variables
  @Output() signIn: EventEmitter<any> = new EventEmitter();
  @Output() register: EventEmitter<any> = new EventEmitter();
  @Output() resetPassword: EventEmitter<any> = new EventEmitter();
  @Input() errorMessage: string;
  @Input() isAlreadyActived: boolean;

  signInForm: FormGroup = this.fb.group({
    login: ['', [Validators.required, Validators.pattern(mailRegex)]],
    password: ['', Validators.required]
  });

  isLoading = false;
  mySubscription: Subscription = new Subscription();

  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isRememberMe = false;

  errorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // Constructor
  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private gtds: GroundTruthDataService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {
    const isSpinnerActivated = this.gtds
      .getIsSpinnerActivated()
      .pipe(distinctUntilChanged())
      .subscribe((response: boolean) => {
        this.isLoading = response;
        this.cdr.markForCheck();
      });

    this.mySubscription.add(isSpinnerActivated);
    this.setRememberMeInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.errorMessage && changes.errorMessage.currentValue) {
      this.errorMessage$.next(changes.errorMessage.currentValue);
    }
  }

  // Methods
  ngOnInit(): void {
    this.mySubscription.add(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.errorMessage$.next(
          params.isKnowAffiliate && params.isKnowAffiliate === 'true'
            ? this.translateService.instant('AFFILIE_APZ.LOGIN.ALREADY_EXISTING')
            : ''
        );
        this.cdr.markForCheck();
      })
    );
  }

  submit(): void {
    this.gtds.setIsSpinnerActivated(true);
    this.isAlreadyActived = false;
    if (this.signInForm.valid) {
      this.signIn.emit(this.signInForm.getRawValue());
    } else {
      this.errorMessage$.next(this.translateService.instant('AFFILIE_APZ.LOGIN.INVALID_FORM'));
    }
  }

  firstConnexion(): void {
    this.register.emit(null);
  }

  requestResetPassword(): void {
    this.resetPassword.emit(null);
  }

  rememberMe(): void {
    localStorage.setItem('rememberMe', String(this.isRememberMe));
    if (this.isRememberMe) {
      localStorage.setItem('dataRememberMe', this.signInForm.get('login').value);
    } else {
      if (localStorage.getItem('dataRememberMe')) {
        localStorage.removeItem('dataRememberMe');
      }
    }
  }

  private setRememberMeInfo(): void {
    if (localStorage.getItem('rememberMe') === 'true') {
      this.isRememberMe = true;
      this.signInForm.get('login').setValue(localStorage.getItem('dataRememberMe'));
    }
  }

  ngOnDestroy(): void {
    this.mySubscription.unsubscribe();
  }
}
