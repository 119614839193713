<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN">
  <app-third-step-asp-component (reSend)="reSendEmail()" [messageEmail]="messageEmail"> </app-third-step-asp-component>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE">
  <app-third-step-apz-mobile
    (reSend)="reSendEmail()"
    [saml]="saml"
    [isMobile]="isMobile"
    [typeLogin]="typeLogin"
    [codeInvalide]="codeInvalide"
    (validateCodeSMS)="validateCodeSMS($event)"
  >
  </app-third-step-apz-mobile>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ">
    <app-third-step-apz-component
        (reSend)="reSendEmail()"
        [messageEmail]="messageEmail"
        [saml]="saml"
        [isMobile]="isMobile"
        [typeLogin]="typeLogin"
        [messageErreur]="messageErreur"
        (validateCodeSMS)="validateCodeSMS($event)"
    >
    </app-third-step-apz-component>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_FINANCEUR
    || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE">
  <app-third-step-bimpli-apetiz
    (reSend)="reSendEmail()"
    [messageEmail]="messageEmail"
    [email]="email"
    [compteur]="compteur"
    [saml]="saml"
    [applicationId]="applicationId"
    [type]="type"
    [isMobile]="(applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE"
    [typeLogin]="typeLogin"
    [messageErreur]="messageErreur"
    (validateCodeSMS)="validateCodeSMS($event)"
  >
  </app-third-step-bimpli-apetiz>
</div>

<div *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
  <app-cesu-third-step></app-cesu-third-step>
  <app-cesu-footer></app-cesu-footer>
</div>

<div class="app-affilie-apz-component" *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.AFFILIE_APZ">
  <app-third-step-affilie-apz-component [saml]="saml" (reSend)="reSendEmail()"> </app-third-step-affilie-apz-component>
</div>
