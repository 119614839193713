<div id="pagination-container">
    <nav class="d-flex justify-content-center">
        <ul class="pagination">
            <li class="page-item btn p-0" [ngClass]="isFirstPage() ? 'disabled' : ''">
                <a class="page-link d-flex justify-content-center align-items-center" (click)="isFirstPage() ? null : previousPage()">
                    <span class="material-icons"> arrow_back_ios </span>
                </a>
            </li>
            <li class="page-item btn p-0 ml-2" *ngFor="let num of getPages()">
                <a
                    class="page-link d-flex justify-content-center align-items-center"
                    (click)="changePage(num)"
                    [ngClass]="isCurrentPage(num) ? 'isActive' : 'isNotActive'"
                >
                    <span>{{ num }}</span></a
                >
            </li>
            <li class="page-item btn p-0 ml-2" [ngClass]="isLastPage() ? 'disabled' : ''">
                <a class="page-link d-flex justify-content-center align-items-center" (click)="isLastPage() ? null : nextPage()">
                    <span class="material-icons"> arrow_forward_ios </span>
                </a>
            </li>
        </ul>
    </nav>
</div>
