<div class="img-row">
    <a><img src=" ../../../assets/asp/logo_wee.png" class="logo-img" (click)="connection()"/></a>
</div>
<div class="header d-flex align-items-center justify-content-center">
    <p class="title">{{'CREATION_ACCOUNT.TITLE' | translate}}</p>
</div>
<div class="d-flex background-blanc justify-content-center align-items-center m-4">
    <div class="row images-header d-flex justify-content-between align-items-center">
        <i class="icon-carte icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
        <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
        <i class="icon-profil icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
        <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
        <i class="icon-envoi-mail icon-not-active rounded-circle d-flex align-items-center justify-content-center"></i>
        <i class="icon-arrow d-flex align-items-center justify-content-center"></i>
        <i class="icon-active-card icon rounded-circle d-flex align-items-center justify-content-center"></i>
    </div>
</div>

<div class="container detail-container">
    <div class="text-center">
        <div class="row">
            <div class="col-12 title-card">
                {{'CREATION_ACCOUNT.FOURTH_STEP.ACTIVE' | translate}}
            </div>
            <div class="col-12 sub-title pt-4">
                {{'CREATION_ACCOUNT.FOURTH_STEP.CONNEXION' | translate}}
            </div>
            <div class="col-12 pt-4 actions">
                <button class="btn btn-primary" type="button" (click)="connection()">
                        {{'CREATION_ACCOUNT.FOURTH_STEP.CONNECTER' | translate}}
                </button>   
            </div>
        </div>
    </div>                
</div>
    
