<div id="pagination" class="mb-0">
    <app-pagination-less-n
        *ngIf="totalPages < paginationN"
        [totalPages]="totalPages!"
        (currentPageEmitter)="updatePageNumber($event)"
        class="mt-4"
    ></app-pagination-less-n>
    <app-pagination-equals-n
        *ngIf="totalPages === paginationN"
        class="mt-4"
        [totalPages]="totalPages"
        (currentPageEmitter)="updatePageNumber($event)"
    ></app-pagination-equals-n>
    <app-pagination-greater-n
        *ngIf="totalPages > paginationN"
        [totalPages]="totalPages"
        class="mt-4"
        (currentPageEmitter)="updatePageNumber($event)"
    ></app-pagination-greater-n>
</div>
