import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { TealiumService } from '@services/tealium/tealium.service';
import { ModalService } from '@shared/components/modal/modal.service';
import { CustomAdapter } from '@shared/custom/date.adapter';
import { CustomDateParserFormatter } from '@shared/custom/date.formater';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { nameRegEx } from '@shared/helpers/regexp.helpers';
import { AuthToken } from '@shared/models/authtoken.model';
import { CreateTermsAndConditions } from '@shared/models/createTermsAndConditions.model';
import { isAdultValidator } from '@shared/validators/custom.validators';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { error } from 'protractor';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-second-step-bimpli-cado',
  templateUrl: './second-step-bimpli-cado.component.html',
  styleUrls: ['./second-step-bimpli-cado.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class SecondStepBimpliCadoComponent implements OnInit {

  @Input() saml: string;
  @Input() application: string;
  token: AuthToken;
  userId: number;
  isLoading = false;
  affCodeActivation = false;
  valueCaptcha: string;


  constructor( private readonly formBuilder: FormBuilder,
               private readonly gatewayService: GatewayService,
               private readonly siloApiService: SiloApiService,
               private readonly router: Router,
               private readonly modalService: ModalService, private readonly tealiumService: TealiumService,
               private readonly reCaptchaV3Service: ReCaptchaV3Service
              ) {
    this.step = 1;
  }

  messageErreur: string;
  step: number;
  scheduledStartDate: any;
  individualInfosForm = this.formBuilder.group({
    title: ['MR', []],
      firstName: ['',
        [
          Validators.required,
          Validators.maxLength(30)
        ]],
      lastName: ['',
        [
          Validators.required,
          Validators.maxLength(30)
        ]]
    });
  profilForm = this.formBuilder.group({
    birthdate: ['',
      [
        Validators.required,
        isAdultValidator
      ]],
    cellPhoneNumber: ['',
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^[0][6-7][0-9]{8}$/)
      ]]

  });

  activationForm = this.formBuilder.group({
    card: this.formBuilder.group({
      id: ['', [Validators.required, this.validateIdCode()]],
      activationCode: [''],
    }),
    smsOptin: [false, []],
    mailOptin: [false, []]
  });

  ngOnInit(): void {
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.application) {
      this.tealiumService.view('bimpli_benef.web.register.cado.personnalIdPageLoad');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.application) {
      this.tealiumService.view('bimpli_mobile.web.register.cado.personnalIdPageLoad');
    }
    this.scheduledStartDate = {
      year: new Date().getFullYear() - 37,
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
  }

  validateIdCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueLength: number = control.value.length;
      if (valueLength < 19) {
        return { tooShort: { value: 19 - valueLength } };
      } else if (valueLength > 19) {
        return { tooLong: { value: valueLength - 19 } };
      } else {
        return null;
      }
    };
  }

  numericPhoneOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  formatName(event): boolean {
    const patt = nameRegEx;
    return patt.test(event.key);
  }

  goToStep2(numberStep: number): void {
    if (this.step === 2) {
      if (ApplicationCodeEnum.BENEFIT_BENEF === this.application) {
        this.tealiumService.view('bimpli_benef.web.register.cado.finalizeProfilPageLoad');
      } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.application) {
        this.tealiumService.view('bimpli_mobile.web.register.cado.finalizeProfilPageLoad');
      }
    }
    if (this.step === 3) {
      if (ApplicationCodeEnum.BENEFIT_BENEF === this.application) {
        this.tealiumService.view('bimpli_benef.web.register.cado.cadoCardPageLoad');
      } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.application) {
        this.tealiumService.view('bimpli_mobile.web.register.cado.cadoCardPageLoad');
      }
    }
    this.step = numberStep;
  }

  back(): void {
    if (this.step === 2) {
      this.step = 1;
    } else  if (this.step === 3) {
      this.step = 2;
    } else {
      window.history.back();
    }
  }

  createAccount(): void {
    this.isLoading = true;
    const birthdate = {birthDate: this.profilForm.get('birthdate').value};
    const equipmentCADO = {equipmentCADO: Object.assign(birthdate, this.activationForm.getRawValue())};

    // TODO ADO: à supprimer
    delete equipmentCADO.equipmentCADO.smsOptin;
    delete equipmentCADO.equipmentCADO.mailOptin;



    const emailLocal = localStorage.getItem('login');
    const passwordLocal = localStorage.getItem('password');
    const passwordLocalCado = localStorage.getItem('passwordCado');
    const firstStep = {login: emailLocal, password: passwordLocal, passwordCado: passwordLocalCado, email: emailLocal
      , providerId: 'TC', applicationId: this.application};
    const body = Object.assign(this.profilForm.getRawValue(), equipmentCADO , this.individualInfosForm.getRawValue(), firstStep);

    // On supprime l'objet birthdate car il est présent dans l'objet equipementCADO
    delete body.birthdate;

     // on n'ajoute plus la carte sur la création du compte
     delete body.equipmentCADO.card;

    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.token = authToken;

      this.reCaptchaV3Service
      .execute('CreationAccountAction')
      .pipe(finalize(() => this.createUserAccount(body)))
      .subscribe((token) => (this.valueCaptcha = token));

    });
  }

  createUserAccount(body: any): void {
    this.siloApiService.createExternalUser(this.token, body, this.valueCaptcha).subscribe(
        (userId) =>  this.createTermsAndConditionsAcceptances(userId),
        (error1) => {
          this.gestionErreur(error1);
        }
      );
  }

  private createTermsAndConditionsAcceptances(userId) {
    this.userId = userId.id;
    localStorage.setItem('id', this.userId.toString());

    const profileTypeLocal = 'EMPLOYEE';

    const body: CreateTermsAndConditions = {
      profileType: profileTypeLocal,
      applicationId: this.application
    };
    this.siloApiService
      .createTermsAndConditionsAcceptances(userId.id, body,  this.token)
      .subscribe(
        () => {
          // Go to step 3
          this.isLoading = false;
          localStorage.removeItem('password');
          this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}/cado`]);
        },
        (error2) => {
          this.isLoading = false;
          console.log(error2);
        }
      );
  }

  closePopin(id: string) {
    this.modalService.close(id);
  }

  // tslint:disable-next-line:no-shadowed-variable
  private gestionErreur(error: any) {
    this.isLoading = false;
    if (error.error.errors[0].attribute === 'codeActivation') {
      this.affCodeActivation = true;
    } else if ( error.error.errors[0].code === 'IMPOSSIBLE_ACTION_013'
        && error.error.errors[0].additionalInformation.message === 'card already attached')  {
      this.messageErreur = 'La carte cado est déjà rattachée à un compte.';
      this.modalService.open('errorModal');
    } else if ( error.error.errors[0].code === 'IMPOSSIBLE_ACTION_013'
      && error.error.errors[0].additionalInformation.message === 'card not found')  {
      this.messageErreur = 'Carte non trouvée.';
      this.modalService.open('errorModal');
    } else {
      this.messageErreur = error.error.errors[0].message;
      this.modalService.open('errorModal');
    }
  }

  openPopUp(popUpCodeActivation: string) {
    this.modalService.open(popUpCodeActivation);
  }
}

