import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GatewayService } from '@services/gateway.service';
import { SiloApiService } from '@services/siloApi.service';
import { validatePassword } from '@shared/components/password-validation/password.helper';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { finalize } from 'rxjs/operators';
import { TealiumService } from '../../../../shared/services/tealium/tealium.service';

@Component({
  selector: 'app-apz-change-pwd',
  templateUrl: './apz-change-pwd.component.html',
  styleUrls: ['./apz-change-pwd.component.scss', '../../../../../assets/css/apz-global.scss']
})
export class ApzChangePwdComponent implements OnInit {
  passwordEqual = false;
  isSecondStep = false;
  hide = true;
  hide2 = true;
  hide3 = true;
  currentPasswordInvalid = false;
  private valueCaptcha: string;

  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() retour: EventEmitter<any[]> = new EventEmitter();
  @Input() errorMessage: string;
  @Input() passwordOk: boolean;
  @Input() login: string;

  passwordForm = this.fb.group({
    currentPassword: [undefined, Validators.required],
    newPassword: [undefined, [validatePassword(), Validators.required]],
    confirmPassword: [undefined, [validatePassword(), Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    private siloApiService: SiloApiService,
    private gatewayService: GatewayService,
    private readonly tealiumService: TealiumService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    this.tealiumService.view('apz.mobile.profile.changePasswordPageLoad');
  }

  controlPassWord() {
    this.passwordEqual = this.passwordForm.get('newPassword').value === this.passwordForm.get('confirmPassword').value;
  }

  back() {
    this.retour.emit();
  }

  submitForm() {
    this.tealiumService.link('apz.mobile.profile.validateChangePasswordChoosePasswordClick');
    if (this.passwordForm.valid) {
      this.submitted.emit(this.passwordForm.getRawValue());
    }
  }

  goToSecondStep() {
    this.tealiumService.link('apz.mobile.profile.validateChangePasswordClick');
    if (this.passwordForm.get('currentPassword').valid) {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.reCaptchaV3Service
          .execute('UserPasswordCheckAction')
          .pipe(
            finalize(() => {
              this.siloApiService
                .userPasswordCheck(
                  this.login,
                  this.passwordForm.get('currentPassword').value,
                  'SiteAPZMobile',
                  authToken,
                  this.valueCaptcha
                )
                .subscribe((retour) => {
                  this.currentPasswordInvalid = !retour.resultCheck;
                  this.isSecondStep = !this.currentPasswordInvalid;
                  if (this.isSecondStep) {
                    this.tealiumService.view('apz.mobile.profile.changePasswordChoosePasswordPageLoad');
                  }
                });
            })
          )
          .subscribe((token) => (this.valueCaptcha = token));
      });
    }
  }
}
