import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { ActivationCompteComponent } from './features/activation-compte/activation-compte.component';
import { ActivationSmsComponent } from './features/activation-sms/activation-sms.component';
import { AuthentificationComponent } from './features/authentification/authentification.component';
import { CguComponent } from './features/cgu/cgu.component';
import { ChangementIdentifiantComponent } from './features/changement-identifiant/changement-identifiant.component';
import { ChangePwdSuccessComponent } from './features/changement-mot-de-passe/change-pwd-success/change-pwd-success.component';
import { ChangementMotDePasseComponent } from './features/changement-mot-de-passe/change-pwd/changement-mot-de-passe.component';
// tslint:disable-next-line: max-line-length
import { ConfirmationDemandeMotDePasseSmsComponent } from './features/confirmation-demande-mot-de-passe-sms/confirmation-demande-mot-de-passe-sms.component';
// tslint:disable-next-line: max-line-length
import { ConfirmationDemandeMotDePasseComponent } from './features/confirmation-demande-mot-de-passe/confirmation-demande-mot-de-passe.component';
import { FirstStepComponent } from './features/create-account/first-step/first-step.component';
import { FourthStepComponent } from './features/create-account/fourth-step/fourth-step.component';
import { CreateAccountMailComponent } from './features/create-account/mail/create-account-mail.component';
import { SecondStepComponent } from './features/create-account/second-step/second-step.component';
import { ThirdStepComponent } from './features/create-account/third-step/third-step.component';
import { DemandeIdentifiantComponent } from './features/demande-identifiant/demande-identifiant.component';
import { DemandeMotDePasseComponent } from './features/demande-mot-de-passe/demande-mot-de-passe.component';
import { ForgottenLoginMailComponent } from './features/forgotten-login/forgotten-login-mail/forgotten-login-mail.component';
// tslint:disable-next-line: max-line-length
import { ChangeForgottenPwdSuccessComponent } from './features/forgotten-password/change-forgotten-pwd-sucess/change-forgotten-pwd-success.component';
import { ForgottenPasswordMailComponent } from './features/forgotten-password/forgotten-password-mail/forgotten-password-mail.component';
// tslint:disable-next-line: max-line-length
import { FirstStepSignupAffilieComponent } from './features/affiliate-signup/first-step-signup-affilie/first-step-signup-affilie.component';
// tslint:disable-next-line: max-line-length
import { SecondStepSignupAffilieComponent } from './features/affiliate-signup/second-step-signup-affilie/second-step-signup-affilie.component';
import { ChangementQuestionComponent } from './features/changement-question/changement-question.component';
import { BimpliChoiceComponent } from './features/create-account/choice/bimpli-choice/bimpli-choice.component';
// tslint:disable-next-line: max-line-length
import { FirstStepInfoBimpliCadoComponent } from './features/create-account/first-step-info/bimpli-cado/first-step-info-bimpli-cado.component';
import { IdentityStepComponent } from './features/create-account/identity-step/identity-step.component';
import { LoginComponent } from './features/login/login.component';
import { NoticeComponent } from './features/notice/notice.component';
import { PinCodeComponent } from './features/pin-code/pin-code.component';
import { RgpdComponent } from './features/rgpd/rgpd.component';
import { RoutingComponent } from './features/routing/routing.component';
import { TokenComponent } from './features/token/token.component';

import { EcarteActivationCompteComponent } from './features/activation-compte/bimpli-ecarte/ecarte-activation-compte.component';
// tslint:disable-next-line: max-line-length
import { FirstStepSignupAffiliateManagerComponent } from './features/affiliate-manager-signup/first-step-signup-affiliate-manager/first-step-signup-affiliate-manager.component';
// tslint:disable-next-line: max-line-length
import { SecondStepSignupAffiliateManagerComponent } from './features/affiliate-manager-signup/second-step-signup-affiliate-manager/second-step-signup-affiliate-manager.component';
// tslint:disable-next-line: max-line-length
import { ThirdStepSignupAffiliateManagerComponent } from './features/affiliate-manager-signup/third-step-signup-affiliate-manager/third-step-signup-affiliate-manager.component';
// tslint:disable-next-line: max-line-length
import { FourthStepSignupAffilieComponent } from './features/affiliate-signup/fourth-step-signup-affilie/fourth-step-signup-affilie.component';
import { ThirdStepComponent as ThirdStepSignupComponent } from './features/affiliate-signup/third-step/third-step.component';
import { CheckUserComponent } from './features/check-user/check-user.component';
import { AccountExistsComponent } from './features/merchants-invite/account-exists/account-exists.component';
import { ContactInfoComponent } from './features/merchants-invite/contact-info/contact-info.component';
import { TechnicalErrorComponent } from './features/merchants-invite/technical-error/technical-error.component';
import { VersionComponent } from '@shared/components/version/version.component';
import { MerchantsSignupComponent } from './features/merchants-signup/merchants-signup.component';

const routes: Routes = [
  { path: 'auth', component: AuthentificationComponent },
  { path: `${RoutesEnum.LOGIN}/:saml`, component: LoginComponent },
  { path: 'token', component: TokenComponent },
  // TODO : Add a SAML to the Activate Account page (requires backend action)
  {
    path: `${RoutesEnum.ACTIVATE_ACCOUNT}`,
    component: ActivationCompteComponent,
  },
  {
    path: `${RoutesEnum.ACTIVATE_ACCOUNT}/:saml`,
    component: EcarteActivationCompteComponent,
  },
  { path: 'activationSMS/:saml', component: ActivationSmsComponent },
  {
    path: 'demande-renouvellement-mot-de-passe-SMS/:saml',
    component: ConfirmationDemandeMotDePasseSmsComponent,
  },
  {
    path: `${RoutesEnum.FORGOTTEN_PASSWORD}/:saml`,
    component: DemandeMotDePasseComponent,
  },
  {
    path: `${RoutesEnum.FORGOTTEN_PASSWORD_MAIL}/:saml`,
    component: ForgottenPasswordMailComponent,
  },
  {
    path: `${RoutesEnum.CHANGE_PASSWORD}/:saml`,
    component: ChangementMotDePasseComponent,
  },
  {
    path: `${RoutesEnum.CHANGE_PASSWORD_SUCCESS}/:saml`,
    component: ChangePwdSuccessComponent,
  },
  {
    path: 'changement-identifiant/:saml',
    component: ChangementIdentifiantComponent,
  },
  { path: 'routing/:miniUrl', component: RoutingComponent },
  {
    path: `${RoutesEnum.CHANGE_FORGOTTEN_PASSWORD}/:saml`,
    component: ConfirmationDemandeMotDePasseComponent,
  },
  {
    path: `${RoutesEnum.CHANGE_FORGOTTEN_PASSWORD_SUCCESS}/:saml`,
    component: ChangeForgottenPwdSuccessComponent,
  },
  {
    path: `${RoutesEnum.FORGOTTEN_LOGIN}/:saml`,
    component: DemandeIdentifiantComponent,
  },
  {
    path: `${RoutesEnum.FORGOTTEN_LOGIN_MAIL}/:saml`,
    component: ForgottenLoginMailComponent,
  },
  { path: `${RoutesEnum.CREATE_ACCOUNT}/:saml`, component: FirstStepComponent },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT}/choice/:saml`,
    component: BimpliChoiceComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_FIRST_STEP}/:saml`,
    component: FirstStepComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_FIRST_STEP_INFO}/:saml`,
    component: FirstStepInfoBimpliCadoComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_FIRST_STEP}/:saml/:type`,
    component: FirstStepComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/:saml`,
    component: SecondStepComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_SECOND_STEP}/:saml/:type`,
    component: SecondStepComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/:saml`,
    component: ThirdStepComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/:saml/:type`,
    component: ThirdStepComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_FOURTH_STEP}/:saml`,
    component: FourthStepComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_MAIL}/:saml`,
    component: CreateAccountMailComponent,
  },
  {
    path: `${RoutesEnum.CREATE_ACCOUNT_IDENTITY_STEP}/:saml`,
    component: IdentityStepComponent,
  },
  {
    path: `${RoutesEnum.CHANGE_QUESTION}/:saml`,
    component: ChangementQuestionComponent,
  },
  {
    path: `${RoutesEnum.SIGNUP_AFFILIE_FIRST_STEP}/:saml`,
    component: FirstStepSignupAffilieComponent,
  },
  {
    path: `${RoutesEnum.SIGNUP_AFFILIE_SECOND_STEP}/:saml`,
    component: SecondStepSignupAffilieComponent,
  },
  {
    path: `${RoutesEnum.SIGNUP_AFFILIE_THIRD_STEP}/:saml`,
    component: ThirdStepSignupComponent,
  },
  {
    path: `${RoutesEnum.SIGNUP_AFFILIE_FOURTH_STEP}/:saml`,
    component: FourthStepSignupAffilieComponent,
  },
  {
    path: `${RoutesEnum.SIGNUP_AFFILIATE_MANAGER_FIRST_STEP}/:saml`,
    component: FirstStepSignupAffiliateManagerComponent,
  },
  {
    path: `${RoutesEnum.SIGNUP_AFFILIATE_MANAGER_SECOND_STEP}/:saml`,
    component: SecondStepSignupAffiliateManagerComponent,
  },
  {
    path: `${RoutesEnum.SIGNUP_AFFILIATE_MANAGER_THIRD_STEP}/:saml`,
    component: ThirdStepSignupAffiliateManagerComponent,
  },
  {
    path: `${RoutesEnum.MERCHANTS_INVITE_CONTACT_INFO}/:saml`,
    component: ContactInfoComponent,
  },
  {
    path: `${RoutesEnum.MERCHANTS_SIGNUP}/:saml`,
    component: MerchantsSignupComponent,
  },
  {
    path: `${RoutesEnum.TECHNICAL_ERROR}/:saml`,
    component: TechnicalErrorComponent,
  },
  { path: `${RoutesEnum.MERCHANTS_INVITE_ACCOUNT_EXISTS}/:saml`, component: AccountExistsComponent },
  // Receive pin code
  { path: `${RoutesEnum.PIN_CODE}/:saml`, component: PinCodeComponent },
  { path: `${RoutesEnum.CHECK_USER}/:saml`, component: CheckUserComponent },
  // Terms and Conditions with SAML
  { path: `${RoutesEnum.TERMS_AND_CONDITIONS}/:saml`, component: CguComponent },
  // Terms and Conditions access to use when no saml is available (ex: in the case of Activate Account page)
  // TODO : Remove when Activate Account will have a saml
  { path: `${RoutesEnum.TERMS_AND_CONDITIONS}`, component: CguComponent },
  // Legal Notice with SAML
  { path: `${RoutesEnum.LEGAL_NOTICE}/:saml`, component: NoticeComponent },
  // Legal Notice access to use when no saml is available (ex: in the case of Activate Account page)
  // TODO : Remove when Activate Account will have a saml
  { path: `${RoutesEnum.LEGAL_NOTICE}`, component: NoticeComponent },
  // GDPR with SAML
  { path: `${RoutesEnum.GDPR}/:saml`, component: RgpdComponent },
  // GDPR access to use when no saml is available (ex: in the case of Activate Account page)
  // TODO : Remove when Activate Account will have a saml
  { path: `${RoutesEnum.GDPR}`, component: RgpdComponent },
  // Version route
  { path: `${RoutesEnum.VERSION}`, component: VersionComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
