<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.WEEZEN">
  <app-rgpd-asp></app-rgpd-asp>
</ng-container>

<ng-container *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.CESU">
  <app-cesu-rgpd></app-cesu-rgpd>
</ng-container>

<ng-container
  *ngIf="(applicationCode$ | async) === ApplicationCodeEnum.APZ || (applicationCode$ | async) === ApplicationCodeEnum.APZ_MOBILE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_BENEF || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_CAGNOTTE || (applicationCode$ | async) === ApplicationCodeEnum.BENEFIT_MOBILE"
>
  <app-rgpd-apz></app-rgpd-apz>
</ng-container>
