<div class="container-fluid vw-100 vh-100 p-0 m-0 row">
    <div class="d-none d-sm-block col-4 left-side p-3">
        <div class="d-flex flex-column">
            <span class="title-bp">Miam !</span>
            <span class="sub-title-bp">Vous avez choisi <br>carte Bimpli<br> (ex Apétiz) !</span>
        </div>
    </div>
    <div class="d-block col-xs-12 col-sm-8 offset-sm-4">
        <div class="bloc-center d-flex flex-column">
            <div class="bloc-action-back d-flex align-items-center" (click)="back()" *ngIf="!isMobile">
                <i class="bi bi-arrow-left"></i>
                <span class="quit ml-2">Retour</span>
            </div>

            <span class="create">Numéro de carte</span>
            <span class="create-subtitle mt-4 mb-4">Saisissez le numéro au dos de votre carte Bimpli (ex Apétiz).</span>

            <form name="form" role="form" (keydown.enter)="send()" (ngSubmit)="send()" [formGroup]="cardForm">
                <div class="d-flex flex-column form-group">
                    <label>
                        Numéro de la carte
                    </label>
                    <app-input-credit-card formControlName="numCard"></app-input-credit-card>
                    <div *ngIf="cardForm.get('numCard').touched && cardForm.get('numCard').invalid">
                        <small class="form-text text-danger" *ngIf="cardForm.get('numCard').errors?.pattern">
                            {{ 'COMMON.FORM.NUM_CARTE_PATTERN' | translate }}
                        </small>
                        <small class="form-text text-danger" *ngIf="cardForm.get('numCard').errors?.required">
                            {{ 'COMMON.FORM.REQUIRED' | translate }}
                        </small>
                    </div>
                </div>

                <div class="d-flex flex-column form-group">
                    <label>
                        Date de validité de la carte
                    </label>
                    <app-input-credit-card-date formControlName="expirationDate"></app-input-credit-card-date>
                    <div *ngIf="cardForm.get('expirationDate').touched && cardForm.get('expirationDate').invalid">
                        <small class="form-text text-danger" *ngIf="cardForm.get('expirationDate').errors?.pattern">
                            {{ 'COMMON.FORM.DATE_PATTERN' | translate }}
                        </small>
                        <small class="form-text text-danger" *ngIf="cardForm.get('expirationDate').errors?.required">
                            {{ 'COMMON.FORM.REQUIRED' | translate }}
                        </small>
                    </div>
                </div>

                <div class="d-flex flex-column form-group">
                    <label>
                        Référence client
                    </label>
                    <input type="refClient" class="form-control" name="refClient" placeholder="Ref Client indiquée au dos de votre carte." id="refClient"
                           formControlName="refClient" maxlength="120" >
                    <div *ngIf="cardForm.get('refClient').touched && cardForm.get('refClient').invalid">
                        <small class="form-text text-danger" *ngIf="cardForm.get('refClient').errors?.pattern">
                            {{ 'COMMON.FORM.CUSTOMER_NUMBER_PATTERN' | translate }}
                        </small>
                        <small class="form-text text-danger" *ngIf="cardForm.get('refClient').errors?.required">
                            {{ 'COMMON.FORM.REQUIRED' | translate }}
                        </small>
                    </div>
                </div>

                <button type="button" class="btn btn-primary btn-block" [disabled]="!cardForm.valid" (click)="send()">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div *ngIf="isLoading" class="spinner-border"></div>
                        <span [ngClass]="{'ml-2': isLoading }">Continuer</span>
                    </div>
                </button>

            </form>
        </div>
    </div>
</div>

<app-modal id="errorModal">
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <img class="img-fluid" src="/assets/images/error.svg" />
        </div>
        <div class="mt-4 col-12 text-center">{{ messageErreur }}</div>
        <div class="mt-4 mb-4 col-12">
            <button type="button" class="btn btn-primary btn-block"
                    data-dismiss="modal" (click)="closePopin('errorModal')">FERMER</button>
        </div>
    </div>
</app-modal>
