<div id="coordonnes-cards-container">
  <div class="text-right" *ngIf="affiliates?.length > 1">
    <a class="download" (click)="download()">
      {{ 'AFFILIE_APZ.MERCHANTS_SIGNUP.COMMERICAL_INFOS.MULTIPLE_AFFILIATES.DOWNLOAD' | translate }}
    </a>
  </div>
  <ng-container *ngFor="let affiliate of affiliates">
    <div class="mt-2">
      <app-commercial-infos-card [affiliate]="affiliate"> </app-commercial-infos-card>
    </div>
    <div></div>
  </ng-container>
  <ng-container *ngIf="totalPages > 1">
    <app-pagination [totalPages]="totalPages" (currentPageEmitter)="updateAffiliates($event)"> </app-pagination>
  </ng-container>
</div>
