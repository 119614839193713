<app-apz-layout [title]="'Je crée mon compte'" [saml]="saml">
  <form class="row justify-content-center py-3" name="passwordForm" [formGroup]="passwordForm">
    <!-- encart gauche -->
    <app-ouvre-apetiz></app-ouvre-apetiz>

    <!-- encart droite -->
    <section class="col carte">
      <h2>{{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.BONJOUR' | translate }} {{ employee?.firstName }},</h2>
      <p>Remplissez le formulaire ci dessous pour creer votre compte Apetiz</p>

      <label class="pt-4">Votre identifiant</label>
      <p>{{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.INSTRUCTION_LOGIN' | translate }}</p>

      <!-- selecteur -->
      <div class="row justify-content-center py-3">
        <div *ngIf="false" class="btn-group" role="group">
          <button type="button" [ngClass]="loginEstMail ? 'btn btn-selected btnleft' : 'btn btnleft'" (click)="setLoginEmail(true)">
            {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.EMAIL' | translate }}
          </button>
          <button type="button" [ngClass]="loginEstMail ? 'btn btnRight' : 'btn btn-selected btnRight'" (click)="setLoginEmail(false)">
            {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.MOBILE' | translate }}
          </button>
        </div>
      </div>

      <!-- cas email -->
      <div class="row justify-content-center" *ngIf="loginEstMail && !isLoading">
        <div class="form-group">
          <div class="custom-control custom-radio pb-4" *ngIf="mailProPresent">
            <input
              class="custom-control-input"
              type="radio"
              name="loginSaisie"
              id="exampleRadios1"
              [value]="false"
              formControlName="loginSaisie"
            />
            <label class="custom-control-label" for="exampleRadios1">{{ mailPro }}</label>
          </div>
          <div class="custom-control custom-radio pb-4 ">
            <input
              class="custom-control-input"
              type="radio"
              name="loginSaisie"
              id="exampleRadios2"
              formControlName="loginSaisie"
              [value]="true"
            />
            <label class="custom-control-label" for="exampleRadios2">
              <input
                type="text"
                class="form-control mail"
                id="login"
                formControlName="login"
                [placeholder]="this.mailProPresent ? 'Un autre email de votre choix' : 'Un email de votre choix'"
                (click)="inputClick()"
              />
            </label>
            <div *ngIf="passwordForm.get('loginSaisie').value && passwordForm.get('login').touched && !passwordForm.get('login').value">
              <small class="form-text text-danger text-center">
                {{ 'COMMON.FORM.REQUIRED' | translate }}
              </small>
            </div>
            <div *ngIf="passwordForm.get('loginSaisie').value && passwordForm.get('login').touched && !mailValid()">
              <small class="form-text text-danger text-center">
                {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
              </small>
            </div>
            <div *ngIf="loginExistant">
              <small class="form-text text-danger text-center">
                {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.LOGIN_EXISTANT' | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>

      <!-- cas tel -->
      <div class="row justify-content-center" *ngIf="!loginEstMail && !isLoading">
        <div class="form-group">
          <input type="text" class="form-control phone" id="phone" formControlName="login" placeholder="ex : 06 06 06 06 06" />
          <div *ngIf="passwordForm.get('login').touched && !passwordForm.get('login').value">
            <small class="form-text text-danger text-center">
              {{ 'COMMON.FORM.REQUIRED' | translate }}
            </small>
          </div>
          <div *ngIf="passwordForm.get('login').touched && !mobileValid()">
            <small class="form-text text-danger text-center">
              {{ 'COMMON.FORM.MOBILE_INVALID' | translate }}
            </small>
          </div>
          <div *ngIf="loginExistant">
            <small class="form-text text-danger text-center">
              {{ 'APZ.CREATION_ACCOUNT.SECOND_STEP.LOGIN_EXISTANT' | translate }}
            </small>
          </div>
        </div>
      </div>

      <div *ngIf="isLoading" class="d-block text-center">
        <div class="spinner-border" role="status"></div>
      </div>

      <label class="pt-4">Votre mot de passe</label>
      <p>Choisissez votre mot de passe en respectant le format</p>

      <div class="row justify-content-center">
        <section class="w-100 row">
          <!-- MDP -->
          <div class="col-12 col-sm-7 align-self-center">
            <div class="form-group row d-flex justify-content-center align-items-center">
              <div class="col-10">
                <input
                  [type]="hide ? 'password' : 'text'"
                  class="form-control lock"
                  name="password"
                  formControlName="password"
                  placeholder="Mot de passe"
                />
              </div>
              <div class="img-fluid mr-1 col-1" [ngClass]="hide ? 'hide' : 'show'" (click)="hide = !hide"></div>
              <div *ngIf="passwordForm.get('password').touched && passwordForm.get('password').invalid">
                <small class="form-text text-danger" *ngIf="passwordForm.get('password').errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <div class="form-group row d-flex justify-content-center align-items-center">
              <div class="col-10">
                <input
                  [type]="hide2 ? 'password' : 'text'"
                  class="form-control lock"
                  name="confirmPassword"
                  formControlName="confirmPassword"
                  placeholder="Confirmation"
                />
              </div>
              <div class="img-fluid mr-1 col-1" [ngClass]="hide2 ? 'hide' : 'show'" (click)="hide2 = !hide2"></div>
              <div *ngIf="passwordForm.get('confirmPassword').touched">
                <small
                  class="form-text text-danger"
                  *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.required"
                >
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="form-text text-danger" *ngIf="!passwordEqual()">
                  {{ 'COMMON.PASSWORD.DO_NOT_MATCH' | translate }}
                </small>
              </div>
            </div>
          </div>
          <!-- Progress -->
          <div class="col-12 col-sm-5 infos-utilisateur">
            <p>{{ 'CREATION_ACCOUNT.SECOND_STEP.VALIDATION' | translate }}</p>
            <app-password-validation [passwordValue]="passwordForm.get('password').value" [affLastPassword]="false" [progressBar]="true">
            </app-password-validation>
          </div>
        </section>

        <!-- Question secrete-->
        <section *ngIf="questions" class="col-12 py-4">
          <div class="row justify-content-center">
            <p class="col-12 col-sm-8 py-4 text-center">
              Pour apporter une sécurité supplémentaire à votre compte Apetiz, répondez à une des questions suivantes :
            </p>
          </div>
          <div *ngFor="let question of questions; let i = index" class=" row justify-content-center text-left">
            <div class="form-group col-12 col-sm-6">
              <div class=" custom-control custom-radio ">
                <input
                  class="custom-control-input"
                  type="radio"
                  name="secretQuestion"
                  id="{{ 'radio' + i }}"
                  [value]="question.id"
                  formControlName="secretQuestion"
                />
                <label class="custom-control-label question " for="{{ 'radio' + i }}">{{ question.label }}</label>
              </div>
            </div>
          </div>
          <div class="row justify-content-center py-4">
            <div class="col-12 col-sm-8">
              <input
                type="text"
                class="form-control response"
                id="reponse"
                name="response"
                formControlName="response"
                placeholder="Ma réponse*"
              />
              <div *ngIf="passwordForm.get('response').touched">
                <small
                  class="form-text text-danger text-center"
                  *ngIf="passwordForm.get('response').errors && passwordForm.get('response').errors.required"
                >
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small
                  class="form-text text-danger text-center"
                  *ngIf="passwordForm.get('secretQuestion').errors && passwordForm.get('secretQuestion').errors.required"
                >
                  {{ 'COMMON.FORM.SECRET_QUESTION.REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
        </section>

        <!-- CGU -->
        <section class="col-12 col-sm-8 py-4 custom-control custom-switch text-center">
          <div class="row justify-content-center">
            <label class="col text-left cgu">J'ai lu et j'accepte les conditions générales d'utilisation de l'application Apetiz</label>
            <app-toggle-switch formControlName="acceptCGU"></app-toggle-switch>
          </div>
          <small class="cgu-link" (click)="getCGU()">Consulter les CGU </small>
        </section>

        <section class="col-12 actions py-3 text-center">
          <button
            class="btn btn-action px-4"
            type="submit"
            (click)="validateForm()"
            [disabled]="
              !passwordForm.valid ||
              !passwordEqual() ||
              !passwordForm.get('acceptCGU').value ||
              (loginEstMail && passwordForm.get('loginSaisie').value && !mailValid()) ||
              (!loginEstMail && !mobileValid())
            "
          >
            {{ 'COMMON.FORM.SUBMIT' | translate | uppercase }}
          </button>
        </section>
      </div>

      <div *ngIf="messageErreur" class="subHeaderError d-flex align-items-center justify-content-center">
        <i class="icon-info mr-3"></i>
        <p>{{ messageErreur }}</p>
      </div>
    </section>
  </form>
</app-apz-layout>
