import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReponseMinifiedToken } from '../../shared/models/reponse.minifiedToken';
import { SiloApiService } from '../../shared/services/siloApi.service';
import { GatewayService } from '../../shared/services/gateway.service';

@Component({
    selector: 'app-routing',
    templateUrl: './routing.component.html',
    styleUrls: ['./routing.component.scss']
})
export class RoutingComponent implements OnInit {
    private miniUrl: string;

    constructor(private route: ActivatedRoute, private siloApiService: SiloApiService, private gatewayService: GatewayService) { }

    ngOnInit(): void {
        this.miniUrl = this.route.snapshot.paramMap.get('miniUrl');
        this.gatewayService
            .getAccessToken()
            .subscribe(authToken => this.siloApiService
                .getUrlFromMinifiedToken(this.miniUrl, authToken).subscribe(retour => this.redirigerPageActivation(retour)));
    }

    public redirigerPageActivation(reponse: string) {
        const urlRedirection: ReponseMinifiedToken = JSON.parse(reponse);
        document.location.href = urlRedirection.url;
    }
}
