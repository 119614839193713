<!--
<div
    class="img-fluid"
    [ngClass]="{ 'apetiz-image': estAppetiz, 'cesu-image': estCesu, 'asp-image': !estAppetiz && !estCesu }"
    style="height: 100%; width: 100%"
>
    <div class="container">
        <div id="loginbox" style="max-width: 500px;" class="d-block mx-auto">
            <div class="form-row text-center">
                <div class="col-12 " style="padding-bottom: 4.5vh;">
                    <div
                        class="img-fluid"
                        [ngClass]="{
                            'apetiz-logo': estAppetiz,
                            'cesu-logo': estCesu,
                            'asp-logo': !estAppetiz && !estCesu
                        }"
                    ></div>
                    <div class="title" style="padding-top: 2.5vh;">
                        Mot de passe oublié
                    </div>
                    <div class="subtitle" style="padding-top: 2.5vh;">
                        Vous avez reçu un code de confirmation par SMS <br>
                        Saisissez le ci-dessous puis validez.
                    </div>
                </div>
                <div *ngIf="messageErreur !== null"  class="error">
                    {{ messageErreur }}
                </div>
            </div>
            <div>
                <form
                    #f="ngForm"
                    (submit)="validerInscription(f)"
                    [ngClass]="f.submitted ? 'was-validated' : 'needs-validation'"
                    novalidate
                >
                    <section [formGroup]="ginsc">
                        <div style="margin-bottom: 25px">
                            <div class="input-group">
                                <input
                                    [formControl]="codeSMS"
                                    type="text"
                                    class="form-control input"
                                    placeholder="Code de confirmation"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                />
                                <div *ngIf="f.submitted" class="invalid-feedback ">
                                    <div class="error">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                        >
                                            <defs>
                                                <style>
                                                    .a {
                                                        fill: none;
                                                        stroke: #b44b46;
                                                        stroke-width: 1.5px;
                                                    }
                                                    .b {
                                                        fill: #b44b46;
                                                    }
                                                </style>
                                            </defs>
                                            <g transform="translate(0 -0.5)">
                                                <circle
                                                    class="a"
                                                    cx="9.25"
                                                    cy="9.25"
                                                    r="9.25"
                                                    transform="translate(0.75 1.25)"
                                                />
                                                <path
                                                    class="b"
                                                    d="M9.363,12.63,9,5.35h2l-.364,7.28Zm1.6,1.37v1.792H9.041V14Z"
                                                />
                                            </g>
                                        </svg>
                                        Veuillez saisir votre code.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <br />
                    <br />
                    <div class="form-row text-center">
                        <div class="col-12">
                            <button type="submit" class="btn_prim" style="margin-bottom: 25px">
                                VALIDER
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
-->
<div *ngIf="estApetizMobile" class="img-fluid div-login">
  <app-snackbar></app-snackbar>
  <div class="container">
    <form name="passwordForm" [formGroup]="ginsc" class="d-block mx-auto">
      <div class="form-row text-center">
        <div class="col-12 ">
          <div class="img-fluid apetiz-logo"></div>
          <div class="title pt-5 pb-4">
            Mot de passe oublié
          </div>
        </div>
      </div>
      <div>
        <h4 class="text-center">Demande de réinitialisation envoyée avec succès !</h4>
        <img class="img-fluid py-4" src="assets/apz/pointilles_paperplane.svg" />
        <div class="row justify-content-center encart p-3">
          <p class="pb-3 text-center">
            Vous avez reçu un code de confirmation par SMS<br />
            Saisissez le ci-dessous puis validez.
          </p>
          <div class="w-100 row d-flex justify-content-center align-items-center">
            <input class="form-control lock" type="text" maxlength="6" [formControl]="codeSMS" placeholder="- - - - - -" />
            <div *ngIf="codeSMS.touched && codeSMS.errors">
              <small class="form-text text-danger text-center" *ngIf="codeSMS.errors.required">
                {{ 'COMMON.FORM.REQUIRED' | translate }}
              </small>
              <small class="form-text text-danger text-center" *ngIf="codeSMS.errors.pattern">
                {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
              </small>
            </div>
          </div>
          <div class="text-center py-3">
            <nit-spinner-button
              (clicked)="submit()"
              label="{{ 'COMMON.FORM.SUBMIT' | translate | uppercase }}"
              [isLoading]="isLoading"
              [disabled]="ginsc.invalid"
              css="btn btn-action px-5"
            >
            </nit-spinner-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div *ngIf="!estApetizMobile">
  <app-apz-layout [title]="'Mot de passe oublié'" [saml]="saml">
    <form class="row justify-content-center py-3" name="passwordForm" [formGroup]="ginsc">
      <!-- encart gauche -->
      <app-ouvre-apetiz></app-ouvre-apetiz>

      <!-- encart droite -->
      <section class="col carte">
        <app-snackbar></app-snackbar>
        <div>
          <h4 class="text-center">Demande de réinitialisation envoyée avec succès !</h4>
          <img class="img-fluid py-4" src="assets/apz/pointilles_paperplane.svg" />
          <div class="row justify-content-center encart p-3">
            <p class="pb-3 text-center">
              Vous avez reçu un code de confirmation par SMS<br />
              Saisissez le ci-dessous puis validez.
            </p>
            <div class="w-100 row d-flex justify-content-center align-items-center">
              <input class="form-control lock" type="text" maxlength="6" [formControl]="codeSMS" placeholder="- - - - - -" />
              <div *ngIf="codeSMS.touched && codeSMS.errors">
                <small class="form-text text-danger text-center" *ngIf="codeSMS.errors.required">
                  {{ 'COMMON.FORM.REQUIRED' | translate }}
                </small>
                <small class="form-text text-danger text-center" *ngIf="codeSMS.errors.pattern">
                  {{ 'COMMON.FORM.EMAIL_INVALID' | translate }}
                </small>
              </div>
            </div>
            <div class="text-center py-3">
              <nit-spinner-button
                (clicked)="submit()"
                label="{{ 'COMMON.FORM.SUBMIT' | translate | uppercase }}"
                [isLoading]="isLoading"
                [disabled]="ginsc.invalid"
                css="btn btn-action px-5"
              >
              </nit-spinner-button>
            </div>
          </div>
        </div>
      </section>
    </form>
  </app-apz-layout>
</div>
