<div id="last-step-container" class="p-0">

    <app-go-back-btn
    [text]="'Retour'"
    (goBack$)="goBack()">
    </app-go-back-btn>

    <div class="title text-left">
        {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LAST_STEP.LINE1" | translate }}
    </div>

    <div class="content">
        <span class="text-left text">
            {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LAST_STEP.LINE2_1" | translate }}
        </span>
        <span class="text text-left font-weight-bold">
            {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LAST_STEP.LINE2_2" | translate }}
        </span>
        <span class="text">
            {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LAST_STEP.LINE2_3" | translate }}
        </span>
        <span class="text">
            {{ email }}
        </span>

    </div>

    <div class="text text-left my-4">
        {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LAST_STEP.LINE3_PS" | translate }}

    </div>

    <div id="divider" class="mb-4 w-50"></div>



    <div class="resend">
        <span class="text">
            {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LAST_STEP.RESEND_DESCRIPTION" | translate }}
        </span>
        <span class="text text-link ml-3">
            {{ "AFFILIE_APZ.MERCHANTS_SIGNUP.LAST_STEP.RESEND_ACTION" | translate }}
        </span>
    </div>
    
</div>
